import { computed, Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CaptureMethod, GetNumberDto } from "@api";
import { Observable, of } from "rxjs";

import { FeatureContext, UserContext } from "~services/contexts";

import { CopyNumberBackwardsDialogComponent, DeploymentAdoptionDialogComponent, DeploymentMigrationDialogComponent } from "../dialogs";
import { QuarterlyPlanningAdminService } from "./quarterly-planning-admin.service";

@Injectable({ providedIn: "root" })
export class DeploymentMigrationService {

    private readonly enableDeploymentMigration = computed(() =>
        this.userContext.isSuperAdmin() &&
        this.featureContext.deployedNumbersEnabled() &&
        this.quarterlyPlanningAdminService.enableDeploymentMigration());

    constructor(
        private readonly userContext: UserContext,
        private readonly featureContext: FeatureContext,
        private readonly quarterlyPlanningAdminService: QuarterlyPlanningAdminService,
        private readonly dialog: MatDialog,
    ) { }

    canMigrate = (number: GetNumberDto) =>
        !number.isDelegated &&
        number.captureMethod === CaptureMethod.calculated &&
        this.enableDeploymentMigration();

    migrateNumber = (number: GetNumberDto): Observable<GetNumberDto | undefined> =>
        !this.canMigrate(number) ? of(undefined) :
            DeploymentMigrationDialogComponent.open(this.dialog, number).afterClosed();

    canAdopt = (number: GetNumberDto) =>
        !number.isDelegated &&
        number.captureMethod === CaptureMethod.manual &&
        this.enableDeploymentMigration();

    adoptNumber = (number: GetNumberDto): Observable<GetNumberDto | undefined> =>
        !this.canAdopt(number) ? of(undefined) :
            DeploymentAdoptionDialogComponent.open(this.dialog, number).afterClosed();

    canCopyBackwards = (number: GetNumberDto) =>
        !number.isDelegated &&
        !number.source &&
        number.isRecurring &&
        this.enableDeploymentMigration();

    copyBackwards = (number: GetNumberDto): Observable<boolean | undefined> =>
        !this.canCopyBackwards(number) ? of(undefined) :
            CopyNumberBackwardsDialogComponent.open(this.dialog, number).afterClosed();
}

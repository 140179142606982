<div class="label-row">
    @if (withSortHeader()) {
        <div class="column-label" mat-sort-header>
            <ng-container [ngTemplateOutlet]="columnLabel"></ng-container>
        </div>
    } @else {
        <div class="column-label">
            <ng-container [ngTemplateOutlet]="columnLabel"></ng-container>
        </div>
    }
    <ng-template #columnLabel>
        <ng-content></ng-content>
    </ng-template>
    <button class="filter-button" mat-icon-button [matMenuTriggerFor]="userMenu" [matTooltip]="'userFilter.filterByUser' | translate"
        (click)="$event.stopPropagation()" [color]="selectedUser ? 'primary' : null">
        <mat-icon>filter_list</mat-icon>
    </button>
    <mat-menu #userMenu xPosition="before">
        <div class="user-list">
            <button mat-menu-item (click)="selectUser(null)">
                {{ 'noneOption' | translate }}
            </button>
            <button mat-menu-item *ngFor="let user of users$ | async" (click)="selectUser(user)">
                {{ getUserName(user) }}
            </button>
        </div>
    </mat-menu>
</div>
@if (selectedUser) {
    <mat-chip-listbox (click)="$event.stopPropagation()" [attr.aria-label]="'userFilter.userFilter' | translate">
        <mat-chip-option (removed)="selectUser(null)" class="small-filter-chip" [selectable]="false">
            <mat-icon matChipAvatar>filter_list</mat-icon>
            <span class="user-filter-name">{{ getUserName(selectedUser) }}</span>
            <button matChipRemove [attr.aria-label]="'userFilter.clearFilter' | translate">
                <mat-icon>cancel</mat-icon>
            </button>
        </mat-chip-option>
    </mat-chip-listbox>
}
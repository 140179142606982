import { GetTeamWatchlistDto, GetTeamWatchlistNotificationDto, GetUserWatchlistDto, GetUserWatchlistNotificationDto, GetWatchlistDto, GetWatchlistSharingReferenceDto, SimpleCompanyDto, SimpleTeamDto, TeamWatchlistItemsDto, WatchlistItemReferenceDto, WatchlistItemsDto } from "@api";

import { EntityType } from "~shared/enums";

import { getDelegatedItemCompanyTeam, IDelegatedItem } from "./delegation-helper";

export type GetWatchlistNotificationDto = GetTeamWatchlistNotificationDto | GetUserWatchlistNotificationDto;

export const isUserList = (list: GetWatchlistDto): list is GetUserWatchlistDto => list.type === "user";
export const isTeamList = (list: GetWatchlistDto): list is GetTeamWatchlistDto => list.type === "team";

export type UserWatchlistItemsDto = WatchlistItemsDto & { list: GetUserWatchlistDto };

export const isUserItems = (items: WatchlistItemsDto): items is UserWatchlistItemsDto => isUserList(items.list);
export const isTeamItems = (items: WatchlistItemsDto): items is TeamWatchlistItemsDto => isTeamList(items.list);

export type SharedWatchlistDto = GetWatchlistDto & { sharingContext: GetWatchlistSharingReferenceDto };

export const isShared = (list: GetWatchlistDto): list is SharedWatchlistDto => !!list.sharingContext;

interface BaseReferenceSource {
    id: string;
    company: SimpleCompanyDto;
    team: SimpleTeamDto;
}

interface PeriodicReferenceSource extends BaseReferenceSource {
    financialYear: number;
    planningPeriod: number;
}

export declare type ReferenceSource = BaseReferenceSource | PeriodicReferenceSource |
    BaseReferenceSource & IDelegatedItem | PeriodicReferenceSource & IDelegatedItem;

export const buildItemReference = (source: ReferenceSource, type: EntityType): WatchlistItemReferenceDto => {
    const { company, team } = "isDelegated" in source ? getDelegatedItemCompanyTeam(source) : source;
    const coreReference = {
        entityType: type,
        companyId: company.id,
        teamId: team.id,
        entityId: source.id,
    };
    return "financialYear" in source ? {
        type: "periodic",
        financialYear: source.financialYear,
        planningPeriod: source.planningPeriod,
        ...coreReference,
    } : {
        type: "fixed",
        ...coreReference,
    };
};


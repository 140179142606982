import { Directive, forwardRef } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

/**
 * This regexp is copied from the regular angular email validator.
 * See https://github.com/angular/angular/blob/11.0.4/packages/forms/src/validators.ts
 */
const EMAIL_REGEXP =
    // eslint-disable-next-line max-len
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

/**
 * Validator function to validate a UserEmailSelectorComponent control has a valid email, if set.
 *
 * @returns an error of "email" if the control has an email set and is invalid.
 */
export const userOrEmailValidator = (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (!value) return null;

    let email: string;
    if (typeof value === "string") {
        email = value;
    } else {
        email = value.email;
        if (!email) return null;
    }

    return EMAIL_REGEXP.test(email) ? null : {"email": true};
};

export const USER_OR_EMAIL_VALIDATOR = {
    provide: NG_VALIDATORS,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    useExisting: forwardRef(() => UserOrEmailValidator),
    multi: true
};

/**
 * A directive that adds the `userOrEmail` validator to controls marked with the
 * `userOrEmail` attribute. This is provided with the `NG_VALIDATORS` multi-provider list.
 */
@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[userOrEmail][formControlName],[userOrEmail][formControl],[userOrEmail][ngModel]",
    providers: [USER_OR_EMAIL_VALIDATOR]
})
export class UserOrEmailValidator implements Validator {
    validate(control: AbstractControl): ValidationErrors | null {
        return userOrEmailValidator(control);
    }
}

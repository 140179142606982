<li app-feed-list-item-content [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" [icon]="iconName"
    [class.positive-change]="positiveChange"
    [class.negative-change]="negativeChange">
    <div>
        <wf-translate-template [key]="approvalTypeNameKey">
            <b *wfTransTplContent="'teamName'">{{ teamNameOrDefault }}</b>
        </wf-translate-template>
    </div>
    <div *ngIf="comment" class="comment multiline-description">{{ comment }}</div>
</li>
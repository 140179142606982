<h4 mat-dialog-title>
    <mat-icon>tips_and_updates</mat-icon>
    {{ 'goals.suggest.title' | translate }}
</h4>

@if (state === "focus") {
    <form [formGroup]="form" (submit)="submitFocusForm()">
        <mat-dialog-content>
            <p translate="goals.suggest.focusAreaExplanation"></p>
    
            <mat-form-field>
                <mat-label>{{ 'goals.suggest.focusArea' | translate }}</mat-label>
                <textarea matInput formControlName="focusArea" name="focusArea" maxlength="1000"
                    cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"></textarea>
                <mat-error *ngIf="focusAreaControl.hasError('required')">
                    {{ 'goals.suggest.focusAreaRequired' | translate }}
                </mat-error>
                <mat-error *ngIf="focusAreaControl.errors?.maxlength">
                    {{ 'goals.suggest.focusAreaRequired' | translate: focusAreaControl.errors?.maxlength }}
                </mat-error>
            </mat-form-field>
        </mat-dialog-content>

        <mat-dialog-actions align="end">
            <button type="submit" mat-flat-button color="primary">
                {{ 'goals.suggest.generate' | translate }}
            </button>
        </mat-dialog-actions>
    </form>
} @else if (state === "suggestions") {
    <mat-dialog-content>
        <table mat-table class="wf-table" [dataSource]="dataSource" wfTableFocus>
            <ng-container matColumnDef="heading">
                <th mat-header-cell *matHeaderCellDef class="wf-column-heading">
                    {{'goals.heading' | translate}}
                </th>
                <td mat-cell *matCellDef="let element" class="wf-column-heading">
                    <div class="multiline-description">
                        {{ element.heading }}
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>
                    {{'goals.description' | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                    <div class="multiline-description">
                        {{ element.description }}
                    </div>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="apply">
                <th mat-header-cell *matHeaderCellDef>
                    {{'goals.suggest.apply' | translate}}
                </th>
                <td mat-cell *matCellDef="let suggestion" [ngSwitch]="isApplied(suggestion)">
                    <button *ngSwitchCase="false" mat-button color="primary" (click)="applySuggestion(suggestion)">
                        {{ 'goals.suggest.apply' | translate }}
                    </button>
                    <span *ngSwitchCase="true" class="applied">
                        {{ 'goals.suggest.applied' | translate }}
                    </span>
                </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns" [wfRowFocus]="row" [wfRowFocusIndex]="i">
            </tr>
        </table>
    </mat-dialog-content>
} @else if (state === "loading") {
    <mat-dialog-content>
        <div class="loading-container">
            <mat-spinner [diameter]="40"></mat-spinner>
            <span translate="goals.suggest.loading"></span>
        </div>
    </mat-dialog-content>
}
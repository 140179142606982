import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { IL10nsStrings, TimeagoIntl } from "ngx-timeago";
import { strings as defaultStrings } from "ngx-timeago/language-strings/en";
import { BehaviorSubject, catchError, from, map, Observable, of, Subscription, switchMap } from "rxjs";

@Injectable()
export class CustomTimeagoIntl extends TimeagoIntl implements OnDestroy {

    private readonly currentLanguage: BehaviorSubject<string>;
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly translate: TranslateService,
    ) {
        super();
        this.strings = defaultStrings;

        this.currentLanguage = new BehaviorSubject<string>(this.translate.currentLang);
        this.subscriptions.add(this.translate.onLangChange.pipe(map(e => e.lang)).subscribe(this.currentLanguage));

        this.subscriptions.add(this.currentLanguage.pipe(
            switchMap(this.getTranslation),
        ).subscribe(strings => {
            this.strings = strings;
            this.changes.next();
        }));
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private getTranslation = (lang: string): Observable<IL10nsStrings> =>
        from(import(`/node_modules/ngx-timeago/language-strings/${lang}`).then(m => m.strings as IL10nsStrings)).pipe(
            catchError(() => of(defaultStrings)),
        );
}

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetNewsItemDto, NewsItemsApi } from "@api";

import { NotificationService } from "~services/notification.service";
import { NewsItemStateService } from "~services/state";
import { ButtonState } from "~shared/components/status-button/status-button.component";

@Component({
    selector: "app-delete-news-item-dialog",
    templateUrl: "./delete-news-item-dialog.component.html",
    styleUrls: ["./delete-news-item-dialog.component.scss"]
})
export class DeleteNewsItemDialogComponent {

    buttonState: ButtonState;

    get heading(): string {
        return this.newsItem.heading;
    }

    get isTeamOwned(): boolean {
        return this.newsItem.currentTeam.id === this.newsItem.team.id;
    }

    constructor(
        private readonly newsItemsApi: NewsItemsApi,
        private readonly newsItemStateService: NewsItemStateService,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<DeleteNewsItemDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) private readonly newsItem: GetNewsItemDto,
    ) { }

    static open(dialog: MatDialog, newsItem: GetNewsItemDto) {
        return dialog.open<DeleteNewsItemDialogComponent, GetNewsItemDto, boolean>(
            DeleteNewsItemDialogComponent, {
            width: "500px",
            data: newsItem,
        });
    }

    delete = () => {
        if (this.buttonState) return;

        this.buttonState = "loading";

        this.newsItemsApi.deleteNewsItem(
            this.newsItem.currentTeam.company.id,
            this.newsItem.currentTeam.id,
            this.newsItem.id,
        ).subscribe({
            next: () => {
                this.buttonState = "success";
                this.newsItemStateService.notifyDelete({
                    companyId: this.newsItem.currentTeam.company.id,
                    teamId: this.newsItem.currentTeam.id,
                    id: this.newsItem.id,
                });
                setTimeout(() => {
                    this.dialogRef.close(true);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };

}

// eslint-disable-next-line max-classes-per-file
import { CollectionViewer, ListRange } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { MatSort } from "@angular/material/sort";
import {
    BehaviorSubject, catchError, combineLatest, EMPTY, exhaustMap, map,
    merge, Observable, of, shareReplay, Subscription, switchMap, tap
} from "rxjs";

import { defaultFilterPredicate, defaultSortData, defaultSortingDataAccessor } from "./async-table-data-helpers";
import { withRefresh } from "./rx-operators";

const defaultUnknownFilterPredicate = () => true;

export declare interface PagedData<TData> {
    data: TData[];
    hasMore: boolean;
}

declare interface AsyncTableState<TData> {
    data: Readonly<TData[]>;
    hasMore: boolean;
    hasAll: boolean;
    filteredData: Readonly<TData[]>;
}

export interface SortDef<TSort> {
    column: TSort;
    direction: "asc" | "desc";
}

export declare type AsyncTableDataCallback<TData, TFilter, TSort extends string, TInput> =
    (sort: SortDef<TSort> | null, filter: TFilter | null, skip: number, take: number, input: TInput) =>
        Observable<PagedData<TData>>;

export declare interface AsyncTableDataSourceOptions {
    pageSize: number;
    loadThreshold: number;
}

const defaultOptions: Readonly<AsyncTableDataSourceOptions> = {
    pageSize: 50,
    loadThreshold: 10,
};

const getSortDef = <TSort extends string>(sorter: MatSort | null): SortDef<TSort> | null => {
    if (!sorter) return null;
    return { column: sorter.active as TSort, direction: sorter.direction || "asc" };
};

export class AsyncDataSource<TData, TFilter, TSort extends string = string, TInput = void> extends DataSource<TData> {

    get data(): Readonly<TData[]> {
        return this.stateSub.value.data;
    }

    get filteredData(): Readonly<TData[]> {
        return this.stateSub.value.filteredData;
    }

    get hasMore(): boolean {
        return this.stateSub.value.hasMore;
    }

    get hasError(): boolean {
        return this.hasErrorInternal;
    }

    get isLoading(): boolean {
        return this.isLoadingInternal;
    }

    get isLoadingInitial(): boolean {
        return this.isLoadingInternal && !this.stateSub.value.data.length;
    }

    get isLoadingMore(): boolean {
        return this.isLoadingInternal && !!this.stateSub.value.data.length;
    }

    get hasNoData(): boolean {
        return !this.isLoadingInternal && !this.hasErrorInternal && !this.stateSub.value.data.length;
    }

    get hasNoVisibleData(): boolean {
        return !this.isLoadingInternal && !this.hasErrorInternal && !this.stateSub.value.filteredData.length;
    }

    get loadFailed(): boolean {
        return this.hasErrorInternal && !this.stateSub.value.data.length;
    }

    get loadMoreFailed(): boolean {
        return this.hasErrorInternal && !!this.stateSub.value.data.length;
    }

    get sort(): MatSort | null {
        return this.sorterSub.value ?? null;
    }

    set sort(value: MatSort | null) {
        if (this.sorterSub.value === value) return;
        this.sorterSub.next(value);
    }

    get filter(): TFilter | null {
        return this.filterSub.value;
    }

    set filter(value: TFilter | null) {
        this.filterSub.next(value);
    }

    filterPredicate: (data: TData, filter: TFilter) => boolean = defaultUnknownFilterPredicate;
    sortingDataAccessor: (data: TData, sortHeaderId: string) => string | number = defaultSortingDataAccessor;

    private readonly options: Readonly<AsyncTableDataSourceOptions>;
    private readonly refreshSub = new BehaviorSubject<void>(undefined);
    private readonly loadMoreRetrySub = new BehaviorSubject<void>(undefined);
    private readonly sorterSub = new BehaviorSubject<MatSort | null>(null);
    private readonly filterSub = new BehaviorSubject<TFilter | null>(null);

    private isLoadingInternal = true;
    private hasErrorInternal = false;

    private readonly sorterWithChanges$: Observable<MatSort | null> = this.sorterSub.pipe(
        switchMap(sorter => !sorter ? of(null) : merge(of(sorter), sorter.sortChange.pipe(map(() => sorter)))),
    );

    private readonly stateSub = new BehaviorSubject<AsyncTableState<TData>>({ data: [], hasMore: true, hasAll: false, filteredData: [] });
    private readonly filteredData$ = this.stateSub.pipe(map(state => state.filteredData));

    private dataSubscription?: Subscription;

    constructor(
        private readonly input$: Observable<TInput>,
        private readonly callback: AsyncTableDataCallback<TData, TFilter, TSort, TInput>,
        private readonly comparator: (o1: TData, o2: TData) => boolean,
        options?: Partial<AsyncTableDataSourceOptions>,
    ) {
        super();

        this.options = { ...defaultOptions, ...options };
    }

    sortData: (data: TData[], sort: MatSort) => TData[] = (data: TData[], sort: MatSort) => defaultSortData(data, sort, this);

    connect = (collectionViewer: CollectionViewer): Observable<readonly TData[]> => {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = this.subscribeToData(collectionViewer);
        return this.filteredData$;
    };

    disconnect = (collectionViewer: CollectionViewer): void => {
        this.dataSubscription?.unsubscribe();
        this.dataSubscription = undefined;
        this.resetState();
    };

    refresh = () => this.refreshSub.next();

    retryLoadMore = () => {
        this.hasErrorInternal = false;
        this.loadMoreRetrySub.next();
    };

    addRow = (newRow: TData): void => {
        const state = this.stateSub.value;
        const data = state.data.filter(row => !this.comparator(row, newRow)).concat(newRow);
        this.mutateData(state, data);
    };

    replaceRow = (newRow: TData): boolean => {
        const state = this.stateSub.value;
        const index = state.data.findIndex(row => this.comparator(row, newRow));
        if (index < 0) return false;

        const data = [...state.data];
        data[index] = newRow;
        this.mutateData(state, data);
        return true;
    };

    deleteRow = (selector: (row: TData) => boolean): boolean => {
        const state = this.stateSub.value;
        const index = state.data.findIndex(selector);
        if (index < 0) return false;

        const data = [...state.data];
        data.splice(index, 1);
        this.mutateData(state, data);
        return true;
    };

    private resetState = () => {
        this.isLoadingInternal = false;
        this.hasErrorInternal = false;
        this.stateSub.next({ data: [], hasMore: true, hasAll: false, filteredData: [] });
    };

    private subscribeToData = (collectionViewer: CollectionViewer): Subscription =>
        this.input$.pipe(
            withRefresh(this.refreshSub),
            tap(this.resetState),
            switchMap(input => this.buildStateSub(input, collectionViewer.viewChange)),
        ).subscribe(state => this.stateSub.next(state));

    private buildStateSub = (input: TInput, visibleRange$: Observable<ListRange>): Observable<AsyncTableState<TData>> => {
        const baseState$: Observable<AsyncTableState<TData>> = combineLatest({
            sorter: this.sorterWithChanges$,
            filter: this.filterSub,
        }).pipe(
            switchMap(({ sorter, filter }) => {
                const state = this.stateSub.value;
                if (state.hasAll) {
                    // If we've loaded all the data (e.g. without filter), we can do a local sort/filter.
                    return of({
                        ...state,
                        filteredData: this.applySortAndFilter(state.data, sorter, filter),
                    } as AsyncTableState<TData>);
                }

                this.isLoadingInternal = true;
                return merge(
                    of({ data: [], hasMore: true, hasAll: false, filteredData: [] } as AsyncTableState<TData>),
                    this.callback(getSortDef<TSort>(sorter), filter, 0, this.options.pageSize, input).pipe(
                        map(result => ({
                            data: result.data,
                            hasMore: result.hasMore,
                            hasAll: !filter && !result.hasMore,
                            filteredData: result.data,
                        } as AsyncTableState<TData>)),
                        catchError(() => {
                            this.hasErrorInternal = true;
                            return of({
                                data: [],
                                hasMore: false,
                                hasAll: false,
                                filteredData: [],
                            } as AsyncTableState<TData>);
                        }),
                        tap(() => this.isLoadingInternal = false),
                    )
                );
            }),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        const pagedData$: Observable<AsyncTableState<TData>> = baseState$.pipe(
            // If we get a new base state, we should immediately cancel any callback to load more data.
            switchMap(() => visibleRange$.pipe(
                withRefresh(this.loadMoreRetrySub),
                // We use exhaustMap to stop listening to range changes while loading data
                exhaustMap(visibleRange => {
                    const currentState = this.stateSub.value;
                    // We've loaded all the data - we don't need to try to load more.
                    if (!currentState.hasMore) return EMPTY;
                    // There is some error - we should abort loading more data.
                    if (this.hasError) return EMPTY;
                    // We're already loading - don't try to load more.
                    if (this.isLoading) return EMPTY;
                    const itemCount = currentState.data.length;
                    // We haven't loaded anything yet - let the base runner get data.
                    if (!itemCount) return EMPTY;
                    const indexToLoadWhenVisible = itemCount - this.options.loadThreshold;
                    // We haven't scrolled to the point we need to load more data.
                    if (visibleRange.end < indexToLoadWhenVisible) return EMPTY;

                    const sortDef = getSortDef<TSort>(this.sort);
                    const filter = this.filter;
                    this.isLoadingInternal = true;
                    return this.callback(sortDef, filter, itemCount, this.options.pageSize, input).pipe(
                        map(result => {
                            const data = this.appendPage(currentState.data, result.data);
                            return {
                                data: data,
                                hasMore: result.hasMore,
                                hasAll: !filter && !result.hasMore,
                                filteredData: data,
                            };
                        }),
                        tap(() => this.isLoadingInternal = false),
                        catchError(() => {
                            this.hasErrorInternal = true;
                            this.isLoadingInternal = false;
                            // We can just leave the old data in place - scrolling again will refresh.
                            return EMPTY;
                        }),
                    );
                })
            )),
            shareReplay({ bufferSize: 1, refCount: true }),
        );

        return merge(baseState$, pagedData$);
    };

    private applySortAndFilter = (data: Readonly<TData[]>, sorter: MatSort | null, filter: TFilter | null): TData[] =>
        this.applySort(this.applyFilter(data, filter), sorter);

    private applyFilter = (data: Readonly<TData[]>, filter: TFilter | null): TData[] =>
        !filter ? [...data] : data.filter(obj => this.filterPredicate(obj, filter));

    private applySort = (data: TData[], sorter: MatSort | null): TData[] => {
        if (!sorter) return data;
        return this.sortData(data.slice(), sorter);
    };

    private appendPage = (existingData: Readonly<TData[]>, page: Readonly<TData[]>): TData[] =>
        existingData.filter(row => !page.some(pageRow => this.comparator(row, pageRow)))
            .concat(page);

    private mutateData = (state: AsyncTableState<TData>, data: TData[]): void =>
        this.stateSub.next({ ...state, data, filteredData: this.applySortAndFilter(data, this.sort, this.filter) });
}


export class AsyncTableDataSource<TData extends object, TSort extends string = string, TInput = void>
    extends AsyncDataSource<TData, string, TSort, TInput> {

    filterPredicate: (data: TData, filter: string) => boolean = defaultFilterPredicate;

    constructor(
        input$: Observable<TInput>,
        callback: AsyncTableDataCallback<TData, string, TSort, TInput>,
        comparator: (o1: TData, o2: TData) => boolean,
        options?: Partial<AsyncTableDataSourceOptions>,
    ) {
        super(input$, callback, comparator, options);
    }
}

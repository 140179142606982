<app-discussion-homepage [discussion]="discussion" readonly></app-discussion-homepage>

<div class="button-row">
    <app-status-button type="button" (buttonClick)="editSolution()" [disabled]="!meetingRunning">
        {{ "discussions.approvals.editSolution" | translate }}
    </app-status-button>
    <app-status-button type="button" (buttonClick)="markNoted()" [state]="buttonState"
        [disabled]="!meetingRunning">
        {{ "discussions.approvals.noted" | translate }}
    </app-status-button>
</div>
import { DialogModule } from "@angular/cdk/dialog";
import { OverlayModule } from "@angular/cdk/overlay";
import { PortalModule } from "@angular/cdk/portal";
import { NgModule } from "@angular/core";
import { MatCommonModule } from "@angular/material/core";
import { MatDialog, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogTitle } from "@angular/material/dialog";

import { WfDialog } from "./dialog";
import { WfDialogCloseButtonComponent } from "./dialog-close-button/dialog-close-button.component";
import { WfDialogContainerComponent } from "./dialog-container/dialog-container.component";

const DIRECTIVES = [
    WfDialogContainerComponent,
    WfDialogCloseButtonComponent,
    MatDialogClose,
    MatDialogTitle,
    MatDialogActions,
    MatDialogContent,
];

@NgModule({
    imports: [
        DialogModule,
        OverlayModule,
        PortalModule,
        MatCommonModule,
        ...DIRECTIVES,
    ],
    exports: [
        MatCommonModule,
        ...DIRECTIVES,
    ],
    providers: [
        WfDialog,
        {
            provide: MatDialog,
            useExisting: WfDialog
        },
    ],
})
export class WfDialogModule { }

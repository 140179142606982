import { MediaMatcher } from "@angular/cdk/layout";
import { Component, computed, OnDestroy, OnInit, Signal, ViewChild } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { PaymentApi } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";

import { AppUpdateService } from "~services/app-update.service";
import { TeamContext, UserContext } from "~services/contexts";
import { INotification, NotificationService } from "~services/notification.service";
import { PartnerStripeService, PartnerStripeStatus } from "~services/partner-stripe.service";
import { DirectSocketManager, SOCKET_MANAGER } from "~services/socket-manager.service";
import { UpdateNotificationOrchestrationService } from "~services/update-notification-orchestration.service";
import { StripeAccountStatus } from "~shared/enums";
import { getDarkModeCompanyLogoUrl } from "~shared/util/util";

@Component({
    selector: "app-full-layout",
    templateUrl: "full.component.html",
    styleUrls: ["./full.component.scss"],
    providers: [
        {
            provide: SOCKET_MANAGER,
            useClass: DirectSocketManager,
        },
        UpdateNotificationOrchestrationService,
    ]
})
export class FullComponent implements OnInit, OnDestroy {

    @ViewChild("snav") sideMenu?: MatSidenav;

    /**
     * The mode the sidebar is displayed. On larger screens, the sidebar appears next to
     * the content. On smaller screens, it appears over the content.
     */
    get sidebarMode(): "side" | "over" {
        return this.sidebarModeQuery.matches ? "side" : "over";
    }

    /**
     * Whether the header is the desktop variant (tall) or the mobile variant (short).
     * The mobile variant does not include the company
     */
    get mobileHeader(): boolean {
        return this.mobileHeaderQuery.matches;
    }

    /**
     * Whether the team select (and company logo) are displayed in the sidebar.
     * Normally they are displayed in the application header, but on very small screens
     * this appears in the sidebar instead.
     */
    get sidebarTeamSelect(): boolean {
        return this.sidebarTeamSelectQuery.matches;
    }

    readonly hasTeam = computed(() => !!this.teamContext.companyTeam());

    readonly companyLogoVisible: Signal<boolean>;
    readonly companyLogoUrl: Signal<string>;
    readonly showGlobalCreateButton = this.teamContext.features.globalCreateEnabled;

    private partnerNotification?: INotification;
    private updateNotification?: MatSnackBarRef<TextOnlySnackBar>;
    private subscriptionNotification?: MatSnackBarRef<TextOnlySnackBar>;

    private readonly subscriptions = new Subscription();
    private updateNotificationClickSubscription?: Subscription;
    private subscriptionNotificationClickSubscription?: Subscription;

    private readonly sidebarModeQuery: MediaQueryList;
    private readonly mobileHeaderQuery: MediaQueryList;
    private readonly sidebarTeamSelectQuery: MediaQueryList;

    constructor(
        media: MediaMatcher,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly partnerStripeService: PartnerStripeService,
        private readonly notificationService: NotificationService,
        private readonly paymentApi: PaymentApi,
        private readonly snackBar: MatSnackBar,
        private readonly translate: TranslateService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly appUpdateService: AppUpdateService,
        private readonly updateNotificationOrchestrationService: UpdateNotificationOrchestrationService,
    ) {
        this.sidebarModeQuery = media.matchMedia("(min-width: 991px)");
        this.mobileHeaderQuery = media.matchMedia("(max-width: 767px)");
        this.sidebarTeamSelectQuery = media.matchMedia("(max-width: 400px)");

        this.companyLogoVisible = computed(() =>
            this.teamContext.features.companyLogoEnabled() || this.teamContext.features.companyLogoTeased());

        this.companyLogoUrl = computed(() => getDarkModeCompanyLogoUrl(this.teamContext.company()));
    }

    ngOnInit() {
        this.subscriptions.add(this.activatedRoute.paramMap.pipe(
            map(params => {
                const companyId = params.get("companyId");
                const teamId = params.get("teamId");
                return companyId && teamId ? { companyId, teamId } : null;
            }),
            filter(Boolean),
        ).subscribe(ids => this.teamContext.setCompanyTeam(ids.companyId, ids.teamId, /* refresh: */ false)));

        this.subscriptions.add(this.partnerStripeService.status$.pipe(
            tap(() => this.partnerNotification?.close()),
            filter((p): p is PartnerStripeStatus => p !== null && p.status.initialised === true),
        ).subscribe(this.showStripeAccountNotification));

        this.subscriptions.add(this.teamContext.companyTeam$.subscribe(companyTeam => {
            this.updateSubscriptionNotificationState(companyTeam?.company.isSubscriptionOverdue);
        }));

        this.subscriptions.add(this.appUpdateService.updateAvailable$.subscribe(this.updateUpdateNotificationState));

        this.updateNotificationOrchestrationService.initialise();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.updateNotificationClickSubscription?.unsubscribe();
        this.updateNotification?.dismiss();
        this.subscriptionNotificationClickSubscription?.unsubscribe();
        this.subscriptionNotification?.dismiss();
    }

    toggleMenu = () => {
        if (!this.sideMenu) return;
        if (this.sideMenu.opened) {
            this.sideMenu.close();
        } else {
            this.sideMenu.open();
        }
    };

    private updateUpdateNotificationState = (updateAvailable: boolean) => {
        if (this.updateNotification) {
            if (!updateAvailable) {
                this.updateNotificationClickSubscription?.unsubscribe();
                this.updateNotification.dismiss();
                this.updateNotification = undefined;
            }
            return;
        }
        if (!updateAvailable) return; // We are already showing the banner. No need to show it again.

        this.updateNotificationClickSubscription?.unsubscribe();

        const notification = this.snackBar.open(
            this.translate.instant("updateAvailablePopup.message"),
            this.translate.instant("refresh"), {
            panelClass: "alert-snackbar"
        });
        this.updateNotificationClickSubscription = notification.onAction().subscribe(() =>
            this.appUpdateService.activateUpdate());
        this.updateNotification = notification;
    };

    private showStripeAccountNotification = ({ company, status }: PartnerStripeStatus) => {
        let notificationString: string;
        switch (status.status) {
            case StripeAccountStatus.created:
                notificationString = "stripeAccountPopup.created";
                break;
            case StripeAccountStatus.requirementsDue:
                notificationString = "stripeAccountPopup.requirementsDue";
                break;
            default:
                return;
        }
        const notification = this.notificationService.warning(notificationString, "stripeAccountPopup.title", false, true, true);
        notification.onClick().subscribe(() => this.partnerStripeService.openDashboard(company.id));
        this.partnerNotification = notification;
    };

    private updateSubscriptionNotificationState = (isSubscriptionOverdue: boolean | null | undefined) => {
        const showNotification = isSubscriptionOverdue === true && this.userContext.isBillingAdmin();
        if (this.subscriptionNotification) {
            if (!showNotification) {
                this.subscriptionNotificationClickSubscription?.unsubscribe();
                this.subscriptionNotification.dismiss();
                this.subscriptionNotification = undefined;
            }
            return;
        }
        if (!showNotification) {
            return;
        }
        this.subscriptionNotificationClickSubscription?.unsubscribe();
        const notification = this.snackBar.open(
            this.translate.instant("stripeSubscriptionPopup.message"),
            this.translate.instant("stripeSubscriptionPopup.updateBillingDetails"), {
            panelClass: "alert-snackbar"
        });
        this.subscriptionNotificationClickSubscription = notification.onAction().pipe(
            map(() => this.teamContext.company()?.id),
            filter((companyId): companyId is string => companyId !== null && companyId !== undefined),
            switchMap(companyId => this.paymentApi.getBillingPortalUrl(companyId))
        ).subscribe({
            next: result => {
                if (result.Url) document.location.href = result.Url;
            },
            error: () => {
                this.notificationService.errorUnexpected();
            },
        });
        this.subscriptionNotification = notification;
    };
}

<input #timeInput type="text" matInput
    [formControl]="inputField"
    (ngModelChange)="inputChanged($event)"
    [matAutocomplete]="auto" 
    [matAutocompleteConnectedTo]="getConnectedElement()"
    (blur)="onBlur(auto.isOpen)" 
    (keydown)="inputKeydown($event)"
    [attr.aria-describedby]="ariaDescribedBy" />

<mat-autocomplete #auto="matAutocomplete" 
    (optionSelected)="optionSelected($event.option)"
    (opened)="autoOpened()"
    (closed)="autoClosed()"
    [autoActiveFirstOption]="false">
    <mat-option *ngFor="let o of timeOptions" 
                [value]="o.value" 
                [ngClass]="{ 'option-active': shouldHighlight(o) }">
        {{ o.display }}
    </mat-option>
</mat-autocomplete>
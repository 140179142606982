import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, HostListener, Input, Optional, Self, ViewEncapsulation } from "@angular/core";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip, MatTooltipDefaultOptions } from "@angular/material/tooltip";

const getTooltipClass = (multiline: boolean): string[] => {
    const classes = ["wf-help-indicator-tooltip"];
    if (multiline) classes.push("wf-help-indicator-tooltip-multiline");
    return classes;
};

@Component({
    selector: "wf-help-indicator",
    templateUrl: "./help-indicator.component.html",
    styleUrls: ["./help-indicator.component.scss"],
    host: {
        class: "wf-help-indicator",
    },
    encapsulation: ViewEncapsulation.None,
    providers: [{
        provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
        useValue: {
            position: "after",
        } as MatTooltipDefaultOptions,
    }]
})
export class HelpIndicatorComponent {

    @Input() set multiline(value: BooleanInput) {
        this.multilineInternal = coerceBooleanProperty(value);
        this.updateTooltipClass();
    }

    get multiline(): boolean {
        return this.multilineInternal;
    }

    private multilineInternal = false;

    constructor(@Optional() @Self() private readonly tooltip: MatTooltip | null) {
        this.updateTooltipClass();
    }

    @HostListener("click") click = () => this.tooltip?.show();

    private updateTooltipClass = () => {
        if (this.tooltip) this.tooltip.tooltipClass = getTooltipClass(this.multiline);
    };
}

import { Injectable } from "@angular/core";
import { CurrentCompanyAndTeamDto, UserApi } from "@api";
import { catchError, combineLatest, EMPTY, filter, map, merge, Observable, of, shareReplay, takeUntil, tap } from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { buildRefreshableCompanyTeamCache, deleteCompanyCacheItem, deleteTeamCacheItem, getTeamCacheItem, ICompanyTeamCache, IRefreshableCompanyTeamCache, setTeamCacheItem } from "~shared/util/caching";
import { CompanyStore, TeamStore } from "~stores";

const STORAGE_CACHE_EXPIRY_MS = 5 * 1000; // 5 seconds;

@Injectable({
    providedIn: "root",
})
export class CompanyTeamRepository extends WithDestroy() {
    private readonly serverCache: IRefreshableCompanyTeamCache<CurrentCompanyAndTeamDto>;
    private readonly storageCache: ICompanyTeamCache<CurrentCompanyAndTeamDto | null> = {};

    constructor(
        private readonly userApi: UserApi,
        private readonly companyStore: CompanyStore,
        private readonly teamStore: TeamStore,
    ) {
        super();

        this.serverCache = buildRefreshableCompanyTeamCache(
            (companyId, teamId) => this.userApi.getCompanyAndTeamDetails(companyId, teamId).pipe(
                tap(this.saveCompanyTeam),
            ),
            this.destroyed$,
        );
    }

    getCompanyTeam = (companyId: string, teamId: string, force = false, includeCached = true): Observable<CurrentCompanyAndTeamDto> => {
        const fromServer = this.serverCache.getData(companyId, teamId, force);
        if (!includeCached) return fromServer;

        const fromStorage = this.getCompanyTeamFromStorage(companyId, teamId);

        return merge(
            fromServer,
            fromStorage.pipe(
                // If we don't find a company/team, we don't want to emit anything
                filter(Boolean),
                // If (somehow) we get the server response back first, use that one
                takeUntil(fromServer),
            ),
        );
    };

    invalidateCompany = (companyId: string) => {
        this.serverCache.invalidateAll(companyId);
        deleteCompanyCacheItem(this.storageCache, companyId);
    };

    private getCompanyTeamFromStorage = (companyId: string, teamId: string): Observable<CurrentCompanyAndTeamDto | null> => {
        let cacheItem = getTeamCacheItem(this.storageCache, companyId, teamId);
        if (!cacheItem) {
            cacheItem = this.getCompanyTeamFromStorageInternal(companyId, teamId).pipe(
                shareReplay({ bufferSize: 1, refCount: false }),
            );
            setTeamCacheItem(this.storageCache, companyId, teamId, cacheItem, STORAGE_CACHE_EXPIRY_MS);
        }
        return cacheItem;
    };

    private getCompanyTeamFromStorageInternal = (companyId: string, teamId: string): Observable<CurrentCompanyAndTeamDto | null> =>
        combineLatest({
            company: this.companyStore.getById(companyId),
            team: this.teamStore.getById(teamId),
        }).pipe(
            map(({ company, team }) => {
                if (!company || !team || team.companyId !== company.id) return null;
                return { company, team };
            }),
            catchError(() => of(null)),
        );

    private saveCompanyTeam = ({ company, team }: CurrentCompanyAndTeamDto) => {
        this.companyStore.update(company).pipe(catchError(() => EMPTY)).subscribe();
        this.teamStore.update(team).pipe(catchError(() => EMPTY)).subscribe();
        deleteTeamCacheItem(this.storageCache, company.id, team.id);
    };
}

<app-homepage-scaffold *ngIf="goal">
    <span *appHomepageTitle class="multiline-description">
        {{ goal.heading }}
    </span>

    <ng-template appHomepageTitleActions>
        <app-schedule-navigator [item]="goal" [schedule]="schedule$ | async" (selected)="selectSchedule($event)"></app-schedule-navigator>
    </ng-template>

    <ng-template appHomepageActions>
        <app-watch-item-button type="goal" [source]="goal" [isPrivate]="goal.isPrivate"></app-watch-item-button>
        <app-add-goal-child-action-button [source]="goal"></app-add-goal-child-action-button>
        <app-add-goal-child-discussion-button [source]="goal"></app-add-goal-child-discussion-button>
        <ng-container *ngIf="allowEdit">
            <button mat-icon-button type="button" [matMenuTriggerFor]="popupMenu">
                <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #popupMenu="matMenu" xPosition="before">
                <button mat-menu-item (click)="edit()" *ngIf="(access$ | async)?.canEdit; else viewButton">
                    <mat-icon color="primary">edit</mat-icon>
                    <span>{{'Edit' | translate}}</span>
                </button>
                <ng-template #viewButton>
                    <button mat-menu-item (click)="view()">
                        <mat-icon color="primary">visibility</mat-icon>
                        <span>{{'View' | translate}}</span>
                    </button>
                </ng-template>
                <button mat-menu-item (click)="delete()" [disabled]="!(access$ | async)?.canDelete">
                    <mat-icon color="warn">delete</mat-icon>
                    <span>{{'Delete' | translate}}</span>
                </button>
            </mat-menu>
        </ng-container>
    </ng-template>
    
    <section class="wf-section">
        <dl>
            <div class="full-width" *ngIf="goal.description">
                <dt translate="goals.description"></dt>
                <dd>
                    <span class="multiline-description">{{ goal.description }}</span>
                </dd>
            </div>
            <div>
                <dt translate="goals.owner"></dt>
                <dd>
                    <app-delegated-user-display [entity]="goal" primary>
                        {{ getUserName(goal.owner) }}
                        <wf-private-indicator *ngIf="goal.isPrivate"></wf-private-indicator>
                    </app-delegated-user-display>
                </dd>
            </div>
            <div>
                <dt translate="goals.dueDate"></dt>
                <dd>{{ goal.dueDateLocal | dateFormat }}</dd>
            </div>
            <div>
                <dt translate="goals.team"></dt>
                <dd>{{ goal.team.name }} ({{ goal.company.name }})</dd>
            </div>
            <div *ngIf="goal.department">
                <dt translate="goals.department"></dt>
                <dd>{{ goal.department.name }}</dd>
            </div>
            <div *ngIf="goal.category" class="sub-list">
                <div>
                    <dt translate="goals.category"></dt>
                    <dd>{{ goal.category.description }}</dd>
                </div>
                <div *ngIf="goal.category.subCategory">
                    <dt translate="goals.subCategory"></dt>
                    <dd>{{ goal.category.subCategory.description }}</dd>
                </div>
            </div>
        </dl>
    </section>

    <section wf-expandable-section [label]="'homepage.details' | translate">
        <dl>
            <div>
                <dt translate="goals.planningStatus"></dt>
                <dd>
                    <wf-planning-status [status]="goal.goalStatus"></wf-planning-status>
                </dd>
            </div>
            <div>
                <dt translate="goals.captureFrequency"></dt>
                <dd>{{ getUpdateScheduleDescription(goal.scheduleDefinition) }}</dd>
            </div>
            <div *ngIf="goal.annualGoal" class="sub-list">
                <div>
                    <dt translate="goals.annualBusinessGoal"></dt>
                    <dd>{{ goal.annualGoal.heading }}</dd>
                </div>
                <div *ngIf="goal.strategy">
                    <dt translate="goals.strategy"></dt>
                    <dd>{{ goal.strategy.description }}</dd>
                </div>
            </div>
        </dl>
    </section>

    <section wf-expandable-section [label]="'goals.status' | translate">
        <ng-template wfExpandableSectionActions>
            <app-goal-status-summary [goal]="goal"></app-goal-status-summary>
        </ng-template>
        <app-goal-records-table class="table-container" [records]="records$ | async" (recordUpdated)="recordUpdated($event)"
            [selectedWeek]="isRecord(goal) ? goal.week : null">
        </app-goal-records-table>
    </section>

    <ng-container *ngIf="actions$ | async; let actions">
        <section wf-expandable-section [label]="'homepage.relatedActions' | translate" *ngIf="actions.length" @fadeIn>
            <app-related-actions-table *wfExpandableSectionBody [actions]="actions"></app-related-actions-table>
        </section>
    </ng-container>

    <ng-container *ngIf="discussions$ | async; let discussions">
        <section wf-expandable-section [label]="'homepage.relatedDiscussions' | translate" *ngIf="discussions.length" @fadeIn>
            <app-related-discussions-table *wfExpandableSectionBody [discussions]="discussions"></app-related-discussions-table>
        </section>
    </ng-container>

</app-homepage-scaffold>
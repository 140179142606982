<form (submit)="addComment()" [formGroup]="form">
    <mat-form-field class="no-label" subscriptSizing="dynamic">
        <input type="text" matInput formControlName="comment" name="comment"
            [placeholder]="'FeedsList.listActions.addComment' | translate" cdkFocusInitial />
        <span class="context-indicator" matTextSuffix *ngIf="displayContextHint" @fadeIn>
            {{ contextHint }}
        </span>
        <button matSuffix mat-icon-button color="primary" type="submit"
            [appMatVisualDisable]="!form.valid || !commentControl.value">
            <mat-icon>send</mat-icon>
        </button>
    </mat-form-field>
</form>
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable, OnDestroy } from "@angular/core";
import { CurrentCompanyDto, CurrentUserDto, UserApi } from "@api";
import { Intercom, IntercomBootInput } from "@supy-io/ngx-intercom";
import { catchError, map, Observable, of, Subscription, switchMap } from "rxjs";

import { Profile, Role } from "~shared/enums";
import { getProfileRoles } from "~shared/util/profile-helper";

import { TeamContext, UserContext } from "./contexts";

@Injectable({
    providedIn: "root"
})
export class IntercomService implements OnDestroy {

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly intercom: Intercom,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly userApi: UserApi,
    ) { }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.intercom.shutdown();
    }

    initialise = () => {
        this.subscriptions.add(this.userContext.user$.pipe(
            switchMap(user => this.getUserCompany(user).pipe(
                map(company => ({ user, company })),
            )),
        ).subscribe(({ user, company }) => this.handleUserCompany(user, company)));
    };

    private getUserCompany = (user: CurrentUserDto | null): Observable<CurrentCompanyDto | null> => {
        if (!user) return of(null);

        return this.teamContext.companyTeam$.pipe(
            map(companyTeam => companyTeam?.company ?? null),
            switchMap(company => {
                if (!getProfileRoles(Profile.partner).includes(user.roleName as Role) ||
                    company?.id === user.companyId) {
                    return of(company);
                }
                // Only add partners and super admins to their home company.
                // Prevents admins showing up in any company they access.
                if (!user.companyId) return of(null);
                return this.userApi.getCompanyDetails(user.companyId).pipe(
                    catchError(() => of(null)),
                );
            }),
        );
    };

    private handleUserCompany = (user: CurrentUserDto | null, company: CurrentCompanyDto | null) => {
        if (!user || !company) {
            this.intercom.shutdown();
            return;
        }

        this.intercom.boot({
            user_id: user.id,
            user_hash: user.intercomHash,
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            role: user.roleName,
            company: {
                id: company.id,
                name: company.name,
                plan: company.planTier.code,
                clientId: company.clientId,
            }
            // As we supply some extra properties, we cast as the input to avoid errors.
        } as unknown as IntercomBootInput);
    };
}

import { CurrentCompanyDto } from "@api";

import { DATE_FORMATS } from "./constants";

const TIME_FORMAT_24H = "HH:mm";
const TIME_FORMAT_12H = "h:mm a";
const FALLBACK_DATE_FORMAT = "dd MMM yy";

const constructDateTimeFormat = (dateFormat: string, timeFormat: string): string =>
    `${dateFormat} ${timeFormat}`;

export const getTimeFormat = (company: CurrentCompanyDto | null): string => {
    switch (company?.settings.timeFormat) {
        case 24:
            return TIME_FORMAT_24H;
        case 12:
        default:
            return TIME_FORMAT_12H;
    }
};

export const getDateFormat = (company: CurrentCompanyDto | null): string =>
    company?.settings.dateFormat || FALLBACK_DATE_FORMAT;

export const getDateTimeFormat = (company: CurrentCompanyDto | null): string =>
    constructDateTimeFormat(getDateFormat(company), getTimeFormat(company));

/**
 * Converts the configured date format into one that can be understood moment.js.
 * Note: this will enforce the date format is a known format, as some legacy formats including
 * day of week strings are not able to be understood.
 *
 * This mostly involves converting the lower-case "d"s and "y"s to upper-case "D"s and "Y"s.
 *
 * @param format The format as configured for the current company
 * @returns The fixed date format that can be understood by moment.js.
 */
export const fixDateFormatForMoment = (format: string): string => {
    if (!DATE_FORMATS.includes(format)){
        return "DD/MM/YYYY";
    }
    return format.replace(/d/g, "D").replace(/y/g, "Y");
};

export const getMomentDateFormat = (company: CurrentCompanyDto | null): string =>
    fixDateFormatForMoment(getDateFormat(company));

export const getMomentDateTimeFormat = (company: CurrentCompanyDto | null): string =>
    constructDateTimeFormat(getMomentDateFormat(company), getTimeFormat(company));

<div [formGroup]="form">
    <div class="row">
        <div class="col-sm-12 recurrence-container">
            <ng-template #simpleSchedule>
                <div class="recurrence-section">
                    <div class="field-desc">{{ "recurrence.every" | translate }}</div>
                    <mat-form-field>
                        <mat-select formControlName="dayOfWeek" aria-label="{{ 'recurrence.day' | translate }}">
                            <mat-option *ngFor="let d of dayOptions" [value]="d">
                                {{ getDayOfWeekNameKey(d) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-template>
            <ng-container *ngIf="scheduleVisible; else simpleSchedule">
                <div class="recurrence-section">
                    <div class="field-desc">{{ "recurrence.every" | translate }}</div>
                    <ng-container *ngIf="scheduleEnabled; else fakeType">
                        <mat-form-field>
                            <mat-select formControlName="interval" aria-label="{{ 'recurrence.interval' | translate }}">
                                <mat-option *ngFor="let i of intervalOptions" [value]="i">
                                    {{ i }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-select formControlName="type" aria-label="{{ 'recurrence.type' | translate }}">
                                <mat-option *ngFor="let t of recurrenceTypes" [value]="t">
                                    {{ (intervalControl.value === 1 ? 
                                        getRecurrenceTypeNameKey(t) : 
                                        getRecurrenceTypePluralNameKey(t)) | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </ng-container>
                    <ng-template #fakeType>
                        <mat-form-field [matTooltip]="'featureUnavailable.inputTooltip' | translate" matTooltipPosition="right" matTooltipClass="feature-tooltip">
                            <input matInput type="text" [attr.aria-label]="'recurrence.type' | translate" [value]="'recurrenceType.week' | translate" disabled />
                            <mat-icon svgIcon="wf-stars-complete" matSuffix class="feature-stars"></mat-icon>
                        </mat-form-field>
                    </ng-template>
                </div>
                <div class="recurrence-section" *ngIf="isMonthly() && scheduleEnabled">
                    <div class="field-desc">{{ "recurrence.onThe" | translate }}</div>
                    <mat-form-field>
                        <mat-select formControlName="index" aria-label="{{ 'recurrence.index' | translate }}">
                            <mat-option *ngFor="let i of indexOptions" [value]="i">
                                {{ getIndicesNameKey(i) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select formControlName="dayOfWeek" aria-label="{{ 'recurrence.day' | translate }}" panelWidth="">
                            <mat-option *ngFor="let d of dayOptions" [value]="d">
                                {{ getDayOfWeekNameKey(d) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="recurrence-section" *ngIf="isWeekly() || !scheduleEnabled">
                    <div class="field-desc">{{ "recurrence.on" | translate }}</div>
                    <mat-form-field>
                        <mat-select formControlName="dayOfWeek" aria-label="{{ 'recurrence.day' | translate }}" panelWidth="">
                            <mat-option *ngFor="let d of dayOptions" [value]="d">
                                {{ getDayOfWeekNameKey(d) | translate }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="recurrence-section" *ngIf="intervalControl.value > 1 && scheduleEnabled">
                    <mat-form-field class="recurrence-date">
                        <mat-label>{{ "recurrence.next" | translate }}</mat-label>
                        <mat-select formControlName="nextScheduled" panelWidth="">
                            <mat-option *ngFor="let date of nextScheduledDateOptions" [value]="date">
                                {{ date | dateFormat }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </ng-container>
        </div>
    </div>
</div>
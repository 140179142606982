<h4 mat-dialog-title>
    <wf-translate-template key="performance.outstandingActionsFor"
        [params]="{ teamName: team.name, financialYear, quarter, week }">
        <span *wfTransTplContent="'teamName'; let content" class="team-name">{{ content }}</span>
    </wf-translate-template>
</h4>

<mat-dialog-content>
    <table mat-table class="wf-table" matSort [dataSource]="dataSource" matSortActive="dueDate" matSortDirection="asc" matSortDisableClear wfTableFocus>
    
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                {{'actions.description' | translate}}
            </th>
            <td mat-cell *matCellDef="let element" class="wf-column-heading">
                <a class="multiline-description" (click)="viewAction(element)" wfEmptyLink>
                    {{element.description }}
                </a>
                <app-origin-table-button *ngIf="element.origin" [origin]="element.origin"></app-origin-table-button>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef>
                <app-table-user-filter [team]="team" (selected)="applyOwnerFilter($event)" withSortHeader>
                    {{'actions.owner' | translate}}
                </app-table-user-filter>
            </th>
            <td mat-cell *matCellDef="let element">
                <app-delegated-user-display [entity]="element">
                    {{ getUserName(element.owner) }}
                    <wf-private-indicator *ngIf="element.isPrivateAction"></wf-private-indicator>
                </app-delegated-user-display>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'actions.dueDate' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                {{element.dueDateLocal | dateFormat}}
            </td>
        </ng-container>
    
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'actions.status' | translate}}
            </th>
            <td mat-cell *matCellDef="let element">
                <app-action-progress [progress]="element.progress"></app-action-progress>
            </td>
        </ng-container>
    
        <ng-container matColumnDef="options">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
                <app-feed-button (openFeed)="openFeed(element)" [reference]="element.feedPartition"></app-feed-button>
            </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let element; let i = index; columns: columns;" [wfRowFocus]="element"
            [wfRowFocusIndex]="i">
        </tr>
        <tr class="mat-mdc-row" *matNoDataRow>
            <td [attr.colspan]="columns.length">
                <span class="no-records">
                    {{'NoRecordsFound.noRecordsFound!' | translate}}
                </span>
            </td>
        </tr>
    </table>
</mat-dialog-content>

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetReportDto, PlanReportsApi } from "@api";

import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";

@Component({
    selector: "app-delete-report-dialog",
    templateUrl: "./delete-report-dialog.component.html",
    styleUrls: ["./delete-report-dialog.component.scss"]
})
export class DeleteReportDialogComponent {
    buttonState: ButtonState;

    get description(): string {
        return this.report.description ?? "";
    }

    get isRecurring(): boolean {
        return this.report.isRecurring;
    }

    constructor(
        private readonly planReportsApi: PlanReportsApi,
        private readonly dialogRef: MatDialogRef<DeleteReportDialogComponent, boolean>,
        private readonly notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) private readonly report: GetReportDto,
    ) { }

    static open(dialog: MatDialog, report: GetReportDto) {
        return dialog.open<DeleteReportDialogComponent, GetReportDto, boolean>(
            DeleteReportDialogComponent, {
            width: "500px",
            data: report
        });
    }

    delete = () => {
        if (this.buttonState) return;

        this.buttonState = "loading";

        this.planReportsApi.deleteReport(
            this.report.company.id,
            this.report.team.id,
            toFiscalQuarter({ financialYear: this.report.financialYear, quarter: this.report.planningPeriod }),
            this.report.id
        ).subscribe({
            next: () => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(true);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };
}

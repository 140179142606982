import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";

import { GoalDto } from "../../components/goal-homepage/goal-homepage.component";
import { HomepageDialog, HomepageEvent } from "../homepage-dialog.interface";
import { buildHomepageDialogConfig, HomepageDialogOptions } from "../homepage-dialog-shared";

interface GoalHomepageDialogData<TGoal extends GoalDto> {
    goal: TGoal;
    allowEdit?: boolean;
}

type GoalHomepageDialogOptions = HomepageDialogOptions & {
    allowEdit?: boolean;
};

@Component({
    selector: "app-goal-homepage-dialog",
    templateUrl: "./goal-homepage-dialog.component.html",
    styleUrls: ["./goal-homepage-dialog.component.scss"]
})
export class GoalHomepageDialogComponent<TGoal extends GoalDto> implements HomepageDialog<TGoal>, OnDestroy {

    readonly events$: Observable<HomepageEvent<TGoal>>;

    goal: TGoal;

    readonly allowEdit: boolean;

    private readonly initialGoal: TGoal;

    private readonly eventsInternal = new Subject<HomepageEvent<TGoal>>();

    constructor(
        private readonly dialogRef: MatDialogRef<GoalHomepageDialogComponent<TGoal>>,
        @Inject(MAT_DIALOG_DATA) data: GoalHomepageDialogData<TGoal>,
    ) {
        this.events$ = this.eventsInternal.asObservable();
        this.initialGoal = data.goal;
        this.goal = data.goal;
        this.allowEdit = data.allowEdit ?? false;
    }


    static open<TGoal extends GoalDto>(dialog: MatDialog, goal: TGoal, options?: GoalHomepageDialogOptions) {
        return dialog.open<GoalHomepageDialogComponent<TGoal>, GoalHomepageDialogData<TGoal>>(
            GoalHomepageDialogComponent,
            {
                data: { goal, allowEdit: options?.allowEdit },
                ...buildHomepageDialogConfig(options),
            });
    }

    ngOnDestroy(): void {
        this.eventsInternal.complete();
    }

    goalUpdated = (goal: TGoal) => {
        this.goal = goal;

        // Check if the dialog scope has changed from when initially opened.
        // If it differs from the original scope, we no longer should emit change events.
        // This may occur if we move between quarters for a goal (for example)
        if (this.initialGoal.id !== goal.id) return;
        // If the initial goal is scoped to a week (e.g. it is a record) the subsequent goal must be scoped to the same week
        if ("week" in this.initialGoal && (!("week" in goal) || this.initialGoal.week !== goal.week)) return;

        this.eventsInternal.next({ type: "updated", item: goal });
    };

    goalDeleted = (goal: TGoal | undefined) => {
        this.eventsInternal.next({ type: "deleted", item: goal ?? this.goal });
        this.dialogRef.close();
    };
}

import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, defer, map, Observable, startWith, Subscription, switchMap, tap } from "rxjs";

import { CustomLocalizationRepository } from "~repositories";

import { TeamContext } from "./contexts";

@Injectable({
    providedIn: "root",
})
export class CustomLocalizationService implements OnDestroy {

    private isInitialized = false;
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly teamContext: TeamContext,
        private readonly customLocalizationRepository: CustomLocalizationRepository,
        private readonly translateService: TranslateService,
    ) { }

    initialise = () => {
        if (this.isInitialized) return;
        const lang$ = defer(() => this.translateService.onLangChange.pipe(
            map(langChange => langChange.lang),
            startWith(this.translateService.currentLang),
        ));
        this.subscriptions.add(combineLatest({
            ct: this.teamContext.companyTeam$,
            lang: lang$,
        }).pipe(
            switchMap(({ ct, lang }) => {
                const resetObs$ = this.resetTranslations(lang);
                // If we don't have a company set, just reset to default translations.
                if (!ct) return resetObs$;
                // Otherwise, reset to default translations while loading language customizations.
                // Note: we wait until the reset is complete before merging in translations - otherwise
                // they may be overwritten.
                return combineLatest({
                    reset: resetObs$,
                    customLoc: this.customLocalizationRepository.getCustomLocalizations(ct.company.id),
                }).pipe(
                    tap(({ customLoc }) => this.translateService.setTranslation(lang, customLoc, /* shouldMerge: */ true)),
                );
            }),
        ).subscribe());
        this.isInitialized = true;
    };

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    private readonly resetTranslations = (lang: string): Observable<any> =>
        defer(() => this.translateService.getTranslation(lang));
}

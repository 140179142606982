export const getObjectFromEntries = <TKey extends string>(entries: [TKey, unknown][]): Partial<Record<TKey, unknown>> | null => {
    if (!entries.length) return null;
    const obj = {} as Partial<Record<TKey, unknown>>;
    for (const [key, value] of entries) {
        obj[key] = value;
    }
    return obj;
};

type AtLeastOne<T> = [T, ...T[]];

export const exhaustiveStringTuple = <T extends string>() =>
  <L extends AtLeastOne<T>>(
        ...x: L extends any ? (
      Exclude<T, L[number]> extends never ?
      L :
      Exclude<T, L[number]>[]
    ) : never
    ) => x;

export const cloneAndOmit = <TBase extends object, TItem extends TBase>(
    item: TItem,
    propsToOmit: Readonly<(Exclude<keyof TItem, keyof TBase>)[]>,
): TBase => getObjectFromEntries(
    Object.entries(item)
        .filter(e => !(propsToOmit as readonly string[]).includes(e[0]))) as TBase;

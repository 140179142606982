@for (data of weekData(); track data.week) {
    @if (data.record) {
        <span class="wf-goal-status-summary-cell" [class.wf-goal-status-summary-cell-current]="data.week === latestWeek()"
            [ngClass]="getCellClass(data)" [wfTemplateTooltip]="tooltip" matTooltipClass="light-tooltip"
            tabindex="0">
            <span class="cdk-visually-hidden">
                <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
            </span>
        </span>
        <ng-template #tooltip>
            <dl>
                <div>
                    <dt translate="period.week"></dt>
                    <dd>{{ data.week }}</dd>
                </div>
                <div>
                    <dt translate="goals.status"></dt>
                    <dd>
                        <app-goal-status [status]="data.record.customStatus ?? data.record.status"></app-goal-status>
                    </dd>
                </div>
            </dl>
        </ng-template>
    } @else {
        <span class="wf-goal-status-summary-cell wf-goal-status-summary-cell-empty" aria-hidden="true"></span>
    }
}
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EntityReferenceDetailsDto } from "@api";
import { BehaviorSubject, map, Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { TeamContext } from "~services/contexts";
import { LinkedItemViewer } from "~services/linked-item-viewer.service";
import { WithDestroy } from "~shared/mixins";
import { IOriginDetails } from "~shared/util/origin-builder";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getEntityTypeNameKey } from "~shared/util/translation-helper";

declare type Origin = IOriginDetails | EntityReferenceDetailsDto;

const getOriginCompanyTeam = (origin: Origin): { companyId: string; teamId: string } => {
    if ("companyId" in origin) return { companyId: origin.companyId, teamId: origin.teamId };
    return { companyId: origin.company.id, teamId: origin.team.id };
};

@Component({
    selector: "app-origin-header",
    templateUrl: "./origin-header.component.html",
    styleUrls: ["./origin-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OriginHeaderComponent extends WithDestroy() {

    @Input({ required: true }) set origin(value: Origin | undefined) {
        this.originSubject.next(value);
    }

    get origin(): Origin | undefined {
        return this.originSubject.value;
    }

    get originNameKey(): string {
        if (!this.origin) return "";
        return getEntityTypeNameKey(this.origin.type, this.teamContext.settings.useAucbgMenus());
    }

    get heading(): string {
        return this.origin?.heading || this.origin?.description || "";
    }

    get description(): string {
        return !!this.origin?.heading && this.origin.description || "";
    }

    get canView(): boolean {
        return !!this.origin && this.linkedItemViewer.canView(this.origin);
    }

    readonly hasAccess$: Observable<boolean>;

    private originSubject = new BehaviorSubject<Origin | undefined>(undefined);

    constructor(
        private readonly teamContext: TeamContext,
        private readonly accessService: AccessService,
        private readonly linkedItemViewer: LinkedItemViewer,
    ) {
        super();

        this.hasAccess$ = this.originSubject.pipe(
            switchMap(origin => {
                if (!origin) return of(false);
                const { companyId, teamId } = getOriginCompanyTeam(origin);
                return this.accessService.getAccessState(companyId, teamId).pipe(
                    map(a => a.canRead),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    openOrigin = () => {
        const origin = this.origin;
        if (!origin || !this.linkedItemViewer.canView(origin)) return;
        this.linkedItemViewer.view(origin);
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_origin: Origin;
}

    <div class="chart-layout">
        
        <button mat-icon-button [disabled]="loadingPrevious" (click)="previous()"
        matTooltip="{{ 'numbers.chart.includePrev' | translate }}">
        <mat-icon>chevron_left</mat-icon>
        </button>

        <div class="chart-container">
            <canvas #chart></canvas>
        </div>

        <button mat-icon-button [disabled]="loadingNext" (click)="next()"
            matTooltip="{{ 'numbers.chart.includeNext' | translate }}">
            <mat-icon>chevron_right</mat-icon>
        </button>
    </div>

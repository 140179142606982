<mat-form-field [appearance]="appearance">
    <mat-label>{{ label }}</mat-label>
    <input matInput [formControl]="inputField" [matAutocomplete]="auto" (blur)="onBlur(auto.isOpen)" name="userEmail"/>

    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getUserOrEmailDisplay" (optionSelected)="optionSelected($event.option)" (closed)="autoClosed()">
        <mat-option *ngFor="let user of filteredUsers$ | async" [value]="user">
            {{ getUserName(user) }}
        </mat-option>
    </mat-autocomplete>

    <mat-hint *ngIf="hint">
        {{ hint }}
    </mat-hint>

    <mat-error *ngIf="inputField.hasError('email')">
        Invalid Email Address
    </mat-error>
</mat-form-field>
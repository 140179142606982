<div mat-dialog-title>
    <div class="header-row">
        <h4>
            <wf-translate-template [key]="titleKey">
                <span *wfTransTplContent="'number'" class="number-description">{{ number.description }}</span>
                <ng-container *wfTransTplContent="'week'">{{ week }}</ng-container>
            </wf-translate-template>
        </h4>
        <div class="mode-container" *ngIf="availableModes.length > 1">
            <mat-button-toggle-group [formControl]="modeControl" class="icon-toggle-group">
                <mat-button-toggle *ngFor="let mode of availableModes" [value]="mode" [matTooltip]="getModeBreakdownKey(mode) | translate">
                    <mat-icon color="primary">{{ getModeIcon(mode) }}</mat-icon>
                </mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <button type="button" mat-icon-button (click)="refresh()" color="accent"
            [title]="'refresh' | translate" [disabled]="isLoading">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</div>

<mat-dialog-content>
    @if (hasError) {
        <app-error-card (retry)="refresh()" [message]="loadFailedMessageKey | translate">
        </app-error-card>
    } @else {
        <div class="table-container">
            <app-generic-numbers-table [items]="sources$ | async" [columns]="displayedColumns$ | async"
                showTrendButton showChildActions="false" readonly>
                <app-table-settings-menu [columnDefinitions]="(columnDefinitions$ | async) ?? []"
                    [tableSettings]="tableSettings$ | async" (tableSettingsChange)="tableUpdated($event)">
                </app-table-settings-menu>
            </app-generic-numbers-table>
            <app-table-spinner *ngIf="isLoading" hasData></app-table-spinner>
        </div>
    }
</mat-dialog-content>

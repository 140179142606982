<form [formGroup]="form" (submit)="rescheduleMeeting()">
    <h4 mat-dialog-title>{{'NewMeetingStartScreen.rescheduleMeeting' | translate}}</h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{'NewMeetingStartScreen.reason' | translate}}</mat-label>
                    <textarea matInput class="reason-textarea" name="reason" formControlName="reason"
                        autocomplete="off" maxlength="500" required></textarea>
                    <mat-error *ngIf="reasonControl.hasError('required')">
                        {{'NewMeetingStartScreen.reasonRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field class="datePickerBox">
                    <mat-label>{{'NewMeetingStartScreen.meetingPostponeDate' | translate}}</mat-label>
                    <input type="text" matInput name="date" formControlName="date"
                        [min]="weekStartDate" [max]="weekEndDate"
                        [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()"
                        autocomplete="off" required />
                    <mat-error *ngIf="dateControl.hasError('required')">
                        {{'NewMeetingStartScreen.dateRequired' | translate}}
                    </mat-error>
                </mat-form-field>
                <mat-datepicker #picker></mat-datepicker>
            </div>
        </div>

        <div class="row date_fields">
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'NewMeetingStartScreen.startTime' | translate}}</mat-label>
                    <app-time-picker name="startTime" formControlName="startTime" required></app-time-picker>
                    <mat-error *ngIf="startTimeControl.hasError('required')">
                        {{'NewMeetingStartScreen.startTimeRequired' | translate}}
                    </mat-error>
                    <mat-error *ngIf="startTimeControl.errors?.time">
                        {{'NewMeetingStartScreen.invalidTime' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'NewMeetingStartScreen.endTime' | translate}}</mat-label>
                    <app-time-picker name="endTime" formControlName="endTime" required></app-time-picker>
                    <mat-error *ngIf="endTimeControl.hasError('required')">
                        {{'NewMeetingStartScreen.endTimeRequired' | translate}}
                    </mat-error>
                    <mat-error *ngIf="endTimeControl.hasError('timeGreaterThan')">
                        {{'NewMeetingStartScreen.endTimeMustBeGreater' | translate}}
                    </mat-error>
                    <mat-error *ngIf="endTimeControl.errors?.time">
                        {{'NewMeetingStartScreen.invalidTime' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <app-status-button type="submit" [state]="buttonState" [visualDisable]="!form.valid">
            {{ "submit" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
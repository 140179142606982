<form [formGroup]="form" (submit)="submit()">
    <h4 mat-dialog-title>{{ 'numbers.copyBackwards.title' | translate }} - <span class="number-description">{{ description }}</span></h4>

    @if (schedule$ | async; as schedule) {
        @if (schedule === "loading") {
            <mat-dialog-content>
                <div class="loading-container">
                    <mat-spinner [diameter]="40"></mat-spinner>
                </div>
            </mat-dialog-content>
        } @else if (schedule === "error") {
            <mat-dialog-content>
                <app-error-card (retry)="retry()"
                    [message]="'numbers.copyBackwards.loadScheduleFailed' | translate">
                </app-error-card>
            </mat-dialog-content>
        } @else {
            <p mat-dialog-content class="fixed-content" translate="numbers.copyBackwards.subtitle"></p>
            <mat-dialog-content class="table-content">
                <section class="wf-section">
                    <h4 class="wf-section-heading">{{ 'numbers.copyBackwards.currentSchedule' | translate }}</h4>
                    <div class="schedule-container">
                        <table mat-table class="wf-table" [dataSource]="schedule">
                            <ng-container matColumnDef="year">
                                <th mat-header-cell *matHeaderCellDef>{{ 'numbers.copyBackwards.year' | translate }}</th>
                                <td mat-cell *matCellDef="let element">{{ element.financialYear }}</td>
                            </ng-container>
                            <ng-container matColumnDef="quarter">
                                <th mat-header-cell *matHeaderCellDef>{{ 'numbers.copyBackwards.quarter' | translate }}</th>
                                <td mat-cell *matCellDef="let element">{{ element.planningPeriod }}</td>
                            </ng-container>
                            <tr mat-header-row *matHeaderRowDef="columns"></tr>
                            <tr mat-row *matRowDef="let row; columns: columns"></tr>
                        </table>
                    </div>
                </section>
            </mat-dialog-content>
            <mat-dialog-content class="fixed-content">
                <mat-form-field>
                    <mat-label translate="numbers.copyBackwards.targetQuarter"></mat-label>
                    <wf-quarter-field name="period" formControlName="period"
                        [companyId]="companyId" [max]="maxPeriod" required>
                    </wf-quarter-field>
                    <mat-error *ngIf="periodControl.hasError('required')"
                        translate="numbers.copyBackwards.targetQuarterRequired"></mat-error>
                    <mat-error *ngIf="periodControl.hasError('conflict')"
                        translate="numbers.copyBackwards.targetQuarterConflict"></mat-error>
                </mat-form-field>
            </mat-dialog-content>
            <mat-dialog-actions align="end">
                <app-status-button type="submit" [state]="buttonState" [visualDisable]="!form.valid">
                    {{ "numbers.copyBackwards.title" | translate }}
                </app-status-button>
            </mat-dialog-actions>
        }
    }
</form>
import { Injectable } from "@angular/core";
import { EntityReferenceDetailsDto, EntityReferenceDto } from "@api";

declare type EntityReference = EntityReferenceDetailsDto | EntityReferenceDto;

@Injectable()
export abstract class LinkedItemViewer {
    abstract canView(source: EntityReference): boolean;
    abstract view(source: EntityReference): void;
}

<mat-icon *ngIf="isPopular" class="popular" svgIcon="wf-most-popular-banner"
    [attr.aria-label]="'planPricing.mostPopular' | translate"></mat-icon>

<h3>{{ getPlanName() }}</h3>
<mat-icon class="plan-icon" [svgIcon]="icon"></mat-icon>

<ng-container *ngIf="priceMode === 'display'">
    <div *ngIf="price; else noPrice" class="price-section">
        <div class="amount">
            {{ priceAmount | currency: price.currency.toUpperCase() : 'symbol' : '1.0' }}
        </div>
        <div class="per" translate="planPricing.perUserPerMonth"></div>
        <div class="billing-info" [translate]="price.amount === 0 ? 'planPricing.noCost' : 'planPricing.billingInfo'">
        </div>
    </div>
    <ng-template #noPrice>
        <p class="custom-plan" translate="planPricing.customPlan"></p>
    </ng-template>
</ng-container>

<div class="selection-container" *ngIf="selectionMode === 'display'">
    <div class="current-plan" *ngIf="isCurrent; else changePlanTpl" @fadeInOut>
        {{ currentBannerLabel ?? ('planPricing.currentPlan' | translate) }}
    </div>
    <ng-template #changePlanTpl>
        <button mat-flat-button type="button" color="primary" class="change-plan"
            *ngIf="plan.isAvailable && price; else changePlanLinkTpl" (click)="confirmPlanChange()"
            [disabled]="disabled" @fadeInOut>
            {{ selectButtonLabel ?? ('planPricing.getStarted' | translate) }}
        </button>
    </ng-template>
    <ng-template #changePlanLinkTpl>
        <a mat-flat-button color="primary" class="change-plan"
            *ngIf="changePlanLink && !plan.isAvailable; else contactUsTpl" [attr.href]="changePlanLink"
            [disabled]="disabled" @fadeInOut>
            {{ selectButtonLabel ?? ('planPricing.getStarted' | translate) }}
        </a>
    </ng-template>
    <ng-template #contactUsTpl>
        <a [attr.href]="isEnterprise ? 'https://www.workfacta.com/enterprise/#contact' : 'https://www.workfacta.com/planchange/'"
            mat-flat-button color="primary" class="contact" [disabled]="disabled" @fadeInOut>
            {{ 'planPricing.contactUs' | translate }}
        </a>
    </ng-template>
</div>

<div class="plan-info" *ngIf="infoMode === 'display'">
    <ng-container *ngIf="!isEnterprise; else learnMore">
        <p>
            <ng-container *ngIf="(getFeatureConstraint('userCount') ?? 0) > 1">
                {{ 'planPricing.upTo' | translate }}
            </ng-container>
            {{ getFeatureConstraintDescription('userCount') }}
        </p>
        <p>
            <ng-container *ngIf="(getFeatureConstraint('teamCount') ?? 0) > 1">
                {{ 'planPricing.upTo' | translate }}
            </ng-container>
            {{ getFeatureConstraintDescription('teamCount') }}
        </p>
    </ng-container>
    <ng-template #learnMore>
        <a href="https://www.workfacta.com/enterprise/" translate="planPricing.learnMore"
            [attr.tabindex]="disabled ? -1 : undefined"></a>
    </ng-template>
</div>

<p class="description" *ngIf="descriptionMode === 'display'">
    {{ descriptionKey | translate }}
</p>

<div class="features" *ngIf="featureMode === 'verbose'">
    <h4 translate="planPricing.includes"></h4>
    <ul>
        <li *ngFor="let constraint of constraints">
            {{ getFeatureConstraintDescription(constraint) }}
        </li>
        <ng-container *ngFor="let availability of availabilities">
            <li *ngIf="shouldShowAvailability(availability)">
                {{ getFeatureAvailabilityNameKey(availability) | translate }}
            </li>
        </ng-container>
    </ul>
</div>
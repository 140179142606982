<mat-select #multiSelect [formControl]="inputField" multiple (selectionChange)="selectedOptionsChange($event.value)" disableOptionCentering panelClass="mat-select-panel-bottom" [compareWith]="compareWith">
    <mat-select-trigger>
        <mat-chip-listbox>
            <mat-chip-option *ngFor="let option of selectedOptions$ | async" [removable]="true" (removed)="removeOption(option)" [disabled]="disabled || isOptionDisabled(option)">
                {{ getOptionDisplay(option) }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-option>
        </mat-chip-listbox>
    </mat-select-trigger>
    <mat-option *ngFor="let option of options$ | async" [value]="getOptionValue(option)" [disabled]="isOptionDisabled(option)">
        @if (optionContent && optionContent.template) {
            <ng-container *ngTemplateOutlet="optionContent.template; context: $any({$implicit: option})"></ng-container>
        } @else {
            {{ getOptionDisplay(option)}}
        }
    </mat-option>
</mat-select>

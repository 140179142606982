<h2 mat-dialog-title translate="discussions.delete.title"></h2>
<p mat-dialog-content translate="discussions.delete.confirmation" [translateParams]="{ heading: heading }"></p>

<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" [mat-dialog-close]>
        {{ "Cancel" | translate }}
    </button>
    <app-status-button type="submit" [state]="buttonState" (buttonClick)="delete()">
        {{ "Delete" | translate }}
    </app-status-button>
</mat-dialog-actions>
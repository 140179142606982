import { sortNumber } from "./sorters";

interface ItemRecord {
    week: number;
}

interface ItemWithRecords<TRecord extends ItemRecord = ItemRecord> {
    collectionPeriodCount: number;
    records: TRecord[];
}

/**
 * Get the latest week for the relevant data. This is normally the collection
 * period count, but if a "week 14" is present, it will include that, too.
 *
 * @param item The item being summarised.
 * @returns The latest week for that item.
 */
export const getLatestWeek = (item: ItemWithRecords): number => {
    const weeks = item.records.map(record => record.week)
        .sort(sortNumber.descending());
    const maxListedWeek = weeks[0] ?? 0;
    return Math.max(maxListedWeek, item.collectionPeriodCount);
};

export interface ExpandedWeekData<TRecord extends ItemRecord> {
    readonly week: number;
    readonly record: TRecord | null;
}

export const expandWeekData = <TRecord extends ItemRecord>(
    item: ItemWithRecords<TRecord>,
): ExpandedWeekData<TRecord>[] => {
    const latestWeek = getLatestWeek(item);

    return new Array(latestWeek).fill(0).map((_, i) => {
        const week = i + 1;
        const record = item.records.find(r => r.week === week) ?? null;
        return {
            week,
            record,
        };
    });
};

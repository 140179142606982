import { FillerControllerDatasetOptions, LineControllerDatasetOptions } from "chart.js";
import { DeepPartial } from "chart.js/types/utils";
import * as color from "color";

import { secondaryGray, secondaryGreen,workfactaBlue } from "./colors";

export const resultColor = workfactaBlue;
export const targetColor = secondaryGreen;
export const averageColor = secondaryGray;

export const getBackgroundColor = (lineColor: string) => color(lineColor).alpha(0.2).string();

export const resultBgColor = getBackgroundColor(resultColor);
export const targetBgColor = getBackgroundColor(targetColor);
export const averageBgColor = getBackgroundColor(averageColor);

export declare type LineOptions = DeepPartial<LineControllerDatasetOptions & FillerControllerDatasetOptions>;

export const resultLineStyleCore: LineOptions = {
    backgroundColor: resultBgColor,
    borderColor: resultColor,
    borderWidth: 2,
};

export const resultLineStyle: LineOptions = {
    ...resultLineStyleCore,
    fill: true
};

export const targetLineStyle: LineOptions = {
    backgroundColor: targetBgColor,
    borderColor: targetColor,
    borderWidth: 2,
    borderDash: [10, 5],
    fill: true
};

export const averageLineStyle: LineOptions = {
    backgroundColor: averageBgColor,
    borderColor: averageColor,
    borderWidth: 2,
    borderDash: [0, 6],
    borderCapStyle: "round",
    fill: false
};

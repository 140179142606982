import { trigger } from "@angular/animations";
import { AfterViewInit, Component, EventEmitter, HostBinding, Input, Output } from "@angular/core";
import { PlanTierPaymentDto, PlanTierPriceDto } from "@api";
import { TranslateService } from "@ngx-translate/core";

import {
    FeatureAvailability, FeatureConstraintExtended, getFeatureAvailabilityNameKey,
    getFeatureConstraintDescription, getPlanSizeIcon, getPlanTierDescriptionKey,
    isConstraint, isFeatureAvailable, PlanSize, PlanTierCode
} from "~plan-shared";
import { FeatureStatus } from "~shared/enums";
import { defaultAnimationTiming, fadeInAnimationBuilder } from "~shared/util/animations";
import { getPlanName } from "~shared/util/translation-helper";


const constraintsToShow: FeatureConstraintExtended[] = [
    "userCount", "goalCount", "actionCount", "teamCount", "numberCount", "openIssueCount", "widgetCount"
];

const availabilitiesToShow: FeatureAvailability[] = [
    "flexibleScheduling", "recurringActions", "annualPlanning", "enterpriseWidgets", "companyPerformance",
    "enterprisePerformance", "numberApi", "externalIntegrations"
];

export type SectionDisplayMode = "none" | "display";
export type FeatureDisplayMode = "none" | "verbose";

@Component({
    selector: "app-plan-pricing",
    templateUrl: "./plan-pricing.component.html",
    styleUrls: ["./plan-pricing.component.scss"],
    animations: [
        trigger("fadeInOut", fadeInAnimationBuilder(defaultAnimationTiming)),
    ]
})
export class PlanPricingComponent implements AfterViewInit {

    @Input() plan!: PlanTierPaymentDto;
    @HostBinding("class.wf-plan-current") @Input() isCurrent = false;
    @Input() featureMode: FeatureDisplayMode = "verbose";
    @Input() priceMode: SectionDisplayMode = "display";
    @Input() selectionMode: SectionDisplayMode = "display";
    @Input() infoMode: SectionDisplayMode = "display";
    @Input() descriptionMode: SectionDisplayMode = "display";

    @Input() selectButtonLabel: string | undefined;
    @Input() currentBannerLabel: string | undefined;
    @Input() changePlanLink: string | undefined;

    @Input() disabled = false;

    @Output() changePlanClicked = new EventEmitter<void>();

    @HostBinding("@.disabled")
    disableAnimations = true;

    get price(): PlanTierPriceDto | null {
        return this.plan.prices[0] ?? null;
    }

    get priceAmount(): number | null {
        if (!this.price) return null;
        // Amount is in cents, change to dollars
        return this.price.amount / 100;
    }

    @HostBinding("class")
    get planClass(): string {
        switch (this.plan.size) {
            case PlanSize.xSmall:
                return "wf-plan-pricing-xsmall";
            case PlanSize.small:
                return "wf-plan-pricing-small";
            case PlanSize.medium:
                return "wf-plan-pricing-medium";
            case PlanSize.large:
                return "wf-plan-pricing-large";
            case PlanSize.xLarge:
                return "wf-plan-pricing-xlarge";
            default:
                return "wf-plan-pricing-large";
        }
    }

    get icon(): string {
        return getPlanSizeIcon(this.plan.size);
    }

    get isPopular(): boolean {
        return this.plan.code === PlanTierCode.businessPremium;
    }

    get descriptionKey(): string {
        return getPlanTierDescriptionKey(this.plan.code as PlanTierCode);
    }

    get isEnterprise(): boolean {
        // We take the user to the enterprise contact form if the plan is not selectable.
        // For normally selectable plans, we use the regular contact form.
        return this.plan.code === PlanTierCode.enterprise;
    }

    readonly constraints = constraintsToShow;
    readonly availabilities = availabilitiesToShow;

    constructor(
        private readonly translate: TranslateService,
    ) { }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    getFeatureConstraint = (feature: FeatureConstraintExtended): number | undefined =>
        isConstraint(feature) ? this.plan.featureConstraints[feature] : undefined;

    getFeatureConstraintDescription = (feature: FeatureConstraintExtended) =>
        getFeatureConstraintDescription(this.translate, this.plan, feature);

    getFeatureAvailabilityNameKey = (feature: FeatureAvailability): string => {
        if (feature === "flexibleScheduling" && this.plan.featureAvailability[feature] !== FeatureStatus.enabled) {
            return "planFeatures.availabilities.weeklyScheduling";
        }
        return getFeatureAvailabilityNameKey(feature);
    };

    getPlanName = () => getPlanName(this.translate, this.plan);

    shouldShowAvailability = (availability: FeatureAvailability) =>
        isFeatureAvailable(this.plan, availability) || availability === "flexibleScheduling";

    confirmPlanChange = () => {
        this.changePlanClicked.emit();
    };
}

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";

import { WfDialogModule } from "~shared/dialog";

import * as components from "./components";
import * as dialogs from "./dialogs";
import * as directives from "./directives";

@NgModule({
    declarations: [
        components.PlanComparisonComponent,
        components.PlanComparisonTableComponent,
        components.PlanUpgradeButtonComponent,
        components.PlanPricingComponent,
        directives.PlanTooltipDirective,

        dialogs.NumberCappedDialogComponent,
        dialogs.NumberExceededDialogComponent,
        dialogs.TeamCappedDialogComponent,
        dialogs.UserCappedDialogComponent,
        dialogs.WidgetCappedDialogComponent,
        dialogs.IssuesCappedDialogComponent,
        dialogs.NewsCappedDialogComponent,
        dialogs.ReportExceededDialogComponent,
        dialogs.ReportCappedDialogComponent,

        components.NumberExceededHeaderComponent,
        components.FakeScheduleFieldComponent,
        components.FeatureCapDisplayComponent,
        components.ReportExceededHeaderComponent,
    ],
    imports: [
        CommonModule,
        TranslateModule,
        RouterModule,

        MatButtonModule,
        MatIconModule,
        WfDialogModule,
        MatRippleModule,
        MatInputModule,
        MatFormFieldModule,
        MatTooltipModule,
    ],
    exports: [
        components.PlanComparisonComponent,
        components.PlanComparisonTableComponent,
        components.PlanUpgradeButtonComponent,
        components.PlanPricingComponent,
        directives.PlanTooltipDirective,

        components.NumberExceededHeaderComponent,
        components.FakeScheduleFieldComponent,
        components.FeatureCapDisplayComponent,
        components.ReportExceededHeaderComponent,
    ]
})
export class PlanSharedModule { }

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GoalImprovementRequestDto, GoalSuggestionDto, PlanningSuggestionsApi } from "@api";
import { BehaviorSubject, catchError, delay, EMPTY, map, Observable, of, startWith, switchMap, tap } from "rxjs";

import { environment } from "~/environments/environment";
import { NotificationService } from "~services/notification.service";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";

export interface SuggestGoalImprovementDialogData {
    companyId: string;
    teamId: string;
    goal: GoalImprovementRequestDto;
}

export interface SuggestGoalImprovementDialogResult {
    suggestion: GoalSuggestionDto;
}

@Component({
    selector: "app-suggest-goal-improvement-dialog",
    templateUrl: "./suggest-goal-improvement-dialog.component.html",
    styleUrls: ["./suggest-goal-improvement-dialog.component.scss"],
})
export class SuggestGoalImprovementDialogComponent extends WithDestroy() {

    readonly goal: GoalImprovementRequestDto;
    readonly suggestion$: Observable<GoalSuggestionDto | null>;

    noSuggestion = false;

    private readonly companyId: string;
    private readonly teamId: string;

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(
        private readonly planningSuggestionsApi: PlanningSuggestionsApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<SuggestGoalImprovementDialogComponent, SuggestGoalImprovementDialogResult>,
        @Inject(MAT_DIALOG_DATA) data: SuggestGoalImprovementDialogData,
    ) {
        super();

        this.goal = data.goal;
        this.companyId = data.companyId;
        this.teamId = data.teamId;

        this.suggestion$ = this.refreshSubject.pipe(
            tap(() => this.noSuggestion = false),
            switchMap(() => this.getSuggestion().pipe(
                startWith(null),
            )),
            catchError(() => {
                this.dialogRef.close();
                this.notificationService.errorUnexpected();
                return EMPTY;
            }),
            map(suggestion => {
                if (!suggestion) return suggestion;
                if (!suggestion.heading ||
                    !suggestion.description ||
                    (
                        suggestion.heading === this.goal.heading &&
                        suggestion.description === this.goal.description
                    )) {
                    this.noSuggestion = true;
                    return null;
                }
                return suggestion;
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    static open(dialog: MatDialog, data: SuggestGoalImprovementDialogData) {
        return dialog.open<SuggestGoalImprovementDialogComponent,
            SuggestGoalImprovementDialogData,
            SuggestGoalImprovementDialogResult>(
                SuggestGoalImprovementDialogComponent,
                {
                    width: "600px",
                    data,
                });
    }

    apply = (suggestion: GoalSuggestionDto) => this.dialogRef.close({ suggestion });

    private getSuggestion = (): Observable<GoalSuggestionDto> => {
        if (environment.localSuggestions) {
            return of({
                heading: this.goal.heading,
                description: this.goal.description ?? "",
            }).pipe(
                delay(1000),
            );
        }
        return this.planningSuggestionsApi.getGoalImprovementSuggestion(this.companyId, this.teamId, this.goal);
    };

}

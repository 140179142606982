import { Directive, ElementRef, Input } from "@angular/core";

import { BaseScrollListenerDirective } from "./base-scroll-listener.directive";

@Directive({
    selector: "[wfScrollListener]"
})
export class ScrollListenerDirective extends BaseScrollListenerDirective {

    get itemCount(): number | undefined {
        return this.itemCountInternal;
    }

    @Input() set itemCount(value: number | undefined){
        this.itemCountInternal = value;
        this.onViewChange(this.calculateVisibleRange());
    }

    protected itemCountInternal: number | undefined;

    constructor(
        element: ElementRef<HTMLElement>,
    ) {
        super(element);
    }

    protected getItemCount = (): number | undefined => this.itemCount;
}

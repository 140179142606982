import { MeetingReminderType } from "~shared/enums";

export const getMeetingReminderTypeNameKey = (type: MeetingReminderType): string => {
    switch (type) {
        case MeetingReminderType.updateReminder: return "meetingReminderType.updateReminder";
        case MeetingReminderType.overdueUpdateReminder: return "meetingReminderType.overdueUpdateReminder";
        case MeetingReminderType.managementNotificationLateUpdates: return "meetingReminderType.managementNotificationLateUpdates";
        case MeetingReminderType.meetingAgenda: return "meetingReminderType.meetingAgenda";
        case MeetingReminderType.teamNotificationLateUpdates: return "meetingReminderType.teamNotificationLateUpdates";
        default: return "";
    }
};

export const getMeetingReminderTypeDescriptionKey = (type: MeetingReminderType, lineManagerEnabled: boolean): string => {
    switch (type) {
        case MeetingReminderType.updateReminder:
            return "meetingReminderType.description.updateReminder";
        case MeetingReminderType.overdueUpdateReminder:
            return "meetingReminderType.description.overdueUpdateReminder";
        case MeetingReminderType.managementNotificationLateUpdates:
            return lineManagerEnabled ?
                "meetingReminderType.description.managementNotificationLateUpdatesLineManager" :
                "meetingReminderType.description.managementNotificationLateUpdates";
        case MeetingReminderType.meetingAgenda:
            return "meetingReminderType.description.meetingAgenda";
        case MeetingReminderType.teamNotificationLateUpdates:
            return lineManagerEnabled ?
                "meetingReminderType.description.teamNotificationLateUpdatesLineManager" :
                "meetingReminderType.description.teamNotificationLateUpdates";
    }
};

import { GetCustomGoalStatusDto } from "@api";

import { GoalStatus } from "~shared/enums";

export declare type IGoalStatus = GoalStatus | GetCustomGoalStatusDto;

export interface StatusWithNotes {
    status: IGoalStatus;
    notes?: string;
};

const statusOptions =
    [GoalStatus.updateRequired, GoalStatus.offTarget, GoalStatus.onTarget, GoalStatus.complete, GoalStatus.cancelled];

export const getStatusOptions = (cancellationEnabled: boolean): GoalStatus[] =>
    cancellationEnabled ? statusOptions : statusOptions.filter(s => s !== GoalStatus.cancelled);

export const isStandardStatus = (status: IGoalStatus): status is GoalStatus =>
    typeof status === "number";

export const isCustomStatus = (status: IGoalStatus): status is GetCustomGoalStatusDto =>
    !isStandardStatus(status);

export const getUnderlyingStatus = (status: IGoalStatus): GoalStatus =>
    isCustomStatus(status) ? status.status : status;

export const areStatusesEqual = (o1: IGoalStatus, o2: IGoalStatus) => {
    if (isStandardStatus(o1)) {
        return o1 === o2;
    }
    if (isStandardStatus(o2)) return false;
    return o1.id === o2.id;
};

export const augmentCustomStatuses = (customStatuses: GetCustomGoalStatusDto[], cancellationEnabled: boolean): IGoalStatus[] => {
    const missingStatuses = getStatusOptions(cancellationEnabled).filter(s => !customStatuses.some(cs => cs.status === s));
    return [...missingStatuses, ...customStatuses];
};

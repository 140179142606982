/* eslint-disable max-classes-per-file */
import { NumberFeedApi, NumberFeedContext } from "@api";

import { GenericFeedAdapter } from "./feed.adapter";
import { FeedReference } from "./feed.common";

type NumberScope = readonly [companyId: string, teamId: string, period: string, numberId: string];

export class NumberFeedAdapter extends GenericFeedAdapter<NumberScope> {

    protected get scope() {
        return [
            this.reference.context.companyId,
            this.reference.context.teamId,
            this.reference.context.period,
            this.reference.context.numberId,
        ] as const;
    }

    constructor(
        readonly reference: Readonly<FeedReference<NumberFeedContext>>,
        api: NumberFeedApi,
    ) {
        super(api);
    }
}

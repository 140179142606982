/* eslint-disable max-classes-per-file */
import { HttpErrorResponse } from "@angular/common/http";
import { Injectable, InjectionToken, OnDestroy } from "@angular/core";
import { SocketApi } from "@api";
import {
    BehaviorSubject, catchError, distinctUntilChanged, EMPTY, filter, map, Observable, of, share, Subject, Subscription, switchMap, tap,
    throwError
} from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { retryWithDelay } from "~shared/util/caching";
import { withRefresh } from "~shared/util/rx-operators";
import { createSocket, GenericWebSocket } from "~shared/util/socket-helper";
import { SocketEvent, SocketRequest } from "~workers/update-events-shared";

import { UserContext } from "./contexts";

export interface SocketManager {
    readonly messages$: Observable<unknown>;

    initialise(): void;
    reconnect(): void;
}

export const SOCKET_MANAGER = new InjectionToken<SocketManager>("SOCKET_MANAGER");

const NEGOTIATE_RETRY_DELAY_MS = 60 * 1000; // 60 seconds

interface NegotiateSocketEvent {
    readonly userId: string;
    readonly socketUrl: string;
    readonly deferSubscribe: boolean;
}

@Injectable()
abstract class BaseSocketManager extends WithDestroy() implements OnDestroy, SocketManager {

    readonly messages$: Observable<unknown>;

    protected readonly socketResponse$: Observable<NegotiateSocketEvent | null>;

    protected readonly reconnectSubject = new BehaviorSubject<void>(undefined);
    protected readonly connectedSubject = new Subject<void>();
    protected readonly messageSubject = new Subject<unknown>();
    protected readonly subscriptions = new Subscription();

    protected deferSubscribe = false;

    constructor(
        private readonly userContext: UserContext,
        private readonly socketApi: SocketApi,
    ) {
        super();
        this.messages$ = this.messageSubject.asObservable();
        this.socketResponse$ = this.userContext.user$.pipe(
            distinctUntilChanged((a, b) => a?.id === b?.id),
            withRefresh(this.reconnectSubject),
            switchMap(user => !user ? of(null) : this.socketApi.negotiate().pipe(
                map(response => ({ userId: user.id, socketUrl: response.uri, deferSubscribe: response.deferSubscribe })),
                catchError(err => {
                    if (err instanceof HttpErrorResponse && err.status === 404) {
                        return of(null);
                    }
                    return throwError(() => err);
                }),
                retryWithDelay(NEGOTIATE_RETRY_DELAY_MS),
            )),
            tap(response => this.deferSubscribe = !!response && response.deferSubscribe),
        );
    }

    initialise(): void {
        this.subscriptions.add(this.connectedSubject.pipe(
            filter(() => this.deferSubscribe),
            switchMap(() => this.socketApi.subscribe().pipe(
                retryWithDelay(),
            )),
            tap(() => this.deferSubscribe = false),
        ).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    reconnect() {
        this.reconnectSubject.next();
    }
}

@Injectable()
export class DirectSocketManager extends BaseSocketManager implements OnDestroy, SocketManager {

    constructor(
        userContext: UserContext,
        socketApi: SocketApi,
    ) {
        super(userContext, socketApi);
    }

    initialise() {
        super.initialise();

        const socket$ = this.socketResponse$.pipe(
            switchMap(response => !response ? EMPTY : new Observable<GenericWebSocket>(subscriber => {
                const socket = createSocket(response.socketUrl);
                subscriber.next(socket);
                return () => socket.close();
            })),
            share(),
        );

        this.subscriptions.add(socket$.pipe(
            switchMap(socket => socket.messages$),
        ).subscribe(message => this.messageSubject.next(message)));

        this.subscriptions.add(socket$.pipe(
            switchMap(socket => socket.connected$),
        ).subscribe(() => this.connectedSubject.next()));
    }
}

const mapSocketResponse = (response: NegotiateSocketEvent | null): SocketRequest =>
    !response ? { type: "disconnect" } : { type: "connect", ...response };

@Injectable()
export class SharedSocketManager extends BaseSocketManager implements OnDestroy, SocketManager {

    private worker: SharedWorker | null = null;

    constructor(
        userContext: UserContext,
        socketApi: SocketApi,
    ) {
        super(userContext, socketApi);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.worker?.port.close();
    }

    initialise(): void {
        super.initialise();
        if (this.worker) return;

        this.worker = new SharedWorker(new URL("~/workers/update-events.worker", import.meta.url),
            {
                type: "module",
            });

        this.worker.port.onmessage = (event) => this.handleMessage(event);
        this.worker.port.start();

        this.subscriptions.add(this.socketResponse$.pipe(
            map(mapSocketResponse),
        ).subscribe(event => this.worker?.port.postMessage(event)));
    }

    reconnect() {
        this.worker?.port.postMessage({ type: "disconnect" });
        super.reconnect();
    }

    private handleMessage = (message: MessageEvent<SocketEvent>) => {
        switch (message.data.type) {
            case "connected":
                this.connectedSubject.next();
                break;
            case "message":
                this.messageSubject.next(message.data.message);
                break;
        }
    };

}

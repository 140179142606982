import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { catchError, debounceTime, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { ReportRecordDetailDto, UpdateReportsApi } from "~/api";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { ReportStateService } from "~services/state";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { ReportAttachmentsDialogComponent } from "~shared/dialogs";
import { ReportCollectionType } from "~shared/enums";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";

@Component({
    selector: "app-report-upload-button",
    templateUrl: "./report-upload-button.component.html",
    styleUrls: ["./report-upload-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportUploadButtonComponent implements OnInit, OnDestroy {

    @Input({ required: true }) set record(value: ReportRecordDetailDto | undefined) {
        this.recordInternal = value;
        this.updatedControl.reset(this.recordInternal?.externallyUpdated ?? false, { emitEvent: false });
        this.updateControlDisabledState();
    }

    get record(): ReportRecordDetailDto | undefined {
        return this.recordInternal;
    }

    @Input() set disabled(value: BooleanInput) {
        this.disabledInternal = coerceBooleanProperty(value);
        this.updateControlDisabledState();
    }

    get disabled(): boolean {
        return this.disabledInternal;
    }

    get isExternallyUpdated(): boolean {
        return !!this.record && this.record.collectionType === ReportCollectionType.externallyUpdated;
    }

    @Output() updated = new EventEmitter<ReportRecordDetailDto>();

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        CommonFunctions.setLoader(value);
        this.isLoadingInternal = value;
    }

    get statusNotesEnabled() {
        return this.teamContext.features.statusNotesEnabled();
    }

    get hasNote(): boolean {
        return !!this.record?.notes;
    }

    readonly updatedControl = new FormControl<boolean>(false, { nonNullable: true });

    private recordInternal?: ReportRecordDetailDto;
    private isLoadingInternal = false;
    private disabledInternal = false;

    private subscriptions = new Subscription();

    constructor(
        private readonly updateReportsApi: UpdateReportsApi,
        private readonly reportStateService: ReportStateService,
        private readonly notificationService: NotificationService,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.updatedControl.valueChanges.pipe(
            tap(() => this.isLoading = true),
            debounceTime(50),
            switchMap(this.updateReport),
        ).subscribe(() => this.isLoading = false));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    showReports = () => {
        if (!this.record) return;
        ReportAttachmentsDialogComponent.open(this.dialog, this.record).afterClosed()
            .subscribe(result => result ? this.updated.emit(result) : undefined);
    };

    private updateControlDisabledState = () => {
        const value = this.recordInternal;
        if (!this.disabled && value && value.canEdit && value.collectionType === ReportCollectionType.externallyUpdated) {
            this.updatedControl.enable({ emitEvent: false });
        } else {
            this.updatedControl.disable({ emitEvent: false });
        }
    };

    private updateReport = (updated: boolean): Observable<unknown> => {
        const record = this.record;
        if (!record || record.collectionType !== ReportCollectionType.externallyUpdated) return of(null);
        const { company, team } = getDelegatedItemCompanyTeam(record);
        return this.updateReportsApi.markExternallyUpdated(
            company.id,
            team.id,
            toFiscalQuarter({ financialYear: record.financialYear, quarter: record.planningPeriod }),
            record.week,
            record.id,
            { updated },
        ).pipe(
            tap(updatedRecord => {
                if (this.record && this.record.id === updatedRecord.id && this.record.week === updatedRecord.week) {
                    this.record.externallyUpdated = updatedRecord.externallyUpdated;
                    this.record.notes = updatedRecord.notes;
                }
                this.reportStateService.notifyUpdate(updatedRecord);
                this.updated.emit(updatedRecord);
                this.updatedControl.reset(updatedRecord.externallyUpdated, { emitEvent: false });
            }),
            catchError(() => {
                this.notificationService.errorUnexpected();
                this.updatedControl.reset(this.record?.externallyUpdated ?? false, { emitEvent: false });
                return of(null);
            }),
        );
    };
}

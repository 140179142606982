import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Optional, Output, ViewChild } from "@angular/core";
import {
    AbstractControl, ControlValueAccessor, FormControl, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgForm,
    NonNullableFormBuilder, ValidatorFn
} from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import {
    AddActionDto, DiscussionAndSolutionDto, GetActionDto, GetSolutionDto, SimpleCompanyTeamDto, SimpleUserDto, TeamReferenceDto,
    UpdateActionDto, UpdateSolutionDto
} from "@api";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { TeamSettingsRepository, UserRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { EditActionDialogComponent, SuggestSolutionActionsDialogComponent } from "~shared/dialogs";
import { IAddActionForSolutionResult } from "~shared/dialogs/edit-action-dialog/edit-action-dialog.component";
import { DiscussionType, SolutionApprovalActionType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { defaultAnimationTiming, fadeInAnimationBuilder } from "~shared/util/animations";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getSolutionApprovalActionTypeNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

declare type SolutionSection = "solution" | "actions" | "approval";

export interface ActionPendingCreation {
    type: "create";
    companyId: string;
    teamId: string;
    action: AddActionDto;
}

export interface ActionPendingUpdate {
    type: "update";
    originalAction: GetActionDto;
    action: UpdateActionDto;
}

export interface UnchangedAction {
    type: "unchanged";
    action: GetActionDto;
}

export declare type SolutionAction =
    ActionPendingCreation | ActionPendingUpdate | UnchangedAction;

export interface SolutionWithActions {
    solution: UpdateSolutionDto;
    actions: SolutionAction[];
    deletedActions: GetActionDto[];
}

const hasActionsValidator = (actionCountFn: () => number): ValidatorFn =>
    (control: AbstractControl<boolean>) => {
        // Either we have selected "no actions required" or we have at least one action.
        if (control.value || actionCountFn()) return null;
        return { noActions: true };
    };

@Component({
    selector: "app-solution-form",
    templateUrl: "./solution-form.component.html",
    styleUrls: ["./solution-form.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder(defaultAnimationTiming)),
    ],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => SolutionFormComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            useExisting: forwardRef(() => SolutionFormComponent),
            multi: true
        }
    ]
})
export class SolutionFormComponent extends WithDestroy() implements OnInit, OnDestroy, ControlValueAccessor {

    @Input() set discussion(value: DiscussionAndSolutionDto | null) {
        if (value === this.discussion) return;

        this.discussionSubject.next(value);
        if (value?.solution) this.bindSolutionForm(value.solution);
    }

    get discussion() {
        return this.discussionSubject.value;
    }

    @Input() set actions(value: GetActionDto[]) {
        if (!value) return;
        this.mergeActions(value);
        this.onValueChanged(this.getValueInternal());
    }

    @Input() set value(value: SolutionWithActions | null) {
        if (value) this.bindValue(value);
    }

    get value(): SolutionWithActions | null {
        return this.getValueInternal();
    }

    @Input() set disabled(value: boolean) {
        value = coerceBooleanProperty(value);
        this.disabledSubject.next(value);
        if (value) {
            this.form.disable({ emitEvent: false });
        } else {
            this.form.enable({ emitEvent: false });
        }
    }

    get disabled(): boolean {
        return this.form.disabled;
    }

    @Input() set expandAll(value: boolean) {
        value = coerceBooleanProperty(value);
        if (!value) return;
        this.expand("actions");
        this.expand("approval");
        this.expand("solution");
    }

    @Output() valueChange = new EventEmitter<SolutionWithActions>();

    @ViewChild(MatSort) set sort(value: MatSort) {
        this.actionsDataSource.sort = value;
    }

    actionChanges: SolutionAction[] = [];

    readonly rootCauseControl = this.fb.control<string | null>(null);
    readonly discussionNotesControl = this.fb.control<string | null>(null);
    readonly solutionControl = this.fb.control<string | null>(null);

    readonly noActionRequiredControl = this.fb.control(false, [
        hasActionsValidator(() => this.actionChanges?.length)
    ]);

    readonly approvingTeamControl = this.fb.control<TeamReferenceDto | null>(null);

    readonly form = new FormGroup({
        rootCause: this.rootCauseControl,
        discussionNotes: this.discussionNotesControl,
        solution: this.solutionControl,
        noActionRequired: this.noActionRequiredControl,
        approvingTeam: this.approvingTeamControl
    });

    readonly actionsDataSource = new MatTableDataSource<SolutionAction>();
    readonly actionsColumns = ["description", "owner", "dueDate", "options"];

    readonly getSolutionApprovalActionTypeNameKey = getSolutionApprovalActionTypeNameKey;

    get isChallenge(): boolean {
        return this.discussion?.type === DiscussionType.challenge;
    }

    get actionCount(): number | undefined {
        if (this.noActionRequiredControl.value) return undefined;
        return this.actionChanges.length;
    }

    get showActionError(): boolean {
        return this.errorStateMatcher.isErrorState(this.noActionRequiredControl, this.parentFormGroup || this.parentForm);
    }

    readonly approvingTeams$: Observable<(SimpleCompanyTeamDto | undefined)[]>;
    readonly approvalsSectionVisible$: Observable<boolean>;

    readonly suggestionsEnabled: boolean;

    private deletedActions: GetActionDto[] = [];

    private onChangedCallback?: (_: SolutionWithActions) => void;
    private onTouchedCallback?: () => void;

    private users: SimpleUserDto[] = [];

    private readonly expandedSections = new Set<SolutionSection>(["solution"]);

    private readonly subscriptions = new Subscription();
    private readonly discussionSubject = new BehaviorSubject<DiscussionAndSolutionDto | null>(null);
    private readonly disabledSubject = new BehaviorSubject(false);

    constructor(
        private readonly userRepository: UserRepository,
        private readonly teamSettingsRepository: TeamSettingsRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly translate: TranslateService,
        private readonly fb: NonNullableFormBuilder,
        private readonly dialog: MatDialog,
        private readonly errorStateMatcher: ErrorStateMatcher,
        @Optional() private readonly parentForm: NgForm,
        @Optional() private readonly parentFormGroup: FormGroupDirective,
    ) {
        super();

        const approvalsEnabled = this.teamContext.features.approvalsEnabled();

        this.approvingTeams$ = combineLatest({
            discussion: this.discussionSubject.pipe(filter(Boolean)),
            disabled: this.disabledSubject,
        }).pipe(
            switchMap(({ discussion, disabled }) => {
                // If disabled, return an empty list, but do not process as if approvals are disabled
                if (disabled) return of([]);
                const teams$ = !approvalsEnabled ? of([])
                    : this.teamSettingsRepository.getApprovingTeams(discussion.company.id, discussion.team.id);
                return teams$.pipe(
                    tap(this.availableApprovingTeamsChanged),
                    // Add in the "No Approval Required" option
                    map(approvingTeams => approvingTeams.length ? [undefined, ...approvingTeams] : []),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.approvalsSectionVisible$ = this.discussionSubject.pipe(
            switchMap(discussion => {
                if (!discussion || !approvalsEnabled) return of(false);
                if (discussion.solution?.approvalHistory.length) return of(true);
                return this.approvingTeams$.pipe(
                    map(approvingTeams => !!approvingTeams.length)
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.suggestionsEnabled = this.teamContext.features.discussionSuggestionsEnabled() ||
            this.userContext.isSuperAdmin() && this.teamContext.features.superAdminDiscussionSuggestionsEnabled();

        this.actionsDataSource.sortingDataAccessor = (data, header) => {
            switch (header) {
                case "description": return this.getSolutionActionDescription(data);
                case "owner": return this.getSolutionActionOwner(data);
                case "dueDate": return this.getSolutionActionDueDate(data);
                default: return "";
            }
        };
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.form.valueChanges.pipe(map(this.getValueInternal)).subscribe(this.onValueChanged)
        );
        this.subscriptions.add(combineLatest({
            discussion: this.discussionSubject.pipe(filter(Boolean)),
            disabled: this.disabledSubject,
        }).pipe(
            map(({ discussion, disabled }) => ({ companyId: discussion.company.id, teamId: discussion.team.id, disabled })),
            distinctUntilChanged((a, b) => a.companyId === b.companyId && a.teamId === b.teamId && a.disabled === b.disabled),
            switchMap(ids => ids.disabled ? of([]) : this.userRepository.getTeamMembers(ids.companyId, ids.teamId)),
        ).subscribe(result => this.users = result));
        this.subscriptions.add(this.noActionRequiredControl.valueChanges.pipe(
            distinctUntilChanged(),
        ).subscribe(noActionRequired =>
            noActionRequired ? this.collapse("actions") : this.expand("actions")));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    validate = (_: FormControl) => this.form.valid ? null : { valid: false };

    getApprovingTeamReference = (team: SimpleCompanyTeamDto | undefined): TeamReferenceDto | undefined =>
        !team ? undefined : { companyId: team.company.id, teamId: team.id };

    getApprovingTeamDisplay = (team: SimpleCompanyTeamDto | undefined | null): string =>
        !team ? this.translate.instant("Not Required") : team.name;

    compareTeams = (o1: TeamReferenceDto | undefined, o2: TeamReferenceDto | undefined): boolean =>
        o1 === o2 || !!o1 && !!o2 && (o1.companyId === o2.companyId && o1.teamId === o2.teamId);

    isApproved = (operation: SolutionApprovalActionType) => operation === SolutionApprovalActionType.approvedBy;
    isRejected = (operation: SolutionApprovalActionType) => operation === SolutionApprovalActionType.rejectedBy;

    // #region Expansion
    isExpanded = (section: SolutionSection) => this.expandedSections.has(section);
    isActionsExpanded = () => !this.noActionRequiredControl.value && this.isExpanded("actions");
    expand = (section: SolutionSection) => this.expandedSections.add(section);
    collapse = (section: SolutionSection) => this.expandedSections.delete(section);
    // #endregion

    // #region ControlValueAccessor implementation
    writeValue(obj: SolutionWithActions): void {
        this.value = obj;
    }

    registerOnChange(fn: any): void {
        this.onChangedCallback = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedCallback = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
    // #endregion

    // #region Actions
    addAction = () => {
        if (!this.discussion) return;
        EditActionDialogComponent.openForAdd(this.dialog, {
            companyId: this.discussion.company.id,
            teamId: this.discussion.team.id,
            fromSolution: true,
            actionInput: {
                departmentId: this.discussion.department?.id,
                categoryId: this.discussion.category?.id,
                subCategoryId: this.discussion.category?.subCategory?.id,
            },
        }).afterClosed().subscribe(result => {
            if (!result || result.type !== "addActionForSolution") return;
            this.handleAddActionResult(result);
        });
    };

    showSuggestedActions = () => {
        if (!this.discussion) return;
        SuggestSolutionActionsDialogComponent.open(this.dialog, this.discussion, {
            rootCause: this.rootCauseControl.value ?? undefined,
            discussionNotes: this.discussionNotesControl.value ?? undefined,
            solution: this.solutionControl.value ?? undefined,
            existingActions: this.actionChanges.map(a => a.action.description),
        }).componentInstance.actions$.subscribe(result => {
            this.handleAddActionResult(result);
        });
    };

    viewSolutionAction = (action: SolutionAction) => {
        // We only should be viewing when the form is disabled. In this case, all actions will be unchanged.
        if (action.type !== "unchanged") return;
        EditActionDialogComponent.openForEdit(this.dialog, { action: action.action, readonly: true });
    };

    editSolutionAction = (action: SolutionAction) => {
        let obs$: Observable<SolutionAction[] | null>;
        switch (action.type) {
            case "create":
                obs$ = EditActionDialogComponent.openForAdd(this.dialog, {
                    companyId: action.companyId,
                    teamId: action.teamId,
                    fromSolution: true,
                    actionInput: action.action,
                }).afterClosed().pipe(map(result => {
                    if (!result || result.type !== "addActionForSolution") return null;
                    return result.actions.map(a => ({
                        type: "create",
                        companyId: result.companyId,
                        teamId: result.teamId,
                        action: a
                    }));
                }));
                break;
            case "update":
                obs$ = EditActionDialogComponent.openForEdit(this.dialog, {
                    action: action.originalAction,
                    fromSolution: true,
                    actionInput: action.action,
                }).afterClosed().pipe(map(result => {
                    if (!result || result.type !== "updateActionForSolution") return null;
                    return [{
                        type: "update",
                        originalAction: action.originalAction,
                        action: result.action,
                    }];
                }));
                break;
            case "unchanged":
                obs$ = EditActionDialogComponent.openForEdit(this.dialog, {
                    action: action.action,
                    fromSolution: true,
                }).afterClosed().pipe(map(result => {
                    if (!result || result.type !== "updateActionForSolution") return null;
                    return [{
                        type: "update",
                        originalAction: action.action,
                        action: result.action,
                    }];
                }));
                break;
            default: return;
        }
        obs$.subscribe(result => {
            if (!result) return;
            this.replaceSolutionAction(action, result);
            this.onValueChanged(this.getValueInternal());
        });
    };

    deleteSolutionAction = (index: number) => {
        const currentActions = [...this.actionChanges];
        const deletedAction = currentActions.splice(index, 1)[0];
        if (!deletedAction) return;
        this.actionChanges = currentActions;
        switch (deletedAction.type) {
            case "update":
                this.deletedActions.push(deletedAction.originalAction);
                break;
            case "unchanged":
                this.deletedActions.push(deletedAction.action);
                break;
            case "create":
                // When deleting an added action, we don't need to do anything.
                break;
        }
        this.actionsDataSource.data = this.actionChanges;
        this.onValueChanged(this.getValueInternal());
    };

    getSolutionActionDescription = (action: SolutionAction) => action.action.description;
    getSolutionActionOwner = (action: SolutionAction): string => {
        switch (action.type) {
            case "create":
            case "update": {
                const ownerId = action.action.ownerUserId;
                return ownerId ? this.lookupOwnerName(ownerId) : "";
            }
            default:
                return getUserName(action.action.owner);
        }
    };

    getSolutionActionDueDate = (action: SolutionAction) => {
        switch (action.type) {
            case "update": return action.action.dueDateLocal ?? action.originalAction.dueDateLocal;
            default: return action.action.dueDateLocal;
        }
    };
    // #endregion

    private onValueChanged = (value: SolutionWithActions) => {
        this.noActionRequiredControl.updateValueAndValidity({ emitEvent: false });
        this.valueChange.emit(value);
        this.onChangedCallback?.(value);
        this.onTouchedCallback?.();
    };

    private availableApprovingTeamsChanged = (teams: SimpleCompanyTeamDto[]) => {
        const approvingTeam = this.approvingTeamControl.value;
        if (approvingTeam && !teams.some(t => t.company.id === approvingTeam.companyId && t.id === approvingTeam.teamId)) {
            this.approvingTeamControl.setValue(null);
        }
    };

    // #region Actions Private
    private handleAddActionResult = (result: IAddActionForSolutionResult) => {
        const newActions: ActionPendingCreation[] = result.actions.map(action => ({
            type: "create",
            companyId: result.companyId,
            teamId: result.teamId,
            action: action,
        }));
        this.actionChanges = [...this.actionChanges, ...newActions];
        this.actionsDataSource.data = this.actionChanges;
        this.onValueChanged(this.getValueInternal());
        this.expand("actions");
    };

    private lookupOwnerName = (userId: string) =>
        getUserName(this.users.find(u => u.userId === userId));

    private replaceSolutionAction = (original: SolutionAction, updated: SolutionAction[]): boolean => {
        const index = this.actionChanges.indexOf(original);
        if (index < 0) return false;
        const currentActions = [...this.actionChanges];
        currentActions.splice(index, 1, ...updated);
        this.actionChanges = currentActions;
        this.actionsDataSource.data = this.actionChanges;
        return true;
    };

    private mergeActions = (actions: GetActionDto[]) => {
        this.actionChanges = this.removeOrphanedModifiedActions(actions);
        this.deletedActions = this.removeOrphanedDeletedActions(actions);
        this.updateActionSources(actions);
        this.actionsDataSource.data = this.actionChanges;
    };

    private removeOrphanedModifiedActions = (actions: GetActionDto[]): SolutionAction[] => {
        const actionChanges = [...this.actionChanges];
        const existingActions = actionChanges.filter((c): c is ActionPendingUpdate | UnchangedAction =>
            c.type === "unchanged" || c.type === "update");
        for (const change of existingActions) {
            const action = change.type === "unchanged" ? change.action : change.originalAction;
            if (actions.some(a => a.id === action.id)) continue;
            // The original action can no longer be found
            // Thus, we remove it from the change list
            const index = actionChanges.indexOf(change);
            actionChanges.splice(index, 1);
        }
        return actionChanges;
    };

    private removeOrphanedDeletedActions = (actions: GetActionDto[]): GetActionDto[] => {
        const deletedActions = [...this.deletedActions];
        for (const deletedAction of this.deletedActions) {
            if (actions.some(a => a.id === deletedAction.id)) continue;
            // The original action can no longer be found
            // Thus, remove it from the change list
            const index = deletedActions.indexOf(deletedAction);
            deletedActions.splice(index, 1);
        }
        return deletedActions;
    };

    private updateActionSources = (actions: GetActionDto[]): void => {
        for (const action of actions) {
            const unchanged = this.actionChanges.find(a => a.type === "unchanged" && a.action.id === action.id);
            if (unchanged) {
                unchanged.action = action;
                continue;
            }
            const edited = this.actionChanges.find(a => a.type === "update" && a.originalAction.id === action.id);
            if (edited) {
                edited.action = action;
                continue;
            }
            const deletedIndex = this.deletedActions.findIndex(a => a.id === action.id);
            if (deletedIndex >= 0) {
                this.deletedActions[deletedIndex] = action;
                continue;
            }
            // We have not found the action to update.
            // Add it as an unchanged action
            this.actionChanges.push({ type: "unchanged", action });
        }
    };
    // #endregion

    // #region Data Binding
    private bindValue = (value: SolutionWithActions) => {
        this.actionChanges = [...value.actions];
        this.deletedActions = [...value.deletedActions];
        this.bindSolutionForm(value.solution);
        this.actionsDataSource.data = this.actionChanges;
    };

    private bindSolutionForm = (solution: GetSolutionDto | UpdateSolutionDto) => {
        let approvingTeam: TeamReferenceDto | undefined;
        if (solution.approvingTeam) {
            if ("teamId" in solution.approvingTeam) {
                approvingTeam = solution.approvingTeam;
            } else {
                approvingTeam = {
                    companyId: solution.approvingTeam.company.id,
                    teamId: solution.approvingTeam.id,
                };
            }
        }
        this.form.setValue({
            rootCause: solution.rootCause ?? null,
            discussionNotes: solution.discussionNotes ?? null,
            solution: solution.solution ?? null,
            noActionRequired: solution.noActionRequired,
            approvingTeam: approvingTeam ?? null,
        }, { emitEvent: false });
    };

    private getValueInternal = (): SolutionWithActions => ({
        solution: this.getSolutionValue(),
        actions: [...this.actionChanges],
        deletedActions: [...this.deletedActions],
    });

    private getSolutionValue = (): UpdateSolutionDto => ({
        rootCause: this.rootCauseControl.value ?? undefined,
        discussionNotes: this.discussionNotesControl.value ?? undefined,
        solution: this.solutionControl.value ?? undefined,
        noActionRequired: this.noActionRequiredControl.value ?? undefined,
        approvingTeam: this.approvingTeamControl.value ?? undefined,
    });
    // #endregion

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_actions: GetActionDto[] | null;
    static ngAcceptInputType_disabled: BooleanInput;
    static ngAcceptInputType_expandAll: BooleanInput;
    /* eslint-enable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}

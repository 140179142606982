<h2 mat-dialog-title translate="actions.delete.title"></h2>

<mat-dialog-content>
    <p translate="actions.delete.confirmation" [translateParams]="{ description: description }"></p>
    <p *ngIf="isRecurring" translate="actions.delete.recurringWarning"></p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" [mat-dialog-close]>
        {{ "Cancel" | translate }}
    </button>
    <app-status-button type="submit" [state]="buttonState" (buttonClick)="delete()">
        {{ "Delete" | translate }}
    </app-status-button>
</mat-dialog-actions>
import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

import { NumberExceededDialogComponent } from "../../dialogs";

@Component({
    selector: "app-number-exceeded-header",
    templateUrl: "./number-exceeded-header.component.html",
    styleUrls: ["./number-exceeded-header.component.scss"]
})
export class NumberExceededHeaderComponent {

    constructor(private readonly matDialog: MatDialog) { }

    openDialog = () => {
        NumberExceededDialogComponent.open(this.matDialog);
    };
}

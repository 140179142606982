import { effect, Injectable, signal, WritableSignal } from "@angular/core";

const ENABLE_DEPLOYMENT_MIGRATION_KEY = "QP_EnableDeploymentMigration";

const createStorageBackedBooleanSignal =
    (storage: Storage, key: string, defaultValue: boolean): WritableSignal<boolean> => {
        const initialSetting = storage.getItem(key);
        const settingSignal = signal(!initialSetting ? defaultValue : initialSetting === "true");
        effect(() => {
            const value = settingSignal();
            if (value === defaultValue) {
                storage.removeItem(key);
            } else {
                storage.setItem(key, value ? "true" : "false");
            }
        });
        return settingSignal;
    };

@Injectable({ providedIn: "root" })
export class QuarterlyPlanningAdminService {
    readonly enableDeploymentMigration = createStorageBackedBooleanSignal(localStorage, ENABLE_DEPLOYMENT_MIGRATION_KEY, false);
}

import { Injectable } from "@angular/core";
import { GetCustomGoalStatusDto, TeamCustomStatusesApi } from "@api";
import { catchError, Observable, of, throwError } from "rxjs";

import { ErrorCode, WorkfactaError, wrapWorkfactaError } from "~shared/api-errors";
import { WithDestroy } from "~shared/mixins";
import { getTeamCacheItem, ICompanyTeamCache, retryWithDelay, setTeamCacheItem } from "~shared/util/caching";
import { shareReplayUntil } from "~shared/util/rx-operators";

@Injectable({
    providedIn: "root"
})
export class CustomStatusRepository extends WithDestroy() {
    private goalStatusCache: ICompanyTeamCache<GetCustomGoalStatusDto[]> = {};

    constructor(
        private readonly api: TeamCustomStatusesApi,
    ) {
        super();
    }

    clearCache = () => {
        this.goalStatusCache = {};
    };

    getGoalStatuses = (companyId: string, teamId: string): Observable<GetCustomGoalStatusDto[]> => {
        let cacheItem = getTeamCacheItem(this.goalStatusCache, companyId, teamId);
        if (!cacheItem) {
            cacheItem = this.getGoalStatusesObservable(companyId, teamId);
            setTeamCacheItem(this.goalStatusCache, companyId, teamId, cacheItem);
        }
        return cacheItem;
    };

    private getGoalStatusesObservable = (companyId: string, teamId: string) =>
        this.api.listCustomGoalStatuses(companyId, teamId).pipe(
            wrapWorkfactaError(),
            catchError(err => {
                if (err instanceof WorkfactaError && err.status === 403 && err.code === ErrorCode.featureUnavailable) {
                    return of([]);
                }
                return throwError(() => err);
            }),
            retryWithDelay(),
            shareReplayUntil(this.destroyed$),
        );
}

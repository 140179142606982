import { booleanAttribute, Component, computed, input } from "@angular/core";
import { GetNumberDto, NumberRecordDetailDto } from "@api";

import { getDayOfWeekNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "app-number-description-column",
    templateUrl: "./number-description-column.component.html",
    styleUrl: "./number-description-column.component.scss"
})
export class NumberDescriptionColumnComponent {
    readonly number = input.required<GetNumberDto | NumberRecordDetailDto>();
    readonly hideUpdateDay = input(false, { transform: booleanAttribute });

    readonly isDaily = computed(() => !!this.number().source && this.number().updateDay != null);

    readonly getDayOfWeekNameKey = getDayOfWeekNameKey;
}

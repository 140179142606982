import { Injectable } from "@angular/core";
import { ActionsV2Api, GetActionDetailsDto, GetActionDto } from "@api";
import { Observable } from "rxjs";

import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";

import { BaseStateServiceWithRefresh } from "./base-state.service";
import { ItemReference } from "./comparators";
import { StateEvent } from "./state-shared";

export type ActionReference = ItemReference;

const isActionForTeam = (action: GetActionDetailsDto, companyId: string, teamId: string): boolean => {
    const { company, team } = getDelegatedItemCompanyTeam(action);
    return company.id === companyId && team.id === teamId;
};

const isActionReferenceForTeam = (action: ActionReference, companyId: string, teamId: string): boolean =>
    action.companyId === companyId && action.teamId === teamId;

export type ActionStateEvent = StateEvent<GetActionDetailsDto, ActionReference>;

@Injectable({
    providedIn: "root",
})
export class ActionStateService extends BaseStateServiceWithRefresh<GetActionDetailsDto, ActionReference, GetActionDto | ActionReference> {

    constructor(private readonly actionsApi: ActionsV2Api) {
        super();
    }

    eventsForTeam = (companyId: string, teamId: string) => this.eventsForFilter(
        item => isActionForTeam(item, companyId, teamId),
        item => isActionReferenceForTeam(item, companyId, teamId),
    );

    eventsForActions = (...actions: GetActionDto[]) => this.eventsForItems(actions);

    protected refreshItem = (item: ActionReference): Observable<GetActionDetailsDto> =>
        this.actionsApi.getAction(item.companyId, item.teamId, item.id);

    protected toReference = (item: GetActionDto | ActionReference): ActionReference => {
        if ("companyId" in item) return item;
        const { company, team } = getDelegatedItemCompanyTeam(item);
        return { companyId: company.id, teamId: team.id, id: item.id };
    };

    protected compareReferences = (ref1: ActionReference, ref2: ActionReference): boolean =>
        ref1.companyId === ref2.companyId &&
        ref1.teamId === ref2.teamId &&
        ref1.id === ref2.id;
}

<ng-container *ngIf="highlightedPlan$ | async; let highlightedPlan">
    <div *ngIf="currentPlan$ | async; let currentPlan" class="plan current-plan">
        <div class="plan-desc">
            {{ "planComparison.current" | translate }}
            <span class="plan-name">
                {{ getPlanName(currentPlan) }}
            </span>
        </div>
        <ng-container *ngTemplateOutlet="planFeatures; context { plan: currentPlan}"></ng-container>
    </div>
    <div class="plan highlighted-plan">
        <div class="plan-desc">
            {{ "planComparison.popular" | translate }}
            <span class="plan-name">
                {{ getPlanName(highlightedPlan) }}
            </span>
        </div>
        <ng-container *ngTemplateOutlet="planFeatures; context { plan: highlightedPlan}"></ng-container>
    </div>
</ng-container>

<ng-template #planFeatures let-plan="plan">
    <ul>
        <ng-container *ngFor="let feature of changedFeatures$ | async; let i = index">
            <li *ngIf="i === 0 || shouldShowFeature(plan, feature)" [class.feature-highlight]="i === 0">
                <mat-icon>check_round</mat-icon>
                <span class="feature-name">
                    <ng-container *ngIf="isConstraint(feature)">
                        {{ getFeatureConstraintDescription(plan, feature) }}
                    </ng-container>
                    <ng-container *ngIf="isAvailability(feature)">
                        {{ getFeatureAvailabilityNameKey(feature) | translate }}
                    </ng-container>
                </span>
            </li>
        </ng-container>
    </ul>
</ng-template>
<form [formGroup]="form" (submit)="submit()">
    <h4 mat-dialog-title>{{ 'cloneTemplateDialog.title' | translate }}</h4>

    <mat-dialog-content>
        <!-- Template -->
        <mat-form-field>
            <mat-label>{{ 'cloneTemplateDialog.template' | translate }}</mat-label>
            <mat-select formControlName="template">
                <mat-option *ngFor="let t of templates$ | async" [value]="t.id">
                    {{ t.name }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="templateControl.errors?.required">
                {{ 'cloneTemplateDialog.templateRequired' | translate }}
            </mat-error>
        </mat-form-field>

        <!-- User -->
        <mat-form-field *ngIf="isPartnerAdmin">
            <mat-label>{{ 'cloneTemplateDialog.user' | translate }}</mat-label>
            <app-auto-select
                formControlName="user"
                [options]="users$ | async"
                [optionValueFunc]="getUserValue"
                [optionDisplayFunc]="getUserName">
            </app-auto-select>
            <mat-error *ngIf="userControl.errors?.required">
                {{ 'cloneTemplateDialog.userRequired' | translate }}
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button mat-flat-button color="primary" type="submit">
            {{ this.btnAction.buttonText | translate }}
            <img *ngIf="this.btnAction.buttonIconImg != ''" src="{{ this.btnAction.buttonIconImg }}" alt="loader"
                height="{{ heightImg }}" width="{{ widthImg }}" />
        </button>
    </mat-dialog-actions>
</form>

<wf-dialog-close-button [disabled]="isLoading"></wf-dialog-close-button>
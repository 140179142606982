/**
 * A list of pages with permission tied to them.
 */
export enum PageName {
    companyPerformance = "companydashboard",
    quarterlyPlanning = "quarterlyplanning",
    meetingArchive = "meetingarchive",
    update = "update",
    teamPerformance = "dashboard",
    actions = "actions",
    meeting = "newmeeting",
    annualPlanning = "annualplanning",
    discussions = "discussions",
    news = "news",
    approvals = "approvals",
}

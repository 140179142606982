/* eslint-disable max-classes-per-file */
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { DataSource, isDataSource } from "@angular/cdk/collections";
import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { BehaviorSubject } from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { AsyncDataSource } from "~shared/util/async-table-data-source";

import { ItemEvent } from "./generic-table-shared";

@Directive()
export abstract class GenericTable<TItem, TColumn = string> extends WithDestroy() {

    @Input() set columns(value: TColumn[] | null) {
        this.columnsSubject.next(value ?? this.getDefaultColumns());
    }

    get columns(): TColumn[] {
        return this.columnsSubject.value;
    }

    @Input() defaultSort: TColumn | null = null;

    /**
     * Whether the result should be display only. If set to false, will allow editing the result.
     */
    @Input() set readonly(value: BooleanInput) {
        this.readonlyInternal = coerceBooleanProperty(value);
    }

    get readonly(): boolean {
        return this.readonlyInternal;
    }

    /**
     * Whether any controls should be disabled or not. This will disable the result control, as well
     * as adding or updating any child items.
     */
    @Input() set disabled(value: BooleanInput) {
        this.disabledInternal = coerceBooleanProperty(value);
    }

    get disabled(): boolean {
        return this.disabledInternal;
    }

    @Output() updated = new EventEmitter<ItemEvent<TItem>>();

    protected readonly columnsSubject = new BehaviorSubject<TColumn[]>([]);

    private readonlyInternal = false;
    private disabledInternal = false;

    protected abstract sortingDataAccessor(data: TItem, sortHeaderId: TColumn): string | number;

    protected getDefaultColumns = (): TColumn[] => [];
}

@Directive()
export abstract class GenericArrayTable<TItem, TColumn = string> extends GenericTable<TItem, TColumn> {

    @Input() set items(value: TItem[] | null) {
        this.dataSource.data = value ?? [];
    }

    get items(): TItem[] {
        return this.dataSource.data;
    }

    protected readonly dataSource = new MatTableDataSource<TItem>();

    constructor() {
        super();
        this.dataSource.sortingDataAccessor = (data, sortHeaderId) =>
            this.sortingDataAccessor(data, sortHeaderId as unknown as TColumn);
    }
}

@Directive()
export abstract class GenericDataSourceTable<TItem, TColumn extends string = string> extends GenericTable<TItem, TColumn> {

    @Input() set items(value: TItem[]) {
        this.dataSource = value;
    }

    @Input() set dataSource(
        value: TItem[] | DataSource<TItem> | MatTableDataSource<TItem> | AsyncDataSource<TItem, unknown, TColumn, unknown>) {
        if (!isDataSource(value)) {
            if ("paginator" in this.dataSourceInternal) {
                // We have a MatDataSource. Simply set the data property.
                this.dataSourceInternal.data = value;
                return;
            } else {
                const ds = new MatTableDataSource<TItem>(value);
                this.applySort(ds);
                this.applySortingAccessor(ds);
                this.dataSourceInternal = ds;
            }
        } else {
            this.dataSourceInternal = value;
            if ("sort" in this.dataSourceInternal) {
                this.applySort(this.dataSourceInternal);
                this.applySortingAccessor(this.dataSourceInternal);
            }
        }
    }

    get dataSource(): DataSource<TItem> | MatTableDataSource<TItem> | AsyncDataSource<TItem, unknown, TColumn, unknown> {
        return this.dataSourceInternal;
    }

    get asyncDataSource(): AsyncDataSource<TItem, unknown, TColumn, unknown> | null {
        return "refresh" in this.dataSourceInternal ? this.dataSourceInternal : null;
    }

    private dataSourceInternal: DataSource<TItem> | MatTableDataSource<TItem> | AsyncDataSource<TItem, unknown, TColumn, unknown>;

    constructor() {
        super();
        const dataSource = new MatTableDataSource<TItem>();
        this.dataSourceInternal = dataSource;
        this.applySortingAccessor(dataSource);
    }

    protected abstract getMatSort(): MatSort | null;

    private applySort = (dataSource: MatTableDataSource<TItem> | AsyncDataSource<TItem, unknown, TColumn, unknown>) => {
        dataSource.sort = this.getMatSort();
    };

    private applySortingAccessor = (dataSource: MatTableDataSource<TItem> | AsyncDataSource<TItem, unknown, TColumn, unknown>) => {
        dataSource.sortingDataAccessor = (data, sortHeaderId) =>
            this.sortingDataAccessor(data, sortHeaderId as unknown as TColumn);
    };
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { apiBaseURL } from "../api-config/app-api.config";
import { IPage } from "../shared/models/page-model";

@Injectable()
export class SetUp {
    private readonly baseUrl: string;

    constructor(private http: HttpClient) {
        this.baseUrl = apiBaseURL.baseURL;
    }

    printPlanSummary(printData: any): Observable<any> {
        const data = {
            html: printData,
        };
        const result = this.http
            .post(this.baseUrl + "api/Vision/ConvertHtmlToPDF", data)
            .pipe(map((s) => s));
        return result;
    }

    printExecutionPlan(printData: any): Observable<any> {
        const data = {
            html: printData,
        };
        const result = this.http
            .post(this.baseUrl + "api/Vision/HtmlToLandscapeViewPDF", data)
            .pipe(map((s) => s));
        return result;
    }

    deactivateInstance(companyId: any): Observable<any> {
        const result = this.http
            .delete(
                this.baseUrl + "api/Account/ArchivedDeleteUser?companyId=" + companyId
            )
            .pipe(map((s) => s));
        return result;
    }

    deleteInstance(companyId: any): Observable<any> {
        const result = this.http
            .delete(
                this.baseUrl + "api/Account/DeleteClientInstance?companyId=" + companyId
            )
            .pipe(map((s) => s));
        return result;
    }

    addSuggestion(suggestionFormModel: any): Observable<any> {
        const result = this.http
            .post(this.baseUrl + "api/Suggestion/AddSuggestion", suggestionFormModel)
            .pipe(map((s) => s));
        return result;
    }

    updateSuggestionStatus(id: any, statusId: any): Observable<any> {
        const result = this.http
            .post(
                this.baseUrl +
                "api/Suggestion/UpdateSuggestionStatus?id=" +
                id +
                "&statusId=" +
                statusId,
                ""
            )
            .pipe(map((s) => s));
        return result;
    }

    getAllPartnerConfigTemplate(): Observable<any> {
        const result = this.http
            .get(this.baseUrl + "api/Partner/ConfigTemplateListForDDN")
            .pipe(map((s) => s));
        return result;
    }

    addUpdateConfigTemplate(configTempModel: any): Observable<any> {
        const result = this.http
            .post(
                this.baseUrl + "api/Partner/AddUpdateConfigTemplate",
                configTempModel
            )
            .pipe(map((s) => s));
        return result;
    }

    getAllPages(): Observable<IPage[]> {
        const result = this.http
            .get<IPage[]>(this.baseUrl + "api/Pages/GetAllPages");
        return result;
    }

    deleteCompany(companyId: any): Observable<any> {
        const result = this.http
            .delete(this.baseUrl + "api/Account/DeleteClientInstance?companyId=" + companyId)
            .pipe(map((s) => s));
        return result;
    }

    getAllSuggestionsStatus(): Observable<any> {
        const result = this.http
            .get(this.baseUrl + "api/Suggestion/GetAllSuggestionStatus")
            .pipe(map((s) => s));
        return result;
    }

    getAllSuggestions(model: any): Observable<any> {
        const result = this.http
            .get(
                this.baseUrl +
                "api/Suggestion/GetAllSuggestion?skip=" +
                model.skip +
                "&take=" +
                model.take +
                "&userId=" +
                model.userId
            )
            .pipe(map((s) => s));
        return result;
    }

    getTotalNumberOfRecordsForSuggestions(): Observable<any> {
        const result = this.http
            .get(this.baseUrl + "api/Suggestion/GetSuggestionTotalCount")
            .pipe(map((s) => s));
        return result;
    }

    addUpdateAnnualGoalType(annualGoalFormModel: any): Observable<any> {
        const result = this.http
            .post(
                this.baseUrl + "api/Goals/AddUpdateAnnualGoalType",
                annualGoalFormModel
            )
            .pipe(map((s) => s));
        return result;
    }

    // Category
    addCategory(categoryFormModel: any): Observable<any> {
        const result = this.http
            .post(
                this.baseUrl + "api/WorkCategory/AddUpdateWorkCategory",
                categoryFormModel
            )
            .pipe(map((s) => s));
        return result;
    }

    getWorkCategoryTreeNGoalType(configTemplateId: any): Observable<any> {
        const result = this.http
            .get(
                this.baseUrl +
                "api/Partner/GetCategoryAnnualGoalTypeTree?configTemplateId=" +
                configTemplateId
            )
            .pipe(map((s) => s));
        return result;
    }

    getWorkCategoryById(id: string) {
        const result = this.http
            .get(this.baseUrl + "api/WorkCategory/GetWorkCategoryById?id=" + id)
            .pipe(map((s) => s));
        return result;
    }

    deleteCategory(id: any): Observable<any> {
        const result = this.http
            .delete(this.baseUrl + "api/WorkCategory/DeleteCategory?id=" + id)
            .pipe(map((s) => s));
        return result;
    }

    deleteConfigTemp(id: any): Observable<any> {
        const result = this.http
            .delete(this.baseUrl + "api/Partner/DeletePartnerConfigTemplate?id=" + id)
            .pipe(map((s) => s));
        return result;
    }

    DeleteAnnualGoalType(id: any): Observable<any> {
        const result = this.http
            .delete(this.baseUrl + "api/Goals/DeleteAnnualGoalTypeById?id=" + id)
            .pipe(map((s) => s));
        return result;
    }

    deleteSubCategory(id: string, categoryId: string) {
        const result = this.http
            .delete(
                this.baseUrl +
                "api/WorkCategory/DeleteSubCategory?categoryId=" +
                categoryId +
                "&subCategoryId=" +
                id
            )
            .pipe(map((s) => s));
        return result;
    }
}

import { Injectable } from "@angular/core";
import { CurrentTeamDto } from "@api";
import { NgxIndexedDBService } from "ngx-indexed-db";

import { BaseStoreWithExpiry } from "./base-store";

export interface TeamData {
    id: string;
    companyId: string;

    data: CurrentTeamDto;
    updated: Date;
}

@Injectable({
    providedIn: "root",
})
export class TeamStore extends BaseStoreWithExpiry<CurrentTeamDto, TeamData> {

    protected readonly storeName = "teams";

    constructor(
        dbService: NgxIndexedDBService,
    ) {
        super(dbService);
    }

    protected wrapItem = (item: CurrentTeamDto): TeamData => ({
        id: item.id,
        companyId: item.companyId,
        data: item,
        updated: new Date(),
    });
}

import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import { IFeedAdapter } from "./feed.adapter";
import { FeedScope } from "./feed-scope";

@Injectable()
export class SimpleFeedScope extends FeedScope implements OnDestroy {

    readonly adapter$: Observable<IFeedAdapter | null>;

    get adapter(): IFeedAdapter | null {
        return this.adapterSubject.value;
    }

    set adapter(value: IFeedAdapter | null) {
        this.adapterSubject.next(value);
    }

    private readonly adapterSubject = new BehaviorSubject<IFeedAdapter | null>(null);

    constructor() {
        super();
        this.adapter$ = this.adapterSubject.asObservable();
    }

    ngOnDestroy(): void {
        this.adapterSubject.complete();
    }
}


<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>{{ 'discussions.createDiscussion' | translate }}</h4>
    <mat-dialog-content>
        <app-origin-header *ngIf="origin" [origin]="origin"></app-origin-header>
        <mat-form-field>
            <mat-label>{{'discussions.heading' | translate}}</mat-label>
            <textarea matInput name="heading" formControlName="heading" required maxlength="250"></textarea>
            <mat-error *ngIf="!headingControl.valid">
                {{'discussions.headingRequired' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'discussions.descriptionReason' | translate}}</mat-label>
            <textarea matInput name="description" formControlName="description" maxlength="1000" cdkFocusInitial></textarea>
            <mat-error *ngIf="!descriptionControl.valid">
                {{'discussions.descriptionRequired' | translate}}
            </mat-error>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'discussions.type' | translate}}</mat-label>
            <mat-select disableOptionCentering name="type" formControlName="type">
                <mat-option *ngFor="let type of types" [value]="type">
                    {{ getTypeNameKey(type) | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <p class="warning-box" *ngIf="form.errors?.capReached" role="alert" translate="planUpgrade.discussions.capReached"></p>
    </mat-dialog-content>
        
    <mat-dialog-actions align="end">
        <app-status-button type="button" *ngIf="canResolveNow$ | async" (buttonClick)="save(true)" [state]="resolveNowState" [disabled]="form.disabled" @fadeIn>
            {{ "discussions.solution.resolveNow" | translate }}
        </app-status-button>
        <app-status-button type="submit" [state]="buttonState" [disabled]="form.disabled">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
import { trigger } from "@angular/animations";
import { AfterViewInit, Component, HostBinding, Inject } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
    AttachmentDto, GetNewsItemDto, NewsItemsApi, SimpleCompanyDto, SimpleCompanyTeamDto, SimpleUserDto, UpdateNewsItemDto
} from "@api";
import { concat, defer, last, map, Observable, of, startWith, switchMap, tap } from "rxjs";

import { TeamRepository, UserRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { NewsItemStateService } from "~services/state";
import { ErrorCode, WorkfactaError, wrapWorkfactaError } from "~shared/api-errors";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { WithDestroy } from "~shared/mixins";
import { defaultAnimationTiming, fadeExpandAnimationBuilder } from "~shared/util/animations";
import { ACCEPT_DOCUMENT_OR_IMAGE, hasDocumentOrImageExtension, showInvalidDocumentOrImageWarning } from "~shared/util/attachments";
import { LINK_PATTERN } from "~shared/util/custom-validators";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortString } from "~shared/util/sorters";
import { compareTeams } from "~shared/util/team-helper";
import { getUserName } from "~shared/util/user-helper";

interface INewsItemDialogData {
    newsItem?: GetNewsItemDto;
    clientId: string;
    companyId: string;
    teamId: string;
    readonly: boolean;
}

export interface IAddNewsItemDialogData {
    clientId: string;
    companyId: string;
    teamId: string;
}

interface INewAttachment {
    file: File;
    name: string;
}

interface TeamsGroup {
    readonly company: SimpleCompanyDto;
    readonly teams: SimpleCompanyTeamDto[];
}

const groupTeams = (teams: SimpleCompanyTeamDto[]): TeamsGroup[] => {
    const teamsGrouped = teams.groupBy(x => x.company.id);

    return Array.from(teamsGrouped).map(([_cid, companyTeams]) => ({
        company: companyTeams[0].company,
        teams: companyTeams.sort(sortString.ascending(x => x.name)),
    })).sort(sortString.ascending(x => x.company.name));
};

const CURRENT_USER = "currentUser";

@Component({
    selector: "app-edit-news-item-dialog",
    templateUrl: "./edit-news-item-dialog.component.html",
    styleUrls: ["./edit-news-item-dialog.component.scss"],
    animations: [
        trigger("fadeExpand", fadeExpandAnimationBuilder(defaultAnimationTiming)),
    ],
})
export class EditNewsItemDialogComponent extends WithDestroy() implements AfterViewInit {

    @HostBinding("@.disabled") disableAnimations = true;

    buttonState: ButtonState;

    readonly headingControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(250)]);
    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(1000)]);
    readonly creatorControl = this.fb.nonNullable.control<string | null>(CURRENT_USER, [Validators.required]);
    readonly recipientsControl = this.fb.nonNullable.control<SimpleCompanyTeamDto[]>([], [Validators.required]);

    readonly form = new FormGroup({
        heading: this.headingControl,
        description: this.descriptionControl,
        creator: this.creatorControl,
        recipients: this.recipientsControl,
    });

    readonly linkControl = this.fb.control<string | null>(null);

    readonly newAttachments: INewAttachment[] = [];
    readonly attachments: AttachmentDto[];
    readonly links: string[];

    get isNewNewsItem(): boolean {
        return !this.newsItem;
    }

    get originalCreator(): SimpleUserDto | undefined {
        return this.newsItem?.creator;
    }

    get hasAttachments(): boolean {
        return !!this.attachments.length || !!this.newAttachments.length;
    }

    readonly getUserName = getUserName;
    readonly compareTeams = compareTeams;

    readonly attachmentAcceptTypes = ACCEPT_DOCUMENT_OR_IMAGE;

    readonly users$: Observable<SimpleUserDto[]>;
    readonly companies$: Observable<TeamsGroup[]>;

    private get creatorUserId(): string | undefined {
        const creator = this.creatorControl.value;
        if (!creator || creator === CURRENT_USER) return undefined;
        return creator;
    }

    private readonly companyId: string;
    private readonly teamId: string;
    private readonly newsItem?: GetNewsItemDto;

    private readonly removedAttachments: string[] = [];

    constructor(
        private readonly newsItemsApi: NewsItemsApi,
        private readonly newsItemStateService: NewsItemStateService,
        private readonly userRepository: UserRepository,
        private readonly teamRepository: TeamRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly dialogRef: MatDialogRef<EditNewsItemDialogComponent, GetNewsItemDto>,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) data: INewsItemDialogData,
    ) {
        super();
        this.companyId = data.companyId;
        this.teamId = data.teamId;
        this.newsItem = data.newsItem;
        this.updateControlState(data);

        this.attachments = [...data.newsItem?.attachments ?? []];
        this.links = [...data.newsItem?.links ?? []];

        this.users$ = defer(() => this.userRepository.getTeamMembers(this.companyId, this.teamId)).pipe(
            map(this.addCurrentUser),
            tap(this.availableUsersChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.companies$ = defer(() => this.teamRepository.getAllClientTeams(data.clientId)).pipe(
            tap(this.availableTeamsChanged),
            map(groupTeams),
            startWith([]),
            shareReplayUntil(this.destroyed$),
        );
    }

    static openForAdd(dialog: MatDialog, data: IAddNewsItemDialogData) {
        return this.openInternal(dialog, {
            newsItem: undefined,
            clientId: data.clientId,
            companyId: data.companyId,
            teamId: data.teamId,
            readonly: false,
        });
    }

    static openForEdit(dialog: MatDialog, newsItem: GetNewsItemDto, readonly: boolean = false) {
        return this.openInternal(dialog, {
            newsItem,
            clientId: newsItem.company.clientId,
            companyId: newsItem.company.id,
            teamId: newsItem.team.id,
            readonly,
        });
    }

    private static openInternal(dialog: MatDialog, data: INewsItemDialogData) {
        return dialog.open<EditNewsItemDialogComponent, INewsItemDialogData, GetNewsItemDto>(EditNewsItemDialogComponent, {
            width: "750px",
            data: data
        });
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    //#region Attachments/Links
    addLink = () => {
        let linkValue = this.linkControl.value;
        if (!this.linkControl.valid || !linkValue) return;

        if (!LINK_PATTERN.test(linkValue)) linkValue = "http://" + linkValue;

        this.links.push(linkValue);
        this.linkControl.reset();
    };

    removeLink = (index: number) => {
        this.links.splice(index, 1);
    };

    onFileSelected = (event: Event) => {
        const input = event.target;
        if (!(input instanceof HTMLInputElement) || !input.files) return;
        let error = false;
        // A FileList is not an iterator, so cannot be iterated with for-of
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < input.files.length; i++) {
            const file = input.files[i];
            const name = file.name;
            if (!hasDocumentOrImageExtension(name)) {
                error = true;
            } else {
                this.newAttachments.push({ file, name });
            }
        }
        if (error) {
            showInvalidDocumentOrImageWarning(this.notificationService);
        }
        input.value = "";
    };

    removeExistingAttachment = (attachment: AttachmentDto): void => {
        const index = this.attachments.indexOf(attachment);
        if (index < 0) return;
        this.attachments.splice(index, 1);
        this.removedAttachments.push(attachment.path);
    };

    removeNewAttachment = (attachment: INewAttachment): void => {
        const index = this.newAttachments.indexOf(attachment);
        if (index < 0) return;
        this.newAttachments.splice(index, 1);
    };
    //#endregion

    //#region Recipients (Teams)
    areAllTeamsSelected = (companies: TeamsGroup[]) => companies.every(this.areAllCompanyTeamsSelected);

    areSomeTeamsSelected = (companies: TeamsGroup[]) => companies.some(this.areSomeCompanyTeamsSelected);

    selectAllTeams = (selected: boolean, companies: TeamsGroup[]) => {
        if (!selected) {
            this.recipientsControl.setValue([]);
        } else {
            this.recipientsControl.setValue(companies.map(g => g.teams).flat());
        }
    };

    areAllCompanyTeamsSelected = (company: TeamsGroup) =>
        company.teams.every(team => this.recipientsControl.value.some(t => compareTeams(t, team)));

    areSomeCompanyTeamsSelected = (company: TeamsGroup) =>
        company.teams.some(team => this.recipientsControl.value.some(t => compareTeams(t, team)));

    selectAllCompanyTeams = (company: TeamsGroup, selected: boolean) => {
        const recipients = [...this.recipientsControl.value];
        if (!selected) {
            this.recipientsControl.setValue(recipients.filter(t => !company.teams.some(ct => compareTeams(t, ct))));
        } else {
            this.recipientsControl.setValue([
                ...recipients,
                // Add in any company teams not already selected
                ...company.teams.filter(ct => !recipients.some(t => compareTeams(ct, t))),
            ]);
        }
    };

    removeTeam = (team: SimpleCompanyTeamDto) => {
        const recipients = [...this.recipientsControl.value];
        const index = recipients.indexOf(team);
        if (index < 0) return;
        recipients.splice(index, 1);
        this.recipientsControl.setValue(recipients);
    };
    //#endregion

    getUserId = (user: SimpleUserDto) => user.userId;

    save = () => {
        if (this.buttonState || !this.form.valid) return;
        this.buttonState = "loading";

        const updateDto: UpdateNewsItemDto = {
            heading: this.headingControl.value ?? "",
            description: this.descriptionControl.value ?? "",
            links: this.links,
            recipients: this.recipientsControl.value.map(r => ({
                companyId: r.company.id,
                teamId: r.id,
            })),
        };
        const newAttachments = [...this.newAttachments];
        const removedAttachments = [...this.removedAttachments];

        let result$: Observable<GetNewsItemDto>;
        if (this.newsItem) {
            result$ = this.newsItemsApi.updateNewsItem(
                this.newsItem.company.id,
                this.newsItem.team.id,
                this.newsItem.id,
                updateDto,
            );
        } else {
            result$ = this.newsItemsApi.addNewsItem(
                this.companyId,
                this.teamId,
                {
                    ...updateDto,
                    creatorUserId: this.creatorUserId,
                },
            );
        }
        result$.pipe(
            wrapWorkfactaError(),
            switchMap(newsItem => this.addNewAttachments(newsItem, newAttachments)),
            switchMap(newsItem => this.removeAttachments(newsItem, removedAttachments)),
        ).subscribe({
            next: result => {
                this.buttonState = "success";
                if (this.newsItem) {
                    this.newsItemStateService.notifyUpdate(result);
                } else {
                    this.newsItemStateService.notifyAdd(result);
                }
                setTimeout(() => {
                    this.dialogRef.close(result);
                }, 1000);
            },
            error: error => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                if (error instanceof WorkfactaError) {
                    switch (error.status) {
                        case 409:
                            switch (error.code) {
                                case ErrorCode.planCapReached:
                                    this.form.setErrors({ capReached: true });
                                    return;
                            }
                    }
                }
                this.notificationService.errorUnexpected();
            },
        });
    };

    private updateControlState = (data: INewsItemDialogData) => {
        if (data.newsItem) {
            this.bindNewsItem(data.newsItem);

            // The creator of an existing news item cannot be updated.
            this.creatorControl.disable();
            // If this news item was not loaded from the scope of the owning team,
            // it cannot be edited, only viewed.
            if (data.readonly || data.newsItem.currentTeam.id !== data.newsItem.team.id) {
                this.form.disable();
            }
        }
    };

    private bindNewsItem = (newsItem: GetNewsItemDto) => {
        this.headingControl.setValue(newsItem.heading);
        this.descriptionControl.setValue(newsItem.description);
        this.creatorControl.setValue(newsItem.creator?.userId ?? null);
        this.recipientsControl.setValue(newsItem.recipients);
    };

    //#region Attachments/Links Internal
    private addNewAttachments = (newsItem: GetNewsItemDto, newAttachments: INewAttachment[]): Observable<GetNewsItemDto> =>
        concat(
            of(newsItem),
            ...newAttachments.map(a => a.file).map(file =>
                this.newsItemsApi.addAttachment(
                    newsItem.company.id, newsItem.team.id, newsItem.id, file)),
        ).pipe(last());

    private removeAttachments = (newsItem: GetNewsItemDto, removedAttachments: string[]): Observable<GetNewsItemDto> => {
        if (!removedAttachments.length) return of(newsItem);
        return this.newsItemsApi.deleteAttachments(
            newsItem.company.id, newsItem.team.id, newsItem.id, { attachmentPaths: this.removedAttachments });
    };
    //#endregion

    //#region Drop-Down Data Validation
    private addCurrentUser = (users: SimpleUserDto[]): SimpleUserDto[] => {
        const user = this.userContext.user();
        if (!user) return users;
        const currentUser: SimpleUserDto = {
            userId: CURRENT_USER,
            firstName: user.firstName,
            lastName: user.lastName,
        };
        users = [...users];
        const index = users.findIndex(u => u.userId === user.id);
        if (index >= 0) {
            // Replace the user with the dummy record.
            users.splice(index, 1, currentUser);
        } else {
            // Add the current user to the start of the list.
            users.unshift(currentUser);
        }
        return users;
    };

    private availableUsersChanged = (users: SimpleUserDto[]) => {
        if (!users.some(x => x.userId === this.creatorControl.value)) {
            this.creatorControl.setValue(null);
        }
        if (this.isNewNewsItem && !this.creatorControl.value) {
            this.creatorControl.setValue(CURRENT_USER);
        }
    };

    private availableTeamsChanged = (teams: SimpleCompanyTeamDto[]) => {
        const recipients = this.recipientsControl.value;
        if (recipients.some(r => !teams.some(t => compareTeams(t, r)))) {
            this.recipientsControl.setValue(recipients.filter(r => teams.some(t => compareTeams(t, r))));
        }
        // Add in the current team if no other teams are set.
        if (this.isNewNewsItem && !this.recipientsControl.value.length) {
            const companyTeam = this.teamContext.companyTeam();
            const currentTeam = companyTeam?.team;
            const currentCompany = companyTeam?.company;
            if (currentTeam && currentCompany && teams.some(t => t.id === currentTeam.id && t.company.id === currentCompany.id)) {
                this.recipientsControl.setValue([{
                    id: currentTeam.id,
                    name: currentTeam.name,
                    company: {
                        id: currentCompany.id,
                        clientId: currentCompany.clientId,
                        name: currentCompany.name,
                    },
                }]);
            }
        }
    };
    //#endregion
}

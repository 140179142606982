import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscussionsApi, GetNewsItemDto } from "@api";

import { TeamContext } from "~services/contexts";
import { NewsItemStateService } from "~services/state";
import { mapNewsOrigin } from "~shared/util/origin-builder";

import { CommonAddChildDiscussionButtonDirective } from "./add-child-discussion-button.component";

@Component({
    selector: "app-add-news-child-discussion-button",
    templateUrl: "./add-child-discussion-button.component.html",
    styleUrls: ["./add-child-discussion-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewsChildDiscussionButtonComponent extends CommonAddChildDiscussionButtonDirective<GetNewsItemDto> {

    readonly mapToOrigin = mapNewsOrigin;
    readonly mapToInput = undefined;

    constructor(
        discussionsApi: DiscussionsApi,
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly newsItemStateService: NewsItemStateService,
    ) {
        super(discussionsApi, teamContext, dialog);
    }

    protected notifyChange = (source: GetNewsItemDto) => this.newsItemStateService.notifyRefresh(source);
}

import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart, ChartDataset, ChartOptions, registerables } from "chart.js";

import { averageLineStyle, getBackgroundColor, LineOptions, resultLineStyle, targetLineStyle } from "~shared/chart-defaults";
import { getScoreLevelColor } from "~shared/util/performance-colors";
import { findScoreLevel, getScoreLevelNameKey, PerformanceDto } from "~shared/util/performance-shared";

declare type CustomDataSet = ChartDataset<"line">;

@Component({
    selector: "app-performance-chart",
    templateUrl: "./performance-chart.component.html",
    styleUrls: ["./performance-chart.component.scss"]
})
export class PerformanceChartComponent implements AfterViewInit, OnDestroy {

    @ViewChild("chart") chartEl?: ElementRef<HTMLCanvasElement>;

    @Input() set data(value: PerformanceDto | null) {
        this.dataInternal = value;
        this.updateChart();
    }

    get data(): PerformanceDto | null {
        return this.dataInternal;
    }

    @Input() set level(value: string | null) {
        // As a level of null will display the execution score, if we explicitly request
        // the execution score, we must convert this to null.
        if (value === "3") value = null;
        this.levelInternal = value;
        this.updateChart();
    }

    get level(): string | null {
        return this.levelInternal;
    }

    private dataInternal: PerformanceDto | null = null;
    private levelInternal: string | null = null;
    private chart?: Chart;

    private readonly chartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            legend: {
                labels: {
                    color: "black",
                    font: {
                        size: 15
                    }
                }
            },
            tooltip: {
                callbacks: {
                    title: (items) => {
                        if (!this.dataInternal) return items[0].label;
                        const index = items[0].dataIndex;
                        const period = this.dataInternal.periods[index];
                        return this.translate.instant("performance.periodDisplay", period);
                    },
                    label: (item) => {
                        const value = item.raw;
                        return `${item.dataset.label}: ${value}%`;
                    },
                }
            }
        },
        datasets: {
            line: {
                fill: true,
                tension: 0.4,
            },
            bar: {
                barPercentage: 0.4,
            }
        },
        scales: {
            yAxis: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    color: "black",
                    callback: value => value.toString() + "%"
                },
                title: {
                    text: this.translate.instant("Score"),
                    display: true,
                }
            },
            xAxis: {
                beginAtZero: true,
                ticks: {
                    color: "black",
                },
                title: {
                    text: this.translate.instant("Weeks"),
                    display: true,
                }
            }
        },
        maintainAspectRatio: false,
    };

    constructor(
        private readonly translate: TranslateService,
    ) {
        Chart.register(...registerables);
    }

    ngAfterViewInit() {
        this.buildChart();
    }

    ngOnDestroy() {
        this.chart?.destroy();
    }

    private buildChart = () => {
        if (!this.chartEl) return;

        this.chart = new Chart(this.chartEl.nativeElement, {
            type: "line",
            options: this.chartOptions,
            data: {
                labels: this.buildChartLabels(this.dataInternal),
                datasets: this.buildChartDatasets(this.dataInternal, this.levelInternal),
            }
        });
    };

    private updateChart = () => {
        if (!this.chart) {
            this.buildChart();
            return;
        }

        this.chart.data.labels = this.buildChartLabels(this.dataInternal);
        this.chart.data.datasets = this.buildChartDatasets(this.dataInternal, this.levelInternal);
        this.chart.update();
    };

    private buildChartLabels = (data: PerformanceDto | null): string[] | undefined => {
        if (!data) return this.buildEmptyChartLabels();
        const periods = data.periods;
        if (!periods.length) return [];

        const firstPeriod = periods[0];
        if (periods.every(p => p.financialYear === firstPeriod.financialYear && p.planningPeriod === firstPeriod.planningPeriod)) {
            // All periods are in the same planning period
            // We can simply use the week name as the label
            return periods.map(w => w.collectionPeriod.toString());
        }

        // The periods are split over one or more planning periods
        return data.periods.map((w, i) =>
            // For the first week of each period, display the full quarter label
            (i === 0 || w.collectionPeriod === 1) ? this.translate.instant("performance.periodDisplay", w) :
                // For subsequent weeks, simply use the week name as the label.
                w.collectionPeriod.toString());
    };

    private buildChartDatasets = (data: PerformanceDto | null, level: string | null): CustomDataSet[] => {
        if (!data) return this.buildEmptyChartDatasets(level);

        const datasets: CustomDataSet[] = [];

        const values = data.periods.map(w => (!level ? w.executionScore : findScoreLevel(w, level)?.score) ?? null);
        const averages = data.periods.map(w => (!level ? w.rawExecutionScore : findScoreLevel(w, level)?.rawScore) ?? null)
            .reduce((result, _, i, rawValues) => {
                let average: number | null;
                // If we have no scores from this week on, don't calculate the average any further
                // (This may be the case if this week and remaining weeks are in the future.)
                const futureResults = rawValues.slice(i).filter((v): v is number => v !== null);
                if (!futureResults.length) {
                    average = null;
                } else {
                    // If we have no past data, the average is undefined.
                    const prevResults = rawValues.slice(0, i + 1).filter((v): v is number => v !== null);
                    if (!prevResults.length) {
                        average = null;
                    } else {
                        const sum = prevResults.reduce((s, v) => s + v, 0);
                        average = Math.round(sum / prevResults.length * 100);
                    }
                }
                result.push(average);
                return result;
            }, [] as (number | null)[]);

        const levelLineStyle: LineOptions = {};
        const levelColor = level && getScoreLevelColor(level);
        if (levelColor) {
            levelLineStyle.borderColor = levelColor;
            levelLineStyle.backgroundColor = getBackgroundColor(levelColor);
        }

        datasets.push({
            ...resultLineStyle,
            ...levelLineStyle,
            label: this.getLevelLabel(level),
            data: values,
            normalized: true,
        });

        const targets = data.periods.map(w => (!level ? w.executionTarget : findScoreLevel(w, level)?.target) ?? null)
            .map(target => target == null ? null : Math.round(target));
        if (targets.some(t => t != null)) {
            datasets.push({
                ...targetLineStyle,
                label: this.translate.instant("performance.target"),
                data: targets,
                fill: "end",
                normalized: true,
            });
        }

        datasets.push({
            ...averageLineStyle,
            label: this.translate.instant("performance.average"),
            data: averages,
            normalized: true,
        });

        return datasets;
    };

    private buildEmptyChartLabels = (): string[] =>
        new Array(13).fill(0).map((_, i) => (i + 1).toString());

    private buildEmptyChartDatasets = (level: string | null): CustomDataSet[] => {
        const datasets: CustomDataSet[] = [];
        const data = new Array(13).fill(0);

        datasets.push({
            ...resultLineStyle,
            label: this.getLevelLabel(level),
            data: data,
            normalized: true,
        });

        datasets.push({
            ...averageLineStyle,
            label: this.translate.instant("performance.average"),
            data: data,
            normalized: true,
        });

        return datasets;
    };

    private getLevelLabel = (level: string | null) =>
        this.translate.instant("performance.scoreFor", { name: this.getLevelName(level) });

    private getLevelName = (level: string | null) =>
        this.translate.instant(!level ? "performanceScores.execution" : (getScoreLevelNameKey(level) ?? ""));
}

<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>
        <wf-translate-template key="goals.update.notesDialogTitle">
            <span *wfTransTplContent="'heading'" class="goal-heading">{{ record.heading }}</span>
            <span *wfTransTplContent="'week'" class="goal-week">{{ 'period.weekShort' | translate: { week: record.week } }}</span>
        </wf-translate-template>
    </h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{ 'goals.status' | translate }}</mat-label>
                    <mat-select [appDisable]="false" disableOptionCentering formControlName="status"
                        [compareWith]="areStatusesEqual">
                        <mat-select-trigger>
                            <app-goal-status [status]="statusControl.value"></app-goal-status>
                        </mat-select-trigger>
                        <!-- Dummy option to allow trigger to work properly in case the current status has been removed -->
                        <mat-option *ngIf="!isCurrentStatusAvailable(statuses$ | async)" disabled [value]="statusControl.value">
                            <app-goal-status [status]="statusControl.value"></app-goal-status>
                        </mat-option>
                        <mat-option *ngFor=" let status of statuses$ | async" [value]="status">
                            <app-goal-status [status]="status"></app-goal-status>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{ 'goals.notes' | translate }}</mat-label>
                    <textarea matInput formControlName="notes" name="notes" autocomplete="off"
                        [maxlength]="maxNotesLength" cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5"
                        cdkFocusInitial></textarea>
                    <mat-error *ngIf="notesControl.hasError('required')" translate="goals.notesRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <app-status-button type="submit" [visualDisable]="!form.valid">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
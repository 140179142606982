import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscussionsApi, GetNumberDto, NumberRecordDetailDto } from "@api";

import { TeamContext } from "~services/contexts";
import { NumberStateService } from "~services/state";
import { mapNumberDiscussionInput } from "~shared/util/discussion-input-builder";
import { mapNumberOrigin } from "~shared/util/origin-builder";

import { CommonAddChildDiscussionButtonDirective } from "./add-child-discussion-button.component";

declare type NumberDto = GetNumberDto | NumberRecordDetailDto;

@Component({
    selector: "app-add-number-child-discussion-button",
    templateUrl: "./add-child-discussion-button.component.html",
    styleUrls: ["./add-child-discussion-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNumberChildDiscussionButtonComponent extends CommonAddChildDiscussionButtonDirective<NumberDto> {

    readonly mapToOrigin = mapNumberOrigin;
    readonly mapToInput = mapNumberDiscussionInput;

    constructor(
        discussionsApi: DiscussionsApi,
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly numberStateService: NumberStateService,
    ) {
        super(discussionsApi, teamContext, dialog);
    }

    protected notifyChange = (source: NumberDto) => this.numberStateService.notifyRefresh(source);

    protected creationAllowed = (source: NumberDto): boolean => !source.isPrivate && source.canEdit;
}

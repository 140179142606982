/* eslint-disable max-classes-per-file */
import { ComponentType } from "@angular/cdk/portal";
import { Directive } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AttachmentDto } from "@api";
import { concat, Observable, of } from "rxjs";
import { last, map, switchMap } from "rxjs/operators";

import { NotificationService } from "~services/notification.service";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { ACCEPT_DOCUMENT_OR_IMAGE, hasDocumentOrImageExtension, showInvalidDocumentOrImageWarning } from "~shared/util/attachments";

interface INewAttachment {
    file: File;
    name: string;
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BaseUpdateAttachmentsDialogComponent<TResponse> {

    abstract get description(): string;

    buttonState: ButtonState;

    get existingAttachments() {
        return this.getExistingAttachments().filter(a => !this.removedAttachments.includes(a.path));
    }

    get hasChanges() {
        return this.newAttachments.length > 0 || this.removedAttachments.length > 0;
    }

    get hasNoFiles() {
        return !this.existingAttachments.length && !this.newAttachments.length;
    }

    get canEdit(): boolean {
        return true;
    }

    get isValid(): boolean {
        return true;
    }

    readonly newAttachments: INewAttachment[] = [];

    readonly attachmentAcceptTypes = ACCEPT_DOCUMENT_OR_IMAGE;

    private readonly removedAttachments: string[] = [];

    constructor(
        protected readonly notificationService: NotificationService,
        protected readonly dialogRef: MatDialogRef<unknown, TResponse>
    ) {
    }

    protected static openInternal<TResponse>(
        component: ComponentType<BaseUpdateAttachmentsDialogComponent<TResponse>>,
        dialog: MatDialog, data: unknown): MatDialogRef<unknown, TResponse> {
        return dialog.open(component, {
            width: "500px",
            data: data
        });
    }

    protected abstract updateAttachments(newAttachments: File[], removedAttachments: string[]): Observable<TResponse>;
    protected abstract getExistingAttachments(): AttachmentDto[];

    save = () => {
        if (this.buttonState || !this.canEdit || !this.isValid) return;

        this.buttonState = "loading";

        this.updateAttachments(this.newAttachments.map(a => a.file), this.removedAttachments).subscribe({
            next: result => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(result);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.dialogRef.close();
                }, 2000);
                this.notificationService.errorUnexpected();
            }
        });
    };

    removeExistingAttachment = (attachment: AttachmentDto): void => {
        if (!this.canEdit) return;
        this.removedAttachments.push(attachment.path);
    };

    removeNewAttachment = (attachment: INewAttachment): void => {
        if (!this.canEdit) return;
        const index = this.newAttachments.indexOf(attachment);
        if (index >= 0) {
            this.newAttachments.splice(index, 1);
        }
    };

    onFileSelected = (event: Event) => {
        const input = event.target;
        if (!(input instanceof HTMLInputElement) || !input.files) return;
        let error = false;
        // A FileList is not an iterator, so cannot be iterated with for-of
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < input.files.length; i++) {
            const file = input.files[i];
            const name = file.name;
            if (!hasDocumentOrImageExtension(name)) {
                error = true;
            } else {
                this.newAttachments.push({ file, name });
            }
        }
        if (error) {
            showInvalidDocumentOrImageWarning(this.notificationService);
        }
        input.value = ""; // Clears the file selection
    };
}

export abstract class BaseEditAttachmentsDialogComponent extends BaseUpdateAttachmentsDialogComponent<boolean> {

    constructor(
        notificationService: NotificationService,
        dialogRef: MatDialogRef<unknown, boolean>
    ) {
        super(notificationService, dialogRef);
    }

    protected abstract uploadAttachment(file: File): Observable<unknown>;
    protected abstract deleteAttachment(path: string): Observable<unknown>;
    protected abstract deleteAttachments?(paths: string[]): Observable<unknown>;

    protected updateAttachments = (newAttachments: File[], removedAttachments: string[]): Observable<boolean> =>
        this.addNewAttachments(newAttachments).pipe(
            switchMap(() => this.removeAttachments(removedAttachments)),
            map(() => true),
        );

    private addNewAttachments = (newAttachments: File[]): Observable<unknown> =>
        concat(
            of(undefined),
            ...newAttachments.map(this.uploadAttachment)
        ).pipe(
            last()
        );

    private removeAttachments = (removedAttachments: string[]): Observable<unknown> => {
        if (this.deleteAttachments && removedAttachments.length) {
            return this.deleteAttachments(removedAttachments);
        }
        return concat(
            of(undefined),
            ...removedAttachments.map(this.deleteAttachment),
        ).pipe(
            last()
        );
    };
}

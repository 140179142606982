<div class="child-table-container">
    <table mat-table class="wf-table" [dataSource]="actionsDataSource" matSort matSortActive="dueDate"
        matSortDirection="asc" matSortDisableClear wfTableFocus>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                {{"actions.description" | translate}}
            </th>
            <td mat-cell *matCellDef="let row" class="wf-column-heading">
                <a class="multiline-description" (click)="viewAction(row)" wfEmptyLink>
                    {{row.description}}
                </a>
            </td>
        </ng-container>
        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.status" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                <app-action-progress-update [action]="row" [disabled]="disabled" (updated)="reloadActions()">
                </app-action-progress-update>
            </td>
        </ng-container>
        <ng-container matColumnDef="priorityName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.priority" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                <app-priority [priority]="row.priority"></app-priority>
            </td>
        </ng-container>
        <ng-container matColumnDef="owner">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.owner" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                <app-delegated-user-display [entity]="row">
                    {{ getUserName(row.owner) }}
                    <wf-private-indicator *ngIf="row.isPrivateAction"></wf-private-indicator>
                </app-delegated-user-display>
            </td>
        </ng-container>
        <ng-container matColumnDef="creator">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.creator" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ getUserName(row.creator) }}
            </td>
        </ng-container>
        <ng-container matColumnDef="team">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{'actions.team' | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                <app-team-display [team]="row.team" [company]="row.company"></app-team-display>
            </td>
        </ng-container>
        <ng-container matColumnDef="createdDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.created" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.createdDateLocal | dateFormat }}
            </td>
        </ng-container>
        <ng-container matColumnDef="dueDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.due" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.dueDateLocal | dateFormat }}
            </td>
        </ng-container>
        <ng-container matColumnDef="department">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.department" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.department?.name }}
            </td>
        </ng-container>
        <ng-container matColumnDef="category">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.category" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.category?.description }}
            </td>
        </ng-container>
        <ng-container matColumnDef="subCategory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                {{"actions.subCategory" | translate}}
            </th>
            <td mat-cell *matCellDef="let row">
                {{ row.category?.subCategory?.description }}
            </td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>
                <app-table-settings-menu [columnDefinitions]="columnDefinitions"
                    [tableSettings]="tableSettings$ | async" (tableSettingsChange)="tableUpdated($event)">
                </app-table-settings-menu>
            </th>
            <td mat-cell *matCellDef="let row" class="actions_options goals_optionsDiv">
                <div *ngIf="menuType !== 'none'" class="button-holder">
                    <app-feed-button (openFeed)="openFeed(row)" [reference]="row.feedPartition"
                        [entity]="row" [mapEntityToTeam]="getDelegatedItemCompanyTeam">
                    </app-feed-button>
                    <ng-container *ngIf="menuType === 'edit'">
                        <app-edit-action-menu [action]="row" [disabled]="disabled"
                            (updated)="reloadActions()" (deleted)="reloadParent()" (copied)="reloadActions()">
                        </app-edit-action-menu>
                    </ng-container>
                </div>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async;"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns$ | async"
            [class.action-overdue]="isActionOverdue(row)" [class.action-complete]="isActionComplete(row)"
            [class.action-cancelled]="isActionCancelled(row)"
            [wfRowFocus]="row" [wfRowFocusIndex]="i"></tr>
        <tr mat-row *matNoDataRow>
            <td [attr.colSpan]="(displayedColumns$ | async)?.length">
                <span class="no-records">
                    {{'NoRecordsFound.noRecordsFound!' | translate}}
                </span>
            </td>
        </tr>
    </table>
</div>
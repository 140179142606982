import { Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

import { FeedPartitionContext, feedPartitionContextDescription, partitionKeysEqual } from "~feed/services/feed.common";

@Component({
    selector: "app-feed-partition-context",
    template: `
    <mat-chip-set>
        <mat-chip color="primary" highlighted (click)="onClick()" class="small-chip"
            [class.context-selected]="areContextsEqual" [disableRipple]="areContextsEqual">
            {{ getDescription() }}
        </mat-chip>
    </mat-chip-set>
    `,
    styleUrls: ["./feed-partition-context.component.scss"]
})
export class FeedPartitionContextComponent implements OnChanges {
    /** The context to display (relative to the viewContext if specified). */
    @Input() context!: FeedPartitionContext;

    /** The overall context that the user is viewing. */
    @Input() viewContext?: FeedPartitionContext;

    @Output() contextSelected = new EventEmitter<FeedPartitionContext>();

    areContextsEqual = false;

    constructor(private readonly translate: TranslateService) {
    }

    readonly getDescription = () => feedPartitionContextDescription(this.translate, this.context, this.viewContext);

    ngOnChanges() {
        this.areContextsEqual = partitionKeysEqual(this.context.key, this.viewContext?.key);
    }

    onClick = () => {
        if (this.areContextsEqual) return;
        this.contextSelected.emit(this.context);
    };
}

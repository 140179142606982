<app-discussion-homepage [referredSolution]="solution" readonly></app-discussion-homepage>

<form [formGroup]="form" *ngIf="fromMeeting" class="dialog-footer">
    <mat-form-field>
        <mat-label>{{'discussions.approvals.reviewNotes' | translate}}</mat-label>
        <textarea matInput name="reviewNotes" formControlName="reviewNotes" autocomplete="off" maxlength="1000"
            cdkTextareaAutosize cdkAutosizeMinRows="2" cdkAutosizeMaxRows="5" cdkFocusInitial></textarea>
    </mat-form-field>
    <div class="button-row">
        <app-status-button type="submit" (click)="save('reject')" [state]="rejectButtonState"
            [visualDisable]="!form.valid" [disabled]="!!approveButtonState">
            {{ "discussions.approvals.reject" | translate }}
        </app-status-button>
        <app-status-button type="submit" (click)="save('approve')" [state]="approveButtonState"
            [visualDisable]="!form.valid" [disabled]="!!rejectButtonState">
            {{ "discussions.approvals.approve" | translate }}
        </app-status-button>
    </div>
</form>
import { computed, Signal } from "@angular/core";
import { CurrentCompanyDto } from "@api";

export class CompanySettingsContext {

    readonly useAucbgMenus = computed(() => this.company()?.settings?.useAucbgMenus ?? false);

    constructor(
        private readonly company: Signal<CurrentCompanyDto | null>,
    ) { }
}

<table mat-table class="wf-table" [dataSource]="dataSource" matSort wfTableFocus
    matSortActive="dueDate" matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'actions.description' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <a class="multiline-description" (click)="view(element)" wfEmptyLink>
                {{element.description }}
            </a>
        </td>
    </ng-container>
    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.owner' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-delegated-user-display [entity]="element">
                {{ getUserName(element.owner) }}
                <wf-private-indicator *ngIf="element.isPrivateAction"></wf-private-indicator>
            </app-delegated-user-display>
        </td>
    </ng-container>
    <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.dueDate' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.dueDateLocal | dateFormat}}
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'actions.status' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-action-progress [progress]="element.progress">
            </app-action-progress>
        </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns" [wfRowFocus]="row" [wfRowFocusIndex]="i">
    </tr>
</table>
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, input, output } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { SimpleCompanyTeamDto, SimpleUserDto } from "@api";
import { distinctUntilChanged, map, Observable, of, switchMap, tap } from "rxjs";

import { UserRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getUserName } from "~shared/util/user-helper";

@Component({
    selector: "app-table-user-filter",
    templateUrl: "./table-user-filter.component.html",
    styleUrl: "./table-user-filter.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableUserFilterComponent extends WithDestroy() {

    readonly team = input<SimpleCompanyTeamDto | null>(null);
    readonly selected = output<SimpleUserDto | null>();

    readonly withSortHeader = input<boolean, BooleanInput>(false, { transform: coerceBooleanProperty });

    selectedUser: SimpleUserDto | null = null;

    readonly users$: Observable<SimpleUserDto[]>;

    readonly getUserName = getUserName;

    constructor(
        private readonly userRepository: UserRepository,
        private readonly teamContext: TeamContext,
    ) {
        super();

        const companyTeamId$ = toObservable(this.team).pipe(
            switchMap(team => team ? of({ companyId: team.company.id, teamId: team.id }) :
                this.teamContext.companyTeam$.pipe(
                    map(ct => ct && ({ companyId: ct.company.id, teamId: ct.team.id })),
                )),
            distinctUntilChanged((a, b) => a?.companyId === b?.companyId && a?.teamId === b?.teamId),
        );
        this.users$ = companyTeamId$.pipe(
            switchMap(ids => !ids ? of([]) : this.userRepository.getTeamMembers(ids.companyId, ids.teamId)),
            tap(users => {
                if (this.selectedUser && !users.some(u => u.userId === this.selectedUser?.userId)) {
                    this.selectUser(null);
                }
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    selectUser = (user: SimpleUserDto | null) => {
        this.selectedUser = user;
        this.selected.emit(user);
    };
}

/* eslint-disable max-classes-per-file */
import { ReferredSolutionFeedApi, ReferredSolutionFeedContext } from "@api";

import { GenericFeedAdapter } from "./feed.adapter";
import { FeedReference } from "./feed.common";

type ReferredSolutionScope = readonly [companyId: string, teamId: string, solutionId: string];

export class ReferredSolutionFeedAdapter extends GenericFeedAdapter<ReferredSolutionScope> {

    protected get scope() {
        return [this.reference.context.companyId, this.reference.context.teamId, this.reference.context.solutionId] as const;
    }

    constructor(
        readonly reference: Readonly<FeedReference<ReferredSolutionFeedContext>>,
        api: ReferredSolutionFeedApi,
    ) {
        super(api);
    }
}

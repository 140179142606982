/* eslint-disable max-classes-per-file */
import { booleanAttribute, Component, ContentChild, Directive, Input, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTable } from "@angular/material/table";
import { GetActionDetailsDto, GetActionDto, SimpleUserDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { map, Observable } from "rxjs";

import { ActionHomepageDialogComponent } from "~homepage";
import { getActionProgressSortOrder } from "~shared/action-progress";
import { ActionProgress, EntityType } from "~shared/enums";
import { ActionColumn } from "~shared/util/action-columns";
import { defaultActionsFilterPredicate } from "~shared/util/table-filtering";
import { getOriginNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

import { GenericDataSourceTable } from "../generic-table/generic-table.directive";
import { ExtraTableOptionsContext } from "../generic-table/generic-table-shared";
import { isActionForSolution } from "../view-action-solution-button/view-action-solution-button.component";

const DEFAULT_COLUMNS: ActionColumn[] = ["description", "status", "owner", "dueDate"];

declare type ExtendedActionColumn = ActionColumn | "actionDiscuss" | "options";

declare type ActionDto = GetActionDto | GetActionDetailsDto;

declare type FilterMode = "owner" | "custom";

declare type ChildButtonMode = "discuss" | "actionDiscuss";

declare type MenuType = "edit" | "feedOnly";

@Directive({
    selector: "[appExtraActionOptions]",
})
export class ExtraActionOptionsDirective {
    static ngTemplateContextGuard(dir: ExtraActionOptionsDirective, ctx: unknown): ctx is ExtraTableOptionsContext<ActionDto> {
        return true;
    }
}

@Component({
    selector: "app-generic-actions-table",
    templateUrl: "./generic-actions-table.component.html",
    styleUrls: ["./generic-actions-table.component.scss"]
})
export class GenericActionsTableComponent extends GenericDataSourceTable<ActionDto, ActionColumn> {

    @Input() filterMode: FilterMode = "owner";
    @Input() childButtonMode: ChildButtonMode = "actionDiscuss";
    @Input() menuType: MenuType = "edit";
    @Input({ transform: booleanAttribute }) fromMeeting = false;

    @ContentChild(ExtraActionOptionsDirective, { read: TemplateRef, static: false })
    extraOptionsTemplate: TemplateRef<ExtraTableOptionsContext<ActionDto>> | null = null;

    @ViewChild(MatSort, { static: true }) set matSort(value: MatSort | null) {
        if ("sort" in this.dataSource) this.dataSource.sort = value;
        this.matSortInternal = value;
    }

    get matSort(): MatSort | null {
        return this.matSortInternal;
    }

    @ViewChild(MatTable, { static: true }) table: MatTable<ActionDto> | null = null;

    readonly displayedColumns$: Observable<ExtendedActionColumn[]>;

    readonly getUserName = getUserName;
    readonly isActionForSolution = isActionForSolution;
    readonly defaultOrigin = EntityType.action;

    private matSortInternal: MatSort | null = null;

    constructor(
        private readonly dialog: MatDialog,
        private readonly translate: TranslateService,
    ) {
        super();

        if ("filter" in this.dataSource) {
            this.dataSource.filterPredicate = defaultActionsFilterPredicate;
        }

        this.displayedColumns$ = this.columnsSubject.pipe(
            map(columns => [
                ...columns,
                "actionDiscuss",
                "options",
            ]),
        );

        this.columns = DEFAULT_COLUMNS;
        this.defaultSort = "dueDate";
    }

    applyOwnerFilter = (user: SimpleUserDto | null) => {
        if ("filter" in this.dataSource) {
            this.dataSource.filter = user?.userId || null;
        }
    };

    isOverdue = (action: ActionDto) => action.progress === ActionProgress.overdue;

    shouldShowDueDateHistory = (action: ActionDto): action is GetActionDetailsDto =>
        "dueDateHistory" in action && !!action.dueDateHistory && action.dueDateHistory.length > 1;

    viewAction = (action: ActionDto, focusFeed = false) =>
        ActionHomepageDialogComponent.open(this.dialog, action, { focusFeed });

    openFeed = (action: ActionDto) => this.viewAction(action, /* focusFeed: */ true);

    actionUpdated = (action: ActionDto) =>
        this.updated.emit({ type: "updated", item: action });

    actionDeleted = (action: ActionDto) =>
        this.updated.emit({ type: "deleted", item: action });

    childActionAdded = (action: GetActionDto) =>
        this.updated.emit({ type: "child", item: action, childType: "action" });

    discussionAdded = (action: ActionDto) =>
        this.updated.emit({ type: "child", item: action, childType: "discussion" });

    trackById = (_: number, action: ActionDto) => action.id;

    protected getMatSort = () => this.matSortInternal;

    protected sortingDataAccessor = (data: ActionDto, property: ActionColumn) => {
        switch (property) {
            case "description": return data.description;
            case "status": return getActionProgressSortOrder(data.progress);
            case "priority": return data.priority as number ?? 0;
            case "owner": return getUserName(data.owner);
            case "creator": return getUserName(data.creator);
            case "team": return data.team.name;
            case "origin": return this.translate.instant(getOriginNameKey(data.origin?.type ?? this.defaultOrigin)) as string;
            case "createdDate": return data.createdDate;
            case "dueDate": return data.dueDate;
            case "department": return data.department?.name ?? "";
            case "category": return data.category?.description ?? "";
            case "subCategory": return data.category?.subCategory?.description ?? "";
        }
    };
}

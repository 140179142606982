/* eslint-disable max-classes-per-file */
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Directive, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { CurrentCompanyDto, CurrentTeamDto, DiscussionAndSolutionDto, DiscussionsApi, FeatureCapStatusDto } from "@api";
import { catchError, defer, EMPTY, filter, Observable, of, switchMap, tap } from "rxjs";

import { IssuesCappedDialogComponent } from "~/app/plan-shared/dialogs";
import { TeamContext } from "~services/contexts";
import { QuickAddDiscussionDialogComponent } from "~shared/dialogs";
import { IDiscussionInputModel } from "~shared/dialogs/quick-add-discussion-dialog/quick-add-discussion-dialog.component";
import { IOriginDetails } from "~shared/util/origin-builder";

@Directive()
export abstract class BaseAddChildDiscussionButtonDirective<TSource = unknown> {
    abstract readonly mapToOrigin?: (source: TSource) => IOriginDetails;
    abstract readonly mapToInput?: (source: TSource) => IDiscussionInputModel;

    abstract readonly hasDiscussions: boolean;
    abstract readonly hasUnsolvedDiscussions: boolean;

    @Input() source?: TSource;

    @Input() set disabled(value: BooleanInput) {
        this.disabledInternal = coerceBooleanProperty(value);
    }

    get disabled(): boolean {
        return this.disabledInternal;
    }

    @Output() discussionAdded = new EventEmitter<DiscussionAndSolutionDto>();

    get isCreationAllowed(): boolean {
        return !!this.source && (!this.creationAllowed || this.creationAllowed(this.source));
    }

    protected onDiscussionCreated?: (source: TSource, discussion: DiscussionAndSolutionDto) => void;
    protected creationAllowed?: (source: TSource) => boolean;

    private disabledInternal = false;
    private isCheckingCap = false;

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
    ) { }

    addDiscussion = () => {
        const source = this.source;
        if (!source) return;
        const origin = this.mapToOrigin?.(source);
        if (!origin) return;

        const companyTeam = this.teamContext.companyTeam();
        if (!companyTeam) return;
        const { company, team } = companyTeam;

        const input = this.mapToInput?.(source);
        if (input && origin.companyId !== company.id) {
            // We will be creating the discussion in a different company than the origin,
            // so we cannot use the company-related properties from the origin.
            input.departmentId = undefined;
            input.categoryId = undefined;
            input.subCategoryId = undefined;
        }

        this.checkCapStatus(company, team).pipe(
            switchMap(() =>
                QuickAddDiscussionDialogComponent.open(this.dialog, {
                    companyId: company.id,
                    teamId: team.id,
                    origin,
                    input,
                }).afterClosed()),
            filter(Boolean),
        ).subscribe(res => {
            this.discussionAdded.emit(res);
            this.onDiscussionCreated?.(source, res);
        });
    };

    private checkCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto): Observable<void> =>
        defer(() => of(this.isCheckingCap)).pipe(
            filter(isCheckingCap => !isCheckingCap),
            tap(() => this.isCheckingCap = true),
            switchMap(() => this.getCapStatus(company, team)),
            tap(() => this.isCheckingCap = false),
            switchMap(this.handleCapStatus),
        );

    private getCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto): Observable<FeatureCapStatusDto> => {
        const issueCap = company.planTier.featureConstraints.openIssueCount;
        if (issueCap === null || issueCap === undefined) {
            return of({
                canAdd: true,
                actualCount: 0,
                cap: undefined
            });
        }

        return this.discussionsApi.getCapStatus(
            company.id,
            team.id
        ).pipe(catchError(() => of({
            canAdd: true,
            actualCount: 0,
            cap: undefined
        })));
    };

    private handleCapStatus = (capStatus: FeatureCapStatusDto): Observable<void> => {
        if (capStatus.canAdd) return of(undefined);

        IssuesCappedDialogComponent.open(this.dialog);
        return EMPTY;
    };
}

@Component({
    selector: "app-add-child-discussion-button",
    templateUrl: "./add-child-discussion-button.component.html",
    styleUrls: ["./add-child-discussion-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddChildDiscussionButtonComponent<TSource = unknown> extends BaseAddChildDiscussionButtonDirective<TSource> {
    @Input() mapToOrigin?: (source: TSource) => IOriginDetails;
    @Input() mapToInput?: (source: TSource) => IDiscussionInputModel;
    @Input() hasDiscussions = false;
    @Input() hasUnsolvedDiscussions = false;
}

export interface CommonItemWithDiscussions {
    discussionsCount: number;
    unsolvedDiscussionsCount: number;
}

@Directive()
export abstract class CommonAddChildDiscussionButtonDirective<TSource extends CommonItemWithDiscussions = CommonItemWithDiscussions>
    extends BaseAddChildDiscussionButtonDirective<TSource> {

    protected abstract notifyChange: (source: TSource) => void;

    get hasDiscussions(): boolean {
        return !!this.source && !!this.source.discussionsCount;
    }

    get hasUnsolvedDiscussions(): boolean {
        return !!this.source && !!this.source.unsolvedDiscussionsCount;
    }

    protected onDiscussionCreated = (source: TSource, _: DiscussionAndSolutionDto): void => {
        source.discussionsCount++;
        source.unsolvedDiscussionsCount++;
        this.notifyChange(source);
    };
}

import { ComponentType } from "@angular/cdk/portal";
import { Inject } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetWatchlistDto, UpdateWatchlistDto } from "@api";
import { Observable } from "rxjs";

import { NotificationService } from "~services/notification.service";
import { ButtonState } from "~shared/components/status-button/status-button.component";

export interface EditWatchlistDialogData<TList> {
    list?: TList;
}

export abstract class BaseEditWatchlistDialogComponent<TList extends GetWatchlistDto, TData extends EditWatchlistDialogData<TList>> {

    buttonState: ButtonState;

    readonly nameControl = new FormControl<string | null>(null, [Validators.required, Validators.maxLength(50)]);

    readonly form = new FormGroup({
        name: this.nameControl,
    });

    get isNew(): boolean {
        return !this.list;
    }

    protected readonly list: TList | undefined;

    constructor(
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<BaseEditWatchlistDialogComponent<TList, TData>, TList>,
        @Inject(MAT_DIALOG_DATA) data: TData,
    ) {
        this.list = data.list;

        if (this.list) {
            this.nameControl.reset(this.list.name);
        }
    }

    protected static openInternal<TList extends GetWatchlistDto, TData extends EditWatchlistDialogData<TList>>(
        component: ComponentType<BaseEditWatchlistDialogComponent<TList, TData>>,
        dialog: MatDialog, data: TData): MatDialogRef<unknown, TList> {
        return dialog.open(component, {
            width: "500px",
            data
        });
    }

    protected abstract addList(dto: UpdateWatchlistDto): Observable<TList>;
    protected abstract updateList(list: TList, dto: UpdateWatchlistDto): Observable<TList>;

    save = () => {
        if (this.buttonState || !this.form.valid) return;
        this.buttonState = "loading";

        const dto: UpdateWatchlistDto = {
            name: this.nameControl.value ?? "",
        };

        const operation$ = this.list ? this.updateList(this.list, dto) : this.addList(dto);

        operation$.subscribe({
            next: result => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(result);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                this.notificationService.errorUnexpected();
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
            }
        });
    };

}

import { CdkTableModule } from "@angular/cdk/table";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { TranslateModule } from "@ngx-translate/core";

import { PlanSharedModule } from "~plan-shared";
import { WfDialogModule } from "~shared/dialog";
import { SharedModule } from "~shared/shared.module";

import {
    DialogAddAnnualGoalTypeforConfigComponent
} from "./dialogs/dialog-add-annual-goal-typefor-config/dialog-add-annual-goal-typefor-config.component";
import { DialogAddConfigSubCategoryComponent } from "./dialogs/dialog-add-config-sub-category/dialog-add-config-sub-category.component";
import { DialogAddHirePlanComponent } from "./dialogs/dialog-add-hire-plan/dialog-add-hire-plan.component";
import { DialogAddPlanAnnualComponent } from "./dialogs/dialog-add-plan-annual/dialog-add-plan-annual.component";
import { DialogAddUpdateDatesComponent } from "./dialogs/dialog-add-update-dates/dialog-add-update-dates.component";
import { DialogAddWorkCategoryComponent } from "./dialogs/dialog-add-work-category/dialog-add-work-category.component";
import { DialogCloneTemplateComponent } from "./dialogs/dialog-clone-template/dialog-clone-template.component";
import { DialogConfirmComponent } from "./dialogs/dialog-confirm/dialog-confirm.component";
import { DialogDeleteComponent } from "./dialogs/dialog-delete/dialog-delete.component";
import { DialogInactivePartnerComponent } from "./dialogs/dialog-inactive-partner/dialog-inactive-partner.component";
import { DialogSendSuggestionComponent } from "./dialogs/dialog-send-suggestion/dialog-send-suggestion.component";
import { DialogUpdateSuggestionComponent } from "./dialogs/dialog-update-suggestion/dialog-update-suggestion.component";
import { DialogUpdateTeamPaidStatusComponent } from "./dialogs/dialog-update-team-paid-status/dialog-update-team-paid-status.component";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CdkTableModule,
        TranslateModule,

        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        WfDialogModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatTableModule,

        SharedModule,
        PlanSharedModule,
    ],
    declarations: [
        DialogDeleteComponent,
        DialogConfirmComponent,
        DialogUpdateTeamPaidStatusComponent,
        DialogSendSuggestionComponent,
        DialogUpdateSuggestionComponent,
        DialogAddPlanAnnualComponent,
        DialogAddAnnualGoalTypeforConfigComponent,
        DialogAddWorkCategoryComponent,
        DialogAddConfigSubCategoryComponent,
        DialogAddUpdateDatesComponent,
        DialogAddHirePlanComponent,
        DialogInactivePartnerComponent,
        DialogCloneTemplateComponent,
    ],
    exports: []
})

export class MaterialComponentsModule { }

<ng-container *ngIf="entity; let e">
    <ng-container *ngIf="displayedInfo === 'base' || !delegationEnabled(); else assignee">
        <ng-content></ng-content>
        <app-delegation-info *ngIf="e.delegation && delegationEnabled() && (hasResponsibility || e.isDelegated)" [entity]="e"></app-delegation-info>
    </ng-container>
    <ng-template #assignee>
        <app-delegation-info [entity]="e" reverse></app-delegation-info>
        <ng-container *ngIf="mode === 'team'; else assigneeDisplay">
            <app-team-display [team]="e.delegation!.team" [company]="e.delegation!.company"></app-team-display>
        </ng-container>
        <ng-template #assigneeDisplay>
            {{ getUserName(e.delegation!.assignee) }}
        </ng-template>
    </ng-template>
</ng-container>
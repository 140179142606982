import { Component, computed, Input, Signal } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
    CurrentCompanyDto, CurrentTeamDto, CurrentUserDto, DiscussionsApi, FeatureCapStatusDto, NewsItemsApi
} from "@api";
import { catchError, defer, EMPTY, filter, Observable, of, switchMap, tap } from "rxjs";

import { IssuesCappedDialogComponent } from "~/app/plan-shared/dialogs";
import { NewsCappedDialogComponent } from "~/app/plan-shared/dialogs";
import { EditActionDialogComponent, EditDiscussionDialogComponent, EditNewsItemDialogComponent } from "~/app/shared/dialogs";
import { TeamContext, UserContext } from "~services/contexts";
import { PermissionType, Profile, Role } from "~shared/enums";
import { getProfileRoles } from "~shared/util/profile-helper";

declare type GlobalCreateButtonSize = "large" | "small";

interface IGlobalCreateItem {
    nameKey: string;
    show: Signal<boolean>;
    create: (() => unknown);
}

@Component({
    selector: "app-global-create-button",
    templateUrl: "./global-create-button.component.html",
    styleUrls: ["./global-create-button.component.scss"]
})
export class GlobalCreateButtonComponent {

    @Input() size: GlobalCreateButtonSize = "large";

    readonly itemsToBeCreated: IGlobalCreateItem[] = [
        {
            nameKey: "discussions.discussion",
            show: computed(() =>
                this.userContext.isFullUser() &&
                this.canAccessPage(this.userContext.user(), "discussions")),
            create: () => this.openDiscussionDialog()
        },
        {
            nameKey: "actions.action",
            show: computed(() =>
                this.userContext.isFullUser() &&
                this.canAccessPage(this.userContext.user(), "actions")),
            create: () => this.openActionDialog()
        },
        {
            nameKey: "news.news",
            show: computed(() =>
                this.userContext.isFullUser() &&
                this.teamContext.features.newsEnabled() &&
                this.canAccessPage(this.userContext.user(), "news")),
            create: () => this.openNewsDialog()
        }
    ];

    get isShow() {
        return this.itemsToBeCreated.some((item: IGlobalCreateItem) => item.show());
    }

    private fullUserRoles = getProfileRoles(Profile.fullUser);
    private isCheckingDiscussionsCap = false;
    private isCheckingNewsCap = false;

    constructor(
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
        private readonly discussionsApi: DiscussionsApi,
        private readonly newsItemsApi: NewsItemsApi,
    ) {
    }

    private canAccessPage = (user: CurrentUserDto | null, page: string): boolean => {
        if (!user) return false;

        // If the user is not of "User" role, they can access the feature
        if (user.roleName as Role !== Role.user) return true;

        return user.pagePermissions.some(p => p.name === page && p.permission === PermissionType.readWrite);
    };

    private openDiscussionDialog(): void {
        const companyTeam = this.teamContext.companyTeam();
        if (!companyTeam) return;
        const { company, team } = companyTeam;

        this.checkDiscussionCapStatus(company, team).pipe(
            switchMap(() =>
                EditDiscussionDialogComponent.openForAdd(this.dialog, {
                    companyId: company.id, teamId: team.id,
                }).afterClosed()
            ),
        ).subscribe();
    }

    private checkDiscussionCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto): Observable<void> =>
        defer(() => of(this.isCheckingDiscussionsCap)).pipe(
            filter(isCheckingCap => !isCheckingCap),
            tap(() => this.isCheckingDiscussionsCap = true),
            switchMap(() => this.getDiscussionCapStatus(company, team)),
            tap(() => this.isCheckingDiscussionsCap = false),
            switchMap(this.handleDiscussionCapStatus),
        );

    private getDiscussionCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto): Observable<FeatureCapStatusDto> => {
        const issueCap = company.planTier.featureConstraints.openIssueCount;
        if (issueCap === null || issueCap === undefined) {
            return of({
                canAdd: true,
                actualCount: 0,
                cap: undefined
            });
        }

        return this.discussionsApi.getCapStatus(
            company.id,
            team.id
        ).pipe(catchError(() => of({
            canAdd: true,
            actualCount: 0,
            cap: undefined
        })));
    };

    private handleDiscussionCapStatus = (capStatus: FeatureCapStatusDto): Observable<void> => {
        if (capStatus.canAdd) return of(undefined);

        IssuesCappedDialogComponent.open(this.dialog);
        return EMPTY;
    };

    private openActionDialog() {
        const companyTeam = this.teamContext.companyTeam();
        if (!companyTeam) return;
        const { company, team } = companyTeam;

        EditActionDialogComponent.openForAdd(this.dialog, {
            companyId: company.id,
            teamId: team.id,
        });
    }

    private openNewsDialog() {
        const companyTeam = this.teamContext.companyTeam();
        if (!companyTeam) return;
        const { company, team } = companyTeam;

        this.checkNewsCapStatus(company, team).pipe(
            switchMap(() =>
                EditNewsItemDialogComponent.openForAdd(this.dialog, {
                    clientId: company.clientId, companyId: company.id, teamId: team.id,
                }).afterClosed()
            ),
        ).subscribe();
    }

    private checkNewsCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto): Observable<void> =>
        defer(() => of(this.isCheckingNewsCap)).pipe(
            filter(isCheckingCap => !isCheckingCap),
            tap(() => this.isCheckingNewsCap = true),
            switchMap(() => this.getNewsCapStatus(company, team)),
            tap(() => this.isCheckingNewsCap = false),
            switchMap(this.handleNewsCapStatus),
        );

    private getNewsCapStatus = (company: CurrentCompanyDto, team: CurrentTeamDto): Observable<FeatureCapStatusDto> => {
        const issueCap = company.planTier.featureConstraints.openIssueCount;
        if (issueCap === null || issueCap === undefined) {
            return of({
                canAdd: true,
                actualCount: 0,
                cap: undefined
            });
        }

        return this.newsItemsApi.getCapStatus(
            company.id,
            team?.id
        ).pipe(catchError(() => of({
            canAdd: true,
            actualCount: 0,
            cap: undefined
        })));
    };

    private handleNewsCapStatus = (capStatus: FeatureCapStatusDto): Observable<void> => {
        if (capStatus.canAdd) return of(undefined);

        NewsCappedDialogComponent.open(this.dialog);
        return EMPTY;
    };
}

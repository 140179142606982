import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";

export const TABLE_SETTING_PREFIX = "TableSetting_";

/**
 * The type of lock a column can have:
 * "visibility": Column cannot be hidden
 * "position": Column cannot be hidden or moved
 */
export declare type ColumnLockType = "visibility" | "position";

export interface ColumnDefinition<TKey extends string = string> {
    /**
     * The unique idenfifier of the column.
     */
    readonly key: TKey;
    /**
     * The localisation key of the column name.
     */
    nameKey: string | (() => string);
    /**
     * Whether the column is visible by default.
     */
    defaultVisibility?: boolean;
    /**
     * If set, the type of lock placed on the column.
     */
    lockType?: ColumnLockType;
    /**
     * Whether the column is available to use. If not specified, assumed to be enabled.
     */
    isEnabled?: undefined | true | (() => boolean);
}

export interface ColumnSetting<TKey extends string = string> {
    readonly key: TKey;
    visible: boolean;
}

export interface TableSettings<TKey extends string = string> {
    columns: ColumnSetting<TKey>[];
}

export const defaultColumnBuilder =
    <TKey extends string = string>(key: TKey, nameKey: string | (() => string), lockType?: ColumnLockType): ColumnDefinition<TKey> =>
        ({ key, nameKey, defaultVisibility: true, lockType });

export const columnBuilder =
    <TKey extends string = string>(key: TKey, nameKey: string): ColumnDefinition<TKey> => ({ key, nameKey });

export const getDisplayedColumns =
    <TKey extends string = string>(
        settings: Readonly<TableSettings<TKey>>,
        columnDefinitions: Readonly<ColumnDefinition<TKey>>[],
    ): TKey[] =>
        settings.columns.filter((c): c is ColumnSetting<TKey> => {
            const defaultColumn = columnDefinitions.find(d => d.key === c.key);
            if (!defaultColumn) return false;
            if (typeof defaultColumn.isEnabled === "function" && !defaultColumn.isEnabled()) return false;
            if (defaultColumn.lockType) return true;
            return c.visible;
        }).map(c => c.key);

export const getDisplayedColumns$ =
    <TKey extends string = string>(
        settings$: Observable<Readonly<TableSettings<TKey>>>,
        columnDefinitions$: Observable<Readonly<ColumnDefinition<TKey>>[]>,
    ): Observable<TKey[]> =>
        combineLatest({ settings: settings$, columnDefinitions: columnDefinitions$ }).pipe(
            map(({ settings, columnDefinitions }) => getDisplayedColumns(settings, columnDefinitions)),
        );

const buildDefaultColumnSettings =
    <TKey extends string = string>(columnDefinitions: Readonly<ColumnDefinition<TKey>>[]): ColumnSetting<TKey>[] =>
        columnDefinitions.map(c => ({
            key: c.key,
            visible: (c.defaultVisibility || !!c.lockType)
        } as ColumnSetting<TKey>));

const mergeSettingsWithDefaults = <T extends TableSettings>(columnDefinitions: Readonly<ColumnDefinition>[], settings: T) => {
    let columns = settings.columns;

    // Remove unknown columns
    columns = columns.filter(c => columnDefinitions.some(d => d.key === c.key));

    // Add new columns
    const newColumns = columnDefinitions.filter(d => columns.every(c => c.key !== d.key));
    const newColumnSettings = buildDefaultColumnSettings(newColumns);
    for (const column of newColumnSettings) {
        const originalIndex = columnDefinitions.findIndex(d => d.key === column.key);
        if (originalIndex < 0) {
            columns.push(column); // should never occur
            continue;
        }
        columns.splice(originalIndex, 0, column);
    }

    // Ensure locks are in place
    for (let i = 0; i < columnDefinitions.length; i++) {
        const defaultColumn = columnDefinitions[i];
        if (!defaultColumn.lockType) continue;

        switch (defaultColumn.lockType) {
            case "visibility": {
                const column = columns.find(c => c.key === defaultColumn.key);
                if (!column || column.visible) break; // should never occur
                column.visible = true;
                break;
            }
            case "position": {
                const columnIndex = columns.findIndex(c => c.key === defaultColumn.key);
                if (columnIndex < 0) break; // should never occur
                columns[columnIndex].visible = true;
                if (columnIndex === i) {
                    break;
                }
                const column = columns.splice(columnIndex, 1)[0];
                columns.splice(i, 0, column);
                // If we have readjusted an earlier column, we need to re-scan from there in case we
                // have inadvertantly moved another fixed-position column.
                if (columnIndex < i) i = columnIndex - 1;
            }
        }
    }

    settings.columns = columns;
    return settings;
};

export const buildDefaultTableSettings =
    <TKey extends string = string, TSettings extends TableSettings<TKey> = TableSettings<TKey>>(
        columnDefinitions: Readonly<ColumnDefinition<TKey>>[]
    ): TSettings => ({
        columns: buildDefaultColumnSettings(columnDefinitions)
    } as TSettings);

const getTableKey = (tableName: string) => TABLE_SETTING_PREFIX + tableName;

type GetSettingsFunc =
    (<TKey extends string = string, TSettings extends TableSettings<TKey> = TableSettings<TKey>>(
        tableName: string,
        columnDefinitions: Readonly<ColumnDefinition<TKey>>[]
    ) => Observable<TSettings>) &
    (<TSettings extends TableSettings = TableSettings>(
        tableName: string,
        columnDefinitions: Readonly<ColumnDefinition>[]
    ) => Observable<TSettings>);

@Injectable({
    providedIn: "root"
})
export class TableSettingsService {

    private tableCache: { [tableName: string]: BehaviorSubject<TableSettings> } = {};

    getTableSettings: GetSettingsFunc = <TKey extends string = string, TSettings extends TableSettings<TKey> = TableSettings<TKey>>(
        tableName: string,
        columnDefinitions: Readonly<ColumnDefinition<TKey>>[]
    ): Observable<TSettings> => {
        const savedSettings = this.getSettingsFromStorage<TSettings>(tableName);

        let settings: TSettings;
        if (savedSettings) {
            settings = mergeSettingsWithDefaults(columnDefinitions, savedSettings);
        } else {
            settings = buildDefaultTableSettings(columnDefinitions);
        }

        const key = getTableKey(tableName);
        // We cast as unknown first as we assume the type extends the supplied type.
        let cachedData = this.tableCache[key] as unknown as BehaviorSubject<TSettings> | null;
        if (!cachedData) {
            cachedData = new BehaviorSubject<TSettings>(settings);
            this.tableCache[key] = cachedData as unknown as BehaviorSubject<TableSettings>;
        } else {
            cachedData.next(settings);
        }
        return cachedData.asObservable();
    };

    setTableSettings = <TSettings extends TableSettings>(tableName: string, settings: TSettings): void => {
        const key = getTableKey(tableName);
        localStorage.setItem(key, JSON.stringify(settings));
        const cachedData = this.tableCache[key];
        if (cachedData) {
            cachedData.next(settings);
        }
    };

    clearTableSettings = (tableName: string): void => {
        localStorage.removeItem(getTableKey(tableName));
    };

    private getSettingsFromStorage = <T>(tableName: string): T | null => {
        const key = getTableKey(tableName);
        const stringValue = localStorage.getItem(key);
        try {
            return stringValue ? JSON.parse(stringValue) as T : null;
        } catch {
            return null;
        }
    };
}

import { ActionProgress } from "./enums";

export const getActionProgressSortOrder = (progress: ActionProgress) => {
    switch (progress) {
        case ActionProgress.overdue: return 0;
        case ActionProgress.notStarted: return 1;
        case ActionProgress.inProgress: return 2;
        case ActionProgress.complete: return 3;
        case ActionProgress.cancelled: return 4;
        default: return 1;
    }
};

import {
    CompanyTeamActionFeedContext, DayOfWeek, FeedPartitionDto, GoalFeedContext, NewsItemFeedContext,
    NumberFeedContext, ReferredSolutionFeedContext, ReportFeedContext
} from "@api";
import { TranslateService } from "@ngx-translate/core";

import { getShortDayOfWeekNameKey } from "~shared/util/translation-helper";

export enum FeedPartitionKeyType {
    /** Represents a time period - year, quarter or week */
    period = 1,
    /** Partition represents a sequence of partitions - used for recurring actions */
    sequence = 2,
}

export interface FeedPartitionContext {
    readonly key: FeedPartitionKey;
    readonly type: FeedPartitionKeyType;
}

export interface CompanyTeamFeedContext {
    companyId: string;
    teamId: string;
}

export type FeedContext =
    CompanyTeamFeedContext | NewsItemFeedContext | CompanyTeamActionFeedContext | ReferredSolutionFeedContext |
    GoalFeedContext | NumberFeedContext | ReportFeedContext;

export interface FeedPeriod {
    readonly financialYear: number;
    readonly planningPeriod?: number;
    readonly collectionPeriod?: number;
    readonly updateDay?: DayOfWeek;
}

export interface FeedReference<TContext extends FeedContext = FeedContext> {
    readonly context: TContext;
    readonly partition: FeedPartitionDto;
    readonly initialKey?: FeedPartitionKey;
    /** Defaults to 'period' in not specified. */
    readonly partitionType?: FeedPartitionKeyType;
}

export type FeedPartitionKey = number[];

export const isCompanyTeamFeedContext = (context: FeedContext): context is CompanyTeamFeedContext =>
    !!context && !!context.companyId && !!context.teamId;

export const isNewsItemFeedContext = (context: FeedContext): context is NewsItemFeedContext =>
    isCompanyTeamFeedContext(context) && "newsItemId" in context;

export const isActionFeedContext = (context: FeedContext): context is CompanyTeamActionFeedContext =>
    isCompanyTeamFeedContext(context) && "actionId" in context;

export const isReferredSolutionContext = (context: FeedContext): context is ReferredSolutionFeedContext =>
    isCompanyTeamFeedContext(context) && "solutionId" in context;

export const isGoalFeedContext = (context: FeedContext): context is GoalFeedContext =>
    isCompanyTeamFeedContext(context) && "goalId" in context;

export const isNumberFeedContext = (context: FeedContext): context is NumberFeedContext =>
    isCompanyTeamFeedContext(context) && "numberId" in context;

export const isReportFeedContext = (context: FeedContext): context is ReportFeedContext =>
    isCompanyTeamFeedContext(context) && "reportId" in context;

/** Used for invoking API */
export const feedPartitionKeyToString = (key: FeedPartitionKey) => key.join("-");

export const partitionKeysEqual = (a?: FeedPartitionKey, b?: FeedPartitionKey) => {
    if (!a?.length && !b?.length) return true;
    return Boolean(a?.length === b?.length && a?.every((x, i) => x === (b ? b[i] : false)));
};

export const periodToFeedPartitionKey =
    ({ financialYear, planningPeriod: planningPeriod, collectionPeriod: collectionPeriod }: FeedPeriod) =>
        [financialYear, planningPeriod, collectionPeriod].filter(Boolean);

const toDayOfWeek = (day: number | undefined): DayOfWeek | undefined => {
    // The partition key will be 1-based, starting on Monday.
    // The enum is 0-based, starting on Sunday.
    if (day === 7) return DayOfWeek.sunday;
    if (day != null && day >= 1 && day <= 6) return day as DayOfWeek;
    return undefined;
};

export const feedPartitionKeyToPeriod = (key: Partial<FeedPartitionKey>): Partial<FeedPeriod> => ({
    financialYear: key[0],
    planningPeriod: key.length >= 2 ? key[1] : undefined,
    collectionPeriod: key.length >= 3 ? key[2] : undefined,
    updateDay: key.length >= 4 ? toDayOfWeek(key[3]) : undefined,
});

export const areFeedsEqual = (a: FeedReference<FeedContext> | null, b: FeedReference<FeedContext> | null): boolean => {
    if (a === b) return true;
    if (!a && !b) return true;
    if (!a || !b) return false;

    if (!partitionKeysEqual(a.partition.key, b.partition.key)) return false;

    if (a.context.companyId !== b.context.companyId || a.context.teamId !== b.context.teamId) return false;

    if (isNewsItemFeedContext(a.context) && isNewsItemFeedContext(b.context)) {
        return a.context.newsItemId === b.context.newsItemId;
    }

    if (isActionFeedContext(a.context) && isActionFeedContext(b.context)) {
        return a.context.actionId === b.context.actionId;
    }

    if (isReferredSolutionContext(a.context) && isReferredSolutionContext(b.context)) {
        return a.context.solutionId === b.context.solutionId;
    }

    if (isGoalFeedContext(a.context) && isGoalFeedContext(b.context)) {
        return a.context.goalId === b.context.goalId && a.context.period === b.context.period;
    }

    if (isNumberFeedContext(a.context) && isNumberFeedContext(b.context)) {
        return a.context.numberId === b.context.numberId && a.context.period === b.context.period;
    }

    if (isReportFeedContext(a.context) && isReportFeedContext(b.context)) {
        return a.context.reportId === b.context.reportId && a.context.period === b.context.period;
    }

    if (isCompanyTeamFeedContext(a.context) && isCompanyTeamFeedContext(b.context)) {
        return a.partition.feedId === b.partition.feedId;
    }

    return false;
};

export const ordinalIndexToFeedPartitionKey = (ordinalIndex?: number) => typeof ordinalIndex === "number" ? [ordinalIndex] : undefined;

/**
 * Returns key and all of the 'parent' keys of which this key is a part.
 * For example, [1, 2, 3] => [[1], [1, 2], [1, 2, 3]]
 */
export const feedPartitionKeyHierarchy = (key: FeedPartitionKey): FeedPartitionKey[] => key.map((_, index) => key.slice(0, index + 1));

/**
 * Nullifies the common values between the two contexts and returns the period's key relative to the viewPeriod.
 * e.g. viewPeriod: [1], period: [1, 2, 3] should return [undefined, 2, 3].
 * e.g. viewPeriod: [1, 2], period: [1, 2, 3] should return [undefined, undefined, 3].
 * e.g. viewPeriod: [8, 9], period: [1, 2, 3] should return [1, 2, 3].
 * e.g. viewPeriod: [1], period: [1] should return [1].
 */
const toRelativePartitionKey = (viewPeriod: FeedPartitionKey, period: FeedPartitionKey): Partial<FeedPartitionKey> => {
    let lastMatchingIndex = -1;

    for (let periodIndex = 0; periodIndex < period.length; periodIndex++) {
        if (viewPeriod[periodIndex] !== period[periodIndex]) break;
        lastMatchingIndex = periodIndex;
    }

    // Ensure that there is at least 1 key value return even if it matches the viewPeriod.
    // e.g. viewPeriod: [1, 2], period: [1, 2] should return [undefined, 2].
    lastMatchingIndex = Math.min(lastMatchingIndex + 1, period.length - 1);

    // Return undefined where the values match.
    return period.map((value, index) => index < lastMatchingIndex ? undefined : value);
};

/** Depending on which fields are set returns the full or partial description. */
const periodToDescription = (translate: TranslateService, period: Partial<FeedPeriod>) => [
    period.financialYear != null && translate.instant("feedPeriod.financialYear", period),
    period.planningPeriod != null && translate.instant("feedPeriod.planningPeriod", period),
    period.collectionPeriod != null && translate.instant("feedPeriod.collectionPeriod", period),
    period.updateDay != null && translate.instant(getShortDayOfWeekNameKey(period.updateDay)),
]
    .filter(x => !!x)
    .join(" ");

export const feedPartitionContextDescription = (
    translate: TranslateService, context?: FeedPartitionContext, viewContext?: FeedPartitionContext) => {

    if (!context) return "";

    if (context.key.length === 0) {
        return translate.instant("feedPeriod.all");
    }

    switch (context.type) {
        case FeedPartitionKeyType.sequence: {
            if (context.key.length !== 1) throw new Error("Expecting key of length 1");
            const sequence = context.key[0] + 1; // Translate into a 1-based index.
            return translate.instant("feedPeriod.sequence", { sequence });
        }
        case FeedPartitionKeyType.period: {
            if (!viewContext) return periodToDescription(translate, feedPartitionKeyToPeriod(context.key));
            const relativePeriod = feedPartitionKeyToPeriod(toRelativePartitionKey(viewContext.key, context.key));
            return periodToDescription(translate, relativePeriod);
        }
        default:
            return "";
    }
};


import * as moment from "moment";

import timemachine from "./time-machine-internal";

const FIXED_DATE_ITEM_KEY = "fixedDate";

const updateTimeMachine = (fixedDate: moment.Moment | null) => {
    timemachine.reset();
    if (fixedDate) {
        timemachine.config({
            dateString: fixedDate.format("YYYY-MM-DD") + "T" + moment().format("HH:mm:ss.SSS"),
            tick: true
        });
    }
};

export const getFixedDate = (): moment.Moment | null => {
    const savedValue = localStorage.getItem(FIXED_DATE_ITEM_KEY);
    return savedValue ? moment(savedValue, moment.ISO_8601) : null;
};

export const saveFixedDate = (fixedDate: moment.Moment | null): void => {
    if (!fixedDate) {
        localStorage.removeItem(FIXED_DATE_ITEM_KEY);
    } else {
        localStorage.setItem(FIXED_DATE_ITEM_KEY, fixedDate.toISOString());
    }
    updateTimeMachine(fixedDate);
};

export const initTimeMachine = (): void => {
    const fixedDate = getFixedDate();
    updateTimeMachine(fixedDate);
};

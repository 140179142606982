<form [formGroup]="form" (submit)="updateTarget()" class="float-right exec-score" *ngIf="featureAvailable$ | async">
    <mat-form-field floatLabel="always" class="execution-score mat-form-field-narrow" subscriptSizing="dynamic">
        <mat-label>{{ 'executionScoreTarget.executionScoreTarget' | translate }}</mat-label>
        <input matInput type="number" formControlName="target" class="exec-input" 
            (blur)="updateTarget()" (keyup.escape)="resetTarget()" min="0" max="100" placeholder="{{'executionScoreTarget.none' | translate }}" />
        <div matTextSuffix [class.locked-suffix]="locked">%</div>
    </mat-form-field>

    <button type="button" mat-icon-button (click)="locked = !locked" class="lock-button" color="primary"
        title="{{ (locked ? 'executionScoreTarget.unlockLabel' : 'executionScoreTarget.lockLabel') | translate }}"
        [class.lock-button-locked]="locked" [disabled]="form.disabled || form.dirty || !isEditable">
        <mat-icon fontSet="material-icons-outlined">{{ !locked ? "lock_open" : "lock" }}</mat-icon>
    </button>

    <button type="submit" style="display: none;">{{ 'Save' | translate }}</button>
</form>
import { Component, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GetActionDto } from "@api";

import { getActionProgressSortOrder } from "~shared/action-progress";
import { getUserName } from "~shared/util/user-helper";

import { ActionHomepageDialogComponent } from "../../dialogs";

@Component({
    selector: "app-related-actions-table",
    templateUrl: "./related-actions-table.component.html",
    styleUrls: ["./related-actions-table.component.scss"]
})
export class RelatedActionsTableComponent {

    @Input() set actions(value: GetActionDto[]) {
        this.dataSource.data = value;
    }

    get actions(): GetActionDto[] {
        return this.dataSource.data;
    }

    @ViewChild(MatSort, { static: true }) set sort(value: MatSort | null) {
        this.dataSource.sort = value;
    }

    get sort(): MatSort | null {
        return this.dataSource.sort;
    }

    readonly dataSource = new MatTableDataSource<GetActionDto>();
    readonly displayedColumns = ["description", "owner", "dueDate", "status"];

    readonly getUserName = getUserName;

    constructor(
        private readonly dialog: MatDialog,
    ) {
        this.dataSource.sortingDataAccessor = (data: GetActionDto, sortHeaderId: string) => {
            switch (sortHeaderId) {
                case "description": return data.description;
                case "owner": return getUserName(data.owner);
                case "status": return getActionProgressSortOrder(data.progress);
                default:
                    return (data as never)[sortHeaderId] ?? "";
            }
        };
    }

    view = (action: GetActionDto) => ActionHomepageDialogComponent.open(this.dialog, action);
}

<div class="homepage-heading">
    <h3 class="homepage-title">
        <ng-container *ngIf="!titleTemplate">{{ title }}</ng-container>
        <ng-container *ngTemplateOutlet="titleTemplate ?? null"></ng-container>
    </h3>
    <div class="homepage-title-actions" *ngIf="titleActionsTemplate">
        <ng-container *ngTemplateOutlet="titleActionsTemplate"></ng-container>
    </div>
    <div class="homepage-actions" *ngIf="actionsTemplate">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
    </div>
    @if (showCloseButton) {
        <wf-dialog-close-button></wf-dialog-close-button>
    }
</div>
<div class="homepage-body">
    <ng-content *ngIf="!bodyTemplate"></ng-content>
    <ng-container *ngTemplateOutlet="bodyTemplate ?? null"></ng-container>
</div>
<app-feed-list></app-feed-list>
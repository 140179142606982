import { NumberSuggestionDto } from "@api";

export const exampleNumbers: NumberSuggestionDto[] = [
    {
        "description": "Total sales revenue",
        "type": 1
    },
    {
        "description": "Number of new clients acquired",
        "type": 0
    },
    {
        "description": "Average deal size",
        "type": 1
    },
    {
        "description": "Conversion rate of leads to sales",
        "type": 2
    },
    {
        "description": "Number of sales calls made",
        "type": 0
    },
    {
        "description": "Percentage of sales team meeting quota",
        "type": 2
    },
    {
        "description": "Average response time to customer inquiries",
        "type": 0
    },
    {
        "description": "Number of upsell/cross-sell opportunities identified",
        "type": 0
    },
    {
        "description": "Percentage of repeat customers",
        "type": 2
    },
    {
        "description": "Sales team turnover rate",
        "type": 2
    }
];

import { MonoTypeOperatorFunction, Observable, of, switchMap } from "rxjs";

import {
    Comparator, compareByTeamIdAndWeek, compareReferenceByTeamIdAndWeek, ItemWithIdAndWeek, PeriodicItemReference
} from "./comparators";
import { mergeUpdatesFrom, UpdateHandler } from "./state-rx-operators";
import { StateEvent } from "./state-shared";

export const buildSimpleMergeHandler = <TItem, TReference>(
    comparator: Comparator<TItem>, referenceComparator: Comparator<TItem, TReference>):
    UpdateHandler<TItem[], StateEvent<TItem, TReference>> => (items, event) => {
        switch (event.type) {
            case "added":
                // For simple merging, we don't add any new items
                break;
            case "updated":
                if (items.some(item => comparator(item, event.item))) {
                    return items.map(item => comparator(item, event.item) ? event.item : item);
                }
                break;
            case "deleted":
                if (items.some(item => referenceComparator(item, event.item))) {
                    return items.filter(item => !referenceComparator(item, event.item));
                }
                break;
        }
        return null;
    };

export const buildRecordMergeHandler = <TItem extends ItemWithIdAndWeek, TReference extends PeriodicItemReference>():
    UpdateHandler<TItem[], StateEvent<TItem, TReference>> => buildSimpleMergeHandler<TItem, TReference>(
        compareByTeamIdAndWeek, compareReferenceByTeamIdAndWeek);

export const mergeRecordUpdatesFrom = <TItem extends ItemWithIdAndWeek, TReference extends PeriodicItemReference>(
    eventsCallback: (data: TItem[]) => Observable<StateEvent<TItem, TReference>>):
    MonoTypeOperatorFunction<TItem[]> => switchMap(data => {
        const events$ = eventsCallback(data);
        return of(data).pipe(
            mergeUpdatesFrom(events$, buildRecordMergeHandler()),
        );
    });

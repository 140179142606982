import { WfDialogConfig } from "~shared/dialog";

export interface HomepageDialogOptions {
    /**
     * Whether the feed should be focused when the dialog opens.
     */
    focusFeed?: boolean;
}

export const buildHomepageDialogConfig = (options?: HomepageDialogOptions): WfDialogConfig => ({
    maxWidth: "none",
    autoFocus: options?.focusFeed ? "app-feed-list input[name='comment']" : false,
    showCloseButton: false, // The close button is emitted by the homepage scaffold
});

import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatSelectModule } from "@angular/material/select";
import { MatSortModule } from "@angular/material/sort";
import { MatTableModule } from "@angular/material/table";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

import { FeedModule } from "~feed";
import { LinkedItemViewer } from "~services/linked-item-viewer.service";
import { WfDialogModule } from "~shared/dialog";
import { SharedModule } from "~shared/shared.module";

import components from "./components";
import dialogs from "./dialogs";
import { HomepageLinkedItemViewer } from "./services";

@NgModule({
    declarations: [
        components,
        dialogs,
    ],
    exports: [
        components,
        dialogs,
    ],
    imports: [
        CommonModule,
        TranslateModule.forChild(),

        MatButtonModule,
        WfDialogModule,
        MatIconModule,
        MatMenuModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatTooltipModule,

        SharedModule,
        FeedModule,
    ],
    providers: [
        {
            provide: LinkedItemViewer,
            useClass: HomepageLinkedItemViewer,
        },
    ]
})
export class HomepageModule { }

<ng-container *ngIf="isShow">
    <button mat-mini-fab color="primary" [matMenuTriggerFor]="globalItemsMenu" class="create-button"
        [class.small-create]="size === 'small'">
        <mat-icon>add</mat-icon>
    </button>
    <mat-menu #globalItemsMenu="matMenu">
        <ng-container *ngFor="let item of itemsToBeCreated">
            <button *ngIf="item.show()" (click)="item.create()" mat-menu-item>
                {{ item.nameKey | translate }}
            </button>
        </ng-container>
    </mat-menu>
</ng-container>
import { TimeZoneDto } from "@api";
import * as moment from "moment";

import { sortMultiple, sortNumber, sortString } from "./sorters";

export interface TimeZoneDetails extends TimeZoneDto {
    trimmedName: string;
    currentName: string;
    currentOffset: number;
}

/**
 * Returns the name of the specified time zone, adjusted the offset to match the offset at the specified date.
 *
 * @param timezone The ViewModel of the time zone to format.
 * @param date The date that should be used to determine the offset. If not set will default to the current date.
 */
export const getTimeZoneNameAtDate = (timezone: TimeZoneDto, date?: moment.Moment | null): string => {
    if (!date) date = moment();

    const zone = moment.tz(date, timezone.ianaId);
    const offset = zone.utcOffset();

    const absOffset = Math.abs(offset);

    const hours = Math.floor(absOffset / 60);
    const hoursString = (hours >= 10 ? "" : "0") + hours.toString();

    const mins = absOffset % 60;
    const minsString = (mins >= 10 ? "" : "0") + mins.toString();

    const newOffsetString = (offset >= 0 ? "+" : "-") + hoursString + ":" + minsString;

    return timezone.name.replace(/[+-][^)]*/, newOffsetString);
};

export const mapToDetails = (tz: TimeZoneDto): TimeZoneDetails => ({
    name: tz.name,
    value: tz.value,
    ianaId: tz.ianaId,
    trimmedName: tz.name.replace(/^\([^)]*\) /, ""),
    currentName: getTimeZoneNameAtDate(tz),
    currentOffset: moment.tz(tz.ianaId).utcOffset()
});

export const sortTimeZones = (timeZones: TimeZoneDetails[]): TimeZoneDetails[] =>
    timeZones.sort(sortMultiple(
        sortNumber.ascending(t => t.currentOffset),
        sortString.ascending(t => t.trimmedName),
    ));


/* eslint-disable max-classes-per-file */
import { BehaviorSubject, catchError, Observable, of, switchMap, tap } from "rxjs";

import { CommonFunctions } from "~shared/commonfunctions";

import { shareReplayUntil } from "./rx-operators";

export class ExpansionController<TParent, TChild> {

    protected readonly expandedIds = new Set<string>();
    protected readonly parentSubjects = new Map<string, BehaviorSubject<TParent | null>>();
    protected readonly children$ = new Map<string, Observable<TChild[]>>();

    constructor(
        protected readonly getId: (parent: TParent) => string,
        protected readonly loadChildren: (parent: TParent) => Observable<TChild[]>,
        protected readonly destroyed$: Observable<void>,
    ) { }

    getChildren = (parent: TParent): Observable<TChild[]> => {
        const id = this.getId(parent);
        const existingChildren$ = this.children$.get(id);
        if (existingChildren$) return existingChildren$;
        return this.loadChildrenInternal(parent);
    };

    isExpanded = (parent: TParent) => this.expandedIds.has(this.getId(parent));

    expand = (parent: TParent) => {
        const id = this.getId(parent);
        this.expandedIds.add(id);
        const parentSubject = this.parentSubjects.get(id);
        if (parentSubject) parentSubject.next(parent);
    };

    collapse = (parent: TParent) => this.collapseInternal(this.getId(parent));

    clear = (parent: TParent) => {
        const id = this.getId(parent);
        this.collapseInternal(id);
        this.parentSubjects.delete(id);
        this.children$.delete(id);
    };

    private collapseInternal = (id: string) => this.expandedIds.delete(id);

    private loadChildrenInternal = (parent: TParent): Observable<TChild[]> => {
        const id = this.getId(parent);
        const parentSubject = new BehaviorSubject<TParent | null>(parent);
        const children$ = parentSubject.pipe(
            switchMap(p => !p ? of([]) : this.loadChildren(p).pipe(
                catchError(() => {
                    this.collapseInternal(id);
                    return of([]);
                }),
            )),
            shareReplayUntil(this.destroyed$),
        );
        this.parentSubjects.set(id, parentSubject);
        this.children$.set(id, children$);
        return children$;
    };
}

export class ExpansionControllerWithLoader<TParent, TChild> extends ExpansionController<TParent, TChild> {

    private readonly loadingIds = new Set<string>();

    constructor(
        getId: (parent: TParent) => string,
        getChildren: (parent: TParent) => Observable<TChild[]>,
        destroyed$: Observable<void>,
    ) {
        super(getId, parent =>
            of(parent).pipe(
                tap(this.setLoading),
                switchMap(getChildren),
                tap({
                    next: () => this.setLoaded(parent),
                    finalize: () => this.setLoaded(parent),
                    unsubscribe: () => this.setLoaded(parent),
                }),
            ), destroyed$);
    }

    private setLoading = (parent: TParent): void => {
        this.loadingIds.add(this.getId(parent));
        this.updateLoadingState();
    };

    private setLoaded = (parent: TParent): void => {
        this.loadingIds.delete(this.getId(parent));
        this.updateLoadingState();
    };

    private updateLoadingState = () => {
        if (this.loadingIds.size) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
    };
}

/* eslint-disable max-len */
import { GoalSuggestionDto } from "@api";

export const exampleGoals: GoalSuggestionDto[] = [
    {
        "heading": "Improve network security",
        "description": "Implement new security protocols and conduct regular vulnerability assessments to ensure the network is secure from cyber attacks."
    },
    {
        "heading": "Train team members on new security measures",
        "description": "Organize training sessions to educate team members on the latest security measures and best practices to protect company data and systems."
    },
    {
        "heading": "Conduct penetration testing",
        "description": "Hire a third-party company to conduct penetration testing on our systems to identify any potential vulnerabilities and address them before they can be exploited."
    },
    {
        "heading": "Implement multi-factor authentication",
        "description": "Require all employees to use multi-factor authentication when accessing company systems to add an extra layer of security."
    },
    {
        "heading": "Create incident response plan",
        "description": "Develop a detailed incident response plan to ensure quick and effective action in case of a security breach or cyber attack."
    },
    {
        "heading": "Regularly update security software",
        "description": "Ensure all security software, including antivirus and firewalls, are regularly updated to protect against the latest threats."
    },
    {
        "heading": "Conduct security awareness training",
        "description": "Organize workshops and seminars to educate employees on the importance of cybersecurity and how they can contribute to keeping the company's data safe."
    },
    {
        "heading": "Perform risk assessment",
        "description": "Conduct a thorough risk assessment to identify potential security threats and prioritize areas that require immediate attention."
    },
    {
        "heading": "Implement data backup and recovery plan",
        "description": "Create a data backup and recovery plan to ensure critical data is not lost in case of a security breach or system failure."
    },
    {
        "heading": "Regularly review and update security policies",
        "description": "Review and update company security policies to ensure they align with industry best practices and comply with any new regulations or laws."
    }
];

<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>
        <wf-translate-template key="numbers.update.notesDialogTitle">
            <span *wfTransTplContent="'description'" class="number-description">{{ record.description }}</span>
            <span *wfTransTplContent="'week'" class="number-week">{{ 'period.weekShort' | translate: { week: record.week } }}</span>
        </wf-translate-template>
    </h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{ 'numbers.result' | translate }}</mat-label>
                    <input type="number" matInput formControlName="result" name="result" autocomplete="off"
                        [class.on-target]="targetStatus === 'ontarget'"
                        [class.off-target]="targetStatus === 'offtarget'" />
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{ 'numbers.notes' | translate }}</mat-label>
                    <textarea matInput formControlName="notes" name="notes" autocomplete="off"
                        [maxlength]="maxNotesLength" cdkTextareaAutosize cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5" cdkFocusInitial></textarea>
                    <mat-error *ngIf="notesControl.hasError('required')" translate="numbers.notesRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>
        <div *ngIf="record.workInstruction && targetStatus === 'offtarget' && workInstructionEnabled()" @fadeExpand [@.disabled]="disableAnimations">
            @if (record.workInstructionLink) {
                <a [attr.href]="record.workInstructionLink" target="_blank">
                    <ng-container [ngTemplateOutlet]="workInstruction"></ng-container>
                </a>
            } @else {
                <ng-container [ngTemplateOutlet]="workInstruction"></ng-container>
            }
            <ng-template #workInstruction>
                <section class="wf-section work-instruction">
                    <h4 class="wf-section-heading-legend">{{ 'numbers.workInstruction' | translate }}</h4>
                    <p class="multiline-description">{{ record.workInstruction }}</p>
                </section>
            </ng-template>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="record.canEdit">
        <app-status-button type="submit" [visualDisable]="!form.valid">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
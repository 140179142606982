import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { CurrentCompanyDto, TeamPerformanceTargetsApi } from "@api";
import { BehaviorSubject, combineLatest, EMPTY, Observable, of, Subject, Subscription } from "rxjs";
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from "rxjs/operators";

import { IQuarter } from "~repositories";
import { AccessService } from "~services/access.service";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { PageName, PermissionType } from "~shared/enums";
import { integerValidator } from "~shared/util/custom-validators";
import { isExecutionScoreTargetEnabled, isPerformanceTargetsEnabled } from "~shared/util/feature-helper";

declare interface ExecutionTargetParams {
    companyId: string;
    teamId: string;
    quarter: IQuarter;
}

declare interface ExecutionTargetState {
    params: ExecutionTargetParams;
    target: number | null;
}

const isExecutionScoreAvailable = (company: CurrentCompanyDto): boolean =>
    isExecutionScoreTargetEnabled(company) &&
    // The execution score is not available if the full performance targets feature is available.
    !isPerformanceTargetsEnabled(company);

@Component({
    selector: "app-execution-score-target",
    templateUrl: "./execution-score-target.component.html",
    styleUrls: ["./execution-score-target.component.scss"],
})
export class ExecutionScoreTargetComponent implements OnInit, OnDestroy {

    @Input() set selectedQuarter(value: IQuarter | null) {
        if (!value) return;
        this.quarterSubject.next(value);
    }

    get locked(): boolean {
        return this.targetControl.disabled;
    }

    set locked(value: boolean) {
        if (this.form.disabled || this.form.dirty) return;
        if (value) {
            this.targetControl.disable({ emitEvent: false });
        } else {
            this.targetControl.enable({ emitEvent: false });
        }
    }

    readonly targetControl = new FormControl<number | null>({ value: null, disabled: true },
        [Validators.min(0), Validators.max(100), integerValidator]);

    readonly stateControl = new FormControl<ExecutionTargetState | null>(null, [Validators.required]);

    readonly form = new FormGroup({
        target: this.targetControl,
        state: this.stateControl
    });

    get isEditable(): boolean {
        return this.accessService.getPageAccess(PageName.quarterlyPlanning) === PermissionType.readWrite;
    }

    readonly featureAvailable$: Observable<boolean>;

    private readonly quarterSubject = new BehaviorSubject<IQuarter | null>(null);
    private readonly saveSubject = new Subject<void>();

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly teamContext: TeamContext,
        private readonly targetsApi: TeamPerformanceTargetsApi,
        private readonly accessService: AccessService,
        private readonly notificationService: NotificationService,
    ) {
        this.form.disable();

        this.featureAvailable$ = this.teamContext.companyTeam$.pipe(
            filter(Boolean),
            map(ct => isExecutionScoreAvailable(ct.company)),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(combineLatest({
            ct: this.teamContext.companyTeam$.pipe(
                filter(Boolean),
                filter(ct => isExecutionScoreAvailable(ct.company)),
            ),
            quarter: this.quarterSubject.pipe(
                filter((q): q is IQuarter => !!q),
                distinctUntilChanged((a, b) => a.financialYear === b.financialYear && a.quarter === b.quarter),
            ),
        }).pipe(
            map(({ ct, quarter }) => ({
                companyId: ct.company.id,
                teamId: ct.team?.id,
                quarter: quarter
            } as ExecutionTargetParams)),
            tap(this.initLoad),
            switchMap(this.getTargetInternal),
            tap(this.bindTarget),
        ).subscribe());

        this.subscriptions.add(this.saveSubject.pipe(
            switchMap(this.updateTargetInternal),
        ).subscribe());
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    updateTarget = () => {
        if (!this.form.valid || this.locked) return;
        this.saveSubject.next();
    };

    resetTarget = () => {
        const state = this.stateControl.value;
        if (!state) return;
        this.bindTarget(state);
    };

    private getTargetInternal = (params: ExecutionTargetParams): Observable<ExecutionTargetState> =>
        this.targetsApi.getExecutionTarget(
            params.companyId,
            params.teamId,
            toFiscalQuarter(params.quarter),
        ).pipe(
            map(target => ({ target: target.target ?? null, params })),
            catchError(() => EMPTY)
        );

    private initLoad = () => {
        this.form.reset(undefined, { emitEvent: false });
        this.form.disable({ emitEvent: false });
        this.locked = true;
    };

    private bindTarget = (state: ExecutionTargetState) => {
        this.form.reset({
            target: state.target,
            state,
        }, { emitEvent: false });
        this.form.enable({ emitEvent: false });
        this.targetControl.disable({ emitEvent: false });
    };

    private updateTargetInternal = (): Observable<unknown> => {
        const state = this.stateControl.value;
        if (!this.form.valid || this.locked || !state) return of(undefined);

        const target = this.targetControl.value;
        return this.targetsApi.updateExecutionTarget(
            state.params.companyId,
            state.params.teamId,
            toFiscalQuarter(state.params.quarter),
            { target: target ?? undefined },
        ).pipe(
            catchError(() => {
                this.notificationService.errorUnexpected();
                // Reset the control to unmodified state.
                this.bindTarget(state);
                return EMPTY;
            }),
            tap(() => {
                this.notificationService.success("executionScoreTarget.targetUpdated", undefined, undefined, true);
                // Reset the control to the updated state.
                this.bindTarget({ target, params: state.params });
            })
        );
    };
}

<h2 mat-dialog-title>
    {{ description }}
    <span class="period">
        {{ 'numbers.chart.period' | translate: { financialYear: financialYear, quarter: quarter } }}
    </span>
</h2>

<mat-dialog-content>
    <app-number-chart [number]="number">
    </app-number-chart>
</mat-dialog-content>
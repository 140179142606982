<app-feed-list-comment *ngIf="feedItem.type === 'getCommentFeedItem'" [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" (itemChanged)="itemChanged.emit()">
</app-feed-list-comment>

<app-feed-list-link *ngIf="feedItem.type === 'getLinkFeedItem'" [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" (itemChanged)="itemChanged.emit()">
</app-feed-list-link>

<app-feed-list-attachment *ngIf="feedItem.type === 'getAttachmentFeedItem'" [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" (itemChanged)="itemChanged.emit()">
</app-feed-list-attachment>

<app-feed-list-status *ngIf="feedItem.type === 'getStatusChangedFeedItem'" [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)">
</app-feed-list-status>

<app-feed-list-approval *ngIf="feedItem.type === 'getApprovalFeedItem'" [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)">
</app-feed-list-approval>

<app-feed-list-linked-entity *ngIf="feedItem.type === 'getLinkedEntityFeedItem'" [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)">
</app-feed-list-linked-entity>
<li app-feed-list-item-content [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" icon="chat" iconColor="primary" [showCreationInfo]="!showEditForm">

    <ng-container *ngIf="!showEditForm; else editForm">
        {{ feedItem.text }}
    </ng-container>
    <ng-template #editForm>
        <form (submit)="editComment()" [formGroup]="editCommentForm">
            <mat-form-field class="no-label" subscriptSizing="dynamic">
                <input type="text" matInput formControlName="editCommentControl" />
                <button matSuffix mat-icon-button color="primary">
                    <mat-icon>send</mat-icon>
                </button>
            </mat-form-field>
        </form>
    </ng-template>

    <div class="actions" *appFeedListItemActions>
        <button mat-icon-button (click)="toggleEditMode()" *ngIf="canUserEditComment" class="edit-button">
            <mat-icon *ngIf="!editModeEnabled">edit</mat-icon>
            <mat-icon *ngIf="editModeEnabled">close</mat-icon>
        </button>
        <button mat-icon-button *ngIf="canUserDelete" (click)="delete()" [disabled]="deleteStarted" color="warn">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</li>
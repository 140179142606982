import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, EventEmitter, Input, input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { BehaviorSubject, EMPTY, of, Subscription } from "rxjs";
import { filter, map, switchMap, tap } from "rxjs/operators";

import { IWeek, PeriodRepository, WeekContextRepository } from "~repositories";
import { defaultAnimationTiming, fadeInAnimationBuilder } from "~shared/util/animations";
import { integerValidator } from "~shared/util/custom-validators";

interface IWeekSelectorState {
    /**
     * The currently selected period, or null if no period is yet selected
     */
    selectedPeriod: IWeek | null;

    /**
     * The delta waiting to be applied to the quarter
     */
    pendingDelta: number;
}

export interface IWeekDetails extends IWeek {
    readonly startDate: string;
    readonly endDate: string;
}

const mapToState = (period: IWeek | null, delta?: number): IWeekSelectorState => ({
    selectedPeriod: period,
    pendingDelta: delta ?? 0
});

const MIN_YEAR = 2010;
const MAX_YEARS_FROM_NOW = 5;

const compareWeeks = (o1: IWeek, o2: IWeek): number =>
    (o2.financialYear - o1.financialYear) || (o2.quarter - o1.quarter) || (o2.week - o1.week);

@Component({
    selector: "app-week-selector",
    templateUrl: "./week-selector.component.html",
    styleUrls: ["./week-selector.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder(defaultAnimationTiming)),
    ],
})
export class WeekSelectorComponent implements OnInit, OnDestroy {

    private static lastElementId = 0;

    @Output() selectedWeekChange = new EventEmitter<IWeek>();

    get companyId(): string {
        return this._companyId;
    }

    @Input() set companyId(value: string) {
        this._companyId = value;
        if (this._companyId) this.ensurePeriodSet();
    }

    get selectedWeek() {
        if (this.weekContext) return this.weekContext.getSelectedWeek(this.companyId);
        return this._selectedWeek;
    }

    @Input() set selectedWeek(value: IWeek | null) {
        if (!value) return;
        const selectedWeek = this.selectedWeek;
        if (selectedWeek && compareWeeks(selectedWeek, value) === 0) return;
        if (this.weekContext && this.companyId) this.weekContext.setSelectedWeek(this.companyId, value);
        this._selectedWeek = value;
        this.form.setValue(value);
        // By piping into the state subject, we validate the week is possible.
        this.stateSubject.next(mapToState(value));
    }

    readonly showWeekBeginning = input(false, { transform: booleanAttribute });

    @Input() allowEdit = false;

    @Input() set readonly(value: boolean) {
        this.readonlyInternal = coerceBooleanProperty(value);
    }

    get readonly(): boolean {
        return this.readonlyInternal;
    }

    @Input() set pastOnly(value: boolean) {
        this.pastOnlyInternal = coerceBooleanProperty(value);
    }

    get pastOnly(): boolean {
        return this.pastOnlyInternal;
    }

    @Output() selectedWeekDetailsChange = new EventEmitter<IWeekDetails>();

    get disableNext(): boolean {
        return this.pastOnly && !!this.currentWeek && !!this.selectedWeek &&
            compareWeeks(this.currentWeek, this.selectedWeek) >= 0;
    }

    get canReset(): boolean {
        return !this.readonly && !!this.currentWeek && !!this.selectedWeek &&
            compareWeeks(this.currentWeek, this.selectedWeek) !== 0;
    }

    readonly financialYearControl = new FormControl<number | string | null>(null, [
        Validators.required, Validators.minLength(4), Validators.maxLength(4), integerValidator,
        Validators.min(MIN_YEAR), Validators.max(new Date().getFullYear() + MAX_YEARS_FROM_NOW)]);

    readonly quarterControl = new FormControl<number | string | null>(null,
        [Validators.required, Validators.maxLength(1), integerValidator]);

    readonly weekControl = new FormControl<number | string | null>(null,
        [Validators.required, Validators.maxLength(2), integerValidator]);

    readonly form = new FormGroup({
        financialYear: this.financialYearControl,
        quarter: this.quarterControl,
        week: this.weekControl
    });

    weekStartDate: moment.Moment | null = null;

    readonly elementId: string;

    private readonlyInternal = false;
    private pastOnlyInternal = false;
    private currentPeriodSub?: Subscription;
    private stateSub?: Subscription;
    private formSub?: Subscription;
    private _companyId!: string;

    private currentWeek: IWeek | null = null;

    private _selectedWeek: IWeek | null = null;

    private stateSubject = new BehaviorSubject<IWeekSelectorState>({
        pendingDelta: 0,
        selectedPeriod: null
    });

    constructor(
        private readonly periodRepository: PeriodRepository,
        @Optional() private readonly weekContext: WeekContextRepository | null,
    ) {
        this.elementId = `week-selector-${(WeekSelectorComponent.lastElementId++)}`;
    }

    ngOnInit(): void {
        this.ensurePeriodSet();
        this.applyStateListener();
        this.applyFormListener();
    }

    ngOnDestroy(): void {
        this.selectedWeekChange.complete();
        this.selectedWeekDetailsChange.complete();
        this.stateSubject.complete();
        this.currentPeriodSub?.unsubscribe();
        this.stateSub?.unsubscribe();
        this.formSub?.unsubscribe();
    }

    incrementWeek = (): void => {
        if (this.readonly || this.disableNext) return;
        this.mutatePendingDelta(1);
    };

    decrementWeek = (): void => {
        if (this.readonly) return;
        this.mutatePendingDelta(-1);
    };

    reset = (): void => {
        if (!this.canReset) return;
        this.stateSubject.next(mapToState(this.currentWeek, 0));
    };

    private ensurePeriodSet = () => {
        if (this.selectedWeek == null) {
            this.setCurrentWeek(/* updateSelected: */ true);
        } else {
            if (!this.currentWeek) {
                this.setCurrentWeek(/* updateSelected: */ false);
            }
            const state = this.stateSubject.value;
            this.stateSubject.next({
                selectedPeriod: this.selectedWeek,
                pendingDelta: state.pendingDelta
            });
        }
    };

    private mutatePendingDelta = (delta: number): void => {
        const state = this.stateSubject.value;
        this.stateSubject.next({
            selectedPeriod: state.selectedPeriod,
            pendingDelta: state.pendingDelta + delta
        });
    };

    private setCurrentWeek = (updateSelected: boolean) => {
        if (this.currentPeriodSub) this.currentPeriodSub.unsubscribe();
        this.currentPeriodSub = this.periodRepository.getCurrentPeriod(this.companyId).pipe(
            map(period => ({
                financialYear: period.current.financialYear,
                quarter: period.current.planningPeriodIndex,
                week: period.current.collectionPeriodIndex
            } as IWeek)),
            tap(period => this.currentWeek = period),
        ).subscribe(period => {
            if (updateSelected && !this.selectedWeek) this.stateSubject.next(mapToState(period, 0));
        });
    };

    private applyStateListener = () => {
        this.stateSub = this.stateSubject.pipe(
            filter((state): state is { pendingDelta: number; selectedPeriod: IWeek } => !!state.selectedPeriod),

            switchMap(({ selectedPeriod }) =>
                this.periodRepository.getPeriods(this.companyId, selectedPeriod.financialYear).pipe(
                    map(selectedGroupPeriods => ({ selectedPeriod, selectedGroupPeriods })))),

            switchMap(({ selectedPeriod, selectedGroupPeriods }) => {
                const currentFinancialYear: number = selectedPeriod.financialYear;
                const maxQuarter = selectedGroupPeriods.length;
                let currentQuarter = selectedPeriod.quarter;
                if (currentQuarter < 1) currentQuarter = 1;
                if (currentQuarter > maxQuarter) currentQuarter = maxQuarter;

                const currentPeriodInfo = selectedGroupPeriods.find(p => p.index === currentQuarter);
                if (!currentPeriodInfo) return EMPTY; // this should never occur.

                const collectionPeriods = currentPeriodInfo.collectionPeriods;
                const maxWeek = collectionPeriods.length;
                let currentWeek = selectedPeriod.week;
                if (currentWeek < 1) currentWeek = 1;
                if (currentWeek > maxWeek) currentWeek = maxWeek;

                const newWeek = this.stateSubject.value.pendingDelta + currentWeek; // Always use the latest pending delta

                if (newWeek >= 1 && newWeek <= maxWeek) {
                    const weekData = collectionPeriods.find(p => p.index === newWeek);
                    if (!weekData) return EMPTY; // this should never occur.
                    return of({
                        selectedWeek: {
                            financialYear: currentFinancialYear,
                            quarter: currentQuarter,
                            week: newWeek
                        } as IWeek,
                        weekData
                    });
                }

                this.stateSubject.next(this.buildNextState(newWeek, currentFinancialYear, currentQuarter, maxWeek, maxQuarter));

                // Don't set the state here - let the next iteration set it
                return EMPTY;
            })
        ).subscribe(({ selectedWeek, weekData }) => {
            this.setSelectedWeek(selectedWeek);
            // Dates are always displayed in local time. Asserting as local makes sure the displayed value is independent of time zone.
            this.weekStartDate = moment.utc(weekData.startDate).local(true);
            this.selectedWeekDetailsChange.emit({
                ...selectedWeek,
                startDate: weekData.startDate,
                endDate: weekData.endDate,
            });
        });
    };

    private applyFormListener = () => {
        this.formSub = this.form.valueChanges.subscribe(() => {
            if (!this.form.valid) return;
            const week = {
                financialYear: parseInt(this.financialYearControl.value?.toString() ?? "", 10),
                quarter: parseInt(this.quarterControl.value?.toString() ?? "", 10),
                week: parseInt(this.weekControl.value?.toString() ?? "", 10),
            };
            const selectedWeek = this.selectedWeek;
            if (!!selectedWeek &&
                selectedWeek.financialYear === week.financialYear &&
                selectedWeek.quarter === week.quarter &&
                selectedWeek.week === week.week) {
                return;
            }
            this.selectedWeek = week;
        });
    };

    private setSelectedWeek = (value: IWeek) => {
        if (this.weekContext) this.weekContext.setSelectedWeek(this.companyId, value);
        this._selectedWeek = value;
        this.form.setValue(value);
        this.selectedWeekChange.emit(value);
    };

    private buildNextState = (
        newWeek: number,
        currentFinancialYear: number,
        currentQuarter: number,
        maxWeek: number,
        maxQuarter: number
    ): IWeekSelectorState => {
        if (newWeek < 1) {
            // We are moving backward
            if (currentQuarter > 1) {
                // move back a quarter
                return mapToState({
                    financialYear: currentFinancialYear,
                    quarter: currentQuarter - 1,
                    week: Number.MAX_VALUE // This will be lowered to the max week in the previous quarter
                }, newWeek);
            }
            // move back a year
            return mapToState({
                financialYear: currentFinancialYear - 1,
                quarter: Number.MAX_VALUE, // This will be lowered to the max quarter in the previous year
                week: Number.MAX_VALUE // This will be lowered to the max week in the previous quarter
            }, newWeek);
        } else {
            // We are moving forward
            if (currentQuarter < maxQuarter) {
                // move forward a quarter
                return mapToState({
                    financialYear: currentFinancialYear,
                    quarter: currentQuarter + 1,
                    week: 1
                }, newWeek - (maxWeek + 1));
            }
            // move forward a year
            return mapToState({
                financialYear: currentFinancialYear + 1,
                quarter: 1,
                week: 1
            }, newWeek - (maxWeek + 1));
        }
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_readonly: BooleanInput;
    static ngAcceptInputType_pastOnly: BooleanInput;
    /* eslint-enable  @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}

import { CompanyStore } from "./company.store";
import { PERSISTENT_STORE } from "./shared";
import { TeamStore } from "./team.store";

export * from "./shared";
export * from "./company.store";
export * from "./team.store";

export const providePersistentStores = () => [
    {
        provide: PERSISTENT_STORE,
        useExisting: CompanyStore,
        multi: true,
    },
    {
        provide: PERSISTENT_STORE,
        useExisting: TeamStore,
        multi: true,
    },
];

import { Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
    selector: "app-issues-capped-dialog",
    templateUrl: "./issues-capped-dialog.component.html",
    styleUrls: ["./issues-capped-dialog.component.scss"]
})
export class IssuesCappedDialogComponent {

    static open(dialog: MatDialog) {
        return dialog.open(IssuesCappedDialogComponent, {
            autoFocus: false,
        });
    }

}

import { computed, Signal } from "@angular/core";
import { CurrentCompanyAndTeamDto } from "@api";
import { Observable } from "rxjs";

import { FeatureContext } from "./feature.context";
import { CompanySettingsContext } from "./settings.context";

export abstract class TeamContext {
    abstract readonly companyTeam: Signal<CurrentCompanyAndTeamDto | null>;
    abstract readonly companyTeam$: Observable<CurrentCompanyAndTeamDto | null>;

    readonly company = computed(() => this.companyTeam()?.company ?? null);
    readonly team = computed(() => this.companyTeam()?.team ?? null);

    readonly settings = new CompanySettingsContext(this.company);
    readonly features = new FeatureContext(this.company);

    abstract refresh(): void;

    abstract setCompanyTeam(companyId: string, teamId: string, refresh?: boolean): void;
}

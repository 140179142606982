import { NumberTargetDto, NumberTargetType } from "@api";

export const hasLowerTarget = (targetType: NumberTargetType): boolean => targetType !== NumberTargetType.belowTarget;
export const hasUpperTarget = (targetType: NumberTargetType): boolean => targetType !== NumberTargetType.aboveTarget;

export const sanitiseTarget = (targetType: NumberTargetType, target: NumberTargetDto): NumberTargetDto => {
    const hasLower = hasLowerTarget(targetType);
    const hasUpper = hasUpperTarget(targetType);

    return {
        lowerBound: hasLower ? target.lowerBound : undefined,
        upperBound: hasUpper ? target.upperBound : undefined,
    };
};

declare interface WeekTargets { [week: string]: NumberTargetDto }

export const sanitiseWeekTargets = (weeks: number[], targetType: NumberTargetType, weekTargets: WeekTargets): WeekTargets =>
    weeks.reduce((targets, week) => {
        const weekString = week.toString();
        const matchingTargets = weekTargets[weekString] ?? {};

        targets[weekString] = sanitiseTarget(targetType, matchingTargets);

        return targets;
    }, {} as WeekTargets);

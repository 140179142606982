import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

interface IconMetadata {
    name: string;
    path: string;
}

const icons: IconMetadata[] = [
    {
        name: "wf-goal-check",
        path: "goal-check.svg"
    },
    {
        name: "wf-dept-finance",
        path: "departments/finance.svg"
    },
    {
        name: "wf-dept-operations",
        path: "departments/operations.svg"
    },
    {
        name: "wf-dept-management",
        path: "departments/management.svg"
    },
    {
        name: "wf-dept-sales-marketing",
        path: "departments/sales-marketing.svg"
    },
    {
        name: "wf-email-success",
        path: "email-success.svg"
    },
    {
        name: "wf-email-error",
        path: "email-error.svg"
    },
    {
        name: "wf-stars-complete",
        path: "stars-complete.svg"
    },
    {
        name: "wf-stars-incomplete",
        path: "stars-incomplete.svg"
    },
    {
        name: "wf-getting-started",
        path: "getting-started.svg"
    },
    {
        name: "wf-goodbye-face",
        path: "goodbye-face.svg"
    },
    {
        name: "wf-plan-capped-numbers",
        path: "plan/numbers-capped.svg"
    },
    {
        name: "wf-plan-upgrade-numbers",
        path: "plan/numbers-upgrade.svg"
    },
    {
        name: "wf-plan-capped-reports",
        path: "plan/reports-capped.svg"
    },
    {
        name: "wf-plan-upgrade-reports",
        path: "plan/reports-upgrade.svg"
    },
    {
        name: "wf-plan-capped-teams",
        path: "plan/teams-capped.svg"
    },
    {
        name: "wf-plan-upgrade-teams",
        path: "plan/teams-upgrade.svg"
    },
    {
        name: "wf-plan-capped-users",
        path: "plan/users-capped.svg"
    },
    {
        name: "wf-plan-upgrade-users",
        path: "plan/users-upgrade.svg"
    },
    {
        name: "wf-plan-upgrade-widgets",
        path: "plan/widgets-upgrade.svg"
    },
    {
        name: "wf-plan-capped-discussions",
        path: "plan/issues-capped.svg"
    },
    {
        name: "wf-plan-upgrade-discussions",
        path: "plan/issues-upgrade.svg"
    },
    {
        name: "wf-plan-capped-news",
        path: "plan/news-capped.svg"
    },
    {
        name: "wf-plan-upgrade-news",
        path: "plan/news-upgrade.svg"
    },
    {
        name: "wf-annual-planning-fullpage",
        path: "plan/annual-planning-fullpage.svg"
    },
    {
        name: "wf-company-performance-fullpage",
        path: "plan/company-performance-fullpage.svg"
    },
    {
        name: "wf-plan-tier-xsmall",
        path: "plan-tier/xsmall.svg"
    },
    {
        name: "wf-plan-tier-small",
        path: "plan-tier/small.svg"
    },
    {
        name: "wf-plan-tier-medium",
        path: "plan-tier/medium.svg"
    },
    {
        name: "wf-plan-tier-large",
        path: "plan-tier/large.svg"
    },
    {
        name: "wf-plan-tier-xlarge",
        path: "plan-tier/xlarge.svg"
    },
    {
        name: "wf-most-popular-banner",
        path: "most-popular-banner.svg",
    }
];

const baseIconPath = "./assets/images/icons/";

export const addIcons = (iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) => {
    icons.forEach(icon =>
        iconRegistry.addSvgIcon(icon.name, sanitizer.bypassSecurityTrustResourceUrl(baseIconPath + icon.path)));
};

import { FormControl, FormGroup, Validators } from "@angular/forms";
import { DayOfWeek, GetDailyChildNumberDto, NumberTargetType, SimpleUserDto } from "@api";
import { distinctUntilChanged, pairwise, Subscription } from "rxjs";

import { decimalValidator, greaterThanControl } from "~shared/util/custom-validators";
import { compareUsers } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";

import { sanitiseTarget } from "./target-helper";
import { bindWeeklyTargets, getWeekTargetsValue, WeekTargetsForm } from "./week-targets-helper";

export type DailyChildForm = FormGroup<DailyChildFormControls>;

export type DailyChildrenForm = FormGroup<{ [day: string]: DailyChildForm }>;

export interface DailyChildFormControls {
    updater: FormControl<SimpleUserDto | null>;
    targetLowerBound: FormControl<number | null>;
    targetUpperBound: FormControl<number | null>;
    weekTargets: WeekTargetsForm;
}

export interface ParentFormControls extends DailyChildFormControls {
    dailyChildren: DailyChildrenForm;
}

type ChildNumberSettingsDto = Pick<GetDailyChildNumberDto, "updater" | "target" | "weekTargets">;

const buildDailyChildForm = (
    weeks: number[],
    parentFormControls: DailyChildFormControls,
    value?: ChildNumberSettingsDto,
): [DailyChildForm, Subscription] => {
    const updaterControl = new FormControl<SimpleUserDto | null>(
        {
            value: value ? value.updater ?? null : parentFormControls.updater.value,
            disabled: parentFormControls.updater.disabled,
        },
        // The updater may be set to required or not required depending on whether the item is delegated.
        parentFormControls.updater.hasValidator(Validators.required) ? [Validators.required] : []);

    const targetLowerBoundControl = new FormControl<number | null>(
        {
            value: value ? value.target.lowerBound ?? null : parentFormControls.targetLowerBound.value,
            disabled: parentFormControls.targetLowerBound.disabled,
        },
        [decimalValidator]);

    const targetUpperBoundControl = new FormControl<number | null>(
        {
            value: value ? value.target.upperBound ?? null : parentFormControls.targetUpperBound.value,
            disabled: parentFormControls.targetUpperBound.disabled,
        },
        [decimalValidator, greaterThanControl("targetLowerBound")]);

    const weekTargetsControl: WeekTargetsForm = new FormGroup({});

    const form: DailyChildForm = new FormGroup({
        updater: updaterControl,
        targetLowerBound: targetLowerBoundControl,
        targetUpperBound: targetUpperBoundControl,
        weekTargets: weekTargetsControl,
    });

    const sub = targetLowerBoundControl.valueChanges.subscribe(() => targetUpperBoundControl.updateValueAndValidity());
    bindWeeklyTargets(weeks, form.controls, sub, value?.weekTargets);

    return [form, sub];
};

export const bindDailyChildren = (
    days: DayOfWeek[],
    weeks: number[],
    parentControls: ParentFormControls,
    subscription: Subscription,
    children?: GetDailyChildNumberDto[],
) => {
    for (const day of days) {
        const dayStr = day.toString();
        if (dayStr in parentControls.dailyChildren.controls) continue;
        const value = children?.find(c => c.updateDay === day);
        const [dayForm, daySub] = buildDailyChildForm(weeks, parentControls, value);
        parentControls.dailyChildren.addControl(dayStr, dayForm);
        subscription.add(daySub);
        subscription.add(valueAndChanges(parentControls.updater).pipe(
            distinctUntilChanged(),
            pairwise(),
        ).subscribe(([oldUpdater, newUpdater]) => {
            const dayUpdater = dayForm.controls.updater.value;
            // When the main updater is changed, if the day updater was the same as the old updater, change it too.
            if (!dayUpdater || oldUpdater && compareUsers(dayUpdater, oldUpdater)) {
                dayForm.controls.updater.setValue(newUpdater);
            }
        }));
    }
};

export const forAllDays = (form: DailyChildrenForm, callback: (dayStr: string, child: DailyChildForm) => void) => {
    for (const dayStr in form.controls) {
        if (!Object.prototype.hasOwnProperty.call(form.controls, dayStr)) continue;
        callback(dayStr, form.controls[dayStr]);
    }
};

export const getDailyChildrenValue = (
    days: DayOfWeek[],
    weeks: number[],
    targetType: NumberTargetType,
    form: DailyChildrenForm,
): GetDailyChildNumberDto[] | undefined => {
    if (!days.length) return undefined;
    return days.map(day => {
        const dayStr = day.toString();
        const dayForm = form.controls[dayStr];
        return {
            // Note: we should never use the id and global id, so we can leave them empty.
            id: "",
            globalId: "",
            updateDay: day,
            updater: dayForm.controls.updater.value ?? undefined,
            target: sanitiseTarget(targetType, {
                lowerBound: dayForm.controls.targetLowerBound.value ?? undefined,
                upperBound: dayForm.controls.targetUpperBound.value ?? undefined,
            }),
            weekTargets: getWeekTargetsValue(weeks, targetType, dayForm.controls.weekTargets),
        };
    });
};

import { Injectable } from "@angular/core";
import { LocalizationOverrideApi } from "@api";
import { Observable } from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";
import { shareReplayUntil } from "~shared/util/rx-operators";

const CACHE_EXPIRY_MS = 15 * 60 * 1000; // 15 minutes

declare type LocData = object;

@Injectable({
    providedIn: "root"
})
export class CustomLocalizationRepository extends WithDestroy() {

    private cache: ICompanyCache<LocData> = {};

    constructor(
        private readonly localizationOverrideApi: LocalizationOverrideApi,
    ) {
        super();
    }

    getCustomLocalizations = (companyId: string): Observable<LocData> => {
        let cacheItem = getCompanyCacheItem(this.cache, companyId);
        if (!cacheItem) {
            cacheItem = this.getCustomLocalizationsObservable(companyId);
            setCompanyCacheItem(this.cache, companyId, cacheItem, CACHE_EXPIRY_MS);
        }
        return cacheItem;
    };

    private getCustomLocalizationsObservable = (companyId: string): Observable<LocData> =>
        this.localizationOverrideApi.getOverrides(companyId).pipe(
            retryWithDelay(),
            shareReplayUntil(this.destroyed$),
        );
}

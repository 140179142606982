// eslint-disable-next-line max-classes-per-file
import { NgxIndexedDBService } from "ngx-indexed-db";
import { catchError, map, Observable, of } from "rxjs";

import { GenericStore, IStore } from "./shared";

export abstract class BaseStore<TItem> implements IStore {

    protected abstract readonly storeName: string;

    constructor(
        private readonly dbService: NgxIndexedDBService,
    ) { }

    clear = (): Observable<void> =>
        this.dbService.clear(this.storeName).pipe(
            catchError(() => of(null)),
            map(() => undefined),
        );

    protected getByIdInternal(id: string): Observable<TItem | null> {
        return this.dbService.getByID<TItem>(this.storeName, id).pipe(
            // The item returns undefined if not found - we want to change to null
            map(item => item ?? null),
            // We want to ignore any error - treat it as if the item is not found
            catchError(() => of(null)),
        );
    }

    protected updateInternal(item: TItem): Observable<unknown> {
        return this.dbService.update<TItem>(this.storeName, item);
    }
}

export interface ItemWithUpdated<TData> {
    data: TData;
    updated: Date;
}

export abstract class BaseStoreWithExpiry<TItem, TItemContainer extends ItemWithUpdated<TItem>>
    extends BaseStore<TItemContainer> implements GenericStore<TItem> {

    protected readonly expiryMs: number = 1000 * 60 * 60 * 24; // 24 hours

    protected abstract wrapItem(item: TItem): TItemContainer;

    getById = (id: string): Observable<TItem | null> =>
        this.getByIdInternal(id).pipe(
            map(item => item?.data ?? null),
        );

    update = (item: TItem): Observable<unknown> =>
        this.updateInternal(this.wrapItem(item));

    protected getByIdInternal(id: string): Observable<TItemContainer | null> {
        return super.getByIdInternal(id).pipe(
            map(item => !item || this.isExpired(item) ? null : item),
            catchError(() => of(null)),
        );
    }

    protected isExpired(item: TItemContainer): boolean {
        if (!item.updated || !item.updated.getTime) return true;
        return item.updated.getTime() < Date.now() - this.expiryMs;
    }
}

import { computed, Directive, Host, input, OnDestroy, OnInit, Self } from "@angular/core";
import { toObservable } from "@angular/core/rxjs-interop";
import { MatTab, MatTabGroup } from "@angular/material/tabs";
import { ActivatedRoute, IsActiveMatchOptions, NavigationEnd, Router } from "@angular/router";
import { combineLatest, filter, startWith, Subscription } from "rxjs";

const DEFAULT_ROUTE_MATCH: IsActiveMatchOptions =
    { paths: "subset", queryParams: "subset", fragment: "ignored", matrixParams: "ignored" };

@Directive({
    selector: "mat-tab[wfTabLinkActive]",
})
export class TabLinkActiveDirective implements OnInit, OnDestroy {

    readonly tabRoute = input.required<string>({ alias: "wfTabLinkActive" });

    private readonly tabUrlTree = computed(() =>
        this.router.createUrlTree([this.tabRoute() ?? "/"], { relativeTo: this.activatedRoute }));

    private readonly tabUrlTree$ = toObservable(this.tabUrlTree);

    private readonly subscriptions = new Subscription();

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        @Host() private readonly tabGroup: MatTabGroup,
        @Self() private readonly tab: MatTab,
    ) {
    }

    ngOnInit(): void {
        const activeRoute$ = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd),
            startWith(null),
        );

        this.subscriptions.add(combineLatest({
            route: activeRoute$,
            urlTree: this.tabUrlTree$,
        }).subscribe(({ urlTree }) => {
            if (this.tab.isActive) return;
            if (this.router.isActive(urlTree, DEFAULT_ROUTE_MATCH)) {
                const index = this.tabGroup._tabs.toArray().indexOf(this.tab);
                if (index >= 0) this.tabGroup.selectedIndex = index;
            }
        }));

        this.subscriptions.add(this.tabGroup.selectedTabChange.pipe(
            filter(event => event.tab === this.tab),
        ).subscribe(() => {
            const urlTree = this.tabUrlTree();
            if (this.router.isActive(urlTree, DEFAULT_ROUTE_MATCH)) return;
            this.router.navigateByUrl(urlTree, { onSameUrlNavigation: "ignore" });
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}

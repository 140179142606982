import { Injectable } from "@angular/core";
import { GetMeetingInstanceDto } from "@api";
import { BehaviorSubject, map, Observable } from "rxjs";

import { MeetingStatus } from "~shared/enums";

type CompanyTeamId = `${string}_${string}`;

const toCompanyTeamId = (companyId: string, teamId: string): CompanyTeamId => `${companyId}_${teamId}`;

@Injectable({ providedIn: "root" })
export class MeetingProgressService {

    private readonly inProgressTeamsSubject = new BehaviorSubject<CompanyTeamId[]>([]);

    isInProgress = (companyId: string, teamId: string): boolean =>
        this.inProgressTeamsSubject.value.includes(toCompanyTeamId(companyId, teamId));

    isInProgress$ = (companyId: string, teamId: string): Observable<boolean> => {
        const id = toCompanyTeamId(companyId, teamId);
        return this.inProgressTeamsSubject.pipe(map(ids => ids.includes(id)));
    };

    stopAll = (): void => this.inProgressTeamsSubject.next([]);

    stop = (companyId: string, teamId: string): void => this.removeFromInProgress(companyId, teamId);

    setProgress = (companyId: string, teamId: string, status: MeetingStatus): void => {
        if (status === MeetingStatus.inProgress) {
            this.setInProgress(companyId, teamId);
        } else {
            this.removeFromInProgress(companyId, teamId);
        }
    };

    setInstance = (instance: GetMeetingInstanceDto): void =>
        this.setProgress(instance.meeting.company.id, instance.meeting.team.id, instance.status);

    stopInstance = (instance: GetMeetingInstanceDto): void =>
        this.stop(instance.meeting.company.id, instance.meeting.team.id);

    private setInProgress = (companyId: string, teamId: string): void => {
        const id = toCompanyTeamId(companyId, teamId);
        const inProgressTeams = this.inProgressTeamsSubject.value;
        if (!inProgressTeams.includes(id)) {
            this.inProgressTeamsSubject.next([...inProgressTeams, id]);
        }
    };

    private removeFromInProgress = (companyId: string, teamId: string): void => {
        const id = toCompanyTeamId(companyId, teamId);
        const inProgressTeams = this.inProgressTeamsSubject.value;
        const index = inProgressTeams.indexOf(id);
        if (index >= 0) {
            this.inProgressTeamsSubject.next(inProgressTeams.filter((_, i) => i !== index));
        }
    };
}

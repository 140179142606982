import { inject } from "@angular/core";
import { EMPTY } from "rxjs";

import { UserService } from "~services/user.service";
import { authGuardBuilder } from "~shared/base-auth.guard";
import { ClientStatus, OnboardingStatus, Profile, Role } from "~shared/enums";
import { getProfileRoles } from "~shared/util/profile-helper";

export const authGuard = authGuardBuilder(({ user, company, router }) => {
    if (!user || !company) {
        inject(UserService).redirectToLogin(/* returnUrl: */ router.getCurrentNavigation()?.finalUrl?.toString());
        return EMPTY;
    }
    if (company.onboardingStatus !== OnboardingStatus.complete &&
        (user.companyId === company.id || user.roleName !== Role.superAdmin)) {
        return false;
    }
    return company.clientStatus === ClientStatus.active || user.roleName === Role.superAdmin ||
        // A partner is allowed to access a disabled company that they manage (not their home company).
        getProfileRoles(Profile.partner).includes(user.roleName as Role) && company.id !== user.companyId;
});

/**
 * SAASCLOUDAPP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum StatusChangedFieldType {
    numberResult = 0,
    goalStatus = 1,
    report = 2,
    actionStatus = 3,
    discussionSolutionStatus = 4,
    reportExternallyUpdated = 5
}


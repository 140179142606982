import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { Priority } from "~shared/enums";
import { getPriorityNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "app-priority",
    templateUrl: "./priority.component.html",
    styleUrls: ["./priority.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityComponent {

    get priority(): Priority {
        return this.priorityInternal;
    }

    @Input() set priority(value: Priority) {
        this.priorityInternal = value ?? Priority.medium;
    }

    get className() {
        switch (this.priority) {
            case Priority.low: return "priority-low";
            case Priority.medium: return "priority-medium";
            case Priority.high: return "priority-high";
            default: return undefined;
        }
    }

    get priorityNameKey() {
        return getPriorityNameKey(this.priority);
    }

    private priorityInternal = Priority.medium;

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_priority: Priority | null | undefined;
}

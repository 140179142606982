import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetNewsItemDto } from "@api";

import { TeamContext } from "~services/contexts";
import { NewsItemStateService } from "~services/state";
import { mapNewsOrigin } from "~shared/util/origin-builder";

import { CommonAddChildActionButtonDirective } from "./add-child-action-button.component";

@Component({
    selector: "app-add-news-child-action-button",
    templateUrl: "./add-child-action-button.component.html",
    styleUrls: ["./add-child-action-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddNewsChildActionButtonComponent extends CommonAddChildActionButtonDirective<GetNewsItemDto> {

    readonly mapToOrigin = mapNewsOrigin;
    readonly mapToInput = undefined;

    constructor(
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly newsItemStateService: NewsItemStateService,
    ) {
        super(teamContext, dialog);
    }

    protected notifyChange = (source: GetNewsItemDto) => this.newsItemStateService.notifyRefresh(source);
}

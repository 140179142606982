import { DiscussionStatus } from "./enums";

export const getDiscussionStatusSortOrder = (status: DiscussionStatus) => {
    switch (status) {
        case DiscussionStatus.new: return 0;
        case DiscussionStatus.rejected: return 1;
        case DiscussionStatus.referred: return 2;
        case DiscussionStatus.approved: return 3;
        case DiscussionStatus.resolved: return 4;
        default: return 0;
    }
};

import { NotificationService } from "~services/notification.service";

export const IMAGE_EXTENSIONS: readonly string[] = ["jpg", "jpeg", "png", "bmp", "gif"];
export const DOCUMENT_EXTENSIONS: readonly string[] = [
    "pdf",
    // Document Types
    "docx", "docm", "doc",
    // Spreadsheet Types
    "xlsx", "xlsm", "xls",
    // Powerpoint Types
    "pptx", "pptm", "ppt",
    // Other report types
    "csv"];
export const ALL_EXTENSIONS: readonly string[] = [...DOCUMENT_EXTENSIONS, ...IMAGE_EXTENSIONS];

const buildAcceptAttribute = (extensions: readonly string[]) =>
    extensions.map(ext => "." + ext).join(",");

export const ACCEPT_IMAGE = buildAcceptAttribute(IMAGE_EXTENSIONS);
export const ACCEPT_DOCUMENT_OR_IMAGE = buildAcceptAttribute(ALL_EXTENSIONS);

export const getExtension = (fileName: string): string | undefined =>
    fileName?.split(".")?.pop()?.toLowerCase();

const hasExtension = (fileName: string, extensions: readonly string[]) => {
    const fileExt = getExtension(fileName);
    return fileExt && extensions.includes(fileExt);
};

export const hasImageExtension = (fileName: string) => hasExtension(fileName, IMAGE_EXTENSIONS);
export const hasDocumentOrImageExtension = (fileName: string) => hasExtension(fileName, ALL_EXTENSIONS);

export const showInvalidImageWarning = (notificationService: NotificationService) =>
    notificationService.warning("fileTypeWarning.notImage", "fileTypeWarning.notImageTitle", undefined, true);
export const showInvalidDocumentOrImageWarning = (notificationService: NotificationService) =>
    notificationService.warning("fileTypeWarning.notDocument", "fileTypeWarning.notDocumentTitle", undefined, true);

<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title [translate]="isNew ? 'watchlists.createList' : 'watchlists.renameList'"></h4>
    <mat-dialog-content>
        <mat-form-field>
            <mat-label>{{'watchlists.name' | translate}}</mat-label>
            <input matInput name="name" formControlName="name" required maxlength="50" />
            <mat-error *ngIf="!nameControl.valid">
                {{'watchlists.nameRequired' | translate}}
            </mat-error>
        </mat-form-field>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <app-status-button type="submit" [state]="buttonState" [disabled]="form.disabled">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
<span class="year-container period-separator">
    <label class="year-label" [for]="elementId + '-selected-year'">{{ 'periodSelector.year' | translate }} </label>
    
    <span class="year" [id]="elementId + '-selected-year'">
        {{ selectedQuarter?.financialYear }}
    </span>
</span>

&#8203;
<span class="quarter-container">
    <label class="quarter-label" [for]="elementId + '-selected-quarter'">{{ 'periodSelector.quarter' | translate }} </label>
    
    <button *ngIf="!readonly" type="button" mat-icon-button color="primary"
        (click)="decrementQuarter()"
        [title]="'periodSelector.prev' | translate">
        <mat-icon>arrow_back</mat-icon>
    </button>
    
    <span class="quarter" [id]="elementId + '-selected-quarter'">
        {{ selectedQuarter?.quarter }}
    </span>
    
    <button *ngIf="!readonly" type="button" mat-icon-button color="primary"
        (click)="incrementQuarter()"
        [title]="'periodSelector.next' | translate" [disabled]="disableNext">
        <mat-icon>arrow_forward</mat-icon>
    </button>

    <button *ngIf="canReset" type="button" mat-icon-button (click)="reset()" class="reset-button" @fadeIn
        [matTooltip]="'periodSelector.resetToThisQuarter' | translate">
        <mat-icon>undo</mat-icon>
    </button>
</span>
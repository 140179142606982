<table mat-table class="wf-table" [dataSource]="dataSource" matSort wfTableFocus
    matSortActive="dateIdentified" matSortDirection="asc" matSortDisableClear>
    
    <ng-container matColumnDef="heading">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'discussions.heading' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <a class="multiline-description" (click)="view(element)" wfEmptyLink>
                {{ element.heading }}
            </a>
        </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'discussions.description' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="multiline-description" *ngIf="element.description">
                {{ element.description }}
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="creator">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'discussions.creator' | translate}}
        </th>

        <td mat-cell *matCellDef="let element">
            {{ getUserName(element.creator) }}
        </td>
    </ng-container>
    <ng-container matColumnDef="dateIdentified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'discussions.dateIdentified' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ element.dateIdentifiedLocal | dateFormat }}
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'discussions.status' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-discussion-status [status]="element.status"></app-discussion-status>
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns" [wfRowFocus]="row" [wfRowFocusIndex]="i"
        [appDiscussionStatusRow]="row.status">
    </tr>
</table>
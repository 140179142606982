import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { GetNumberDto, NumberRecordDto } from "@api";

import { getNumberTargetStatus } from "~shared/util/number-helper";
import { expandWeekData } from "~shared/util/summary-component-helpers";

declare type WeekStatus = "ontarget" | "offtarget" | "updaterequired" | "updated" | null;

type NumberWeeklyRecord = {
    week: number;
} & ({
    record: null;
    status: null;
} | {
    record: NumberRecordDto;
    status: WeekStatus;
});

const getWeekStatus = (record: NumberRecordDto): WeekStatus => {
    if (record.result == null) return "updaterequired";
    return getNumberTargetStatus(record.result, record.target) ?? "updated";
};

const calculateNumberWeekData = (number: GetNumberDto): NumberWeeklyRecord[] =>
    expandWeekData(number).map(data => !data.record ?
        { week: data.week, record: data.record, status: null } :
        { week: data.week, record: data.record, status: getWeekStatus(data.record) });

@Component({
    selector: "app-number-result-summary",
    templateUrl: "./number-result-summary.component.html",
    styleUrls: ["./number-result-summary.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberResultSummaryComponent {

    readonly number = input.required<GetNumberDto>();

    readonly weekData = computed(() => calculateNumberWeekData(this.number()));

    readonly latestWeek = computed(() => this.number().latestWeek ?? null);

    getCellClass = (data: NumberWeeklyRecord): string => {
        switch (data.status) {
            case "ontarget":
                return "wf-result-summary-cell-on-target";
            case "offtarget":
                return "wf-result-summary-cell-off-target";
            case "updaterequired": {
                const latestWeek = this.latestWeek();
                if (latestWeek == null || data.week > latestWeek) {
                    return "wf-result-summary-cell-update-required-future";
                }
                return "wf-result-summary-cell-update-required";
            }
            case "updated":
                return "wf-result-summary-cell-updated";
            case null:
            default:
                return "wf-result-summary-cell-empty";
        }
    };
}

<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>{{'actions.action' | translate}}</h4>
    <mat-dialog-content>
        <app-origin-header *ngIf="origin" [origin]="origin"></app-origin-header>
        <div class="row">
            <div class="col-sm-12 textarea_col">
                <mat-form-field>
                    <mat-label>{{'actions.description' | translate}}</mat-label>
                    <textarea matInput formControlName="description" autocomplete="off" maxlength="1000"
                        required cdkFocusInitial></textarea>
                    <mat-error *ngIf="!descriptionControl.valid">
                        {{'actions.descriptionRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
            <!-- Creator -->
            <div class="col-sm-6">
                <mat-form-field *ngIf="creatorControl.enabled; else fakeCreator">
                    <mat-label>{{'actions.creator' | translate}}</mat-label>
                    <app-auto-select formControlName="creator" [options]="creators$ | async" [optionValueFunc]="getUserId"
                        [optionDisplayFunc]="getUserName" required>
                    </app-auto-select>
                    <mat-error *ngIf="!creatorControl.valid">
                        {{'actions.creatorRequired' | translate}}
                    </mat-error>
                </mat-form-field>
                <ng-template #fakeCreator>
                    <mat-form-field>
                        <mat-label>{{'actions.creator' | translate}}</mat-label>
                        <input matInput type="text" [value]="getUserName(originalCreator)" disabled required />
                    </mat-form-field>
                </ng-template>
            </div>
            <!-- Due Date -->
            <div class="col-sm-6" *ngIf="showDueDate">
                <mat-form-field class="datePickerBox">
                    <mat-label>{{'actions.dueDate' | translate}}</mat-label>
                    <input matInput type="text" formControlName="dueDate" readonly autocomplete="off" [min]="minDueDate"
                        autocomplete="off" [matDatepicker]="picker" (focus)="picker.open()" (click)="picker.open()" />
                </mat-form-field>
                <mat-datepicker #picker></mat-datepicker>
            </div>
            <!-- Team -->
            <div class="col-sm-6"
                [matTooltip]="(teamSelectionDisallowed$ | async) ? ('actions.teamSelectionDisallowed' | translate) : undefined"
                [matTooltipPosition]="'above'">
                <mat-form-field *ngIf="!readonly && (teamSelectionEnabled$ | async); else disabledTeam">
                    <mat-label>{{'actions.team' | translate}}</mat-label>
                    <app-auto-select formControlName="team" [options]="teams$ | async" [optionValueFunc]="getTeamId"
                        [optionDisplayFunc]="getTeamDisplay" [searchFunc]="getTeamSearchData" required>
                        <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                    </app-auto-select>
                    <mat-error *ngIf="!teamControl.valid">
                        {{'actions.teamRequired' | translate}}
                    </mat-error>
                </mat-form-field>
                <ng-template #disabledTeam>
                    <mat-form-field [appPlanTooltip]="(crossTeamTeased$ | async) ?
                        ('featureUnavailable.inputTooltip' | translate) : undefined">
                        <mat-label>{{'actions.team' | translate}}</mat-label>
                        <input matInput type="text" [value]="originalTeamName" disabled required />
                        <mat-icon svgIcon="wf-stars-complete" matSuffix class="feature-stars"
                            *ngIf="(crossTeamTeased$ | async)"></mat-icon>
                    </mat-form-field>
                </ng-template>
            </div>
            <!-- Owner -->
            <div class="col-sm-6" *ngIf="!isNewAction; else multipleOwners">
                <mat-form-field *ngIf="!readonly; else fakeOwner">
                    <mat-label>{{'actions.owner' | translate}}</mat-label>
                    <app-auto-select formControlName="owner" [options]="owners$ | async" [optionValueFunc]="getUserId"
                        [optionDisplayFunc]="getUserName" required>
                    </app-auto-select>
                    <mat-error *ngIf="!ownerControl.valid">
                        {{'actions.ownerRequired' | translate}}
                    </mat-error>
                </mat-form-field>
                <ng-template #fakeOwner>
                    <mat-form-field>
                        <mat-label>{{'actions.owner' | translate}}</mat-label>
                        <input matInput type="text" [value]="getUserName(originalOwner)" disabled required />
                    </mat-form-field>
                </ng-template>
            </div>
            <ng-template #multipleOwners>
                <!-- Owners -->
                <div class="col-sm-12">
                    <mat-form-field>
                        <mat-label>{{'actions.owner' | translate}}</mat-label>
                        <app-multi-select formControlName="owners" [options]="owners$ | async"
                            [optionValueFunc]="getUserId" [optionDisplayFunc]="getUserName" required>
                        </app-multi-select>
                        <mat-error *ngIf="!ownersControl.valid">
                            {{'actions.ownerRequired' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-template>
            <!-- Priority -->
            <div class="col-sm-6">
                <mat-form-field>
                    <mat-label>{{'actions.priority' | translate}}</mat-label>
                    <mat-select formControlName="priority" disableOptionCentering required>
                        <mat-option *ngFor="let priority of priorities" [value]="priority">
                            {{ getPriorityNameKey(priority) | translate }}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="!priorityControl.valid">
                        {{'actions.priorityRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="col-sm-6" *ngIf="readonly && originalDepartment">
                <mat-form-field>
                    <mat-label>{{'actions.department' | translate}}</mat-label>
                    <input matInput type="text" [value]="originalDepartment.name" disabled />
                </mat-form-field>
            </div>
            <ng-container *ngIf="!readonly && (departments$ | async); let departments">
                <div class="col-sm-6" *ngIf="departments.length">
                    <mat-form-field>
                        <mat-label>{{'actions.department' | translate}}</mat-label>
                        <app-auto-select name="department" formControlName="department" [options]="departments$ | async"
                            [optionValueFunc]="getDepartmentId" [optionDisplayFunc]="getDepartmentName">
                        </app-auto-select>
                    </mat-form-field>
                </div>
            </ng-container>

            <!-- CAT -->
            <div class="col-sm-6" *ngIf="readonly && originalCategory">
                <mat-form-field>
                    <mat-label>{{ (isAucbg ? 'actions.frameworkCategory' : 'actions.category') | translate }}</mat-label>
                    <input matInput type="text" [value]="originalCategory.description" disabled />
                </mat-form-field>
            </div>
            <ng-container *ngIf="!readonly && (categories$ | async); let categories">
                <div class="col-sm-6" *ngIf="categories.length">
                    <mat-form-field>
                        <mat-label>{{ (isAucbg ? 'actions.frameworkCategory' : 'actions.category') | translate }}</mat-label>
                        <app-auto-select formControlName="category" [options]="categories"
                            [optionValueFunc]="getCategoryId" [optionDisplayFunc]="getCategoryDisplay">
                        </app-auto-select>
                    </mat-form-field>
                </div>
            </ng-container>
            <div class="col-sm-6" *ngIf="readonly && originalSubCategory">
                <mat-form-field>
                    <mat-label>{{ (isAucbg ? 'actions.frameworkSubCategory' : 'actions.subCategory') | translate }}</mat-label>
                    <input matInput type="text" [value]="originalSubCategory.description" disabled required />
                </mat-form-field>
            </div>
            <ng-container *ngIf="!readonly && (subCategories$ | async); let subCategories">
                <div class="col-sm-6" *ngIf="subCategories.length">
                    <mat-form-field>
                        <mat-label>{{ (isAucbg ? 'actions.frameworkSubCategory' : 'actions.subCategory') | translate }}</mat-label>
                        <app-auto-select formControlName="subCategory" [options]="subCategories"
                            [optionValueFunc]="getSubCategoryId" [optionDisplayFunc]="getSubCategoryDisplay" required>
                        </app-auto-select>
                        <mat-error *ngIf="!subCategoryControl.valid">
                            {{'actions.subCategoryRequired' | translate}}
                        </mat-error>
                    </mat-form-field>
                </div>
            </ng-container>
            <!-- Goal -->
            <div class="col-sm-6" *ngIf="goalControl.enabled">
                <mat-form-field>
                    <mat-label>{{ ( isAucbg ? 'actions.target' : 'actions.goal' ) | translate }}</mat-label>
                    <mat-select formControlName="goal" disableOptionCentering>
                        <mat-option>
                            {{ 'noneOption' | translate }}
                        </mat-option>
                        <mat-option *ngFor="let goal of goals$ | async" [value]="goal.id">
                            {{ goal.heading }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <ng-container *ngIf="canSetRecurrence && (recurringActionsVisible$ | async)">
                <!-- Recurrence -->
                <div class="col-sm-6">
                    <mat-form-field *ngIf="recurringActionsEnabled$ | async; else fakeRecurrence">
                        <mat-label>{{'actions.actionRecurrence' | translate}}</mat-label>
                        <mat-select formControlName="recurrenceType" required>
                            <mat-option *ngFor="let type of recurrenceTypes" [value]="type">
                                {{ getRecurrenceTypeNameKey(type) | translate}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <ng-template #fakeRecurrence>
                        <mat-form-field [appPlanTooltip]="'featureUnavailable.inputTooltip' | translate">
                            <mat-label>{{'actions.actionRecurrence' | translate}}</mat-label>
                            <input matInput type="text" [value]="'actionRecurringType.never' | translate" disabled
                                required />
                            <mat-icon svgIcon="wf-stars-complete" matSuffix class="feature-stars"></mat-icon>
                        </mat-form-field>
                    </ng-template>
                </div>
                <div class="col-sm-12" *ngIf="isRecurring && (recurringActionsEnabled$ | async)">
                    <app-recurrence-control formControlName="recurrence" required extended>
                    </app-recurrence-control>
                </div>
            </ng-container>
        </div>
        <div class="row" *ngIf="canSetPrivateAction">
            <div class="col-sm-12">
                <mat-checkbox formControlName="isPrivateAction">
                    {{'actions.privateAction' | translate}}
                </mat-checkbox>
            </div>
        </div>
        <ng-container *ngIf="delegationVisible$ | async">
            <div class="row">
                <div class="col-sm-12">
                    <mat-checkbox formControlName="isDelegated">
                        {{'delegation.delegateToAnotherTeam' | translate}}
                    </mat-checkbox>
                </div>
            </div>
            <div class="delegation-container" *ngIf="isDelegatedControl.value" @fadeExpand [@.disabled]="disableAnimations">
                <fieldset formGroupName="delegation">
                    <legend>{{'delegation.delegation' | translate}}</legend>
                    <div class="delegation-row">
                        <mat-form-field *ngIf="!readonly; else fakeDelegatedTeam">
                            <mat-label>{{'delegation.team' | translate}}</mat-label>
                            <app-auto-select formControlName="team" [options]="delegationTeams$ | async" [optionValueFunc]="getTeamId"
                                [optionDisplayFunc]="getTeamDisplay" [searchFunc]="getTeamSearchData" required>
                                <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
                            </app-auto-select>
                            <mat-error *ngIf="!delegationTeamControl.valid">
                                {{'delegation.required' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <ng-template #fakeDelegatedTeam>
                            <mat-form-field>
                                <mat-label>{{'delegation.team' | translate}}</mat-label>
                                <input matInput type="text" [value]="originalDelegation?.team?.name" disabled required />
                            </mat-form-field>
                        </ng-template>
                        <mat-form-field *ngIf="!readonly; else fakeDelegatedUser">
                            <mat-label>{{'delegation.assignee' | translate}}</mat-label>
                            <app-auto-select formControlName="assignee" [options]="delegationUsers$ | async" [optionValueFunc]="getUserId"
                                [optionDisplayFunc]="getUserName" required>
                            </app-auto-select>
                            <mat-error *ngIf="!delegationAssigneeControl.valid">
                                {{'delegation.required' | translate}}
                            </mat-error>
                        </mat-form-field>
                        <ng-template #fakeDelegatedUser>
                            <mat-form-field>
                                <mat-label>{{'delegation.assignee' | translate}}</mat-label>
                                <input matInput type="text" [value]="getUserName(originalDelegation?.assignee)" disabled required />
                            </mat-form-field>
                        </ng-template>
                    </div>
                </fieldset>
            </div>
        </ng-container>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="!readonly">
        <app-status-button type="submit" [state]="buttonState">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
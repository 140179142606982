/* eslint-disable max-classes-per-file */
import { AriaDescriber, FocusMonitor } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { ConnectedPosition, Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { Platform } from "@angular/cdk/platform";
import { DOCUMENT } from "@angular/common";
import {
    ANIMATION_MODULE_TYPE, ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive,
    ElementRef, Inject, Input, NgZone, Optional, TemplateRef, ViewChild, ViewContainerRef, ViewEncapsulation
} from "@angular/core";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_SCROLL_STRATEGY, MatTooltip, MatTooltipDefaultOptions, TooltipComponent } from "@angular/material/tooltip";

// These constants were taken from MDC's `numbers` object. We can't import them from MDC,
// because they have some top-level references to `window` which break during SSR.
const UNBOUNDED_ANCHOR_GAP = 8;

@Directive({
    selector: "[wfTemplateTooltip]",
    exportAs: "wfTemplateTooltip",
    host: {
        "class": "mat-tooltip-trigger",
    },
})
export class TemplateTooltipDirective extends MatTooltip {

    get template() {
        return this._template;
    }

    @Input("wfTemplateTooltip")
    set template(value: TemplateRef<any> | null) {
        this._template = value;

        // Much of the tooltip's functionality relies on checking if the message property is falsey.
        // As such, if we have a template, set the message property to a non-falsey value.
        (this as any)._message = value ? " " : "";

        if (!this._template && this._isTooltipVisible()) {
            this.hide(0);
        } else {
            (this as any)._setupPointerEnterEventsIfNeeded();
            this._updateTooltipTemplate();
            (this as any)._updateTooltipMessage();
        }
    }

    private _template: TemplateRef<unknown> | null = null;

    constructor(
        overlay: Overlay,
        elementRef: ElementRef<HTMLElement>,
        scrollDispatcher: ScrollDispatcher,
        viewContainerRef: ViewContainerRef,
        ngZone: NgZone,
        platform: Platform,
        ariaDescriber: AriaDescriber,
        focusMonitor: FocusMonitor,
        @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: any,
        @Optional() dir: Directionality,
        @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS) defaultOptions: MatTooltipDefaultOptions,
        @Inject(DOCUMENT) _document: any,
    ) {
        super(
            overlay,
            elementRef,
            scrollDispatcher,
            viewContainerRef,
            ngZone,
            platform,
            ariaDescriber,
            focusMonitor,
            scrollStrategy,
            dir,
            defaultOptions,
            _document,
        );

        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        (this as any)._tooltipComponent = TemplateTooltipComponent;
        (this as any)._cssClassPrefix = "wf-template";
    }

    show(delay: number = this.showDelay): void {
        super.show(delay);
        this._updateTooltipTemplate();
    }

    private _updateTooltipTemplate() {
        if (this._tooltipInstance) {
            (this._tooltipInstance as TemplateTooltipComponent).template = this.template;
            (this as any)._updateTooltipMessage();
        }
    }

    protected override _addOffset(position: ConnectedPosition): ConnectedPosition {
        const offset = UNBOUNDED_ANCHOR_GAP;
        const isLtr = !this._dir || this._dir.value === "ltr";

        if (position.originY === "top") {
            position.offsetY = -offset;
        } else if (position.originY === "bottom") {
            position.offsetY = offset;
        } else if (position.originX === "start") {
            position.offsetX = isLtr ? -offset : offset;
        } else if (position.originX === "end") {
            position.offsetX = isLtr ? offset : -offset;
        }

        return position;
    }
}

@Component({
    selector: "wf-template-tooltip-component",
    templateUrl: "./template-tooltip.component.html",
    styleUrls: ["./template-tooltip.component.scss"],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        /* eslint-disable @typescript-eslint/naming-convention */
        // Forces the element to have a layout in IE and Edge. This fixes issues where the element
        // won't be rendered if the animations are disabled or there is no web animations polyfill.
        "[style.zoom]": "isVisible() ? 1 : null",
        "aria-hidden": "true",
        /* eslint-enable @typescript-eslint/naming-convention */
    },
})
export class TemplateTooltipComponent extends TooltipComponent {

    template: TemplateRef<unknown> | null = null;

    @ViewChild("tooltip", {
        // Use a static query here since we interact directly with
        // the DOM which can happen before `ngAfterViewInit`.
        static: true,
    }) _tooltip!: ElementRef<HTMLElement>;

    constructor(
        changeDetectorRef: ChangeDetectorRef,
        elementRef: ElementRef<HTMLElement>,
        @Optional() @Inject(ANIMATION_MODULE_TYPE) animationMode?: string,
    ) {
        super(changeDetectorRef, elementRef, animationMode);

        (this as any)._showAnimation = "wf-template-tooltip-show";
        (this as any)._hideAnimation = "wf-template-tooltip-hide";
    }
}

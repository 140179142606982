import { Directive, ElementRef, HostBinding, Input, OnChanges } from "@angular/core";

// eslint-disable-next-line unused-imports/no-unused-imports
import { growWidthAnimationBuilder } from "~shared/util/animations"; // We need this import for the doc link to work.

/**
 * A directive that can be used to give the host element width animation when transitioning between two non-zero widths.
 *
 * Note: Use of this directive requires an animation trigger called "growWidth" defined on the parent component.
 * This animation should accept a parameter called "startWidth" for the width of the element in px prior to the animation.
 *
 * @see {@link growWidthAnimationBuilder} for an example of this animation.
 *
 * @example
 * // In component .ts file
 * animations: [
 *     trigger("growWidth", growWidthAnimationBuilder("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
 *     // other animations here
 * ]
 *
 * // In component template
 * <div [appSmoothWidth]="isExpanded">
 *     <div *ngIf="!isExpanded">
 *         <!-- Collapsed content goes here -->
 *     </div>
 *     <div *ngIf="isExpanded">
 *         <!-- Expanded content goes here -->
 *     </div>
 * </div>
 */
@Directive({
    selector: "[appSmoothWidth]"
})
export class SmoothWidthAnimationDirective implements OnChanges {

    @Input("appSmoothWidth") smoothWidth: unknown;

    @HostBinding("@growWidth") get grow() {
        return { value: this.smoothWidth, params: { startWidth: this.startWidth } };
    }

    private startWidth = 0;

    constructor(private readonly element: ElementRef<HTMLElement>) { }

    ngOnChanges() {
        this.setStartWidth();
    }

    private setStartWidth = () => {
        this.startWidth = this.element.nativeElement.clientWidth;
    };

}

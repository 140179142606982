import { Injectable } from "@angular/core";
import { CurrentCompanyDto } from "@api";
import { NgxIndexedDBService } from "ngx-indexed-db";

import { BaseStoreWithExpiry } from "./base-store";

export interface CompanyData {
    id: string;
    clientId: string;

    data: CurrentCompanyDto;
    updated: Date;
}

@Injectable({
    providedIn: "root",
})
export class CompanyStore extends BaseStoreWithExpiry<CurrentCompanyDto, CompanyData> {

    protected readonly storeName = "companies";

    constructor(
        dbService: NgxIndexedDBService,
    ) {
        super(dbService);
    }

    protected wrapItem = (item: CurrentCompanyDto): CompanyData => ({
        id: item.id,
        clientId: item.clientId,
        data: item,
        updated: new Date(),
    });
}

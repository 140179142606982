import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Observable } from "rxjs";

import { apiBaseURL } from "~/app/api-config/app-api.config";
import { environment } from "~/environments/environment";

import { getFixedDate } from "./time-machine";

const headerDateFormat = "YYYY-MM-DDTHH:mm:ss.SSSSSSSZ";

@Injectable()
export class TimeMachineInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (environment.timeMachine && req.url.startsWith(apiBaseURL.baseURL) && getFixedDate()) {
            req = req.clone({
                setHeaders: {
                    "x-date-override": moment.utc().format(headerDateFormat)
                }
            });
        }
        return next.handle(req);
    }
}

import { GetMeetingInstanceDto } from "@api";

import { IWeek } from "~repositories";
import { MeetingStatus } from "~shared/enums";

export const isInstanceThisWeek = (instance: GetMeetingInstanceDto, currentWeek: IWeek) =>
    instance.financialYear === currentWeek.financialYear &&
    instance.planningPeriod === currentWeek.quarter &&
    instance.collectionPeriod === currentWeek.week;

export const canLaunchMeeting = (data: GetMeetingInstanceDto, currentWeek: IWeek) =>
    data.status === MeetingStatus.notStarted &&
    (data.type !== "scheduled" || !data.isCancelled) &&
    isInstanceThisWeek(data, currentWeek);

export const canLaunchAdHocMeeting = (data: GetMeetingInstanceDto, currentWeek: IWeek) =>
    data.meeting.allowAdHocMeetings &&
    data.status !== MeetingStatus.inProgress;

import * as color from "color";

import { secondaryBlue, secondaryGreen } from "~shared/colors";

/* eslint-disable @typescript-eslint/naming-convention */
const majorRowColors: { [level: string]: string } = {
    "3.2": "#e61a4f",
    "3.3": secondaryBlue,
    "3.4": secondaryGreen,
};
/* eslint-enable @typescript-eslint/naming-convention */

const minorRowColors: { [level: string]: string } =
    Object.keys(majorRowColors).reduce((dict, level) => {
        dict[level] = color(majorRowColors[level]).mix(color("white"), 0.5).hex();
        return dict;
    }, {} as { [level: string]: string });

export const getScoreLevelColor = (level: string): string | undefined => {
    const tokens = level.split(".");
    if (tokens.length === 2) {
        return majorRowColors[level] ?? undefined;
    }
    if (tokens.length === 3) {
        const majorLevel = `${tokens[0]}.${tokens[1]}`;
        return minorRowColors[majorLevel] ?? undefined;
    }
    return undefined;
};

@for (data of weekData(); track data.week) {
    @if (data.record) {
        <span class="wf-report-summary-cell" [class.wf-report-summary-cell-current]="data.week === latestWeek()"
            [ngClass]="getCellClass(data)" [wfTemplateTooltip]="tooltip" matTooltipClass="light-tooltip"
            tabindex="0">
            <span class="cdk-visually-hidden">
                <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
            </span>
        </span>
        <ng-template #tooltip>
            <dl>
                <div>
                    <dt translate="period.week"></dt>
                    <dd>{{ data.week }}</dd>
                </div>
                @if (isExternallyUpdated()) {
                    <div>
                        <dt translate="reports.externallyUpdated"></dt>
                        <dd>
                            <span class="wf-status-indicator"
                                [ngClass]="data.record.externallyUpdated ? 'wf-status-indicator-shaded wf-status-indicator-success' : 'wf-status-indicator-outline wf-status-indicator-failure'">
                                {{ (data.record.externallyUpdated ? 'reports.updated' : 'reports.notUpdated') | translate }}
                            </span>
                        </dd>
                    </div>
                }
                <div [class.full-width]="isExternallyUpdated() && report().permanentLinks?.length || data.record.reportIsSet">
                    <dt translate="reports.reports"></dt>
                    <dd>
                        @if (isExternallyUpdated() && report().permanentLinks?.length) {
                            <div *ngFor="let link of report().permanentLinks" class="link">
                                <i class="fas fa-link"></i>
                                <a class="link-name" [attr.href]="link" target="_blank">{{ link }}</a>
                            </div>
                        } @else if (!data.record.reportIsSet) {
                            {{ 'reports.update.noAttachments' | translate }}
                        }
                        @for (doc of data.record.reports; track doc) {
                            <div class="file">
                                <i class="fas fa-file-alt"></i>
                                <a class="attachment-name" [attr.href]="doc.uri" target="_blank">{{ doc.name }}</a>
                            </div>
                        }
                        @for (link of data.record.links; track link) {
                            <div class="link">
                                <i class="fas fa-link"></i>
                                <a class="link-name" [attr.href]="link" target="_blank">{{ link }}</a>
                            </div>
                        }
                    </dd>
                </div>
            </dl>
        </ng-template>
    } @else {
        <span class="wf-report-summary-cell wf-report-summary-cell-empty" aria-hidden="true"></span>
    }
}
<li app-feed-list-item-content [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" icon="link">

    <a [href]="feedItem.link" target="_blank">{{ feedItem.link }}</a>

    <div class="actions" *appFeedListItemActions>
        <button color="warn" mat-icon-button *ngIf="canUserDelete" (click)="delete()" [disabled]="deleteStarted">
            <mat-icon>delete</mat-icon>
        </button>
    </div>
</li>
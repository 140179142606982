<table mat-table class="wf-table" [dataSource]="dataSource" [trackBy]="trackByIdAndWeek" wfTableFocus>
    <ng-container matColumnDef="week">
        <th mat-header-cell *matHeaderCellDef class="wf-column-heading">
            {{ 'period.week' | translate }}
        </th>
        <td mat-cell *matCellDef="let record" class="wf-column-heading">
            <mat-icon *ngIf="record.week === selectedWeek" class="selected-week"
                [matTooltip]="'homepage.selectedWeek' | translate">today</mat-icon>
            {{ record.week }}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="updated">
        <th mat-header-cell *matHeaderCellDef>
            {{'reports.updated' | translate}}
        </th>
        <td mat-cell *matCellDef="let report">
            <app-completion-indicator [completed]="report.reportIsSet">
            </app-completion-indicator>
        </td>
    </ng-container>

    <ng-container matColumnDef="reports">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'reports.reports' | translate }}
        </th>
        <td mat-cell *matCellDef="let record">
            <div class="reports-container">
                <app-report-upload-button [record]="record" (updated)="recordUpdated.emit($event)">
                </app-report-upload-button>
                <app-report-attachment-links [report]="record">
                </app-report-attachment-links>
            </div>

            <app-notes-callout *ngIf="record.notes" [notes]="record.notes"></app-notes-callout>
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; let i = index; columns: displayedColumns;" [wfRowFocus]="element"
        [wfRowFocusIndex]="i"></tr>
</table>
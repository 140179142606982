/**
 * Colors in this file should be synchronised with src/styles/palette.scss
 */

export const workfactaNavy = "#243746";
export const workfactaBlue = "#3CB4E5";
export const workfactaYellow = "#FFC629";
export const workfactaRed = "#E7004C";

export const secondaryGray = "#758592";
export const secondaryNavy = "#0B223F";
export const secondaryBlue = "#003DA6";
export const secondaryGreen = "#00AE42";
export const secondaryOrange = "#FF8300";
export const secondaryRed = "#FE3B1F";

// Used for goal status. Can potentially be used for other purposes.
export const successColor = secondaryGreen; // Green
export const failureColor = workfactaRed; // Red

export const AVATAR_COLOURS = [workfactaBlue, workfactaYellow, workfactaRed, secondaryNavy,
    secondaryBlue, secondaryGreen, secondaryOrange, secondaryRed];

import { HttpErrorResponse } from "@angular/common/http";
import { MonoTypeOperatorFunction, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export class WorkfactaError {
    readonly status: number;
    readonly code: string;

    constructor(status: number, code: string) {
        this.status = status;
        this.code = code;
    }
}

export const wrapWorkfactaError = <T>(): MonoTypeOperatorFunction<T> => catchError(err => {
    if (err instanceof HttpErrorResponse) {
        const errorCode = err.error?.errorCode;
        if (errorCode && typeof errorCode === "string") {
            return throwError(() => new WorkfactaError(err.status, errorCode));
        }
    }
    return throwError(() => err);
});

export enum ErrorCode {
    nameConflict = "name_conflict",
    teamNameConflict = "team_name_conflict",
    userNameConflict = "user_name_conflict",
    subscriptionConflict = "subscription_conflict",
    roleConflict = "role_conflict",
    planCapExceeded = "plan_cap_exceeded",
    planCapReached = "plan_cap_reached",
    featureUnavailable = "feature_unavailable",
    primaryTeamNotSet = "primary_team_not_set",
    calculationCircularReference = "calculation_circular_reference",
    meetingReminderTypeConflict = "meeting_reminder_type_conflict",
};

<mat-select #yearSelect [formControl]="financialYearControl" class="wf-quarter-field-year" panelClass="wf-quarter-field-panel-year">
    @if (!disabled) {
        <mat-option *ngFor="let year of financialYears$ | async" [value]="year">
            {{ 'period.yearShort' | translate: { financialYear: year } }}
        </mat-option>
    } @else {
        <mat-option [value]="year" *ngIf="financialYearControl.value; let year">
            {{ 'period.yearShort' | translate: { financialYear: year } }}
        </mat-option>
    }
</mat-select>
<mat-select #quarterSelect [formControl]="quarterControl" class="wf-quarter-field-quarter" panelClass="wf-quarter-field-panel-quarter">
    @if (!disabled) {
        <mat-option *ngFor="let quarter of quarters$ | async" [value]="quarter">
            {{ 'period.quarterShort' | translate: { quarter } }}
        </mat-option>
    } @else {
        <mat-option [value]="quarter" *ngIf="quarterControl.value; let quarter">
            {{ 'period.quarterShort' | translate: { quarter } }}
        </mat-option>
    }
</mat-select>
<h4 mat-dialog-title>{{'NewMeetingStartScreen.unscheduledMeeting' | translate}}</h4>
<mat-dialog-content [ngSwitch]="messageType">
    <ng-container *ngSwitchCase="'closed'">
        {{'NewMeetingStartScreen.unscheduledMessageClosed' | translate}}
    </ng-container>
    <ng-container *ngSwitchCase="'cancelled'">
        {{'NewMeetingStartScreen.unscheduledMessageCancelled' | translate}}
    </ng-container>
    <ng-container *ngSwitchCase="'notStarted'">
        {{'NewMeetingStartScreen.unscheduledMessageNotStarted' | translate}}
    </ng-container>
    <ng-container *ngSwitchCase="'noScheduled'">
        {{'NewMeetingStartScreen.unscheduledMessageNoScheduled' | translate}}
    </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <app-status-button type="button" [state]="buttonState"
        (buttonClick)="launchAdHocMeeting()">
        {{ "Start" | translate }}
    </app-status-button>
</mat-dialog-actions>
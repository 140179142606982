<button type="button" mat-icon-button [matMenuTriggerFor]="popupMenu" [disabled]="!action">
    <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #popupMenu="matMenu" xPosition="before">
    <ng-container *ngIf="access$ | async; let access">
        <button mat-menu-item (click)="editAction(access)" *ngIf="access.canEdit && !action?.isDelegated; else viewButton">
            <mat-icon color="primary">edit</mat-icon>
            <span>{{'Edit' | translate}}</span>
        </button>
        <ng-template #viewButton>
            <button mat-menu-item (click)="viewAction()">
                <mat-icon color="primary">visibility</mat-icon>
                <span>{{'View' | translate}}</span>
            </button>
        </ng-template>
        <button mat-menu-item [disabled]="!access.canEdit" (click)="copyAction(access)" *ngIf="!disableCopy">
            <mat-icon color="primary">copy</mat-icon>
            <span>{{'Copy' | translate}}</span>
        </button>
        <button mat-menu-item [disabled]="!access.canDelete" (click)="deleteAction(access)" *ngIf="!action?.isDelegated">
            <mat-icon color="warn">delete</mat-icon>
            <span>{{'Delete' | translate}}</span>
        </button>
    </ng-container>
</mat-menu>
import { Injectable } from "@angular/core";
import { LanguageApi } from "@api";
import { map, shareReplay } from "rxjs/operators";

import { retryWithDelay } from "~shared/util/caching";

const ERROR_RETRY_DELAY_MS = 10000; // 10 seconds

/**
 * Service designed to provide and cache lists of statuses and types
 */
@Injectable({
    providedIn: "root"
})
export class StatusRepository {

    readonly languages$ = this.languageApi.getAllLanguages().pipe(
        retryWithDelay(ERROR_RETRY_DELAY_MS),
        map(langs => langs.filter(lang => lang.FullName === "English")),
        shareReplay({ bufferSize: 1, refCount: false }),
    );

    constructor(
        private readonly languageApi: LanguageApi,
    ) {
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface Array<T> {
    groupBy<TKey = string>(this: T[], keyField: keyof T | ((item: T) => TKey)): Map<TKey, T[]>;
    flat(this: T[]): T;
    distinct(this: T[]): T[];
}

Array.prototype.groupBy = function<TKey, T>(this: T[], keyField: (item: T) => TKey): Map<TKey, T[]> {
    const keyFieldSelector = typeof keyField !== "function" ? ((item: any) => item[keyField]) : keyField;
    return this.reduce((result, current) => {
        const key = current && keyFieldSelector(current);
        const values = result.get(key);
        if (values) {
            values.push(current);
        } else {
            result.set(key, [current]);
        }
        return result;
    }, new Map<TKey, T[]>());
};

Array.prototype.flat = function<T>(this: T[]) {
    return ([] as T[]).concat(...this);
};

Array.prototype.distinct = function<T>(this: T[]) {
    return this.reduce((distinctSet, value) => {
        // eslint-disable-next-line eqeqeq
        if (!distinctSet.find(x => x == value)) {
            distinctSet.push(value);
        }
        return distinctSet;
    }, [] as T[]);
};

<div class="wf-number-result">

    <input type="number" matInput [appDisable]="disabled" [formControl]="resultControl" name="result" (focus)="displayAnyWarnings()" (blur)="save()"
        [ngClass]="{ 'on-target': targetStatus === 'ontarget' , 'off-target': targetStatus === 'offtarget' }"
        placeholder="{{ 'numbers.result' | translate }}" autocomplete="off" />

    <button mat-icon-button *ngIf="statusNotesEnabled" [appDisable]="disabled" [disabled]="disabled"
        (click)="openNotesDialog()" [color]="hasNote ? 'primary' : undefined"
        [matTooltip]="'numbers.update.addNote' | translate"
        class="add-notes-button" [attr.aria-pressed]="hasNote">
        <mat-icon>record_voice_over</mat-icon>
    </button>

    <mat-icon *ngIf="isAutomaticallyUpdated && automaticRetrievalFailed" matTooltip="{{ 'numbers.update.retrievalFailTooltip' | translate }}"
        matTooltipPosition="right" class="status-icon" color="warn">error</mat-icon>

    <mat-icon *ngIf="isAutomaticallyUpdated" matTooltip="{{ 'numbers.update.autoTooltip' | translate }}"
        matTooltipPosition="right" class="status-icon auto-indicator">update</mat-icon>

    <app-number-calc-sources-button [number]="record" *ngIf="record && shouldShowCalculationSources"></app-number-calc-sources-button>

</div>
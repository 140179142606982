import { authGuardBuilder } from "./base-auth.guard";
import { CustomRouteData } from "./custom-route";

export const featureFlagGuard = authGuardBuilder(({ company, route }) => {
    const routeData = route.data as CustomRouteData;

    const featureNames = routeData?.featureFlags;
    if (!featureNames) return true;

    return featureNames.every(featureName =>
        company?.features.some(feature =>
            feature.name === featureName && feature.state));
});

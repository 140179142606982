import { HttpErrorResponse } from "@angular/common/http";
import { BehaviorSubject, EMPTY, map, merge, MonoTypeOperatorFunction, Observable, of, retry, Subject, switchMap, tap, throwError, timer } from "rxjs";

import { shareReplayUntil } from "./rx-operators";

export const DEFAULT_ERROR_RETRY_DELAY_MS = 10000; // 10 seconds
export const DEFAULT_CACHE_EXPIRY_MS = 60000; // 1 minute

// #region Observable Operators
export const retryWithDelay = <T>(delayMs: number = DEFAULT_ERROR_RETRY_DELAY_MS) => retry<T>({
    delay: delayMs,
});

export const retryTransientWithDelay = <T>(delayMs: number = DEFAULT_ERROR_RETRY_DELAY_MS) => retry<T>({
    delay: error => {
        if (error instanceof HttpErrorResponse && (error.status >= 400 && error.status < 500)) {
            // This is a permanent error. We should raise the error.
            return throwError(() => error);
        }
        return timer(delayMs);
    }
});
// #endregion

// #region Standard Caching
export interface ICacheItem<T> {
    observable: Observable<T>;
    expires: number;
}

export interface ICompanyCache<T> {
    [companyId: string]: ICacheItem<T>;
}

export interface ICompanyTeamCache<T> {
    [companyId: string]: {
        [teamId: string]: ICacheItem<T>;
    };
}

export const deleteCompanyCacheItem = (cache: ICompanyCache<unknown> | ICompanyTeamCache<unknown>, companyId: string): void => {
    delete cache[companyId];
};

export const deleteTeamCacheItem = (cache: ICompanyTeamCache<unknown>, companyId: string, teamId: string): void => {
    const companyCache = cache[companyId];
    if (companyCache) {
        delete companyCache[teamId];
    }
};

export const getCompanyCacheItem = <T>(cache: ICompanyCache<T>, companyId: string): Observable<T> | null => {
    const item = cache[companyId];
    if (!item) return null;
    if (item.expires < Date.now()) {
        deleteCompanyCacheItem(cache, companyId);
        return null;
    }
    return item.observable;
};

export const getTeamCacheItem = <T>(cache: ICompanyTeamCache<T>, companyId: string, teamId: string): Observable<T> | null => {
    const item = cache[companyId]?.[teamId];
    if (!item) return null;
    if (item.expires < Date.now()) {
        deleteTeamCacheItem(cache, companyId, teamId);
        return null;
    }
    return item.observable;
};

export const buildCacheItem = <T>(
    value: Observable<T>,
    cacheExpiryMs: number): ICacheItem<T> => ({
        expires: Date.now() + cacheExpiryMs,
        observable: value
    });

export const setCompanyCacheItem = <T>(
    cache: ICompanyCache<T>,
    companyId: string,
    value: Observable<T>,
    cacheExpiryMs: number = DEFAULT_CACHE_EXPIRY_MS
): void => {
    cache[companyId] = buildCacheItem(value, cacheExpiryMs);
};

export const setTeamCacheItem = <T>(
    cache: ICompanyTeamCache<T>,
    companyId: string,
    teamId: string,
    value: Observable<T>,
    cacheExpiryMs: number = DEFAULT_CACHE_EXPIRY_MS
): void => {
    let companyCache = cache[companyId];
    if (!companyCache) {
        companyCache = {};
        cache[companyId] = companyCache;
    }
    companyCache[teamId] = buildCacheItem(value, cacheExpiryMs);
};
// #endregion

// #region Refreshable Caching
interface IRefreshableCacheItem<T> {
    getData(refresh?: boolean): Observable<T>;
    refresh(invalidate?: boolean): void;
    invalidate(): void;
}

interface IInternalRefreshableCompanyCache<T> {
    [companyId: string]: IRefreshableCacheItem<T>;
}

interface IInternalRefreshableCompanyTeamCache<T> {
    [companyId: string]: {
        [teamId: string]: IRefreshableCacheItem<T>;
    };
}

export interface IRefreshableCompanyCache<T> {
    getData(companyId: string, refresh?: boolean): Observable<T>;
    refresh(companyId: string, invalidate?: boolean): void;
    invalidate(companyId: string): void;
    destroy(): void;
}

export interface IRefreshableCompanyTeamCache<T> {
    getData(companyId: string, teamId: string, refresh?: boolean): Observable<T>;
    refresh(companyId: string, teamId: string, invalidate?: boolean): void;
    invalidate(companyId: string, teamId: string): void;

    refreshAll(companyId: string, invalidate?: boolean): void;
    invalidateAll(companyId: string): void;

    destroy(): void;
}

const buildRefreshableCacheItem = <T>(
    dataFunc: () => Observable<T>,
    destroyed$: Observable<void>,
    retryFunc: (<O>() => MonoTypeOperatorFunction<O>) | null,
    cacheExpiryMs: number): IRefreshableCacheItem<T> => {

    let isLoading = false;
    const refreshSubject = new BehaviorSubject<void>(undefined);
    let invalidatedDate: number | null = null;
    const data$ = refreshSubject.pipe(
        tap(() => isLoading = true),
        switchMap(() => dataFunc()),
        tap(() => isLoading = false),
        map(item => ({ item, stamp: Date.now() })),
        shareReplayUntil(destroyed$),
        (retryFunc ?? retryTransientWithDelay)(),
        switchMap(data => {
            // If the data is expired or has been invalidated, we should refresh and skip this value.
            if ((data.stamp + cacheExpiryMs < Date.now()) ||
                (invalidatedDate !== null && invalidatedDate > data.stamp)) {
                // If we're already loading data, we don't need to refresh here.
                if (!isLoading) refreshSubject.next();
                return EMPTY;
            }
            return of(data);
        }),
    );
    return {
        getData: (refresh: boolean = false) => {
            const requestedDate = Date.now();
            return data$.pipe(
                switchMap((data, index) => {
                    // If the first item emitted is before we requested the refresh, we should refresh.
                    // This allows for an item that was already in-flight when we requested the refresh to be ignored.
                    if (refresh && index === 0 && data.stamp < requestedDate) {
                        // If we're already loading data, we don't need to refresh here.
                        if (!isLoading) refreshSubject.next();
                        return EMPTY;
                    }
                    return of(data.item);
                })
            );
        },
        refresh: (invalidate = false) => {
            if (invalidate) {
                invalidatedDate = Date.now();
            }
            refreshSubject.next();
        },
        invalidate: () => {
            invalidatedDate = Date.now();
        },
    };
};

export const buildRefreshableCompanyCache = <T>(
    dataFunc: (companyId: string) => Observable<T>,
    destroyed$: Observable<void>,
    retryFunc: (<O>() => MonoTypeOperatorFunction<O>) | null = null,
    cacheExpiryMs: number = DEFAULT_CACHE_EXPIRY_MS,
): IRefreshableCompanyCache<T> => {
    const cache: IInternalRefreshableCompanyCache<T> = {};
    const destroySubject = new Subject<void>();
    const compoundDestroyed$ = merge(destroyed$, destroySubject);
    return {
        destroy: () => destroySubject.next(),
        getData: (companyId: string, refresh = false) => {
            let cacheItem = cache[companyId];
            if (!cacheItem) {
                cacheItem = buildRefreshableCacheItem(
                    () => dataFunc(companyId),
                    compoundDestroyed$,
                    retryFunc,
                    cacheExpiryMs,
                );
                cache[companyId] = cacheItem;
            }
            return cacheItem.getData(refresh);
        },
        refresh: (companyId: string, invalidate = false) => {
            const cacheItem = cache[companyId];
            if (cacheItem) cacheItem.refresh(invalidate);
        },
        invalidate: (companyId: string) => {
            const cacheItem = cache[companyId];
            if (cacheItem) cacheItem.invalidate();
        },
    };
};

export const buildRefreshableCompanyTeamCache = <T>(
    dataFunc: (companyId: string, teamId: string) => Observable<T>,
    destroyed$: Observable<void>,
    retryFunc: (<O>() => MonoTypeOperatorFunction<O>) | null = null,
    cacheExpiryMs: number = DEFAULT_CACHE_EXPIRY_MS,
): IRefreshableCompanyTeamCache<T> => {
    const cache: IInternalRefreshableCompanyTeamCache<T> = {};
    const destroySubject = new Subject<void>();
    const compoundDestroyed$ = merge(destroyed$, destroySubject);

    return {
        destroy: () => destroySubject.next(),
        getData: (companyId: string, teamId: string, refresh = false) => {
            let companyCache = cache[companyId];
            if (!companyCache) {
                companyCache = {};
                cache[companyId] = companyCache;
            }
            let cacheItem = companyCache[teamId];
            if (!cacheItem) {
                cacheItem = buildRefreshableCacheItem(
                    () => dataFunc(companyId, teamId),
                    compoundDestroyed$,
                    retryFunc,
                    cacheExpiryMs,
                );
                companyCache[teamId] = cacheItem;
            }
            return cacheItem.getData(refresh);
        },
        refresh: (companyId: string, teamId: string, invalidate = false) => {
            const companyCache = cache[companyId];
            if (!companyCache) return;
            const cacheItem = companyCache[teamId];
            if (cacheItem) cacheItem.refresh(invalidate);
        },
        invalidate: (companyId: string, teamId: string) => {
            const companyCache = cache[companyId];
            if (!companyCache) return;
            const cacheItem = companyCache[teamId];
            if (cacheItem) cacheItem.invalidate();
        },
        refreshAll: (companyId: string, invalidate?: boolean) => {
            const companyCache = cache[companyId];
            if (!companyCache) return;
            for (const key in companyCache) {
                if (Object.prototype.hasOwnProperty.call(companyCache, key)) {
                    companyCache[key].refresh(invalidate);
                }
            }
        },
        invalidateAll: (companyId: string) => {
            const companyCache = cache[companyId];
            if (!companyCache) return;
            for (const key in companyCache) {
                if (Object.prototype.hasOwnProperty.call(companyCache, key)) {
                    companyCache[key].invalidate();
                }
            }
        },
    };
};
// #endregion

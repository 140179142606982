import { FeatureAvailabilityDto, FeatureConstraintsDto, PlanTierDto } from "@api";
import { TranslateService } from "@ngx-translate/core";

import { FeatureStatus } from "~shared/enums";

import { makeSortDefinition, sortBoolean, sortMultiple, sortNumber } from "../shared/util/sorters";

export declare type FeatureConstraint = keyof (FeatureConstraintsDto);

export declare type FeatureConstraintExtended = FeatureConstraint | "goalCount" | "actionCount";

export declare type FeatureAvailability = keyof (FeatureAvailabilityDto);

export declare type FeatureAvailabilityExtended = FeatureAvailability | "weeklyScheduling";

export declare type Feature = FeatureConstraint | FeatureAvailability;

export const featureConstraints: FeatureConstraint[] = [
    "userCount", "teamCount", "numberCount", "reportCount", "openIssueCount", "openNewsCount", "widgetCount"
];

export const featureConstraintsExtended: FeatureConstraintExtended[] = [
    "userCount", "teamCount", "goalCount", "actionCount", "numberCount", "reportCount", "openIssueCount", "openNewsCount", "widgetCount"
];

export const perTeamConstraints: FeatureConstraintExtended[] = [
    "goalCount", "actionCount", "numberCount", "reportCount", "openIssueCount", "openNewsCount"
];

export const featureAvailabilities: FeatureAvailability[] = [
    "adHocMeetings", "recurringActions", "annualPlanning", "numberChartWidgets", "crossTeamFeatures",
    "enterpriseWidgets", "companyPerformance", "enterprisePerformance", "numberApi",
    "solutionApprovals", "flexibleScheduling", "publicHolidays", "externalIntegrations",
    "executionScoreTarget", "companyLogo", "lineManagerNotifications", "minutesNumberCharts", "delegation"
];

export const allFeatures = [...featureConstraints, ...featureAvailabilities];

const isNull = <T>(a: T | undefined | null): a is undefined | null => a === undefined || a === null;

export const isConstraint = (feature: Feature | undefined | FeatureConstraintExtended): feature is FeatureConstraint =>
    featureConstraints.includes(feature as FeatureConstraint);

export const isAvailability = (feature: Feature | undefined | FeatureConstraintExtended): feature is FeatureAvailability =>
    featureAvailabilities.includes(feature as FeatureAvailability);

const sortConstraintValue = makeSortDefinition<number | undefined>(sortMultiple(
    sortBoolean.ascending(c => isNull(c)),
    sortNumber.ascending(),
));

const sortAvailabilityValue = makeSortDefinition<FeatureStatus>(sortBoolean.ascending(f => f === FeatureStatus.enabled));

const sortConstraint = (constraint: FeatureConstraint) =>
    makeSortDefinition(sortConstraintValue.ascending<PlanTierDto>(p => p.featureConstraints[constraint]));

const sortAvailability = (availability: FeatureAvailability) =>
    makeSortDefinition(sortAvailabilityValue.ascending<PlanTierDto>(p => p.featureAvailability[availability]));

export const sortPlanOrder = makeSortDefinition(sortNumber.ascending<PlanTierDto>(a => a.order));

export const sortFeature = (feature: Feature | undefined) => {
    if (isConstraint(feature)) {
        return sortConstraint(feature);
    }
    if (isAvailability(feature)) {
        return sortAvailability(feature);
    }
    return sortPlanOrder;
};

export const isConstraintUnlimited = (plan: PlanTierDto, constraint: FeatureConstraintExtended): boolean => {
    const cap = isConstraint(constraint) ? plan.featureConstraints[constraint] : undefined;
    return cap === null || cap === undefined;
};

export const isConstraintPerTeam = (plan: PlanTierDto, constraint: FeatureConstraintExtended): boolean => {
    if (isConstraintUnlimited(plan, constraint)) return false;
    if (plan.featureConstraints.teamCount === 1) return false;
    return perTeamConstraints.includes(constraint);
};

export const isFeatureAvailable = (plan: PlanTierDto, availability: FeatureAvailability): boolean =>
    plan.featureAvailability[availability] === FeatureStatus.enabled;

export const isFeatureTeased = (plan: PlanTierDto, availability: FeatureAvailability): boolean =>
    plan.featureAvailability[availability] === FeatureStatus.teased;

export const getFeatureConstraintPluralNameKey = (feature: FeatureConstraintExtended): string =>
    featureConstraintsExtended.includes(feature) ? `planFeatures.constraintsPlural.${feature}` : "";

export const getFeatureConstraintSingularNameKey = (feature: FeatureConstraintExtended): string =>
    featureConstraintsExtended.includes(feature) ? `planFeatures.constraintsSingular.${feature}` : "";

export const getFeatureConstraintNameKey = (feature: FeatureConstraintExtended, count: number | undefined): string =>
    count === 1 ? getFeatureConstraintSingularNameKey(feature) : getFeatureConstraintPluralNameKey(feature);

export const getFeatureConstraintDescription = (
    translate: TranslateService,
    plan: PlanTierDto,
    feature: FeatureConstraintExtended
): string => {
    const count = isConstraint(feature) ? plan.featureConstraints[feature] : undefined;
    const nameKey = getFeatureConstraintNameKey(feature, count);
    const name = translate.instant(nameKey);
    const countString = (count === null || count === undefined) ? translate.instant("planFeatures.unlimited") : count.toString();

    const baseDesc = `${countString} ${name}`;
    return isConstraintPerTeam(plan, feature) ? `${baseDesc} ${translate.instant("planFeatures.perTeam")}` : baseDesc;
};

export const getFeatureAvailabilityNameKey = (feature: FeatureAvailability): string =>
    featureAvailabilities.includes(feature) ? `planFeatures.availabilities.${feature}` : "";

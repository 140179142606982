import { Injectable, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { CurrentCompanyAndTeamDto } from "@api";
import { BehaviorSubject, catchError, distinctUntilChanged, map, Observable, of, switchMap, tap } from "rxjs";

import { CompanyTeamRepository } from "~repositories";
import { UserService } from "~services/user.service";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";

import { SettingsService } from "../settings.service";
import { TeamContext } from "./team.context";

interface CompanyTeamId {
    companyId: string;
    teamId: string;
}

const buildCompanyTeamValue = (companyId: string | null | undefined, teamId: string | null | undefined): CompanyTeamId | null =>
    !companyId || !teamId ? null : {
        companyId: companyId,
        teamId: teamId
    };

@Injectable()
export class SimpleTeamContext extends WithDestroy(TeamContext) {

    readonly companyTeam: Signal<CurrentCompanyAndTeamDto | null>;
    readonly companyTeam$: Observable<CurrentCompanyAndTeamDto | null>;

    private readonly companyTeamIdSubject = new BehaviorSubject<CompanyTeamId | null>(
        buildCompanyTeamValue(this.settingsService.currentCompany?.id, this.settingsService.currentTeam?.id));

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(
        private readonly companyTeamRepository: CompanyTeamRepository,
        private readonly userService: UserService,
        private readonly settingsService: SettingsService,
    ) {
        super();

        this.companyTeam$ = this.userService.user$.pipe(
            tap(user => {
                if (user && !this.companyTeamIdSubject.value) {
                    this.companyTeamIdSubject.next(buildCompanyTeamValue(user.companyId, user.teamId));
                }
            }),
            map(u => !!u),
            distinctUntilChanged(),
            switchMap(hasUser => hasUser ? this.companyTeamIdSubject : of(null)),
            switchMap(ids => this.refreshSubject.pipe(
                map((_, index) => index !== 0),
                map(refresh => ({ ids, refresh })),
            )),
            switchMap(({ ids, refresh }) => !ids ? of(null) :
                // When we switch team (i.e. refresh = false), we don't need to force refresh data from the server -
                // this is only kept for 60 seconds anyway, so it would only be there if we recently got it. In this case,
                // we are also happy for the cached data to be emitted.
                // However, when refreshing data, we always want to force a refresh and we never want to emit the cached data -
                // it's likely the current value of the observable, so we want to wait for the server response.
                this.companyTeamRepository.getCompanyTeam(ids.companyId, ids.teamId,
                    /* force: */ refresh, /* includeCached */ !refresh).pipe(
                    catchError(() => of(null)),
                )),
            tap(companyTeam => {
                if (!companyTeam && this.userService.isAuthenticated) {
                    this.userService.reauthorize();
                }
            }),
            tap(this.saveCompanyTeam),
            shareReplayUntil(this.destroyed$),
        );

        this.companyTeam = toSignal(this.companyTeam$, { initialValue: null });
    }

    refresh = () => this.refreshSubject.next();

    setCompanyTeam = (companyId: string, teamId: string, refresh: boolean = true) => {
        const currentCompanyTeam = this.companyTeamIdSubject.value;
        if (currentCompanyTeam && currentCompanyTeam.companyId === companyId && currentCompanyTeam.teamId === teamId) {
            // If we're setting to the same team, fire a refresh. This will ensure the data is actually refreshed from the server.
            if (refresh) this.refreshSubject.next();
        } else {
            this.companyTeamIdSubject.next(buildCompanyTeamValue(companyId, teamId));
        }
    };

    private saveCompanyTeam = (companyTeam: CurrentCompanyAndTeamDto | null) => {
        this.settingsService.currentCompany = companyTeam?.company ?? null;
        this.settingsService.currentTeam = companyTeam?.team ?? null;
    };
}

import "intl";
import "intl/locale-data/jsonp/en";

import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { APP_INITIALIZER, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatAutocompleteDefaultOptions } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS, MatButtonToggleDefaultOptions } from "@angular/material/button-toggle";
import { MAT_CHECKBOX_DEFAULT_OPTIONS, MatCheckboxDefaultOptions } from "@angular/material/checkbox";
import { MAT_CHIPS_DEFAULT_OPTIONS, MatChipsDefaultOptions } from "@angular/material/chips";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions, MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioDefaultOptions } from "@angular/material/radio";
import { MAT_SELECT_CONFIG, MatSelectConfig } from "@angular/material/select";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS } from "@angular/material/slide-toggle";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from "@angular/material-moment-adapter";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { DomSanitizer } from "@angular/platform-browser";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PreloadAllModules, RouterModule } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { IntercomModule } from "@supy-io/ngx-intercom";
import { TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { GoogleTagManagerModule, GoogleTagManagerService } from "angular-google-tag-manager";
import { AvatarModule, AvatarSource } from "ngx-avatars";
import { NgxIndexedDBModule } from "ngx-indexed-db";
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from "ngx-timeago";
import { ToastrModule } from "ngx-toastr";
import { ToastrService } from "ngx-toastr";

import { ApiModule, BASE_PATH } from "~/api";
import { TimeMachineInterceptor } from "~/utils/time-machine-interceptor";
import { FeedModule } from "~feed";
import { YearContextRepository } from "~repositories";
import { AppUpdateService } from "~services/app-update.service";
import { provideSimpleContexts } from "~services/contexts";
import { GlobalFeatureFlagService, initializeGlobalFlagsFactory } from "~services/global-feature-flag.service";
import { IntercomService } from "~services/intercom.service";
import { AVATAR_COLOURS } from "~shared/colors";
import { CustomDateAdapter } from "~shared/custom-date-adapter";
import { CustomTimeagoIntl } from "~shared/custom-timeago-intl";
import { MatDateFormatsProvider } from "~shared/mat-date-formats.provider";
import { providePersistentStores } from "~stores";

import { environment } from "../environments/environment";
import { apiBaseURL } from "./api-config/app-api.config";
import { AppComponent } from "./app.component";
import { appRoutes } from "./app.routing";
import { ErrorInterceptor } from "./auth/request-interceptor.service";
import { AuthConfigModule } from "./auth-config.module";
import components from "./components";
import { dbConfig } from "./dbconfig";
import { HomepageModule } from "./homepage/homepage.module";
import { addIcons } from "./icons";
import { MaterialComponentsModule } from "./material-component/material-component.module";
import { AccessService } from "./services/access.service";
import { NotificationService } from "./services/notification.service";
import { SetUp } from "./services/setup.service";
import { VisionService } from "./services/vision.service";
import { SharedModule } from "./shared/shared.module";
import { DATE_LOCALE, STRIPE_INSTANCE } from "./shared/util/constants";
import { WebpackTranslateLoader } from "./webpack-translate-loader";

@NgModule({
    declarations: [
        AppComponent,
        components,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,

        MatButtonModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatToolbarModule,

        ApiModule,
        SharedModule,
        RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }),
        ToastrModule.forRoot(),
        MaterialComponentsModule,
        MatMomentDateModule,
        FeedModule,
        HomepageModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader
            }
        }),
        NgxIndexedDBModule.forRoot(dbConfig),
        IntercomModule.forRoot({
            appId: environment.intercomAppId,
            updateOnRouterChange: true
        }),
        GoogleTagManagerModule.forRoot({
            id: environment.gtm.id,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            gtm_preview: environment.gtm.environment,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            gtm_auth: environment.gtm.auth
        }),
        TimeagoModule.forRoot({
            intl: { provide: TimeagoIntl, useClass: CustomTimeagoIntl },
            formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
        }),
        AvatarModule.forRoot({
            sourcePriorityOrder: [AvatarSource.CUSTOM, AvatarSource.INITIALS],
            colors: AVATAR_COLOURS
        }),
        AuthConfigModule,
        ServiceWorkerModule.register("ngsw-worker.js", {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: "registerWhenStable:30000"
        }),
    ],
    providers: [
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_LOCALE, useValue: DATE_LOCALE },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: MAT_DATE_FORMATS, useClass: MatDateFormatsProvider },
        { provide: HTTP_INTERCEPTORS, useClass: TimeMachineInterceptor, multi: true },
        { provide: BASE_PATH, useValue: apiBaseURL.baseURL.replace(/\/$/, "") },
        { provide: STRIPE_INSTANCE, useFactory: () => window.Stripe?.(apiBaseURL.stripeKey) },
        { provide: TINYMCE_SCRIPT_SRC, useValue: "assets/tinymce/tinymce.min.js" },
        { provide: APP_INITIALIZER, useFactory: initializeGlobalFlagsFactory, deps: [GlobalFeatureFlagService], multi: true },
        { provide: MAT_SLIDE_TOGGLE_DEFAULT_OPTIONS, useValue: { color: "primary" } },
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: {
                autoActiveFirstOption: true,
                hideSingleSelectionIndicator: true,
            } as MatAutocompleteDefaultOptions,
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: "outline",
            } as MatFormFieldDefaultOptions,
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: "primary" } as MatRadioDefaultOptions,
        },
        {
            provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
            useValue: { color: "accent" } as MatCheckboxDefaultOptions,
        },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: {
                hideSingleSelectionIndicator: true,
            } as MatSelectConfig,
        },
        {
            provide: MAT_BUTTON_TOGGLE_DEFAULT_OPTIONS,
            useValue: {
                hideSingleSelectionIndicator: true,
                hideMultipleSelectionIndicator: true,
            } as MatButtonToggleDefaultOptions,
        },
        {
            provide: MAT_CHIPS_DEFAULT_OPTIONS,
            useValue: {
                hideSingleSelectionIndicator: true,
            } as MatChipsDefaultOptions,
        },
        providePersistentStores(),
        provideSimpleContexts(),
        SetUp,
        NotificationService,
        ToastrService,
        VisionService,
        AccessService,
        YearContextRepository,
    ],
    bootstrap: [AppComponent]
})

export class AppModule {
    constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer,
        intercomService: IntercomService, gtmService: GoogleTagManagerService,
        appUpdateService: AppUpdateService) {
        iconRegistry.setDefaultFontSetClass("material-icons-round");
        addIcons(iconRegistry, sanitizer);
        intercomService.initialise();
        gtmService.addGtmToDom();
        appUpdateService.initialise();
    }
}

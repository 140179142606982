/* eslint-disable max-classes-per-file */
import { Directive, Input, TemplateRef } from "@angular/core";

export class TranslateTemplateContext {
    $implicit!: string;
}

@Directive({
    selector: "[wfTransTplContent]"
})
export class TranslateTemplateContentDirective {
    @Input("wfTransTplContent") name!: string;

    constructor(
        public template: TemplateRef<TranslateTemplateContext>
    ) { }

    static ngTemplateContextGuard(dir: TranslateTemplateContentDirective, ctx: any): ctx is TranslateTemplateContext {
        return true;
    }
}

<app-homepage-scaffold>
    <ng-template appHomepageTitle>{{ "discussions.solution.discussAndResolve" | translate}}</ng-template>
    <ng-template appHomepageActions>
        <app-discussion-type-heading [type]="discussion.type"></app-discussion-type-heading>
    </ng-template>

    <div class="discussion-details">
        <h4>{{ discussion.heading || discussion.description }}</h4>
        <p *ngIf="discussion.heading && discussion.description" class="multiline-description discussion-description">
            {{ discussion.description }}
        </p>
    </div>
    
    <app-origin-header *ngIf="discussion.origin" [origin]="discussion.origin"></app-origin-header>

    <form [formGroup]="form" (submit)="save()" class="dialog-form">
        <app-solution-form [discussion]="discussion" [actions]="actions$ | async" 
            formControlName="solution" [expandAll]="!fromMeeting"></app-solution-form>
        <div class="button-row" *ngIf="fromMeeting">
            <app-status-button type="submit" [state]="buttonState" [visualDisable]="!form.valid">
                {{ "Save" | translate }}
            </app-status-button>
        </div>
    </form>
</app-homepage-scaffold>
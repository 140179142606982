import "moment-timezone";

import { MatDialog } from "@angular/material/dialog";
import * as moment from "moment";

import { IQuarter } from "~repositories";

import { DialogConfirmComponent, DialogConfirmData }
    from "../material-component/dialogs/dialog-confirm/dialog-confirm.component";

export class CommonFunctions {

    static isNumberInRange(evt: any, min: number, max: number) {
        const charCode = evt.keyCode;
        if (charCode !== 46 && (charCode > 31 && (charCode < 48 || charCode > 57))) {
            return false;
        } else {
            const value = parseInt(evt.currentTarget.value + evt.key, 10);
            if (value < min || value > max) {
                return false;
            } else {
                return true;
            }
        }
    }

    static closeAndSuccess(isSuccess: boolean): any {
        if (isSuccess) {
            return {
                buttonText: "Success!",
                buttonIconImg: "assets/images/success_btn.png",
                isClicked: true,
            };
        } else {
            return {
                buttonText: "Error!",
                buttonIconImg: "assets/images/error_btn.png",
                isClicked: false,
            };
        }
    }

    static warningAndClose() {
        return {
            buttonText: "Warning!",
            buttonIconImg: "assets/images/warning.jpg",
            isClicked: false,
        };
    }

    static disableAndLoader(buttonText: string) {
        return {
            buttonText: buttonText,
            buttonIconImg: "assets/images/wait.gif",
            isClicked: true,
        };
    }

    static showConfirmDialog(dialog: MatDialog, data: DialogConfirmData) {
        return DialogConfirmComponent.open(dialog, data);
    }

    static copyToClipboard(text: string) {

        const input = document.createElement("textarea");
        input.innerHTML = text;
        document.body.appendChild(input);

        input.select();

        const copied = document.execCommand("copy");
        if (!copied) {
            throw new Error("Could not copy text to the clipboard");
        }

        document.body.removeChild(input);
    }

    static showLoader() {
        const loader = document.getElementById("loading-spinner");
        if (loader) loader.style.display = "block";
    }

    static hideLoader() {
        const loader = document.getElementById("loading-spinner");
        if (loader) loader.style.display = "none";
    }

    static setLoader(show: boolean) {
        if (show) {
            this.showLoader();
        } else {
            this.hideLoader();
        }
    }
}

export const getFinancialYearStartDate = () => {
    const startDate = moment.utc().startOf("year");
    startDate.month(6); // month is zero-indexed - this sets to July
    if (moment().isBefore(startDate)) {
        startDate.year(startDate.year() - 1);
    }
    return startDate;
};

export const toFiscalQuarter = (quarter: IQuarter): string =>
    `${quarter.financialYear}q${quarter.quarter}`;


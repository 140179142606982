import { Injectable } from "@angular/core";
import { SimpleUserDto, UserApi } from "@api";
import { map, Observable, shareReplay } from "rxjs";

import { getCompanyCacheItem, getTeamCacheItem, ICompanyCache, ICompanyTeamCache, retryWithDelay, setCompanyCacheItem, setTeamCacheItem } from "~shared/util/caching";
import { sortUser } from "~shared/util/sorters";

@Injectable({
    providedIn: "root"
})
export class UserRepository {
    private teamMemberCache: ICompanyTeamCache<SimpleUserDto[]> = {};
    private clientUserCache: ICompanyCache<SimpleUserDto[]> = {};

    constructor(
        private readonly userApi: UserApi) { }

    getTeamMembers = (companyId: string, teamId: string, force = false): Observable<SimpleUserDto[]> => {
        let cacheItem = force ? null : getTeamCacheItem(this.teamMemberCache, companyId, teamId);
        if (!cacheItem) {
            cacheItem = this.getTeamMembersObservableForCompanyTeam(companyId, teamId);
            setTeamCacheItem(this.teamMemberCache, companyId, teamId, cacheItem);
        }
        return cacheItem;
    };

    getClientUsers = (clientId: string, force = false): Observable<SimpleUserDto[]> => {
        let cacheItem = force ? null : getCompanyCacheItem(this.clientUserCache, clientId);
        if (!cacheItem) {
            cacheItem = this.getClientUsersObservable(clientId);
            setCompanyCacheItem(this.clientUserCache, clientId, cacheItem);
        }
        return cacheItem;
    };

    private getTeamMembersObservableForCompanyTeam = (companyId: string, teamId: string) =>
        this.userApi.listUsers(companyId, teamId).pipe(
            retryWithDelay(),
            map(users => users.sort(sortUser.ascending())),
            shareReplay({ bufferSize: 1, refCount: false }),
        );

    private getClientUsersObservable = (clientId: string) =>
        this.userApi.listClientUsers(clientId).pipe(
            retryWithDelay(),
            map(users => users.sort(sortUser.ascending())),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}

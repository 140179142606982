import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { TranslateService } from "@ngx-translate/core";

export declare type ButtonType = "button" | "submit";
export declare type ButtonState = "loading" | "success" | "error" | "warning" | undefined;

@Component({
    selector: "app-status-button",
    templateUrl: "./status-button.component.html",
    styleUrls: ["./status-button.component.css"]
})
export class StatusButtonComponent {

    @Input() state: ButtonState;
    @Input() disabled = false;

    @Input() visualDisable = false;

    @Input() type: ButtonType = "button";

    @Input() color: ThemePalette = "primary";

    @Output() buttonClick = new EventEmitter<void>();

    get buttonText(): string | null {
        if (this.state === "success") return this.translateService.instant("statusButton.success");
        if (this.state === "error") return this.translateService.instant("statusButton.error");
        if (this.state === "warning") return this.translateService.instant("statusButton.warning");
        return null;
    }

    get imageSrc(): string | null {
        if (this.state === "success") return "assets/images/success_btn.png";
        if (this.state === "error") return "assets/images/error_btn.png";
        if (this.state === "warning") return "assets/images/warning.jpg";
        if (this.state === "loading") return "assets/images/wait.gif";
        return null;
    }

    get imageAlt(): string {
        if (this.state === "loading") return this.translateService.instant("statusButton.loading");
        return "";
    }

    constructor(private translateService: TranslateService) { }

    onClick = (): void => {
        // We cannot click the button if in any state.
        if (this.state) return;
        this.buttonClick.emit();
    };
}

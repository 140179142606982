import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";

export type DialogConfirmResult = "confirm" | "cancel";

export interface DialogConfirmButton {
    readonly title: string;
}

export interface DialogConfirmData {
    readonly title: string;
    readonly description: string;
    readonly confirm: DialogConfirmButton;

    /**
     * If not specified then no button is displayed.
     * However a 'cancel' result can still occur if the user closes the dialog.
     */
    readonly cancel?: DialogConfirmButton;
}

@Component({
    selector: "app-dialog-confirm",
    templateUrl: "./dialog-confirm.component.html",
    styleUrls: ["./dialog-confirm.component.css"]
})
export class DialogConfirmComponent {
    constructor(
        private dialogRef: MatDialogRef<DialogConfirmComponent, DialogConfirmResult>,
        @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData) {
    }

    static open(dialog: MatDialog, data: DialogConfirmData) {
        return dialog.open<DialogConfirmComponent, DialogConfirmData, DialogConfirmResult>(DialogConfirmComponent, {
            data: { ...data },
            width: "370px",
        });
    }

    onConfirm() {
        this.dialogRef.close("confirm");
    }

    onCancel() {
        this.dialogRef.close("cancel");
    }
}

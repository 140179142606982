import { BreakpointObserver } from "@angular/cdk/layout";
import { Injectable, OnDestroy } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRouteSnapshot, ActivationEnd, Router } from "@angular/router";
import { CurrentCompanyAndTeamDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, filter, identity, map, Observable, of, Subscription, switchMap } from "rxjs";

import { CustomRouteData } from "~shared/custom-route";

import { TeamContext } from "./contexts";

interface TitleState {
    route: ActivatedRouteSnapshot | null;
    manualTitle: string | null;
    overrideRouteTitle: boolean;
}

@Injectable({
    providedIn: "root",
})
export class TitleService implements OnDestroy {

    private readonly titleStateSubject = new BehaviorSubject<TitleState>({ route: null, manualTitle: null, overrideRouteTitle: false });

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly titleService: Title,
        private readonly teamContext: TeamContext,
        private readonly router: Router,
        private readonly translateService: TranslateService,
        private readonly breakpointObserver: BreakpointObserver,
    ) {

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    initialise = () => {
        this.subscriptions.add(this.router.events.pipe(
            filter((event): event is ActivationEnd => event instanceof ActivationEnd),
            map(event => ({ route: event.snapshot, manualTitle: null, overrideRouteTitle: false } as TitleState)),
        ).subscribe(this.titleStateSubject));

        const appTitle$ = this.translateService.stream("Workfacta");
        const isStandalone$ = this.breakpointObserver.observe("(display-mode: standalone), (display-mode: minimal-ui)").pipe(
            map(state => state.matches),
        );

        const pageTitle$ = combineLatest({
            state: this.titleStateSubject,
            ct: this.teamContext.companyTeam$,
        }).pipe(
            map(({ state, ct }) => ({ ...state, ct })),
            switchMap(({ route, manualTitle, overrideRouteTitle, ct }) => {
                if (overrideRouteTitle && manualTitle) return of(manualTitle);
                return this.getRouteTitle(route, ct).pipe(
                    manualTitle ? map(routeTitle => `${manualTitle} - ${routeTitle}`) : identity
                );
            }),
        );

        this.subscriptions.add(
            combineLatest({
                appTitle: appTitle$,
                pageTitle: pageTitle$,
                isStandalone: isStandalone$,
            }).pipe(
                map(({ appTitle, pageTitle, isStandalone }) =>
                    // In the case we are displaying the app in a standalone window, the app title is prepended to the page title.
                    // Thus, we should include only the page title to prevent double-ups.
                    pageTitle ? (isStandalone ? pageTitle : `${pageTitle} - ${appTitle}`) : appTitle),
            ).subscribe(title => this.titleService.setTitle(title))
        );
    };

    setTitle = (title: string | null, overrideRouteTitle = false) => {
        const currentState = this.titleStateSubject.value;
        this.titleStateSubject.next({
            ...currentState,
            manualTitle: title,
            overrideRouteTitle: !!title && overrideRouteTitle,
        });
    };

    private getRouteTitle = (route: ActivatedRouteSnapshot | null, ct: CurrentCompanyAndTeamDto | null):
        Observable<string | undefined> => {
        if (!route) return of(undefined);
        const childRouteName$ = route?.firstChild ? this.getRouteTitle(route.firstChild, ct) : of(undefined);
        const data = route.data as CustomRouteData;
        const routeTitleKey = (ct?.company.settings.useAucbgMenus ? data.aucbgTitleKey : undefined) ?? data.titleKey;
        const routeTitle$: Observable<string | undefined> = routeTitleKey ? this.translateService.stream(routeTitleKey, {
            company: ct?.company.name ?? "",
            team: ct?.team.name ?? "",
        }) : of(undefined);
        return combineLatest({
            childRouteName: childRouteName$,
            routeTitle: routeTitle$
        }).pipe(
            map(({ childRouteName, routeTitle }) => {
                if (routeTitle && childRouteName) return `${routeTitle} > ${childRouteName}`;
                return routeTitle ?? childRouteName;
            })
        );
    };
}

<ng-container *ngIf="origin">
    <button type="button" mat-button class="origin-button" *ngIf="canView && (hasAccess$ | async); else readonlyDisplay" (click)="openOrigin()">
        <ng-container *ngTemplateOutlet="originDisplay"></ng-container>
    </button>

    <ng-template #readonlyDisplay>
        <div class="origin-container">
            <ng-container *ngTemplateOutlet="originDisplay"></ng-container>
        </div>
    </ng-template>
    
    <ng-template #originDisplay>
        <span class="origin-type">{{ 'originFor' | translate: { entityType: ( originNameKey | translate ) } }} - </span>
        <span class="origin-heading">{{ heading }}</span>
        <span class="origin-heading-more" *ngIf="hasMore">...</span>
    </ng-template>
</ng-container>

import { Injectable } from "@angular/core";
import { AccountApi, Role } from "@api";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";

@Injectable({
    providedIn: "root"
})
export class RoleRepository {
    private cache: ICompanyCache<Role[]> = {};

    constructor(private readonly accountApi: AccountApi) { }

    getRoles = (companyId: string, force = false): Observable<Role[]> => {
        let cacheItem = force ? null : getCompanyCacheItem(this.cache, companyId);
        if (!cacheItem) {
            cacheItem = this.getRolesObservableForCompany(companyId);
            setCompanyCacheItem(this.cache, companyId, cacheItem);
        }
        return cacheItem;
    };

    private getRolesObservableForCompany = (companyId: string): Observable<Role[]> =>
        this.accountApi.getCompanyRoles(companyId).pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}

/* eslint-disable max-classes-per-file */
import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { GetFeedItemDto } from "@api";
import { TranslateService } from "@ngx-translate/core";

import { FeedPartitionContext, feedPartitionContextDescription, partitionKeysEqual } from "~feed/services";

@Directive()
export abstract class BaseAddFeedItemDirective {
    @Input() context: FeedPartitionContext | undefined;

    @Output() itemAdded = new EventEmitter<GetFeedItemDto>();
}

@Directive()
export abstract class BaseAddFeedItemWithInputDirective extends BaseAddFeedItemDirective {

    @Input() viewContext: FeedPartitionContext | undefined;

    get contextHint(): string {
        return feedPartitionContextDescription(this.translate, this.context, this.viewContext);
    }

    get displayContextHint(): boolean {
        return !!this.context && !!this.context.key.length && !partitionKeysEqual(this.context.key, this.viewContext?.key);
    }

    constructor(protected readonly translate: TranslateService) {
        super();
    }
}

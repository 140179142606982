export type ColumnVisibility = "hidden" | "visible" | "fixed";

export interface ColumnSpec<TKey extends string> {
    readonly key: TKey;
    readonly visibility?: ColumnVisibility;
}

export const columnSpec = <TKey extends string>(key: TKey, visibility?: ColumnVisibility): ColumnSpec<TKey> =>
    ({ key, visibility });

export type ColumnSpecInput<TKey extends string> = TKey | [TKey, ColumnVisibility] | ColumnSpec<TKey>;

export const coerceColumnSpec = <TKey extends string>(specOrKey: ColumnSpecInput<TKey>): ColumnSpec<TKey> =>
    typeof specOrKey === "string" ? { key: specOrKey } :
        Array.isArray(specOrKey) ? { key: specOrKey[0], visibility: specOrKey[1] } :
            specOrKey;

import {
    CompanyPerformanceDto, EnterprisePerformanceDto, PeriodPerformanceDto, ScoreItemDto, TeamAnalyticsDto, TeamPerformanceDto,
    TeamUserAnalyticsDto, TeamUserPerformanceDto, UserAnalyticsDto
} from "@api";

import { IWeek } from "~repositories";

export declare type PerformanceDto =
    TeamPerformanceDto | CompanyPerformanceDto | EnterprisePerformanceDto |
    TeamAnalyticsDto | TeamUserAnalyticsDto | UserAnalyticsDto | TeamUserPerformanceDto;

export declare type PeriodPerformance = Pick<PeriodPerformanceDto, "executionScore" | "scores">;

export const isUserAnalytics = (data: PerformanceDto): data is UserAnalyticsDto => "user" in data && !("team" in data);
export const isUserPerformance = (data: PerformanceDto): data is TeamUserPerformanceDto | TeamUserAnalyticsDto | UserAnalyticsDto =>
    "user" in data;
export const isTeamPerformance = (data: PerformanceDto): data is TeamPerformanceDto | TeamAnalyticsDto =>
    "team" in data && !("user" in data);
export const isCompanyPerformance = (data: PerformanceDto): data is CompanyPerformanceDto => "company" in data;
export const isEnterprisePerformance = (data: PerformanceDto): data is EnterprisePerformanceDto => "parentCompany" in data;

const findChildScoreInternal = (scores: ScoreItemDto[], level: string): ScoreItemDto | null => {
    // Check if the score we're looking for is a direct descendent of the current level
    const matchingScore = scores.find(s => s.level === level);
    if (matchingScore) return matchingScore;

    // If not, find a level that is an ascendant of the score we're looking for
    const nextAscendant: ScoreItemDto | undefined = scores.find(s => level.startsWith(s.level + "."));
    if (!nextAscendant || !nextAscendant.children) return null;

    return findChildScoreInternal(nextAscendant.children, level);
};

export const findScoreLevel = (period: PeriodPerformance, level: string): ScoreItemDto | null =>
    findChildScoreInternal(period.scores, level);

export const findChildScore = (score: ScoreItemDto, level: string): ScoreItemDto | null =>
    findChildScoreInternal([score], level);

const buildEmptyScore = (level: string, name: string, children?: ScoreItemDto[]): ScoreItemDto => (children?.length ? {
    type: "composite",
    level,
    name,
    weighting: 0,
    rawScore: 0,
    score: 0,
    children,
} : {
    type: "primary",
    level,
    name,
    weighting: 0,
    successes: 0,
    total: 1,
    rawScore: 0,
    score: 0,
});

const buildMockScoreHierarchy = (): ScoreItemDto[] => [
    buildEmptyScore("3.2", "Results", [
        buildEmptyScore("3.2.1", "Goals"),
        buildEmptyScore("3.2.2", "Numbers"),
        buildEmptyScore("3.2.3", "Actions"),
    ]),
    buildEmptyScore("3.3", "Team Discipline", [
        buildEmptyScore("3.3.1", "Updates on time"),
        buildEmptyScore("3.3.2", "Meeting Start on Time"),
        buildEmptyScore("3.3.3", "Meeting Finish on Time"),
        buildEmptyScore("3.3.4", "Meeting Attendance"),
    ]),
    buildEmptyScore("3.4", "Teamwork", [
        buildEmptyScore("3.4.1", "Problem Solving"),
        buildEmptyScore("3.4.2", "Meeting"),
    ]),
];

export const buildMockPeriodPerformance = (): PeriodPerformance => ({
    executionScore: 0,
    scores: buildMockScoreHierarchy(),
});

const comparePeriods = (o1: IWeek, o2: IWeek): number =>
    (o2.financialYear - o1.financialYear) || (o2.quarter - o1.quarter) || (o2.week - o1.week);

export const findMatchingPeriod = (periods: PeriodPerformanceDto[], week: IWeek): PeriodPerformanceDto | undefined => {
    if (!periods.length) return undefined;
    const matchingPeriod = periods.find(p =>
        p.financialYear === week.financialYear &&
        p.planningPeriod === week.quarter &&
        p.collectionPeriod === week.week);
    if (matchingPeriod) return matchingPeriod;

    const firstPeriod = periods[0];
    const firstWeek = { financialYear: firstPeriod.financialYear, quarter: firstPeriod.planningPeriod, week: firstPeriod.collectionPeriod };

    if (comparePeriods(week, firstWeek) > 0) {
        // The requested week is before the first period.
        // Use the first period data.
        return firstPeriod;
    }

    const lastPeriod = periods[periods.length - 1];
    const lastWeek = { financialYear: lastPeriod.financialYear, quarter: lastPeriod.planningPeriod, week: lastPeriod.collectionPeriod };

    if (comparePeriods(lastWeek, week) > 0) {
        // The requested week is after the last period.
        // Use the last period data.
        return lastPeriod;
    }

    return undefined;
};

export const getPerformanceForPeriod = (data: PerformanceDto | null, week: IWeek | null): PeriodPerformance =>
    (week && data && findMatchingPeriod(data.periods, week)) ?? buildMockPeriodPerformance();

export const getScoreLevelNameKey = (level: string): string | null => {
    switch (level) {
        case "3": return "performanceScores.execution";
        case "3.2": return "performanceScores.progress";
        case "3.2.1": return "performanceScores.goals";
        case "3.2.2": return "performanceScores.numbers";
        case "3.2.3": return "performanceScores.actions";
        case "3.3": return "performanceScores.teamDiscipline";
        case "3.3.1": return "performanceScores.updatesOnTime";
        case "3.3.2": return "performanceScores.meetingStartOnTime";
        case "3.3.3": return "performanceScores.meetingFinishOnTime";
        case "3.3.4": return "performanceScores.meetingAttendance";
        case "3.4": return "performanceScores.teamwork";
        case "3.4.1": return "performanceScores.problemSolving";
        case "3.4.2": return "performanceScores.meeting";
        default: return null;
    }
};

import { trigger } from "@angular/animations";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import {
    ActionsV2Api, AddActionDto, CategoryDetailDto, EntityReferenceDto, GetActionDetailsDto, GetActionDto, GetGoalDto,
    GetTeamUserDelegationDto, RecurrenceDto, SimpleCategoryDto, SimpleCompanyTeamDto, SimpleDepartmentDto, SimpleSubCategoryDto,
    SimpleUserDto, TeamReferenceDto, UpdateActionDto, UpdateTeamUserDelegationDto
} from "@api";
import * as moment from "moment";
import {
    BehaviorSubject, combineLatest, concat, defer, distinctUntilChanged, filter, first, map,
    Observable, of, startWith, Subscription, switchMap, tap, throwError, toArray
} from "rxjs";

import { CategoryRepository, CurrentGoalsRepository, DepartmentRepository, TeamRepository, TeamSettingsRepository } from "~repositories";
import { UserRepository } from "~repositories/user.repository";
import { TeamContext, UserContext } from "~services/contexts";
import { MeetingProgressService } from "~services/meeting-progress.service";
import { NotificationService } from "~services/notification.service";
import { ActionStateService } from "~services/state";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { EntityType, Priority, RecurrenceType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { defaultAnimationTiming, fadeExpandAnimationBuilder } from "~shared/util/animations";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isCrossTeamFeaturesEnabled, isCrossTeamFeaturesTeased, isDelegationEnabled, isRecurringActionsEnabled, isRecurringActionsTeased } from "~shared/util/feature-helper";
import { IOriginDetails } from "~shared/util/origin-builder";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortString } from "~shared/util/sorters";
import { getTeamSearchData } from "~shared/util/team-helper";
import { getPriorityNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";

interface IActionDialogData {
    action?: GetActionDto;
    companyId: string;
    teamId: string;
    origin?: IOriginDetails;

    // If we are from a solution, we emit the resultant DTO rather than performing the update.
    // This is so the actions can be created/updated when the solution itself changes.
    fromSolution: boolean;
    readonly: boolean;
    preventPrivate: boolean;
    actionInput?: IActionInputModel;
}

export interface IAddActionDialogData {
    companyId: string;
    teamId: string;
    origin?: IOriginDetails;
    fromSolution?: boolean;
    preventPrivate?: boolean;
    actionInput?: IActionInputModel;
}

export interface IEditActionDialogData {
    action: GetActionDto;
    // If we are copying, we populate the form with the existing action's details,
    // but otherwise behave like creating a new action.
    isCopy?: boolean;
    fromSolution?: boolean;
    readonly?: boolean;
    actionInput?: IActionInputModel;
}

interface UpdateActionData {
    companyId: string;
    teamId: string;
    actionId: string;
    action: UpdateActionDto;
}

interface AddActionData {
    companyId: string;
    teamId: string;
    actions: AddActionDto[];
}

export interface IUpdateActionForSolutionResult extends UpdateActionData {
    type: "updateActionForSolution";
}

export interface IAddActionForSolutionResult extends AddActionData {
    type: "addActionForSolution";
}

export interface IActionAddedResult {
    type: "added";
    actions: GetActionDetailsDto[];
}

export interface IActionUpdatedResult {
    type: "updated";
    action: GetActionDetailsDto;
}

declare type DelegationInput = Omit<UpdateTeamUserDelegationDto, "assigneeUserId"> & { assigneeUserId?: string };

export declare type IActionDialogResult =
    IUpdateActionForSolutionResult | IAddActionForSolutionResult | IActionAddedResult | IActionUpdatedResult;

export declare type IActionInputModel =
    Partial<Omit<UpdateActionDto & AddActionDto, "delegation"> & { delegation: DelegationInput }>;

interface CompanyTeamId {
    companyId: string;
    teamId: string;
}

enum ActionRecurringType {
    never = 0,
    custom = 1
}

const getTodayDate = () => moment().startOf("day").utc(true);

const getDefaultRecurrence = (): RecurrenceDto => ({
    referenceDate: getTodayDate().toISOString(),
    type: RecurrenceType.weekly,
    interval: 1,
});

const getMinDueDate = (action?: GetActionDto): moment.Moment => {
    const nowDate = getTodayDate();
    if (!action) return nowDate;
    return moment.min(moment(action.dueDateLocal), nowDate);
};

const convertToActionInput = (action: GetActionDto): IActionInputModel => ({
    creatorUserId: action.creator?.userId,
    dueDateLocal: action.dueDateLocal,
    transferToTeam: undefined,
    ownerUserId: action.owner?.userId,
    departmentId: action.department?.id,
    categoryId: action.category?.id,
    subCategoryId: action.category?.subCategory?.id,
    description: action.description,
    priority: action.priority,
    isPrivateAction: action.isPrivateAction,
    recurrence: action.recurrence,
    delegation: action.delegation && {
        companyId: action.delegation.company.id,
        teamId: action.delegation.team.id,
        assigneeUserId: action.delegation.assignee?.userId,
    },
});

const CURRENT_USER = "currentUser";

@Component({
    selector: "app-edit-action-dialog",
    templateUrl: "./edit-action-dialog.component.html",
    styleUrls: ["./edit-action-dialog.component.scss"],
    animations: [
        trigger("fadeExpand", fadeExpandAnimationBuilder(defaultAnimationTiming))
    ],
})
export class EditActionDialogComponent extends WithDestroy() implements OnInit, OnDestroy, AfterViewInit {

    buttonState: ButtonState;
    disableAnimations = true;

    readonly priorities = [
        Priority.low,
        Priority.medium,
        Priority.high
    ];

    readonly recurrenceTypes = [
        ActionRecurringType.never,
        ActionRecurringType.custom,
    ];

    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(1000)]);
    readonly creatorControl = this.fb.control<string | null>(CURRENT_USER, [Validators.required]);
    readonly ownerControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly ownersControl = this.fb.nonNullable.control<string[]>([], [Validators.required]);
    readonly companyControl = this.fb.nonNullable.control<string>("", [Validators.required]);
    readonly teamControl = this.fb.nonNullable.control<string>("", [Validators.required]);

    readonly dueDateControl = this.fb.nonNullable.control(getTodayDate(), [Validators.required]);
    readonly priorityControl = this.fb.nonNullable.control(Priority.medium, [Validators.required]);
    readonly departmentControl = this.fb.control<string | null>(null);
    readonly categoryControl = this.fb.control<string | null>(null);
    readonly subCategoryControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly goalControl = this.fb.control<string | null>(null);
    readonly isPrivateActionControl = this.fb.nonNullable.control(false);

    readonly isDelegatedControl = this.fb.nonNullable.control(false);
    readonly delegationCompanyControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly delegationTeamControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly delegationAssigneeControl = this.fb.control<string | null>(null, [Validators.required]);
    readonly delegationControl = this.fb.group({
        company: this.delegationCompanyControl,
        team: this.delegationTeamControl,
        assignee: this.delegationAssigneeControl,
    });

    readonly recurrenceTypeControl = this.fb.nonNullable.control(ActionRecurringType.never);
    readonly recurrenceControl = this.fb.nonNullable.control<RecurrenceDto | undefined>(getDefaultRecurrence());

    readonly form = new FormGroup({
        description: this.descriptionControl,
        creator: this.creatorControl,
        owner: this.ownerControl,
        owners: this.ownersControl,
        company: this.companyControl,
        team: this.teamControl,

        dueDate: this.dueDateControl,
        priority: this.priorityControl,
        department: this.departmentControl,
        category: this.categoryControl,
        subCategory: this.subCategoryControl,
        goal: this.goalControl,
        isPrivateAction: this.isPrivateActionControl,

        recurrenceType: this.recurrenceTypeControl,
        recurrence: this.recurrenceControl,

        isDelegated: this.isDelegatedControl,
        delegation: this.delegationControl,
    });

    get isNewAction(): boolean {
        return !this.action;
    }

    get isAucbg(): boolean {
        return this.teamContext.settings.useAucbgMenus();
    }

    get showDueDate(): boolean {
        // We show the due date either when updating an action or creating a non-recurring action.
        return !this.isNewAction || !this.isRecurring;
    }

    get originalCreator(): SimpleUserDto | undefined {
        return this.action?.creator;
    }

    get originalOwner(): SimpleUserDto | undefined {
        return this.action?.owner;
    }

    get originalDepartment(): SimpleDepartmentDto | undefined {
        return this.action?.department;
    }

    get originalCategory(): SimpleCategoryDto | undefined {
        return this.action?.category;
    }

    get originalSubCategory(): SimpleSubCategoryDto | undefined {
        return this.action?.category?.subCategory;
    }

    get originalDelegation(): GetTeamUserDelegationDto | undefined {
        return this.action?.delegation;
    }

    get canSetRecurrence(): boolean {
        // Either it's a new action, or there's a recurrence set for this action already
        return !this.action || !!this.action.recurrence;
    }

    get isRecurring(): boolean {
        return this.recurrenceTypeControl.value === ActionRecurringType.custom;
    }

    get canSetPrivateAction(): boolean {
        return !this.origin && !this.fromSolution && !this.preventPrivate;
    }

    readonly originalTeamName: string;

    readonly readonly: boolean;
    readonly origin?: IOriginDetails;
    readonly minDueDate: moment.Moment;

    readonly getUserName = getUserName;
    readonly getPriorityNameKey = getPriorityNameKey;
    readonly getTeamSearchData = getTeamSearchData;

    readonly recurringActionsVisible$: Observable<boolean>;
    readonly recurringActionsEnabled$: Observable<boolean>;

    readonly crossTeamTeased$: Observable<boolean>;
    readonly teamSelectionEnabled$: Observable<boolean>;
    readonly teamSelectionDisallowed$: Observable<boolean>;
    readonly delegationVisible$: Observable<boolean>;

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;
    readonly creators$: Observable<SimpleUserDto[]>;
    readonly owners$: Observable<SimpleUserDto[]>;
    readonly departments$: Observable<SimpleDepartmentDto[]>;
    readonly categories$: Observable<CategoryDetailDto[]>;
    readonly subCategories$: Observable<SimpleSubCategoryDto[]>;
    readonly goals$: Observable<GetGoalDto[]>;
    readonly delegationTeams$: Observable<SimpleCompanyTeamDto[]>;
    readonly delegationUsers$: Observable<SimpleUserDto[]>;

    private get creatorUserId(): string | undefined {
        const creator = this.creatorControl.value;
        if (!creator || creator === CURRENT_USER) return undefined;
        return creator;
    }

    private readonly action?: GetActionDto;
    private readonly inMeeting: boolean;
    private readonly fromSolution: boolean;
    private readonly preventPrivate: boolean;

    private readonly subscriptions = new Subscription();
    private readonly companyTeamIdSubject: BehaviorSubject<CompanyTeamId>;
    private readonly delegationCompanyTeamIdSubject = new BehaviorSubject<CompanyTeamId | null>(null);

    constructor(
        private readonly actionsApi: ActionsV2Api,
        private readonly actionStateService: ActionStateService,
        private readonly meetingProgressService: MeetingProgressService,
        private readonly teamRepository: TeamRepository,
        private readonly teamSettingsRepository: TeamSettingsRepository,
        private readonly departmentRepository: DepartmentRepository,
        private readonly categoryRepository: CategoryRepository,
        private readonly currentGoalsRepository: CurrentGoalsRepository,
        private readonly userRepository: UserRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        private readonly dialogRef: MatDialogRef<EditActionDialogComponent, IActionDialogResult>,
        @Inject(MAT_DIALOG_DATA) data: IActionDialogData,
    ) {
        super();

        this.companyControl.setValue(data.companyId);
        this.teamControl.setValue(data.teamId);
        this.companyTeamIdSubject = new BehaviorSubject({
            companyId: data.companyId,
            teamId: data.teamId,
        });

        this.action = data.action;
        this.inMeeting = this.meetingProgressService.isInProgress(data.companyId, data.teamId);
        this.fromSolution = data.fromSolution;
        this.preventPrivate = data.preventPrivate;
        this.readonly = data.readonly || !!data.action?.isDelegated;
        if (this.readonly) this.form.disable();

        this.origin = data.origin;
        this.minDueDate = getMinDueDate(this.action);
        this.updateControlState(data);
        this.originalTeamName = this.action?.team.name ?? this.teamContext.team()?.name ?? "";

        this.recurringActionsVisible$ = this.teamContext.companyTeam$.pipe(
            map(ct => isRecurringActionsEnabled(ct) || isRecurringActionsTeased(ct)));
        this.recurringActionsEnabled$ = this.teamContext.companyTeam$.pipe(map(isRecurringActionsEnabled));

        this.crossTeamTeased$ = this.teamContext.companyTeam$.pipe(map(isCrossTeamFeaturesTeased));
        const crossTeamEnabled$ = this.teamContext.companyTeam$.pipe(map(isCrossTeamFeaturesEnabled));

        this.teamSelectionEnabled$ = crossTeamEnabled$.pipe(
            map(s => s && (!data.action || (!!data.action && !data.action.recurrence))));
        this.teamSelectionDisallowed$ = crossTeamEnabled$.pipe(map(s => s && !!data.action?.recurrence));
        const delegationEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isDelegationEnabled),
        );

        this.teams$ = defer(() => this.teamRepository.getClientInstanceTeams(data.companyId));
        const users$ = this.companyTeamIdSubject.pipe(
            switchMap(({ companyId, teamId }) => this.userRepository.getTeamMembers(companyId, teamId)),
            shareReplayUntil(this.destroyed$),
        );
        this.creators$ = users$.pipe(
            map(this.addExtraCreators),
            tap(this.availableCreatorsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.owners$ = users$.pipe(
            tap(this.availableOwnersChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.delegationTeams$ = this.companyTeamIdSubject.pipe(
            switchMap(({ companyId, teamId }) => this.teamSettingsRepository.getDelegationTeams(companyId, teamId)),
            tap(this.availableDelegatedTeamsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.delegationUsers$ = this.delegationCompanyTeamIdSubject.pipe(
            switchMap(ids => !ids ? of([]) : this.userRepository.getTeamMembers(ids.companyId, ids.teamId)),
            tap(this.availableDelegatedUsersChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.delegationVisible$ = delegationEnabled$.pipe(
            switchMap(enabled => {
                // If the form is disabled, show the delegation section if enabled and set but do no further processing
                if (this.form.disabled) return of(enabled && !!this.action?.delegation);
                // Delegation is otherwise visible if enabled and there are some delegation teams set
                const visible$ = !enabled ? of(false) : this.delegationTeams$.pipe(map(teams => !!teams.length));
                return visible$.pipe(
                    tap(visible => {
                        if (!visible) {
                            this.isDelegatedControl.setValue(false);
                            this.isDelegatedControl.disable();
                            this.delegationControl.disable();
                        } else {
                            if (this.form.enabled) {
                                this.isDelegatedControl.enable();
                            }
                        }
                    }),
                    // While the delegation teams are loading, should be based on whether delegation settings currently exist.
                    startWith(!!this.action?.delegation),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        const companyId$ = this.companyTeamIdSubject.pipe(
            map(({ companyId }) => companyId),
            distinctUntilChanged(),
        );
        this.departments$ = companyId$.pipe(
            switchMap(companyId => this.departmentRepository.getDepartments(companyId)),
            tap(this.availableDepartmentsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.categories$ = companyId$.pipe(
            switchMap(companyId => this.categoryRepository.getCategories(companyId)),
            tap(this.availableCategoriesChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.subCategories$ = combineLatest({
            categories: this.categories$,
            categoryId: valueAndChanges(this.categoryControl),
        }).pipe(
            map(({ categories, categoryId }) => categories.find(c => c.id === categoryId)?.subCategories ?? []),
            tap(this.availableSubCategoriesChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.goals$ = this.companyTeamIdSubject.pipe(
            switchMap(({ companyId, teamId }) => this.currentGoalsRepository.getCurrentGoals(companyId, teamId)),
            map(goals => goals.filter(g => !g.isPrivate).sort(sortString.ascending(g => g.heading))),
            shareReplayUntil(this.destroyed$),
        );
    }

    static openForAdd(dialog: MatDialog, data: IAddActionDialogData) {
        return this.openInternal(dialog, {
            action: undefined,
            companyId: data.companyId,
            teamId: data.teamId,
            origin: data.origin,
            fromSolution: data.fromSolution ?? false,
            readonly: false, // Not relevant for adding an action.
            preventPrivate: data.preventPrivate ?? false,
            actionInput: data.actionInput,
        });
    }

    static openForEdit(dialog: MatDialog, data: IEditActionDialogData) {
        const action = data.action;
        const origin = action.origin;
        const { company, team } = data.isCopy ? getDelegatedItemCompanyTeam(action) : action;
        return this.openInternal(dialog, {
            action: data.isCopy ? undefined : action,
            companyId: company.id,
            teamId: team.id,
            origin: origin && {
                companyId: origin.company.id,
                teamId: origin.team.id,
                type: origin.type,
                id: origin.id,
                week: origin.week,
                heading: origin.heading,
                description: origin.description
            },

            fromSolution: data.fromSolution ?? false,
            readonly: data.readonly ?? false,
            preventPrivate: false, // Not relevant for editing an action.
            actionInput: {
                ...convertToActionInput(action),
                ...data.actionInput,
                creatorUserId: data.actionInput?.creatorUserId ?? data.action.creator?.userId,
                dueDateLocal: data.actionInput?.dueDateLocal ?? data.action.dueDateLocal,
            },
        });
    }

    private static openInternal(dialog: MatDialog, data: IActionDialogData) {
        return dialog.open<EditActionDialogComponent, IActionDialogData, IActionDialogResult>(EditActionDialogComponent, {
            width: "750px",
            data: data
        });
    }

    ngOnInit(): void {
        if (this.readonly) return;
        this.subscriptions.add(
            valueAndChanges(this.teamControl).pipe(
                switchMap(teamId => this.teams$.pipe(
                    first(),
                    map(teams => teams.find(t => t.id === teamId)),
                )),
                filter(Boolean),
                map(team => ({ companyId: team.company.id, teamId: team.id })),
                filter(({ companyId, teamId }) => {
                    const current = this.companyTeamIdSubject.value;
                    return current.companyId !== companyId || current.teamId !== teamId;
                }),
                tap(({ companyId }) => {
                    this.companyControl.setValue(companyId);
                }),
            ).subscribe(this.companyTeamIdSubject),
        );
        this.subscriptions.add(
            valueAndChanges(this.delegationTeamControl).pipe(
                switchMap(teamId => {
                    if (!teamId) return of(null);
                    return this.delegationTeams$.pipe(
                        first(),
                        map(teams => teams.find(t => t.id === teamId)),
                    );
                }),
                map(team => !team ? null : { companyId: team.company.id, teamId: team.id }),
                filter(ids => {
                    const current = this.delegationCompanyTeamIdSubject.value;
                    return current?.companyId !== ids?.companyId || current?.teamId !== ids?.teamId;
                }),
                tap(ids => {
                    this.delegationCompanyControl.setValue(ids?.companyId ?? null);
                    if ((ids?.teamId ?? null) !== this.delegationTeamControl.value) {
                        this.delegationTeamControl.setValue(ids?.teamId ?? null);
                    }
                }),
            ).subscribe(this.delegationCompanyTeamIdSubject)
        );
        this.subscriptions.add(combineLatest({
            recurrenceType: valueAndChanges(this.recurrenceTypeControl),
            isPrivateAction: valueAndChanges(this.isPrivateActionControl),
        }).subscribe(({ recurrenceType, isPrivateAction }) => {
            if (!this.form.enabled) return;
            if (recurrenceType === ActionRecurringType.custom) {
                this.recurrenceControl.enable();
                this.dueDateControl.disable();
            } else {
                this.recurrenceControl.disable();
                if (!this.action || this.inMeeting || isPrivateAction) {
                    this.dueDateControl.enable();
                } else {
                    this.dueDateControl.disable();
                    this.dueDateControl.setValue(moment(this.action.dueDateLocal).utc(true));
                }
            }
        }));
        this.subscriptions.add(valueAndChanges(this.isDelegatedControl).subscribe((isDelegated: boolean) => {
            if (!isDelegated) {
                this.delegationControl.disable();
                this.delegationCompanyControl.setValue(null);
                this.delegationTeamControl.setValue(null);
                this.delegationAssigneeControl.setValue(null);
            } else {
                if (this.form.enabled) {
                    this.delegationControl.enable();
                }
            }
        }));
        if (this.canSetPrivateAction) {
            const isOwnedByMe$ = this.isNewAction ?
                valueAndChanges(this.ownersControl).pipe(
                    map((owners: string[]) => owners.every(owner => owner === this.userContext.userId()))) :
                valueAndChanges(this.ownerControl).pipe(
                    map(owner => owner === this.userContext.userId()));
            const isCreatedByMe$ = valueAndChanges(this.creatorControl).pipe(
                map(creator => creator === this.userContext.userId() || creator === CURRENT_USER));
            this.subscriptions.add(combineLatest({
                isOwnedByMe: isOwnedByMe$,
                isCreatedByMe: isCreatedByMe$,
                goal: valueAndChanges(this.goalControl),
                isDelegated: valueAndChanges(this.isDelegatedControl),
            }).subscribe(({ isOwnedByMe, isCreatedByMe, goal, isDelegated }) => {
                if (!this.form.enabled) return;
                if ((isOwnedByMe || isCreatedByMe) && !goal && !isDelegated) {
                    this.isPrivateActionControl.enable();
                } else {
                    this.isPrivateActionControl.disable();
                    this.isPrivateActionControl.setValue(false);
                }
            }));
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    save = () => {
        if (this.buttonState || this.readonly || !this.form.valid) return;

        if (this.fromSolution) {
            if (!this.action) {
                this.dialogRef.close({
                    type: "addActionForSolution",
                    ...this.getAddActionData(),
                });
            } else {
                this.dialogRef.close({
                    type: "updateActionForSolution",
                    ...this.getUpdateActionData(this.action),
                });
            }
            return;
        }

        this.buttonState = "loading";
        const result$: Observable<IActionDialogResult> = !this.action ?
            this.addActions().pipe(map(actions => ({ type: "added", actions } as IActionAddedResult))) :
            this.updateAction(this.action).pipe(map(action => ({ type: "updated", action } as IActionUpdatedResult)));
        result$.subscribe({
            next: result => {
                this.buttonState = "success";
                setTimeout(() => this.dialogRef.close(result), 1000);
            },
            error: () => {
                this.notificationService.errorUnexpected();
                this.buttonState = "error";
                setTimeout(() => {
                    this.dialogRef.close();
                }, 2000);
            },
        });
    };

    getUserId = (user: SimpleUserDto) => user.userId;

    getTeamId = (team: SimpleCompanyTeamDto) => team.id;
    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined) => team?.name ?? "";

    getDepartmentId = (department: SimpleDepartmentDto) => department.id;
    getDepartmentName = (department: SimpleDepartmentDto | null | undefined) => department?.name ?? "";
    getCategoryId = (category: CategoryDetailDto): string => category?.id ?? "";
    getCategoryDisplay = (category: CategoryDetailDto | null | undefined) => category?.description ?? "";
    getSubCategoryId = (subCategory: SimpleSubCategoryDto): string => subCategory?.id ?? "";
    getSubCategoryDisplay = (subCategory: SimpleSubCategoryDto | null | undefined): string => subCategory?.description ?? "";

    getRecurrenceTypeNameKey = (type: ActionRecurringType): string => {
        switch (type) {
            case ActionRecurringType.never: return "actionRecurringType.never";
            case ActionRecurringType.custom: return "actionRecurringType.custom";
        }
        return "";
    };

    private addActions = (): Observable<GetActionDetailsDto[]> => {
        const data = this.getAddActionData();
        if (!data.actions.length) return throwError(() => new Error("No actions to add"));
        return concat(...data.actions.map(action =>
            this.actionsApi.addAction(data.companyId, data.teamId, action).pipe(
                tap(result => this.actionStateService.notifyAdd(result)),
            ))
        ).pipe(
            toArray(),
        );
    };

    private updateAction = (action: GetActionDto): Observable<GetActionDetailsDto> => {
        const data = this.getUpdateActionData(action);
        return this.actionsApi.updateAction(data.companyId, data.teamId, data.actionId, data.action).pipe(
            tap(result => this.actionStateService.notifyUpdate(result)),
        );
    };

    private getAddActionData = (): AddActionData => ({
        companyId: this.companyControl.value,
        teamId: this.teamControl.value,
        actions: this.getAddActionDtos(),
    });

    private getUpdateActionData = (action: GetActionDto): UpdateActionData => ({
        companyId: action.company.id,
        teamId: action.team.id,
        actionId: action.id,
        action: this.getUpdateActionDto(action),
    });

    private getAddActionDtos = (): AddActionDto[] => {
        const owners = this.ownersControl.value;
        let origin: EntityReferenceDto | undefined;
        if (this.origin) {
            origin = {
                companyId: this.origin.companyId,
                teamId: this.origin.teamId,
                type: this.origin.type,
                id: this.origin.id,
                week: this.origin.week,
            };
        } else if (this.goalControl.value) {
            origin = {
                companyId: this.companyControl.value,
                teamId: this.teamControl.value,
                type: EntityType.goal,
                id: this.goalControl.value,
            };
        } else {
            origin = undefined;
        }
        return owners.map(owner => ({
            creatorUserId: this.creatorUserId,
            origin: origin,
            dueDateLocal: this.dueDateControl.value.toISOString(),
            ownerUserId: owner,
            departmentId: this.departmentControl.value ?? undefined,
            categoryId: this.categoryControl.value ?? undefined,
            subCategoryId: this.subCategoryControl.value ?? undefined,
            description: this.descriptionControl.value ?? "",
            priority: this.priorityControl.value,
            isPrivateAction: !origin && this.isPrivateActionControl.value,
            recurrence: this.getRecurrence(),
            delegation: this.getDelegation(),
        }));
    };

    private getUpdateActionDto = (action: GetActionDto): UpdateActionDto => {
        const creatorUserId = this.creatorUserId;
        const teamId: string = this.teamControl.value;
        const companyId: string = this.companyControl.value;
        let transferToTeam: TeamReferenceDto | undefined;
        if (teamId !== action.team.id || companyId !== action.company.id) {
            transferToTeam = {
                companyId: companyId,
                teamId: teamId,
            };
        }
        return {
            creatorUserId: creatorUserId === action.creator?.userId ? undefined : creatorUserId,
            dueDateLocal: this.dueDateControl.enabled ? this.dueDateControl.value.toISOString() : undefined,
            transferToTeam,
            ownerUserId: this.ownerControl.value ?? "",
            departmentId: this.departmentControl.value ?? undefined,
            categoryId: this.categoryControl.value ?? undefined,
            subCategoryId: this.subCategoryControl.value ?? undefined,
            description: this.descriptionControl.value ?? "",
            priority: this.priorityControl.value,
            isPrivateAction: !action.origin && this.isPrivateActionControl.value,
            recurrence: this.getRecurrence(),
            delegation: this.getDelegation(),
        };
    };

    private getRecurrence = (): RecurrenceDto | undefined =>
        this.recurrenceTypeControl.value === ActionRecurringType.custom ? this.recurrenceControl.value : undefined;

    private getDelegation = (): UpdateTeamUserDelegationDto | undefined => {
        if (!this.isDelegatedControl.value) return undefined;
        return {
            companyId: this.delegationCompanyControl.value ?? "",
            teamId: this.delegationTeamControl.value ?? "",
            assigneeUserId: this.delegationAssigneeControl.value ?? "",
        };
    };

    private updateControlState = (data: IActionDialogData) => {
        this.dueDateControl.addValidators([Validators.min(this.minDueDate.valueOf())]);

        if (data.actionInput) {
            this.bindAction(data.actionInput);
        }

        if (data.origin || data.fromSolution) {
            // Items with an origin cannot be set as private.
            this.isPrivateActionControl.disable();
            // As they already have an origin, we can't set it to a goal.
            this.goalControl.disable();
        } else if (data.preventPrivate) {
            this.isPrivateActionControl.disable();
        }

        if (data.action) {
            // We are updating an action.
            const action = data.action;
            // The origin cannot be updated once set.
            this.goalControl.disable();
            // We can only set one owner for an updated action.
            this.ownersControl.disable();
            const creatorId = action.creator?.userId;
            if (creatorId && this.userContext.userId() !== creatorId) {
                // The creator can only be changed by the original creator.
                this.creatorControl.disable();
            }
            if (!action.recurrence) {
                // A recurrence cannot be added to an existing non-recurring action.
                this.recurrenceTypeControl.disable();
                this.recurrenceControl.disable();
            }
            if (!this.inMeeting) this.dueDateControl.disable();
        } else {
            // We are creating an action.
            // We don't set a singular owner, but rather multiple owners.
            this.ownerControl.disable();
        }
    };

    private bindAction = (a: IActionInputModel) => {
        this.descriptionControl.setValue(a.description ?? null);
        this.creatorControl.setValue(a.creatorUserId ?? null);
        this.ownerControl.setValue(a.ownerUserId ?? null);
        this.ownersControl.setValue(a.ownerUserId ? [a.ownerUserId] : []);
        if (a.dueDateLocal) this.dueDateControl.setValue(moment(a.dueDateLocal).utc(true));
        this.priorityControl.setValue(a.priority ?? Priority.medium);
        this.departmentControl.setValue(a.departmentId ?? null);
        this.categoryControl.setValue(a.categoryId ?? null);
        this.subCategoryControl.setValue(a.subCategoryId ?? null);
        this.goalControl.setValue(a.origin?.type === EntityType.goal ? a.origin.id : null);
        this.isPrivateActionControl.setValue(a.isPrivateAction ?? false);
        this.recurrenceTypeControl.setValue(a.recurrence ? ActionRecurringType.custom : ActionRecurringType.never);
        this.recurrenceControl.setValue(a.recurrence);
        if (a.transferToTeam) {
            this.companyControl.setValue(a.transferToTeam.companyId);
            this.teamControl.setValue(a.transferToTeam.teamId);
            this.companyTeamIdSubject.next(a.transferToTeam);
        }
        if (a.delegation) {
            const { companyId, teamId, assigneeUserId } = a.delegation;
            this.isDelegatedControl.setValue(true);
            this.delegationCompanyControl.setValue(companyId);
            this.delegationTeamControl.setValue(teamId);
            this.delegationAssigneeControl.setValue(assigneeUserId ?? null);
            this.delegationCompanyTeamIdSubject.next({ companyId, teamId });
        }
    };

    /**
     * Modifies the list of users to possibly include an extra user. Depending on whether this
     * is a new or updated action, the list of users will either include the logged-in user or the
     * current user.
     *
     * @param users The list of users in the selected team.
     * @returns A potentially modified list of users, including up to one extra user.
     */
    private addExtraCreators = (users: SimpleUserDto[]): SimpleUserDto[] => {
        if (this.isNewAction) {
            // For a new action, we want to ensure the current logged in user is an available option.
            return this.addCurrentUser(users);
        } else {
            // For updated actions, we want to ensure the current creator is an available option
            return this.addExistingUser(users);
        }
    };

    /**
     * Modifies the list of users to include the logged-in user. This is intended
     * to be used for new actions, as the logged-in user is a possible creator, even if not in
     * the current team.
     *
     * @param users The list of users in the selected team.
     * @returns A potentially modified list of users including the logged-in user.
     */
    private addCurrentUser = (users: SimpleUserDto[]): SimpleUserDto[] => {
        const user = this.userContext.user();
        if (!user) return users;
        const currentUser: SimpleUserDto = {
            userId: CURRENT_USER,
            firstName: user.firstName,
            lastName: user.lastName,
        };
        users = [...users];
        const index = users.findIndex(u => u.userId === user.id);
        if (index >= 0) {
            // Replace the user with the dummy record.
            users.splice(index, 1, currentUser);
        } else {
            // Add the current user to the start of the list.
            users.unshift(currentUser);
        }
        return users;
    };

    /**
     * Modifies the list of users to include the existing creator. This is intended to be used
     * for updated actions, as the option to leave the creator as-is should always be present.
     *
     * @param users The list of users in the selected team.
     * @returns A potentially modified list of users existing creator.
     */
    private addExistingUser = (users: SimpleUserDto[]): SimpleUserDto[] => {
        if (!this.action || !this.action.creator) return users;
        const creator = { ...this.action.creator };
        // In the case the user is already in the team, no need to add them
        if (users.some(u => u.userId === creator.userId)) return users;
        users = [...users];
        // Add the existing creator to the start of the list.
        users.unshift(creator);
        return users;
    };

    private availableCreatorsChanged = (users: SimpleUserDto[]) => {
        if (!users.some(x => x.userId === this.creatorControl.value)) {
            this.creatorControl.setValue(null);
        }
        if (this.isNewAction && !this.creatorControl.value) {
            this.creatorControl.setValue(CURRENT_USER);
        }
    };

    private availableOwnersChanged = (users: SimpleUserDto[]) => {
        if (!users.some(x => x.userId === this.ownerControl.value)) {
            this.ownerControl.setValue(null);
        }
        const owners: string[] = this.ownersControl.value;
        if (owners.some(owner => !users.some(x => x.userId === owner))) {
            this.ownersControl.setValue(owners.filter(owner => users.some(x => x.userId === owner)));
        }
        if (this.isNewAction) {
            const currentUserId = this.userContext.userId();
            if (currentUserId && users.some(x => x.userId === currentUserId)) {
                if (!this.ownerControl.value) this.ownerControl.setValue(currentUserId);
                if (!this.ownersControl.value.length) this.ownersControl.setValue([currentUserId]);
            }
        }
    };

    private availableDepartmentsChanged = (departments: SimpleDepartmentDto[]) => {
        if (this.departmentControl.value && !departments.some(d => d.id === this.departmentControl.value)) {
            this.departmentControl.setValue(null);
        }
    };

    private availableCategoriesChanged = (categories: CategoryDetailDto[]) => {
        if (!categories.length) {
            // Ensures even if the list of sub-categories is not subscribed to (due to no categories)
            // the control will be cleared and disabled appropriately.
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
        }
        if (this.categoryControl.value && !categories.some(x => x.id === this.categoryControl.value)) {
            this.categoryControl.setValue(null);
        }
    };

    private availableSubCategoriesChanged = (subCategories: SimpleSubCategoryDto[]) => {
        if (!subCategories.length) {
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
            return;
        }

        if (this.form.enabled) this.subCategoryControl.enable();
        if (subCategories.length === 1) {
            this.subCategoryControl.setValue(subCategories[0].id ?? null);
        } else if (this.subCategoryControl.value && !subCategories.some(x => x.id === this.subCategoryControl.value)) {
            this.subCategoryControl.setValue(null);
        }
    };

    private availableDelegatedTeamsChanged = (teams: SimpleCompanyTeamDto[]) => {
        if (this.delegationTeamControl.value && !teams.some(t => t.id === this.delegationTeamControl.value)) {
            this.delegationCompanyControl.setValue(null);
            this.delegationTeamControl.setValue(null);
        }
    };

    private availableDelegatedUsersChanged = (users: SimpleUserDto[]) => {
        if (this.delegationAssigneeControl.value && !users.some(x => x.userId === this.delegationAssigneeControl.value)) {
            this.delegationAssigneeControl.setValue(null);
        }
    };
}

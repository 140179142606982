import { ChangeDetectionStrategy, Component, HostBinding, Input, ViewEncapsulation } from "@angular/core";

@Component({
    selector: "wf-child-count",
    templateUrl: "./child-count.component.html",
    styleUrls: ["./child-count.component.scss"],
    host: {
        class: "wf-child-count"
    },
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChildCountComponent {

    @Input() count = 0;

    @HostBinding("class.wf-child-count-has-children") get hasChildren() {
        return !!this.count;
    }

}

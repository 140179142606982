/**
 * SAASCLOUDAPP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum SubscriptionStatus {
    active = 0,
    activeTrial = 1,
    pastDue = 10,
    unpaid = 11,
    incomplete = 12,
    cancelled = 20,
    incompleteExpired = 21
}


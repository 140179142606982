import { Provider } from "@angular/core";

import { FeatureContext } from "./feature.context";
import { SimpleTeamContext } from "./simple-team.context";
import { TeamContext } from "./team.context";
import { SimpleUserContext, UserContext } from "./user.context";

export { CompanySettingsContext } from "./settings.context";
export { FeatureContext } from "./feature.context";
export { TeamContext } from "./team.context";
export { UserContext } from "./user.context";

export const provideSimpleContexts: () => Provider[] = () => [
    { provide: TeamContext, useClass: SimpleTeamContext },
    { provide: UserContext, useClass: SimpleUserContext },
    {
        provide: FeatureContext,
        useFactory: (teamContext: TeamContext) => teamContext.features,
        deps: [TeamContext],
    },
];

<button type="button" class="wf-expandable-section-header" (click)="toggle()"
    [attr.aria-expanded]="expanded" [attr.aria-disabled]="disabled"
    [id]="headerId" [attr.aria-controls]="bodyId">
    <h4 class="wf-section-heading" *ngIf="!headerTemplate">{{ label }}</h4>
    <div class="wf-expandable-section-header-inner" *ngIf="headerTemplate">
        <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
    </div>
    <div class="wf-expandable-section-actions" *ngIf="actionsTemplate">
        <ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
    </div>
    <mat-icon class="wf-expand-icon" *ngIf="!disabled">expand_more</mat-icon>
</button>

<div class="wf-expandable-section-body" *ngIf="expanded" @expanded [@.disabled]="disableAnimations"
    role="region" [id]="bodyId" [attr.aria-labelledby]="headerId">
    <ng-content *ngIf="!bodyTemplate"></ng-content>
    <ng-container *ngTemplateOutlet="bodyTemplate ?? null"></ng-container>
</div>
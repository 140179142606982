import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { GetCommentFeedItemDto } from "@api";

import { FeedScope } from "~feed/services";
import { UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";

import { DeletableFeedListItemDirective } from "../deletable-feed-list-item.directive";

@Component({
    selector: "app-feed-list-comment",
    templateUrl: "./feed-list-comment.component.html",
    styleUrls: ["./feed-list-comment.component.scss"]
})
export class FeedListCommentComponent extends DeletableFeedListItemDirective<GetCommentFeedItemDto> {

    editModeEnabled = false;
    readonly editCommentControl = new FormControl<string | null>(null, [Validators.required]);
    readonly editCommentForm = new FormGroup({
        editCommentControl: this.editCommentControl
    });

    get canUserEditComment(): boolean {
        return !!this.feedItem && !!this.feedItem.user && this.feedItem.user.userId === this.userContext.userId();
    }

    get showEditForm(): boolean {
        return this.editModeEnabled && this.canUserEditComment;
    }

    constructor(
        feedScope: FeedScope,
        userContext: UserContext,
        notificationService: NotificationService,
        dialog: MatDialog,
    ) {
        super(feedScope, userContext, notificationService, dialog);
    }

    toggleEditMode() {
        if (!this.feedItem || !this.canUserEditComment) return;
        this.editCommentControl.setValue(this.feedItem.text);
        this.editModeEnabled = !this.editModeEnabled;
    }

    editComment() {
        const adapter = this.feedScope.adapter;
        if (!this.editCommentForm.valid) return;
        if (!this.feedItem || !this.canUserEditComment || !this.editCommentControl.value || !adapter) {
            this.editModeEnabled = false;
            return;
        }
        this.editCommentForm.disable();

        adapter.updateFeedItem(
            this.feedItem.feedItemId,
            {
                type: "updateCommentFeedItem",
                text: this.editCommentControl.value
            },
        ).subscribe({
            next: (updatedItem) => {
                // manually patch the feed item until it is replaced
                this.feedItem = updatedItem as GetCommentFeedItemDto;
                this.editModeEnabled = false;

                this.editCommentForm.enable();
                this.itemChanged.emit();
            },
            error: () => {
                this.editCommentForm.enable();
                this.notificationService.errorUnexpected();
            }
        });
    }
}

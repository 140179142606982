import { Injectable, OnDestroy } from "@angular/core";
import { FeedPartitionReferenceDto, FeedPartitionsApi, GetFeedItemDto } from "@api";
import * as moment from "moment";
import {
    BehaviorSubject, bufferTime, concatMap, distinctUntilChanged,
    EMPTY, filter, Observable, of, Subject, Subscription, switchMap, tap
} from "rxjs";

import { retryWithDelay } from "~shared/util/caching";

export interface UserFeedViewedState {
    lastViewed: moment.Moment | null;
}

export interface FeedPartitionReference extends FeedPartitionReferenceDto {
    clientId: string;
}

const BUFFER_TIME_MS = 50;
const BUFFER_SIZE = 50;

const getReferenceKey = (ref: FeedPartitionReferenceDto): string => {
    if (!ref.key || !ref.key.length) return ref.feedId;
    return `${ref.feedId}_${ref.key.join("-")}`;
};

@Injectable({
    providedIn: "root",
})
export class FeedStateService implements OnDestroy {

    private readonly clientSubjects = new Map<string, Subject<FeedPartitionReference>>();
    private readonly statesSubject =
        new BehaviorSubject<Map<string, UserFeedViewedState>>(new Map<string, UserFeedViewedState>());

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly feedPartitionsApi: FeedPartitionsApi,
    ) {

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getUserState = (reference: FeedPartitionReference): Observable<UserFeedViewedState> => of(null).pipe(
        tap(() => this.getClientSubject(reference.clientId).next(reference)),
        switchMap(() =>
            this.statesSubject.pipe(
                switchMap(data => {
                    const key = getReferenceKey(reference);
                    if (data.has(key)) {
                        const value = data.get(key);
                        if (value) return of(value);
                    }
                    return EMPTY;
                }),
                distinctUntilChanged()
            )
        ),
    );

    markLastViewed = (item: GetFeedItemDto, reference: FeedPartitionReferenceDto) => {
        const states = new Map(this.statesSubject.value);
        const statesToUpdate: string[] = [];
        const rootKey = getReferenceKey(reference);
        if (states.has(rootKey)) {
            const rootState = states.get(rootKey);
            if (!rootState?.lastViewed || rootState.lastViewed.isBefore(item.createdDate)) {
                statesToUpdate.push(rootKey);
            }
        } else {
            statesToUpdate.push(rootKey);
        }

        // Also mark any child partitions as viewed.
        const childStates = [...states.entries()].filter(([k]) => k.startsWith(rootKey + "-"));
        for (const [childKey, childState] of childStates) {
            if (childState.lastViewed && childState.lastViewed.isSameOrAfter(item.createdDate)) {
                continue;
            }
            statesToUpdate.push(childKey);
        }

        if (!statesToUpdate.length) return;

        for (const stateKey of statesToUpdate) {
            states.set(stateKey, { lastViewed: moment(item.createdDate) });
        }

        this.statesSubject.next(states);
    };

    private getClientSubject = (clientId: string) =>
        this.clientSubjects.get(clientId) ?? this.createClientSubject(clientId);

    private createClientSubject = (clientId: string): Subject<FeedPartitionReference> => {
        const subject = new Subject<FeedPartitionReference>();
        this.clientSubjects.set(clientId, subject);

        this.subscriptions.add(
            subject.pipe(
                bufferTime(BUFFER_TIME_MS, undefined, BUFFER_SIZE),
                filter(refs => !!refs.length),
                concatMap(refs =>
                    this.feedPartitionsApi.getUserState(clientId, refs).pipe(
                        retryWithDelay()
                    )
                ),
            ).subscribe(states => {
                const currentState = new Map(this.statesSubject.value);
                let dataModified = false;
                for (const state of states) {
                    if (!state.partition) continue;
                    const key = getReferenceKey(state.partition);
                    currentState.set(key, {
                        lastViewed: !state.lastViewedItemCreated ? null : moment(state.lastViewedItemCreated),
                    });
                    dataModified = true;
                }
                if (dataModified) {
                    this.statesSubject.next(currentState);
                }
            })
        );

        return subject;
    };
}

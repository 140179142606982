<app-homepage-scaffold *ngIf="discussion">
    <span *appHomepageTitle class="multiline-description">{{ discussion.heading || discussion.description }}</span>
    <ng-template appHomepageActions>
        <app-discussion-type-heading [type]="discussion.type"></app-discussion-type-heading>

        <ng-container *ngIf="!readonly">
            <button *ngIf="canResolveNow$ | async" mat-flat-button type="button" color="primary" class="header-action"
                (click)="editSolution()">
                {{'discussions.resolve' | translate}}
            </button>

            <button *ngIf="canNoteNow$ | async" mat-flat-button type="button" color="primary" class="header-action"
                (click)="markNoted()">
                {{'discussions.approvals.noted' | translate}}
            </button>

            <button *ngIf="canApproveNow$ | async" mat-flat-button type="button" color="primary" class="header-action"
                (click)="reviewSolution()">
                {{'discussions.approvals.review' | translate}}
            </button>

            <app-edit-discussion-menu [discussion]="discussion" *ngIf="!referredSolution"
                (updated)="afterUpdated($event)" (deleted)="afterDeleted()">
            </app-edit-discussion-menu>
        </ng-container>
    </ng-template>

    <app-origin-header *ngIf="discussion.origin" [origin]="discussion.origin"></app-origin-header>

    <section class="wf-section">
        <dl>
            <div class="full-width" *ngIf="discussion.heading && discussion.description">
                <dt translate="discussions.description"></dt>
                <dd>
                    <span class="multiline-description">{{ discussion.description }}</span>
                </dd>
            </div>
            <div>
                <dt translate="discussions.creator"></dt>
                <dd>{{ getUserName(discussion.creator) }}</dd>
            </div>
            <div>
                <dt translate="discussions.dateIdentified"></dt>
                <dd>{{ discussion.dateIdentifiedLocal | dateFormat }}</dd>
            </div>
            <div>
                <dt translate="discussions.priority"></dt>
                <dd>
                    <app-priority [priority]="discussion.priority"></app-priority>
                </dd>
            </div>
            <div>
                <dt translate="discussions.status"></dt>
                <dd><app-discussion-status [status]="discussion.status"></app-discussion-status></dd>
            </div>
            <div>
                <dt translate="discussions.team"></dt>
                <dd>{{ discussion.team.name }} ({{ discussion.company.name }})</dd>
            </div>
            <div *ngIf="discussion.department">
                <dt translate="discussions.department"></dt>
                <dd>{{ discussion.department.name }}</dd>
            </div>
            <div *ngIf="discussion.category" class="sub-list">
                <div>
                    <dt translate="discussions.category"></dt>
                    <dd>{{ discussion.category.description }}</dd>
                </div>
                <div *ngIf="discussion.category.subCategory">
                    <dt translate="discussions.subCategory"></dt>
                    <dd>{{ discussion.category.subCategory.description }}</dd>
                </div>
            </div>
        </dl>
    </section>

    <section wf-expandable-section *ngIf="discussion.solution; let solution"
        [label]="'discussions.solution.solution' | translate" expanded>
        <dl>
            <div>
                <dt translate="discussions.solvedDate"></dt>
                <dd>{{ discussion.solution.solvedDateLocal | dateFormat }}</dd>
            </div>
            <div class="full-width" *ngIf="solution.rootCause">
                <dt translate="discussions.solution.rootCause"></dt>
                <dd>
                    <span class="multiline-description">{{ solution.rootCause }}</span>
                </dd>
            </div>
            <div class="full-width" *ngIf="solution.discussionNotes">
                <dt translate="discussions.solution.discussionNotes"></dt>
                <dd>
                    <span class="multiline-description">{{ solution.discussionNotes }}</span>
                </dd>
            </div>
            <div class="full-width" *ngIf="solution.solution">
                <dt translate="discussions.solution.solution"></dt>
                <dd>
                    <span class="multiline-description">{{ solution.solution }}</span>
                </dd>
            </div>
            <div class="full-width">
                <dt translate="discussions.solution.actions"></dt>
                <dd>
                    <span *ngIf="solution.noActionRequired" translate="discussions.solution.noActionRequired"></span>
                    <ng-container *ngIf="solutionActions$ | async; let actions">
                        <app-related-actions-table [actions]="actions" *ngIf="actions.length"></app-related-actions-table>
                    </ng-container>
                </dd>
            </div>
        </dl>
    </section>

    <section wf-expandable-section *ngIf="hasApprovalDetails && approvalsEnabled()"
        [label]="'discussions.solution.approvalDetails' | translate" expanded>
        <dl *ngIf="discussion.solution; let solution">
            <div>
                <dt translate="discussions.solution.referForApproval"></dt>
                <dd>
                    <ng-container *ngIf="solution.approvingTeam; let approvingTeam; else noApprovingTeam">
                        {{ approvingTeam.name }}
                    </ng-container>
                    <ng-template #noApprovingTeam>
                        {{ "discussions.solution.notRequired" | translate }}
                    </ng-template>
                </dd>
            </div>
            <div class="full-width" *ngIf="solution.approvalHistory.length">
                <dt translate="discussions.approvals.history"></dt>
                <dd class="approval-section">
                    <div *ngFor="let record of solution.approvalHistory"
                        [class.approved]="isApproved(record.operation)"
                        [class.rejected]="isRejected(record.operation)">
                        <span class="approval-date">
                            {{ record.dateTimeLocal | dateTimeFormat }}
                        </span>
                        <span class="approval-type">
                            {{ getSolutionApprovalActionTypeNameKey(record.operation) | translate }}
                        </span>
                        <span class="approval-team-name">
                            {{ record.team?.name ?? ("discussions.solution.deletedTeam" | translate) }}
                        </span>
                        <span *ngIf="record.comment" class="approval-comment">
                            {{'discussions.solution.withTheseComments' | translate}} :
                            {{record.comment}}
                        </span>
                    </div>
                </dd>
            </div>
        </dl>
    </section>

</app-homepage-scaffold>
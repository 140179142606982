<mat-dialog-content>
    <p>
        {{ 'reports.delete.confirmation' | translate: { description: description } }}
    </p>
    <p *ngIf="isRecurring">
        {{ 'reports.delete.recurringNote' | translate: { description: description } }}
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <app-status-button type="submit" [state]="buttonState" (buttonClick)="delete()">
        {{ "Delete" | translate }}
    </app-status-button>
</mat-dialog-actions>
import { FullComponent } from "./full/full.component";
import { MenuListItemComponent } from "./menu-list-item/menu-list-item.component";
import { RootRedirectComponent } from "./root-redirect/root-redirect.component";
import { AppSidebarComponent } from "./sidebar/sidebar.component";
import { TeamSelectMenuComponent } from "./team-select-menu/team-select-menu.component";
import { UserMenuComponent } from "./user-menu/user-menu.component";

const components: unknown[] = [
    AppSidebarComponent,
    FullComponent,
    MenuListItemComponent,
    RootRedirectComponent,
    TeamSelectMenuComponent,
    UserMenuComponent,
];

export {
    components as default,

    AppSidebarComponent,
    FullComponent,
    MenuListItemComponent,
    RootRedirectComponent,
    TeamSelectMenuComponent,
    UserMenuComponent,
};

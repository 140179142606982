import { Component, TemplateRef, ViewChild } from "@angular/core";
import { GetLinkedEntityFeedItemDto, LinkedEntityEventType } from "@api";
import { filter, first, Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { LinkedItemViewer } from "~services/linked-item-viewer.service";
import { EntityType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { getEntityTypeNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

import { BaseFeedListItemDirective } from "../base-feed-list-item.directive";

@Component({
    selector: "app-feed-list-linked-entity",
    templateUrl: "./feed-list-linked-entity.component.html",
    styleUrls: ["./feed-list-linked-entity.component.scss"],
    // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
    inputs: ["viewContext", "feedItem"],
    // eslint-disable-next-line @angular-eslint/no-outputs-metadata-property
    outputs: ["changeContext"],
})
export class FeedListLinkedEntityComponent extends WithDestroy(BaseFeedListItemDirective<GetLinkedEntityFeedItemDto>) {

    @ViewChild("tooltip", { static: false, read: TemplateRef }) tooltip: TemplateRef<unknown> | undefined;

    get iconName(): string | undefined {
        switch (this.feedItem.entityType) {
            case EntityType.action:
                return "playlist_add_check";
            case EntityType.discussion:
                return "connect_without_contact";
            case EntityType.solution:
                return "check_circle_outline";
            default:
                return undefined;
        }
    }

    get messageKey(): string {
        switch (this.feedItem.eventType) {
            case LinkedEntityEventType.created:
                return "FeedsList.listItems.linkedEntityMessage.created";
            default: return "";
        }
    }

    get entityNameKey(): string {
        return getEntityTypeNameKey(this.feedItem.entityType);
    }

    get subject(): string {
        return this.feedItem.subject;
    }

    readonly canReadSource$: Observable<boolean>;

    readonly getUserName = getUserName;

    constructor(
        private readonly accessService: AccessService,
        private readonly linkedItemViewer: LinkedItemViewer,
    ) {
        super();

        this.canReadSource$ = this.feedItemSubject.pipe(
            switchMap(feedItem => {
                if (!feedItem?.source) return of(false);
                const source = feedItem.source;
                if (!this.linkedItemViewer.canView(source)) return of(false);
                return this.accessService.canAccessCompanyTeam(source.company.id, source.team.id);
            }),
            shareReplayUntil(this.destroyed$)
        );
    }

    viewSource = () => {
        const source = this.feedItem.source;
        if (!source) return;

        this.canReadSource$.pipe(first(), filter(Boolean)).subscribe(() =>
            this.linkedItemViewer.view(source));
    };
}

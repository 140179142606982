import { trigger } from "@angular/animations";
import { ListRange } from "@angular/cdk/collections";
import { AfterViewInit, Component, HostBinding, OnDestroy, OnInit } from "@angular/core";
import { GetFeedItemDto } from "@api";
import { BehaviorSubject, EMPTY, Observable, of } from "rxjs";
import { distinctUntilChanged, map, switchMap, tap } from "rxjs/operators";

import {
    areFeedsEqual,
    FeedContext,
    FeedPaginationFilter,
    FeedPartitionContext,
    FeedPartitionKey,
    FeedPartitionKeyType,
    FeedReference,
    FeedScope,
    IFeedAdapter,
    partitionKeysEqual
} from "~feed/services";
import { FeedStateService } from "~services/feed-state.service";
import {
    defaultAnimationTiming, fadeExpandAnimationBuilder, fadeInAnimationBuilder, growWidthAnimationBuilder
} from "~shared/util/animations";
import { AsyncDataSource } from "~shared/util/async-table-data-source";

import { AddItemType } from "../add-item-header/add-item-header.component";

interface FeedDataSourceInput { adapter: IFeedAdapter | undefined; context: FeedPartitionContext | undefined }

const userGeneratedTypes: GetFeedItemDto["type"][] = ["getCommentFeedItem", "getLinkFeedItem", "getAttachmentFeedItem"];

const filterFeedItems = (item: GetFeedItemDto, filter: FeedPaginationFilter) => {
    switch (filter) {
        case "comments":
            return item.type === "getCommentFeedItem";
        case "links":
            return item.type === "getLinkFeedItem";
        case "attachments":
            return item.type === "getAttachmentFeedItem";
        case "userGenerated":
            return userGeneratedTypes.includes(item.type);
        case "systemGenerated":
            return !userGeneratedTypes.includes(item.type);
    }
    return true;
};

/**
 * Determines which context (if any) should be used for items added to the feed.
 * The intention is that items can only be added when the partition they will be added to is unambiguous.
 *
 * @param initialPartition The key of the partition that was initially opened.
 * @param selectedPartition The key of the partition that is currently selected.
 * @returns The partition key to add items to, or false if adding items should be prohibited.
 */
const getNewItemPartition = (initialPartition: FeedPartitionKey | undefined, selectedPartition: FeedPartitionKey | undefined):
    FeedPartitionKey | undefined | false => {
    const initial = initialPartition ?? [];
    const selected = selectedPartition ?? [];

    // If the initial partition is not the "All" partition, we cannot add items to the "All" partition.
    if (initial.length && !selected.length) return false;

    // If the selected partition is more specific than the initial partition, add items to the selected partition.
    // This means that if for example we open Q3, we can add items to Q3, Q3 W7 or Q4 W2.
    // Similarly, if we opened Q3 W7, we could add items to Q3 W7 or Q4 W2.
    if (selected.length >= initial.length) return selectedPartition;

    // The selected partition is less specific than the initial partition.

    // If the selected partition is an ascendant of the initial partition, add items to the initial partition.
    // This means if we for example we initially opened Q3 W7, while viewing Q3 we still add items to Q3 W7.
    if (selected.every((key, index) => key === initial[index])) return initialPartition;

    // Otherwise we cannot add items.
    // This prevents adding items when for example we initially open Q3 W7, then select Q4.
    return false;
};

@Component({
    selector: "app-feed-list",
    templateUrl: "./feed-list.component.html",
    styleUrls: ["./feed-list.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder(defaultAnimationTiming)),
        trigger("fadeExpand", fadeExpandAnimationBuilder(defaultAnimationTiming)),
        trigger("growWidth", growWidthAnimationBuilder(defaultAnimationTiming)),
    ],
})
export class FeedListComponent implements OnInit, OnDestroy, AfterViewInit {

    @HostBinding("@.disabled") disableAnimations = true;

    addItemType: AddItemType = "comment";

    initialContext?: FeedPartitionContext;

    get selectedContext(): FeedPartitionContext | undefined {
        return this.selectedContextSubject.value;
    }

    set selectedContext(value: FeedPartitionContext | undefined) {
        this.selectedContextSubject.next(value);
        const newItemPartition = getNewItemPartition(this.initialContext?.key, value?.key);
        this.canAddFeedItems = newItemPartition !== false;
        this.newItemContext = this.initialContext && newItemPartition ? {
            type: this.initialContext.type,
            key: newItemPartition,
        } : undefined;

    }

    feedItems$: Observable<readonly GetFeedItemDto[]> = EMPTY;
    readonly feedItemsDataSource: AsyncDataSource<GetFeedItemDto, FeedPaginationFilter, string, FeedDataSourceInput>;

    readonly visibleRange = new BehaviorSubject<ListRange>({ start: 0, end: 0 });

    get shouldHideFeedList(): boolean {
        return this.feedItemsDataSource.loadFailed ||
            this.feedItemsDataSource.hasNoVisibleData ||
            this.isLoadingInitialItems;
    }

    get isLoadingInitialItems(): boolean {
        return this.feedItemsDataSource.isLoading && !this.feedItemsDataSource.isLoadingMore;
    }

    get isFilterSelected(): boolean {
        return !!this.feedItemsDataSource.filter;
    }

    newItemContext: FeedPartitionContext | undefined = undefined;

    canAddFeedItems = false;

    private readonly selectedContextSubject = new BehaviorSubject<FeedPartitionContext | undefined>(undefined);

    private readonly collectionViewer = { viewChange: this.visibleRange.asObservable() };

    constructor(
        private readonly feedScope: FeedScope,
        private readonly feedStateService: FeedStateService,
    ) {
        const feedAdapter$ = this.feedScope.adapter$.pipe(
            distinctUntilChanged((a, b) => areFeedsEqual(a?.reference ?? null, b?.reference ?? null)),
        );

        const feedAdapterWithContext$ = feedAdapter$.pipe(
            switchMap(adapter => {
                this.onFeedChanged(adapter?.reference);
                return this.selectedContextSubject.pipe(map(context => ({ adapter: adapter ?? undefined, context })));
            }),
        );

        this.feedItemsDataSource = new AsyncDataSource<GetFeedItemDto, FeedPaginationFilter, string, FeedDataSourceInput>(
            feedAdapterWithContext$,
            (_, filter, skip, take, input) => this.getFeedData(input.adapter, input.context, skip, take, filter),
            (o1, o2) => o1.feedItemId === o2.feedItemId,
            { pageSize: 20, loadThreshold: 5 },
        );

        this.feedItemsDataSource.filterPredicate = filterFeedItems;
    }

    ngOnInit() {
        this.feedItems$ = this.feedItemsDataSource.connect(this.collectionViewer);
    }

    ngAfterViewInit(): void {
        this.disableAnimations = false;
    }

    ngOnDestroy() {
        this.feedItemsDataSource.disconnect(this.collectionViewer);
    }

    refresh = () => {
        this.feedItemsDataSource.refresh();
    };

    itemAdded = (item: GetFeedItemDto) => {
        // If the item just added will not be visible, clear out the filter.
        if (this.feedItemsDataSource.filter && !filterFeedItems(item, this.feedItemsDataSource.filter)) {
            this.feedItemsDataSource.filter = null;
        }
        this.updateLastActivityDate(item);
        this.refresh();
    };

    filterBy = (newFilter: FeedPaginationFilter | null) => {
        this.feedItemsDataSource.filter = newFilter;
    };

    private onFeedChanged = (feedReference?: FeedReference<FeedContext>) => {
        this.initialContext = this.getInitialContext(feedReference);
        this.selectedContext = this.getInitialSelectedContext(feedReference);
        this.feedItemsDataSource.filter = null;
    };

    private getInitialContext = (feedReference?: FeedReference<FeedContext>) => {
        const initialPartitionKey = feedReference?.partition.key;
        return initialPartitionKey && { key: initialPartitionKey, type: feedReference?.partitionType ?? FeedPartitionKeyType.period };
    };

    private getInitialSelectedContext = (feedReference?: FeedReference<FeedContext>) => {
        const initialPartitionKey = feedReference?.initialKey ?? feedReference?.partition.key;
        return initialPartitionKey && { key: initialPartitionKey, type: feedReference?.partitionType ?? FeedPartitionKeyType.period };
    };

    private getFeedData = (
        adapter: IFeedAdapter | undefined,
        context: FeedPartitionContext | undefined,
        skip: number,
        take: number,
        filter: FeedPaginationFilter | null
    ) => {
        const selectedContextKey = context?.key;

        if (!adapter) return of({ data: [], hasMore: false });
        return adapter.paginateFeedItems(skip, take, filter ?? undefined, selectedContextKey).pipe(
            tap(result => !skip && this.markFeedItemsAsViewed(adapter, selectedContextKey, result.data)),
        );
    };

    protected updateLastActivityDate = (item: GetFeedItemDto) => {
        if (!this.feedScope.adapter?.reference) return;
        const activePartition = this.feedScope.adapter.reference.partition;
        // Ensures this is the active partition
        // This may not be the case if the item is added to a child partition.
        if (!partitionKeysEqual(activePartition.key, item.partitionKey)) return;
        activePartition.lastActivityDate = item.createdDate;
    };

    private markFeedItemsAsViewed = (adapter: IFeedAdapter, selectedContextKey: FeedPartitionKey | undefined, result: GetFeedItemDto[]) => {
        if (!result.length) return;
        const item = result[0];
        adapter.setFeedViewedState(item.feedItemId, selectedContextKey).subscribe();
        const feedRef = adapter.reference;
        if (feedRef) {
            this.feedStateService.markLastViewed(
                item,
                { feedId: feedRef.partition.feedId, key: selectedContextKey ?? feedRef.partition?.key }
            );
        }
    };
}

<mat-icon class="main-icon" [color]="iconColor">{{ icon }}</mat-icon>
<div class="body">
    <div class="content">
        <ng-content></ng-content>
    </div>
    <app-feed-creation-info *ngIf="showCreationInfo" [isCommentEdited]="isCommentEdited" [feedItem]="feedItem">
    </app-feed-creation-info>
</div>
<app-feed-partition-context *ngIf="feedItemPartitionContext" [viewContext]="viewContext"
    [context]="feedItemPartitionContext" (contextSelected)="changeContext.emit($event)">
</app-feed-partition-context>
<div class="actions" *ngIf="actions">
    <ng-container [ngTemplateOutlet]="actions"></ng-container>
</div>
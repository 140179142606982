<h4 mat-dialog-title>
    <mat-icon>tips_and_updates</mat-icon>
    {{ 'goals.suggest.titleActions' | translate: { heading: goalHeading } }}
</h4>

<mat-dialog-content>
    <table mat-table *ngIf="!isLoading" class="wf-table" [dataSource]="dataSource" wfTableFocus>
        <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef class="wf-column-heading">
                {{ 'actions.description' | translate }}
            </th>
            <td mat-cell *matCellDef="let element" class="wf-column-heading">
                <div class="multiline-description">
                    {{ element.description }}
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="apply">
            <th mat-header-cell *matHeaderCellDef>
                {{'goals.suggest.apply' | translate}}
            </th>
            <td mat-cell *matCellDef="let suggestion" [ngSwitch]="isApplied(suggestion)">
                <button *ngSwitchCase="false" mat-button color="primary" (click)="applySuggestion(suggestion)">
                    {{ 'goals.suggest.apply' | translate }}
                </button>
                <span *ngSwitchCase="true" class="applied">
                    {{ 'goals.suggest.applied' | translate }}
                </span>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns" [wfRowFocus]="row" [wfRowFocusIndex]="i">
        </tr>
    </table>

    <div *ngIf="isLoading" class="loading-container">
        <mat-spinner [diameter]="40"></mat-spinner>
        <span translate="goals.suggest.loading"></span>
    </div>
</mat-dialog-content>
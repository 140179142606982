import { Injectable } from "@angular/core";
import { TimeZonesApi } from "@api";
import { map, Observable } from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { retryWithDelay } from "~shared/util/caching";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { mapToDetails, sortTimeZones, TimeZoneDetails } from "~shared/util/time-zone-helper";

@Injectable({
    providedIn: "root"
})
export class TimeZoneRepository extends WithDestroy() {

    readonly timeZones$: Observable<TimeZoneDetails[]> =
        this.timeZonesApi.list().pipe(
            retryWithDelay(),
            shareReplayUntil(this.destroyed$),
            map(timeZones => timeZones.map(mapToDetails)),
            map(sortTimeZones),
        );

    constructor(
        private readonly timeZonesApi: TimeZonesApi,
    ) {
        super();
    }
}

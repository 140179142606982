/* eslint-disable max-classes-per-file */
import { CompanyTeamFeedApi } from "@api";

import { GenericFeedAdapter } from "./feed.adapter";
import { CompanyTeamFeedContext, FeedReference } from "./feed.common";

type CompanyTeamScope = readonly [companyId: string, teamId: string, feedId: string];

export class CompanyTeamFeedAdapter extends GenericFeedAdapter<CompanyTeamScope> {

    protected get scope() {
        return [this.reference.context.companyId, this.reference.context.teamId, this.reference.partition.feedId] as const;
    }

    constructor(
        readonly reference: Readonly<FeedReference<CompanyTeamFeedContext>>,
        api: CompanyTeamFeedApi,
    ) {
        super(api);
    }
}

<form (submit)="addLink()" [formGroup]="form">
    <mat-form-field class="no-label" subscriptSizing="dynamic">
        <input type="text" matInput formControlName="link" name="link"
            [placeholder]="'FeedsList.listActions.addLink' | translate" cdkFocusInitial />
        <span class="context-indicator" matTextSuffix *ngIf="displayContextHint" @fadeIn>
            {{ contextHint }}
        </span>
        <button matSuffix mat-icon-button color="primary" type="submit"
            [appMatVisualDisable]="!form.valid || !linkControl.value">
            <mat-icon>send</mat-icon>
        </button>
        <mat-error *ngIf="linkControl.errors?.pattern">{{ 'FeedsList.listActions.linkError' | translate }}
        </mat-error>
    </mat-form-field>
</form>
import { Injectable } from "@angular/core";
import { CurrentCompanyDto, PartnerApi, PartnerStripeStatusDto } from "@api";
import { combineLatest, map, Observable, of, switchMap } from "rxjs";

import { Profile, Role } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { getProfileRoles } from "~shared/util/profile-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

import { TeamContext, UserContext } from "./contexts";
import { NotificationService } from "./notification.service";

export interface PartnerStripeStatus {
    company: CurrentCompanyDto;
    status: PartnerStripeStatusDto;
};

@Injectable({
    providedIn: "root",
})
export class PartnerStripeService extends WithDestroy() {

    /**
     * If the current company is a partner (and the user has permissions to view), the partner's stripe status
     */
    readonly status$: Observable<PartnerStripeStatus | null>;

    constructor(
        private readonly partnerApi: PartnerApi,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly notificationService: NotificationService,
    ) {
        super();

        this.status$ = combineLatest({
            user: this.userContext.user$,
            companyTeam: this.teamContext.companyTeam$,
        }).pipe(
            switchMap(({ user, companyTeam: ct }) => {
                if (!user || !ct || !ct.company.isPartner) return of(null);
                const partner = ct.company;

                const role = user.roleName as Role | null;
                if (!role || !getProfileRoles(Profile.partnerManager).includes(role)) return of(null);

                // If not a super admin, only allow checking this for your home company.
                if (role !== Role.superAdmin && user.companyId !== partner.id) return of(null);

                return this.partnerApi.getPartnerStripeStatus(partner.id).pipe(
                    map(status => ({ company: partner, status } as PartnerStripeStatus)),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    openDashboard = (companyId: string) => {
        this.partnerApi.createPartnerStripeDashboardUrl(companyId).subscribe({
            next: response => window.open(response.Url),
            error: _ => this.notificationService.errorUnexpected(),
        });
    };
}

<ng-container *ngIf="item && shouldShow" [ngSwitch]="true">

    <div *ngSwitchCase="isSeparator" class="list-separator">
        <div class="list-separator-inner"></div>
    </div>

    <a *ngSwitchCase="isLink" mat-list-item [routerLink]="linkItem?.route"
        routerLinkActive="active" [routerLinkActiveOptions]="routeMatchOptions" class="menu-list-item">
        <div class="menu-list-item-inner">
            <mat-icon class="menu-icon" [svgIcon]="svgIcon ?? ''">{{ icon }}</mat-icon>
            <span>{{ name | translate}}</span>
            <mat-icon *ngIf="linkItem?.teaser" svgIcon="wf-stars-complete" class="feature-stars"></mat-icon>
        </div>
    </a>

    <ng-container *ngSwitchCase="isMenu">
        <button mat-list-item (click)="toggleExpand()"
            [ngClass]="{ expanded: expanded }" class="menu-list-item"
            [attr.aria-expanded]="expanded">
            <div class="menu-list-item-inner">
                <mat-icon class="menu-icon" [svgIcon]="svgIcon ?? ''">{{ icon }}</mat-icon>
                <span>{{ name | translate}}</span>
                <mat-icon class="expand-icon" [@indicatorRotate]="expanded ? 'expanded' : 'collapsed'"
                    *ngIf="subMenuItem?.children?.length">
                    expand_more
                </mat-icon>
            </div>
        </button>
    
        <div *ngIf="expanded && subMenuItem?.children?.length" class="sub-menu-container">
            <app-menu-list-item [item]="child" *ngFor="let child of subMenuItem?.children; trackBy: trackMenuItem">
            </app-menu-list-item>
        </div>
    </ng-container>
</ng-container>
/**
 * SAASCLOUDAPP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum EntityType {
    goal = 0,
    action = 1,
    number = 2,
    solution = 3,
    news = 4,
    weeklyReport = 5,
    discussion = 6,
    report = 7
}


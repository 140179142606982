<li app-feed-list-item-content [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" [icon]="iconName">
    <wf-translate-template [key]="messageKey">
        <span class="entity" *wfTransTplContent="'entity'">{{ entityNameKey | translate }}</span>
        <ng-template [wfTransTplContent]="'subject'">
            <span class="subject" [wfTemplateTooltip]="tooltip ?? null" matTooltipClass="light-tooltip">
                @if (canReadSource$ | async) {
                    <a href="javascript:void(0)" (click)="viewSource()">{{ subject }}</a>
                } @else {
                    {{ subject }}
                }
            </span>
            @if (feedItem.source; as source) {
            @if (source.extras; as extras) {
            @if (extras.type === "action") {
                {{ ' ' }}
                <app-action-progress [progress]="extras.progress" class="extras-status"></app-action-progress>
                <ng-template #tooltip>
                    <dl>
                        <div>
                            <dt translate="actions.owner"></dt>
                            <dd>{{ getUserName(extras.owner) }}</dd>
                        </div>
                        <div>
                            <dt translate="actions.team"></dt>
                            <dd>{{ source.team.name }} ({{ source.company.name }})</dd>
                        </div>
                        <div>
                            <dt translate="actions.dueDate"></dt>
                            <dd>{{ extras.dueDateLocal | dateFormat }}</dd>
                        </div>
                        <div *ngIf="extras.status">
                            <dt translate="actions.completedDate"></dt>
                            <dd>{{ extras.solvedDateLocal | dateFormat }}</dd>
                        </div>
                    </dl>
                </ng-template>
            }
            @if (extras.type === "discussion") {
                {{ ' ' }}
                <app-discussion-status [status]="extras.status" class="extras-status"></app-discussion-status>
                <ng-template #tooltip>
                    <dl>
                        <div>
                            <dt translate="discussions.type"></dt>
                            <dd><app-discussion-type [type]="extras.discussionType"></app-discussion-type></dd>
                        </div>
                        <div>
                            <dt translate="discussions.team"></dt>
                            <dd>{{ source.team.name }} ({{ source.company.name }})</dd>
                        </div>
                        @if (extras.solution; as solution) {
                            <div>
                                <dt translate="discussions.solvedDate"></dt>
                                <dd>{{ solution.solvedDateLocal | dateFormat }}</dd>
                            </div>
                            <div class="full-width" *ngIf="solution.discussionNotes">
                                <dt translate="discussions.solution.discussionNotes"></dt>
                                <dd>
                                    <span class="multiline-description">{{ solution.discussionNotes }}</span>
                                </dd>
                            </div>
                            <div class="full-width" *ngIf="solution.solution">
                                <dt translate="discussions.solution.solution"></dt>
                                <dd>
                                    <span class="multiline-description">{{ solution.solution }}</span>
                                </dd>
                            </div>
                        }
                    </dl>
                </ng-template>
            }
            }
            }
        </ng-template>
    </wf-translate-template>
</li>
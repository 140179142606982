import { CalculationType } from "~shared/enums";

export const getCalculationTypeNameKey = (type: CalculationType): string => {
    switch (type) {
        case CalculationType.sum: return "calculationType.sum";
        case CalculationType.average: return "calculationType.average";
        case CalculationType.product: return "calculationType.product";
        case CalculationType.latest: return "calculationType.latest";
        default: return "";
    }
};

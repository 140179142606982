/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */
import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AttachmentDto, CompanyTeamPlanningDocumentApi, CompanyTeamPlanningDocumentDto, CompanyTeamPlanningDocumentType } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, filter, Observable } from "rxjs";

import { NotificationService } from "~services/notification.service";

import { BaseEditAttachmentsDialogComponent } from "../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component";
import { BasePlanningDocumentsButtonComponent } from "../base-planning-documents-button/base-planning-documents-button.component";

export { CompanyTeamPlanningDocumentType };

interface CompanyTeamType {
    companyId: string;
    teamId: string;
    type: CompanyTeamPlanningDocumentType;
}

@Component({
    selector: "app-company-team-planning-documents-button",
    templateUrl: "../base-planning-documents-button/base-planning-documents-button.component.html",
    styleUrls: ["../base-planning-documents-button/base-planning-documents-button.component.scss"]
})
export class CompanyTeamPlanningDocumentsButtonComponent
    extends BasePlanningDocumentsButtonComponent<CompanyTeamPlanningDocumentDto, CompanyTeamType> {

    get teamId(): string | null {
        return this.teamIdSubject.value;
    }

    @Input() set teamId(value: string | null) {
        this.teamIdSubject.next(value);
    }

    get type(): CompanyTeamPlanningDocumentType | null {
        return this.typeSubject.value;
    }

    @Input() set type(value: CompanyTeamPlanningDocumentType | null) {
        this.typeSubject.next(value);
    }

    protected readonly input$: Observable<CompanyTeamType>;

    private readonly teamIdSubject = new BehaviorSubject<string | null>(null);
    private readonly typeSubject = new BehaviorSubject<CompanyTeamPlanningDocumentType | null>(null);

    constructor(
        private readonly documentApi: CompanyTeamPlanningDocumentApi,
        private readonly dialog: MatDialog,
    ) {
        super();
        this.input$ = combineLatest({
            companyId: this.companyIdSubject.pipe(filter(Boolean)),
            teamId: this.teamIdSubject.pipe(filter(Boolean)),
            type: this.typeSubject.pipe(
                filter((type): type is CompanyTeamPlanningDocumentType => type !== null && type !== undefined)
            ),
        });
    }

    protected getDocument = (input: CompanyTeamType) =>
        this.documentApi.getPlanningAttachments(
            input.companyId,
            input.teamId,
            input.type,
        );

    protected openDialogInternal = (document: CompanyTeamPlanningDocumentDto) =>
        EditCompanyTeamPlanningDocumentsDialogComponent.open(this.dialog, document);

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_type: CompanyTeamPlanningDocumentType | `${CompanyTeamPlanningDocumentType}` | null;
}

@Component({
    selector: "app-edit-company-team-planning-documents-dialog",
    templateUrl: "../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component.html",
    styleUrls: ["../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component.scss"],
})
export class EditCompanyTeamPlanningDocumentsDialogComponent extends BaseEditAttachmentsDialogComponent {
    get description(): string {
        return this.translate.instant(this.descriptionKey);
    }

    private get descriptionKey(): string {
        switch (this.doc.type) {
            case CompanyTeamPlanningDocumentType.hirePlan:
                return "Staff Resources";
            case CompanyTeamPlanningDocumentType.resourcesNonStaff:
                return "Resources (Non Staff)";
        }
        return "";
    }

    constructor(
        private readonly documentApi: CompanyTeamPlanningDocumentApi,
        private readonly translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) private readonly doc: CompanyTeamPlanningDocumentDto,
        notificationService: NotificationService,
        dialogRef: MatDialogRef<unknown, boolean>,
    ) {
        super(notificationService, dialogRef);
    }

    static open(dialog: MatDialog, doc: CompanyTeamPlanningDocumentDto) {
        return BaseEditAttachmentsDialogComponent.openInternal(EditCompanyTeamPlanningDocumentsDialogComponent, dialog, doc);
    }

    protected getExistingAttachments = (): AttachmentDto[] => this.doc.attachments;

    protected uploadAttachment = (file: File): Observable<unknown> =>
        this.documentApi.addPlanningAttachment(
            this.doc.company.id,
            this.doc.team.id,
            this.doc.type,
            file
        );

    protected deleteAttachment = (path: string): Observable<unknown> => this.deleteAttachments([path]);

    protected deleteAttachments = (paths: string[]): Observable<unknown> =>
        this.documentApi.deletePlanningAttachments(
            this.doc.company.id,
            this.doc.team.id,
            this.doc.type,
            { attachmentPaths: paths },
        );
}

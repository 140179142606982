import { InjectionToken } from "@angular/core";
import { Observable } from "rxjs";

export interface IStore {
    clear(): Observable<void>;
}

export interface GenericStore<TItem> extends IStore {
    getById(id: string): Observable<TItem | null>;
    update(item: TItem): Observable<unknown>;
}

export const PERSISTENT_STORE = new InjectionToken<IStore[]>("PERSISTENT_STORE");

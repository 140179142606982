import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import {
    AfterViewInit, Component, ContentChild, EventEmitter, HostBinding, Input, Output, TemplateRef, ViewEncapsulation
} from "@angular/core";

import { fadeExpandAnimationBuilder } from "~shared/util/animations";

import { ExpandableSectionActionsDirective } from "./expandable-section-actions.directive";
import { ExpandableSectionBodyDirective } from "./expandable-section-body.directive";
import { ExpandableSectionHeaderDirective } from "./expandable-section-header.directive";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "wf-expandable-section,section[wf-expandable-section]",
    templateUrl: "./expandable-section.component.html",
    styleUrls: ["./expandable-section.component.scss"],
    encapsulation: ViewEncapsulation.None,
    host: {
        class: "wf-section wf-expandable-section",
    },
    animations: [
        trigger("expanded", fadeExpandAnimationBuilder()),
    ],
})
export class ExpandableSectionComponent implements AfterViewInit {

    private static nextId = 0;

    @Input() label: string | null = null;

    @HostBinding("class.wf-expandable-section-expanded")
    @Input() set expanded(value: boolean) {
        this.expandedInternal = coerceBooleanProperty(value);
    }

    get expanded() {
        return this.expandedInternal;
    }

    @Input() set disabled(value: boolean) {
        this.disabledInternal = coerceBooleanProperty(value);
    }

    get disabled() {
        return this.disabledInternal;
    }

    @Output() expandedChange = new EventEmitter<boolean>();

    @ContentChild(ExpandableSectionHeaderDirective, { read: TemplateRef, static: false }) headerTemplate?: TemplateRef<unknown>;
    @ContentChild(ExpandableSectionActionsDirective, { read: TemplateRef, static: false }) actionsTemplate?: TemplateRef<unknown>;
    @ContentChild(ExpandableSectionBodyDirective, { read: TemplateRef, static: false }) bodyTemplate?: TemplateRef<unknown>;

    @HostBinding() readonly id: string;
    readonly headerId: string;
    readonly bodyId: string;

    @HostBinding("class.wf-expandable-section-collapsed")
    get collapsed(): boolean {
        return !this.expanded;
    }

    disableAnimations = true;

    private expandedInternal = false;
    private disabledInternal = false;

    private readonly idNumber = ExpandableSectionComponent.nextId++;

    constructor() {
        this.id = `wf-expandable-section-${this.idNumber}`;
        this.headerId = `wf-expandable-section-header-${this.idNumber}`;
        this.bodyId = `wf-expandable-section-body-${this.idNumber}`;
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    toggle = () => this.setExpanded(!this.expanded);
    expand = () => this.setExpanded(true);
    collapse = () => this.setExpanded(false);

    private setExpanded = (expanded: boolean) => {
        if (this.disabled) return;
        this.expandedInternal = expanded;
        this.expandedChange.emit(expanded);
    };

    /* eslint-disable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
    static ngAcceptInputType_expanded: BooleanInput;
    static ngAcceptInputType_disabled: BooleanInput;
    /* eslint-enable @typescript-eslint/member-ordering, @typescript-eslint/naming-convention */
}

import { Injectable } from "@angular/core";
import { AnnualGoalTypeApi, SimpleAnnualGoalTypeDto } from "@api";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";

@Injectable({
    providedIn: "root"
})
export class AnnualGoalTypeRepository {
    private cache: ICompanyCache<SimpleAnnualGoalTypeDto[]> = {};

    constructor(private readonly annualGoalTypeApi: AnnualGoalTypeApi) { }

    getTypes = (companyId: string, force = false): Observable<SimpleAnnualGoalTypeDto[]> => {
        let cacheItem = force ? null : getCompanyCacheItem(this.cache, companyId);
        if (!cacheItem) {
            cacheItem = this.getTypesObservableForCompany(companyId);
            setCompanyCacheItem(this.cache, companyId, cacheItem);
        }
        return cacheItem;
    };

    private getTypesObservableForCompany = (companyId: string): Observable<SimpleAnnualGoalTypeDto[]> =>
        this.annualGoalTypeApi.listAnnualGoalTypes(companyId).pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}

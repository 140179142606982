import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DiscussionAndSolutionDto } from "@api";

import { DeleteDiscussionDialogComponent, EditDiscussionDialogComponent } from "~shared/dialogs";

@Component({
    selector: "app-edit-discussion-menu",
    templateUrl: "./edit-discussion-menu.component.html",
    styleUrls: ["./edit-discussion-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditDiscussionMenuComponent {

    @Input() discussion?: DiscussionAndSolutionDto;
    @Input() disabled = false;

    @Output() updated = new EventEmitter<DiscussionAndSolutionDto>();
    @Output() deleted = new EventEmitter<void>();

    constructor(
        private readonly dialog: MatDialog,
    ) { }

    edit = () => {
        if (!this.discussion || this.disabled) return;
        EditDiscussionDialogComponent.openForEdit(this.dialog, {
            discussion: this.discussion,
        }).afterClosed().subscribe(res => res && this.updated.emit(res));
    };

    delete = () => {
        if (!this.discussion || this.disabled) return;
        DeleteDiscussionDialogComponent.open(this.dialog, this.discussion)
            .afterClosed().subscribe(res => res && this.deleted.emit());
    };

}

import { GoalStatus } from "./enums";

export const getGoalStatusSortOrder = (status: GoalStatus): number => {
    switch (status) {
        case GoalStatus.updateRequired: return 0;
        case GoalStatus.offTarget: return 1;
        case GoalStatus.onTarget: return 2;
        case GoalStatus.complete: return 3;
        case GoalStatus.cancelled: return 4;
        default: return 0;
    }
};

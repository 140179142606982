<li app-feed-list-item-content [feedItem]="feedItem" [viewContext]="viewContext"
    (changeContext)="changeContext.emit($event)" [icon]="iconName"
    [class.positive-change]="positiveStatusChange"
    [class.negative-change]="negativeStatusChange"
    [ngSwitch]="feedItem.fieldType">

    <div class="status-text" *ngSwitchCase="StatusChangedFieldType.numberResult">
        <wf-translate-template [key]="numberChangeTypeDescKey">
            <b *wfTransTplContent="'oldResult'">
                <app-number-result [result]="$any(feedItem.oldValue)" [numberType]="numberType"></app-number-result>
            </b>
            <b *wfTransTplContent="'newResult'">
                <app-number-result [result]="$any(feedItem.newValue)" [numberType]="numberType"></app-number-result>
            </b>
        </wf-translate-template>
    </div>

    <div class="status-text" *ngSwitchCase="StatusChangedFieldType.goalStatus">
        <wf-translate-template key="FeedsList.listItems.statuses.goals.statusChanged">
            <app-goal-status *wfTransTplContent="'status'" [status]="newGoalStatus ?? 3"
                [nameOverride]="newValueGoalStatusCustomName"></app-goal-status>
        </wf-translate-template>
    </div>

    <div class="status-text" *ngSwitchCase="StatusChangedFieldType.actionStatus">
        <wf-translate-template key="FeedsList.listItems.statuses.actions.statusChanged">
            <app-action-progress *wfTransTplContent="'status'" [progress]="newActionProgress ?? 0"></app-action-progress>
        </wf-translate-template>
    </div>

    <div class="status-text" *ngSwitchCase="StatusChangedFieldType.discussionSolutionStatus">
        <wf-translate-template key="FeedsList.listItems.statuses.discussions.statusChanged">
            <app-discussion-status *wfTransTplContent="'status'" [status]="newDiscussionStatus ?? 0"></app-discussion-status>
        </wf-translate-template>
    </div>

    <div class="status-text" *ngSwitchCase="StatusChangedFieldType.report" [ngSwitch]="changeType">
        <ng-container *ngSwitchCase="'removed'">
            {{ 'FeedsList.listItems.statuses.reports.reportRemoved' | translate }} <b>{{ feedItem.oldValue || ('FeedsList.listItems.statuses.reports.unknown' | translate) }}</b>
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{ 'FeedsList.listItems.statuses.reports.reportAdded' | translate }} <b>{{ feedItem.newValue || ('FeedsList.listItems.statuses.reports.unknown' | translate) }}</b>
        </ng-container>
    </div>

    <div class="status-text" *ngSwitchCase="StatusChangedFieldType.reportExternallyUpdated" [ngSwitch]="feedItem.newValue">
        <ng-container *ngSwitchCase="true">
            {{ 'FeedsList.listItems.statuses.reports.reportMarkedUpdated' | translate }}
        </ng-container>
        <ng-container *ngSwitchDefault>
            {{ 'FeedsList.listItems.statuses.reports.reportMarkedNotUpdated' | translate }}
        </ng-container>
    </div>

    <div *ngIf="feedItem.notes" class="status-notes">
        <div class="note-callout"></div>
        <span class="multiline-description">
            {{ feedItem.notes }}
        </span>
    </div>
</li>
@for (data of weekData(); track data.week) {
    @if (data.status) {
        <span class="wf-result-summary-cell" [class.wf-result-summary-cell-current]="data.week === latestWeek()"
            [ngClass]="getCellClass(data)" [wfTemplateTooltip]="tooltip" matTooltipClass="light-tooltip"
            tabindex="0">
            <span class="cdk-visually-hidden">
                <ng-container [ngTemplateOutlet]="tooltip"></ng-container>
            </span>
        </span>
        <ng-template #tooltip>
            <dl>
                <div>
                    <dt translate="period.week"></dt>
                    <dd>{{ data.week }}</dd>
                </div>
                <div>
                    <dt translate="numbers.result"></dt>
                    <dd>
                        <app-number-result [result]="data.record.result"
                            [target]="data.record.target" [numberType]="number().type">
                        </app-number-result>
                    </dd>
                </div>
                <div>
                    <dt translate="numbers.target"></dt>
                    <dd>
                        <app-number-target [target]="data.record.target" [numberType]="number().type">
                        </app-number-target>
                    </dd>
                </div>
            </dl>
        </ng-template>
    } @else {
        <span class="wf-result-summary-cell wf-result-summary-cell-empty" aria-hidden="true"></span>
    }
}
import { Injectable } from "@angular/core";
import { CategoriesApi, CategoryDetailDto } from "@api";
import { Observable, shareReplay } from "rxjs";

import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";

@Injectable({
    providedIn: "root"
})
export class CategoryRepository {
    private categoryCache: ICompanyCache<CategoryDetailDto[]> = {};
    private annualGoalCategoryCache: ICompanyCache<CategoryDetailDto[]> = {};

    constructor(private readonly categoriesApi: CategoriesApi) { }

    getCategories = (companyId: string, force = false): Observable<CategoryDetailDto[]> => {
        let cacheItem = force ? null : getCompanyCacheItem(this.categoryCache, companyId);
        if (!cacheItem) {
            cacheItem = this.getCategoriesObservableForCompany(companyId);
            setCompanyCacheItem(this.categoryCache, companyId, cacheItem);
        }
        return cacheItem;
    };

    getAnnualGoalCategories = (companyId: string, force = false): Observable<CategoryDetailDto[]> => {
        let cacheItem = force ? null : getCompanyCacheItem(this.annualGoalCategoryCache, companyId);
        if (!cacheItem) {
            cacheItem = this.getAnnualGoalCategoriesObservableForCompany(companyId);
            setCompanyCacheItem(this.annualGoalCategoryCache, companyId, cacheItem);
        }
        return cacheItem;
    };

    private getCategoriesObservableForCompany = (companyId: string): Observable<CategoryDetailDto[]> =>
        this.categoriesApi.listCategories(companyId).pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );

    private getAnnualGoalCategoriesObservableForCompany = (companyId: string): Observable<CategoryDetailDto[]> =>
        this.categoriesApi.listAnnualGoalCategories(companyId).pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}

import { Injectable } from "@angular/core";
import { PaymentApi, PlanTierDto } from "@api";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { buildCacheItem, DEFAULT_CACHE_EXPIRY_MS, ICacheItem, retryWithDelay } from "~shared/util/caching";

const PLAN_CACHE_EXPIRY_MS = DEFAULT_CACHE_EXPIRY_MS;

@Injectable({
    providedIn: "root"
})
export class PlanTierRepository {
    private cache: ICacheItem<PlanTierDto[]> | null = null;

    constructor(
        private readonly paymentApi: PaymentApi
    ) { }

    getPlanTiers = (): Observable<PlanTierDto[]> => {
        let planTiers = this.getCachedPlanTiers();
        if (!planTiers) {
            planTiers = this.getPlanTiersObservable();
            this.cache = buildCacheItem(planTiers, PLAN_CACHE_EXPIRY_MS);
        }
        return planTiers;
    };

    private getCachedPlanTiers = () => {
        if (!this.cache) return null;
        if (this.cache.expires < Date.now()) {
            this.cache = null;
            return null;
        }
        return this.cache.observable;
    };

    private getPlanTiersObservable = (): Observable<PlanTierDto[]> =>
        this.paymentApi.getPlanTiers().pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}

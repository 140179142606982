import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot } from "@angular/router";
import { distinctUntilChanged, map, Observable } from "rxjs";

import { CompanyMenuRepository } from "~repositories";
import { CustomRouteData } from "~shared/custom-route";
import { PageName, PermissionType, Role } from "~shared/enums";

import { UserContext } from "./contexts";

export interface AccessState {
    /**
     * The user can access the current item and data related to it.
     */
    canRead: boolean;
    /**
     * The user can access the current item and has permission to update it.
     */
    canEdit: boolean;
    /**
     * The user can access the current item and has permission to delete it.
     */
    canDelete: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AccessState {
    export const disabled: AccessState = {
        canRead: false,
        canEdit: false,
        canDelete: false,
    };
}

@Injectable()
export class AccessService {

    constructor(
        private readonly companyMenuRepository: CompanyMenuRepository,
        private readonly userContext: UserContext,
        private readonly activatedRoute: ActivatedRoute,
    ) { }

    canWriteCurrentPage = (): boolean => {
        const currentPage = this.getCurrentPage();
        if (!currentPage) return true;
        return this.getPageAccess(currentPage) === PermissionType.readWrite;
    };

    getPageAccess = (page: PageName): PermissionType | null => {
        const currentRole = this.userContext.role();
        switch (currentRole) {
            case Role.associate:
                // Associates cannot access any page
                return null;
            case Role.user: {
                // Users access level is based on their permissions.
                const pagePermissions = this.userContext.user()?.pagePermissions ?? [];
                const permission = pagePermissions.find(p => p.name === page);
                return permission?.permission ?? null;
            }
            case Role.advisor:
                // Advisors can read all pages but not write to any.
                return PermissionType.read;
            case Role.partnerAdmin:
            case Role.partner:
            case Role.admin:
            case Role.enterpriseAdmin:
            case Role.superAdmin:
                // All other "admin" users can write to all pages
                return PermissionType.readWrite;
            default:
                return null;
        }
    };

    /**
     * Determines whether the current user is an admin of the supplied company.
     * WARNING: Assumes that the user has access rights to the company in question.
     *
     * @param companyId The ID of the company for which to check admin rights.
     */
    isAdmin = (companyId: string) => {
        const currentRole = this.userContext.role();
        const homeCompanyId = this.userContext.user()?.companyId;
        switch (currentRole) {
            // Super admins have admin rights to all companies, while Enterprise and
            // Partner admins have admin rights to all companies they can access.
            case Role.superAdmin:
            case Role.partnerAdmin:
            case Role.enterpriseAdmin:
                return true;
            // Partner users are not admins to their home company, but are admins to any
            // managed companies.
            case Role.partner:
                return !!homeCompanyId && homeCompanyId !== companyId;
            // Admin users are only admins in their home company.
            case Role.admin:
                return !!homeCompanyId && homeCompanyId === companyId;
            default:
                return false;
        }
    };

    canAccessCompanyTeam = (companyId: string, teamId: string): Observable<boolean> =>
        this.companyMenuRepository.getMenuItems().pipe(
            map(companies => companies.find(c => c.company.id === companyId)?.teams ?? []),
            map(teams => teams.some(t => t.id === teamId)),
            distinctUntilChanged(),
        );

    getAccessState = (companyId: string, teamId: string, page?: PageName): Observable<AccessState> =>
        this.canAccessCompanyTeam(companyId, teamId).pipe(
            map(canAccess => ({
                canRead: canAccess,
                canEdit: canAccess && (page ? this.getPageAccess(page) === PermissionType.readWrite : this.canWriteCurrentPage()),
                canDelete: canAccess && this.isAdmin(companyId),
            })),
        );

    private getCurrentPage = (): PageName | null =>
        this.getRoutePageName(this.activatedRoute.snapshot);

    private getRoutePageName = (route: ActivatedRouteSnapshot): PageName | null => {
        if (route.firstChild) {
            const childName = this.getRoutePageName(route.firstChild);
            if (childName) return childName;
        }
        const data = route.data as CustomRouteData;
        if (data && data.pageName) {
            return data.pageName;
        }
        return null;
    };
}

import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { DiscussionType } from "~shared/enums";
import { getDiscussionTypeNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "app-discussion-type-heading",
    templateUrl: "./discussion-type-heading.component.html",
    styleUrls: ["./discussion-type-heading.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscussionTypeHeadingComponent {
    @Input() type: DiscussionType = DiscussionType.discussion;

    get typeNameKey(): string {
        return getDiscussionTypeNameKey(this.type);
    }

    get typeIcon(): string {
        switch (this.type) {
            case DiscussionType.challenge:
                return "call_missed_outgoing";
            case DiscussionType.opportunity:
                return "call_made";
            case DiscussionType.discussion:
            default:
                return "connect_without_contact";
        }
    }
}

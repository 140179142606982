/* eslint-disable
    @typescript-eslint/ban-types,
    @typescript-eslint/naming-convention,
    @typescript-eslint/no-explicit-any,
    max-classes-per-file,
    prefer-arrow/prefer-arrow-functions
*/
import { DestroyRef, inject } from "@angular/core";
import { Observable, Subject } from "rxjs";

import { AbstractConstructor, Constructor } from "./shared";

interface IWithDestroy {
    readonly destroyed$: Observable<void>;
}

type WithDestroyCtor = Constructor<IWithDestroy> & AbstractConstructor<IWithDestroy>;

export function WithDestroy<T extends AbstractConstructor>(Base?: T): WithDestroyCtor & T;
export function WithDestroy<T extends Constructor>(Base: T = (class { } as unknown as T)): WithDestroyCtor & T {
    return class extends Base implements IWithDestroy {
        get destroyed$(): Observable<void> {
            return this._destroyed$.asObservable();
        }

        private readonly _destroyed$ = new Subject<void>();

        constructor(...args: any[]) {
            super(...args);
            inject(DestroyRef).onDestroy(() => {
                this._destroyed$.next();
                this._destroyed$.complete();
            });
        }
    };
}

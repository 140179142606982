/**
 * SAASCLOUDAPP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { FeedItemReferenceDto } from '../model/feedItemReferenceDto';
// @ts-ignore
import { FeedPartitionDto } from '../model/feedPartitionDto';
// @ts-ignore
import { GetFeedItemDto } from '../model/getFeedItemDto';
// @ts-ignore
import { PagedDataDtoGetFeedItemDto } from '../model/pagedDataDtoGetFeedItemDto';
// @ts-ignore
import { UpdateFeedItemDto } from '../model/updateFeedItemDto';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class NumberFeedApi {

    protected basePath = 'http://localhost:50511';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param feedItemDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemDto: UpdateFeedItemDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<GetFeedItemDto>;
    public addFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemDto: UpdateFeedItemDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetFeedItemDto>>;
    public addFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemDto: UpdateFeedItemDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetFeedItemDto>>;
    public addFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemDto: UpdateFeedItemDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling addFeedItem.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling addFeedItem.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling addFeedItem.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling addFeedItem.');
        }
        if (feedItemDto === null || feedItemDto === undefined) {
            throw new Error('Required parameter feedItemDto was null or undefined when calling addFeedItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/items`;
        return this.httpClient.request<GetFeedItemDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: feedItemDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFeedItemFile(companyId: string, teamId: string, period: string, numberId: string, file: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<GetFeedItemDto>;
    public addFeedItemFile(companyId: string, teamId: string, period: string, numberId: string, file: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetFeedItemDto>>;
    public addFeedItemFile(companyId: string, teamId: string, period: string, numberId: string, file: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetFeedItemDto>>;
    public addFeedItemFile(companyId: string, teamId: string, period: string, numberId: string, file: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling addFeedItemFile.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling addFeedItemFile.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling addFeedItemFile.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling addFeedItemFile.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling addFeedItemFile.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/items/files`;
        return this.httpClient.request<GetFeedItemDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param partitionKey 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFeedItemFileForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, file: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<GetFeedItemDto>;
    public addFeedItemFileForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, file: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetFeedItemDto>>;
    public addFeedItemFileForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, file: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetFeedItemDto>>;
    public addFeedItemFileForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, file: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling addFeedItemFileForPartition.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling addFeedItemFileForPartition.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling addFeedItemFileForPartition.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling addFeedItemFileForPartition.');
        }
        if (partitionKey === null || partitionKey === undefined) {
            throw new Error('Required parameter partitionKey was null or undefined when calling addFeedItemFileForPartition.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling addFeedItemFileForPartition.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/partitions/${this.configuration.encodeParam({name: "partitionKey", value: partitionKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items/files`;
        return this.httpClient.request<GetFeedItemDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param partitionKey 
     * @param feedItemDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addFeedItemForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, feedItemDto: UpdateFeedItemDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<GetFeedItemDto>;
    public addFeedItemForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, feedItemDto: UpdateFeedItemDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetFeedItemDto>>;
    public addFeedItemForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, feedItemDto: UpdateFeedItemDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetFeedItemDto>>;
    public addFeedItemForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, feedItemDto: UpdateFeedItemDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling addFeedItemForPartition.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling addFeedItemForPartition.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling addFeedItemForPartition.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling addFeedItemForPartition.');
        }
        if (partitionKey === null || partitionKey === undefined) {
            throw new Error('Required parameter partitionKey was null or undefined when calling addFeedItemForPartition.');
        }
        if (feedItemDto === null || feedItemDto === undefined) {
            throw new Error('Required parameter feedItemDto was null or undefined when calling addFeedItemForPartition.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/partitions/${this.configuration.encodeParam({name: "partitionKey", value: partitionKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items`;
        return this.httpClient.request<GetFeedItemDto>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: feedItemDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param feedItemId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<FeedPartitionDto>;
    public deleteFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<FeedPartitionDto>>;
    public deleteFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<FeedPartitionDto>>;
    public deleteFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling deleteFeedItem.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling deleteFeedItem.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling deleteFeedItem.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling deleteFeedItem.');
        }
        if (feedItemId === null || feedItemId === undefined) {
            throw new Error('Required parameter feedItemId was null or undefined when calling deleteFeedItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'application/xml',
                'text/xml'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/items/${this.configuration.encodeParam({name: "feedItemId", value: feedItemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<FeedPartitionDto>('delete', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param skip 
     * @param take 
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paginateFeedItems(companyId: string, teamId: string, period: string, numberId: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<PagedDataDtoGetFeedItemDto>;
    public paginateFeedItems(companyId: string, teamId: string, period: string, numberId: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PagedDataDtoGetFeedItemDto>>;
    public paginateFeedItems(companyId: string, teamId: string, period: string, numberId: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PagedDataDtoGetFeedItemDto>>;
    public paginateFeedItems(companyId: string, teamId: string, period: string, numberId: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling paginateFeedItems.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling paginateFeedItems.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling paginateFeedItems.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling paginateFeedItems.');
        }
        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling paginateFeedItems.');
        }
        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling paginateFeedItems.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (skip !== undefined && skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>skip, 'skip');
        }
        if (take !== undefined && take !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>take, 'take');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/items`;
        return this.httpClient.request<PagedDataDtoGetFeedItemDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param partitionKey 
     * @param skip 
     * @param take 
     * @param filter 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public paginateFeedItemsForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<PagedDataDtoGetFeedItemDto>;
    public paginateFeedItemsForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<PagedDataDtoGetFeedItemDto>>;
    public paginateFeedItemsForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<PagedDataDtoGetFeedItemDto>>;
    public paginateFeedItemsForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, skip: number, take: number, filter?: 'comments' | 'links' | 'attachments' | 'userGenerated' | 'systemGenerated', observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling paginateFeedItemsForPartition.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling paginateFeedItemsForPartition.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling paginateFeedItemsForPartition.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling paginateFeedItemsForPartition.');
        }
        if (partitionKey === null || partitionKey === undefined) {
            throw new Error('Required parameter partitionKey was null or undefined when calling paginateFeedItemsForPartition.');
        }
        if (skip === null || skip === undefined) {
            throw new Error('Required parameter skip was null or undefined when calling paginateFeedItemsForPartition.');
        }
        if (take === null || take === undefined) {
            throw new Error('Required parameter take was null or undefined when calling paginateFeedItemsForPartition.');
        }

        let localVarQueryParameters = new HttpParams({encoder: this.encoder});
        if (skip !== undefined && skip !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>skip, 'skip');
        }
        if (take !== undefined && take !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>take, 'take');
        }
        if (filter !== undefined && filter !== null) {
          localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
            <any>filter, 'filter');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/partitions/${this.configuration.encodeParam({name: "partitionKey", value: partitionKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/items`;
        return this.httpClient.request<PagedDataDtoGetFeedItemDto>('get', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                params: localVarQueryParameters,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param partitionKey 
     * @param lastFeedItem 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserStateViewedForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, lastFeedItem: FeedItemReferenceDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<object>;
    public setUserStateViewedForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, lastFeedItem: FeedItemReferenceDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<object>>;
    public setUserStateViewedForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, lastFeedItem: FeedItemReferenceDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<object>>;
    public setUserStateViewedForPartition(companyId: string, teamId: string, period: string, numberId: string, partitionKey: string, lastFeedItem: FeedItemReferenceDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling setUserStateViewedForPartition.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling setUserStateViewedForPartition.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling setUserStateViewedForPartition.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling setUserStateViewedForPartition.');
        }
        if (partitionKey === null || partitionKey === undefined) {
            throw new Error('Required parameter partitionKey was null or undefined when calling setUserStateViewedForPartition.');
        }
        if (lastFeedItem === null || lastFeedItem === undefined) {
            throw new Error('Required parameter lastFeedItem was null or undefined when calling setUserStateViewedForPartition.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'application/xml',
                'text/xml'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/partitions/${this.configuration.encodeParam({name: "partitionKey", value: partitionKey, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/user-states/current-user/views`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: lastFeedItem,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param lastFeedItem 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public setUserViewedState(companyId: string, teamId: string, period: string, numberId: string, lastFeedItem: FeedItemReferenceDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<object>;
    public setUserViewedState(companyId: string, teamId: string, period: string, numberId: string, lastFeedItem: FeedItemReferenceDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<object>>;
    public setUserViewedState(companyId: string, teamId: string, period: string, numberId: string, lastFeedItem: FeedItemReferenceDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<object>>;
    public setUserViewedState(companyId: string, teamId: string, period: string, numberId: string, lastFeedItem: FeedItemReferenceDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json' | 'application/xml' | 'text/xml', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling setUserViewedState.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling setUserViewedState.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling setUserViewedState.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling setUserViewedState.');
        }
        if (lastFeedItem === null || lastFeedItem === undefined) {
            throw new Error('Required parameter lastFeedItem was null or undefined when calling setUserViewedState.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json',
                'application/xml',
                'text/xml'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/user-states/current-user/views`;
        return this.httpClient.request<object>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: lastFeedItem,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param companyId 
     * @param teamId 
     * @param period 
     * @param numberId 
     * @param feedItemId 
     * @param feedItemDto 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, feedItemDto: UpdateFeedItemDto, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<GetFeedItemDto>;
    public updateFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, feedItemDto: UpdateFeedItemDto, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpResponse<GetFeedItemDto>>;
    public updateFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, feedItemDto: UpdateFeedItemDto, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<HttpEvent<GetFeedItemDto>>;
    public updateFeedItem(companyId: string, teamId: string, period: string, numberId: string, feedItemId: string, feedItemDto: UpdateFeedItemDto, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json' | 'text/json', context?: HttpContext, transferCache?: boolean}): Observable<any> {
        if (companyId === null || companyId === undefined) {
            throw new Error('Required parameter companyId was null or undefined when calling updateFeedItem.');
        }
        if (teamId === null || teamId === undefined) {
            throw new Error('Required parameter teamId was null or undefined when calling updateFeedItem.');
        }
        if (period === null || period === undefined) {
            throw new Error('Required parameter period was null or undefined when calling updateFeedItem.');
        }
        if (numberId === null || numberId === undefined) {
            throw new Error('Required parameter numberId was null or undefined when calling updateFeedItem.');
        }
        if (feedItemId === null || feedItemId === undefined) {
            throw new Error('Required parameter feedItemId was null or undefined when calling updateFeedItem.');
        }
        if (feedItemDto === null || feedItemDto === undefined) {
            throw new Error('Required parameter feedItemDto was null or undefined when calling updateFeedItem.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json',
                'text/json'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        let localVarTransferCache: boolean | undefined = options && options.transferCache;
        if (localVarTransferCache === undefined) {
            localVarTransferCache = true;
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Content-Type', httpContentTypeSelected);
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/api/companies/${this.configuration.encodeParam({name: "companyId", value: companyId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/teams/${this.configuration.encodeParam({name: "teamId", value: teamId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/periods/${this.configuration.encodeParam({name: "period", value: period, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/numbers/${this.configuration.encodeParam({name: "numberId", value: numberId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}/feed/items/${this.configuration.encodeParam({name: "feedItemId", value: feedItemId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<GetFeedItemDto>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: feedItemDto,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                transferCache: localVarTransferCache,
                reportProgress: reportProgress
            }
        );
    }

}

import { computed, Injectable } from "@angular/core";
import { MatDateFormats } from "@angular/material/core";

import { TeamContext } from "~services/contexts";
import { DATE_FORMATS } from "~shared/util/constants";
import { fixDateFormatForMoment, getMomentDateFormat } from "~shared/util/date-format-helpers";


declare type MatDateFormatsParse = MatDateFormats["parse"];
declare type MatDateFormatsDisplay = MatDateFormats["display"];

const defaultFormatDisplay: Omit<MatDateFormatsDisplay, "dateInput"> = {
    monthYearLabel: "MMM-YYYY",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "MMMM YYYY"
};

const MOMENT_DATE_FORMATS = DATE_FORMATS.map(fixDateFormatForMoment);

@Injectable()
export class MatDateFormatsProvider implements MatDateFormats {
    get parse(): MatDateFormatsParse {
        return this.parseInternal();
    }

    get display(): MatDateFormatsDisplay {
        return this.displayInternal();
    }

    private readonly dateFormat = computed(() =>
        getMomentDateFormat(this.teamContext.company()));

    private readonly parseInternal = computed<MatDateFormatsParse>(() => {
        const dateFormat = this.dateFormat();
        return {
            dateInput: [
                dateFormat,
                ...MOMENT_DATE_FORMATS.filter(f => f !== dateFormat),
            ]
        };
    });

    private readonly displayInternal = computed<MatDateFormatsDisplay>(() => ({
        ...defaultFormatDisplay,
        dateInput: this.dateFormat(),
    }));

    constructor(
        private readonly teamContext: TeamContext,
    ) { }
}

import { FormControl } from "@angular/forms";

export const DEFAULT_TRIGGER_AFTER_UPDATES = 3;
export const TRIGGER_AFTER_UPDATES_OPTIONS = [1, 2, 3, 4, 5];

export type TriggeredDiscussionType = "always" | "offtarget";

interface TriggeredDiscussionFormControls {
    triggerDiscussion: FormControl<boolean>;
    triggerDiscussionType: FormControl<TriggeredDiscussionType>;
    triggerDiscussionAfter: FormControl<number>;
}

export const getTriggeredDiscussionValue = (controls: TriggeredDiscussionFormControls): number | undefined =>
    !controls.triggerDiscussion.value ? undefined :
        controls.triggerDiscussionType.value === "always" ? 0 :
            controls.triggerDiscussionAfter.value;

export const bindTriggeredDiscussionValue = (controls: TriggeredDiscussionFormControls, detectProblemAfterUpdates: number | undefined) => {
    controls.triggerDiscussion.setValue(detectProblemAfterUpdates != null);
    controls.triggerDiscussionType.setValue(detectProblemAfterUpdates === 0 ? "always" : "offtarget");
    controls.triggerDiscussionAfter.setValue(detectProblemAfterUpdates || DEFAULT_TRIGGER_AFTER_UPDATES);
};

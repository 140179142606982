import { Injectable } from "@angular/core";
import { GetReportDto, PlanReportsApi, ReportRecordDetailDto } from "@api";
import { concatMap, from, Observable } from "rxjs";

import { toFiscalQuarter } from "~shared/commonfunctions";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";

import { BaseStateServiceWithRefresh } from "./base-state.service";
import { PeriodicItemReference } from "./comparators";
import { StateEvent } from "./state-shared";

export type ReportReference = PeriodicItemReference;

const isForTeam = (report: ReportRecordDetailDto, companyId: string, teamId: string): boolean => {
    const { company, team } = getDelegatedItemCompanyTeam(report);
    return company.id === companyId && team.id === teamId;
};

const isReferenceForTeam = (report: ReportReference, companyId: string, teamId: string): boolean =>
    report.companyId === companyId && report.teamId === teamId;

export type ReportStateEvent = StateEvent<ReportRecordDetailDto, ReportReference>;

@Injectable({
    providedIn: "root",
})
export class ReportStateService extends BaseStateServiceWithRefresh<ReportRecordDetailDto, ReportReference,
    GetReportDto | ReportRecordDetailDto | ReportReference> {

    constructor(private readonly planReportsApi: PlanReportsApi) {
        super();
    }

    eventsForTeam = (companyId: string, teamId: string) => this.eventsForFilter(
        item => isForTeam(item, companyId, teamId),
        item => isReferenceForTeam(item, companyId, teamId),
    );

    eventsForReports = (...reports: (GetReportDto | ReportRecordDetailDto)[]) => this.eventsForItems(reports);

    protected refreshItem = (item: ReportReference): Observable<ReportRecordDetailDto> =>
        this.planReportsApi.getReportRecords(
            item.companyId,
            item.teamId,
            toFiscalQuarter({ financialYear: item.financialYear, quarter: item.planningPeriod }),
            item.id,
        ).pipe(
            concatMap(records => from(records)),
        );

    protected toReference = (item: GetReportDto | ReportRecordDetailDto | ReportReference): ReportReference => {
        if ("companyId" in item) return item;
        const { company, team } = getDelegatedItemCompanyTeam(item);
        return {
            companyId: company.id,
            teamId: team.id,
            financialYear: item.financialYear,
            planningPeriod: item.planningPeriod,
            id: item.id,
            collectionPeriod: "week" in item ? item.week : undefined,
        };
    };

    protected getRefreshReference = (item: ReportReference): ReportReference => ({
        ...item,
        // As we refresh all records for a report at once, our reference should ignore the collection period.
        // This ensures that we don't get double-up on refresh requests.
        collectionPeriod: undefined,
    });

    protected compareReferences = (ref1: ReportReference, ref2: ReportReference): boolean =>
        ref1.companyId === ref2.companyId &&
        ref1.teamId === ref2.teamId &&
        ref1.financialYear === ref2.financialYear &&
        ref1.planningPeriod === ref2.planningPeriod &&
        ref1.id === ref2.id &&
        (ref1.collectionPeriod == null || ref2.collectionPeriod == null || ref1.collectionPeriod === ref2.collectionPeriod);
}

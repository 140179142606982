import { DBConfig } from "ngx-indexed-db";

export const dbConfig: DBConfig = {
    name: "Workfacta",
    version: 1,
    objectStoresMeta: [
        {
            store: "companies",
            storeConfig: { keyPath: "id", autoIncrement: false },
            storeSchema: [
                { name: "clientId", keypath: "clientId", options: { unique: false } },
            ],
        },
        {
            store: "teams",
            storeConfig: { keyPath: "id", autoIncrement: false },
            storeSchema: [
                { name: "companyId", keypath: "companyId", options: { unique: false } },
            ],
        },
    ],
};

import { Injectable } from "@angular/core";
import * as moment from "moment";

import { DURATION_FORMAT } from "~shared/util/constants";

const expectedFormats = [
    "h:m a",
    "H:m"
];

@Injectable({
    providedIn: "root"
})
export class TimeParserService {

    /**
     * Attempts to parse the supplied user-entered time string, returning a standard format if possible.
     *
     * @param timeString A user-entered string in any time format.
     * @returns The parsed string in HH:MM:SS format, or null if it cannot be parsed.
     */
    parseTime = (timeString: string): string | null => {
        const originalTimeString = timeString;
        timeString = timeString.trim();

        // Appends a zero to any single digit following a colon (e.g turns "1:3" into "1:30")
        timeString = timeString.replace(/:\d(?!\d)/g, s => s + "0");

        // Replaces exactly three time digits with a colon after the first digit (e.g turns "243" into "2:43" or "243 pm" into "2:43 pm")
        // This is required, as moment treats this as HHM, not HMM.
        timeString = timeString.replace(/^([1-9])(\d{2})(?!\d)/, (_, h, m) => `${h}:${m}`);
        return this.parseTimeInternal(timeString) ?? this.parseTimeInternal(originalTimeString);
    };

    private parseTimeInternal = (timeString: string): string | null => {
        const timeMoment = moment(timeString, expectedFormats);

        if (!timeMoment.isValid()) return null;
        return timeMoment.format(DURATION_FORMAT);
    };
}

import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { GetReportDto, ReportCollectionType, ReportRecordDto } from "@api";

import { ExpandedWeekData, expandWeekData } from "~shared/util/summary-component-helpers";

@Component({
    selector: "app-report-summary",
    templateUrl: "./report-summary.component.html",
    styleUrl: "./report-summary.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportSummaryComponent {
    readonly report = input.required<GetReportDto>();

    readonly weekData = computed(() => expandWeekData(this.report()));

    readonly latestWeek = computed(() => this.report().latestWeek ?? null);

    readonly isExternallyUpdated = computed(() => this.report().collectionType === ReportCollectionType.externallyUpdated);

    getCellClass = (data: ExpandedWeekData<ReportRecordDto>) => {
        switch (data.record?.reportIsSet) {
            case false: {
                const latestWeek = this.latestWeek();
                if (latestWeek == null || data.week > latestWeek) {
                    return "wf-report-summary-cell-update-required-future";
                }
                return "wf-report-summary-cell-update-required";
            }
            case true:
                return "wf-report-summary-cell-updated";
            case undefined:
            default:
                return "wf-report-summary-cell-empty";
        }
    };
}

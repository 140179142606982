/* eslint-disable @angular-eslint/no-input-rename */
/* eslint-disable max-classes-per-file */
import { SelectionModel } from "@angular/cdk/collections";
import { CdkTable } from "@angular/cdk/table";
import { Directive, HostBinding, HostListener, Input, OnDestroy, OnInit, Optional, SkipSelf } from "@angular/core";
import { filter, Observable, Subject, Subscription } from "rxjs";

import { environment } from "~/environments/environment";

@Directive({
    selector: "[wfTableFocus]"
})
export class TableFocusDirective<T = any> implements OnInit, OnDestroy {

    @Input("wfTableFocusDisabled") disabled = false;

    readonly onClear$: Observable<TableFocusDirective | undefined>;

    private readonly selection = new SelectionModel();
    private readonly onClearSub = new Subject<TableFocusDirective | undefined>();
    private readonly subscriptions = new Subscription();

    constructor(
        @Optional() private readonly table?: CdkTable<T>,
        @Optional() @SkipSelf() private readonly parent?: TableFocusDirective,
    ) {
        this.onClear$ = this.onClearSub;
    }

    ngOnInit(): void {
        if (this.parent) {
            this.subscriptions.add(
                this.parent.onClear$.pipe(
                    filter(src => src !== this)
                ).subscribe(src => {
                    this.selection.clear();
                    this.onClearSub.next(src);
                })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    focus = (index: number, item: T) => {
        const trackByOutput = this.getTrackByOutput(index, item);
        this.selection.select(trackByOutput);
        this.onClearSub.next(this);
        this.parent?.clear(this);
    };

    isFocused = (index: number, item: T): boolean => {
        const trackByOutput = this.getTrackByOutput(index, item);
        return this.selection.selected.includes(trackByOutput);
    };

    isEnabled = (): boolean => !this.disabled && environment.tableRowFocus;

    /**
     * Bubbles up an event to clear the selection.
     *
     * @param source The source of the event.
     */
    clear = (source?: TableFocusDirective) => {
        this.selection.clear();
        this.onClearSub.next(source);
        this.parent?.clear(this);
    };

    private getTrackByOutput = (index: number, item: T): unknown =>
        this.table?.trackBy ? this.table.trackBy(index, item) : item;
}

@Directive({
    selector: "tr[wfRowFocus],mat-row[wfRowFocus]",
})
export class TableRowFocusDirective<T> {

    @Input("wfRowFocus") item?: T;
    @Input("wfRowFocusIndex") index = 0;

    @HostBinding("class.wf-focusable-row")
    get isFocusable(): boolean {
        return this.tableFocus?.isEnabled() ?? false;
    }

    @HostBinding("class.wf-row-focused")
    get isFocused(): boolean {
        if (!this.item) return false;
        return this.tableFocus?.isFocused(this.index, this.item) ?? false;
    }

    constructor(
        @Optional() private readonly tableFocus?: TableFocusDirective<T>,
    ) {
    }

    @HostListener("click")
    selectRow = () => {
        if (!this.item) return;
        this.tableFocus?.focus(this.index, this.item);
    };
}

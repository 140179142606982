import { Component, EventEmitter, Output } from "@angular/core";
import { GetFeedItemDto } from "@api";

import { BaseFeedListItemDirective } from "./base-feed-list-item.directive";

@Component({
    selector: "app-feed-list-item",
    templateUrl: "./feed-list-item.component.html",
    styleUrls: ["./feed-list-item.component.scss"]
})
export class FeedListItemComponent extends BaseFeedListItemDirective<GetFeedItemDto> {
    @Output() itemChanged = new EventEmitter<void>();
}

import { Component, OnDestroy } from "@angular/core";
import { GuardsCheckEnd, NavigationCancel, NavigationEnd, NavigationError, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
    selector: "app-route-spinner",
    template: `<app-spinner *ngIf="isChangingRoute"></app-spinner>`,
})
export class RouteSpinnerComponent implements OnDestroy {

    isChangingRoute = true;

    private readonly subscriptions = new Subscription();

    constructor(private router: Router) {
        this.subscriptions.add(this.router.events.subscribe(event => {
            if (event instanceof GuardsCheckEnd) {
                this.isChangingRoute = true;
            } else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
                this.isChangingRoute = false;
            }
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
        this.isChangingRoute = false;
    }
}

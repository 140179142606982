import { Injectable } from "@angular/core";
import { GetNewsItemDto, NewsItemsApi } from "@api";
import { Observable } from "rxjs";

import { BaseStateServiceWithRefresh } from "./base-state.service";
import { ItemReference } from "./comparators";
import { StateEvent } from "./state-shared";

export type NewsReference = ItemReference;

const isNewsItemForTeam = (news: GetNewsItemDto, companyId: string, teamId: string): boolean =>
    news.currentTeam.company.id === companyId && news.currentTeam.id === teamId;

const isNewsItemReferenceForTeam = (news: NewsReference, companyId: string, teamId: string): boolean =>
    news.companyId === companyId && news.teamId === teamId;

export type NewsItemStateEvent = StateEvent<GetNewsItemDto, NewsReference>;

@Injectable({
    providedIn: "root",
})
export class NewsItemStateService extends BaseStateServiceWithRefresh<GetNewsItemDto, NewsReference> {

    constructor(private readonly newsItemsApi: NewsItemsApi) {
        super();
    }

    eventsForTeam = (companyId: string, teamId: string) => this.eventsForFilter(
        item => isNewsItemForTeam(item, companyId, teamId),
        item => isNewsItemReferenceForTeam(item, companyId, teamId),
    );

    eventsForNewsItems = (...newsItems: GetNewsItemDto[]) => this.eventsForItems(newsItems);

    protected refreshItem = (item: NewsReference): Observable<GetNewsItemDto> =>
        this.newsItemsApi.getNewsItem(item.companyId, item.teamId, item.id);

    protected toReference = (item: GetNewsItemDto | NewsReference): NewsReference =>
        "companyId" in item ? item : ({ companyId: item.currentTeam.company.id, teamId: item.currentTeam.id, id: item.id });

    protected compareReferences = (ref1: NewsReference, ref2: NewsReference): boolean =>
        ref1.companyId === ref2.companyId &&
        ref1.teamId === ref2.teamId &&
        ref1.id === ref2.id;
}

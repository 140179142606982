import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetActionDetailsDto, GetActionDto } from "@api";
import { Observable, Subject } from "rxjs";

import { HomepageDialog, HomepageEvent } from "../homepage-dialog.interface";
import { buildHomepageDialogConfig, HomepageDialogOptions } from "../homepage-dialog-shared";

declare type ActionDto = GetActionDto | GetActionDetailsDto;

interface ActionHomepageDialogData {
    action: ActionDto;
}

@Component({
    selector: "app-action-homepage-dialog",
    templateUrl: "./action-homepage-dialog.component.html",
    styleUrls: ["./action-homepage-dialog.component.scss"]
})
export class ActionHomepageDialogComponent implements HomepageDialog<ActionDto>, OnDestroy {

    readonly events$: Observable<HomepageEvent<ActionDto>>;

    action: ActionDto;

    private readonly eventsInternal = new Subject<HomepageEvent<ActionDto>>();

    constructor(
        private readonly dialogRef: MatDialogRef<ActionHomepageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: ActionHomepageDialogData,
    ) {
        this.events$ = this.eventsInternal.asObservable();
        this.action = data.action;
    }

    static open(dialog: MatDialog, action: ActionDto, options?: HomepageDialogOptions) {
        return dialog.open<ActionHomepageDialogComponent, ActionHomepageDialogData>(
            ActionHomepageDialogComponent,
            {
                data: { action },
                ...buildHomepageDialogConfig(options),
            });
    }

    ngOnDestroy(): void {
        this.eventsInternal.complete();
    }

    actionUpdated = (action: ActionDto) => {
        this.action = action;
        this.eventsInternal.next({ type: "updated", item: action });
    };

    actionDeleted = () => {
        this.eventsInternal.next({ type: "deleted", item: this.action });
        this.dialogRef.close();
    };
}

import { ComponentType, Overlay, OverlayContainer } from "@angular/cdk/overlay";
import { Location } from "@angular/common";
import { ANIMATION_MODULE_TYPE, Inject, Injectable, InjectionToken, Injector, Optional, SkipSelf, TemplateRef } from "@angular/core";
import { MAT_DIALOG_DEFAULT_OPTIONS, MAT_DIALOG_SCROLL_STRATEGY, MatDialog, MatDialogRef } from "@angular/material/dialog";

import { WfDialogConfig } from "./dialog-config";
import { WfDialogContainerComponent } from "./dialog-container/dialog-container.component";

export const WF_DIALOG_DEFAULT_OPTIONS = new InjectionToken<WfDialogConfig>(
    "wf-dialog-default-options",
);

/**
 * An extension of the `MatDialog` service that uses a custom dialog container.
 */
@Injectable({
    providedIn: "root",
})
export class WfDialog extends MatDialog {
    constructor(
        _overlay: Overlay,
        injector: Injector,
        /**
         * @deprecated `_location` parameter to be removed.
         * @breaking-change 10.0.0
         */
        @Optional() location: Location,
        @Optional() @Inject(MAT_DIALOG_DEFAULT_OPTIONS) _defaultOptions: WfDialogConfig,
        @Inject(MAT_DIALOG_SCROLL_STRATEGY) _scrollStrategy: any,
        @Optional() @SkipSelf() _parentDialog: MatDialog,
        /**
         * @deprecated No longer used. To be removed.
         * @breaking-change 15.0.0
         */
        _overlayContainer: OverlayContainer,
        /**
         * @deprecated No longer used. To be removed.
         * @breaking-change 14.0.0
         */
        @Optional()
        @Inject(ANIMATION_MODULE_TYPE)
        _animationMode?: "NoopAnimations" | "BrowserAnimations",
    ) {
        super(_overlay, injector, location, _defaultOptions, _scrollStrategy, _parentDialog, _overlayContainer, _animationMode);

        (this as any)._dialogContainerType = WfDialogContainerComponent;
    }

    open<T, D = any, R = any>(
        component: ComponentType<T>,
        config?: WfDialogConfig<D>,
    ): MatDialogRef<T, R>;

    open<T, D = any, R = any>(
        // eslint-disable-next-line @typescript-eslint/unified-signatures
        template: TemplateRef<T>,
        config?: WfDialogConfig<D>,
    ): MatDialogRef<T, R>;

    open<T, D = any, R = any>(
        // eslint-disable-next-line @typescript-eslint/unified-signatures
        template: ComponentType<T> | TemplateRef<T>,
        config?: WfDialogConfig<D>,
    ): MatDialogRef<T, R>;

    open<T, D = any, R = any>(
        componentOrTemplateRef: ComponentType<T> | TemplateRef<T>,
        config?: WfDialogConfig<D>,
    ): MatDialogRef<T, R> {
        config = { ...((this as any)._defaultOptions || new WfDialogConfig()), ...config };

        return super.open(componentOrTemplateRef, config);
    }
}

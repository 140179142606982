import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { AbstractSecurityStorage, AuthInterceptor, AuthModule } from "angular-auth-oidc-client";

import { environment } from "~/environments/environment";
import { HybridAuthStorageService } from "~services/auth-storage.service";

import { apiBaseURL } from "./api-config/app-api.config";

@NgModule({
    imports: [AuthModule.forRoot({
        config: {
            authority: environment.auth.authority,
            redirectUrl: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId: environment.auth.clientId,
            scope: "openid profile offline_access",
            responseType: "code",
            // If the time machine is enabled, do not do a silent renew. We will renew when a 401 is returned.
            // This is because the current date may jump around, and we don't want to be constantly refreshing the token.
            silentRenew: !environment.timeMachine,
            useRefreshToken: true,
            // Similarly, we want to skip auto-renew prior to the token expiring if time machine is enabled.
            renewTimeBeforeTokenExpiresInSeconds: environment.timeMachine ? undefined: 300,
            // This disables the check to ensure the time offset between client/server is not too far removed.
            // While this is a good security measure, if the time machine is enabled, the offset is expected to be
            // significant (and may vary), so we need to disable the check in this case.
            disableIatOffsetValidation: environment.timeMachine,
            // This is the maximum allowed time offset between the client and server. The default is two minutes,
            // but this caused problems for some clients, so this is set to five minutes instead.
            maxIdTokenIatOffsetAllowedInSeconds: 300,
            ignoreNonceAfterRefresh: true,
            autoUserInfo: false,
            secureRoutes: [apiBaseURL.baseURL],
            triggerAuthorizationResultEvent: true,
        }
    })],
    exports: [AuthModule],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: AbstractSecurityStorage, useClass: HybridAuthStorageService }
    ]
})
export class AuthConfigModule { }

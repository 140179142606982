import { trigger } from "@angular/animations";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { NumberRecordDetailDto, RequireNote, UpdateNumberResultDto } from "@api";
import { distinctUntilChanged, map, of, Subscription, switchMap } from "rxjs";

import { TeamContext } from "~services/contexts";
import { CaptureMethod } from "~shared/enums";
import { fadeExpandAnimationBuilder } from "~shared/util/animations";
import { NOTES_MAX_LENGTH } from "~shared/util/constants";
import { decimalValidator } from "~shared/util/custom-validators";
import { isNoteEnforcementEnabled } from "~shared/util/feature-helper";
import { getNumberTargetStatus, NumberStatus } from "~shared/util/number-helper";
import { valueAndChanges } from "~shared/util/util";

export interface NumberResultNotesData {
    record: NumberRecordDetailDto;
    result: number | undefined;
}

const getNotesValidators = (required: boolean): ValidatorFn[] => [
    ...(required ? [Validators.required] : []),
    Validators.maxLength(NOTES_MAX_LENGTH),
];

@Component({
    selector: "app-number-result-notes-dialog",
    templateUrl: "./number-result-notes-dialog.component.html",
    styleUrls: ["./number-result-notes-dialog.component.scss"],
    animations: [
        trigger("fadeExpand", fadeExpandAnimationBuilder()),
    ],
})
export class NumberResultNotesDialogComponent implements OnInit, OnDestroy, AfterViewInit {

    readonly maxNotesLength = NOTES_MAX_LENGTH;

    readonly resultControl = new FormControl<number | null>(null, [decimalValidator]);
    readonly notesControl = new FormControl<string | null>(null, getNotesValidators(false));

    readonly form = new FormGroup({
        result: this.resultControl,
        notes: this.notesControl,
    });

    readonly record: Readonly<NumberRecordDetailDto>;

    get targetStatus(): NumberStatus {
        return getNumberTargetStatus(this.resultControl.value, this.record?.recordTarget);
    }

    readonly workInstructionEnabled = this.teamContext.features.workInstructionEnabled;

    disableAnimations = true;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly teamContext: TeamContext,
        private readonly dialogRef: MatDialogRef<NumberResultNotesDialogComponent, UpdateNumberResultDto>,
        @Inject(MAT_DIALOG_DATA) data: NumberResultNotesData,
    ) {
        this.record = data.record;

        this.resultControl.setValue(data.result ?? null);
        this.notesControl.setValue(data.record.notes ?? null);

        if (!data.record.canEdit) {
            this.form.disable();
        } else if (data.record.captureMethod === CaptureMethod.calculated ||
            data.record.captureMethod === CaptureMethod.deployed ||
            data.record.dailyUpdateDefinition) {
            this.resultControl.disable();
        }
    }

    static open(dialog: MatDialog, record: NumberRecordDetailDto, result: number | undefined) {
        return dialog.open<NumberResultNotesDialogComponent, NumberResultNotesData, UpdateNumberResultDto>(
            NumberResultNotesDialogComponent,
            {
                width: "500px",
                data: { record, result },
            });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.teamContext.companyTeam$.pipe(
            map(isNoteEnforcementEnabled),
            map(enabled => enabled ? this.record.requireNote : RequireNote.never),
            distinctUntilChanged(),
            switchMap(requireNote => {
                switch (requireNote) {
                    case RequireNote.whenOffTarget:
                        return valueAndChanges(this.resultControl).pipe(
                            map(result => {
                                const targetStatus = getNumberTargetStatus(result, this.record.recordTarget);
                                return targetStatus === "offtarget";
                            }),
                            distinctUntilChanged(),
                        );
                    case RequireNote.always:
                        return of(true);
                    case RequireNote.never:
                    default:
                        return of(false);
                }
            }),
        ).subscribe(noteRequired => {
            this.notesControl.setValidators(getNotesValidators(noteRequired));
            this.notesControl.updateValueAndValidity();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.disableAnimations = false;
    }

    save = () => {
        if (!this.form.valid) return;

        this.dialogRef.close({
            result: this.resultControl.value ?? undefined,
            notes: this.notesControl.value ?? "",
        });
    };
}

import { HttpErrorResponse } from "@angular/common/http";
import { Inject, Injectable, OnDestroy } from "@angular/core";
import {
    CurrentUserDto,
    EnvironmentSettingsDto,
    UserApi
} from "@api";
import { TranslateService } from "@ngx-translate/core";
import { AuthOptions, AuthStateResult, EventTypes, OidcSecurityService, PublicEventsService } from "angular-auth-oidc-client";
import * as moment from "moment";
import { BehaviorSubject, catchError, concat, defer, EMPTY, filter, last, map, Observable, of, share, shareReplay, skip, Subscription, switchMap, tap } from "rxjs";

import { environment } from "~/environments/environment";
import { saveFixedDate } from "~/utils/time-machine";
import { PeriodRepository, WeekRepository } from "~repositories";
import { withRefresh } from "~shared/util/rx-operators";
import { IStore, PERSISTENT_STORE } from "~stores";

import { NotificationService } from "./notification.service";
import { SettingsService } from "./settings.service";

@Injectable({
    providedIn: "root"
})
export class UserService implements OnDestroy {

    /**
     * The current user. Replayed for late subscribers.
     */
    readonly user$: Observable<CurrentUserDto | null>;

    get isAuthenticated(): boolean {
        return this.isAuthenticatedSubject.value;
    }

    private readonly refreshUserSubject = new BehaviorSubject<void>(undefined);

    private readonly _user$: Observable<CurrentUserDto | null>;
    private readonly isAuthenticatedSubject = new BehaviorSubject<boolean>(false);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly notificationService: NotificationService,
        private readonly userApi: UserApi,
        private readonly settingsService: SettingsService,
        private readonly translateService: TranslateService,
        private readonly weekRepository: WeekRepository,
        private readonly periodRepository: PeriodRepository,
        private readonly oidcSecurityService: OidcSecurityService,
        private readonly oidcEventsService: PublicEventsService,
        @Inject(PERSISTENT_STORE) private readonly persistentStores: IStore[],
    ) {
        this._user$ = this.buildUserObservable();

        this.user$ = this._user$.pipe(shareReplay({ bufferSize: 1, refCount: false }));
    }

    initialise = () => {
        this.subscriptions.add(this.user$.subscribe());
        this.subscriptions.add(
            this.oidcSecurityService.checkAuthIncludingServer().pipe(
                map(response => response.isAuthenticated),
                catchError(() => of(false)),
                switchMap((isAuthenticated, index) => {
                    // For the fist time login, we should clear stored data rather than doing a full logout
                    // This will ensure we don't double-reauthenticate.
                    if (index === 0 && !isAuthenticated && this.settingsService.currentUser) {
                        return this.clearPersistentStores().pipe(
                            map(() => isAuthenticated),
                        );
                    }
                    return of(isAuthenticated);
                }),
            ).subscribe((isAuthenticated) => this.isAuthenticatedSubject.next(isAuthenticated))
        );
        this.subscriptions.add(
            this.oidcEventsService.registerForEvents().pipe(
                filter(event =>
                    event.type === EventTypes.NewAuthenticationResult &&
                    !(event.value as AuthStateResult).isRenewProcess
                ),
                switchMap(() => this.userApi.getEnvironmentSettings().pipe(
                    catchError(_ => EMPTY),
                )),
            ).subscribe((settings) => {
                this.applySettings(settings);
            })
        );
    };

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    redirectToLogin = (returnUrl?: string | null) => this.redirectToAuthServerLogin(returnUrl);

    redirectToSignup = () => this.redirectToAuthServerLogin(null, /* prompt: */ "signup");

    /**
     * Called when the user must log in again, but has not requested to log out.
     *
     * @param returnUrl The url to redirect to after login.
     */
    reauthorize = (returnUrl?: string | null) => {
        this.clearPersistentStores().subscribe(() =>
            this.redirectToAuthServerLogin(returnUrl, /* prompt: */ "login"));
    };

    /**
     * Prompts the user to log out. This will redirect to the logout page on the auth server.
     */
    logout = () => this.oidcSecurityService.logoff().subscribe();

    refreshUser = () => this.refreshUserSubject.next();

    private buildUserObservable = () => this.isAuthenticatedSubject.pipe(
        skip(1),
        withRefresh(this.refreshUserSubject),
        switchMap(isLoggedIn => {
            if (!isLoggedIn) return of(null);
            return this.userApi.getUserDetails().pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse && error.status >= 400 && error.status < 500) {
                        // We have a permanent error - we should log out.
                        this.reauthorize();
                        return EMPTY;
                    }

                    const savedUser = this.settingsService.currentUser;
                    if (!savedUser) {
                        // While this is only a temporary error, without saved user details, we can't do anything.
                        this.notificationService.errorUnexpected();
                        this.reauthorize();
                        return EMPTY;
                    }

                    return of(savedUser);
                })
            );
        }),
        tap(user => {
            if (!user && this.settingsService.currentUser) {
                this.reauthorize();
            }
        }),
        tap(this.saveUser),
        share(),
    );

    private applySettings = (settings: EnvironmentSettingsDto) => {
        if (environment.timeMachine) {
            const initialDate = settings.timeMachineInitialDate;
            if (initialDate) {
                saveFixedDate(moment(initialDate));
            }
            this.weekRepository.clearCache();
            this.periodRepository.clearCache();
        }
    };

    private saveUser = (user: CurrentUserDto | null) => {
        this.settingsService.currentUser = user;
        this.translateService.use(user?.language || "en");
    };

    private redirectToAuthServerLogin = (returnUrl?: string | null, prompt?: "login" | "signup") => {
        this.settingsService.postLoginReturnUrl = returnUrl === "/" ? null : returnUrl ?? null;
        const options: AuthOptions = { customParams: {} };
        if (options.customParams && prompt) {
            options.customParams.prompt = prompt;
        }
        this.oidcSecurityService.authorize(undefined, options);
    };

    private clearPersistentStores = (): Observable<void> =>
        concat(
            defer(() => {
                this.settingsService.clear();
                return of(undefined);
            }).pipe(
                catchError(() => of(undefined)),
            ),
            ...this.persistentStores.map(repo => (repo.clear() || of(undefined)).pipe(
                catchError(() => of(undefined)),
            )),
        ).pipe(
            last(),
        );
}

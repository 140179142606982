import { Inject, Injectable, OnDestroy } from "@angular/core";
import { ActionReferenceDto, DiscussionReferenceDto, GoalRecordReferenceDto, GoalReferenceDto, NewsItemReferenceDto, NumberRecordReferenceDto, NumberReferenceDto, ReportRecordReferenceDto, ReportReferenceDto, UpdateEventDto, UpdateEventType } from "@api";
import { filter, Subscription } from "rxjs";

import { SOCKET_MANAGER, SocketManager } from "./socket-manager.service";
import { ActionStateService, DiscussionStateService, GoalStateService, NewsItemStateService, NumberStateService, ReportStateService } from "./state";

const filterUpdateEvents = (data: unknown): data is UpdateEventDto =>
    !!data && typeof data === "object" &&
    "eventType" in data && "reference" in data;

@Injectable()
export class UpdateNotificationOrchestrationService implements OnDestroy {

    private readonly subscriptions = new Subscription();

    constructor(
        @Inject(SOCKET_MANAGER) private readonly socketManager: SocketManager,
        private readonly actionStateService: ActionStateService,
        private readonly discussionStateService: DiscussionStateService,
        private readonly newsItemStateService: NewsItemStateService,
        private readonly goalStateService: GoalStateService,
        private readonly numberStateService: NumberStateService,
        private readonly reportStateService: ReportStateService,
    ) {
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initialise = () => {
        this.socketManager.initialise();
        this.subscriptions.add(this.socketManager.messages$.pipe(
            filter(filterUpdateEvents),
        ).subscribe(this.handleUpdateEvent));
    };

    private handleUpdateEvent = (event: UpdateEventDto) => {
        switch (event.reference.type) {
            case "action":
                this.handleActionUpdateEvent(event.eventType, event.reference);
                break;
            case "discussion":
                this.handleDiscussionUpdateEvent(event.eventType, event.reference);
                break;
            case "newsItem":
                this.handleNewsItemUpdateEvent(event.eventType, event.reference);
                break;
            case "goal":
            case "goalRecord":
                this.handleGoalRecordUpdateEvent(event.eventType, event.reference);
                break;
            case "number":
            case "numberRecord":
                this.handleNumberRecordUpdateEvent(event.eventType, event.reference);
                break;
            case "report":
            case "reportRecord":
                this.handleReportRecordUpdateEvent(event.eventType, event.reference);
                break;
        }
    };

    private handleActionUpdateEvent = (type: UpdateEventType, action: ActionReferenceDto) => {
        switch (type) {
            case "added":
                this.actionStateService.remoteAdd(action);
                break;
            case "updated":
                this.actionStateService.remoteUpdate(action);
                break;
            case "deleted":
                this.actionStateService.remoteDelete(action);
                break;
        }
    };

    private handleDiscussionUpdateEvent = (type: UpdateEventType, discussion: DiscussionReferenceDto) => {
        switch (type) {
            case "added":
                this.discussionStateService.remoteAdd(discussion);
                break;
            case "updated":
                this.discussionStateService.remoteUpdate(discussion);
                break;
            case "deleted":
                this.discussionStateService.remoteDelete(discussion);
                break;
        }
    };

    private handleNewsItemUpdateEvent = (type: UpdateEventType, newsItem: NewsItemReferenceDto) => {
        switch (type) {
            case "added":
                this.newsItemStateService.remoteAdd(newsItem);
                break;
            case "updated":
                this.newsItemStateService.remoteUpdate(newsItem);
                break;
            case "deleted":
                this.newsItemStateService.remoteDelete(newsItem);
                break;
        }
    };

    private handleGoalRecordUpdateEvent = (type: UpdateEventType, goal: GoalReferenceDto | GoalRecordReferenceDto) => {
        switch (type) {
            case "added":
                this.goalStateService.remoteAdd(goal);
                break;
            case "updated":
                this.goalStateService.remoteUpdate(goal);
                break;
            case "deleted":
                this.goalStateService.remoteDelete(goal);
                break;
        }
    };

    private handleNumberRecordUpdateEvent = (type: UpdateEventType, number: NumberReferenceDto | NumberRecordReferenceDto) => {
        switch (type) {
            case "added":
                this.numberStateService.remoteAdd(number);
                break;
            case "updated":
                this.numberStateService.remoteUpdate(number);
                break;
            case "deleted":
                this.numberStateService.remoteDelete(number);
                break;
        }
    };

    private handleReportRecordUpdateEvent = (type: UpdateEventType, report: ReportReferenceDto | ReportRecordReferenceDto) => {
        switch (type) {
            case "added":
                this.reportStateService.remoteAdd(report);
                break;
            case "updated":
                this.reportStateService.remoteUpdate(report);
                break;
            case "deleted":
                this.reportStateService.remoteDelete(report);
                break;
        }
    };
}

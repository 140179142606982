import "chartjs-adapter-moment";

import { HttpErrorResponse } from "@angular/common/http";
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from "@angular/core";
import { GetNumberDto, PlanningPeriodDetailsDto, PlanNumbersApi } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { Chart, ChartDataset, ChartOptions, ChartTypeRegistry, registerables } from "chart.js";
import * as moment from "moment";
import { EMPTY, Observable, of, throwError } from "rxjs";
import { catchError, finalize, map, switchMap } from "rxjs/operators";
import {
    calculateNumberData,
    calculateNumberPeriodData,
    combineNumberChartData,
    DataPoint,
    getNextPeriod,
    getPreviousPeriod,
    INumberChartData,
    INumberPeriod,
    IWeekInfo,
    WeekDataPoint
} from "src/app/shared/dialogs/number-chart-dialog/number-chart-calculation";

import { IQuarter, PeriodRepository } from "~repositories";
import { NotificationService } from "~services/notification.service";
import { averageLineStyle, resultLineStyle, targetLineStyle } from "~shared/chart-defaults";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { NumberType } from "~shared/enums";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { getNumberPrefix, getNumberSuffix } from "~shared/util/number-helper";

declare type CustomDataSet = ChartDataset<"line", DataPoint[]>;

const augmentNumberPeriodData = (numberPeriod: INumberPeriod) =>
    ({ period: numberPeriod.period, data: calculateNumberPeriodData(numberPeriod) });

@Component({
    selector: "app-number-chart",
    templateUrl: "./number-chart.component.html",
    styleUrls: ["./number-chart.component.scss"]
})
export class NumberChartComponent implements AfterViewInit, OnDestroy {

    @ViewChild("chart") chartEl?: ElementRef<HTMLCanvasElement>;

    @Input() set number(value: GetNumberDto) {
        this.numberInternal = value;
        if (!this.numberInternal) return;
        this.allData = calculateNumberData(this.numberInternal);
        this.latestPeriod = this.earliestPeriod = {
            financialYear: this.numberInternal.financialYear,
            quarter: this.numberInternal.planningPeriod
        };
        this.updateChart();
    }

    get loadingPrevious(): boolean {
        return this.loadingPreviousInternal;
    }

    set loadingPrevious(value: boolean) {
        this.loadingPreviousInternal = value;
        this.updateLoadingState();
    }

    get loadingNext(): boolean {
        return this.loadingNextInternal;
    }

    set loadingNext(value: boolean) {
        this.loadingNextInternal = value;
        this.updateLoadingState();
    }

    @Input() set maintainAspectRatio(value: boolean) {
        this.chartOptions.maintainAspectRatio = value;
        this.chart?.update();
    }

    @Input() set displaySubtitle(value: boolean) {
        if (this.chartOptions?.plugins?.title) {
            this.chartOptions.plugins.title.display = value;
            this.chart?.update();
        }
    }

    @Input() set displayLegend(value: boolean) {
        if (this.chartOptions?.plugins?.legend) {
            this.chartOptions.plugins.legend.display = value;
            this.chart?.update();
        }
    }

    private readonly chartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: this.translate.instant("numbers.chart.subtitle"),
                position: "bottom",
                align: "center"
            },
            legend: {
                labels: {
                    color: "black",
                    font: {
                        size: 15
                    },
                    filter: (item, data) => !!data.datasets[item.datasetIndex].label // hide datasets without a label
                },
            },
            tooltip: {
                callbacks: {
                    title: (items) => {
                        const value = items[0].raw as WeekDataPoint;
                        return this.translate.instant("numbers.chart.weekLabelWithDate", {
                            year: value.financialYear,
                            quarter: value.planningPeriod,
                            week: value.collectionPeriod,
                            date: moment(value.x).format("DD/MM")
                        });
                    },
                    label: (item) => {
                        const value = item.raw as WeekDataPoint;
                        return `${item.dataset.label}: ${this.formatNumber(value.y)}`;
                    }
                }
            },
        },
        datasets: {
            line: {
                tension: 0.4,
                fill: true,
            },
        },
        scales: {
            yAxis: {
                beginAtZero: true,
                grid: {
                    color: (context, options) =>
                        // If we are drawing a 0-line and the chart extends above and below, we should make this twice as dark
                        // Note: we check the chart bounds to make sure the line does not coincide with the border.
                        // If it does coincide with the border the border and we draw anyway, the border will appear darker.
                        context.tick.value === 0 && context.scale.ticks.some(t => t.value < 0) && context.scale.ticks.some(t => t.value > 0)
                            ? "rgba(0, 0, 0, 0.2)" // zero should be twice as dark
                            : options.borderColor as string, // by default, rgba(0, 0, 0, 0.1)
                },
                ticks: {
                    callback: value => this.formatNumber(value as number)
                }
            },
            xAxis: {
                type: "time",
                time: {
                    unit: "week",
                    isoWeekday: true
                },
                bounds: "data",
                ticks: {
                    source: "data",
                    callback: (_, i) => {
                        const week = this.allData?.weeks[i];
                        if (!week) return undefined;
                        const labelTokens = [
                            `W ${week.collectionPeriod}`,
                            week.startDate.clone().add(-1, "days").format("DD/MM")
                        ];
                        // We can return multiple lines, but the callback type does not support this.
                        return labelTokens as never;
                    }
                }
            }
        }
    };

    private numberInternal?: GetNumberDto;
    private allData?: INumberChartData;
    private earliestPeriod?: Readonly<IQuarter>;
    private latestPeriod?: Readonly<IQuarter>;
    private loadingPreviousInternal = false;
    private loadingNextInternal = false;

    private chart?: Chart<keyof ChartTypeRegistry, DataPoint[], IWeekInfo>;

    constructor(
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly periodRepository: PeriodRepository,
        private readonly notificationService: NotificationService,
        private readonly translate: TranslateService,
    ) {
        Chart.register(...registerables);
    }

    ngAfterViewInit() {
        this.buildChart();
    }

    ngOnDestroy() {
        this.chart?.destroy();
    }

    previous = () => {
        if (this.loadingPrevious || !this.earliestPeriod) return;
        this.loadingPrevious = true;
        this.getPreviousPeriod(this.earliestPeriod).pipe(
            switchMap(this.getNumberForPeriod),
            map(augmentNumberPeriodData),
            catchError(this.handleUnexpectedError),
            finalize(() => this.loadingPrevious = false)
        ).subscribe(({ period, data }) => {
            this.earliestPeriod = { financialYear: period.financialYear, quarter: period.index };
            this.allData = this.allData ? combineNumberChartData(data, this.allData) : data;
            this.updateChart();
        });
    };

    next = () => {
        if (this.loadingNext || !this.latestPeriod) return;
        this.loadingNext = true;
        this.getNextPeriod(this.latestPeriod).pipe(
            switchMap(this.getNumberForPeriod),
            map(augmentNumberPeriodData),
            catchError(this.handleUnexpectedError),
            finalize(() => this.loadingNext = false)
        ).subscribe(({ period, data }) => {
            this.latestPeriod = { financialYear: period.financialYear, quarter: period.index };
            this.allData = this.allData ? combineNumberChartData(this.allData, data) : data;
            this.updateChart();
        });
    };

    private handleUnexpectedError = () => {
        this.notificationService.errorUnexpected();
        return EMPTY;
    };

    private getPreviousPeriod = (currentQuarter: IQuarter): Observable<PlanningPeriodDetailsDto> =>
        !this.numberInternal ? EMPTY :
            getPreviousPeriod(this.periodRepository, getDelegatedItemCompanyTeam(this.numberInternal).company.id, currentQuarter);

    private getNextPeriod = (currentQuarter: IQuarter): Observable<PlanningPeriodDetailsDto> =>
        !this.numberInternal ? EMPTY :
            getNextPeriod(this.periodRepository, getDelegatedItemCompanyTeam(this.numberInternal).company.id, currentQuarter);

    private getNumberForPeriod = (period: PlanningPeriodDetailsDto): Observable<INumberPeriod> => {
        if (!this.numberInternal) return EMPTY;
        const { company, team } = getDelegatedItemCompanyTeam(this.numberInternal);
        return this.planNumbersApi.getNumberByGlobalId(
            company.id,
            team.id,
            toFiscalQuarter({ financialYear: period.financialYear, quarter: period.index }),
            this.numberInternal.globalId
        ).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse && err.status === 404) {
                    return of(null);
                }
                return throwError(err);
            }),
            map(number => ({ period, number }))
        );
    };

    private formatNumber = (value: number): string =>
        getNumberPrefix(this.numberInternal?.type ?? NumberType.normal) +
        new Intl.NumberFormat().format(value) +
        getNumberSuffix(this.numberInternal?.type ?? NumberType.normal);

    private buildChart = () => {
        if (!this.chartEl) return;
        if (!this.allData) return;

        this.chart = new Chart(this.chartEl.nativeElement, {
            type: "line",
            options: this.chartOptions,
            data: {
                datasets: this.buildChartDatasets(this.allData),
            }
        });
    };

    private updateChart = () => {
        if (!this.allData) return;
        if (!this.chart) {
            this.buildChart();
            return;
        }

        this.chart.data.datasets = this.buildChartDatasets(this.allData);
        this.chart.update("none"); // don't animate the change
    };

    private buildChartDatasets = (data: INumberChartData): CustomDataSet[] => {
        const datasets: CustomDataSet[] = [];
        datasets.push({
            ...resultLineStyle,
            label: this.translate.instant("numbers.weeklyResult"),
            data: data.results,
            normalized: true
        });

        const hasLowerBound = data.lowerBounds.some(b => b?.y !== null);
        const hasUpperBound = data.upperBounds.some(b => b?.y !== null);
        if (hasLowerBound) {
            datasets.push({
                ...targetLineStyle,
                label: this.translate.instant("numbers.chart.targetAbove"),
                data: data.lowerBounds,
                fill: hasUpperBound ? "+1" : "end",
                normalized: true,
            });
        }

        if (hasUpperBound) {
            datasets.push({
                ...targetLineStyle,
                label: this.translate.instant("numbers.chart.targetBelow"),
                data: data.upperBounds,
                fill: hasLowerBound ? "-1" : "start",
                normalized: true,
            });
        }

        datasets.push({
            ...averageLineStyle,
            label: this.translate.instant("numbers.chart.average"),
            data: data.averages,
            normalized: true,
        });

        return datasets;
    };

    private updateLoadingState = () => {
        if (this.loadingNext || this.loadingPrevious) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
    };
}

<div class="row" >
    <div class="col-sm-5">
        <mat-form-field>
            <mat-label>{{ 'numbers.externalData.externalData' | translate }}</mat-label>
            <mat-select disableOptionCentering [formControl]="retrieverInfoControl" name="retrieverInfo">
                <mat-option [value]="null">
                </mat-option>
                <mat-option *ngFor="let externalRetrieverKey of externalRetrieversEnabled" [value]="externalRetrieverKey">
                    {{ getExternalRetrieverKey(externalRetrieverKey) | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="col-sm-4" *ngIf="retrieveInfoType == 'xeroCashInBankAccountRetriever'">
        <app-xero-bank-account-selector [formControl]="xeroAccountControl" name="xeroAccount"></app-xero-bank-account-selector>
    </div>

    <div class="col-sm-3" *ngIf="externalDataSelected">
        <mat-form-field>
            <mat-label>{{ 'numbers.externalData.schedule' | translate }}</mat-label>
            <app-time-picker [intervalMins]="30" [formControl]="scheduleControl" name="externalDataSchedule" required></app-time-picker>
            <mat-error *ngIf="scheduleControl.errors?.required">
                {{ 'numbers.externalData.scheduleRequired' | translate }}
            </mat-error>
        </mat-form-field>
    </div>
</div>
import { PlanSize } from "@api";

export { PlanSize };

export enum PlanTierCode {
    free = "free",
    freeTest = "free_test",
    starter = "starter",
    upsellTest = "upsell_test",
    business = "business",
    premiumLegacy = "premium_legacy",
    businessPremium = "bus_prem",
    enterpriseLegacy = "enterprise_legacy",
    enterprise = "enterprise",
}

export const getPlanTierDescriptionKey = (code: PlanTierCode): string => {
    switch (code) {
        case PlanTierCode.free:
        case PlanTierCode.freeTest:
            return "planTierDesc.freeForever";
        case PlanTierCode.starter:
        case PlanTierCode.upsellTest:
            return "planTierDesc.starter";
        case PlanTierCode.business:
            return "planTierDesc.business";
        case PlanTierCode.premiumLegacy:
        case PlanTierCode.businessPremium:
            return "planTierDesc.businessPremium";
        case PlanTierCode.enterpriseLegacy:
        case PlanTierCode.enterprise:
            return "planTierDesc.enterprise";
        default: return "";
    }
};


export const getPlanSizeIcon = (planSize: PlanSize): string => {
    switch (planSize) {
        case PlanSize.xSmall:
            return "wf-plan-tier-xsmall";
        case PlanSize.small:
            return "wf-plan-tier-small";
        case PlanSize.medium:
            return "wf-plan-tier-medium";
        case PlanSize.large:
            return "wf-plan-tier-large";
        case PlanSize.xLarge:
            return "wf-plan-tier-xlarge";
        default:
            return "wf-plan-tier-large";
    }
};

<h4 mat-dialog-title>
    <wf-translate-template key="watchlists.addItemsDialog.title">
        <span class="list-name" *wfTransTplContent="'list'">{{ list.name }}</span>
    </wf-translate-template>
</h4>

<mat-dialog-content>
    <div class="search-scope">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ 'watchlists.addItemsDialog.team' | translate }}</mat-label>
            <app-auto-select [formControl]="teamControl" [options]="teams$ | async"
                [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData">
                <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
            </app-auto-select>
        </mat-form-field>
    
        <mat-form-field subscriptSizing="dynamic">
            <mat-label>{{ 'watchlists.addItemsDialog.itemType' | translate }}</mat-label>
            <mat-select [formControl]="typeControl">
                <mat-option value="action">
                    {{ 'actions.actions' | translate }}
                </mat-option>
                <mat-option value="goal">
                    {{ 'goals.goals' | translate }}
                </mat-option>
                <mat-option value="number">
                    {{ 'numbers.numbers' | translate }}
                </mat-option>
                <mat-option value="report">
                    {{ 'reports.reports' | translate }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    
        <app-quarter-selector *ngIf="typeControl.value !== 'action' && teamControl.value; let team"
            [companyId]="team.company.id" (selectedQuarterChange)="quarter = $event">
        </app-quarter-selector>
    
        <mat-checkbox *ngIf="typeControl.value === 'action'" [formControl]="showAllActionsControl">{{ 'showAll' | translate }}</mat-checkbox>
    </div>

    <ng-container *ngIf="typeControl.value === 'action'">
        <app-error-card *ngIf="actionsDataSource.loadFailed" (retry)="actionsDataSource.refresh()"
            [message]="'actions.loadFailed' | translate">
        </app-error-card>

        <div class="table-container" [hidden]="actionsDataSource.loadFailed" [wfTableScrollListener]="actionsTable">
            <table #actionsTable mat-table class="wf-table actions-table" [dataSource]="actionsDataSource" [trackBy]="trackById" #actionsSort="matSort"
                matSort matSortActive="dueDate" matSortDirection="asc" matSortDisableClear wfTableFocus>
                <ng-container matColumnDef="watched">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'watchlists.addItemsDialog.watched' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asActionRow(row); let row">
                            <mat-slide-toggle [formControl]="row.watchedControl"></mat-slide-toggle>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                        {{'actions.description' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" class="wf-column-heading">
                        <ng-container *ngIf="asActionRow(row); let row">
                            <span class="multiline-description">
                                {{ row.item.description }}
                            </span>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'actions.status' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asActionRow(row); let row">
                            <app-action-progress [progress]="row.item.progress"></app-action-progress>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-table-user-filter [team]="teamControl.value" (selected)="actionsDataSource.filter = $event?.userId ?? null" withSortHeader>
                            {{ 'actions.owner' | translate }}
                        </app-table-user-filter>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asActionRow(row); let row">
                            <app-delegated-user-display [entity]="row.item">
                                {{ getUserName(row.item.owner) }}
                                <wf-private-indicator *ngIf="row.item.isPrivateAction"></wf-private-indicator>
                            </app-delegated-user-display>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="creator">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'actions.creator' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asActionRow(row); let row">
                        {{ getUserName(row.item.creator) }}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="dueDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'actions.dueDate' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asActionRow(row); let row">
                        {{ row.item.dueDateLocal | dateFormat }}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'actions.department' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asActionRow(row); let row">
                        {{ row.item.department?.name }}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'actions.category' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asActionRow(row); let row">
                            {{ row.item.category?.description }}
                        </ng-container>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="actionColumns"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: actionColumns" [wfRowFocus]="row"
                    [wfRowFocusIndex]="i"></tr>

                <ng-container *matNoDataRow>
                    <tr class="mat-mdc-row" *ngIf="!actionsDataSource.isLoadingInitial">
                        <td [attr.colSpan]="actionColumns.length" class="mat-mdc-cell">
                            <span class="no-records">
                                {{'NoRecordsFound.noRecordsFound!' | translate}}
                            </span>
                        </td>
                    </tr>
                </ng-container>
        
                <ng-container *matFooterRowDef="[]">
                    <tr>
                        <td [attr.colSpan]="actionColumns.length">
                            <wf-table-scroll-footer [dataSource]="actionsDataSource"></wf-table-scroll-footer>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </ng-container>
    
    <ng-container *ngIf="typeControl.value === 'goal'">
        <app-error-card *ngIf="goalsState === 'failed'" (retry)="refreshGoals()"
            [message]="'goals.loadFailed' | translate">
        </app-error-card>

        <div class="table-container" *ngIf="goalsState !== 'failed'">
            <table mat-table class="wf-table goals-table" [dataSource]="goalsDataSource" [trackBy]="trackById" #goalsSort="matSort"
                matSort matSortActive="heading" matSortDirection="asc" matSortDisableClear wfTableFocus>
                <ng-container matColumnDef="watched">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'watchlists.addItemsDialog.watched' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asGoalRow(row); let row">
                            <mat-slide-toggle [formControl]="row.watchedControl"></mat-slide-toggle>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="heading">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                        {{'goals.heading' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" class="wf-column-heading">
                        <ng-container *ngIf="asGoalRow(row); let row">
                            <span class="multiline-description">
                                {{ row.item.heading }}
                            </span>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'goals.description' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asGoalRow(row); let row">
                            <div class="multiline-description">
                                {{ row.item.description }}
                            </div>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-table-user-filter [team]="teamControl.value" (selected)="goalsDataSource.filter = $event?.userId ?? ''" withSortHeader>
                            {{ 'goals.owner' | translate }}
                        </app-table-user-filter>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asGoalRow(row); let row">
                            <app-delegated-user-display [entity]="row.item">
                                {{ getUserName(row.item.owner) }}
                                <wf-private-indicator *ngIf="row.item.isPrivate"></wf-private-indicator>
                            </app-delegated-user-display>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'goals.department' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asGoalRow(row); let row">
                            {{row.item.department?.name}}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'goals.status' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asGoalRow(row); let row">
                            <ng-container *ngIf="row.item.latestStatus != null">
                                <app-goal-status [status]="row.item.latestCustomStatus ?? row.item.latestStatus"></app-goal-status>
                            </ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="statusSummary">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'goals.statusSummary' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <app-goal-status-summary *ngIf="asGoalRow(row); let row" [goal]="row.item"></app-goal-status-summary>
                    </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'goals.category' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asGoalRow(row); let row">
                            {{ row.item.category?.description }}
                        </ng-container>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="goalColumns"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: goalColumns" [wfRowFocus]="row"
                    [wfRowFocusIndex]="i"></tr>

                <ng-container *matNoDataRow>
                    <tr class="mat-mdc-row" *ngIf="goalsState !== 'loading'">
                        <td [attr.colSpan]="goalColumns.length" class="mat-mdc-cell">
                            <span class="no-records">
                                {{'NoRecordsFound.noRecordsFound!' | translate}}
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="typeControl.value === 'number'">
        <app-error-card *ngIf="numbersState === 'failed'" (retry)="refreshNumbers()"
            [message]="'numbers.loadFailed' | translate">
        </app-error-card>

        <div class="table-container" *ngIf="numbersState !== 'failed'">
            <table mat-table class="wf-table numbers-table" [dataSource]="numbersDataSource" [trackBy]="trackById" #numbersSort="matSort"
                matSort matSortActive="description" matSortDirection="asc" matSortDisableClear wfTableFocus>
                <ng-container matColumnDef="watched">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'watchlists.addItemsDialog.watched' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asNumberRow(row); let row">
                            <mat-slide-toggle [formControl]="row.watchedControl"></mat-slide-toggle>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                        {{'numbers.description' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" class="wf-column-heading">
                        <ng-container *ngIf="asNumberRow(row); let row">
                            <app-number-description-column [number]="row.item"></app-number-description-column>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="resultSummary">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ 'numbers.resultSummary' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <app-number-result-summary *ngIf="asNumberRow(row); let row" [number]="row.item"></app-number-result-summary>
                    </td>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-table-user-filter [team]="teamControl.value" (selected)="numbersDataSource.filter = $event?.userId ?? ''" withSortHeader>
                            {{ 'numbers.owner' | translate }}
                        </app-table-user-filter>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asNumberRow(row); let row">
                            <app-delegated-user-display [entity]="row.item">
                                {{ getUserName(row.item.owner) }}
                                <wf-private-indicator *ngIf="row.item.isPrivate"></wf-private-indicator>
                            </app-delegated-user-display>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="updater">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'numbers.updater' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asNumberRow(row); let row">
                            <app-delegated-user-display [entity]="row.item" mode="updater" *ngIf="numberAllowsUpdater(row.item)">
                                {{ getUserName(row.item.updater) }}
                            </app-delegated-user-display>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'numbers.department' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asNumberRow(row); let row">
                            {{row.item.department?.name}}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'numbers.category' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asNumberRow(row); let row">
                            {{ row.item.category?.description }}
                        </ng-container>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="numberColumns"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: numberColumns" [wfRowFocus]="row"
                    [wfRowFocusIndex]="i"></tr>

                <ng-container *matNoDataRow>
                    <tr class="mat-mdc-row" *ngIf="numbersState !== 'loading'">
                        <td [attr.colSpan]="numberColumns.length" class="mat-mdc-cell">
                            <span class="no-records">
                                {{'NoRecordsFound.noRecordsFound!' | translate}}
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </ng-container>

    <ng-container *ngIf="typeControl.value === 'report'">
        <app-error-card *ngIf="reportsState === 'failed'" (retry)="refreshReports()"
            [message]="'reports.loadFailed' | translate">
        </app-error-card>

        <div class="table-container" *ngIf="reportsState !== 'failed'">
            <table mat-table class="wf-table reports-table" [dataSource]="reportsDataSource" [trackBy]="trackById" #reportsSort="matSort"
                matSort matSortActive="description" matSortDirection="asc" matSortDisableClear wfTableFocus>
                <ng-container matColumnDef="watched">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'watchlists.addItemsDialog.watched' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asReportRow(row); let row">
                            <mat-slide-toggle [formControl]="row.watchedControl"></mat-slide-toggle>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                        {{'reports.description' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row" class="wf-column-heading">
                        <ng-container *ngIf="asReportRow(row); let row">
                            <span class="multiline-description">
                                {{ row.item.description }}
                            </span>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="reportSummary">
                    <th mat-header-cell *matHeaderCellDef>
                        {{'reports.reportSummary' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <app-report-summary *ngIf="asReportRow(row); let row" [report]="row.item"></app-report-summary>
                    </td>
                </ng-container>
                <ng-container matColumnDef="owner">
                    <th mat-header-cell *matHeaderCellDef>
                        <app-table-user-filter [team]="teamControl.value" (selected)="reportsDataSource.filter = $event?.userId ?? ''" withSortHeader>
                            {{ 'reports.owner' | translate }}
                        </app-table-user-filter>
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asReportRow(row); let row">
                            <app-delegated-user-display [entity]="row.item">
                                {{ getUserName(row.item.owner) }}
                            </app-delegated-user-display>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="updater">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'reports.updater' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asReportRow(row); let row">
                            <app-delegated-user-display [entity]="row.item" mode="updater">
                                {{ getUserName(row.item.updater) }}
                            </app-delegated-user-display>
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'reports.department' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asReportRow(row); let row">
                            {{row.item.department?.name}}
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="category">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{'reports.category' | translate}}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="asReportRow(row); let row">
                            {{ row.item.category?.description }}
                        </ng-container>
                    </td>
                </ng-container>
        
                <tr mat-header-row *matHeaderRowDef="reportColumns"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: reportColumns" [wfRowFocus]="row"
                    [wfRowFocusIndex]="i"></tr>

                <ng-container *matNoDataRow>
                    <tr class="mat-mdc-row" *ngIf="reportsState !== 'loading'">
                        <td [attr.colSpan]="reportColumns.length" class="mat-mdc-cell">
                            <span class="no-records">
                                {{'NoRecordsFound.noRecordsFound!' | translate}}
                            </span>
                        </td>
                    </tr>
                </ng-container>
            </table>
        </div>
    </ng-container>
</mat-dialog-content>

import { SimpleCompanyDto, SimpleTeamDto } from "@api";

import { getDelegatedItemCompanyTeam, IDelegatedItem } from "~shared/util/delegation-helper";

export type Comparator<T1, T2 = T1> = (o1: T1, o2: T2) => boolean;
export type Filter<T> = (item: T) => boolean;

export interface ItemReference {
    readonly companyId: string;
    readonly teamId: string;
    readonly id: string;
}

export interface PeriodicItemReference extends ItemReference {
    readonly financialYear: number;
    readonly planningPeriod: number;
    readonly collectionPeriod?: number;
}

export interface ItemWithId {
    readonly id: string;
}

export interface ItemWithTeam extends ItemWithId {
    readonly company: SimpleCompanyDto;
    readonly team: SimpleTeamDto;
}

export declare type ItemWithIdAndDelegation = ItemWithId & IDelegatedItem;

export interface ItemWithIdAndWeek extends ItemWithIdAndDelegation {
    readonly financialYear: number;
    readonly planningPeriod: number;
    readonly week: number;
}

export const compareByTeamAndId: Comparator<ItemWithTeam> =
    (o1: ItemWithTeam, o2: ItemWithTeam) =>
        o1.company.id === o2.company.id && o1.team.id === o2.team.id && o1.id === o2.id;

export const compareReferenceByTeamAndId: Comparator<ItemWithTeam, ItemReference> =
    (o1: ItemWithTeam, o2: ItemReference) =>
        o1.company.id === o2.companyId && o1.team.id === o2.teamId && o1.id === o2.id;

export const compareByDelegatedTeamAndId: Comparator<ItemWithIdAndDelegation> =
    (o1: ItemWithIdAndDelegation, o2: ItemWithIdAndDelegation) => {
        const { company: c1, team: t1 } = getDelegatedItemCompanyTeam(o1);
        const { company: c2, team: t2 } = getDelegatedItemCompanyTeam(o2);
        return c1.id === c2.id && t1.id === t2.id && o1.id === o2.id;
    };

export const compareReferenceByDelegatedTeamAndId: Comparator<ItemWithIdAndDelegation, ItemReference> =
    (o1: ItemWithIdAndDelegation, o2: ItemReference) => {
        const { company, team } = getDelegatedItemCompanyTeam(o1);
        return company.id === o2.companyId && team.id === o2.teamId && o1.id === o2.id;
    };

export const compareByTeamIdAndWeek: Comparator<ItemWithIdAndWeek> =
    (o1: ItemWithIdAndWeek, o2: ItemWithIdAndWeek) => compareByDelegatedTeamAndId(o1, o2) &&
        o1.financialYear === o2.financialYear &&
        o1.planningPeriod === o2.planningPeriod &&
        o1.week === o2.week;

export const compareReferenceByTeamIdAndWeek: Comparator<ItemWithIdAndWeek, PeriodicItemReference> =
    (o1: ItemWithIdAndWeek, o2: PeriodicItemReference) => compareReferenceByDelegatedTeamAndId(o1, o2) &&
        o1.financialYear === o2.financialYear &&
        o1.planningPeriod === o2.planningPeriod &&
        (o1.week === o2.collectionPeriod || o2.collectionPeriod == null);

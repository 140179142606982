
import { trigger } from "@angular/animations";
import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CalculationRollingDefinitionDto, CategoryDetailDto, CompanyTeamNumberExternalDataDto, CurrentCompanyDto, CurrentTeamDto, DayOfWeek, EnterpriseNumbersApi, GetCalculationNumberDto, GetDailyChildNumberDto, GetDeployedChildNumberDto, GetEnterpriseNumberDto, GetNumberCalculationDefinitionDto, GetNumberDeploymentDefinitionDto, GetNumberDto, GetTeamUserResponsibilityDelegationDto, NumberDailyUpdateDefinitionDto, PeriodUnit, PlanNumbersApi, RecurrenceDto, RequireNote, SimpleCategoryDto, SimpleCompanyDto, SimpleCompanyTeamDto, SimpleDepartmentDto, SimpleNumberDto, SimpleSubCategoryDto, SimpleTeamDto, SimpleUserDto, UpdateNumberDto, UpdateScheduleDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { combineLatest, debounceTime, defer, distinctUntilChanged, filter, identity, map, Observable, of, startWith, Subscription, switchMap, tap } from "rxjs";

import { CategoryRepository, DepartmentRepository, IQuarter, TeamRepository, TeamSettingsRepository, UserRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { ErrorCode, WorkfactaError, wrapWorkfactaError } from "~shared/api-errors";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { AutoSelectGroup } from "~shared/components/auto-select";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { CalculationType, CaptureMethod, DelegationResponsibility, NumberEntryType, NumberTargetType, NumberType, PlanningStatus, UpdateScheduleType } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { fadeExpandAnimationBuilder, fadeInAnimationBuilder } from "~shared/util/animations";
import { retryWithDelay } from "~shared/util/caching";
import { decimalValidator, greaterThanControl, integerValidator, linkValidator } from "~shared/util/custom-validators";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isAdvancedCalculationsEnabled, isCalculatedNumbersEnabled, isDailyUpdatedNumbersEnabled, isDelegationEnabled, isDeployedNumbersEnabled, isExternalIntegrationsEnabled, isFlexibleSchedulingEnabled, isFlexibleSchedulingTeased, isNoteEnforcementEnabled, isTriggeredDiscussionsEnabled, isWorkInstructionEnabled } from "~shared/util/feature-helper";
import { setEnabledState } from "~shared/util/form-helper";
import { groupItemsByTeam } from "~shared/util/item-grouping";
import { sortNumberDefinition, supportsAddingNumbers } from "~shared/util/number-helper";
import { shareReplayUntil, tapFirst } from "~shared/util/rx-operators";
import { sortBoolean, sortMultiple, sortTeam } from "~shared/util/sorters";
import { compareTeams, getTeamSearchData } from "~shared/util/team-helper";
import { getCalculationTypeNameKey, getCaptureMethodNameKey, getDayOfWeekNameKey, getDelegationResponsibilityNameKey, getNumberTargetTypeNameKey, getNumberTypeNameKey, getPlanningStatusNameKey, getRequireNoteNameKey, getUpdateScheduleTypeNameKey } from "~shared/util/translation-helper";
import { bindTriggeredDiscussionValue, DEFAULT_TRIGGER_AFTER_UPDATES, getTriggeredDiscussionValue, TRIGGER_AFTER_UPDATES_OPTIONS, TriggeredDiscussionType } from "~shared/util/triggered-discussion-helper";
import { compareUsers, getUserName } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";

import { convertNumberForUpdate, NumberSettingsDto } from "./conversion-helpers";
import { bindDailyChildren, DailyChildrenForm, forAllDays, getDailyChildrenValue } from "./daily-children-helper";
import { bindDeployedChildren, DeployedChildForm, DeployedChildrenForm, forAllDeployed, getCompanyTeamId, getDeployedChildrenValue, getDeployedChildValue, updateDeployedChildForm } from "./deployed-children-helper";
import { hasLowerTarget, hasUpperTarget, sanitiseTarget } from "./target-helper";
import { bindWeeklyTargets, getWeekTargetsValue, revalidateUpperTargets, updateTargetDisabledState, WeekTargetsForm } from "./week-targets-helper";

export type NumberInput = Partial<Pick<GetNumberDto, "description" | "type">>;

type NumberDialogDto = GetNumberDto | { readonly parent: NumberSettingsDto; readonly child: GetDeployedChildNumberDto };

interface INumberDialogData {
    readonly number?: NumberDialogDto;
    isCopy?: boolean;
    team: SimpleCompanyTeamDto;
    financialYear: number;
    planningPeriod: number;
    readonly: boolean;
    input?: NumberInput;
}

export interface IAddNumberDialogData {
    company: CurrentCompanyDto | SimpleCompanyDto;
    team: CurrentTeamDto | SimpleTeamDto;
    financialYear: number;
    planningPeriod: number;
    input?: NumberInput;
}

const recurrenceDefinitionEqual = (left: RecurrenceDto, right: RecurrenceDto) =>
    left.type === right.type &&
    left.interval === right.interval &&
    left.index === right.index &&
    left.dayOfWeek === right.dayOfWeek &&
    left.referenceDate === right.referenceDate;

const updateDefinitionEqual = (left: UpdateScheduleDto, right: UpdateScheduleDto) =>
    left.type === right.type &&
    (
        left.recurrence === right.recurrence ||
        (!!left.recurrence && !!right.recurrence && recurrenceDefinitionEqual(left.recurrence, right.recurrence))
    );

const cloneSchedule = (schedule: UpdateScheduleDto): UpdateScheduleDto => ({
    type: schedule.type,
    recurrence: !schedule.recurrence ? undefined : {
        type: schedule.recurrence.type,
        interval: schedule.recurrence.interval,
        index: schedule.recurrence.index,
        dayOfWeek: schedule.recurrence.dayOfWeek,
        referenceDate: schedule.recurrence.referenceDate
    }
});

const scheduleValid = (schedule: UpdateScheduleDto): boolean => {
    if (schedule.type !== UpdateScheduleType.custom) return true;
    return !!schedule.recurrence;
};

const allCaptureMethods = [
    CaptureMethod.manual,
    CaptureMethod.automatic,
    CaptureMethod.calculated,
    CaptureMethod.deployed,
];

declare type CalculationSourceWarning = "number_type_mismatch" | "entry_type_mismatch";

declare type ExtendedScheduleType = UpdateScheduleType | "daily";

const allScheduleTypes: ExtendedScheduleType[] = [
    UpdateScheduleType.everyPeriod,
    UpdateScheduleType.everyMeeting,
    UpdateScheduleType.custom,
    "daily",
];

const fixUpdateSchedule = ({ type, recurrence }: { type?: ExtendedScheduleType; recurrence?: RecurrenceDto }): UpdateScheduleDto => ({
    type: type === "daily" ? UpdateScheduleType.everyPeriod : type,
    recurrence,
});

const defaultUpdateDays = [
    DayOfWeek.monday,
    DayOfWeek.tuesday,
    DayOfWeek.wednesday,
    DayOfWeek.thursday,
    DayOfWeek.friday,
];

const nonZero: ValidatorFn = control => {
    const value = control.value;
    if (value == null || value !== 0) return null;
    return { nonZero: true };
};

declare type RollingCalculationType = "none" | CalculationType;
declare type RollingDurationType = "week" | "month" | "instance";

type RollingDefinitionForm = FormGroup<{
    calculationType: FormControl<RollingCalculationType>;
    durationType: FormControl<RollingDurationType>;
    duration: FormControl<number>;
}>;

type CalulationNumberForm = FormGroup<{
    number: FormControl<SimpleNumberDto>;
    inverted: FormControl<boolean>;
    weekOffset: FormControl<number>;
    rollingDefinition: RollingDefinitionForm;
}>;

const buildRollingDefinitionForm = (def: CalculationRollingDefinitionDto | undefined): RollingDefinitionForm => {
    const durationType = !def ? "week" :
        def.duration.type === "dynamic" ? "instance" :
            def.duration.lengthUnit === PeriodUnit.month ? "month" : "week";
    const instances = !def ? 2 : def.duration.type === "dynamic" ? def.duration.instanceCount : def.duration.length;
    return new FormGroup({
        calculationType: new FormControl<RollingCalculationType>(def?.calculationType ?? "none", { nonNullable: true }),
        durationType: new FormControl(durationType, { nonNullable: true }),
        duration: new FormControl(instances, { nonNullable: true, validators: [Validators.required, Validators.min(1), integerValidator] }),
    });
};

declare type NumberFormInput = { number: SimpleNumberDto } & Partial<GetCalculationNumberDto>;

const buildCalculationNumberForm = (def: NumberFormInput, disabled: boolean): CalulationNumberForm => {
    const form = new FormGroup({
        number: new FormControl(def.number, { nonNullable: true, validators: Validators.required }),
        inverted: new FormControl(def.inverted ?? false, { nonNullable: true }),
        weekOffset: new FormControl(def.weekOffset ?? 0, { nonNullable: true, validators: [Validators.required, integerValidator] }),
        rollingDefinition: buildRollingDefinitionForm(def.rollingDefinition),
    });
    if (disabled) form.disable();
    return form;
};

@Component({
    templateUrl: "./edit-number-dialog.component.html",
    styleUrls: ["./edit-number-dialog.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
        trigger("fadeExpand", fadeExpandAnimationBuilder()),
    ],
})
export class EditNumberDialogComponent extends WithDestroy() implements OnInit, OnDestroy, AfterViewInit {

    buttonState: ButtonState;
    disableAnimations = true;

    readonly targetTypes = [
        NumberTargetType.aboveTarget,
        NumberTargetType.belowTarget,
        NumberTargetType.withinRange
    ];

    readonly planningStatuses = [
        PlanningStatus.draft,
        PlanningStatus.locked
    ];

    readonly numberTypes = [
        NumberType.normal,
        NumberType.currency,
        NumberType.percentage
    ];

    readonly entryTypes = [
        NumberEntryType.deltas,
        NumberEntryType.totals
    ];

    readonly calculationTypes = [
        CalculationType.sum,
        CalculationType.average,
        CalculationType.product,
    ];

    readonly dailyUpdateCalculationTypes = [
        CalculationType.sum,
        CalculationType.average,
        CalculationType.latest,
    ];

    readonly deploymentCalculationTypes = [
        CalculationType.sum,
        CalculationType.average,
    ];

    readonly rollingCalculationTypes: RollingCalculationType[] = [
        "none",
        CalculationType.sum,
        CalculationType.average,
    ];

    readonly rollingDurationTypes: RollingDurationType[] = [
        "week",
        "month",
        "instance",
    ];

    readonly updateDayOptions = [
        DayOfWeek.monday,
        DayOfWeek.tuesday,
        DayOfWeek.wednesday,
        DayOfWeek.thursday,
        DayOfWeek.friday,
        DayOfWeek.saturday,
        DayOfWeek.sunday,
    ];

    readonly requireNoteOptions = [
        RequireNote.never,
        RequireNote.whenOffTarget,
        RequireNote.always,
    ];

    readonly delegationResponsibilities = [
        DelegationResponsibility.ownership,
        DelegationResponsibility.update,
    ];

    readonly triggerDiscussionAfterUpdatesOptions = TRIGGER_AFTER_UPDATES_OPTIONS;

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;
    readonly users$: Observable<SimpleUserDto[]>;
    readonly captureMethods$: Observable<CaptureMethod[]>;
    readonly updateScheduleTypeOptions$: Observable<ExtendedScheduleType[]>;
    readonly departments$: Observable<SimpleDepartmentDto[]>;
    readonly categories$: Observable<CategoryDetailDto[] | null>;
    readonly subCategories$: Observable<SimpleSubCategoryDto[] | null>;
    readonly delegationTeams$: Observable<SimpleCompanyTeamDto[]>;
    readonly delegationUsers$: Observable<SimpleUserDto[]>;

    readonly unselectedNumbers$: Observable<GetEnterpriseNumberDto[]>;
    readonly availableDeployableTeams$: Observable<SimpleCompanyTeamDto[]>;

    readonly deployedTeamData$: Observable<{
        team: SimpleCompanyTeamDto;
        companyTeamId: string;
        childForm: DeployedChildForm;
        users$: Observable<SimpleUserDto[]>;
    }[]>;

    readonly descriptionControl = this.fb.control<string | null>(null, [Validators.required, Validators.maxLength(250)]);
    readonly teamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly quarterControl = this.fb.control<IQuarter | null>(null, [Validators.required]);
    readonly targetTypeControl = this.fb.nonNullable.control(NumberTargetType.aboveTarget, [Validators.required]);
    readonly targetLowerBoundControl = this.fb.control<number | null>(null, [decimalValidator]);
    readonly targetUpperBoundControl = this.fb.control<number | null>(null, [decimalValidator, greaterThanControl("targetLowerBound")]);
    readonly allowWeeklyTargetsControl = this.fb.nonNullable.control(false);
    readonly enteringTotalsControl = this.fb.nonNullable.control(false);
    readonly isRecurringControl = this.fb.nonNullable.control(true);
    readonly isPrivateControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionControl = this.fb.nonNullable.control(false);
    readonly triggerDiscussionTypeControl = this.fb.nonNullable.control<TriggeredDiscussionType>("offtarget");
    readonly triggerDiscussionAfterControl = this.fb.nonNullable.control<number>(DEFAULT_TRIGGER_AFTER_UPDATES);
    readonly numberTypeControl = this.fb.nonNullable.control(NumberType.normal, [Validators.required]);

    readonly scheduleTypeControl = this.fb.nonNullable.control<ExtendedScheduleType>(UpdateScheduleType.everyPeriod, [Validators.required]);
    readonly recurrenceControl = this.fb.nonNullable.control<RecurrenceDto | undefined>(undefined);

    readonly captureFrequencyControl = this.fb.group({
        type: this.scheduleTypeControl,
        recurrence: this.recurrenceControl
    });

    readonly dailyUpdateCalculationTypeControl = this.fb.nonNullable.control(CalculationType.sum, [Validators.required]);
    readonly updateDaysControl = this.fb.nonNullable.control([...defaultUpdateDays], [Validators.required, Validators.minLength(2)]);

    readonly dailyUpdateDefinitionControl = this.fb.group({
        calculationType: this.dailyUpdateCalculationTypeControl,
        days: this.updateDaysControl,
    });

    readonly calculationTypeControl = this.fb.nonNullable.control(CalculationType.sum, [Validators.required]);
    readonly calculationMultiplierControl = this.fb.control<number | null>(null, [decimalValidator, nonZero]);
    readonly calculationDivisorControl = this.fb.control<number | null>(null, [decimalValidator, nonZero]);
    readonly calculationNumbersControl = this.fb.array<CalulationNumberForm>([], { validators: Validators.required });

    readonly newCalculationNumberControl = this.fb.control<SimpleNumberDto | null>(null);

    readonly calculationDefinitionControl = this.fb.group({
        type: this.calculationTypeControl,
        multiplier: this.calculationMultiplierControl,
        divisor: this.calculationDivisorControl,
        numbers: this.calculationNumbersControl,
    });

    readonly deploymentCalculationTypeControl = this.fb.nonNullable.control(CalculationType.sum, Validators.required);
    readonly deploymentTeamsControl = this.fb.nonNullable.control<SimpleCompanyTeamDto[]>([], Validators.required);

    readonly newDeploymentTeamControl = this.fb.control<SimpleCompanyTeamDto | null>(null);

    readonly deploymentDefinitionControl = this.fb.group({
        calculationType: this.deploymentCalculationTypeControl,
        teams: this.deploymentTeamsControl,
    });

    readonly ownerControl = this.fb.nonNullable.control<SimpleUserDto | null>(null, [Validators.required]);
    readonly updaterControl = this.fb.nonNullable.control<SimpleUserDto | null>(null, [Validators.required]);
    readonly captureMethodControl = this.fb.nonNullable.control(CaptureMethod.manual, [Validators.required]);
    readonly categoryControl = this.fb.nonNullable.control<SimpleCategoryDto | CategoryDetailDto | null>(null);
    readonly subCategoryControl = this.fb.nonNullable.control<SimpleSubCategoryDto | null>(null, [Validators.required]);
    readonly numberStatusControl = this.fb.nonNullable.control(PlanningStatus.draft, [Validators.required]);
    readonly departmentControl = this.fb.nonNullable.control<SimpleDepartmentDto | null>(null);
    readonly externalDataControl = this.fb.nonNullable.control<CompanyTeamNumberExternalDataDto | undefined>({
        value: undefined, disabled: true
    });

    readonly requireNoteControl = this.fb.nonNullable.control(RequireNote.never, [Validators.required]);
    readonly workInstructionControl = this.fb.control<string | null>(null, [Validators.maxLength(1000)]);
    readonly workInstructionLinkControl = this.fb.control<string | null>(null, [linkValidator]);

    readonly weekTargetsControl: WeekTargetsForm = this.fb.group({});

    readonly isDelegatedControl = this.fb.nonNullable.control(false);
    readonly delegationTeamControl = this.fb.control<SimpleCompanyTeamDto | null>(null, [Validators.required]);
    readonly delegationAssigneeControl = this.fb.control<SimpleUserDto | null>(null, [Validators.required]);
    readonly delegationResponsibilityControl = this.fb.nonNullable.control(DelegationResponsibility.ownership);
    readonly delegationControl = this.fb.group({
        team: this.delegationTeamControl,
        assignee: this.delegationAssigneeControl,
        responsibility: this.delegationResponsibilityControl,
    });

    readonly dailyChildrenControl: DailyChildrenForm = this.fb.group({});

    readonly deployedChildrenControl: DeployedChildrenForm = this.fb.group({});

    readonly form = this.fb.group({
        description: this.descriptionControl,
        team: this.teamControl,
        quarter: this.quarterControl,
        targetType: this.targetTypeControl,
        targetLowerBound: this.targetLowerBoundControl,
        targetUpperBound: this.targetUpperBoundControl,
        allowWeeklyTargets: this.allowWeeklyTargetsControl,
        enteringTotals: this.enteringTotalsControl,
        isRecurring: this.isRecurringControl,
        isPrivate: this.isPrivateControl,
        triggerDiscussion: this.triggerDiscussionControl,
        triggerDiscussionType: this.triggerDiscussionTypeControl,
        triggerDiscussionAfter: this.triggerDiscussionAfterControl,
        numberType: this.numberTypeControl,
        captureFrequency: this.captureFrequencyControl,
        dailyUpdateDefinition: this.dailyUpdateDefinitionControl,
        owner: this.ownerControl,
        updater: this.updaterControl,
        captureMethod: this.captureMethodControl,
        calculationDefinition: this.calculationDefinitionControl,
        deploymentDefinition: this.deploymentDefinitionControl,
        category: this.categoryControl,
        subCategory: this.subCategoryControl,
        numberStatus: this.numberStatusControl,
        department: this.departmentControl,
        externalData: this.externalDataControl,
        requireNote: this.requireNoteControl,
        workInstruction: this.workInstructionControl,
        workInstructionLink: this.workInstructionLinkControl,
        weekTargets: this.weekTargetsControl,

        isDelegated: this.isDelegatedControl,
        delegation: this.delegationControl,

        dailyChildren: this.dailyChildrenControl,
        deployedChildren: this.deployedChildrenControl,
    });

    readonly calculationShowAdvancedControl = this.fb.nonNullable.control(false);

    weeks: number[] = [];

    readonly getUserName = getUserName;
    readonly getNumberTargetTypeNameKey = getNumberTargetTypeNameKey;
    readonly getNumberTypeNameKey = getNumberTypeNameKey;
    readonly getPlanningStatusNameKey = getPlanningStatusNameKey;
    readonly getCaptureMethodNameKey = getCaptureMethodNameKey;
    readonly getCalculationTypeNameKey = getCalculationTypeNameKey;
    readonly getDayOfWeekNameKey = getDayOfWeekNameKey;
    readonly getRequireNoteNameKey = getRequireNoteNameKey;
    readonly getDelegationResponsibilityNameKey = getDelegationResponsibilityNameKey;
    readonly compareTeams = compareTeams;
    readonly compareUsers = compareUsers;
    readonly getTeamSearchData = getTeamSearchData;

    get hasLowerTarget(): boolean {
        return hasLowerTarget(this.targetTypeControl.value);
    }

    get hasUpperTarget(): boolean {
        return hasUpperTarget(this.targetTypeControl.value);
    }

    get hasRecurrence(): boolean {
        return this.scheduleTypeControl.value === UpdateScheduleType.custom;
    }

    get lowerTargetSet(): boolean {
        const target = this.targetLowerBoundControl.value;
        return target !== null && target !== undefined;
    }

    get upperTargetSet(): boolean {
        const target = this.targetUpperBoundControl.value;
        return target !== null && target !== undefined;
    }

    get supportsEnteringDeltas(): boolean {
        return supportsAddingNumbers(this.numberTypeControl.value);
    }

    get captureMethodIsAutomatic(): boolean {
        return this.captureMethodControl.value === CaptureMethod.automatic;
    }

    get captureMethodIsCalculated(): boolean {
        return this.captureMethodControl.value === CaptureMethod.calculated;
    }

    get captureMethodIsDeployed(): boolean {
        return this.captureMethodControl.value === CaptureMethod.deployed;
    }

    readonly schedulingVisible$: Observable<boolean>;
    readonly schedulingEnabled$: Observable<boolean>;
    readonly integrationsEnabled$: Observable<boolean>;
    readonly noteEnforcementEnabled$: Observable<boolean>;
    readonly workInstructionEnabled$: Observable<boolean>;
    readonly delegationVisible$: Observable<boolean>;
    readonly triggeredDiscussionsEnabled$: Observable<boolean>;
    readonly advancedCalculationsEnabled$: Observable<boolean>;

    readonly isSuperAdmin = this.userContext.isSuperAdmin;

    private readonly number?: NumberDialogDto;

    private lastEnteringTotalsValue = false;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly enterpriseNumbersApi: EnterpriseNumbersApi,
        private readonly teamRepository: TeamRepository,
        private readonly userRepository: UserRepository,
        private readonly departmentRepository: DepartmentRepository,
        private readonly categoryRepository: CategoryRepository,
        private readonly teamSettingsRepository: TeamSettingsRepository,
        private readonly userContext: UserContext,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<EditNumberDialogComponent, GetNumberDto | GetDeployedChildNumberDto>,
        private readonly notificationService: NotificationService,
        private readonly fb: FormBuilder,
        private readonly translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) data: INumberDialogData
    ) {
        super();
        this.teamControl.setValue(data.team);
        this.number = data.isCopy ? undefined : data.number;
        this.quarterControl.setValue({
            financialYear: data.financialYear,
            quarter: data.planningPeriod,
        });

        const existingNumber = !this.number ? undefined :
            !("child" in this.number) ? this.number :
                // The child may have been created with an empty ID as a placeholder.
                // If so, we aren't really updating a number in this case.
                !this.number.child.id ? undefined : this.number.child;

        if (this.number) {
            const number = "child" in this.number ? this.number.parent : this.number;
            if (data.readonly || number.planningStatus === PlanningStatus.locked || number.isDelegated ||
                number.updateDay != null) {
                this.form.disable();
            } else {
                this.teamControl.disable();
                this.quarterControl.disable();
            }
        } else if (!this.teamContext.features.crossTeamFeaturesEnabled()) {
            this.teamControl.disable();
        }

        const companyTeamId$ = valueAndChanges(this.teamControl).pipe(
            filter(Boolean),
            map((team) => ({ companyId: team.company.id, teamId: team.id })),
            distinctUntilChanged((left, right) => left.companyId === right.companyId && left.teamId === right.teamId),
        );
        const companyId$ = companyTeamId$.pipe(
            map(({ companyId }) => companyId),
            distinctUntilChanged(),
        );

        this.teams$ = defer(() => this.teamRepository.getClientInstanceTeams(data.team.company.id));
        this.users$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.userRepository.getTeamMembers(companyId, teamId)),
            // If we are creating a new number (and not copying), choose a default owner/updater after loading team members.
            this.number ? identity : tapFirst(this.setDefaultOwnerUpdater),
            tap(this.availableUsersChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.departments$ = companyId$.pipe(
            switchMap(companyId => this.departmentRepository.getDepartments(companyId)),
            tap(this.availableDepartmentsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.categories$ = companyId$.pipe(
            switchMap(companyId => this.categoryRepository.getCategories(companyId)),
            tap(this.availableCategoriesChanged),
            // As we hide the categories field if we don't have any categories, we set to null to simplify view logic.
            map(categories => !categories.length ? null : categories),
            shareReplayUntil(this.destroyed$),
        );
        this.subCategories$ = valueAndChanges(this.categoryControl).pipe(
            switchMap(category => {
                if (!category) return of([]);
                if ("subCategories" in category) return of(category.subCategories);
                return this.categories$.pipe(
                    map(categories => categories?.find(c => c.id === category.id)?.subCategories ?? []),
                );
            }),
            tap(this.availableSubCategoriesChanged),
            // As we hide the subcategories field if we don't have any subcategories, we set to null to simplify view logic.
            map(subCategories => !subCategories.length ? null : subCategories),
            shareReplayUntil(this.destroyed$),
        );

        const allNumbers$ = combineLatest({
            companyId: companyId$,
            quarter: valueAndChanges(this.quarterControl).pipe(
                filter(Boolean),
            ),
        }).pipe(
            switchMap(({ companyId, quarter }) => this.enterpriseNumbersApi.getEnterpriseNumbersForPeriod(
                companyId, toFiscalQuarter(quarter)
            ).pipe(
                retryWithDelay(),
                startWith([]),
            )),
        );

        const availableNumbers$ = combineLatest({
            allNumbers: allNumbers$,
            isPrivate: valueAndChanges(this.isPrivateControl),
        }).pipe(
            map(({ allNumbers, isPrivate }) => {
                // We can't reference the delegated end of a number - we need to reference the main end.
                let availableNumbers = allNumbers.filter(n => !n.isDelegated);
                if (!isPrivate) {
                    // If the number is public, we can't reference private numbers.
                    availableNumbers = availableNumbers.filter(n => !n.isPrivate);
                }
                if (existingNumber) {
                    // We are updating a number. Ensure we cannot select ourselves.
                    availableNumbers = availableNumbers.filter(n =>
                        n.company.id !== existingNumber.company.id ||
                        n.team.id !== existingNumber.team.id ||
                        n.globalId !== existingNumber.globalId);
                    // Also ensure we don't include any numbers that are children of this number.
                    availableNumbers = availableNumbers.filter(n =>
                        !n.source ||
                        n.source.company.id !== existingNumber.company.id ||
                        n.source.team.id !== existingNumber.team.id ||
                        n.source.globalId !== existingNumber.globalId);
                }
                return availableNumbers;
            }),
            tap(this.availableCalculationNumbersChanged),
            map(numbers => numbers.sort(sortNumberDefinition.ascending())),
        );

        this.unselectedNumbers$ = combineLatest({
            availableNumbers: availableNumbers$,
            selectedNumbers: valueAndChanges(this.calculationNumbersControl)
        }).pipe(
            // Remove any numbers we have already selected
            map(({ availableNumbers, selectedNumbers }) => availableNumbers.filter(n =>
                !selectedNumbers.some(sn => sn.number && this.compareNumbers(sn.number, n)))),
            shareReplayUntil(this.destroyed$),
        );

        this.delegationTeams$ = companyTeamId$.pipe(
            switchMap(({ companyId, teamId }) => this.teamSettingsRepository.getDelegationTeams(companyId, teamId)),
            tap(this.availableDelegatedTeamsChanged),
            shareReplayUntil(this.destroyed$),
        );
        this.delegationUsers$ = valueAndChanges(this.delegationTeamControl).pipe(
            switchMap(team => !team ? of([]) : this.userRepository.getTeamMembers(team.company.id, team.id)),
            tap(this.availableDelegatedUsersChanged),
            shareReplayUntil(this.destroyed$),
        );

        const allDeployableTeams$ = combineLatest({
            delegationTeams: this.delegationTeams$,
            currentTeam: valueAndChanges(this.teamControl),
        }).pipe(
            map(({ delegationTeams, currentTeam }) => {
                if (!currentTeam) return delegationTeams;
                return [...delegationTeams, currentTeam].sort(sortTeam.ascending());
            }),
        );

        this.availableDeployableTeams$ = combineLatest({
            teams: allDeployableTeams$,
            selectedTeams: valueAndChanges(this.deploymentTeamsControl),
        }).pipe(
            map(({ teams, selectedTeams }) => teams.filter(t =>
                !selectedTeams.some(st => compareTeams(st, t)))),
            shareReplayUntil(this.destroyed$),
        );

        this.deployedTeamData$ = valueAndChanges(this.deploymentTeamsControl).pipe(
            tap(this.handleDeploymentTeamsChange),
            map(teams => teams.map(team => {
                const companyTeamId = getCompanyTeamId(team);
                const childForm = this.deployedChildrenControl.controls[companyTeamId];
                const users$ = this.userRepository.getTeamMembers(team.company.id, team.id).pipe(
                    tap(users => {
                        const ownerControl = childForm.controls.owner;
                        const currentOwner = ownerControl.value;
                        if (currentOwner && !users.some(x => compareUsers(x, currentOwner))) {
                            ownerControl.setValue(null);
                        }
                    }),
                    shareReplayUntil(this.destroyed$),
                );

                return {
                    team,
                    companyTeamId,
                    childForm,
                    users$,
                };
            })),
            shareReplayUntil(this.destroyed$),
        );

        this.schedulingVisible$ = this.teamContext.companyTeam$.pipe(
            map(ct => isFlexibleSchedulingEnabled(ct) || isFlexibleSchedulingTeased(ct)),
        );
        this.schedulingEnabled$ = this.teamContext.companyTeam$.pipe(map(isFlexibleSchedulingEnabled));

        this.integrationsEnabled$ = this.teamContext.companyTeam$.pipe(map(isExternalIntegrationsEnabled));

        this.captureMethods$ = this.teamContext.companyTeam$.pipe(
            map(team => ({
                calculatedEnabled: isCalculatedNumbersEnabled(team),
                deploymentEnabled: isDeployedNumbersEnabled(team),
            })),
            distinctUntilChanged((a, b) => a.calculatedEnabled === b.calculatedEnabled && a.deploymentEnabled === b.deploymentEnabled),
            map(({ calculatedEnabled, deploymentEnabled }) => {
                const prohibitedMethods = [
                    ...(calculatedEnabled ? [] : [CaptureMethod.calculated]),
                    ...(deploymentEnabled ? [] : [CaptureMethod.deployed]),
                ];
                if (!prohibitedMethods.length) return allCaptureMethods;
                return allCaptureMethods.filter(m => !prohibitedMethods.includes(m));
            }),
            tap(this.availableCaptureMethodsChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.updateScheduleTypeOptions$ = this.teamContext.companyTeam$.pipe(
            map(isDailyUpdatedNumbersEnabled),
            distinctUntilChanged(),
            map(dailyUpdatesEnabled => dailyUpdatesEnabled ? allScheduleTypes :
                allScheduleTypes.filter(t => t !== "daily")),
            tap(this.availableScheduleTypesChanged),
            shareReplayUntil(this.destroyed$),
        );

        this.noteEnforcementEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isNoteEnforcementEnabled),
            shareReplayUntil(this.destroyed$),
        );

        this.workInstructionEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isWorkInstructionEnabled),
            shareReplayUntil(this.destroyed$),
        );

        this.delegationVisible$ = this.teamContext.companyTeam$.pipe(
            map(isDelegationEnabled),
            switchMap(enabled => {
                // If the form is disabled, show the delegation section if enabled and set but do no further processing
                if (this.form.disabled) return of(enabled && !!existingNumber?.delegation);
                // Delegation is otherwise visible if enabled and there are some delegation teams set
                const visible$ = !enabled ? of(false) : this.delegationTeams$.pipe(map(teams => !!teams.length));
                return visible$.pipe(
                    tap(visible => {
                        if (!visible) {
                            this.isDelegatedControl.setValue(false);
                            this.isDelegatedControl.disable();
                            this.delegationControl.disable();
                        } else {
                            if (this.form.enabled) {
                                this.isDelegatedControl.enable();
                            }
                        }
                    }),
                    // While the delegation teams are loading, should be based on whether delegation settings currently exist.
                    startWith(!!existingNumber?.delegation),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );

        this.triggeredDiscussionsEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isTriggeredDiscussionsEnabled),
            shareReplayUntil(this.destroyed$),
        );

        this.advancedCalculationsEnabled$ = this.teamContext.companyTeam$.pipe(
            map(isAdvancedCalculationsEnabled),
            shareReplayUntil(this.destroyed$),
        );

        if (data.number) {
            this.bindNumber(data.number);
        } else if (data.input) {
            this.bindInput(data.input);
        }
    }

    static openForAdd(dialog: MatDialog, data: IAddNumberDialogData) {
        return this.openInternal(dialog, {
            number: undefined,
            team: {
                id: data.team.id,
                name: data.team.name,
                company: {
                    id: data.company.id,
                    name: data.company.name,
                    clientId: data.company.clientId,
                },
            },
            financialYear: data.financialYear,
            planningPeriod: data.planningPeriod,
            isCopy: false,
            readonly: false,
            input: data.input,
        });
    }

    static openForEdit(dialog: MatDialog, number: GetNumberDto, isCopy = false, readonly = false) {
        const { company, team } = isCopy ? getDelegatedItemCompanyTeam(number) : number;
        return this.openInternal(dialog, {
            number: number,
            team: {
                id: team.id,
                name: team.name,
                company,
            },
            financialYear: number.financialYear,
            planningPeriod: number.planningPeriod,
            isCopy: isCopy,
            readonly,
        });
    }

    static openForChildEdit(dialog: MatDialog, parent: NumberSettingsDto, child: GetDeployedChildNumberDto, readonly = false) {
        const { company, team } = child;
        return this.openInternal<GetDeployedChildNumberDto>(dialog, {
            number: { parent, child },
            team: {
                id: team.id,
                name: team.name,
                company: company,
            },
            financialYear: parent.financialYear,
            planningPeriod: parent.planningPeriod,
            isCopy: false,
            readonly,
        });
    }

    private static openInternal<TResponse = GetNumberDto>(dialog: MatDialog, data: INumberDialogData) {
        return dialog.open<EditNumberDialogComponent, INumberDialogData, TResponse>(EditNumberDialogComponent, {
            width: "1050px",
            data: data
        });
    }

    ngOnInit(): void {
        const isStandalone = !this.number || !("child" in this.number) && !this.number?.source;
        this.subscriptions.add(valueAndChanges(this.captureMethodControl).subscribe(this.handleCaptureMethodChange));
        if (isStandalone) {
            this.subscriptions.add(valueAndChanges(this.scheduleTypeControl).subscribe(this.handleScheduleTypeChange));
            this.subscriptions.add(valueAndChanges(this.updateDaysControl).subscribe(this.handleUpdateDaysChange));
            this.subscriptions.add(valueAndChanges(this.numberTypeControl).subscribe(this.handleNumberTypeChange));
            this.subscriptions.add(combineLatest({
                isOwnedByMe: valueAndChanges(this.ownerControl).pipe(
                    map(owner => owner?.userId === this.userContext.userId())),
                isUpdatedByMe: valueAndChanges(this.updaterControl).pipe(
                    map(updater => updater?.userId === this.userContext.userId())),
                isDelegated: valueAndChanges(this.isDelegatedControl),
                captureMethod: valueAndChanges(this.captureMethodControl),
            }).subscribe(({ isOwnedByMe, isUpdatedByMe, isDelegated, captureMethod }) => {
                if (!this.form.enabled) return;
                if ((isOwnedByMe || isUpdatedByMe) && !isDelegated &&
                    captureMethod !== CaptureMethod.automatic && captureMethod !== CaptureMethod.deployed) {
                    this.isPrivateControl.enable();
                } else {
                    this.isPrivateControl.disable();
                    this.isPrivateControl.setValue(false);
                }
            }));
        }
        this.subscriptions.add(valueAndChanges(this.isDelegatedControl).subscribe(isDelegated => {
            if (!isDelegated) {
                this.delegationControl.disable();
                this.delegationTeamControl.setValue(null);
                this.delegationAssigneeControl.setValue(null);
                this.updaterControl.setValidators([Validators.required]);
                forAllDays(this.dailyChildrenControl, (_, dayForm) => dayForm.controls.updater.setValidators([Validators.required]));
            } else {
                // When delegating the number, we don't need to specify an updater
                this.updaterControl.setValidators([]);
                forAllDays(this.dailyChildrenControl, (_, dayForm) => dayForm.controls.updater.setValidators([]));
                if (this.form.enabled) {
                    this.delegationControl.enable();
                    if (this.captureMethodControl.value !== CaptureMethod.manual) {
                        this.delegationResponsibilityControl.disable();
                    }
                }
            }
            this.updaterControl.updateValueAndValidity();
            forAllDays(this.dailyChildrenControl, (_, dayForm) => dayForm.controls.updater.updateValueAndValidity());
        }));

        if (isStandalone) {
            this.subscriptions.add(valueAndChanges(this.isPrivateControl).subscribe(isPrivate => {
                if (!this.form.enabled) return;
                const control = this.triggerDiscussionControl;
                if (control.disabled === isPrivate) return;
                if (isPrivate) {
                    // We cannot have a private goal with triggered discussions
                    control.setValue(false);
                    control.disable();
                } else {
                    control.enable();
                }
            }));
            this.subscriptions.add(valueAndChanges(this.triggerDiscussionControl).subscribe(this.handleTriggerDiscussionChange));
            this.subscriptions.add(valueAndChanges(this.triggerDiscussionTypeControl).subscribe(this.handleTriggerDiscussionTypeChange));
        }

        this.subscriptions.add(this.targetLowerBoundControl.valueChanges.subscribe(() => {
            this.targetUpperBoundControl.updateValueAndValidity();
        }));
        this.subscriptions.add(valueAndChanges(this.targetTypeControl).subscribe(this.handleTargetTypeChange));

        this.subscriptions.add(valueAndChanges(this.newCalculationNumberControl).pipe(
            filter(Boolean),
        ).subscribe(number => {
            this.calculationNumbersControl.push(buildCalculationNumberForm({ number }, this.calculationNumbersControl.disabled));
            this.newCalculationNumberControl.reset();
        }));

        this.subscriptions.add(valueAndChanges(this.calculationNumbersControl).pipe(
            map(numbers => numbers.some(n => n.weekOffset !== 0 || n.rollingDefinition && n.rollingDefinition?.calculationType !== "none"))
        ).subscribe(hasAdvancedCalculation => {
            // If we have an advanced calculation, ensure we are viewing advanced calculation settings
            if (hasAdvancedCalculation && !this.calculationShowAdvancedControl.value) {
                this.calculationShowAdvancedControl.setValue(true);
            }
            // We can only hide the advanced calculation settings if we don't have an advanced calculation
            setEnabledState(this.calculationShowAdvancedControl, !hasAdvancedCalculation);
        }));

        this.subscriptions.add(valueAndChanges(this.newDeploymentTeamControl).pipe(
            filter(Boolean),
        ).subscribe(team => {
            const currentTeams = this.deploymentTeamsControl.value;
            this.deploymentTeamsControl.setValue([...currentTeams, team].sort(sortTeam.ascending()));
            this.newDeploymentTeamControl.reset();
        }));

        if (this.form.enabled && isStandalone) {
            this.subscriptions.add(combineLatest({
                team: valueAndChanges(this.teamControl).pipe(
                    filter(Boolean),
                ),
                schedule: valueAndChanges(this.captureFrequencyControl).pipe(
                    map(fixUpdateSchedule),
                    filter(scheduleValid),
                    map(cloneSchedule), // Prevents mutations to the original schedule from breaking the distinct check
                    distinctUntilChanged(updateDefinitionEqual)
                ),
                quarter: valueAndChanges(this.quarterControl).pipe(
                    filter(Boolean),
                ),
            }).pipe(
                debounceTime(100),
                switchMap(({ team, schedule, quarter }) =>
                    this.planNumbersApi.getExpectedScheduleWeeks(
                        team.company.id,
                        team.id,
                        toFiscalQuarter(quarter),
                        schedule).pipe(retryWithDelay()))
            ).subscribe(weeks => {
                this.weeks = weeks.filter((v, i, a) => a.indexOf(v) === i); // Removes duplicates from the list
                // Add any weeks we have not previously seen to the form.
                bindWeeklyTargets(
                    this.weeks,
                    this.form.controls,
                    this.subscriptions);
                forAllDays(this.dailyChildrenControl, (_, dayForm) =>
                    bindWeeklyTargets(
                        this.weeks,
                        dayForm.controls,
                        this.subscriptions));
            }));
        }
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    ngAfterViewInit(): void {
        setTimeout(() => this.disableAnimations = false, 0);
    }

    save() {
        this.revalidateAllUpperTargets();
        if (!this.form.valid || this.buttonState) return;

        this.buttonState = "loading";

        if (this.number && "child" in this.number) {
            // When updating a child, we simply close and return the new child dto
            const childDto = this.getChildResponse(this.number.child);
            this.dialogRef.close(childDto);
            return;
        }

        const value = this.getFormValue();
        if (!value) {
            // This should be impossible, as this will only return null if the form is invalid
            this.buttonState = undefined;
            return;
        }

        const dto: UpdateNumberDto = convertNumberForUpdate(value);

        let obs$: Observable<GetNumberDto>;
        if (this.number) {
            obs$ = this.planNumbersApi.updateNumber(
                this.number.company.id,
                this.number.team.id,
                toFiscalQuarter({ financialYear: this.number.financialYear, quarter: this.number.planningPeriod }),
                this.number.id,
                dto
            );
        } else {
            const team = this.teamControl.value;
            obs$ = this.planNumbersApi.addNumber(
                team?.company.id ?? "",
                team?.id ?? "",
                toFiscalQuarter(this.quarterControl.value as IQuarter),
                dto
            );
        }

        obs$.pipe(wrapWorkfactaError()).subscribe({
            next: result => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(result);
                }, 1000);
            },
            error: error => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                if (error instanceof WorkfactaError) {
                    switch (error.status) {
                        case 409:
                            switch (error.code) {
                                case ErrorCode.planCapReached:
                                    this.form.setErrors({ capReached: true });
                                    return;
                                case ErrorCode.planCapExceeded:
                                    this.form.setErrors({ capExceeded: true });
                                    return;
                                case ErrorCode.calculationCircularReference:
                                    this.calculationNumbersControl.setErrors({ circularReference: true });
                                    return;
                            }
                    }
                }
                this.notificationService.errorUnexpected();
            },
        });
    }

    getDepartmentName = (department: SimpleDepartmentDto | null | undefined): string => department?.name ?? "";
    compareDepartments = (o1: SimpleDepartmentDto, o2: SimpleDepartmentDto) => o1.id === o2.id;
    getCategoryDisplay = (category: SimpleCategoryDto | CategoryDetailDto | null | undefined) => category?.description ?? "";
    compareCategories = (o1: SimpleCategoryDto | CategoryDetailDto, o2: SimpleCategoryDto | CategoryDetailDto) => o1.id === o2.id;
    getSubCategoryDisplay = (subCategory: SimpleSubCategoryDto | null | undefined): string => subCategory?.description ?? "";
    compareSubCategories = (o1: SimpleSubCategoryDto, o2: SimpleSubCategoryDto) => o1.id === o2.id;
    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined): string => team?.name ?? "";

    targetIsSet = (target: number | null | undefined): boolean => target !== null && target !== undefined;

    isCalculationTypeSum = (type: CalculationType): boolean => type === CalculationType.sum;
    isCalculationTypeProduct = (type: CalculationType): boolean => type === CalculationType.product;
    calculationCanInvert = (type: CalculationType): boolean => type === CalculationType.sum || type === CalculationType.product;

    removeDeployedChild = (team: SimpleCompanyTeamDto) => {
        const currentTeams = this.deploymentTeamsControl.value;
        const updatedTeams = currentTeams.filter(t => !compareTeams(t, team));
        if (updatedTeams.length === currentTeams.length) return;
        this.deploymentTeamsControl.setValue(updatedTeams);
    };

    editDeployedChild = (child: DeployedChildForm) => {
        const currentValue = this.getFormValue();
        if (!currentValue) return;
        const currentChildValue = getDeployedChildValue(child, this.targetTypeControl.value, this.weeks,
            this.scheduleTypeControl.value === "daily" ? this.updateDaysControl.value : undefined);

        EditNumberDialogComponent.openForChildEdit(this.dialog, currentValue, currentChildValue, /* readonly: */ this.form.disabled)
            .afterClosed().subscribe(result => {
                if (!result) return;
                updateDeployedChildForm(child, result);
            });
    };

    compareNumbers = (o1: SimpleNumberDto, o2: SimpleNumberDto) => {
        if (!o1 && !o2) return true;
        if (!o1 || !o2) return false;
        return o1.globalId === o2.globalId && o1.team.id === o2.team.id && o1.company.id === o2.company.id;
    };

    getNumberWarnings = (number: GetEnterpriseNumberDto): CalculationSourceWarning[] | null => {
        const warnings: CalculationSourceWarning[] = [];
        const numberType = this.numberTypeControl.value;
        const entryType = this.enteringTotalsControl.value ? NumberEntryType.totals : NumberEntryType.deltas;
        if (number.type !== numberType) warnings.push("number_type_mismatch");
        if (number.entryType !== entryType) warnings.push("entry_type_mismatch");
        return !warnings.length ? null : warnings;
    };

    getRollingCalculationTypeNameKey = (type: RollingCalculationType): string => {
        switch (type) {
            case "none":
                return "numbers.calculation.rollingType.none";
            case CalculationType.sum:
                return "numbers.calculation.rollingType.sum";
            case CalculationType.average:
                return "numbers.calculation.rollingType.average";
            default:
                return "";
        }
    };

    getRollingDurationTypeNameKey = (type: RollingDurationType, plural: boolean): string => {
        switch (type) {
            case "week":
                return plural ? "numbers.calculation.rollingDurationType.weeks" : "numbers.calculation.rollingDurationType.week";
            case "month":
                return plural ? "numbers.calculation.rollingDurationType.months" : "numbers.calculation.rollingDurationType.month";
            case "instance":
                return plural ? "numbers.calculation.rollingDurationType.instances" : "numbers.calculation.rollingDurationType.instance";
        }
    };

    getUpdateScheduleTypeNameKey = (type: ExtendedScheduleType): string => {
        if (type === "daily") return "numbers.dailyUpdates.daily";
        return getUpdateScheduleTypeNameKey(type);
    };

    getNumberDisplayFunc = (number: GetEnterpriseNumberDto | null | undefined): string => !number ? "" :
        number.description + (number.updateDay != null ? ` (${this.translate.instant(getDayOfWeekNameKey(number.updateDay))})` : "");

    getNumberSearchData = (number: GetEnterpriseNumberDto): string =>
        `${this.getNumberDisplayFunc(number)} ${number.team.name} ${number.company.name}`;

    groupNumbers = (numbers: GetEnterpriseNumberDto[]): AutoSelectGroup<GetEnterpriseNumberDto>[] =>
        groupItemsByTeam(numbers).map(t => ({
            id: `${t.company.id}_${t.team.id}`,
            name: `${t.company.name}: ${t.team.name}`,
            options: t.items,
        }));

    private bindNumber = (data: NumberDialogDto) => {
        const definition = "child" in data ? data.parent : data;
        const details = "child" in data ? data.child : data;

        this.bindDefinition(definition);
        this.bindDetails(details);

        if (!definition.canEdit) {
            this.form.disable();
            this.form.setErrors({ capExceeded: true });
        } else {
            if ("child" in data || data.source) {
                // Definition can only be set on the very root number
                this.disableDefinitionControls();
            }
            if (!("child" in data) && data.source) {
                // Targets of child numbers can only be edited from the parent context
                // As we don't have a child we're editing but we do have a source, it implies
                // we're not in the root context.
                this.disableTargetControls();
            }
        }
    };

    private bindDefinition = (n: NumberSettingsDto) => {
        this.descriptionControl.setValue(n.description);
        this.quarterControl.setValue({
            financialYear: n.financialYear,
            quarter: n.planningPeriod,
        });
        this.targetTypeControl.setValue(n.targetType);
        this.allowWeeklyTargetsControl.setValue(n.allowWeeklyTargetOverrides);
        this.enteringTotalsControl.setValue(n.entryType === NumberEntryType.totals);
        this.isRecurringControl.setValue(n.isRecurring);
        this.isPrivateControl.setValue(n.isPrivate);
        bindTriggeredDiscussionValue(this.form.controls, n.detectProblemAfterUpdates);
        this.numberTypeControl.setValue(n.type);
        this.scheduleTypeControl.setValue(
            n.dailyUpdateDefinition ? "daily" : (n.scheduleDefinition.type ?? UpdateScheduleType.everyPeriod));
        this.recurrenceControl.setValue(n.scheduleDefinition.recurrence);
        this.numberStatusControl.setValue(n.numberStatus);
        this.requireNoteControl.setValue(n.requireNote);
        this.workInstructionControl.setValue(n.workInstruction ?? null);
        this.workInstructionLinkControl.setValue(n.workInstructionLink ?? null);

        this.bindDailyUpdateDefinition(n.dailyUpdateDefinition);
    };

    private bindDetails = (n: NumberSettingsDto | GetDeployedChildNumberDto) => {
        this.targetLowerBoundControl.setValue(n.target.lowerBound ?? null);
        this.targetUpperBoundControl.setValue(n.target.upperBound ?? null);
        this.ownerControl.setValue(n.owner ?? null);
        this.updaterControl.setValue(n.updater ?? null);
        this.captureMethodControl.setValue(n.captureMethod);
        this.categoryControl.setValue(n.category ?? null);
        this.subCategoryControl.setValue(n.category?.subCategory ?? null);
        this.departmentControl.setValue(n.department ?? null);
        this.externalDataControl.setValue(n.externalData);

        this.bindDelegation(n.delegation);
        this.bindCalculationDefinition(n.calculationDefinition);
        this.bindDeploymentDefinition(n.deploymentDefinition);

        const weeks: number[] = [];
        for (const weekStr in n.weekTargets) {
            if (!Object.prototype.hasOwnProperty.call(n.weekTargets, weekStr)) continue;
            const week = parseInt(weekStr, 10);
            weeks.push(week);
        }
        this.weeks = weeks;
        bindWeeklyTargets(
            weeks,
            this.form.controls,
            this.subscriptions,
            n.weekTargets,
        );

        if (n.dailyChildren) {
            bindDailyChildren(
                this.updateDaysControl.value,
                weeks,
                this.form.controls,
                this.subscriptions,
                n.dailyChildren,
            );
        }

        if (n.deploymentDefinition && n.deployedChildren) {
            bindDeployedChildren(
                n.deploymentDefinition.teams,
                weeks,
                this.form.controls,
                this.subscriptions,
                n.deployedChildren,
            );
        }
    };

    private disableDefinitionControls = () => {
        this.descriptionControl.disable();
        this.teamControl.disable();
        this.quarterControl.disable();
        this.targetTypeControl.disable();
        this.allowWeeklyTargetsControl.disable();
        this.enteringTotalsControl.disable();
        this.isRecurringControl.disable();
        this.isPrivateControl.disable();
        this.triggerDiscussionControl.disable();
        this.triggerDiscussionTypeControl.disable();
        this.triggerDiscussionAfterControl.disable();
        this.numberTypeControl.disable();
        this.captureFrequencyControl.disable();
        this.dailyUpdateDefinitionControl.disable();
        this.numberStatusControl.disable();
        this.requireNoteControl.disable();
        this.workInstructionControl.disable();
        this.workInstructionLinkControl.disable();
    };

    private disableTargetControls = () => {
        this.targetUpperBoundControl.disable();
        this.targetLowerBoundControl.disable();
        this.weekTargetsControl.disable();

        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            dayForm.controls.targetLowerBound.disable();
            dayForm.controls.targetUpperBound.disable();
            dayForm.controls.weekTargets.disable();
        });

        // Note: we do not disable deployed targets, as we must be in a context that is their parent.
    };

    private getFormValue = (): NumberSettingsDto | null => {
        const team = this.teamControl.value;
        const quarter = this.quarterControl.value;
        if (!team || !quarter) return null;
        const number = !this.number ? undefined :
            !("child" in this.number) ? this.number :
                this.number.parent;
        return {
            id: number?.id ?? "",
            globalId: number?.globalId ?? "",
            updateDay: number?.updateDay,
            source: number?.source,

            company: number?.company ?? team.company,
            team: number?.team ?? {
                id: team.id,
                name: team.name,
            },
            financialYear: number?.financialYear ?? quarter.financialYear,
            planningPeriod: number?.planningPeriod ?? quarter.quarter,
            planningStatus: number?.planningStatus ?? PlanningStatus.draft,
            canEdit: number?.canEdit ?? true,
            owner: this.ownerControl.value ?? undefined,
            updater: this.updaterControl.value ?? undefined,
            department: this.departmentControl.value ?? undefined,
            category: this.getCategoryValue(),

            calculationDefinition: this.getCalculationDefinition(),
            deploymentDefinition: this.getDeploymentDefinition(),
            delegation: this.getDelegation(),
            isDelegated: number?.isDelegated ?? false,
            dailyChildren: this.getDailyChildren(),
            deployedChildren: this.getDeployedChildren(),

            description: this.descriptionControl.value ?? "",
            type: this.numberTypeControl.value,
            entryType: this.enteringTotalsControl.value ? NumberEntryType.totals : NumberEntryType.deltas,
            captureMethod: this.captureMethodControl.value,
            numberStatus: this.numberStatusControl.value,
            targetType: this.targetTypeControl.value,
            isPrivate: this.isPrivateControl.value,
            isRecurring: this.isRecurringControl.value,
            requireNote: this.requireNoteControl.value,
            workInstruction: this.workInstructionControl.value ?? undefined,
            workInstructionLink: this.workInstructionLinkControl.value ?? undefined,
            detectProblemAfterUpdates: getTriggeredDiscussionValue(this.form.controls),
            allowWeeklyTargetOverrides: this.allowWeeklyTargetsControl.value,
            target: sanitiseTarget(this.targetTypeControl.value, {
                lowerBound: this.targetLowerBoundControl.value ?? undefined,
                upperBound: this.targetUpperBoundControl.value ?? undefined,
            }),
            weekTargets: getWeekTargetsValue(this.weeks, this.targetTypeControl.value, this.weekTargetsControl),
            scheduleDefinition: fixUpdateSchedule(this.captureFrequencyControl.value),
            dailyUpdateDefinition: this.getDailyUpdateDefinition(),
            externalData: this.captureMethodControl.value === CaptureMethod.automatic ? this.externalDataControl.value : undefined
        };
    };

    private getChildResponse = (child: GetDeployedChildNumberDto): GetDeployedChildNumberDto => ({
        id: child.id,
        globalId: child.globalId,

        company: child.company,
        team: child.team,

        owner: this.ownerControl.value ?? undefined,
        updater: this.updaterControl.value ?? undefined,
        department: this.departmentControl.value ?? undefined,
        category: this.getCategoryValue(),

        calculationDefinition: this.getCalculationDefinition(),
        deploymentDefinition: this.getDeploymentDefinition(),
        delegation: this.getDelegation(),
        dailyChildren: this.scheduleTypeControl.value !== "daily" ? undefined :
            getDailyChildrenValue(
                this.updateDaysControl.value, this.weeks, this.targetTypeControl.value, this.dailyChildrenControl),
        deployedChildren: this.getDeployedChildren(),
        captureMethod: this.captureMethodControl.value,
        externalData: this.captureMethodControl.value === CaptureMethod.automatic ? this.externalDataControl.value : undefined,
        target: {
            lowerBound: this.targetLowerBoundControl.value ?? undefined,
            upperBound: this.targetUpperBoundControl.value ?? undefined,
        },
        weekTargets: getWeekTargetsValue(this.weeks, this.targetTypeControl.value, this.weekTargetsControl),
    });

    private getCategoryValue = (): SimpleCategoryDto | undefined =>
        this.categoryControl.value ? {
            id: this.categoryControl.value.id,
            description: this.categoryControl.value.description,
            subCategory: this.subCategoryControl.value ?? undefined,
        } : undefined;

    private bindInput = (input: NumberInput) => {
        if (input.description) this.descriptionControl.setValue(input.description);
        if (input.type != null) this.numberTypeControl.setValue(input.type);
    };

    private revalidateAllUpperTargets = () => {
        revalidateUpperTargets(this.form.controls);
        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            revalidateUpperTargets(dayForm.controls);
        });
        forAllDeployed(this.deployedChildrenControl, childForm => {
            revalidateUpperTargets(childForm.controls);
        });
    };

    private handleTargetTypeChange = () => {
        if (this.targetTypeControl.disabled) return;
        const targetType = this.targetTypeControl.value;
        updateTargetDisabledState(targetType, this.form.controls);
        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            updateTargetDisabledState(targetType, dayForm.controls);
        });
        forAllDeployed(this.deployedChildrenControl, childForm => {
            if (childForm.disabled) return;
            updateTargetDisabledState(targetType, childForm.controls);
        });
    };

    private bindDelegation = (delegation: GetTeamUserResponsibilityDelegationDto | undefined) => {
        if (!delegation) return;
        this.isDelegatedControl.setValue(true);
        this.delegationTeamControl.setValue({
            ...delegation.team,
            company: delegation.company,
        });
        this.delegationAssigneeControl.setValue(delegation.assignee ?? null);
        this.delegationResponsibilityControl.setValue(delegation.responsibility);
    };

    private getDelegation = (): GetTeamUserResponsibilityDelegationDto | undefined => {
        if (!this.isDelegatedControl.value) return undefined;
        const team = this.delegationTeamControl.value;
        const assignee = this.delegationAssigneeControl.value;
        if (!team || !assignee) return undefined;
        return {
            company: team.company,
            team: {
                id: team.id,
                name: team.name,
            },
            assignee,
            responsibility: this.delegationResponsibilityControl.value,
        };
    };

    private bindDailyUpdateDefinition = (definition: NumberDailyUpdateDefinitionDto | undefined) => {
        if (!definition) return;
        this.dailyUpdateCalculationTypeControl.setValue(definition.calculationType);
        this.updateDaysControl.setValue([...definition.days]);
    };

    private getDailyUpdateDefinition = (): NumberDailyUpdateDefinitionDto | undefined => {
        if (this.scheduleTypeControl.value !== "daily") return undefined;
        return {
            calculationType: this.dailyUpdateCalculationTypeControl.value,
            days: this.updateDaysControl.value,
        };
    };

    private getDailyChildren = (): GetDailyChildNumberDto[] | undefined => {
        if (this.scheduleTypeControl.value !== "daily") return undefined;
        return getDailyChildrenValue(
            this.updateDaysControl.value, this.weeks, this.targetTypeControl.value, this.dailyChildrenControl);
    };

    private bindCalculationDefinition = (definition: GetNumberCalculationDefinitionDto | undefined) => {
        if (!definition) return;
        this.calculationTypeControl.setValue(definition.type);
        this.calculationMultiplierControl.setValue(definition.multiplier ?? null);
        this.calculationDivisorControl.setValue(definition.divisor ?? null);

        for (const number of definition.numbers.sort(sortMultiple(
            // Any inverted numbers should come last
            sortBoolean.ascending(n => n.inverted),
            sortNumberDefinition.ascending(n => n.number),
        ))) {
            this.calculationNumbersControl.push(buildCalculationNumberForm(number, this.calculationNumbersControl.disabled));
        }
    };

    private getCalculationDefinition = (): GetNumberCalculationDefinitionDto | undefined => {
        if (this.captureMethodControl.value !== CaptureMethod.calculated) return undefined;
        const calculationType = this.calculationTypeControl.value;
        const supportsInversion = calculationType === CalculationType.sum || calculationType === CalculationType.product;
        return {
            type: calculationType,
            multiplier: this.calculationMultiplierControl.value ?? undefined,
            divisor: this.calculationDivisorControl.value ?? undefined,
            numbers: this.calculationNumbersControl.getRawValue().map(({ number, inverted, weekOffset, rollingDefinition }) => ({
                number,
                inverted: inverted && supportsInversion,
                weekOffset: weekOffset ?? 0,
                rollingDefinition: rollingDefinition.calculationType === "none" ? undefined : {
                    calculationType: rollingDefinition.calculationType,
                    duration: rollingDefinition.durationType === "instance" ? {
                        type: "dynamic",
                        instanceCount: rollingDefinition.duration,
                    } : {
                        type: "fixed",
                        lengthUnit: rollingDefinition.durationType === "week" ? PeriodUnit.week : PeriodUnit.month,
                        length: rollingDefinition.duration,
                    },
                },
            })),
        };
    };

    private bindDeploymentDefinition = (definition: GetNumberDeploymentDefinitionDto | undefined) => {
        if (!definition) return;
        this.deploymentCalculationTypeControl.setValue(definition.calculationType);
        this.deploymentTeamsControl.setValue([...definition.teams].sort(sortTeam.ascending()));
    };

    private getDeploymentDefinition = (): GetNumberDeploymentDefinitionDto | undefined => {
        if (this.captureMethodControl.value !== CaptureMethod.deployed) return undefined;
        return {
            calculationType: this.deploymentCalculationTypeControl.value,
            teams: [...this.deploymentTeamsControl.value],
        };
    };

    private getDeployedChildren = (): GetDeployedChildNumberDto[] | undefined => {
        if (this.captureMethodControl.value !== CaptureMethod.deployed) return undefined;
        return getDeployedChildrenValue(
            this.deployedChildrenControl,
            this.deploymentTeamsControl.value,
            this.targetTypeControl.value,
            this.weeks,
            this.scheduleTypeControl.value !== "daily" ? undefined : this.updateDaysControl.value,
        );
    };

    private availableCaptureMethodsChanged = (methods: CaptureMethod[]): void => {
        if (!methods.includes(this.captureMethodControl.value)) {
            this.captureMethodControl.setValue(methods[0]);
        }
    };

    private availableScheduleTypesChanged = (types: ExtendedScheduleType[]): void => {
        if (!types.includes(this.scheduleTypeControl.value)) {
            this.scheduleTypeControl.setValue(UpdateScheduleType.everyPeriod);
        }
    };

    private setDefaultOwnerUpdater = (users: SimpleUserDto[]): void => {
        // Ensure the number is a new number and the owner is not yet set.
        if (this.number || this.ownerControl.value) return;

        const currentUserId = this.userContext.userId();
        // Ensure the user is in the current team.
        const currentUser = users.find(u => u.userId === currentUserId);
        if (currentUser) {
            this.ownerControl.setValue(currentUser);
            if (this.updaterControl.enabled) {
                this.updaterControl.setValue(currentUser);
            }
        }
    };

    private availableUsersChanged = (users: SimpleUserDto[]) => {
        const currentOwner = this.ownerControl.value;
        if (currentOwner && !users.some(x => compareUsers(x, currentOwner))) {
            this.ownerControl.setValue(null);
        }
        const currentUpdater = this.updaterControl.value;
        if (currentUpdater && !users.some(x => compareUsers(x, currentUpdater))) {
            this.updaterControl.setValue(null);
        }
        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            const updaterControl = dayForm.controls.updater;
            const currentDayUpdater = updaterControl.value;
            if (currentDayUpdater && !users.some(x => compareUsers(x, currentDayUpdater))) {
                updaterControl.setValue(null);
            }
        });
    };

    private availableDepartmentsChanged = (departments: SimpleDepartmentDto[]) => {
        const currentDepartment = this.departmentControl.value;
        if (currentDepartment && !departments.some(d => d.id === currentDepartment.id)) {
            this.departmentControl.setValue(null);
        }
    };

    private availableCategoriesChanged = (categories: CategoryDetailDto[]) => {
        if (!categories.length) {
            // Ensures even if the list of sub-categories is not subscribed to (due to no categories)
            // the control will be cleared and disabled appropriately.
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
        }
        const currentCategory = this.categoryControl.value;
        if (currentCategory && !categories.some(x => x.id === currentCategory.id)) {
            this.categoryControl.setValue(null);
        }
    };

    private availableSubCategoriesChanged = (subCategories: SimpleSubCategoryDto[]) => {
        if (!subCategories.length) {
            this.subCategoryControl.setValue(null);
            this.subCategoryControl.disable();
            return;
        }

        if (this.form.enabled) this.subCategoryControl.enable();
        const currentSubCategory = this.subCategoryControl.value;
        if (subCategories.length === 1) {
            this.subCategoryControl.setValue(subCategories[0] ?? null);
        } else if (currentSubCategory && !subCategories.some(x => x.id === currentSubCategory.id)) {
            this.subCategoryControl.setValue(null);
        }
    };

    private handleUpdateDaysChange = (days: DayOfWeek[]) => {
        if (this.scheduleTypeControl.value !== "daily") return;
        bindDailyChildren(
            days,
            this.weeks,
            this.form.controls,
            this.subscriptions,
            /* children: */ undefined,
        );
    };

    private handleDeploymentTeamsChange = (teams: SimpleCompanyTeamDto[]) => {
        if (this.captureMethodControl.value !== CaptureMethod.deployed) return;
        bindDeployedChildren(
            teams,
            this.weeks,
            this.form.controls,
            this.subscriptions,
            /* children: */ undefined,
        );
    };

    private availableCalculationNumbersChanged = (numbers: GetEnterpriseNumberDto[]) => {
        // We don't want to remove any numbers that were already selected.
        const existingNumber = this.number && "child" in this.number ? this.number.child : this.number;
        const allNumbers = [...numbers, ...(existingNumber?.calculationDefinition?.numbers.map(n => n.number) ?? [])];
        // Note: we loop backwards so that removing items doesn't affect the loop.
        for (let i = this.calculationNumbersControl.length - 1; i >= 0; i--) {
            const sn = this.calculationNumbersControl.at(i).controls.number.value;
            if (!allNumbers.some(n => this.compareNumbers(sn, n))) {
                this.calculationNumbersControl.removeAt(i);
            }
        }
    };

    private availableDelegatedTeamsChanged = (teams: SimpleCompanyTeamDto[]) => {
        const currentTeam = this.delegationTeamControl.value;
        if (currentTeam && !teams.some(t => compareTeams(t, currentTeam))) {
            this.delegationTeamControl.setValue(null);
        }
    };

    private availableDelegatedUsersChanged = (users: SimpleUserDto[]) => {
        const currentAssignee = this.delegationAssigneeControl.value;
        if (currentAssignee && !users.some(x => compareUsers(x, currentAssignee))) {
            this.delegationAssigneeControl.setValue(null);
        }
    };

    private handleCaptureMethodChange = (captureMethod: CaptureMethod) => {
        switch (captureMethod) {
            case CaptureMethod.automatic:
                this.disableAndClearUpdater();
                this.calculationDefinitionControl.disable();
                this.deploymentDefinitionControl.disable();
                this.deployedChildrenControl.disable();
                if (this.form.enabled) this.externalDataControl.enable();
                break;
            case CaptureMethod.calculated:
                this.disableAndClearUpdater();
                this.externalDataControl.disable();
                this.deploymentDefinitionControl.disable();
                this.deployedChildrenControl.disable();
                if (this.form.enabled) this.calculationDefinitionControl.enable();
                break;
            case CaptureMethod.deployed:
                this.disableAndClearUpdater();
                this.externalDataControl.disable();
                this.calculationDefinitionControl.disable();
                if (this.form.enabled) {
                    this.deploymentDefinitionControl.enable();
                    bindDeployedChildren(
                        this.deploymentTeamsControl.value,
                        this.weeks,
                        this.form.controls,
                        this.subscriptions,
                        /* children: */ undefined,
                    );
                }
                break;
            case CaptureMethod.manual:
                if (this.form.enabled) {
                    this.updaterControl.enable();
                    forAllDays(this.dailyChildrenControl, (_, dayForm) => {
                        dayForm.controls.updater.enable();
                    });
                    if (this.isDelegatedControl.value) {
                        this.delegationResponsibilityControl.enable();
                    }
                }
                this.externalDataControl.disable();
                this.calculationDefinitionControl.disable();
                this.deploymentDefinitionControl.disable();
                this.deployedChildrenControl.disable();
                break;
        }
    };

    private disableAndClearUpdater = () => {
        this.updaterControl.setValue(null);
        this.updaterControl.disable();
        forAllDays(this.dailyChildrenControl, (_, dayForm) => {
            dayForm.controls.updater.setValue(null);
            dayForm.controls.updater.disable();
        });
        this.delegationResponsibilityControl.setValue(DelegationResponsibility.ownership);
        this.delegationResponsibilityControl.disable();
    };

    private handleScheduleTypeChange = (type: ExtendedScheduleType) => {
        if (!this.form.enabled) return;
        setEnabledState(this.dailyUpdateDefinitionControl, type === "daily");
    };

    private handleNumberTypeChange = (type: NumberType) => {
        if (!supportsAddingNumbers(type)) {
            if (this.enteringTotalsControl.enabled) {
                this.lastEnteringTotalsValue = this.enteringTotalsControl.value;
                this.enteringTotalsControl.disable();
                this.enteringTotalsControl.setValue(true);
            }
        } else {
            if (this.enteringTotalsControl.disabled) {
                this.enteringTotalsControl.setValue(this.lastEnteringTotalsValue);
                if (this.form.enabled) this.enteringTotalsControl.enable();
            }
        }
    };

    private handleTriggerDiscussionChange = (enabled: boolean) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionTypeControl, enabled);
        setEnabledState(this.triggerDiscussionAfterControl, enabled && this.triggerDiscussionTypeControl.value === "offtarget");
    };

    private handleTriggerDiscussionTypeChange = (type: TriggeredDiscussionType) => {
        if (!this.form.enabled) return;
        setEnabledState(this.triggerDiscussionAfterControl, type === "offtarget" && this.triggerDiscussionControl.value);
    };
}

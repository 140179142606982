<h4 mat-dialog-title>
    <mat-icon>tips_and_updates</mat-icon>
    {{ 'goals.suggest.titleImprovement' | translate: { heading: goal.heading } }}
</h4>
@if (suggestion$ | async; as suggestion) {
    <mat-dialog-content>
        <div class="suggestion-container">
            <div class="wf-section">
                <h5 class="wf-section-title">{{ goal.heading }}</h5>
                <p class="wf-section-content">{{ goal.description }}</p>
            </div>
            <mat-icon>south</mat-icon>
            <div class="wf-section">
                <h5 class="wf-section-title">{{ suggestion.heading }}</h5>
                <p class="wf-section-content">{{ suggestion.description }}</p>
            </div>
        </div>
    </mat-dialog-content>
    
    <mat-dialog-actions align="end">
        <button mat-flat-button color="primary" (click)="apply(suggestion)">
            {{ 'goals.suggest.apply' | translate }}
        </button>
    </mat-dialog-actions>
} @else if (noSuggestion) {
    <mat-dialog-content>
        <mat-icon class="no-suggestion-icon">sentiment_very_satisfied</mat-icon>
        <p class="no-suggestion" translate="goals.suggest.noImprovement"></p>

        <div class="button-row">
            <button type="button" mat-flat-button color="primary" [mat-dialog-close]>
                {{ 'Continue' | translate }}
            </button>
        </div>
    </mat-dialog-content>
} @else {
    <mat-dialog-content>
        <div class="loading-container">
            <mat-spinner [diameter]="40"></mat-spinner>
            <span translate="goals.suggest.loading"></span>
        </div>
    </mat-dialog-content>
}
/* eslint-disable max-classes-per-file */
import { CompanyTeamActionFeedApi, CompanyTeamActionFeedContext } from "@api";

import { GenericFeedAdapter } from "./feed.adapter";
import { FeedReference } from "./feed.common";

type CompanyTeamActionScope = readonly [companyId: string, teamId: string, actionId: string];

export class CompanyTeamActionFeedAdapter extends GenericFeedAdapter<CompanyTeamActionScope> {

    protected get scope() {
        return [this.reference.context.companyId, this.reference.context.teamId, this.reference.context.actionId] as const;
    }

    constructor(
        readonly reference: Readonly<FeedReference<CompanyTeamActionFeedContext>>,
        api: CompanyTeamActionFeedApi
    ) {
        super(api);
    }
}

import { WebPubSubClient } from "@azure/web-pubsub-client";
import { Observable, Subject } from "rxjs";

export interface GenericWebSocket {
    readonly messages$: Observable<unknown>;
    readonly connected$: Observable<void>;
    close(): void;
}

const shouldUseReliable = (socketUrl: string): boolean => socketUrl.includes("webpubsub.azure.com");

const createRawSocket = (socketUrl: string): GenericWebSocket => {
    const connectedSubject = new Subject<void>();
    const messageSubject = new Subject<unknown>();
    const socket = new WebSocket(socketUrl);
    socket.onmessage = (message: MessageEvent<unknown>) => {
        if (typeof message.data === "string") {
            messageSubject.next(JSON.parse(message.data));
        }
    };
    socket.onopen = () => {
        connectedSubject.next();
    };
    return {
        messages$: messageSubject.asObservable(),
        connected$: connectedSubject.asObservable(),
        close: () => socket.close(),
    };
};

const createReliableSocket = (socketUrl: string): GenericWebSocket => {
    const connectedSubject = new Subject<void>();
    const messageSubject = new Subject<unknown>();
    const socket = new WebPubSubClient(socketUrl);

    socket.on("group-message", ({ message }) => {
        if (message.dataType === "json") messageSubject.next(message.data);
    });

    // We need to subscribe separately to the server-message event - this will
    // cater for messages sent to all users or sent directly to a user.
    socket.on("server-message", ({ message }) => {
        if (message.dataType === "json") messageSubject.next(message.data);
    });

    socket.on("connected", () => {
        connectedSubject.next();
    });

    socket.start();

    return {
        messages$: messageSubject.asObservable(),
        connected$: connectedSubject.asObservable(),
        close: () => socket.stop(),
    };
};

export const createSocket = (socketUrl: string): GenericWebSocket =>
    shouldUseReliable(socketUrl) ?
        createReliableSocket(socketUrl) :
        createRawSocket(socketUrl);

/* eslint-disable max-classes-per-file */
import { ReportFeedApi, ReportFeedContext } from "@api";

import { GenericFeedAdapter } from "./feed.adapter";
import { FeedReference } from "./feed.common";

type ReportScope = readonly [companyId: string, teamId: string, period: string, reportId: string];

export class ReportFeedAdapter extends GenericFeedAdapter<ReportScope> {

    protected get scope() {
        return [
            this.reference.context.companyId,
            this.reference.context.teamId,
            this.reference.context.period,
            this.reference.context.reportId,
        ] as const;
    }

    constructor(
        readonly reference: Readonly<FeedReference<ReportFeedContext>>,
        api: ReportFeedApi,
    ) {
        super(api);
    }
}

/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */
import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AnnualGoalsPlanningDocumentApi, AnnualGoalsPlanningDocumentDto, AttachmentDto } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, filter, Observable } from "rxjs";

import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";

import { BaseEditAttachmentsDialogComponent } from "../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component";
import { BasePlanningDocumentsButtonComponent } from "../base-planning-documents-button/base-planning-documents-button.component";

interface CompanyTeamYear {
    companyId: string;
    teamId: string;
    year: number;
}

@Component({
    selector: "app-annual-goals-planning-documents-button",
    templateUrl: "../base-planning-documents-button/base-planning-documents-button.component.html",
    styleUrls: ["../base-planning-documents-button/base-planning-documents-button.component.scss"]
})
export class AnnualGoalsPlanningDocumentsButtonComponent
    extends BasePlanningDocumentsButtonComponent<AnnualGoalsPlanningDocumentDto, CompanyTeamYear> {

    get teamId(): string | null {
        return this.teamIdSubject.value;
    }

    @Input() set teamId(value: string | null) {
        this.teamIdSubject.next(value);
    }

    get year(): number | null {
        return this.yearSubject.value;
    }

    @Input() set year(value: number | null) {
        this.yearSubject.next(value);
    }

    protected readonly input$: Observable<CompanyTeamYear>;

    private readonly teamIdSubject = new BehaviorSubject<string | null>(null);
    private readonly yearSubject = new BehaviorSubject<number | null>(null);

    constructor(
        private readonly documentApi: AnnualGoalsPlanningDocumentApi,
        private readonly dialog: MatDialog,
    ) {
        super();
        this.input$ = combineLatest({
            companyId: this.companyIdSubject.pipe(filter(Boolean)),
            teamId: this.teamIdSubject.pipe(filter(Boolean)),
            year: this.yearSubject.pipe(
                filter((year): year is number => year !== null && year !== undefined),
            ),
        });
    }

    protected getDocument = (input: CompanyTeamYear) =>
        this.documentApi.getPlanningAttachments(
            input.companyId,
            input.teamId,
            input.year,
        );

    protected openDialogInternal = (document: AnnualGoalsPlanningDocumentDto) =>
        EditAnnualGoalsPlanningDocumentsDialogComponent.open(this.dialog, document);
}

@Component({
    selector: "app-edit-annual-goals-planning-documents-dialog",
    templateUrl: "../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component.html",
    styleUrls: ["../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component.scss"],
})
export class EditAnnualGoalsPlanningDocumentsDialogComponent extends BaseEditAttachmentsDialogComponent {
    get description(): string {
        return this.translate.instant(this.descriptionKey);
    }

    private get descriptionKey(): string {
        return this.teamContext.settings.useAucbgMenus() ? "Goals and Strategies" : "Business Goals";
    }

    constructor(
        private readonly documentApi: AnnualGoalsPlanningDocumentApi,
        private readonly teamContext: TeamContext,
        private readonly translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) private readonly doc: AnnualGoalsPlanningDocumentDto,
        notificationService: NotificationService,
        dialogRef: MatDialogRef<unknown, boolean>,
    ) {
        super(notificationService, dialogRef);
    }

    static open(dialog: MatDialog, doc: AnnualGoalsPlanningDocumentDto) {
        return BaseEditAttachmentsDialogComponent.openInternal(EditAnnualGoalsPlanningDocumentsDialogComponent, dialog, doc);
    }

    protected getExistingAttachments = (): AttachmentDto[] => this.doc.attachments;

    protected uploadAttachment = (file: File): Observable<unknown> =>
        this.documentApi.addPlanningAttachment(
            this.doc.company.id,
            this.doc.team.id,
            this.doc.financialYear,
            file
        );

    protected deleteAttachment = (path: string): Observable<unknown> => this.deleteAttachments([path]);

    protected deleteAttachments = (paths: string[]): Observable<unknown> =>
        this.documentApi.deletePlanningAttachments(
            this.doc.company.id,
            this.doc.team.id,
            this.doc.financialYear,
            { attachmentPaths: paths },
        );
}

/* eslint-disable max-classes-per-file */
import { GoalFeedApi, GoalFeedContext } from "@api";

import { GenericFeedAdapter } from "./feed.adapter";
import { FeedReference } from "./feed.common";

type GoalScope = readonly [companyId: string, teamId: string, period: string, goalId: string];

export class GoalFeedAdapter extends GenericFeedAdapter<GoalScope> {

    protected get scope() {
        return [
            this.reference.context.companyId,
            this.reference.context.teamId,
            this.reference.context.period,
            this.reference.context.goalId,
        ] as const;
    }

    constructor(
        readonly reference: Readonly<FeedReference<GoalFeedContext>>,
        api: GoalFeedApi,
    ) {
        super(api);
    }
}

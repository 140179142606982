import { Injectable } from "@angular/core";
import { AbstractSecurityStorage } from "angular-auth-oidc-client";
import { StorageKeys } from "angular-auth-oidc-client/lib/storage/storage-persistence.service";

import { getObjectFromEntries } from "~shared/util/type-utils";

export const AUTH_STORAGE_PREFIX = "auth-";

const transformKey = (key: string) => `${AUTH_STORAGE_PREFIX}${key}`;

const SESSION_STORAGE_KEYS: StorageKeys[] = [
    "authNonce", "codeVerifier", "authStateControl", "session_state", "storageCustomParamsAuthRequest", "storageCustomParamsRefresh",
    "storageCustomParamsEndSession", "redirect"
];

const getConfigPart = (storage: Storage, key: string): Partial<Record<StorageKeys, unknown>> | null => {
    const storedConfig = storage.getItem(key);
    if (!storedConfig) return null;
    return JSON.parse(storedConfig);
};

const setConfigPart = (storage: Storage, key: string, value: Partial<Record<StorageKeys, unknown>> | null) => {
    if (!value) {
        storage.removeItem(key);
    } else {
        storage.setItem(key, JSON.stringify(value));
    }
};

const splitConfig = (value: Partial<Record<StorageKeys, unknown>> | null): {
    local: Partial<Record<StorageKeys, unknown>> | null;
    session: Partial<Record<StorageKeys, unknown>> | null;
} => {
    if (!value) return { local: null, session: null };
    const entries = Object.entries(value) as [StorageKeys, unknown][];
    const localEntries = entries.filter(([key]) => !SESSION_STORAGE_KEYS.includes(key));
    const sessionEntries = entries.filter(([key]) => SESSION_STORAGE_KEYS.includes(key));
    return {
        local: getObjectFromEntries(localEntries),
        session: getObjectFromEntries(sessionEntries),
    };
};

const clearAuthData = (storage: Storage): void => {
    Object.keys(storage)
        .filter(k => k.startsWith(AUTH_STORAGE_PREFIX))
        .forEach(k => storage.removeItem(k));
};

@Injectable()
export class HybridAuthStorageService implements AbstractSecurityStorage {

    read(configId: string): string {
        return JSON.stringify(this.getConfig(configId));
    }

    write(configId: string, value: string): void {
        this.writeConfig(configId, !value ? null : JSON.parse(value));
    }

    remove(configId: string): void {
        const key = transformKey(configId);
        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    }

    clear(): void {
        clearAuthData(localStorage);
        clearAuthData(sessionStorage);
    }

    private getConfig = (configId: string): null | Partial<Record<StorageKeys, unknown>> => {
        const key = transformKey(configId);
        const sessionConfig = getConfigPart(sessionStorage, key);
        const localConfig = getConfigPart(localStorage, key);
        if (!sessionConfig && !localConfig) return null;

        return {
            ...localConfig,
            ...sessionConfig,
        };
    };

    private writeConfig = (configId: string, value: Partial<Record<StorageKeys, unknown>> | null) => {
        const key = transformKey(configId);
        const { local, session } = splitConfig(value);
        setConfigPart(localStorage, key, local);
        setConfigPart(sessionStorage, key, session);
    };
}

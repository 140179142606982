export enum Role {
    superAdmin = "SuperAdmin",
    enterpriseAdmin = "EnterpriseAdmin",
    admin = "Admin",
    partner = "Partner",
    partnerAdmin = "PartnerAdmin",
    advisor = "Advisor",
    user = "User",
    associate = "Associate",
}

export enum Profile {
    superAdmin = "SuperAdmin",
    enterpriseAdmin = "EnterpriseAdmin",
    billingAdmin = "BillingAdmin",
    humanResources = "HumanResources",
    partnerAdmin = "PartnerAdmin",
    partnerManager = "PartnerManager",
    partner = "Partner",
    admin = "Admin",
    fullUser = "FullUser",
}

import { FeedItemReferenceDto, FeedPartitionDto, GetFeedItemDto, UpdateFeedItemDto } from "@api";
import { Observable } from "rxjs";

import { PagedData } from "~shared/util/async-table-data-source";
import { IScopedApi } from "~shared/util/generic-api-helper";

import { FeedContext, FeedPartitionKey, feedPartitionKeyToString, FeedReference } from "./feed.common";

export type FeedPaginationFilter = "comments" | "links" | "attachments" | "userGenerated" | "systemGenerated";

export interface IFeedAdapter {
    readonly reference: FeedReference<FeedContext> | undefined;

    addFeedItem(feedItemDto: UpdateFeedItemDto, key: FeedPartitionKey | undefined): Observable<GetFeedItemDto>;
    addFeedItemFile(file: Blob, key: FeedPartitionKey | undefined): Observable<GetFeedItemDto>;
    deleteFeedItem(feedItemId: string): Observable<FeedPartitionDto>;
    updateFeedItem(feedItemId: string, feedItemDto: UpdateFeedItemDto): Observable<GetFeedItemDto>;

    paginateFeedItems(skip: number, take: number, filter: FeedPaginationFilter | undefined, key: FeedPartitionKey | undefined):
        Observable<PagedData<GetFeedItemDto>>;
    setFeedViewedState(lastFeedItemId: string, key: FeedPartitionKey | undefined): Observable<unknown>;
}

interface ICoreFeedApi {
    addFeedItem(feedItemDto: UpdateFeedItemDto): Observable<GetFeedItemDto>;
    addFeedItemForPartition(partitionKey: string, feedItemDto: UpdateFeedItemDto): Observable<GetFeedItemDto>;
    addFeedItemFile(file: Blob): Observable<GetFeedItemDto>;
    addFeedItemFileForPartition(partitionKey: string, file: Blob): Observable<GetFeedItemDto>;
    deleteFeedItem(feedItemId: string): Observable<FeedPartitionDto>;
    updateFeedItem(feedItemId: string, feedItemDto: UpdateFeedItemDto): Observable<GetFeedItemDto>;
    paginateFeedItems(skip: number, take: number, filter: FeedPaginationFilter | undefined):
        Observable<PagedData<GetFeedItemDto>>;
    paginateFeedItemsForPartition(partitionKey: string, skip: number, take: number, filter?: FeedPaginationFilter):
        Observable<PagedData<GetFeedItemDto>>;
    setUserViewedState(lastFeedItem: FeedItemReferenceDto): Observable<unknown>;
    setUserStateViewedForPartition(partitionKey: string, lastFeedItem: FeedItemReferenceDto): Observable<unknown>;
}

export type IFeedApi<TScope extends readonly unknown[]> = IScopedApi<TScope, ICoreFeedApi>;

export abstract class GenericFeedAdapter<TScope extends readonly unknown[]> implements IFeedAdapter {

    abstract readonly reference: Readonly<FeedReference>;

    protected abstract readonly scope: Readonly<TScope>;

    constructor(
        protected readonly api: IFeedApi<TScope>,
    ) { }

    addFeedItem = (feedItemDto: UpdateFeedItemDto, key: FeedPartitionKey | undefined): Observable<GetFeedItemDto> =>
        !key || !key.length ?
            this.api.addFeedItem(...this.scope, feedItemDto) :
            this.api.addFeedItemForPartition(...this.scope, feedPartitionKeyToString(key), feedItemDto);

    addFeedItemFile = (file: Blob, key: FeedPartitionKey | undefined): Observable<GetFeedItemDto> =>
        !key || !key.length ?
            this.api.addFeedItemFile(...this.scope, file) :
            this.api.addFeedItemFileForPartition(...this.scope, feedPartitionKeyToString(key), file);

    deleteFeedItem = (feedItemId: string): Observable<FeedPartitionDto> =>
        this.api.deleteFeedItem(...this.scope, feedItemId);

    updateFeedItem = (feedItemId: string, feedItemDto: UpdateFeedItemDto): Observable<GetFeedItemDto> =>
        this.api.updateFeedItem(...this.scope, feedItemId, feedItemDto);

    paginateFeedItems = (skip: number, take: number, filter: FeedPaginationFilter | undefined, key: FeedPartitionKey | undefined):
        Observable<PagedData<GetFeedItemDto>> =>
        !key || !key.length ?
            this.api.paginateFeedItems(...this.scope, skip, take, filter) :
            this.api.paginateFeedItemsForPartition(...this.scope, feedPartitionKeyToString(key), skip, take, filter);

    setFeedViewedState = (lastFeedItemId: string, key: FeedPartitionKey | undefined): Observable<unknown> =>
        !key || !key.length ?
            this.api.setUserViewedState(...this.scope, { feedItemId: lastFeedItemId }) :
            this.api.setUserStateViewedForPartition(...this.scope, feedPartitionKeyToString(key), { feedItemId: lastFeedItemId });
}

import { AbstractControl } from "@angular/forms";

export const setEnabledState = (control: AbstractControl, enabled: boolean, opts?: {
    onlySelf?: boolean;
    emitEvent?: boolean;
}): void => {
    if (enabled === control.enabled) return;
    if (enabled) {
        control.enable(opts);
    } else {
        control.disable(opts);
    }
};

import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { ActionsV2Api, GetActionDto } from "@api";

import { NotificationService } from "~services/notification.service";
import { ActionStateService } from "~services/state";
import { ButtonState } from "~shared/components/status-button/status-button.component";

@Component({
    selector: "app-delete-action-dialog",
    templateUrl: "./delete-action-dialog.component.html",
    styleUrls: ["./delete-action-dialog.component.scss"]
})
export class DeleteActionDialogComponent {

    buttonState: ButtonState;

    get description(): string {
        return this.action.description;
    }

    get isRecurring(): boolean {
        return !!this.action.recurrence;
    }

    constructor(
        private readonly actionsApi: ActionsV2Api,
        private readonly actionStateService: ActionStateService,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<DeleteActionDialogComponent, boolean>,
        @Inject(MAT_DIALOG_DATA) private readonly action: GetActionDto,
    ) { }

    static open(dialog: MatDialog, action: GetActionDto) {
        return dialog.open<DeleteActionDialogComponent, GetActionDto, boolean>(
            DeleteActionDialogComponent, {
            width: "500px",
            data: action,
        });
    }

    delete = () => {
        if (this.buttonState) return;

        this.buttonState = "loading";

        this.actionsApi.deleteAction(
            this.action.company.id,
            this.action.team.id,
            this.action.id,
        ).subscribe({
            next: () => {
                this.buttonState = "success";
                this.actionStateService.notifyDelete({
                    companyId: this.action.company.id,
                    teamId: this.action.team.id,
                    id: this.action.id,
                });
                setTimeout(() => {
                    this.dialogRef.close(true);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };
}

import { DATE_PIPE_DEFAULT_OPTIONS, DATE_PIPE_DEFAULT_TIMEZONE, DatePipe, DatePipeConfig } from "@angular/common";
import { Inject, LOCALE_ID, Optional } from "@angular/core";
import { isMoment } from "moment";

export declare type DateInput = moment.Moment | Date | string | number;

export class BaseDatePipe extends DatePipe {
    constructor(
        @Inject(LOCALE_ID) locale: string,
        @Inject(DATE_PIPE_DEFAULT_TIMEZONE) @Optional() defaultTimezone?: string | null,
        @Inject(DATE_PIPE_DEFAULT_OPTIONS) @Optional() defaultOptions?: DatePipeConfig | null | undefined,
    ) {
        super(locale, defaultTimezone, defaultOptions);
    }

    transform(value: DateInput, format?: string, timezone?: string, locale?: string): string;
    transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    transform(value: DateInput | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
    transform(value: DateInput | null | undefined, format?: string, timezone?: string, locale?: string): string | null {
        if (isMoment(value)) value = value.toDate();
        return super.transform(value, format, timezone, locale);
    }
}

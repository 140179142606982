/* eslint-disable lines-between-class-members */
import { Injectable } from "@angular/core";
import { CurrentCompanyDto, CurrentTeamDto, CurrentUserDto, FeatureFlagDto } from "@api";

import { AUTH_STORAGE_PREFIX } from "./auth-storage.service";
import { TABLE_SETTING_PREFIX } from "./table-settings.service";

const GLOBAL_FEATURES_KEY = "GlobalFeatures";
const CURRENT_USER_KEY = "CurrentUser";
const CURRENT_COMPANY_KEY = "CurrentCompany";
const CURRENT_TEAM_KEY = "CurrentTeam";
const POST_LOGIN_RETURN_URL = "PostLoginReturnUrl";

const getStringValue = (storage: Storage, key: string) => storage.getItem(key);

const setStringValue = (storage: Storage, key: string, value: string | null) => {
    if (!value) {
        storage.removeItem(key);
    } else {
        storage.setItem(key, value);
    }
};

const getObjectValue = <T>(storage: Storage, key: string): T | null => {
    const stringValue = getStringValue(storage, key);
    try {
        return stringValue ? JSON.parse(stringValue) as T : null;
    } catch {
        return null;
    }
};

const setObjectValue = <T>(storage: Storage, key: string, value: T | null) => {
    if (!value) {
        storage.removeItem(key);
    } else {
        storage.setItem(key, JSON.stringify(value));
    }
};

/**
 * The settings which will be persisted on logout (or otherwise clearing settings)
 */
const settingsToKeep = [GLOBAL_FEATURES_KEY];

@Injectable({
    providedIn: "root"
})
export class SettingsService {

    get globalFeatures(): FeatureFlagDto[] | null {
        return getObjectValue(localStorage, GLOBAL_FEATURES_KEY);
    }
    set globalFeatures(value: FeatureFlagDto[] | null) {
        setObjectValue(localStorage, GLOBAL_FEATURES_KEY, value);
    }

    get currentUser() {
        return getObjectValue(localStorage, CURRENT_USER_KEY);
    }
    set currentUser(value: CurrentUserDto | null) {
        setObjectValue(localStorage, CURRENT_USER_KEY, value);
    }

    get currentCompany() {
        return getObjectValue(localStorage, CURRENT_COMPANY_KEY);
    }
    set currentCompany(value: CurrentCompanyDto | null) {
        setObjectValue(localStorage, CURRENT_COMPANY_KEY, value);
    }

    get currentTeam() {
        return getObjectValue(localStorage, CURRENT_TEAM_KEY);
    }
    set currentTeam(value: CurrentTeamDto | null) {
        setObjectValue(localStorage, CURRENT_TEAM_KEY, value);
    }

    get postLoginReturnUrl() {
        return getStringValue(sessionStorage, POST_LOGIN_RETURN_URL);
    }
    set postLoginReturnUrl(value: string | null) {
        setStringValue(sessionStorage, POST_LOGIN_RETURN_URL, value);
    }

    constructor() {
    }

    clear = (): void => {
        Object.keys(localStorage)
            .filter(k => !settingsToKeep.includes(k))
            .filter(k => !k.startsWith(TABLE_SETTING_PREFIX))
            .filter(k => !k.startsWith(AUTH_STORAGE_PREFIX))
            .forEach(k => localStorage.removeItem(k));
        Object.keys(sessionStorage)
            .filter(k => !k.startsWith(AUTH_STORAGE_PREFIX))
            .forEach(k => sessionStorage.removeItem(k));
    };
}

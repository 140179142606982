import { Directive, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { AttachmentDto, SimpleCompanyDto } from "@api";
import { BehaviorSubject, Observable, Subscription, switchMap } from "rxjs";

import { retryWithDelay } from "~shared/util/caching";
import { withRefresh } from "~shared/util/rx-operators";

interface DocumentDto {
    company: SimpleCompanyDto;
    attachments: AttachmentDto[];
}

@Directive()
// eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class BasePlanningDocumentsButtonComponent<TDocument extends DocumentDto, TInput> implements OnInit, OnDestroy {

    protected abstract readonly input$: Observable<TInput>;

    get companyId(): string | null {
        return this.companyIdSubject.value;
    }

    @Input() set companyId(value: string | null) {
        this.companyIdSubject.next(value);
    }

    get disabled(): boolean {
        return !this.hasData;
    }

    get hasData(): boolean {
        return !!this.documentSubject.value;
    }

    get attachmentCount(): number | null {
        return this.documentSubject.value?.attachments.length ?? null;
    }

    protected readonly companyIdSubject = new BehaviorSubject<string | null>(null);
    protected readonly refreshSubject = new BehaviorSubject<void>(undefined);

    protected readonly documentSubject = new BehaviorSubject<TDocument | null>(null);
    protected readonly subscriptions = new Subscription();

    protected abstract openDialogInternal(document: TDocument): MatDialogRef<unknown, boolean>;
    protected abstract getDocument(input: TInput): Observable<TDocument>;

    ngOnInit(): void {
        this.subscriptions.add(this.input$.pipe(
            withRefresh(this.refreshSubject),
            switchMap(input => this.getDocument(input).pipe(
                retryWithDelay(),
            ))
        ).subscribe(this.documentSubject));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    refresh = () => this.refreshSubject.next();

    openDialog = () => {
        const document = this.documentSubject.value;
        if (!document) return;
        this.openDialogInternal(document).afterClosed().subscribe(res => res && this.refresh());
    };
}

<section class="wf-section origin-container" *ngIf="origin">
    <div class="origin-inner">
        <span class="origin-label">{{ 'originFor' | translate: { entityType: ( originNameKey | translate ) } }}</span>
        <h5 class="origin-heading">
            {{ heading }}
        </h5>
        <span *ngIf="description" class="origin-description multiline-description">
            {{ description }}
        </span>
    </div>
    <button mat-icon-button *ngIf="canView" type="button" [disabled]="(hasAccess$ | async) !== true" (click)="openOrigin()"
        class="origin-button" [matTooltip]="'View' | translate">
        <mat-icon>launch</mat-icon>
    </button>
</section>

/* eslint-disable max-classes-per-file */
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { GetTeamUserResponsibilityDelegationDto } from "@api";

import { IDelegatedItemWithOwner, IDelegatedItemWithUpdater } from "~shared/util/delegation-helper";
import { getDelegationResponsibilityNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

type DelegatedItem = IDelegatedItemWithOwner | IDelegatedItemWithUpdater;

@Component({
    selector: "app-delegation-info",
    templateUrl: "./delegation-info.component.html",
    styleUrls: ["./delegation-info.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegationInfoComponent {

    @Input() entity?: DelegatedItem;

    get reverse() {
        return this.reverseInternal;
    }

    @Input() set reverse(value: boolean) {
        this.reverseInternal = coerceBooleanProperty(value);
    }

    readonly getUserName = getUserName;
    readonly getResponsibilityNameKey = getDelegationResponsibilityNameKey;

    private reverseInternal = false;

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_reverse: BooleanInput;

    hasUpdater = (entity: DelegatedItem): entity is IDelegatedItemWithUpdater =>
        "updater" in entity && !!entity.updater;

    hasResponsibility = (entity: DelegatedItem): entity is DelegatedItem & { delegation: GetTeamUserResponsibilityDelegationDto } =>
        !!entity.delegation && "responsibility" in entity.delegation;
}

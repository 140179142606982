import { Component, Inject } from "@angular/core";
import { AsyncValidatorFn, FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EnterpriseNumbersApi, GetEnterpriseNumberDto, GetNumberDto, NumberDeploymentMigrationApi } from "@api";
import { catchError, map, Observable, of } from "rxjs";

import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { AutoSelectGroup } from "~shared/components/auto-select";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { WithDestroy } from "~shared/mixins";
import { retryWithDelay } from "~shared/util/caching";
import { groupItemsByTeam } from "~shared/util/item-grouping";
import { sortNumberDefinition } from "~shared/util/number-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { compareTeams } from "~shared/util/team-helper";

const adoptionValidator = (api: NumberDeploymentMigrationApi, parent: GetNumberDto): AsyncValidatorFn => control => {
    const child = control.value as GetEnterpriseNumberDto | null;
    if (child == null) return of(null);

    return api.checkAdoptionEligibility(
        parent.company.id,
        parent.team.id,
        toFiscalQuarter({ financialYear: parent.financialYear, quarter: parent.planningPeriod }),
        parent.id,
        {
            companyId: child.company.id,
            teamId: child.team.id,
            numberId: child.id,
        },
    ).pipe(
        map(response => response.eligible ? null : { ineligible: true }),
        catchError(() => of({ ineligible: true })),
    );
};

@Component({
    selector: "app-deployment-adoption-dialog",
    templateUrl: "./deployment-adoption-dialog.component.html",
    styleUrl: "./deployment-adoption-dialog.component.scss"
})
export class DeploymentAdoptionDialogComponent extends WithDestroy() {

    buttonState: ButtonState;

    get description() {
        return this.number.description;
    }

    readonly childControl = new FormControl<GetEnterpriseNumberDto | null>(null, Validators.required);

    readonly form = new FormGroup({
        child: this.childControl,
    });

    readonly orphans$: Observable<GetEnterpriseNumberDto[]>;

    private readonly number: GetNumberDto;

    constructor(
        private readonly api: NumberDeploymentMigrationApi,
        private readonly enterpriseNumbersApi: EnterpriseNumbersApi,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<DeploymentAdoptionDialogComponent, GetNumberDto>,
        @Inject(MAT_DIALOG_DATA) number: GetNumberDto) {
        super();

        this.number = number;

        this.childControl.addAsyncValidators(adoptionValidator(this.api, number));

        const referencedTeams = this.number.deploymentDefinition?.teams ?? [];

        const allNumbers$ = this.enterpriseNumbersApi.getEnterpriseNumbersForPeriod(
            number.company.id,
            toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
        ).pipe(
            retryWithDelay(),
        );

        this.orphans$ = allNumbers$.pipe(
            map(numbers => numbers.filter(n => {
                // Exclude the current number
                if (n.id === this.number.id || n.globalId === this.number.globalId) return false;

                // Exclude the number if it is the direct source of this number
                if (this.number.source && this.number.source.globalId === n.globalId) return false;

                // Exclude any private numbers or numbers that have a parent
                if (n.source || n.isPrivate) return false;

                const relevantTeam = n.delegation ? {
                    ...n.delegation.team,
                    company: n.delegation.company,
                } : {
                    ...n.team,
                    company: n.company,
                };

                // Exclude any numbers from teams that are already referenced.
                return !referencedTeams.some(t => compareTeams(t, relevantTeam));
            })),
            map(numbers => numbers.sort(sortNumberDefinition.ascending())),
            shareReplayUntil(this.destroyed$),
        );
    }

    static open(dialog: MatDialog, number: GetNumberDto) {
        return dialog.open<DeploymentAdoptionDialogComponent, GetNumberDto, GetNumberDto>(DeploymentAdoptionDialogComponent, {
            width: "600px",
            autoFocus: "first-heading",
            data: number,
        });
    }

    adopt = () => {
        const child = this.childControl.value;
        if (this.buttonState || !this.form.valid || !child) return;
        this.buttonState = "loading";

        this.api.adoptNumber(
            this.number.company.id,
            this.number.team.id,
            toFiscalQuarter({ financialYear: this.number.financialYear, quarter: this.number.planningPeriod }),
            this.number.id,
            {
                companyId: child.company.id,
                teamId: child.team.id,
                numberId: child.id,
            },
        ).subscribe({
            next: (result) => {
                this.buttonState = "success";
                this.notificationService.success("numbers.deployment.adoptionSuccess", undefined, undefined, true);
                setTimeout(() => {
                    this.dialogRef.close(result);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                this.notificationService.error("numbers.deployment.adoptionFailed", undefined, undefined, true);
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
            },
        });
    };

    getNumberDisplayFunc = (number: GetEnterpriseNumberDto | null | undefined): string =>
        number?.description ?? "";

    getNumberSearchData = (number: GetEnterpriseNumberDto): string =>
        `${number.description} ${number.team.name} ${number.company.name}`;

    groupNumbers = (numbers: GetEnterpriseNumberDto[]): AutoSelectGroup<GetEnterpriseNumberDto>[] =>
        groupItemsByTeam(numbers).map(t => ({
            id: `${t.company.id}_${t.team.id}`,
            name: `${t.company.name}: ${t.team.name}`,
            options: t.items,
        }));

    compareNumbers = (o1: GetEnterpriseNumberDto, o2: GetEnterpriseNumberDto) => {
        if (!o1 && !o2) return true;
        if (!o1 || !o2) return false;
        return o1.globalId === o2.globalId && o1.team.id === o2.team.id && o1.company.id === o2.company.id;
    };
}

<button mat-icon-button [matMenuTriggerFor]="popupMenu"
    [matTooltip]="'tableSettings.settings' | translate">
    <mat-icon>settings</mat-icon>
</button>
<mat-menu #popupMenu="matMenu" (closed)="saveData()" xPosition="before">
    <ng-template matMenuContent>
        <div (click)="$event.stopPropagation()" class="menu-container">
            <div class="settings-header" *ngIf="headerTemplate">
                <ng-container *ngTemplateOutlet="headerTemplate; context: { $implicit: tableSettings }"></ng-container>
            </div>
            <h3 translate="tableSettings.columns"></h3>
            <div *ngIf="filteredColumns$ | async as filteredColumns" cdkDropList class="column-list"
                (cdkDropListDropped)="drop($event, filteredColumns)">
                <div *ngFor="let column of filteredColumns; trackBy: trackByKey" cdkDrag class="column">
                    <mat-slide-toggle [checked]="isColumnVisible(column)" (change)="toggle(column, $event)"
                        [disabled]="!!column.definition.lockType">
                        <div class="column-name">
                            {{ getColumnNameKey(column) | translate }}
                        </div>
                    </mat-slide-toggle>
                    <mat-icon cdkDragHandle>drag_handle</mat-icon>
                </div>
            </div>

            <button mat-button type="button" (click)="reset()" color="primary">
                {{ "tableSettings.resetToDefaults"| translate }}
            </button>
        </div>
    </ng-template>
</mat-menu>
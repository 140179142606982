/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-classes-per-file */
import { Component, Inject, Input } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AttachmentDto, CompanyAnnualPlanningDocumentApi, CompanyAnnualPlanningDocumentDto, CompanyAnnualPlanningDocumentType } from "@api";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, filter, Observable } from "rxjs";

import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";

import { BaseEditAttachmentsDialogComponent } from "../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component";
import { BasePlanningDocumentsButtonComponent } from "../base-planning-documents-button/base-planning-documents-button.component";

export { CompanyAnnualPlanningDocumentType };

interface CompanyYearType {
    companyId: string;
    year: number;
    type: CompanyAnnualPlanningDocumentType;
}

@Component({
    selector: "app-company-annual-planning-documents-button",
    templateUrl: "../base-planning-documents-button/base-planning-documents-button.component.html",
    styleUrls: ["../base-planning-documents-button/base-planning-documents-button.component.scss"]
})
export class CompanyAnnualPlanningDocumentsButtonComponent
    extends BasePlanningDocumentsButtonComponent<CompanyAnnualPlanningDocumentDto, CompanyYearType> {

    get year(): number | null {
        return this.yearSubject.value;
    }

    @Input() set year(value: number | null) {
        this.yearSubject.next(value);
    }

    get type(): CompanyAnnualPlanningDocumentType | null {
        return this.typeSubject.value;
    }

    @Input() set type(value: CompanyAnnualPlanningDocumentType | null) {
        this.typeSubject.next(value);
    }

    protected readonly input$: Observable<CompanyYearType>;

    private readonly yearSubject = new BehaviorSubject<number | null>(null);
    private readonly typeSubject = new BehaviorSubject<CompanyAnnualPlanningDocumentType | null>(null);

    constructor(
        private readonly documentApi: CompanyAnnualPlanningDocumentApi,
        private readonly dialog: MatDialog,
    ) {
        super();
        this.input$ = combineLatest({
            companyId: this.companyIdSubject.pipe(filter(Boolean)),
            year: this.yearSubject.pipe(
                filter((year): year is number => year !== null && year !== undefined),
            ),
            type: this.typeSubject.pipe(
                filter((type): type is CompanyAnnualPlanningDocumentType => type !== null && type !== undefined)
            ),
        });
    }

    protected getDocument = (input: CompanyYearType) =>
        this.documentApi.getPlanningAttachments(
            input.companyId,
            input.year,
            input.type,
        );

    protected openDialogInternal = (document: CompanyAnnualPlanningDocumentDto) =>
        EditCompanyAnnualPlanningDocumentsDialogComponent.open(this.dialog, document);

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_type: CompanyAnnualPlanningDocumentType | `${CompanyAnnualPlanningDocumentType}` | null;
}

@Component({
    selector: "app-edit-company-annual-planning-documents-dialog",
    templateUrl: "../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component.html",
    styleUrls: ["../../dialogs/base-edit-attachments-dialog/base-edit-attachments-dialog.component.scss"],
})
export class EditCompanyAnnualPlanningDocumentsDialogComponent extends BaseEditAttachmentsDialogComponent {
    get description(): string {
        return this.translate.instant(this.descriptionKey);
    }

    private get descriptionKey(): string {
        switch (this.doc.type) {
            case CompanyAnnualPlanningDocumentType.projections:
                return this.isAucbg ? "Vision Elements" : "Projections";
            case CompanyAnnualPlanningDocumentType.foundationPrinciples:
                return "Foundation Principles";
            case CompanyAnnualPlanningDocumentType.mission:
                return "Mission";
            case CompanyAnnualPlanningDocumentType.values:
                return "Values";
            case CompanyAnnualPlanningDocumentType.visionStatement:
                return "Vision Statement";
            case CompanyAnnualPlanningDocumentType.visionCsf:
                return "Visions CSF";
            case CompanyAnnualPlanningDocumentType.measuresOfSuccess:
                return "Measures of Success";
        }
        return "";
    }

    private get isAucbg(): boolean {
        return this.teamContext.settings.useAucbgMenus();
    }

    constructor(
        private readonly documentApi: CompanyAnnualPlanningDocumentApi,
        private readonly teamContext: TeamContext,
        private readonly translate: TranslateService,
        @Inject(MAT_DIALOG_DATA) private readonly doc: CompanyAnnualPlanningDocumentDto,
        notificationService: NotificationService,
        dialogRef: MatDialogRef<unknown, boolean>,
    ) {
        super(notificationService, dialogRef);
    }

    static open(dialog: MatDialog, doc: CompanyAnnualPlanningDocumentDto) {
        return BaseEditAttachmentsDialogComponent.openInternal(EditCompanyAnnualPlanningDocumentsDialogComponent, dialog, doc);
    }

    protected getExistingAttachments = (): AttachmentDto[] => this.doc.attachments;

    protected uploadAttachment = (file: File): Observable<unknown> =>
        this.documentApi.addPlanningAttachment(
            this.doc.company.id,
            this.doc.financialYear,
            this.doc.type,
            file
        );

    protected deleteAttachment = (path: string): Observable<unknown> => this.deleteAttachments([path]);

    protected deleteAttachments = (paths: string[]): Observable<unknown> =>
        this.documentApi.deletePlanningAttachments(
            this.doc.company.id,
            this.doc.financialYear,
            this.doc.type,
            { attachmentPaths: paths },
        );
}

import { ComponentType } from "@angular/cdk/portal";
import { Directive, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { ActionsV2Api, CaptureMethod, EntityType, GetActionDto, GetGoalDto, GetNumberDto, GetReportDto, GetWatchlistDto, PlanGoalsApi, PlanNumbersApi, PlanReportsApi, SimpleCompanyTeamDto, WatchlistItemReferenceDto, WatchlistItemsDto } from "@api";
import { BehaviorSubject, catchError, combineLatest, distinctUntilChanged, EMPTY, map, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { CompanyMenuRepository, IQuarter } from "~repositories";
import { NotificationService } from "~services/notification.service";
import { getActionProgressSortOrder } from "~shared/action-progress";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { getGoalStatusSortOrder } from "~shared/goal-status";
import { WithDestroy } from "~shared/mixins";
import { ActionColumn } from "~shared/util/action-columns";
import { AsyncTableDataSource } from "~shared/util/async-table-data-source";
import { GoalColumn } from "~shared/util/goal-columns";
import { NumberColumn } from "~shared/util/number-columns";
import { numberDescriptionSortAccessor, sortNumberDefinition } from "~shared/util/number-helper";
import { ReportColumn } from "~shared/util/report-columns";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { sortTeam } from "~shared/util/sorters";
import { defaultActionsFilterPredicate, defaultGoalsFilterPredicate, defaultNumbersFilterPredicate, defaultReportsFilterPredicate } from "~shared/util/table-filtering";
import { compareTeams, getTeamSearchData } from "~shared/util/team-helper";
import { getUserName } from "~shared/util/user-helper";
import { valueAndChanges } from "~shared/util/util";
import { buildItemReference, isUserList, ReferenceSource } from "~shared/util/watchlist-helper";

export interface EditAddWatchlistItemsDialogData<TList extends GetWatchlistDto> {
    list: TList;
    items: WatchlistItemsDto;
}

declare type ItemType = "action" | "goal" | "number" | "report";

type SimpleWatchlistReference = `${ItemType}_${string}`;

const getSimpleItemReference = (type: ItemType, id: string): SimpleWatchlistReference =>
    `${type}_${id}`;

const getSimpleActionReference = (action: GetActionDto): SimpleWatchlistReference =>
    getSimpleItemReference("action", action.id);

const getSimpleGoalReference = (goal: GetGoalDto): SimpleWatchlistReference =>
    getSimpleItemReference("goal", goal.globalId);

const getSimpleNumberReference = (number: GetNumberDto): SimpleWatchlistReference =>
    getSimpleItemReference("number", number.globalId);

const getSimpleReportReference = (report: GetReportDto): SimpleWatchlistReference =>
    getSimpleItemReference("report", report.globalId);

interface ItemForm<TItem> {
    readonly watchedControl: FormControl<boolean>;
    readonly reference: WatchlistItemReferenceDto;
    readonly simpleReference: SimpleWatchlistReference;
    readonly item: TItem;
}

interface ActionTableInput {
    team: SimpleCompanyTeamDto | null;
    includeAll: boolean;
}

declare type WatchlistColumn<TCol extends string> = TCol | "watched";

declare type DataState = "loading" | "loaded" | "failed";

@Directive()
export abstract class BaseAddWatchlistItemsDialogComponent
    <TList extends GetWatchlistDto, TData extends EditAddWatchlistItemsDialogData<TList>>
    extends WithDestroy() implements OnInit, OnDestroy {

    @ViewChild("actionsSort", { static: false }) set actionsSort(value: MatSort | null) {
        this.actionsDataSource.sort = value;
    }

    get actionsSort(): MatSort | null {
        return this.actionsDataSource.sort;
    }

    @ViewChild("goalsSort", { static: false }) set goalsSort(value: MatSort | null) {
        this.goalsDataSource.sort = value;
    }

    get goalsSort(): MatSort | null {
        return this.goalsDataSource.sort;
    }

    @ViewChild("numbersSort", { static: false }) set numbersSort(value: MatSort | null) {
        this.numbersDataSource.sort = value;
    }

    get numbersSort(): MatSort | null {
        return this.numbersDataSource.sort;
    }

    @ViewChild("reportsSort", { static: false }) set reportsSort(value: MatSort | null) {
        this.reportsDataSource.sort = value;
    }

    get reportsSort(): MatSort | null {
        return this.reportsDataSource.sort;
    }

    get quarter(): IQuarter | null {
        return this.quarterSubject.value;
    }

    set quarter(value: IQuarter | null) {
        this.quarterSubject.next(value);
    }

    readonly teamControl = new FormControl<SimpleCompanyTeamDto | null>(null);
    readonly typeControl = new FormControl<ItemType>("action", { nonNullable: true });
    readonly showAllActionsControl = new FormControl<boolean>(false, { nonNullable: true });

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;

    readonly actionsDataSource: AsyncTableDataSource<ItemForm<GetActionDto>, ActionColumn, ActionTableInput>;
    readonly goalsDataSource = new MatTableDataSource<ItemForm<GetGoalDto>>();
    readonly numbersDataSource = new MatTableDataSource<ItemForm<GetNumberDto>>();
    readonly reportsDataSource = new MatTableDataSource<ItemForm<GetReportDto>>();

    readonly actionColumns: WatchlistColumn<ActionColumn>[] =
        ["watched", "description", "status", "creator", "owner", "dueDate", "department", "category"];

    readonly goalColumns: WatchlistColumn<GoalColumn>[] =
        ["watched", "heading", "description", "status", "owner", "department", "category"];

    readonly numberColumns: WatchlistColumn<NumberColumn>[] =
        ["watched", "description", "resultSummary", "owner", "updater", "department", "category"];

    readonly reportColumns: WatchlistColumn<ReportColumn>[] =
        ["watched", "description", "reportSummary", "owner", "updater", "department", "category"];

    goalsState: DataState = "loading";
    numbersState: DataState = "loading";
    reportsState: DataState = "loading";

    readonly list: TList;

    readonly getUserName = getUserName;
    readonly compareTeams = compareTeams;
    readonly getTeamSearchData = getTeamSearchData;

    protected readonly listReferences: Set<SimpleWatchlistReference>;

    protected readonly quarterSubject = new BehaviorSubject<IQuarter | null>(null);
    protected readonly goalRefreshSubject = new BehaviorSubject<void>(undefined);
    protected readonly numberRefreshSubject = new BehaviorSubject<void>(undefined);
    protected readonly reportRefreshSubject = new BehaviorSubject<void>(undefined);

    protected readonly subscriptions = new Subscription();

    private readonly watchedFormCache = new Map<SimpleWatchlistReference, FormControl<boolean>>();

    private readonly goals$: Observable<ItemForm<GetGoalDto>[]>;
    private readonly numbers$: Observable<ItemForm<GetNumberDto>[]>;
    private readonly reports$: Observable<ItemForm<GetReportDto>[]>;

    constructor(
        private readonly companyMenuRepository: CompanyMenuRepository,
        private readonly actionsApi: ActionsV2Api,
        private readonly planGoalsApi: PlanGoalsApi,
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly planReportsApi: PlanReportsApi,
        private readonly notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) data: TData,
    ) {
        super();

        this.list = data.list;

        this.listReferences = new Set([
            ...data.items.actions.map(getSimpleActionReference),
            ...data.items.goals.map(getSimpleGoalReference),
            ...data.items.numbers.map(getSimpleNumberReference),
            ...data.items.reports.map(getSimpleReportReference),
        ]);

        const clientId = isUserList(data.list) ? data.list.clientId : data.list.company.clientId;

        this.teams$ = this.companyMenuRepository.getMenuItems().pipe(
            map(companies => companies.filter(c => c.company.clientId === clientId)),
            map(companies => companies.map(({ company, teams }) =>
                teams.map(team => ({ company, ...team }))
            ).flat().sort(sortTeam.ascending())),
            shareReplayUntil(this.destroyed$),
        );

        const period$ = this.quarterSubject.pipe(
            map(quarter => quarter ? toFiscalQuarter(quarter) : null),
            distinctUntilChanged(),
        );

        const team$ = valueAndChanges(this.teamControl).pipe(
            shareReplayUntil(this.destroyed$),
        );

        const includePrivate = isUserList(data.list); // allowed for user-typed lists.

        this.actionsDataSource = new AsyncTableDataSource<ItemForm<GetActionDto>, ActionColumn, ActionTableInput>(
            combineLatest({
                team: team$,
                includeAll: valueAndChanges(this.showAllActionsControl),
            }),
            (sort, userId, skip, take, { team, includeAll }) => {
                if (!team) return of({ data: [], hasMore: false });
                return this.actionsApi.paginateActions(
                    team.company.id, team.id, skip, take,
                    sort?.column ?? "dueDate", sort?.direction ?? "asc",
                    includeAll, includePrivate, userId ?? undefined
                ).pipe(
                    map(result => ({
                        data: result.data.map(a => this.buildItemForm(a, EntityType.action, getSimpleActionReference(a))),
                        hasMore: result.hasMore,
                    })),
                );
            },
            (o1, o2) => o1.item.id === o2.item.id,
        );

        this.actionsDataSource.sortingDataAccessor = ({ item }: ItemForm<GetActionDto>, property: string) => {
            switch (property as ActionColumn) {
                case "description": return item.description;
                case "status": return getActionProgressSortOrder(item.progress);
                case "priority": return item.priority as number ?? 0;
                case "owner": return getUserName(item.owner);
                case "creator": return getUserName(item.creator);
                case "team": return item.team.name;
                case "createdDate": return item.createdDate;
                case "dueDate": return item.dueDate;
                case "department": return item.department?.name ?? "";
                case "category": return item.category?.description ?? "";
                case "subCategory": return item.category?.subCategory?.description ?? "";
                default: return (item as never)[property] ?? "";
            }
        };

        this.actionsDataSource.filterPredicate = ({ item }, ownerId) => defaultActionsFilterPredicate(item, ownerId);

        this.goals$ = combineLatest({
            team: team$,
            period: period$,
        }).pipe(
            withRefresh(this.goalRefreshSubject),
            tap(() => this.goalsState = "loading"),
            switchMap(({ team, period }) => {
                if (!team || !period) return of([]);
                return this.planGoalsApi.listGoals(team.company.id, team.id, period).pipe(
                    map(l => l.goals.filter(g => includePrivate || !g.isPrivate)),
                    tap(() => this.goalsState = "loaded"),
                    catchError(() => {
                        this.goalsState = "failed";
                        return of([]);
                    }),
                );
            }),
            map(goals => goals.map(g => this.buildItemForm(g, EntityType.goal, getSimpleGoalReference(g)))),
        );

        this.goalsDataSource.sortingDataAccessor = ({ item }: ItemForm<GetGoalDto>, property: string) => {
            switch (property as GoalColumn) {
                case "heading": return item.heading;
                case "description": return item.description;
                case "owner": return getUserName(item.owner);
                case "team": return item.team.name;
                case "dueDate": return item.dueDate;
                case "status": return item.latestStatus == null ? -1 : getGoalStatusSortOrder(item.latestStatus);
                case "department": return item.department?.name ?? "";
                case "category": return item.category?.description ?? "";
                case "subCategory": return item.category?.subCategory?.description ?? "";
                default: return (item as never)[property] ?? "";
            }
        };

        this.goalsDataSource.filterPredicate = ({ item }, ownerId) => defaultGoalsFilterPredicate(item, ownerId);

        this.numbers$ = combineLatest({
            team: team$,
            period: period$,
        }).pipe(
            withRefresh(this.numberRefreshSubject),
            tap(() => this.numbersState = "loading"),
            switchMap(({ team, period }) => {
                if (!team || !period) return of([]);
                return this.planNumbersApi.listNumbers(team.company.id, team.id, period).pipe(
                    map(l => l.numbers.filter(n => includePrivate || !n.isPrivate)),
                    map(numbers => numbers.sort(sortNumberDefinition.ascending())),
                    tap(() => this.numbersState = "loaded"),
                    catchError(() => {
                        this.numbersState = "failed";
                        return of([]);
                    }),
                );
            }),
            map(numbers => numbers.map(n => this.buildItemForm(n, EntityType.number, getSimpleNumberReference(n)))),
        );

        this.numbersDataSource.sortingDataAccessor = ({ item }: ItemForm<GetNumberDto>, property: string) => {
            switch (property as NumberColumn) {
                case "description": return numberDescriptionSortAccessor(item);
                case "owner": return getUserName(item.owner);
                case "updater": return getUserName(item.updater);
                case "team": return item.team.name;
                case "department": return item.department?.name ?? "";
                case "category": return item.category?.description ?? "";
                case "subCategory": return item.category?.subCategory?.description ?? "";
                default: return (item as never)[property] ?? "";
            }
        };

        this.numbersDataSource.filterPredicate = ({ item }, ownerId) => defaultNumbersFilterPredicate(item, ownerId);

        this.reports$ = combineLatest({
            team: team$,
            period: period$,
        }).pipe(
            withRefresh(this.reportRefreshSubject),
            tap(() => this.reportsState = "loading"),
            switchMap(({ team, period }) => {
                if (!team || !period) return of([]);
                return this.planReportsApi.listReports(team.company.id, team.id, period).pipe(
                    map(l => l.reports),
                    tap(() => this.reportsState = "loaded"),
                    catchError(() => {
                        this.reportsState = "failed";
                        return of([]);
                    }),
                );
            }),
            map(reports => reports.map(r => this.buildItemForm(r, EntityType.report, getSimpleReportReference(r)))),
        );

        this.reportsDataSource.sortingDataAccessor = ({ item }: ItemForm<GetReportDto>, property: string) => {
            switch (property as ReportColumn) {
                case "description": return item.description;
                case "owner": return getUserName(item.owner);
                case "updater": return getUserName(item.updater);
                case "team": return item.team.name;
                case "department": return item.department?.name ?? "";
                case "category": return item.category?.description ?? "";
                case "subCategory": return item.category?.subCategory?.description ?? "";
                default: return (item as never)[property] ?? "";
            }
        };

        this.reportsDataSource.filterPredicate = ({ item }, ownerId) => defaultReportsFilterPredicate(item, ownerId);
    }

    protected static openInternal<TList extends GetWatchlistDto, TData extends EditAddWatchlistItemsDialogData<TList>>(
        component: ComponentType<BaseAddWatchlistItemsDialogComponent<TList, TData>>,
        dialog: MatDialog, data: TData): MatDialogRef<unknown, void> {
        return dialog.open(component, {
            width: "1200px",
            data,
        });
    }

    protected abstract setWatched(item: WatchlistItemReferenceDto, watched: boolean): Observable<unknown>;

    ngOnInit(): void {
        this.subscriptions.add(valueAndChanges(this.teamControl).pipe(
            distinctUntilChanged((x, y) => x?.company.id === y?.company.id),
        ).subscribe(() => this.quarterSubject.next(null)));

        this.subscriptions.add(this.typeControl.valueChanges.subscribe(() => {
            // When we change type, we should reset all filters.
            // This is partially as the filter control can otherwise become out of sync.
            this.actionsDataSource.filter = null;
            this.goalsDataSource.filter = "";
            this.numbersDataSource.filter = "";
            this.reportsDataSource.filter = "";
        }));

        this.subscriptions.add(this.goals$.subscribe(goals => this.goalsDataSource.data = goals));
        this.subscriptions.add(this.numbers$.subscribe(numbers => this.numbersDataSource.data = numbers));
        this.subscriptions.add(this.reports$.subscribe(reports => this.reportsDataSource.data = reports));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    refreshGoals = () => this.goalRefreshSubject.next();
    refreshNumbers = () => this.numberRefreshSubject.next();
    refreshReports = () => this.reportRefreshSubject.next();

    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined) => team?.name ?? "";

    asActionRow = (item: ItemForm<GetActionDto>) => item;
    asGoalRow = (item: ItemForm<GetGoalDto>) => item;
    asNumberRow = (item: ItemForm<GetNumberDto>) => item;
    asReportRow = (item: ItemForm<GetReportDto>) => item;

    numberAllowsUpdater = (number: GetNumberDto) => number.captureMethod === CaptureMethod.manual;

    trackById = (_: number, item: ItemForm<{ id: string }>) => item.item.id;

    private buildItemForm = <TItem extends ReferenceSource>(item: TItem, type: EntityType, simpleReference: SimpleWatchlistReference):
        ItemForm<TItem> => {
        const reference = buildItemReference(item, type);
        let watchedControl = this.watchedFormCache.get(simpleReference);
        if (watchedControl) {
            return {
                watchedControl,
                reference,
                simpleReference,
                item,
            };
        }

        watchedControl = new FormControl(this.listReferences.has(simpleReference), { nonNullable: true });
        const form = {
            watchedControl,
            reference,
            simpleReference,
            item,
        };
        this.subscriptions.add(this.subscribeToWatchedState(form));
        this.watchedFormCache.set(simpleReference, watchedControl);
        return form;
    };

    private subscribeToWatchedState = <TItem>(form: ItemForm<TItem>): Subscription =>
        form.watchedControl.valueChanges.pipe(
            switchMap(watched => this.setWatched(form.reference, watched).pipe(
                catchError(() => {
                    this.notificationService.errorUnexpected();
                    form.watchedControl.reset(this.listReferences.has(form.simpleReference), { emitEvent: false });
                    return EMPTY;
                }),
                tap(() => {
                    form.watchedControl.reset(watched, { emitEvent: false });
                    if (watched) {
                        this.listReferences.add(form.simpleReference);
                    } else {
                        this.listReferences.delete(form.simpleReference);
                    }
                }),
            )),
        ).subscribe();
}

import { Injectable } from "@angular/core";

export interface IQuarter {
    financialYear: number;
    quarter: number;
}

interface IQuarterCache {
    [companyId: string]: IQuarter;
}

@Injectable()
export class QuarterContextRepository {

    private selectedQuarters: IQuarterCache = {};

    getSelectedQuarter = (companyId: string): IQuarter | null =>
        this.selectedQuarters[companyId] ?? null;

    setSelectedQuarter = (companyId: string, value: IQuarter): void => {
        this.selectedQuarters[companyId] = value;
    };
}

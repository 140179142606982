import { Injectable } from "@angular/core";
import {
    CompanyTeamActionFeedApi, CompanyTeamFeedApi, FeedPartitionDto, GetActionDto, GetGoalDto, GetNewsItemDto, GetNumberDto,
    GetReportDto, GoalFeedApi, NewsItemFeedApi, NumberFeedApi, ReferredSolutionDto, ReferredSolutionFeedApi,
    ReportFeedApi, SimpleCompanyDto, SimpleTeamDto
} from "@api";

import { toFiscalQuarter } from "~shared/commonfunctions";

import { CompanyTeamActionFeedAdapter } from "./company-team-action-feed.adapter";
import { CompanyTeamFeedAdapter } from "./company-team-feed.adapter";
import { IFeedAdapter } from "./feed.adapter";
import { FeedPartitionKey, FeedPartitionKeyType } from "./feed.common";
import { GoalFeedAdapter } from "./goal-feed.adapter";
import { NewsItemFeedAdapter } from "./news-item-feed.adapter";
import { NumberFeedAdapter } from "./number-feed.adapter";
import { ReferredSolutionFeedAdapter } from "./referred-solution-feed.adapter";
import { ReportFeedAdapter } from "./report-feed.adapter";

export interface ItemWithFeedPartition {
    company: SimpleCompanyDto;
    team: SimpleTeamDto;
    feedPartition?: FeedPartitionDto;
}

@Injectable({
    providedIn: "root",
})
export class FeedAdapterBuilder {

    constructor(
        private readonly companyTeamFeedApi: CompanyTeamFeedApi,
        private readonly companyTeamActionFeedApi: CompanyTeamActionFeedApi,
        private readonly goalFeedApi: GoalFeedApi,
        private readonly numberFeedApi: NumberFeedApi,
        private readonly reportFeedApi: ReportFeedApi,
        private readonly newsItemFeedApi: NewsItemFeedApi,
        private readonly referredSolutionFeedApi: ReferredSolutionFeedApi,
    ) { }

    build = (
        item: ItemWithFeedPartition,
        options?: { partitionType?: FeedPartitionKeyType; initialKey?: FeedPartitionKey },
    ): IFeedAdapter | null => {
        if (!item.feedPartition) return null;
        return new CompanyTeamFeedAdapter({
            context: {
                companyId: item.company.id,
                teamId: item.team.id,
            },
            partition: item.feedPartition,
            ...options,
        }, this.companyTeamFeedApi);
    };

    buildForAction = (action: GetActionDto): IFeedAdapter | null => {
        if (!action.feedPartition) return null;
        if (action.isDelegated) {
            if (!action.delegation) return null;
            return new CompanyTeamActionFeedAdapter({
                context: {
                    companyId: action.delegation.company.id,
                    teamId: action.delegation.team.id,
                    actionId: action.id,
                },
                partition: action.feedPartition,
                partitionType: FeedPartitionKeyType.sequence,
            }, this.companyTeamActionFeedApi);
        } else {
            return this.build(action, { partitionType: FeedPartitionKeyType.sequence });
        }
    };

    buildForGoal = (goal: GetGoalDto): IFeedAdapter | null => {
        if (!goal.feedPartition) return null;
        const initialKey = [goal.financialYear, goal.planningPeriod];
        if (goal.isDelegated) {
            if (!goal.delegation) return null;
            return new GoalFeedAdapter({
                context: {
                    companyId: goal.delegation.company.id,
                    teamId: goal.delegation.team.id,
                    period: toFiscalQuarter({
                        financialYear: goal.financialYear,
                        quarter: goal.planningPeriod,
                    }),
                    goalId: goal.id,
                },
                partition: goal.feedPartition,
                initialKey,
            }, this.goalFeedApi);
        } else {
            return this.build(goal, { initialKey });
        }
    };

    buildForNumber = (number: GetNumberDto): IFeedAdapter | null => {
        if (!number.feedPartition) return null;
        const initialKey = [number.financialYear, number.planningPeriod];
        if (number.isDelegated) {
            if (!number.delegation) return null;
            return new NumberFeedAdapter({
                context: {
                    companyId: number.delegation.company.id,
                    teamId: number.delegation.team.id,
                    period: toFiscalQuarter({
                        financialYear: number.financialYear,
                        quarter: number.planningPeriod,
                    }),
                    numberId: number.id,
                },
                partition: number.feedPartition,
                initialKey,
            }, this.numberFeedApi);
        } else {
            return this.build(number, { initialKey });
        }
    };

    buildForReport = (report: GetReportDto): IFeedAdapter | null => {
        if (!report.feedPartition) return null;
        const initialKey = [report.financialYear, report.planningPeriod];
        if (report.isDelegated) {
            if (!report.delegation) return null;
            return new ReportFeedAdapter({
                context: {
                    companyId: report.delegation.company.id,
                    teamId: report.delegation.team.id,
                    period: toFiscalQuarter({
                        financialYear: report.financialYear,
                        quarter: report.planningPeriod,
                    }),
                    reportId: report.id,
                },
                partition: report.feedPartition,
                initialKey,
            }, this.reportFeedApi);
        } else {
            return this.build(report, { initialKey });
        }
    };

    buildForNewsItem = (newsItem: GetNewsItemDto): IFeedAdapter | null => {
        if (!newsItem.feedPartition) return null;
        return new NewsItemFeedAdapter({
            context: {
                companyId: newsItem.currentTeam.company.id,
                teamId: newsItem.currentTeam.id,
                newsItemId: newsItem.id,
            },
            partition: newsItem.feedPartition,
        }, this.newsItemFeedApi);
    };

    buildForReferredSolution = (solution: ReferredSolutionDto): IFeedAdapter | null => {
        if (!solution.feedPartition) return null;
        return new ReferredSolutionFeedAdapter({
            context: {
                companyId: solution.company.id,
                teamId: solution.team.id,
                solutionId: solution.id,
            },
            partition: solution.feedPartition,
        }, this.referredSolutionFeedApi);
    };
}

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { IFeedAdapter } from "./feed.adapter";

@Injectable()
export abstract class FeedScope {
    abstract adapter$: Observable<IFeedAdapter | null>;
    abstract adapter: IFeedAdapter | null;
}

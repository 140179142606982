import { AriaDescriber, FocusMonitor } from "@angular/cdk/a11y";
import { Directionality } from "@angular/cdk/bidi";
import { Overlay } from "@angular/cdk/overlay";
import { Platform } from "@angular/cdk/platform";
import { ScrollDispatcher } from "@angular/cdk/scrolling";
import { DOCUMENT } from "@angular/common";
import { Directive, ElementRef, Inject, Input, NgZone, Optional, ViewContainerRef } from "@angular/core";
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MAT_TOOLTIP_SCROLL_STRATEGY,MatTooltip, MatTooltipDefaultOptions } from "@angular/material/tooltip";

@Directive({
    selector: "[appPlanTooltip]",
    exportAs: "appPlanTooltip",
})
export class PlanTooltipDirective extends MatTooltip {

    @Input("appPlanTooltip")
    get tooltip(): string {
        return this.message;
    }

    set tooltip(value: string) {
        this.message = value;
    }

    constructor(
        _overlay: Overlay,
        _elementRef: ElementRef,
        _scrollDispatcher: ScrollDispatcher,
        _viewContainerRef: ViewContainerRef,
        _ngZone: NgZone,
        _platform: Platform,
        _ariaDescriber: AriaDescriber,
        _focusMonitor: FocusMonitor,
        @Inject(MAT_TOOLTIP_SCROLL_STRATEGY) _scrollStrategy: any,
        @Optional() _dir: Directionality,
        @Optional() @Inject(MAT_TOOLTIP_DEFAULT_OPTIONS)
        _defaultOptions: MatTooltipDefaultOptions,
        @Inject(DOCUMENT) _document: any) {
        super(
            _overlay,
            _elementRef,
            _scrollDispatcher,
            _viewContainerRef,
            _ngZone,
            _platform,
            _ariaDescriber,
            _focusMonitor,
            _scrollStrategy,
            _dir,
            _defaultOptions,
            _document
        );

        this.tooltipClass = "feature-tooltip";
        this.position = "right";
    }

}

/* eslint-disable max-len */
import { ActionSuggestionDto } from "@api";

export const exampleActions: ActionSuggestionDto[] = [
    {
        "description": "Conduct a thorough audit of the current network security measures and identify any potential vulnerabilities."
    },
    {
        "description": "Implement multi-factor authentication for all employees accessing the network."
    },
    {
        "description": "Create and enforce a strong password policy for all network users."
    },
    {
        "description": "Regularly update and patch all software and systems used on the network."
    },
    {
        "description": "Implement a firewall to monitor and control incoming and outgoing network traffic."
    },
    {
        "description": "Train all employees on proper security protocols and procedures to prevent cyber attacks."
    },
    {
        "description": "Hire a dedicated security team to monitor and respond to any potential threats or breaches."
    },
    {
        "description": "Implement data encryption for all sensitive information stored on the network."
    },
    {
        "description": "Conduct regular penetration testing to identify any weaknesses in the network security."
    },
    {
        "description": "Implement a disaster recovery plan in case of a security breach or cyber attack."
    }
];

import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, ValidatorFn, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AttachmentDto, ReportRecordDetailDto, RequireNote, UpdateReportsApi } from "@api";
import { distinctUntilChanged, map, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { ReportStateService } from "~services/state";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ReportCollectionType } from "~shared/enums";
import { ACCEPT_DOCUMENT_OR_IMAGE } from "~shared/util/attachments";
import { NOTES_MAX_LENGTH } from "~shared/util/constants";
import { LINK_PATTERN } from "~shared/util/custom-validators";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isNoteEnforcementEnabled } from "~shared/util/feature-helper";

import { BaseUpdateAttachmentsDialogComponent } from "../base-edit-attachments-dialog/base-edit-attachments-dialog.component";

const getNotesValidators = (required: boolean): ValidatorFn[] => [
    ...(required ? [Validators.required] : []),
    Validators.maxLength(NOTES_MAX_LENGTH),
];

@Component({
    templateUrl: "./report-attachments-dialog.component.html",
    styleUrls: ["./report-attachments-dialog.component.scss"],
})
export class ReportAttachmentsDialogComponent extends BaseUpdateAttachmentsDialogComponent<ReportRecordDetailDto>
    implements OnInit, OnDestroy {

    readonly maxNotesLength = NOTES_MAX_LENGTH;

    get description(): string {
        return this.record.description;
    }

    get isExternallyUpdated(): boolean {
        return this.record.collectionType === ReportCollectionType.externallyUpdated;
    }

    get permanentLinks(): string[] {
        return this.record.permanentLinks ?? [];
    }

    get hasChanges() {
        return super.hasChanges || this.form.dirty;
    }

    get hasNoFiles() {
        return super.hasNoFiles && !this.linksControl.value.length && (!this.isExternallyUpdated || !this.permanentLinks.length);
    }

    get canEdit(): boolean {
        return this.record.canEdit;
    }

    get isValid(): boolean {
        return this.form.valid;
    }

    readonly updatedControl = new FormControl<boolean>(false, { nonNullable: true });
    readonly notesControl = new FormControl<string | null>(null, getNotesValidators(false));
    readonly linksControl = new FormControl<string[]>([], { nonNullable: true });

    readonly form = new FormGroup({
        updated: this.updatedControl,
        notes: this.notesControl,
        links: this.linksControl,
    });

    readonly linkControl = new FormControl<string | null>(null);

    readonly record: Readonly<ReportRecordDetailDto>;

    get statusNotesEnabled() {
        return this.teamContext.features.statusNotesEnabled();
    }

    readonly attachmentAcceptTypes = ACCEPT_DOCUMENT_OR_IMAGE;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly updateReportsApi: UpdateReportsApi,
        private readonly reportStateService: ReportStateService,
        private readonly teamContext: TeamContext,
        @Inject(MAT_DIALOG_DATA) record: ReportRecordDetailDto,
        notificationService: NotificationService,
        dialogRef: MatDialogRef<ReportAttachmentsDialogComponent, ReportRecordDetailDto>
    ) {
        super(notificationService, dialogRef);
        this.record = record;
        this.updatedControl.reset(record.externallyUpdated ?? false);
        this.notesControl.reset(record.notes ?? null);
        this.linksControl.reset([...record.links ?? []]);
        if (!this.canEdit) {
            this.form.disable();
        } else if (record.collectionType !== ReportCollectionType.externallyUpdated) {
            this.updatedControl.disable();
        }
    }

    static open(dialog: MatDialog, record: ReportRecordDetailDto) {
        return BaseUpdateAttachmentsDialogComponent.openInternal(ReportAttachmentsDialogComponent, dialog, record);
    }

    ngOnInit(): void {
        this.subscriptions.add(this.teamContext.companyTeam$.pipe(
            map(isNoteEnforcementEnabled),
            map(enabled => enabled ? this.record.requireNote : RequireNote.never),
            // For a report, the "when off-target" setting is impossible, so for simplicity treat it as "never"
            map(requireNote => requireNote === RequireNote.always),
            distinctUntilChanged(),
        ).subscribe(noteRequired => {
            this.notesControl.setValidators(getNotesValidators(noteRequired));
            this.notesControl.updateValueAndValidity();
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    addLink = () => {
        let linkValue = this.linkControl.value;
        if (!this.linkControl.valid || !linkValue) return;

        if (!LINK_PATTERN.test(linkValue)) linkValue = "http://" + linkValue;

        const links = this.linksControl.value;
        this.linksControl.setValue([...links, linkValue]);
        this.linksControl.markAsDirty();
        this.linksControl.markAsTouched();
        this.linkControl.reset();
    };

    removeLink = (index: number) => {
        const links = [...this.linksControl.value];
        links.splice(index, 1);
        this.linksControl.setValue(links);
        this.linksControl.markAsDirty();
        this.linksControl.markAsTouched();
    };

    protected getExistingAttachments = (): AttachmentDto[] => this.record.reports ?? [];

    protected updateAttachments = (newAttachments: File[], removedAttachments: string[]): Observable<ReportRecordDetailDto> => {
        const updated = this.updatedControl.value;
        const links = this.linksControl.value;
        // If the feature is not enabled, we send no note - this will keep the existing value.
        // Sending an empty string will otherwise clear out the notes.
        const notes = this.statusNotesEnabled ? (this.notesControl.value ?? "") : undefined;

        return of(this.record).pipe(
            switchMap(record => {
                if (record.collectionType !== ReportCollectionType.externallyUpdated && record.externallyUpdated === updated) {
                    return of(record);
                }
                const { company, team } = getDelegatedItemCompanyTeam(record);
                return this.updateReportsApi.markExternallyUpdated(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: record.financialYear, quarter: record.planningPeriod }),
                    record.week,
                    record.id,
                    { updated, notes },
                );
            }),
            switchMap(record => {
                const { company, team } = getDelegatedItemCompanyTeam(record);
                return this.updateReportsApi.updateAttachments(
                    company.id,
                    team.id,
                    toFiscalQuarter({ financialYear: record.financialYear, quarter: record.planningPeriod }),
                    record.week,
                    record.id,
                    newAttachments,
                    removedAttachments,
                    links,
                    notes,
                );
            }),
            tap(report => this.reportStateService.notifyUpdate(report)),
        );
    };

}

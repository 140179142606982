import { ChangeDetectionStrategy, Component } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetReportDto, ReportRecordDetailDto } from "@api";

import { TeamContext } from "~services/contexts";
import { ReportStateService } from "~services/state";
import { mapReportActionInput } from "~shared/util/action-input-builder";
import { mapReportOrigin } from "~shared/util/origin-builder";

import { CommonAddChildActionButtonDirective } from "./add-child-action-button.component";

declare type ReportDto = GetReportDto | ReportRecordDetailDto;

@Component({
    selector: "app-add-report-child-action-button",
    templateUrl: "./add-child-action-button.component.html",
    styleUrls: ["./add-child-action-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddReportChildActionButtonComponent extends CommonAddChildActionButtonDirective<ReportDto> {

    readonly mapToOrigin = mapReportOrigin;
    readonly mapToInput = mapReportActionInput;

    constructor(
        teamContext: TeamContext,
        dialog: MatDialog,
        private readonly reportStateService: ReportStateService,
    ) {
        super(teamContext, dialog);
    }

    protected notifyChange = (source: ReportDto) => this.reportStateService.notifyRefresh(source);

    protected creationAllowed = (source: ReportDto): boolean => source.canEdit;
}

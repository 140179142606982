import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatChipsModule } from "@angular/material/chips";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { TimeagoModule } from "ngx-timeago";

import { SharedModule } from "~shared/shared.module";

import * as components from "./components";

@NgModule({
    declarations: [
        components.FeedListComponent,
        components.FeedListCommentComponent,
        components.FeedListLinkComponent,
        components.FeedListAttachmentComponent,
        components.FeedListStatusComponent,
        components.FeedListApprovalComponent,
        components.FeedListLinkedEntityComponent,
        components.FeedListItemContentComponent,
        components.FeedListItemActionsDirective,
        components.FeedListItemComponent,
        components.FeedPartitionContextComponent,
        components.FeedPartitionContextMenuComponent,
        components.FeedCreationInfoComponent,
        components.AddCommentComponent,
        components.AddLinkComponent,
        components.AddItemHeaderComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TranslateModule,
        SharedModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatChipsModule,
        MatIconModule,
        MatMenuModule,
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatFormFieldModule,
        MatInputModule,
        TimeagoModule,
    ],
    exports: [
        components.FeedListComponent,
    ]
})
export class FeedModule { }

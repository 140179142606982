import { Component, Input, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GetNumberDto } from "@api";

import { getIsoDayOfWeekOrder } from "~shared/util/sorters";
import { getDayOfWeekNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

import { NumberHomepageDialogComponent } from "../../dialogs";

@Component({
    selector: "app-daily-numbers-table",
    templateUrl: "./daily-numbers-table.component.html",
    styleUrl: "./daily-numbers-table.component.scss"
})
export class DailyNumbersTableComponent {
    @Input() set numbers(value: GetNumberDto[]) {
        this.dataSource.data = value;
    }

    get numbers(): GetNumberDto[] {
        return this.dataSource.data;
    }

    @ViewChild(MatSort, { static: true }) set sort(value: MatSort | null) {
        this.dataSource.sort = value;
    }

    get sort(): MatSort | null {
        return this.dataSource.sort;
    }

    readonly dataSource = new MatTableDataSource<GetNumberDto>();
    readonly displayedColumns = ["updateDay", "updater", "resultSummary"];

    readonly getDayOfWeekNameKey = getDayOfWeekNameKey;
    readonly getUserName = getUserName;

    constructor(
        private readonly dialog: MatDialog,
    ) {
        this.dataSource.sortingDataAccessor = (data: GetNumberDto, sortHeaderId: string) => {
            switch (sortHeaderId) {
                case "updateDay": return getIsoDayOfWeekOrder(data.updateDay);
                case "updater": return getUserName(data.updater);
                default:
                    return (data as never)[sortHeaderId] ?? "";
            }
        };
    }

    view = (number: GetNumberDto) => NumberHomepageDialogComponent.open(this.dialog, number);
}

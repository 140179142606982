import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DiscussionsApi, SolvedDiscussionDto } from "@api";

import { NotificationService } from "~services/notification.service";
import { DiscussionStateService } from "~services/state";
import { ButtonState } from "~shared/components/status-button/status-button.component";
import { WfDialog } from "~shared/dialog";


interface INoteSolutionApprovalDialogData {
    discussion: SolvedDiscussionDto;
    meetingRunning: boolean;
}

export interface INoteSolutionApprovalDialogResult {
    status: "noted" | "edit";
    discussion: SolvedDiscussionDto;
}

@Component({
    selector: "app-note-solution-approval-dialog",
    templateUrl: "./note-solution-approval-dialog.component.html",
    styleUrls: ["./note-solution-approval-dialog.component.scss"]
})
export class NoteSolutionApprovalDialogComponent {

    buttonState: ButtonState;

    readonly discussion: SolvedDiscussionDto;
    readonly meetingRunning: boolean;

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly discussionStateService: DiscussionStateService,
        private readonly notificationService: NotificationService,
        private readonly dialogRef: MatDialogRef<NoteSolutionApprovalDialogComponent, INoteSolutionApprovalDialogResult>,
        @Inject(MAT_DIALOG_DATA) { discussion, meetingRunning }: INoteSolutionApprovalDialogData,
    ) {
        this.discussion = discussion;
        this.meetingRunning = meetingRunning;
    }

    static open(dialog: WfDialog, discussion: SolvedDiscussionDto, meetingRunning: boolean) {
        return dialog.open<NoteSolutionApprovalDialogComponent, INoteSolutionApprovalDialogData, INoteSolutionApprovalDialogResult>(
            NoteSolutionApprovalDialogComponent,
            {
                maxWidth: "none",
                autoFocus: "dialog",
                data: { discussion, meetingRunning },
                showCloseButton: false, // The close button is emitted by the homepage scaffold
            });
    }

    markNoted = () => {
        if (this.buttonState || !this.meetingRunning) return;

        this.buttonState = "loading";
        this.discussionsApi.markSolutionAsNoted(
            this.discussion.company.id,
            this.discussion.team.id,
            this.discussion.id,
        ).subscribe({
            next: discussion => {
                this.buttonState = "success";
                this.discussionStateService.notifyUpdate(discussion);
                setTimeout(() => {
                    this.dialogRef.close({ status: "noted", discussion });
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                this.notificationService.errorUnexpected();
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
            },
        });
    };

    editSolution = () => this.dialogRef.close({ status: "edit", discussion: this.discussion });
}

<form [formGroup]="form" (submit)="cancelMeeting()">
    <h4 mat-dialog-title>{{'NewMeetingStartScreen.cancelMeeting' | translate}}</h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <mat-form-field>
                    <mat-label>{{'NewMeetingStartScreen.reason' | translate}}</mat-label>
                    <textarea matInput class="reason-textarea" name="reason" formControlName="reason"
                        autocomplete="off" maxlength="500" required></textarea>
                    <mat-error *ngIf="reasonControl.hasError('required')">
                        {{'NewMeetingStartScreen.reasonRequired' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <app-status-button type="submit" [state]="buttonState" [visualDisable]="!form.valid">
            {{ "submit" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
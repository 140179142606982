import { Role } from "~/app/shared/enums";
import { getProfileRoles } from "~/app/shared/util/profile-helper";

import { authGuardBuilder } from "./base-auth.guard";
import { CustomRouteData } from "./custom-route";

export const roleGuard = authGuardBuilder(({ user, route }) => {
    const routeData = route.data as CustomRouteData;
    let roles: Role[] = [];
    if (routeData.profile) {
        roles = getProfileRoles(routeData.profile);
    }

    if (!roles || !roles.length) {
        roles = routeData.roles || [];
    }

    const currentRole = user?.roleName as Role | undefined;
    if (!currentRole) return false;
    return roles.includes(currentRole);
});

/* eslint-disable max-classes-per-file */
import { trigger } from "@angular/animations";
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { booleanAttribute, Component, ContentChild, Directive, Input, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSort } from "@angular/material/sort";
import { GetActionDto, NumberRecordDetailDto, PlanNumbersApi, SimpleUserDto } from "@api";
import { BehaviorSubject, combineLatest, map, Observable } from "rxjs";

import { NumberHomepageDialogComponent } from "~homepage";
import { TeamContext } from "~services/contexts";
import { ActionStateService } from "~services/state";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { NumberChartDialogComponent } from "~shared/dialogs";
import { CaptureMethod, EntityType } from "~shared/enums";
import { expandOnEnterAnimation } from "~shared/util/animations";
import { mergeChildUpdatesFrom } from "~shared/util/children-state-helper";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { ExpansionControllerWithLoader } from "~shared/util/expansion-controller";
import { NumberColumn } from "~shared/util/number-columns";
import { numberDescriptionSortAccessor, shouldShowCalculationSources } from "~shared/util/number-helper";
import { getSortablePeriodString } from "~shared/util/period-helper";
import { getCompanyTeamSortAccessor, getIsoDayOfWeekOrder } from "~shared/util/sorters";
import { defaultNumbersFilterPredicate } from "~shared/util/table-filtering";
import { trackByIdAndWeek } from "~shared/util/table-helper";
import { getDayOfWeekNameKey } from "~shared/util/translation-helper";
import { getUserName } from "~shared/util/user-helper";

import { GenericArrayTable } from "../generic-table/generic-table.directive";
import { ExtraTableOptionsContext } from "../generic-table/generic-table-shared";

const DEFAULT_COLUMNS: NumberColumn[] = ["description", "owner", "updater", "result", "target"];

declare type ExtendedNumberColumn = NumberColumn |
    "expand" | "actionsCount" | "updated" | "trend" | "actionDiscuss" | "options";

@Directive({
    selector: "[appExtraNumberOptions]",
})
export class ExtraNumberOptionsDirective {
    static ngTemplateContextGuard(dir: ExtraNumberOptionsDirective, ctx: unknown): ctx is ExtraTableOptionsContext<NumberRecordDetailDto> {
        return true;
    }
}

@Component({
    selector: "app-generic-numbers-table",
    templateUrl: "./generic-numbers-table.component.html",
    styleUrls: ["./generic-numbers-table.component.scss"],
    animations: [
        trigger("detailExpand", expandOnEnterAnimation),
    ],
})
export class GenericNumbersTableComponent extends GenericArrayTable<NumberRecordDetailDto, NumberColumn> {

    @Input() set columns(value: NumberColumn[] | null) {
        super.columns = value;
        const currentSort = (this.dataSource.sort?.active ?? this.defaultSort) as NumberColumn | undefined;
        if (this.columns.length && (!currentSort || !this.columns.includes(currentSort))) {
            if (this.dataSource.sort) {
                this.dataSource.sort.active = this.columns[0];
            } else {
                this.defaultSort = this.columns[0];
            }
        }
    }

    get columns(): NumberColumn[] {
        return super.columns;
    }

    @Input() set highlightUpdateRequired(value: BooleanInput) {
        this.highlightUpdateRequiredInternal = coerceBooleanProperty(value);
    }

    get highlightUpdateRequired(): boolean {
        return this.highlightUpdateRequiredInternal;
    }

    @Input() set showChildActions(value: BooleanInput) {
        this.showChildActionsSubject.next(coerceBooleanProperty(value));
    }

    get showChildActions(): boolean {
        return this.showChildActionsSubject.value;
    }

    @Input() set showUpdated(value: BooleanInput) {
        this.showUpdatedSubject.next(coerceBooleanProperty(value));
    }

    get showUpdated(): boolean {
        return this.showUpdatedSubject.value;
    }

    @Input() set showTrendButton(value: BooleanInput) {
        this.showTrendButtonSubject.next(coerceBooleanProperty(value));
    }

    get showTrendButton(): boolean {
        return this.showTrendButtonSubject.value;
    }

    @Input() set showOptions(value: BooleanInput) {
        this.showOptionsSubject.next(coerceBooleanProperty(value));
    }

    get showOptions(): boolean {
        return this.showOptionsSubject.value;
    }

    @Input() set showInlineCharts(value: BooleanInput) {
        this.showInlineChartsInternal = coerceBooleanProperty(value);
    }

    get showInlineCharts(): boolean {
        return this.showInlineChartsInternal;
    }

    @Input({ transform: booleanAttribute }) fromMeeting = false;

    @ContentChild(ExtraNumberOptionsDirective, { read: TemplateRef, static: false })
    extraOptionsTemplate: TemplateRef<ExtraTableOptionsContext<NumberRecordDetailDto>> | null = null;

    @ViewChild(MatSort) set matSort(value: MatSort) {
        this.dataSource.sort = value;
    }

    readonly displayedColumns$: Observable<ExtendedNumberColumn[]>;

    readonly isOwnerDisplayed$: Observable<boolean>;

    readonly getUserName = getUserName;
    readonly getDayOfWeekNameKey = getDayOfWeekNameKey;
    readonly trackByIdAndWeek = trackByIdAndWeek;

    get limitExceeded(): boolean {
        return this.dataSource.data.some(n => n.canEdit === false);
    }

    get canExpandAll(): boolean {
        return !!this.expandableNumbers.length;
    }

    get areAllExpanded(): boolean {
        return this.expandableNumbers.every(this.childActionsController.isExpanded);
    }

    private readonly childActionsController = new ExpansionControllerWithLoader<NumberRecordDetailDto, GetActionDto>(
        n => n.id,
        n => {
            const { company, team } = getDelegatedItemCompanyTeam(n);
            return this.planNumbersApi.getActionsForNumber(
                company.id,
                team.id,
                toFiscalQuarter({ financialYear: n.financialYear, quarter: n.planningPeriod }),
                n.id,
            ).pipe(
                mergeChildUpdatesFrom(this.actionStateService.events$, n.id, EntityType.number),
            );
        },
        this.destroyed$,
    );

    private get expandableNumbers() {
        return this.dataSource.data.filter(g => g.actionsCount);
    }

    private highlightUpdateRequiredInternal = false;
    private showInlineChartsInternal = false;
    private readonly showChildActionsSubject = new BehaviorSubject<boolean>(true);
    private readonly showUpdatedSubject = new BehaviorSubject<boolean>(false);
    private readonly showTrendButtonSubject = new BehaviorSubject<boolean>(false);
    private readonly showOptionsSubject = new BehaviorSubject<boolean>(true);

    constructor(
        private readonly planNumbersApi: PlanNumbersApi,
        private readonly actionStateService: ActionStateService,
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
    ) {
        super();

        this.dataSource.filterPredicate = defaultNumbersFilterPredicate;

        this.displayedColumns$ = combineLatest({
            columns: this.columnsSubject,
            showChildActions: this.showChildActionsSubject,
            showUpdated: this.showUpdatedSubject,
            showTrend: this.showTrendButtonSubject,
            showOptions: this.showOptionsSubject,
        }).pipe(
            map(({ columns, showChildActions, showUpdated, showTrend, showOptions }) => [
                ...(showChildActions ? ["expand", "actionsCount"] as const : []),
                ...(showUpdated ? ["updated"] as const : []),
                ...(showTrend ? ["trend"] as const : []),
                ...columns,
                ...(showOptions ? ["actionDiscuss", "options"] as const : []),
            ]),
        );

        this.isOwnerDisplayed$ = this.columnsSubject.pipe(
            map(columns => columns.includes("owner")),
        );

        this.columns = DEFAULT_COLUMNS;
        this.defaultSort = "description";
    }

    applyOwnerFilter = (user: SimpleUserDto | null) => this.dataSource.filter = user?.userId ?? "";

    openTrend = (number: NumberRecordDetailDto) => NumberChartDialogComponent.open(this.dialog, number);

    viewNumber = (number: NumberRecordDetailDto, focusFeed = false) =>
        NumberHomepageDialogComponent.open(this.dialog, number, { focusFeed })
            .componentInstance.events$.subscribe(event => this.updated.emit(event));

    openFeed = (number: NumberRecordDetailDto) => this.viewNumber(number, /* focusFeed: */ true);

    shouldShowCalculationSources = (number: NumberRecordDetailDto) => shouldShowCalculationSources(number, this.teamContext.features);

    allowsUpdater = (number: NumberRecordDetailDto) => number.captureMethod === CaptureMethod.manual;

    isUpdated = (number: NumberRecordDetailDto): boolean => {
        const result = number.result;
        return result !== null && result !== undefined;
    };

    numberUpdated = (number: NumberRecordDetailDto) =>
        this.updated.emit({ type: "updated", item: number });

    discussionAdded = (number: NumberRecordDetailDto) => {
        this.updated.emit({ type: "child", item: number, childType: "discussion" });
    };

    actionUpdated = (number: NumberRecordDetailDto) => {
        this.updated.emit({ type: "child", item: number, childType: "action" });
    };

    isExpanded = (number: NumberRecordDetailDto) => this.childActionsController.isExpanded(number);

    expandAll = () => this.expandableNumbers.forEach(this.childActionsController.expand);
    collapseAll = () => this.expandableNumbers.forEach(this.childActionsController.collapse);

    expand = (number: NumberRecordDetailDto) => this.childActionsController.expand(number);
    collapse = (number: NumberRecordDetailDto) => this.childActionsController.collapse(number);

    getChildActions = (number: NumberRecordDetailDto) => this.childActionsController.getChildren(number);

    protected sortingDataAccessor = (data: NumberRecordDetailDto, property: NumberColumn) => {
        switch (property) {
            case "description": return numberDescriptionSortAccessor(data);
            case "updateDayTitle": return getIsoDayOfWeekOrder(data.updateDay);
            case "teamTitle": return getCompanyTeamSortAccessor(data.company, data.team);
            case "week": return getSortablePeriodString(data);
            case "owner": return getUserName(data.owner);
            case "updater": return getUserName(data.updater);
            case "team": return data.team.name;
            case "resultSummary":
            case "result": return data.result ?? Number.NEGATIVE_INFINITY;
            case "target": return data.recordTarget?.lowerBound ?? data.recordTarget?.upperBound ?? Number.NEGATIVE_INFINITY;
            case "resultToDate": return data.resultToDate ?? Number.NEGATIVE_INFINITY;
            case "targetToDate": return data.targetToDate?.lowerBound ?? data.targetToDate?.upperBound ?? Number.NEGATIVE_INFINITY;
            case "department": return data.department?.name ?? "";
            case "category": return data.category?.description ?? "";
            case "subCategory": return data.category?.subCategory?.description ?? "";
        }
    };

    protected getDefaultColumns = () => DEFAULT_COLUMNS;
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { GetNewsItemDto, NewsItemsApi, SimpleCompanyTeamDto } from "@api";
import { BehaviorSubject, EMPTY, map, Observable, of, Subscription, switchMap } from "rxjs";

import { FeedAdapterBuilder, FeedScope, SimpleFeedScope } from "~feed";
import { MeetingProgressService } from "~services/meeting-progress.service";
import { NotificationService } from "~services/notification.service";
import { NewsItemStateEvent, NewsItemStateService } from "~services/state";
import { CommonFunctions } from "~shared/commonfunctions";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";
import { sortTeam } from "~shared/util/sorters";
import { getUserName } from "~shared/util/user-helper";

import { HomepageScaffoldComponent } from "../homepage-scaffold/homepage-scaffold.component";

const sortRecipients = (recipients: SimpleCompanyTeamDto[]) =>
    [...recipients].sort(sortTeam.ascending());

@Component({
    selector: "app-news-item-homepage",
    templateUrl: "./news-item-homepage.component.html",
    styleUrls: ["./news-item-homepage.component.scss"],
    providers: [
        SimpleFeedScope,
        {
            provide: FeedScope,
            useExisting: SimpleFeedScope,
        },
    ],
})
export class NewsItemHomepageComponent extends WithDestroy() implements OnInit, OnDestroy {

    @Input() set newsItem(value: GetNewsItemDto | null) {
        this.newsItemSubject.next(value);
        this.simpleFeedScope.adapter = value ? this.feedAdapterBuilder.buildForNewsItem(value) : null;
        this.recipientsDataSource.data = sortRecipients(value?.recipients ?? []);
    }

    get newsItem(): GetNewsItemDto | null {
        return this.newsItemSubject.value;
    }

    @Output() newsItemChange = new EventEmitter<GetNewsItemDto>();
    @Output() newsItemDeleted = new EventEmitter<void>();

    @ViewChild(HomepageScaffoldComponent) scaffold?: HomepageScaffoldComponent;

    @ViewChild("recipientSort", { static: false, read: MatSort }) set recipientSort(value: MatSort | null) {
        this.recipientsDataSource.sort = value;
    }

    get recipientSort(): MatSort | null {
        return this.recipientsDataSource.sort;
    }

    get isTeamOwned(): boolean {
        return !!this.newsItem && this.newsItem.currentTeam.id === this.newsItem.team.id;
    }

    readonly getUserName = getUserName;

    readonly recipientsDataSource = new MatTableDataSource<SimpleCompanyTeamDto>();
    readonly recipientsColumns = ["team", "company"];

    readonly displayNoted$: Observable<boolean>;
    readonly canMarkNoted$: Observable<boolean>;

    isNoting = false;

    private readonly newsItemSubject = new BehaviorSubject<GetNewsItemDto | null>(null);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly newsItemStateService: NewsItemStateService,
        private readonly meetingProgressService: MeetingProgressService,
        private readonly newsItemsApi: NewsItemsApi,
        private readonly simpleFeedScope: SimpleFeedScope,
        private readonly feedAdapterBuilder: FeedAdapterBuilder,
        private readonly notificationService: NotificationService,
    ) {
        super();

        this.recipientsDataSource.sortingDataAccessor = (data, column) => {
            switch (column) {
                case "team": return data.name;
                case "company": return data.company.name;
                default:
                    return (data as never)[column] ?? "";
            }
        };

        this.displayNoted$ = this.newsItemSubject.pipe(
            map(n => !!n && n.isNoted),
            shareReplayUntil(this.destroyed$),
        );

        this.canMarkNoted$ = this.newsItemSubject.pipe(
            switchMap(n => !n || n.isNoted ? of(false) :
                this.meetingProgressService.isInProgress$(n.currentTeam.company.id, n.currentTeam.id)),
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.newsItemSubject.pipe(
            switchMap(n => !n ? EMPTY : this.newsItemStateService.eventsForNewsItems(n)),
        ).subscribe(this.handleStateEvent));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    afterUpdated = (newsItem: GetNewsItemDto) => {
        if (this.newsItem === newsItem) {
            // The reference is literally equal.
            // This is likely because the change has already been applied to the news item.
            return;
        }
        this.newsItem = newsItem;
        this.newsItemChange.emit(newsItem);
        this.scaffold?.refreshFeed();
    };

    afterDeleted = () => this.newsItemDeleted.emit();

    markNoted = () => {
        const news = this.newsItem;
        if (this.isNoting || !news || !this.meetingProgressService.isInProgress(news.currentTeam.company.id, news.currentTeam.id)) return;
        this.isNoting = true;
        CommonFunctions.showLoader();
        this.newsItemsApi.markNewsItemAsNoted(
            news.currentTeam.company.id,
            news.currentTeam.id,
            news.id,
        ).subscribe({
            next: result => {
                news.notedDate = result.notedDate;
                news.notedDateLocal = result.notedDateLocal;
                news.isNoted = result.isNoted;
                this.isNoting = false;
                this.newsItemStateService.notifyUpdate(result);
                this.notificationService.success("news.newsNoted", undefined, undefined, true);
                CommonFunctions.hideLoader();
                this.newsItem = result;
                this.newsItemChange.emit(result);
                this.scaffold?.refreshFeed();
            },
            error: () => {
                this.isNoting = false;
                CommonFunctions.hideLoader();
                this.notificationService.errorUnexpected();
            },
        });
    };

    private handleStateEvent = (event: NewsItemStateEvent) => {
        switch (event.type) {
            case "added": // We should never get an added event, but treat it as if updated for simplicity
            case "updated":
                this.afterUpdated(event.item);
                break;
            case "deleted":
                this.afterDeleted();
                break;
        }
    };
}

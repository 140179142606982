/* eslint-disable @typescript-eslint/naming-convention */
import { animate, animateChild, AnimationMetadata, group, query, state, style, transition } from "@angular/animations";

export const defaultAnimationTiming = "225ms cubic-bezier(0.4, 0.0, 0.2, 1)";

export const expansionAnimation = [
    state("collapsed, void", style({ height: "0px", minHeight: "0", display: "none" })),
    state("expanded", style({ height: "*", })),
    transition("* <=> *", [
        style({ display: "block" }),
        animate(defaultAnimationTiming)
    ]),
];

export const expandOnEnterAnimationBuilder = (timing: string | number = defaultAnimationTiming): AnimationMetadata[] => [
    transition(":enter", [
        style({ height: "0", overflow: "hidden" }),
        animate(timing, style({ height: "*" })),
    ]),
    transition(":leave", [
        style({ height: "*", overflow: "hidden" }),
        animate(timing, style({ height: "0" })),
    ]),
];

export const fadeInOnlyAnimationBuilder = (timing: string | number = defaultAnimationTiming): AnimationMetadata[] => [
    transition(":enter", [
        style({ opacity: "0" }),
        animate(timing, style({ opacity: "*" })),
    ]),
];

export const fadeInAnimationBuilder = (timing: string | number = defaultAnimationTiming): AnimationMetadata[] => [
    transition(":enter", [
        style({ opacity: "0" }),
        animate(timing, style({ opacity: "*" })),
    ]),
    transition(":leave", [
        style({ opacity: "*" }),
        animate(timing, style({ opacity: "0" })),
    ]),
];

export const fadeExpandAnimationBuilder = (timing: string | number = defaultAnimationTiming): AnimationMetadata[] => [
    transition(":enter", [
        style({ opacity: "0", height: "0", overflow: "hidden" }),
        animate(timing, style({ opacity: "*", height: "*" })),
    ]),
    transition(":leave", [
        style({ opacity: "*", height: "*", overflow: "hidden" }),
        animate(timing, style({ opacity: "0", height: "0" })),
    ]),
];

export const growAnimationBuilder = (timing: string | number = defaultAnimationTiming): AnimationMetadata[] => [
    transition("* <=> *", [
        style({ height: "{{startHeight}}px", position: "relative", overflow: "hidden" }),
        query(":enter", [style({ opacity: 0 })], { optional: true }),
        query(":leave", [
            style({
                position: "absolute",
                width: "100%",
                top: 0,
                opacity: "*"
            }),
            animateChild(),
        ], { optional: true }),
        group([
            animate(timing, style({ height: "*" })),
            query("@*", animateChild(), { optional: true }),
            query(":enter", [
                animate(timing, style({ opacity: "*" })),
            ], { optional: true }),
            query(":leave", [
                animate(timing, style({ opacity: 0 })),
            ], { optional: true }),
        ]),
        query(":enter", animateChild(), { optional: true })
    ], {
        params: { startHeight: 0 }
    })
];

export const growWidthAnimationBuilder = (timing: string | number = defaultAnimationTiming): AnimationMetadata[] => [
    transition("* <=> *", [
        style({ width: "{{startWidth}}px", position: "relative", overflow: "hidden" }),
        query(":enter", [style({ opacity: 0 })], { optional: true }),
        query(":leave", [
            style({
                position: "absolute",
                left: 0,
                opacity: "*"
            }),
            animateChild(),
        ], { optional: true }),
        group([
            animate(timing, style({ width: "*" })),
            query("@*", animateChild(), { optional: true }),
            query(":enter", [
                animate(timing, style({ opacity: "*" })),
            ], { optional: true }),
            query(":leave", [
                animate(timing, style({ opacity: 0 })),
            ], { optional: true }),
        ]),
        query(":enter", animateChild(), { optional: true })
    ], {
        params: { startHeight: 0 }
    })
];

export const rotationAnimationBuilder = (timing: string | number = defaultAnimationTiming): AnimationMetadata[] => [
    state("collapsed, void", style({ transform: "" })),
    state("expanded", style({ transform: "rotate(-180deg)" })),
    transition("* <=> *", [
        animate(timing)
    ]),
];

export const expandOnEnterAnimation = expandOnEnterAnimationBuilder();

export const tabChangeAnimation = [
    transition(":increment", [
        style({ position: "relative" }),
        query(":enter, :leave", [
            style({
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%"
            })
        ]),
        query(":enter", [
            style({ left: "100%" })
        ]),
        query(":leave", animateChild()),
        group([
            query(":leave", [
                animate("300ms ease-out", style({ left: "-100%" }))
            ]),
            query(":enter", [
                animate("300ms ease-out", style({ left: "0%" }))
            ])
        ]),
        query(":enter", animateChild()),
    ]),

    transition(":decrement", [
        style({ position: "relative" }),
        query(":enter, :leave", [
            style({
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%"
            })
        ]),
        query(":enter", [
            style({ left: "-100%" })
        ]),
        query(":leave", animateChild()),
        group([
            query(":leave", [
                animate("300ms ease-out", style({ left: "100%" }))
            ]),
            query(":enter", [
                animate("300ms ease-out", style({ left: "0%" }))
            ])
        ]),
        query(":enter", animateChild()),
    ])
];

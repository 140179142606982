import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActionsV2Api, DiscussionsApi, EntityReferenceDetailsDto, EntityReferenceDto, NewsItemsApi, TeamGoalsApi, TeamNumbersApi, TeamReportsApi } from "@api";

import { ActionHomepageDialogComponent, DiscussionHomepageDialogComponent, GoalHomepageDialogComponent, NewsItemHomepageDialogComponent, NumberHomepageDialogComponent, ReportHomepageDialogComponent } from "~homepage";
import { LinkedItemViewer } from "~services/linked-item-viewer.service";
import { NotificationService } from "~services/notification.service";
import { EntityType } from "~shared/enums";

declare type EntityReferenceBase = EntityReferenceDetailsDto | EntityReferenceDto;

declare type EntityReference<TEntityType extends EntityType> =
    Omit<EntityReferenceDetailsDto, "type"> & {
        type: TEntityType;
    } | Omit<EntityReferenceDto, "type"> & {
        type: TEntityType;
    };

declare type EntityViewHandler<TEntityType extends EntityType> = (source: EntityReference<TEntityType>) => void;

const getReferenceCompanyTeam = (reference: EntityReference<EntityType>): { companyId: string; teamId: string } => {
    if ("companyId" in reference) return { companyId: reference.companyId, teamId: reference.teamId };
    return { companyId: reference.company.id, teamId: reference.team.id };
};

@Injectable()
export class HomepageLinkedItemViewer extends LinkedItemViewer {

    private readonly viewHandlers: { readonly [entityType in EntityType]?: EntityViewHandler<entityType> };
    private isLoadingDialogData = false;

    constructor(
        private readonly discussionsApi: DiscussionsApi,
        private readonly actionsApi: ActionsV2Api,
        private readonly newsItemsApi: NewsItemsApi,
        private readonly teamGoalsApi: TeamGoalsApi,
        private readonly teamNumbersApi: TeamNumbersApi,
        private readonly teamReportsApi: TeamReportsApi,
        private readonly dialog: MatDialog,
        private readonly notificationService: NotificationService,
    ) {
        super();

        this.viewHandlers = {
            [EntityType.action]: this.openActionDialog,
            [EntityType.discussion]: this.openDiscussionDialog,
            [EntityType.solution]: this.openSolutionDialog,
            [EntityType.news]: this.openNewsDialog,
            [EntityType.goal]: this.openGoalDialog,
            [EntityType.number]: this.openNumberDialog,
            [EntityType.report]: this.openReportDialog,
        };
    }

    canView = (source: EntityReferenceBase): boolean => !!this.viewHandlers[source.type];

    view = <TEntityType extends EntityType>(source: EntityReference<TEntityType>) => {
        const handler = this.viewHandlers[source.type];
        if (!handler) return;
        handler(source);
    };

    private openActionDialog = (source: EntityReference<EntityType.action>) => {
        if (this.isLoadingDialogData) return;
        const { companyId, teamId } = getReferenceCompanyTeam(source);
        this.isLoadingDialogData = true;
        this.actionsApi.getAction(companyId, teamId, source.id)
            .subscribe({
                next: action => {
                    this.isLoadingDialogData = false;
                    ActionHomepageDialogComponent.open(this.dialog, action);
                },
                error: () => {
                    this.isLoadingDialogData = false;
                    this.notificationService.errorUnexpected();
                },
            });
    };

    private openDiscussionDialog = (source: EntityReference<EntityType.discussion>) => {
        if (this.isLoadingDialogData) return;
        const { companyId, teamId } = getReferenceCompanyTeam(source);
        this.isLoadingDialogData = true;
        this.discussionsApi.getDiscussion(companyId, teamId, source.id)
            .subscribe({
                next: discussion => {
                    this.isLoadingDialogData = false;
                    DiscussionHomepageDialogComponent.openForDiscussion(this.dialog, discussion);
                },
                error: () => {
                    this.isLoadingDialogData = false;
                    this.notificationService.errorUnexpected();
                },
            });
    };

    private openSolutionDialog = (source: EntityReference<EntityType.solution>) => {
        if (this.isLoadingDialogData) return;
        const { companyId, teamId } = getReferenceCompanyTeam(source);
        this.isLoadingDialogData = true;
        this.discussionsApi.getDiscussionSolution(companyId, teamId, source.id)
            .subscribe({
                next: discussion => {
                    this.isLoadingDialogData = false;
                    DiscussionHomepageDialogComponent.openForDiscussion(this.dialog, discussion);
                },
                error: () => {
                    this.isLoadingDialogData = false;
                    this.notificationService.errorUnexpected();
                },
            });
    };

    private openNewsDialog = (source: EntityReference<EntityType.news>) => {
        if (this.isLoadingDialogData) return;
        const { companyId, teamId } = getReferenceCompanyTeam(source);
        this.isLoadingDialogData = true;
        this.newsItemsApi.getNewsItem(companyId, teamId, source.id)
            .subscribe({
                next: newsItem => {
                    this.isLoadingDialogData = false;
                    NewsItemHomepageDialogComponent.open(this.dialog, newsItem);
                },
                error: () => {
                    this.isLoadingDialogData = false;
                    this.notificationService.errorUnexpected();
                },
            });
    };

    private openGoalDialog = (source: EntityReference<EntityType.goal>) => {
        if (this.isLoadingDialogData) return;
        const { companyId, teamId } = getReferenceCompanyTeam(source);
        this.isLoadingDialogData = true;
        const goal$ = source.week ?
            this.teamGoalsApi.getGoalForWeek(companyId, teamId, source.id, source.week) :
            this.teamGoalsApi.getGoal(companyId, teamId, source.id);
        goal$.subscribe({
            next: goal => {
                this.isLoadingDialogData = false;
                GoalHomepageDialogComponent.open(this.dialog, goal);
            },
            error: () => {
                this.isLoadingDialogData = false;
                this.notificationService.errorUnexpected();
            },
        });
    };

    private openNumberDialog = (source: EntityReference<EntityType.number>) => {
        if (this.isLoadingDialogData) return;
        const { companyId, teamId } = getReferenceCompanyTeam(source);
        this.isLoadingDialogData = true;
        const number$ = source.week ?
            this.teamNumbersApi.getNumberForWeek(companyId, teamId, source.id, source.week) :
            this.teamNumbersApi.getNumber(companyId, teamId, source.id);
        number$.subscribe({
            next: number => {
                this.isLoadingDialogData = false;
                NumberHomepageDialogComponent.open(this.dialog, number);
            },
            error: () => {
                this.isLoadingDialogData = false;
                this.notificationService.errorUnexpected();
            },
        });
    };

    private openReportDialog = (source: EntityReference<EntityType.report>) => {
        if (this.isLoadingDialogData) return;
        const { companyId, teamId } = getReferenceCompanyTeam(source);
        this.isLoadingDialogData = true;
        const report$ = source.week ?
            this.teamReportsApi.getReportForWeek(companyId, teamId, source.id, source.week) :
            this.teamReportsApi.getReport(companyId, teamId, source.id);
        report$.subscribe({
            next: report => {
                this.isLoadingDialogData = false;
                ReportHomepageDialogComponent.open(this.dialog, report);
            },
            error: () => {
                this.isLoadingDialogData = false;
                this.notificationService.errorUnexpected();
            },
        });
    };
}

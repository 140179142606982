<wf-help-indicator [wfTemplateTooltip]="tooltip" matTooltipClass="light-tooltip"></wf-help-indicator>
<ng-template #tooltip>
    <dl>
        <dt translate="delegationResponsibility.ownership"></dt>
        <dd translate="delegationResponsibility.ownershipDescription"></dd>
        
        <dt translate="delegationResponsibility.update"></dt>
        <dd translate="delegationResponsibility.updateDescription"></dd>
    </dl>
</ng-template>

import { GetTeamUserDelegationDto, GetTeamUserResponsibilityDelegationDto, SimpleCompanyDto, SimpleTeamDto, SimpleUserDto } from "@api";

declare interface SimpleCompanyTeamDto {
    company: SimpleCompanyDto;
    team: SimpleTeamDto;
}

export interface IDelegatedItem {
    company: SimpleCompanyDto;
    team: SimpleTeamDto;
    delegation?: GetTeamUserDelegationDto | GetTeamUserResponsibilityDelegationDto;
    isDelegated: boolean;
}

export interface IDelegatedItemWithOwner extends IDelegatedItem {
    owner?: SimpleUserDto;
}

export interface IDelegatedItemWithUpdater extends IDelegatedItemWithOwner {
    updater?: SimpleUserDto;
}

/**
 * Determines the company and team that should be used when updating an item's status or attachments.
 * In the case that the item was loaded from a delegated context, the delegated company/team
 * will be preferenced over the owning company/team.
 *
 * @param item The item to get the company and team for.
 * @returns The company and team to be used to update the item.
 */
export const getDelegatedItemCompanyTeam = (item: IDelegatedItem): SimpleCompanyTeamDto =>
    item.isDelegated && item.delegation || item;

export const getDelegatedItemOwner = (item: IDelegatedItemWithOwner): SimpleUserDto | undefined =>
    item.isDelegated && item.delegation && item.delegation.assignee || item.owner;

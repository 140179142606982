import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import {
    ActionSuggestionDto, GetActionDto, GetGoalDto, PlanningSuggestionsApi
} from "@api";
import { BehaviorSubject, catchError, delay, EMPTY, filter, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { environment } from "~/environments/environment";
import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { EditActionDialogComponent } from "~shared/dialogs";
import { mapGoalOrigin } from "~shared/util/origin-builder";

import { exampleActions } from "./example-actions";


export interface SuggestGoalActionsDialogData {
    goal: GetGoalDto;
}

@Component({
    selector: "app-suggest-goal-actions-dialog",
    templateUrl: "./suggest-goal-actions-dialog.component.html",
    styleUrls: ["./suggest-goal-actions-dialog.component.scss"]
})
export class SuggestGoalActionsDialogComponent implements OnInit, OnDestroy {

    readonly dataSource = new MatTableDataSource<ActionSuggestionDto>();
    readonly displayedColumns = ["description", "apply"];
    isLoading = true;

    get goalHeading(): string {
        return this.goal.heading;
    }

    private readonly goal: GetGoalDto;

    private readonly appliedSuggestions = new Map<ActionSuggestionDto, GetActionDto[]>();

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly planningSuggestionsApi: PlanningSuggestionsApi,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<SuggestGoalActionsDialogComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: SuggestGoalActionsDialogData,
    ) {
        this.goal = data.goal;
    }

    static open(dialog: MatDialog, goal: GetGoalDto) {
        return dialog.open(SuggestGoalActionsDialogComponent, {
            width: "800px",
            data: { goal },
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.refreshSubject.pipe(
            tap(() => this.isLoading = true),
            switchMap(() => this.getSuggestions()),
            catchError(() => {
                this.dialogRef.close();
                this.notificationService.errorUnexpected();
                return EMPTY;
            }),
            switchMap(suggestions => {
                if (!suggestions.length) {
                    this.dialogRef.close();
                    this.notificationService.warning("goals.suggest.noActionSuggestions", undefined, undefined, true);
                    return EMPTY;
                }
                return of(suggestions);
            }),
            tap(() => this.isLoading = false),
        ).subscribe(suggestions => this.dataSource.data = suggestions));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    applySuggestion = (suggestion: ActionSuggestionDto) => {
        if (this.isApplied(suggestion)) return;
        const goal = this.goal;
        EditActionDialogComponent.openForAdd(this.dialog, {
            companyId: goal.company.id,
            teamId: goal.team.id,
            origin: mapGoalOrigin(goal),
            actionInput: suggestion,
        }).afterClosed().pipe(filter(Boolean)).subscribe(result => {
            if (result.type === "added") {
                this.appliedSuggestions.set(suggestion, result.actions);
            }
        });
    };

    isApplied = (suggestion: ActionSuggestionDto) => this.appliedSuggestions.has(suggestion);

    private getSuggestions = (): Observable<ActionSuggestionDto[]> => {
        if (environment.localSuggestions) {
            return of(exampleActions).pipe(
                delay(1000),
            );
        }
        const goal = this.goal;
        return this.planningSuggestionsApi.getGoalActionSuggestions(
            goal.company.id,
            goal.team.id,
            toFiscalQuarter({ financialYear: goal.financialYear, quarter: goal.planningPeriod }),
            this.goal.id);
    };

}

import { inject } from "@angular/core";
import { CanActivateChildFn, CanActivateFn, Router } from "@angular/router";

import { UserService } from "~services/user.service";

export const legacyAuthGuard: CanActivateFn & CanActivateChildFn = (route) => {
    if (route.data.redirectToSignup) {
        inject(UserService).redirectToSignup();
        return false;
    } else {
        return inject(Router).createUrlTree(["/"]);
    }
};

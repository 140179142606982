<ng-container [formGroup]="form">
    <mat-checkbox formControlName="status"></mat-checkbox>
    <mat-select disableOptionCentering formControlName="progress" required panelWidth="">
        <mat-select-trigger>
            <app-action-progress [progress]="progressControl.value"></app-action-progress>
        </mat-select-trigger>
        <mat-option class="wf-action-status-option" *ngFor=" let progress of progressOptions$ | async" [value]="progress">
            <app-action-progress [progress]="progress"></app-action-progress>
        </mat-option>
    </mat-select>
</ng-container>
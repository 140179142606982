import { ListRange } from "@angular/cdk/collections";
import { Directive, ElementRef, Inject, Input, Optional } from "@angular/core";
import { MatTable, MatTableDataSource } from "@angular/material/table";

import { AsyncTableDataSource } from "~shared/util/async-table-data-source";

import { BaseScrollListenerDirective } from "./base-scroll-listener.directive";

@Directive({
    selector: "[wfTableScrollListener]"
})
export class TableScrollListenerDirective extends BaseScrollListenerDirective {

    get table(): MatTable<any> | undefined {
        return this.inputTable ?? this.hostTable;
    }

    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input("wfTableScrollListener") set table(value: MatTable<any> | null | undefined) {
        if (!value) {
            this.inputTable = undefined;
        } else {
            this.inputTable = value;
        }
        this.table?.viewChange.next(this.calculateVisibleRange(this.element.nativeElement));
    }

    private inputTable?: MatTable<any>;
    private readonly hostTable?: MatTable<any>;

    constructor(
        element: ElementRef<HTMLElement>,
        @Inject(MatTable) @Optional() table: MatTable<any> | undefined,
    ) {
        super(element);
        this.hostTable = table;
    }

    protected onViewChange(range: ListRange) {
        super.onViewChange(range);
        this.table?.viewChange.next(range);
    };

    protected getItemCount = (): number | undefined => {
        const dataSource = this.table?.dataSource;

        if (dataSource instanceof AsyncTableDataSource || dataSource instanceof MatTableDataSource) {
            return dataSource.filteredData.length;
        } else {
            return undefined;
        }
    };

}

<table mat-table class="wf-table" [dataSource]="dataSource" matSort wfTableFocus
    matSortActive="updateDay" matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="updateDay">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'numbers.dailyUpdates.updateDay' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <a (click)="view(element)" wfEmptyLink>
                {{ getDayOfWeekNameKey(element.updateDay) | translate }}
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="updater">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'numbers.updater' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ getUserName(element.updater) }}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="resultSummary">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'numbers.resultSummary' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-number-result-summary [number]="element"></app-number-result-summary>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns" [wfRowFocus]="row" [wfRowFocusIndex]="i">
    </tr>
</table>
import { Directive, EventEmitter, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { FeedPartitionDto, GetFeedItemDto } from "@api";
import { EMPTY } from "rxjs";
import { catchError, filter, finalize, switchMap } from "rxjs/operators";

import { FeedScope, isCompanyTeamFeedContext, partitionKeysEqual, } from "~feed/services";
import { UserContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { CommonFunctions } from "~shared/commonfunctions";
import { Role } from "~shared/enums";

import { BaseFeedListItemDirective } from "./base-feed-list-item.directive";

@Directive()
export class DeletableFeedListItemDirective<T extends GetFeedItemDto> extends BaseFeedListItemDirective<T> {
    @Output() itemChanged = new EventEmitter<void>();

    deleteStarted = false;

    get canUserDelete(): boolean {
        const context = this.feedScope.adapter?.reference?.context;
        if (!this.feedItem || !context) return false;
        if (!!this.feedItem.user && this.userContext.userId() === this.feedItem.user.userId) return true;

        const user = this.userContext.user();
        const role = this.userContext.role();

        if (role === Role.partner && user?.companyId === context.companyId) return false;
        if (isCompanyTeamFeedContext(context)) {
            if (role === Role.admin && user?.companyId !== context.companyId) return false;
        } else {
            if (role === Role.admin) return false;
        }
        return this.userContext.isAdmin();
    }

    constructor(
        protected readonly feedScope: FeedScope,
        protected readonly userContext: UserContext,
        protected readonly notificationService: NotificationService,
        protected readonly dialog: MatDialog,
    ) {
        super();
    }

    delete = () => {
        const adapter = this.feedScope.adapter;
        if (!this.feedItem || this.deleteStarted || !this.canUserDelete || !adapter) return;
        this.deleteStarted = true;
        const feedItemId = this.feedItem.feedItemId;

        CommonFunctions.showConfirmDialog(this.dialog, {
            title: "FeedsList.dialogs.confirm.title",
            description: "FeedsList.dialogs.confirm.description",
            confirm: { title: "FeedsList.dialogs.confirm.confirm" },
            cancel: { title: "FeedsList.dialogs.confirm.cancel" }
        }).afterClosed().pipe(
            filter(result => result === "confirm"),
            switchMap(() => adapter.deleteFeedItem(feedItemId)),
            catchError(() => {
                this.notificationService.errorUnexpected();
                return EMPTY;
            }),
            finalize(() => this.deleteStarted = false)
        ).subscribe({
            next: (partition) => {
                this.itemChanged.emit();
                this.updateLastActivityDate(partition);
            },
            error: () => {
                this.notificationService.errorUnexpected();
            }
        });
    };

    protected updateLastActivityDate = (partition: FeedPartitionDto) => {
        if (!this.feedScope.adapter?.reference) return;
        const activePartition = this.feedScope.adapter.reference.partition;
        // Ensures this is the active partition
        // This may not be the case if the deleted item is from a child partition.
        if (activePartition.clientId !== partition.clientId ||
            activePartition.feedId !== partition.feedId ||
            !partitionKeysEqual(activePartition.key, partition.key)) return;
        activePartition.lastActivityDate = partition.lastActivityDate;
    };
}

/* eslint-disable @typescript-eslint/ban-types */
import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from "@angular/core";
import { TranslateParser, TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap } from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";

import { TranslateTemplateContentDirective } from "./translate-template-content.directive";

interface ContentToken {
    template: string | null;
    content: string;
}

// template should be like `::link::Click Here::/link::` or `::description/::`
const TEMPLATE_OPEN_START_DELIMITER = "::";
const TEMPLATE_OPEN_END_DELIMITER = "::";
const TEMPLATE_CLOSE_START_DELIMITER = "::/";
const TEMPLATE_CLOSE_END_DELIMITER = "::";
const TEMPLATE_SELF_CLOSE_END = "/";

@Component({
    selector: "wf-translate-template,[wf-translate-template]",
    template: `
    <ng-container *ngFor="let token of contentTokens$ | async">
        <ng-container *ngIf="findTemplate(token.template) as dir; else raw"
            [ngTemplateOutlet]="dir.template" [ngTemplateOutletContext]="{ $implicit: token.content }">
        </ng-container>
        <ng-template #raw>{{ token.content }}</ng-template>
    </ng-container>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TranslateTemplateComponent extends WithDestroy() {

    get key(): string | undefined {
        return this.translateKeySub.value;
    }

    @Input() set key(value: string | undefined) {
        this.translateKeySub.next(value);
    }

    @Input("wf-translate-template") set keyAlt(value: string | undefined) {
        this.key = value;
    }

    get params(): Object | undefined {
        return this.translateParamsSub.value;
    }

    @Input() set params(value: Object | undefined) {
        this.translateParamsSub.next(value);
    }

    @ContentChildren(TranslateTemplateContentDirective) templates?: QueryList<TranslateTemplateContentDirective>;

    readonly contentTokens$: Observable<ContentToken[]>;

    private readonly translateKeySub = new BehaviorSubject<string | undefined>(undefined);
    private readonly translateParamsSub = new BehaviorSubject<Object | undefined>(undefined);

    constructor(
        private readonly translateService: TranslateService,
        private readonly translateParser: TranslateParser,
    ) {
        super();

        const rawTokens$ = this.translateKeySub.pipe(
            switchMap(key => !key ? of("") : this.translateService.stream(key) as Observable<string>),
            map(this.splitTokens),
        );

        this.contentTokens$ = combineLatest({
            tokens: rawTokens$,
            translateParams: this.translateParamsSub,
        }).pipe(
            map(x => x.tokens.map(token => ({
                template: token.template,
                content: this.translateParser.interpolate(token.content, x.translateParams) ?? ""
            }))),
            shareReplayUntil(this.destroyed$),
        );
    }

    findTemplate = (template: string | null): TranslateTemplateContentDirective | undefined => {
        if (!template || !this.templates) return undefined;
        return this.templates.find(x => x.name === template);
    };

    private splitTokens = (content: string): ContentToken[] => {
        const tokens: ContentToken[] = [];
        const length = content.length;
        let cursor = 0;

        while (cursor < length) {
            const tagIndex = content.indexOf(TEMPLATE_OPEN_START_DELIMITER, cursor);
            if (tagIndex === -1) {
                tokens.push({
                    template: null,
                    content: content.substring(cursor),
                });
                cursor = length;
                break;
            }

            if (tagIndex > cursor) {
                tokens.push({
                    template: null,
                    content: content.substring(cursor, tagIndex),
                });
                cursor = tagIndex;
            }

            // Our template starts the string.
            const templateNameStart = cursor + TEMPLATE_OPEN_START_DELIMITER.length;
            const templateNameEnd = content.indexOf(TEMPLATE_OPEN_END_DELIMITER, templateNameStart);
            if (templateNameEnd === -1) {
                throw new SyntaxError(`Missing closing tag at index ${cursor}`);
            }
            const templateName = content.substring(templateNameStart, templateNameEnd);

            cursor = templateNameEnd + TEMPLATE_OPEN_END_DELIMITER.length;

            if (templateName.endsWith(TEMPLATE_SELF_CLOSE_END)) {
                tokens.push({
                    template: templateName.slice(0, -TEMPLATE_SELF_CLOSE_END.length).trim(),
                    content: "",
                });
                continue;
            }

            const templateEndTag = TEMPLATE_CLOSE_START_DELIMITER + templateName + TEMPLATE_CLOSE_END_DELIMITER;
            const templateEnd = content.indexOf(templateEndTag, cursor);
            if (templateEnd === -1) {
                throw new SyntaxError(`Unable to find end tag for template "${templateName}" from index ${cursor}`);
            }

            tokens.push({
                template: templateName,
                content: content.substring(cursor, templateEnd),
            });
            cursor = templateEnd + templateEndTag.length;
        }
        return tokens;
    };
}

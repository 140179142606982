import { Component, Inject } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { CaptureMethod, GetNumberDto, NumberRecordDetailDto, UpdateNumbersApi } from "@api";
import { BehaviorSubject, catchError, combineLatest, map, Observable, of, switchMap, tap } from "rxjs";

import { TeamContext } from "~services/contexts";
import { mergeRecordUpdatesFrom, NumberStateService } from "~services/state";
import { getDisplayedColumns$, TableSettings, TableSettingsService } from "~services/table-settings.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { createNumberColumnDefinitionBuilder, NumberColumn } from "~shared/util/number-columns";
import { sortNumberDefinition } from "~shared/util/number-helper";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { valueAndChanges } from "~shared/util/util";

interface ViewNumberCalcSourcesDialogData {
    number: GetNumberDto | NumberRecordDetailDto;
    week: number;
}

const BASE_TABLE_SETTINGS_NAME = "NumberCalcSources";

const buildCalculationColumnDefinitions = createNumberColumnDefinitionBuilder([
    "description",
    ["week", "hidden"],
    ["team", "visible"],
    "owner",
    "updater",
    "resultToDate",
    "targetToDate",
    "result",
    "target",
    "resultSummary",
    "department",
    "category",
    "subCategory",
]);

const buildDailyColumnDefinitions = createNumberColumnDefinitionBuilder([
    "updateDayTitle",
    ["week", "hidden"],
    ["team", "hidden"],
    "owner",
    "updater",
    "resultToDate",
    "targetToDate",
    "result",
    "target",
    "resultSummary",
    "department",
    "category",
    "subCategory",
]);

const buildDeploymentColumnDefinitions = createNumberColumnDefinitionBuilder([
    "teamTitle",
    ["week", "hidden"],
    "owner",
    "updater",
    "resultToDate",
    "targetToDate",
    "result",
    "target",
    "resultSummary",
    "department",
    "category",
    "subCategory",
]);

declare type CalcSourceMode = "calculation" | "deployment" | "daily";

const getTableSettingsName = (mode: CalcSourceMode) => `${BASE_TABLE_SETTINGS_NAME}_${mode}`;

const getColumnDefinitionBuilder = (mode: CalcSourceMode) => {
    switch (mode) {
        case "deployment": return buildDeploymentColumnDefinitions;
        case "daily": return buildDailyColumnDefinitions;
        case "calculation":
        default:
            return buildCalculationColumnDefinitions;
    }
};

@Component({
    selector: "app-view-number-calc-sources-dialog",
    templateUrl: "./view-number-calc-sources-dialog.component.html",
    styleUrls: ["./view-number-calc-sources-dialog.component.scss"],
})
export class ViewNumberCalcSourcesDialogComponent extends WithDestroy() {

    readonly number: GetNumberDto | NumberRecordDetailDto;
    readonly week: number;

    readonly sources$: Observable<NumberRecordDetailDto[]>;

    readonly modeControl = new FormControl<CalcSourceMode>("calculation", { nonNullable: true });

    readonly availableModes: CalcSourceMode[];

    readonly columnDefinitions$ = combineLatest({
        mode: valueAndChanges(this.modeControl),
        team: this.teamContext.companyTeam$,
    }).pipe(
        map(({ mode, team }) => getColumnDefinitionBuilder(mode)(team)),
        shareReplayUntil(this.destroyed$),
    );

    readonly tableSettings$ = combineLatest({
        mode: valueAndChanges(this.modeControl),
        defs: this.columnDefinitions$,
    }).pipe(
        switchMap(({ mode, defs }) => this.tableSettingsService.getTableSettings(getTableSettingsName(mode), defs)),
        shareReplayUntil(this.destroyed$),
    );

    readonly displayedColumns$ = getDisplayedColumns$(this.tableSettings$, this.columnDefinitions$).pipe(
        shareReplayUntil(this.destroyed$),
    );

    isLoading = false;
    hasError = false;

    get titleKey(): string {
        switch (this.modeControl.value) {
            case "deployment": return "numbers.deployment.breakdownFor";
            case "daily": return "numbers.dailyUpdates.breakdownFor";
            case "calculation":
            default:
                return "numbers.calculation.breakdownFor";
        }
    }

    get loadFailedMessageKey(): string {
        switch (this.modeControl.value) {
            case "deployment": return "numbers.deployment.loadFailed";
            case "daily": return "numbers.dailyUpdates.loadFailed";
            case "calculation":
            default:
                return "numbers.calculation.loadFailed";
        }
    }

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);

    constructor(
        private readonly updateNumbersApi: UpdateNumbersApi,
        private readonly numberStateService: NumberStateService,
        private readonly teamContext: TeamContext,
        private readonly tableSettingsService: TableSettingsService,
        @Inject(MAT_DIALOG_DATA) { number, week }: ViewNumberCalcSourcesDialogData,
    ) {
        super();

        this.number = number;
        this.week = week;

        const isDeployed = number.captureMethod === CaptureMethod.deployed;
        const isDaily = !!number.dailyUpdateDefinition;
        const isCalculated = number.captureMethod === CaptureMethod.calculated;

        this.modeControl.setValue(
            isDeployed ? "deployment" :
                isDaily ? "daily" : "calculation");

        this.availableModes = [
            isDeployed ? "deployment" : undefined,
            isDaily ? "daily" : undefined,
            isCalculated ? "calculation" : undefined,
        ].filter(Boolean);

        this.sources$ = valueAndChanges(this.modeControl).pipe(
            withRefresh(this.refreshSubject),
            tap(this.initLoad),
            switchMap((mode) => this.getCalculationSources(number, week, mode).pipe(
                mergeRecordUpdatesFrom(data => this.numberStateService.eventsForNumbers(...data)),
                map(numbers => numbers.sort(sortNumberDefinition.ascending())),
                catchError(() => {
                    this.hasError = true;
                    return of([]);
                }),
            )),
            tap(() => this.isLoading = false),
            shareReplayUntil(this.destroyed$),
        );
    }

    static open(dialog: MatDialog, number: GetNumberDto | NumberRecordDetailDto, week: number) {
        return dialog.open(ViewNumberCalcSourcesDialogComponent, {
            data: { number, week },
            autoFocus: "first-heading",
        });
    }

    refresh = () => this.refreshSubject.next();

    tableUpdated = (settings: TableSettings<NumberColumn>) =>
        this.tableSettingsService.setTableSettings(getTableSettingsName(this.modeControl.value), settings);

    getModeBreakdownKey = (mode: CalcSourceMode): string => {
        switch (mode) {
            case "deployment": return "numbers.deployment.deploymentBreakdown";
            case "daily": return "numbers.dailyUpdates.dailyBreakdown";
            case "calculation":
            default:
                return "numbers.calculation.calculationBreakdown";
        }
    };

    getModeIcon = (mode: CalcSourceMode): string => {
        switch (mode) {
            case "deployment": return "device_hub";
            case "daily": return "date_range";
            case "calculation":
            default:
                return "calculate";
        }
    };

    private initLoad = () => {
        this.isLoading = true;
        this.hasError = false;
    };

    private getCalculationSources = (number: GetNumberDto | NumberRecordDetailDto, week: number, mode: CalcSourceMode):
        Observable<NumberRecordDetailDto[]> => {
        const { company, team } = getDelegatedItemCompanyTeam(number);
        const params = [
            company.id,
            team.id,
            toFiscalQuarter({ financialYear: number.financialYear, quarter: number.planningPeriod }),
            week,
            number.id,
        ] as const;

        switch (mode) {
            case "deployment":
                return this.updateNumbersApi.getDeployedChildren(...params);
            case "daily":
                return this.updateNumbersApi.getDailyChildren(...params);
            case "calculation":
            default:
                return this.updateNumbersApi.getCalculationSources(...params);
        }
    };
}

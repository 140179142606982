import { Injectable } from "@angular/core";
import { DepartmentsApi, SimpleDepartmentDto } from "@api";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";

import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";

@Injectable({
    providedIn: "root"
})
export class DepartmentRepository {
    private cache: ICompanyCache<SimpleDepartmentDto[]> = {};

    constructor(private readonly departmentsApi: DepartmentsApi) { }

    getDepartments = (companyId: string, force = false): Observable<SimpleDepartmentDto[]> => {
        let cacheItem = force ? null : getCompanyCacheItem(this.cache, companyId);
        if (!cacheItem) {
            cacheItem = this.getDepartmentsObservableForCompany(companyId);
            setCompanyCacheItem(this.cache, companyId, cacheItem);
        }
        return cacheItem;
    };

    private getDepartmentsObservableForCompany = (companyId: string): Observable<SimpleDepartmentDto[]> =>
        this.departmentsApi.listDepartments(companyId).pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}

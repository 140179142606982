import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { GetGoalDto, PlanGoalsApi } from "@api";

import { NotificationService } from "~services/notification.service";
import { toFiscalQuarter } from "~shared/commonfunctions";
import { ButtonState } from "~shared/components/status-button/status-button.component";

@Component({
    selector: "app-delete-goal-dialog",
    templateUrl: "./delete-goal-dialog.component.html",
    styleUrls: ["./delete-goal-dialog.component.scss"]
})
export class DeleteGoalDialogComponent {

    buttonState: ButtonState;

    get heading(): string {
        return this.goal.heading;
    }

    get isRecurring(): boolean {
        return this.goal.isRecurring;
    }

    constructor(
        private readonly planGoalsApi: PlanGoalsApi,
        private readonly dialogRef: MatDialogRef<DeleteGoalDialogComponent, boolean>,
        private readonly notificationService: NotificationService,
        @Inject(MAT_DIALOG_DATA) private readonly goal: GetGoalDto
    ) { }

    static open(dialog: MatDialog, goal: GetGoalDto) {
        return dialog.open<DeleteGoalDialogComponent, GetGoalDto, boolean>(
            DeleteGoalDialogComponent, {
            width: "500px",
            data: goal
        });
    }

    delete = () => {
        if (this.buttonState) return;

        this.buttonState = "loading";

        this.planGoalsApi.deleteGoal(
            this.goal.company.id,
            this.goal.team.id,
            toFiscalQuarter({ financialYear: this.goal.financialYear, quarter: this.goal.planningPeriod }),
            this.goal.id
        ).subscribe({
            next: () => {
                this.buttonState = "success";
                setTimeout(() => {
                    this.dialogRef.close(true);
                }, 1000);
            },
            error: () => {
                this.buttonState = "error";
                setTimeout(() => {
                    this.buttonState = undefined;
                }, 2000);
                this.notificationService.errorUnexpected();
            },
        });
    };
}

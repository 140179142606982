<form [formGroup]="form" (submit)="save()">
    <h4 mat-dialog-title>
        <wf-translate-template key="reports.update.attachmentsDialogTitle">
            <span *wfTransTplContent="'description'" class="report-description">{{ description }}</span>
            <span *wfTransTplContent="'week'" class="report-week">{{ 'period.weekShort' | translate: { week: record.week } }}</span>
        </wf-translate-template>
    </h4>
    <mat-dialog-content>
        <div class="row" *ngIf="isExternallyUpdated">
            <div class="col-sm-12">
                <mat-checkbox formControlName="updated">
                    {{ 'reports.externallyUpdated' | translate }}
                </mat-checkbox>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <ng-container *ngIf="isExternallyUpdated">
                    <div *ngFor="let link of permanentLinks" class="link">
                        <i class="fas fa-link"></i>
                        <a class="link-name" [attr.href]="link" target="_blank">{{ link }}</a>
                    </div>
                </ng-container>
                <div *ngFor="let doc of existingAttachments" class="file">
                    <i class="fas fa-file-alt"></i>
                    <a class="attachment-name" [attr.href]="doc.uri" target="_blank">{{ doc.name }}</a>
                    <i class="fas fa-minus-circle" (click)="removeExistingAttachment(doc)" *ngIf="canEdit"></i>
                </div>
                <div *ngFor="let doc of newAttachments" class="file">
                    <i class="fas fa-file-alt"></i>
                    <span class="attachment-name">{{ doc.name }}</span>
                    <i class="fas fa-minus-circle" (click)="removeNewAttachment(doc)" *ngIf="canEdit"></i>
                </div>
                <div *ngFor="let link of linksControl.value; let index = index;" class="link">
                    <i class="fas fa-link"></i>
                    <a class="link-name" [attr.href]="link" target="_blank">{{ link }}</a>
                    <i class="fas fa-minus-circle" (click)="removeLink(index)" *ngIf="canEdit"></i>
                </div>
                <div *ngIf="hasNoFiles" class="no-file">
                    {{ "reports.update.noAttachments" | translate }}
                </div>
                <div class="button-row text-right" *ngIf="canEdit && statusNotesEnabled">
                    <!-- If Status Notes is enabled, the upload button is below attachments/above notes -->
                    <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)"
                        [accept]="attachmentAcceptTypes" />
                    <button type="button" mat-button color="primary" (click)="fileInput.click()">
                        {{ "editAttachmentsDialog.uploadAttachment" | translate }} <mat-icon>upload</mat-icon>
                    </button>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="canEdit">
            <div class="col-sm-12">
                <form (submit)="addLink(); $event.preventDefault();">
                    <mat-form-field class="link-field" subscriptSizing="dynamic">
                        <mat-label>{{ 'reports.addLink' | translate }}</mat-label>
                        <input matInput type="url" name="link" [formControl]="linkControl" autocomplete="off"
                            maxlength="600" placeholder="https://example.com" />
                        <button mat-icon-button matSuffix type="submit" color="primary"
                            [appMatVisualDisable]="!linkControl.value" [matTooltip]="'Done' | translate">
                            <mat-icon>done</mat-icon>
                        </button>
                    </mat-form-field>
                </form>
            </div>
        </div>

        <div class="row" *ngIf="statusNotesEnabled">
            <div class="col-sm-12">
                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>{{ 'reports.notes' | translate }}</mat-label>
                    <textarea matInput formControlName="notes" name="notes" autocomplete="off"
                        [maxlength]="maxNotesLength" cdkTextareaAutosize cdkAutosizeMinRows="2"
                        cdkAutosizeMaxRows="5" cdkFocusInitial></textarea>
                    <mat-error *ngIf="notesControl.hasError('required')" translate="reports.notesRequired"></mat-error>
                </mat-form-field>
            </div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions align="end" *ngIf="canEdit">
        <ng-container *ngIf="!statusNotesEnabled">
            <!-- If Status Notes not enabled, we use an add button next to save -->
            <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)"
                [accept]="attachmentAcceptTypes" />
            <app-status-button type="button" (buttonClick)="fileInput.click()">
                {{ "editAttachmentsDialog.add" | translate }}
            </app-status-button>
        </ng-container>
        <app-status-button [disabled]="!hasChanges" type="submit" [state]="buttonState" [visualDisable]="!form.valid">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
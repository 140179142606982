import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { GoalRecordDetailDto, RequireNote, UpdateGoalsApi } from "@api";
import {
    BehaviorSubject, catchError, combineLatest, concatMap, debounceTime, EMPTY, filter, map, Observable, of, startWith,
    Subscription, switchMap, tap
} from "rxjs";

import { CustomStatusRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { GoalStateService } from "~services/state";
import { CommonFunctions, toFiscalQuarter } from "~shared/commonfunctions";
import { GoalStatus } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { isCustomGoalStatusesEnabled, isGoalCancellationEnabled } from "~shared/util/feature-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

import { GoalStatusNotesDialogComponent } from "./goal-status-notes-dialog/goal-status-notes-dialog.component";
import {
    areStatusesEqual, augmentCustomStatuses, getStatusOptions, getUnderlyingStatus, IGoalStatus, isCustomStatus, StatusWithNotes
} from "./goal-status-shared";

const getGoalStatus = (goal: GoalRecordDetailDto | undefined): IGoalStatus =>
    !goal ? GoalStatus.updateRequired : (goal.customStatus ?? goal.status);

@Component({
    selector: "app-goal-status-update",
    templateUrl: "./goal-status-update.component.html",
    styleUrls: ["./goal-status-update.component.scss"],
    host: {
        "class": "wf-goal-status-update"
    },
    encapsulation: ViewEncapsulation.None,
})
export class GoalStatusUpdateComponent extends WithDestroy() implements OnInit, OnDestroy {

    get goal() {
        return this.goalSubject.value;
    }

    @Input() set goal(value: GoalRecordDetailDto | undefined) {
        const isSameGoal = value?.id === this.goalSubject.value?.id && value?.week === this.goalSubject.value?.week;
        this.goalSubject.next(value);
        if (!isSameGoal) {
            this.statusControl.reset(getGoalStatus(value), { emitEvent: false });
        } else if (!this.statusControl.dirty) {
            this.statusControl.setValue(getGoalStatus(value), { emitEvent: false });
        }
    }

    get disabled(): boolean {
        return this.statusControl.disabled;
    }

    @Input() set disabled(value: boolean) {
        if (value) {
            this.statusControl.disable();
        } else {
            this.statusControl.enable();
        }
    }

    @Output() updated = new EventEmitter<GoalRecordDetailDto>();

    get isLoading() {
        return this.isLoadingInternal;
    }

    set isLoading(value: boolean) {
        if (value) {
            CommonFunctions.showLoader();
        } else {
            CommonFunctions.hideLoader();
        }
        this.isLoadingInternal = value;
    }

    get statusNotesEnabled() {
        return this.teamContext.features.statusNotesEnabled();
    }

    get hasNote(): boolean {
        return !!this.goal?.notes;
    }

    readonly statuses$: Observable<IGoalStatus[]>;

    readonly statusControl = new FormControl<IGoalStatus>(GoalStatus.updateRequired, { nonNullable: true });

    readonly areStatusesEqual = areStatusesEqual;

    get statusInternal(): GoalStatus {
        return getUnderlyingStatus(this.statusControl.value);
    }

    private goalSubject = new BehaviorSubject<GoalRecordDetailDto | undefined>(undefined);
    private isLoadingInternal = false;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly updateGoalsApi: UpdateGoalsApi,
        private readonly goalStateService: GoalStateService,
        private readonly teamContext: TeamContext,
        private readonly customStatusRepository: CustomStatusRepository,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
    ) {
        super();
        this.statuses$ = combineLatest({
            ct: this.teamContext.companyTeam$,
            goal: this.goalSubject,
        }).pipe(
            switchMap(({ ct, goal }) => {
                const cancellationEnabled = isGoalCancellationEnabled(ct);
                if (!goal || !isCustomGoalStatusesEnabled(ct)) {
                    return of(getStatusOptions(cancellationEnabled));
                }
                return this.customStatusRepository.getGoalStatuses(goal.company.id, goal.team.id).pipe(
                    map(customStatuses => augmentCustomStatuses(customStatuses, cancellationEnabled)),
                    startWith([goal.customStatus ?? goal.status]),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.statusControl.valueChanges.pipe(
                tap(() => this.isLoading = true),
                debounceTime(50),
                concatMap(this.checkForNotes),
                // If we show the notes dialog, we may hide the loading indicator
                // Make sure at this point we are always setting the loading status.
                tap(() => this.isLoading = true),
                concatMap(this.updateGoalStatus),
            ).subscribe(() => this.isLoading = false)
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    isCurrentStatusAvailable = (statuses: IGoalStatus[] | null): boolean => {
        if (!statuses) return false;
        const status = this.statusControl.value;
        return statuses.some(s => areStatusesEqual(status, s));
    };

    openNotesDialog = () => {
        if (this.isLoading || !this.goal || !this.statusNotesEnabled) return;
        GoalStatusNotesDialogComponent.open(this.dialog, this.goal, this.goal.customStatus ?? this.goal.status)
            .afterClosed().pipe(
                filter(Boolean),
                tap(response => this.statusControl.setValue(response.status, { emitEvent: false })),
                tap(() => this.isLoading = true),
                concatMap(this.updateGoalStatus),
            ).subscribe(() => this.isLoading = false);
    };

    private checkForNotes = (status: IGoalStatus): Observable<StatusWithNotes | null> => {
        if (!this.goal || areStatusesEqual(this.goal.status, status)) return of(null);
        const record = this.goal;
        const defaultResult: StatusWithNotes = { status, notes: undefined };
        if (!this.statusNotesEnabled) return of(defaultResult);
        const underlyingStatus = getUnderlyingStatus(status);
        const requireNote = this.teamContext.features.noteEnforcementEnabled() ? record.requireNote : RequireNote.never;
        if (requireNote !== RequireNote.always && underlyingStatus !== GoalStatus.offTarget && underlyingStatus !== GoalStatus.cancelled) {
            return of(defaultResult);
        }
        this.isLoading = false;
        return GoalStatusNotesDialogComponent.open(this.dialog, record, status)
            .afterClosed().pipe(
                switchMap(response => {
                    if (!response) {
                        const noteRequired = requireNote === RequireNote.always ||
                            requireNote === RequireNote.whenOffTarget && (
                                underlyingStatus === GoalStatus.offTarget ||
                                underlyingStatus === GoalStatus.cancelled
                            );
                        if (noteRequired) {
                            this.statusControl.reset(getGoalStatus(this.goal) ?? null, { emitEvent: false });
                            return EMPTY;
                        }
                    }
                    return of(response);
                }),
                tap(response => {
                    if (!response || areStatusesEqual(response.status, status)) return;
                    this.statusControl.setValue(response.status, { emitEvent: false });
                }),
                map(response => response ?? defaultResult)
            );
    };

    private updateGoalStatus = (dto: StatusWithNotes | null): Observable<GoalRecordDetailDto | null> => {
        if (!this.goal || !dto) return of(null);
        let update$: Observable<GoalRecordDetailDto>;
        const { company, team } = getDelegatedItemCompanyTeam(this.goal);
        if (isCustomStatus(dto.status)) {
            update$ = this.updateGoalsApi.updateCustomStatus(
                company.id,
                team.id,
                toFiscalQuarter({ financialYear: this.goal.financialYear, quarter: this.goal.planningPeriod }),
                this.goal.week,
                this.goal.id,
                {
                    customGoalStatusId: dto.status.id,
                    notes: dto.notes,
                }
            );
        } else {
            update$ = this.updateGoalsApi.updateStatus(
                company.id,
                team.id,
                toFiscalQuarter({ financialYear: this.goal.financialYear, quarter: this.goal.planningPeriod }),
                this.goal.week,
                this.goal.id,
                {
                    status: dto.status,
                    notes: dto.notes,
                },
            );
        }
        return update$.pipe(
            tap(goal => {
                if (goal) {
                    if (this.goal && this.goal.id === goal.id && this.goal.week === goal.week) {
                        this.goal.status = goal.status;
                        this.goal.customStatus = goal.customStatus;
                        this.goal.notes = goal.notes;
                    }
                    this.goalStateService.notifyUpdate(goal);
                    this.updated.emit(goal);
                    this.notificationService.success("goals.update.statusUpdated", undefined, undefined, true);
                    this.statusControl.reset(getGoalStatus(goal), { emitEvent: false });
                }
            }),
            catchError(() => {
                this.notificationService.errorUnexpected();
                this.statusControl.reset(getGoalStatus(this.goal), { emitEvent: false });
                return of(null);
            })
        );
    };
}

import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { SimpleCompanyDto, SimpleCompanyTeamDto, SimpleTeamDto } from "@api";

@Component({
    selector: "app-team-display",
    templateUrl: "./team-display.component.html",
    styleUrl: "./team-display.component.scss",
    changeDetection: ChangeDetectionStrategy.Default,
})
export class TeamDisplayComponent {
    readonly team = input.required<SimpleCompanyTeamDto | SimpleTeamDto>();
    readonly companyInput = input<SimpleCompanyDto | null>(null, { alias: "company" });

    readonly company = computed(() => {
        const companyInput = this.companyInput();
        if (companyInput) return companyInput;
        const team = this.team();
        if ("company" in team) return team.company;
        return null;
    });
}

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { apiBaseURL } from "../api-config/app-api.config";

@Injectable()
export class VisionService {
    private readonly baseUrl: string;
    constructor(
        private http: HttpClient) {
        this.baseUrl = apiBaseURL.baseURL;
    }

    getAllVisionType(): Observable<any> {
        const result = this.http.get(this.baseUrl + "api/VisionTypes/GetAllVisionTypes").pipe(map(s => s));
        return result;
    }

    getAllStatus(): Observable<any> {
        const result = this.http.get(this.baseUrl + "api/Status/GetAllStatus").pipe(map(s => s));
        return result;
    }

    saveVision(visionTypeList: any): Observable<any> {
        const result = this.http.post(this.baseUrl + "api/Vision/AddVision", visionTypeList).pipe(map(s => s));
        return result;
    }

    printVision(printData: any): Observable<any> {
        const data = {
            "html": printData
        };
        const result = this.http.post(this.baseUrl + "api/Vision/ConvertHtmlToPDF", data).pipe(map(s => s));
        return result;
    }

    getYearStatus(lockModel: any): Observable<any> {
        const result = this.http.post(this.baseUrl + "api/Vision/GetYearStatus", lockModel).pipe(map(s => s));
        return result;
    }

    getYearStatusList(): Observable<any> {
        const result = this.http.get(this.baseUrl + "api/CommonStatus/GetAllCommonStatus").pipe(map(s => s));
        return result;
    }

    LockYear(lockModel: any): Observable<any> {
        const result = this.http.post(this.baseUrl + "api/Vision/LockYear", lockModel).pipe(map(s => s));
        return result;
    }

    getAllVision(inputModel: any): Observable<any> {
        const result = this.http.post(this.baseUrl + "api/Vision/GetAllVision", inputModel).pipe(map(s => s));
        return result;
    }

    copyPreviousYearData(dataToCopy: any): Observable<any> {
        const result = this.http
            .post(this.baseUrl + "api/Vision/CopyPreviousYear?menuType=" + dataToCopy.menuType, dataToCopy)
            .pipe(map(s => s));
        return result;
    }
}

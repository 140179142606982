import { concatMap, EMPTY, map, merge, MonoTypeOperatorFunction, Observable, of, startWith, switchMap } from "rxjs";

export type UpdateHandler<TData, TEvent> = (data: TData, event: TEvent) => (TData | null);

export type UpdateSource<TData, TEvent> = readonly [
    events$: Observable<TEvent>,
    handler: UpdateHandler<TData, TEvent>,
];

export type UpdateSources<TData, TEvents extends readonly unknown[]> = {
    [K in keyof TEvents]: UpdateSource<TData, TEvents[K]>;
};

export const mergeUpdatesFrom = <TEvent, TData = TEvent[]>(
    events$: Observable<TEvent>,
    handler: UpdateHandler<TData, TEvent>):
    MonoTypeOperatorFunction<TData> => switchMap(data => events$.pipe(
        concatMap(event => {
            const newData = handler(data, event);
            if (newData) {
                data = newData;
                return of(newData);
            }
            return EMPTY;
        }),
        startWith(data),
    ));

export const mergeAllUpdatesFrom = <TData, TEvents extends readonly unknown[]>(
    ...sources: readonly [...UpdateSources<TData, TEvents>]
): MonoTypeOperatorFunction<TData> => {
    const handlers$ = merge(...sources.map(([events$, handler]) => events$.pipe(
        map(event => (data: TData) => handler(data, event)),
    )));
    return switchMap(data => handlers$.pipe(
        concatMap(handler => {
            const newData = handler(data);
            if (newData) {
                data = newData;
                return of(newData);
            }
            return EMPTY;
        }),
        startWith(data),
    ));
};

export const combineHandlers = <TData, TEvent>(...handlers: readonly UpdateHandler<TData, TEvent>[]): UpdateHandler<TData, TEvent> =>
    (data, event) => {
        for (const handler of handlers) {
            const newData = handler(data, event);
            if (newData) {
                return newData;
            }
        }
        return null;
    };

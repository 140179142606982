import { inject, Injector, runInInjectionContext } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateChildFn, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { CurrentCompanyDto, CurrentUserDto } from "@api";
import { combineLatest, isObservable, Observable, of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { CompanyTeamRepository } from "~repositories";
import { TeamContext, UserContext } from "~services/contexts";

export interface AuthenticateParams {
    user: CurrentUserDto | null;
    company: CurrentCompanyDto | null | undefined;
    route: ActivatedRouteSnapshot;
    state: RouterStateSnapshot;
    router: Router;
}

export type AuthenticateCallback = (data: AuthenticateParams) => boolean | UrlTree | Observable<boolean | UrlTree>;

const getRouteWithTeamScope = (finalRoute: ActivatedRouteSnapshot): ActivatedRouteSnapshot | null => {
    // There may be another route in the tree with company/team ID parameters.
    // As such, we need to ensure we're looking at the correct route.
    for (const route of finalRoute.pathFromRoot) {
        // The route we're looking for is the one with purely company/team ID parameters.
        if (route.routeConfig?.path === "company/:companyId/team/:teamId") {
            return route;
        }
        // We know the company/team ID parameters come first in the route. If we find another path segment first,
        // we know we won't find the company/team scope (at least, not the correct one).
        if (route.routeConfig?.path) {
            return null;
        }
    }
    return null;
};

const getCompanyTeamId = (route: ActivatedRouteSnapshot): { companyId: string; teamId: string } | null => {
    const teamScopeRoute = getRouteWithTeamScope(route);
    if (!teamScopeRoute) {
        return null;
    }

    const companyId = teamScopeRoute.paramMap.get("companyId");
    const teamId = teamScopeRoute.paramMap.get("teamId");

    return companyId && teamId ? { companyId, teamId } : null;
};

/**
 * A base guard that simplifies checking a user and company for access to a route.
 */
export const authGuardBuilder = (callback: AuthenticateCallback): CanActivateFn & CanActivateChildFn =>
    (route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> => {
        const router = inject(Router);
        const injector = inject(Injector);

        const companyTeamId = getCompanyTeamId(route);

        const companyTeam$ = companyTeamId ?
            inject(CompanyTeamRepository).getCompanyTeam(companyTeamId.companyId, companyTeamId.teamId) :
            inject(TeamContext).companyTeam$;

        return combineLatest([inject(UserContext).user$, companyTeam$]).pipe(
            switchMap(([user, companyTeam]) => {
                const canActivate = runInInjectionContext(injector, () => callback({
                    user,
                    company: companyTeam?.company,
                    route,
                    state,
                    router,
                }));
                return isObservable(canActivate) ? canActivate : of(canActivate);
            }),
            catchError(() => of(false)),
            map(canActivate => canActivate || router.createUrlTree(["/"])),
        );
    };

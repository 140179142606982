<h4 mat-dialog-title>
    <wf-translate-template key="watchlists.teamListPicker.title">
        <span class="item-heading" *wfTransTplContent="'item'">{{ itemHeading }}</span>
    </wf-translate-template>
</h4>
<mat-dialog-content>
    <mat-form-field subscriptSizing="dynamic">
        <mat-label>{{ 'watchlists.teamListPicker.team' | translate }}</mat-label>
        <app-auto-select [formControl]="teamControl" [options]="teams$ | async"
            [optionDisplayFunc]="getTeamDisplay" [compareWith]="compareTeams" [searchFunc]="getTeamSearchData">
            <app-team-display *appAutoOption="let team" [team]="team"></app-team-display>
        </app-auto-select>
    </mat-form-field>
    
    <ng-container *ngIf="!hasFailed && teamControl.value && (lists$ | async); let lists">
        <div class="lists-container" *ngIf="lists.length">
            <mat-slide-toggle *ngFor="let list of lists" [formControl]="list.watchedControl">
                <span class="wf-list-name">{{ list.list.name }}</span>
            </mat-slide-toggle>
            <button type="button" mat-button color="primary" (click)="add()">
                <mat-icon>add</mat-icon>
                {{ 'watchlists.createNewList' | translate }}
            </button>
        </div>
    
        <mat-card appearance="outlined" *ngIf="!lists.length">
            <mat-card-header>
                <mat-icon mat-card-avatar color="primary">visibility</mat-icon>
                <mat-card-title translate="watchlists.noListsCard.title"></mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <p translate="watchlists.noListsCard.messageTeam"></p>
            </mat-card-content>
            <mat-card-actions align="end">
                <button type="button" mat-flat-button color="primary" (click)="add()">
                    {{ "watchlists.createList" | translate}}
                </button>
            </mat-card-actions>
        </mat-card>
    </ng-container>

    <app-error-card *ngIf="hasFailed" (retry)="refresh()"
        [message]="'watchlists.loadFailed' | translate">
    </app-error-card>
</mat-dialog-content>

/**
 * The contents of this file are based on time timemachine npm package - https://github.com/schickling/timemachine
 * Several changes were needed to be made to this module:
 * 1. The module attempted to override the prototype of Date after reset. This caused an error on Chrome (and possibly other browsers)
 * 2. It automatically initialised on start (e.g. prior to being configured)
 * 3. There were several logic errors around some functions (although most are not used in the current implementation).
 */

/* eslint-disable prefer-rest-params */
const OriginalDate = Date;

interface ConfigOptions {
    dateString?: string;
    timestamp?: number;
    difference?: number;
    tick?: boolean;
    keepTime?: boolean;
}

class TimeMachine {
    timestamp = 0;
    tick = false;
    tickStartDate: Date | null = null;
    keepTime = false;
    difference = 0;

    config = (options: ConfigOptions): void => {
        this.timestamp = (options.dateString && OriginalDate.parse(options.dateString)) || options.timestamp || this.timestamp;
        this.difference = options.difference || this.difference;
        this.keepTime = options.keepTime || this.keepTime;
        this.tick = options.tick || this.tick;
        if (this.tick) {
            this.tickStartDate = new OriginalDate();
        }
        this.apply();
    }

    reset = (): void => {
        this.timestamp = 0;
        this.tick = false;
        this.tickStartDate = null;
        this.keepTime = false;
        this.difference = 0;
        // eslint-disable-next-line no-global-assign
        Date = OriginalDate;
    }

    private apply = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        // eslint-disable-next-line no-global-assign
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const newDate: any = function () {
            let date;
            if (self.keepTime && !arguments.length) {
                date = new OriginalDate();
            } else if (arguments.length === 1) {  //Cannot use OriginalDate.apply(this, arguments).  See http://stackoverflow.com/questions/181348/instantiating-a-javascript-object-by-calling-prototype-constructor-apply
                date = new OriginalDate(arguments[0]);
            } else if (arguments.length === 2) {
                date = new OriginalDate(arguments[0], arguments[1]);
            } else if (arguments.length === 3) {
                date = new OriginalDate(arguments[0], arguments[1], arguments[2]);
            } else if (arguments.length === 4) {
                date = new OriginalDate(arguments[0], arguments[1], arguments[2], arguments[3]);
            } else if (arguments.length === 5) {
                date = new OriginalDate(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4]);
            } else if (arguments.length === 6) {
                date = new OriginalDate(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5]);
            } else if (arguments.length === 7) {
                date = new OriginalDate(arguments[0], arguments[1], arguments[2], arguments[3], arguments[4], arguments[5], arguments[6]);
            } else {
                date = new OriginalDate(self.timestamp);
            }

            if (arguments.length === 0) {
                const difference = self.getDifference();
                if (difference !== 0) {
                    date = new OriginalDate(date.getTime() + difference);
                }
            }

            return date;
        }

        newDate.prototype = OriginalDate.prototype;
        newDate.now = function () {
            const timestamp = self.keepTime ? OriginalDate.now() : self.timestamp;
            return timestamp + self.getDifference();
        };
        newDate.OriginalDate = OriginalDate;
        newDate.UTC = OriginalDate.UTC;
        newDate.parse = OriginalDate.parse;
        // eslint-disable-next-line no-global-assign
        Date = newDate;
    }

    private getDifference = (): number => {
        let difference = this.difference;

        if (this.tick && this.tickStartDate) {
            difference += OriginalDate.now() - this.tickStartDate.getTime();
        }

        return difference;
    };
}

export default new TimeMachine();

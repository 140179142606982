// eslint-disable-next-line max-classes-per-file
import { computed, Injectable, Signal } from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { CurrentUserDto } from "@api";
import { Observable, startWith } from "rxjs";

import { Profile, Role } from "~shared/enums";
import { WithDestroy } from "~shared/mixins";
import { getProfileRoles } from "~shared/util/profile-helper";
import { shareReplayUntil } from "~shared/util/rx-operators";

import { SettingsService } from "../settings.service";
import { UserService } from "../user.service";

const isInProfile = (profile: Profile, role: Role | null): boolean => {
    const roles = getProfileRoles(profile);
    return !!role && roles.includes(role);
};

export abstract class UserContext {
    abstract readonly user: Signal<CurrentUserDto | null>;
    abstract readonly user$: Observable<CurrentUserDto | null>;

    readonly userId = computed(() => this.user()?.id ?? null);
    readonly role = computed(() => (this.user()?.roleName ?? null) as Role | null);

    readonly isSuperAdmin = computed(() => isInProfile(Profile.superAdmin, this.role()));
    readonly isEnterpriseAdmin = computed(() => isInProfile(Profile.enterpriseAdmin, this.role()));
    readonly isBillingAdmin = computed(() => isInProfile(Profile.billingAdmin, this.role()));
    readonly isHumanResources = computed(() => isInProfile(Profile.humanResources, this.role()));
    readonly isPartnerAdmin = computed(() => isInProfile(Profile.partnerAdmin, this.role()));
    readonly isPartnerManager = computed(() => isInProfile(Profile.partnerManager, this.role()));
    readonly isPartner = computed(() => isInProfile(Profile.partner, this.role()));
    readonly isAdmin = computed(() => isInProfile(Profile.admin, this.role()));
    readonly isFullUser = computed(() => isInProfile(Profile.fullUser, this.role()));

    abstract refresh(): void;
}

@Injectable()
export class SimpleUserContext extends WithDestroy(UserContext) {

    readonly user: Signal<CurrentUserDto | null>;
    readonly user$: Observable<CurrentUserDto | null>;

    constructor(
        private readonly settingsService: SettingsService,
        private readonly userService: UserService,
    ) {
        super();

        const user$ = this.userService.user$;
        const user = this.settingsService.currentUser;
        this.user = toSignal(user$, { initialValue: user });
        this.user$ = user$.pipe(
            startWith(user),
            shareReplayUntil(this.destroyed$),
        );
    }

    refresh = (): void => this.userService.refreshUser();
}

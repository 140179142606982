/* eslint-disable max-classes-per-file */
import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Directive, EventEmitter, Input, Output } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { GetActionDetailsDto } from "@api";

import { EditActionDialogComponent } from "~/app/shared/dialogs";
import { TeamContext } from "~services/contexts";
import { IActionInputModel } from "~shared/dialogs/edit-action-dialog/edit-action-dialog.component";
import { IOriginDetails } from "~shared/util/origin-builder";

@Directive()
export abstract class BaseAddChildActionButtonDirective<TSource = unknown> {
    abstract readonly mapToOrigin?: (source: TSource) => IOriginDetails;
    abstract readonly mapToInput?: (source: TSource) => IActionInputModel;

    abstract readonly hasActions: boolean;
    abstract readonly hasOpenActions: boolean;

    @Input() source?: TSource;

    @Input() set disabled(value: BooleanInput) {
        this.disabledInternal = coerceBooleanProperty(value);
    }

    get disabled(): boolean {
        return this.disabledInternal;
    }

    @Output() actionAdded = new EventEmitter<void>();

    get isCreationAllowed(): boolean {
        return !!this.source && (!this.creationAllowed || this.creationAllowed(this.source));
    }

    protected onActionsCreated?: (source: TSource, actions: GetActionDetailsDto[]) => void;
    protected creationAllowed?: (source: TSource) => boolean;

    private disabledInternal = false;

    constructor(
        private readonly teamContext: TeamContext,
        private readonly dialog: MatDialog,
    ) { }

    addAction = () => {
        const source = this.source;
        if (!source) return;
        const originDetails = this.mapToOrigin?.(source);
        if (!originDetails) return;

        const companyTeam = this.teamContext.companyTeam();
        if (!companyTeam) return;

        EditActionDialogComponent.openForAdd(this.dialog, {
            origin: originDetails,
            companyId: companyTeam.company.id,
            teamId: companyTeam.team.id,
            actionInput: this.mapToInput?.(source),
        }).afterClosed().subscribe(res => {
            if (res && res.type === "added") {
                this.actionAdded.emit();
                this.onActionsCreated?.(source, res.actions);
            }
        });
    };
}

@Component({
    selector: "app-add-child-action-button",
    templateUrl: "./add-child-action-button.component.html",
    styleUrls: ["./add-child-action-button.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddChildActionButtonComponent<TSource = unknown> extends BaseAddChildActionButtonDirective<TSource> {
    @Input() mapToOrigin?: (source: TSource) => IOriginDetails;
    @Input() mapToInput?: (source: TSource) => IActionInputModel;
    @Input() hasActions = false;
    @Input() hasOpenActions = false;
}

export interface CommonItemWithActions {
    actionsCount: number;
    openActionsCount: number;
}

@Directive()
export abstract class CommonAddChildActionButtonDirective<TSource extends CommonItemWithActions = CommonItemWithActions>
    extends BaseAddChildActionButtonDirective<TSource> {

    protected abstract notifyChange: (source: TSource) => void;

    get hasActions(): boolean {
        return !!this.source && !!this.source.actionsCount;
    }

    get hasOpenActions(): boolean {
        return !!this.source && !!this.source.openActionsCount;
    }

    protected onActionsCreated = (source: TSource, actions: GetActionDetailsDto[]): void => {
        source.actionsCount += actions.length;
        source.openActionsCount += actions.length;
        this.notifyChange(source);
    };
}

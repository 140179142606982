import { EntityReferenceDetailsDto, GetActionDetailsDto, GetActionDto, GoalRecordDetailDto, NumberRecordDetailDto, ReportRecordDetailDto, SimpleCompanyDto, SimpleTeamDto, WatchlistItemsDto } from "@api";
import { MonoTypeOperatorFunction, Observable } from "rxjs";

import {
    ActionReference, ActionStateService, Comparator, compareByDelegatedTeamAndId, compareByTeamIdAndWeek,
    compareReferenceByDelegatedTeamAndId, compareReferenceByTeamIdAndWeek, GoalReference, GoalStateService, ItemReference,
    mergeUpdatesFrom, NumberReference, NumberStateService, ReportReference, ReportStateService, StateEvent, UpdateSource
} from "~services/state";
import { EntityType } from "~shared/enums";

import { getDelegatedItemCompanyTeam, IDelegatedItem } from "./delegation-helper";

export type WatchlistUpdateSource<TList extends WatchlistItemsDto, TItem, TReference> = UpdateSource<TList, StateEvent<TItem, TReference>>;

type ItemWithOrigin = ({
    company: SimpleCompanyDto;
    team: SimpleTeamDto;
} | IDelegatedItem) & {
    id: string;
    origin?: EntityReferenceDetailsDto;
};

const getOptionalDelegatedCompanyTeam = (item: ItemWithOrigin): { company: SimpleCompanyDto; team: SimpleTeamDto } => {
    if ("isDelegated" in item) return getDelegatedItemCompanyTeam(item);
    return { company: item.company, team: item.team };
};

const optionalDelegatedComparer: Comparator<ItemWithOrigin> = (o1: ItemWithOrigin, o2: ItemWithOrigin) => {
    const { company: c1, team: t1 } = getOptionalDelegatedCompanyTeam(o1);
    const { company: c2, team: t2 } = getOptionalDelegatedCompanyTeam(o2);
    return c1.id === c2.id && t1.id === t2.id && o1.id === o2.id;
};

const optionalReferenceDelegatedComparer: Comparator<ItemWithOrigin, ItemReference> = (o1: ItemWithOrigin, o2: ItemReference) => {
    const { company: c1, team: t1 } = getOptionalDelegatedCompanyTeam(o1);
    return c1.id === o2.companyId && t1.id === o2.teamId && o1.id === o2.id;
};

export const updateChildrenFromEvent = <TItem extends ItemWithOrigin, TReference extends ItemReference>(
    items: TItem[], event: StateEvent<TItem, TReference>, originId: string, originType: EntityType,
    refreshParent?: () => void): TItem[] | null => {
    switch (event.type) {
        case "added":
            if (event.item.origin && event.item.origin.id === originId && event.item.origin.type === originType &&
                // Don't add delegated items - we should preference adding the primary reference
                (!("isDelegated" in event.item) || !event.item.isDelegated)) {
                refreshParent?.();
                if (items.some(a => optionalDelegatedComparer(a, event.item))) {
                    return items.map(a => optionalDelegatedComparer(a, event.item) ? event.item : a);
                } else {
                    return [...items, event.item];
                }
            }
            break;
        case "updated":
            if (items.some(a => optionalDelegatedComparer(a, event.item))) {
                return items.map(a => optionalDelegatedComparer(a, event.item) ? event.item : a);
            }
            break;
        case "deleted":
            if (items.some(a => optionalReferenceDelegatedComparer(a, event.item))) {
                refreshParent?.();
                return items.filter(a => !optionalReferenceDelegatedComparer(a, event.item));
            }
            break;
    }
    return null;
};

export const mergeChildUpdatesFrom = <
    TItem extends ItemWithOrigin,
    TState extends TItem = TItem,
    TReference extends ItemReference = ItemReference>(
        events$: Observable<StateEvent<TState, TReference>>, originId: string, originType: EntityType,
        refreshParent?: () => void):
    MonoTypeOperatorFunction<TItem[]> => mergeUpdatesFrom(events$,
        (items, event) => updateChildrenFromEvent(items, event, originId, originType, refreshParent));

declare type WatchlistItem = keyof Omit<WatchlistItemsDto, "list">;
declare type WatchlistItemType<TItem extends WatchlistItem> = TItem extends WatchlistItem ?
    (WatchlistItemsDto[TItem] extends (infer T)[] ? T : never) :
    never;

export const updateWatchlistFromEvent = <TList extends WatchlistItemsDto, TType extends WatchlistItem, TReference>(
    watchlist: TList, type: TType,
    event: StateEvent<WatchlistItemType<TType>, TReference>,
    comparator: Comparator<WatchlistItemType<TType>>,
    referenceComparator: Comparator<WatchlistItemType<TType>, TReference>):
    TList | null => {
    const items = watchlist[type] as WatchlistItemType<TType>[];
    switch (event.type) {
        case "added":
            break;
        case "updated": {
            if (items.some(item => comparator(item, event.item))) {
                return {
                    ...watchlist,
                    [type]: items.map(item => comparator(item, event.item) ? event.item : item),
                };
            }
            break;
        }
        case "deleted": {
            if (items.some(item => referenceComparator(item, event.item))) {
                return {
                    ...watchlist,
                    [type]: items.filter(item => !referenceComparator(item, event.item)),
                };
            }
            break;
        }
    }
    return null;
};

export const watchlistActionUpdateHandler = <TList extends WatchlistItemsDto>(
    actions: GetActionDto[], stateService: ActionStateService):
    WatchlistUpdateSource<TList, GetActionDetailsDto, ActionReference> =>
    [
        stateService.eventsForActions(...actions),
        (data, event) => updateWatchlistFromEvent(data, "actions", event,
            compareByDelegatedTeamAndId, compareReferenceByDelegatedTeamAndId),
    ];

export const watchlistGoalUpdateHandler = <TList extends WatchlistItemsDto>(
    goals: GoalRecordDetailDto[], stateService: GoalStateService):
    WatchlistUpdateSource<TList, GoalRecordDetailDto, GoalReference> =>
    [
        stateService.eventsForGoals(...goals),
        (data, event) => updateWatchlistFromEvent(data, "goals", event,
            compareByTeamIdAndWeek, compareReferenceByTeamIdAndWeek),
    ];

export const watchlistNumberUpdateHandler = <TList extends WatchlistItemsDto>(
    numbers: NumberRecordDetailDto[], stateService: NumberStateService):
    WatchlistUpdateSource<TList, NumberRecordDetailDto, NumberReference> =>
    [
        stateService.eventsForNumbers(...numbers),
        (data, event) => updateWatchlistFromEvent(data, "numbers", event,
            compareByTeamIdAndWeek, compareReferenceByTeamIdAndWeek),
    ];

export const watchlistReportUpdateHandler = <TList extends WatchlistItemsDto>(
    reports: ReportRecordDetailDto[], stateService: ReportStateService):
    WatchlistUpdateSource<TList, ReportRecordDetailDto, ReportReference> =>
    [
        stateService.eventsForReports(...reports),
        (data, event) => updateWatchlistFromEvent(data, "reports", event,
            compareByTeamIdAndWeek, compareReferenceByTeamIdAndWeek),
    ];

import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { GoalRecordDetailDto } from "@api";

import { sortNumber } from "~shared/util/sorters";
import { trackByIdAndWeek } from "~shared/util/table-helper";

declare type GoalRecordColumn = "week" | "status";

const sortByWeek = (records: GoalRecordDetailDto[]) =>
    records.sort(sortNumber.ascending(r => r.week));

@Component({
    selector: "app-goal-records-table",
    templateUrl: "./goal-records-table.component.html",
    styleUrls: ["./goal-records-table.component.scss"]
})
export class GoalRecordsTableComponent {

    @Input() set records(value: GoalRecordDetailDto[] | null) {
        this.dataSource.data = sortByWeek(value ?? []);
    }

    get records(): GoalRecordDetailDto[] {
        return this.dataSource.data;
    }

    @Input() selectedWeek: number | null | undefined;

    @Output() recordUpdated = new EventEmitter<GoalRecordDetailDto>();

    readonly dataSource = new MatTableDataSource<GoalRecordDetailDto>();
    readonly displayedColumns: GoalRecordColumn[] = ["week", "status"];

    readonly trackByIdAndWeek = trackByIdAndWeek;

}

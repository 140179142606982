<table mat-table class="wf-table" [dataSource]="dataSource" [trackBy]="trackByIdAndWeek" wfTableFocus>
    <ng-container matColumnDef="week">
        <th mat-header-cell *matHeaderCellDef class="wf-column-heading">
            {{ 'period.week' | translate }}
        </th>
        <td mat-cell *matCellDef="let record" class="wf-column-heading">
            <mat-icon *ngIf="record.week === selectedWeek" class="selected-week"
                [matTooltip]="'homepage.selectedWeek' | translate">today</mat-icon>
            {{ record.week }}
        </td>
    </ng-container>

    <ng-container matColumnDef="result">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'numbers.result' | translate }}
        </th>
        <td mat-cell *matCellDef="let record">
            <app-update-number-result [record]="record" (updated)="recordUpdated.emit($event)">
            </app-update-number-result>

            <app-notes-callout *ngIf="record.notes" [notes]="record.notes"></app-notes-callout>
        </td>
    </ng-container>
    
    <ng-container matColumnDef="target">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'numbers.target' | translate }}
        </th>
        <td mat-cell *matCellDef="let record">
            <app-number-target [target]="record.recordTarget" [numberType]="record.type"></app-number-target>
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let element; let i = index; columns: displayedColumns;" [wfRowFocus]="element"
        [wfRowFocusIndex]="i"></tr>
</table>
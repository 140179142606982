<form (submit)="save()">
    <h4 mat-dialog-title>{{ 'editAttachmentsDialog.heading' | translate: { description: description } }}</h4>
    <mat-dialog-content>
        <div class="row">
            <div class="col-sm-12">
                <div *ngFor="let doc of existingAttachments" class="file">
                    <i class="fas fa-file-alt"></i>
                    <a class="attachment-name" [attr.href]="doc.uri" target="_blank">{{ doc.name }}</a>
                    <i class="fas fa-minus-circle" (click)="removeExistingAttachment(doc)" *ngIf="canEdit"></i>
                </div>
                <div *ngFor="let doc of newAttachments" class="file">
                    <i class="fas fa-file-alt"></i>
                    <span class="attachment-name">{{ doc.name }}</span>
                    <i class="fas fa-minus-circle" (click)="removeNewAttachment(doc)" *ngIf="canEdit"></i>
                </div>
                <div *ngIf="hasNoFiles" class="no-file">
                    {{ "editAttachmentsDialog.noFiles" | translate }}
                </div>
            </div>
        </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end" *ngIf="canEdit">
        <input type="file" #fileInput style="display: none;" (change)="onFileSelected($event)"
            [accept]="attachmentAcceptTypes" />
        <app-status-button type="button" (buttonClick)="fileInput.click()">
            {{ "editAttachmentsDialog.add" | translate }}
        </app-status-button>
        <app-status-button [disabled]="!hasChanges" type="submit" [state]="buttonState">
            {{ "Save" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>
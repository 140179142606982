<app-homepage-scaffold *ngIf="newsItem">
    <span *appHomepageTitle class="multiline-description">{{ newsItem.heading }}</span>

    <ng-template appHomepageActions>
        <span *ngIf="displayNoted$ | async" class="noted-indicator">
            {{'news.noted' | translate}}
        </span>

        <button *ngIf="canMarkNoted$ | async" mat-flat-button type="button" color="primary" class="header-action"
            (click)="markNoted()" [disabled]="isNoting || newsItem.isNoted">
            {{'news.noted' | translate}}
        </button>

        <app-add-news-child-action-button [source]="newsItem"></app-add-news-child-action-button>
        <app-add-news-child-discussion-button [source]="newsItem"></app-add-news-child-discussion-button>
        <app-edit-news-item-menu *ngIf="isTeamOwned" [newsItem]="newsItem" (updated)="afterUpdated($event)"
            (deleted)="afterDeleted()"></app-edit-news-item-menu>
    </ng-template>

    <section class="wf-section">
        <dl>
            <div class="full-width">
                <dt translate="news.description"></dt>
                <dd>
                    <span class="multiline-description">{{ newsItem.description }}</span>
                </dd>
            </div>
            <div>
                <dt translate="news.creator"></dt>
                <dd>
                    {{ getUserName(newsItem.creator) }}
                </dd>
            </div>
            <div>
                <dt translate="news.created"></dt>
                <dd>{{ newsItem.createdDateLocal | dateFormat }}</dd>
            </div>
            <div>
                <dt translate="news.team"></dt>
                <dd>{{ newsItem.team.name }} ({{ newsItem.company.name }})</dd>
            </div>
            <div *ngIf="newsItem.isNoted">
                <dt translate="news.noted"></dt>
                <dd>{{ newsItem.notedDateLocal | dateFormat }}</dd>
            </div>
        </dl>
    </section>

    <section *ngIf="newsItem.links.length" wf-expandable-section
        [label]="'news.links' | translate" expanded>

        <div *ngFor="let link of newsItem.links" class="link">
            <i class="fas fa-link"></i>
            <a class="link-name" [attr.href]="link" target="_blank">{{ link }}</a>
        </div>
    
    </section>

    <section *ngIf="newsItem.attachments.length" wf-expandable-section
        [label]="'news.attachments' | translate" expanded>

        <div *ngFor="let doc of newsItem.attachments" class="attachment">
            <i class="fas fa-file-alt"></i>
            <a class="attachment-name" [attr.href]="doc.uri" target="_blank">{{ doc.name }}</a>
        </div>
    
    </section>

    <section wf-expandable-section [label]="'news.recipients' | translate">
        <div class="table-container">
            <table mat-table class="wf-table" [dataSource]="recipientsDataSource" wfTableFocus
                matSort matSortActive="team" matSortDirection="asc" matSortDisableClear #recipientSort="matSort">
                <ng-container matColumnDef="team">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
                        {{ 'news.team' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let team" class="wf-column-heading">
                        {{ team.name }}
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="company">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        {{ 'news.company' | translate }}
                    </th>
                    <td mat-cell *matCellDef="let team">
                        {{ team.company.name }}
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="recipientsColumns"></tr>
                <tr mat-row *matRowDef="let row; let i = index; columns: recipientsColumns;" [wfRowFocus]="row" [wfRowFocusIndex]="i"></tr>
            </table>
        </div>
    </section>

</app-homepage-scaffold>
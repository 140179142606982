import { authGuardBuilder } from "./base-auth.guard";
import { CustomRouteData } from "./custom-route";
import { FeatureStatus } from "./enums";

export const planFeatureGuard = authGuardBuilder(({ company, route }) => {
    const routeData = route.data as CustomRouteData;

    const features = routeData?.planFeatures;
    if (!features) return true;

    return features.every(feature => company?.planTier.featureAvailability[feature] === FeatureStatus.enabled);
});

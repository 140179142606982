import { Injectable } from "@angular/core";

export interface IWeek {
    financialYear: number;
    quarter: number;
    week: number;
}

interface IWeekCache {
    [companyId: string]: IWeek;
}

@Injectable()
export class WeekContextRepository {

    private readonly selectedWeeks: IWeekCache = {};

    getSelectedWeek = (companyId: string): IWeek | null =>
        this.selectedWeeks[companyId] ?? null;

    setSelectedWeek = (companyId: string, value: IWeek): void => {
        this.selectedWeeks[companyId] = value;
    };
}

import { ChangeDetectionStrategy, Component, computed, input } from "@angular/core";
import { GetGoalDto, GoalRecordDto, GoalStatus } from "@api";

import { ExpandedWeekData, expandWeekData } from "~shared/util/summary-component-helpers";

@Component({
    selector: "app-goal-status-summary",
    templateUrl: "./goal-status-summary.component.html",
    styleUrl: "./goal-status-summary.component.scss",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GoalStatusSummaryComponent {
    readonly goal = input.required<GetGoalDto>();

    readonly weekData = computed(() => expandWeekData(this.goal()));

    readonly latestWeek = computed(() => this.goal().latestWeek ?? null);

    getCellClass = (data: ExpandedWeekData<GoalRecordDto>) => {
        switch (data.record?.status) {
            case GoalStatus.updateRequired: {
                const latestWeek = this.latestWeek();
                if (latestWeek == null || data.week > latestWeek) {
                    return "wf-goal-status-summary-cell-update-required-future";
                }
                return "wf-goal-status-summary-cell-update-required";
            }
            case GoalStatus.offTarget:
                return "wf-goal-status-summary-cell-off-target";
            case GoalStatus.onTarget:
                return "wf-goal-status-summary-cell-on-target";
            case GoalStatus.complete:
                return "wf-goal-status-summary-cell-complete";
            case GoalStatus.cancelled:
                return "wf-goal-status-summary-cell-cancelled";
            case undefined:
            default:
                return "wf-goal-status-summary-cell-empty";
        }
    };
}

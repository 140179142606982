import { Injectable } from "@angular/core";

interface IYearCache {
    [companyId: string]: number;
}

@Injectable()
export class YearContextRepository {

    private selectedYears: IYearCache = {};

    getSelectedYear = (companyId: string): number | null =>
        this.selectedYears[companyId] ?? null;

    setSelectedYear = (companyId: string, value: number): void => {
        this.selectedYears[companyId] = value;
    };
}

import { GetNumberDto, PlanningPeriodDetailsDto } from "@api";
import { ScatterDataPoint } from "chart.js";
import * as moment from "moment";

export { getNextPeriod, getPreviousPeriod } from "~shared/util/period-helper";

export interface IWeekInfo {
    readonly financialYear: number;
    readonly planningPeriod: number;
    readonly collectionPeriod: number;
    /** Week start date */
    readonly startDate: moment.Moment;
}

export interface INumberPeriod {
    period: PlanningPeriodDetailsDto;
    number: GetNumberDto | null;
}

export declare type WeekDataPoint = ScatterDataPoint & IWeekInfo;

export declare type DataPoint = WeekDataPoint | null;

export interface INumberChartData {
    readonly weeks: IWeekInfo[];
    readonly results: DataPoint[];
    readonly upperBounds: DataPoint[];
    readonly lowerBounds: DataPoint[];
    readonly averages: DataPoint[];
}

export const parseWeekStartDate = (value: string): moment.Moment => moment.utc(value).local(true);

export const generateDataPoint = (value: number | null | undefined, week: IWeekInfo): DataPoint =>
({
    x: week.startDate.clone().add(-1, "day").valueOf(),
    y: (value ?? null) as number, // The interface thinks the y value can't be null, but it can. We need this for labelling.
    financialYear: week.financialYear,
    planningPeriod: week.planningPeriod,
    collectionPeriod: week.collectionPeriod,
    startDate: week.startDate,
})
    ;

export const calculateNumberData = (number: GetNumberDto): INumberChartData => {
    const weeks: IWeekInfo[] = [];
    const results: DataPoint[] = [];
    const upperBounds: DataPoint[] = [];
    const lowerBounds: DataPoint[] = [];

    for (const record of number.records) {
        const weekDate = parseWeekStartDate(record.weekStartDate);

        const weekInfo: IWeekInfo = {
            financialYear: number.financialYear,
            planningPeriod: number.planningPeriod,
            collectionPeriod: record.week,
            startDate: weekDate
        };

        weeks.push(weekInfo);
        results.push(generateDataPoint(record.result, weekInfo));
        lowerBounds.push(generateDataPoint(record.target.lowerBound, weekInfo));
        upperBounds.push(generateDataPoint(record.target.upperBound, weekInfo));
    }

    const averages = weeks.reduce((arr, week, i) => {
        const prevResults = results.slice(0, i + 1).filter((r): r is WeekDataPoint => !!r && r.y !== null);
        let average: number | null;
        if (!prevResults.length) {
            average = null;
        } else {
            average = prevResults.reduce((s, v) => s + v.y, 0) / prevResults.length;
        }
        arr.push(generateDataPoint(average, week));
        return arr;
    }, [] as DataPoint[]);

    return { weeks, results, upperBounds, lowerBounds, averages };
};

const calculateBlankPeriodData = (period: PlanningPeriodDetailsDto): INumberChartData => {
    const weeks: IWeekInfo[] = [];
    const points: DataPoint[] = [];

    for (const week of period.collectionPeriods) {
        const weekInfo: IWeekInfo = {
            financialYear: period.financialYear,
            planningPeriod: period.index,
            collectionPeriod: week.index,
            startDate: parseWeekStartDate(week.startDate)
        };
        weeks.push(weekInfo);
        points.push(generateDataPoint(null, weekInfo));
    }

    return { weeks, results: points, upperBounds: points, lowerBounds: points, averages: points };
};

export const calculateNumberPeriodData = (numberPeriod: INumberPeriod): INumberChartData =>
    numberPeriod.number ? calculateNumberData(numberPeriod.number) : calculateBlankPeriodData(numberPeriod.period);

const combineArrays = <T>(first: T[], second: T[]) => [...first, ...second];

export const combineNumberChartData = (first: INumberChartData, second: INumberChartData): INumberChartData => ({
    weeks: combineArrays(first.weeks, second.weeks),
    results: combineArrays(first.results, second.results),
    upperBounds: combineArrays(first.upperBounds, second.upperBounds),
    lowerBounds: combineArrays(first.lowerBounds, second.lowerBounds),
    averages: combineArrays(first.averages, second.averages)
});

/**
 * SAASCLOUDAPP.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export enum ReminderNames {
    actionCreated = 0,
    actionUpdated = 1,
    issueCreated = 2,
    issueUpdated = 3,
    addNewPartner = 4,
    userInvited = 5,
    updateAppNotification = 6,
    addSuggestion = 7,
    minutesOfMeetings = 8,
    issueSolved = 9,
    newClientAdded = 10,
    newsItemCreated = 11,
    newsItemEdited = 12,
    reportUpdated = 13,
    meetingRescheduled = 14,
    meetingCancelled = 15,
    meetingReinstated = 16,
    enterpriseSummaryReport = 17,
    teamWatchlistNotification = 18,
    personalWatchlistNotification = 19
}


<mat-select disableOptionCentering [attr.name]="name" [disabled]="disabled" [required]="required"
    [placeholder]="placeholder" [value]="value" [placeholder]="placeholder" (closed)="onBlur()"
    (valueChange)="setValue($event)" [attr.aria-describedby]="ariaDescribedBy">

    <mat-select-trigger *ngIf="value">
        {{ dateFormatExampleDate | date: value }}
        <span class="date-format">{{ value }}</span>
    </mat-select-trigger>
    
    <mat-option *ngIf="!!value && !dateFormats.includes(value)" [value]="value">
        <div class="date-format-example">{{ dateFormatExampleDate | date: value }}</div>
        <div class="date-format">{{ value }}</div>
    </mat-option>
    
    <mat-option *ngFor="let dateFormat of dateFormats" [value]="dateFormat">
        <div class="date-format-example">{{ dateFormatExampleDate | date: dateFormat }}</div>
        <div class="date-format">{{ dateFormat }}</div>
    </mat-option>
</mat-select>
import { ChangeDetectionStrategy, Component, HostBinding, Input } from "@angular/core";

import { ActionProgress } from "~shared/enums";
import { actionProgressNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "app-action-progress",
    templateUrl: "./action-progress.component.html",
    styleUrls: ["./action-progress.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: "wf-action-progress wf-status-indicator",
    },
})
export class ActionProgressComponent {
    @Input() progress: ActionProgress = ActionProgress.notStarted;

    @HostBinding("class")
    get className() {
        switch (this.progress) {
            case ActionProgress.notStarted: return "wf-status-indicator-outline";
            case ActionProgress.inProgress: return "wf-status-indicator-shaded wf-status-indicator-intermediate";
            case ActionProgress.overdue: return "wf-status-indicator-shaded wf-status-indicator-failure";
            case ActionProgress.complete: return "wf-status-indicator-filled wf-status-indicator-success";
            case ActionProgress.cancelled: return "wf-status-indicator-filled";
            default: return undefined;
        }
    }

    get progressKeyName() {
        return actionProgressNameKey(this.progress);
    }
}

import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { TeamContext } from "~services/contexts";
import { DelegationResponsibility } from "~shared/enums";
import { IDelegatedItem } from "~shared/util/delegation-helper";
import { getUserName } from "~shared/util/user-helper";

@Component({
    selector: "app-delegated-user-display",
    templateUrl: "./delegated-user-display.component.html",
    styleUrls: ["./delegated-user-display.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DelegatedUserDisplayComponent {

    @Input() entity: IDelegatedItem | null = null;

    @Input() set primary(value: boolean) {
        this.primaryInternal = coerceBooleanProperty(value);
    }

    get primary(): boolean {
        return this.primaryInternal;
    }

    @Input() mode: "owner" | "team" | "updater" = "owner";

    /**
     * Determine whether or not we should show the base or delegation info.
     */
    get displayedInfo(): "base" | "delegation" {
        if (!this.entity || !this.entity.delegation) return "base";
        if (this.primary || !this.entity.isDelegated) {
            // If we have requested to show the primary info, or the entity is not loaded in the delegated context,
            // we almost always want to show the base info.
            // However, if we are in the "updater" mode, it is possible the item does not have an updater.
            // In this case, we want to show the delegation info.
            if (this.mode === "updater" && (!("updater" in this.entity) || !this.entity.updater)) {
                return "delegation";
            }
            return "base";
        }

        // If the entity is loaded in the delegated context, we almost always want to show the delegation info.
        // However, the current mode may not have responsibility, in which case we want to show the base info.
        if (!this.hasResponsibility) {
            return "base";
        }
        return "delegation";
    }

    get hasResponsibility(): boolean {
        // If we are in owner mode, we only have responsibility if we have delegated ownership.
        // If in team or updater mode, we always have responsibility.
        return this.mode !== "owner" || this.delegationResponsibility === DelegationResponsibility.ownership;
    }

    readonly getUserName = getUserName;

    readonly delegationEnabled = this.teamContext.features.delegationEnabled;

    private get delegationResponsibility(): DelegationResponsibility {
        return this.entity && this.entity.delegation && "responsibility" in this.entity.delegation ?
            this.entity.delegation.responsibility : DelegationResponsibility.ownership;
    }

    private primaryInternal = false;

    constructor(private readonly teamContext: TeamContext) { }

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_primary: BooleanInput;

}

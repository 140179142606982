import { Directive, TemplateRef } from "@angular/core";

/**
 * Marker directive for the option content
 */
@Directive({
    selector: "[appMultiOption]"
})
export class MultiOptionContentDirective<TOpt> {
    constructor(public template: TemplateRef<TOpt>) { }
}

import { Injectable } from "@angular/core";
import { AnnualGoalsApi, GetAnnualGoalDto } from "@api";
import { Observable, shareReplay } from "rxjs";

import { getTeamCacheItem, ICompanyTeamCache, retryWithDelay, setTeamCacheItem } from "~shared/util/caching";

interface IYearCache {
    [financialYear: number]: ICompanyTeamCache<GetAnnualGoalDto[]>;
}

@Injectable({
    providedIn: "root"
})
export class AnnualGoalRepository {

    private cache: IYearCache = {};

    constructor(private readonly annualGoalsApi: AnnualGoalsApi) { }

    clearCache = () => {
        this.cache = {};
    };

    getAnnualGoals = (companyId: string, teamId: string, financialYear: number | string): Observable<GetAnnualGoalDto[]> => {
        const year = typeof financialYear === "number" ? financialYear : parseInt(financialYear, 10);
        const cache = this.getCompanyCache(year);
        let cacheItem = getTeamCacheItem(cache, companyId, teamId);
        if (!cacheItem) {
            cacheItem = this.getAnnualGoalsObservableForCompanyTeamYear(companyId, teamId, year);
            setTeamCacheItem(cache, companyId, teamId, cacheItem);
        }
        return cacheItem;
    };

    private getCompanyCache = (financialYear: number): ICompanyTeamCache<GetAnnualGoalDto[]> => {
        let cache = this.cache[financialYear];
        if (!cache) {
            cache = {};
            this.cache[financialYear] = cache;
        }
        return cache;
    };

    private getAnnualGoalsObservableForCompanyTeamYear = (companyId: string, teamId: string, financialYear: number) =>
        this.annualGoalsApi.listAnnualGoals(
            companyId,
            teamId,
            financialYear
        ).pipe(
            retryWithDelay(),
            shareReplay({ bufferSize: 1, refCount: false }),
        );
}

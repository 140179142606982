import { RecurrenceDto, SimplePlanTierDto, UpdateScheduleDto } from "@api";
import { TranslateService } from "@ngx-translate/core";

import {
    ActionProgress, ApprovalType, CaptureMethod, ClientInstanceSource, ClientStatus, DayOfWeek, DiscussionStatus,
    DiscussionType, EntityType, GoalStatus, NumberEntryType, NumberTargetType, NumberType, OnboardingStatus,
    PartnerStatus, PartnerType, PaymentMethod, PlanningStatus, Priority, RecurrenceType, Role,
    SolutionApprovalActionType, SubscriptionProductType, SubscriptionStatus, SubscriptionType, UpdateScheduleType
} from "~shared/enums";

export const getWidgetForNameKey = (widgetFor: string, isAucbg: boolean): string => {
    switch (widgetFor) {
        case "discussions":
            return "widgetType.discussions";
        case "outstandingactions":
            return "widgetType.outstandingactions";
        case "goalstatus":
            if (isAucbg) {
                return "widgetType.targetstatus";
            } else {
                return "widgetType.goalstatus";
            }
        case "numberstatus":
            return "widgetType.numberstatus";
        case "reportstatus":
            return "widgetType.reportstatus";
        case "days":
            return "widgetType.days";
        case "actionsduethisweek":
            return "widgetType.actionsduethisweek";
        case "allopenactions":
            return "widgetType.allopenactions";
        case "upcomingactions":
            return "widgetType.upcomingactions";
        case "enterprise":
            return "widgetType.enterprise";
        case "nextweek":
            return "widgetType.nextweek";
        case "nextweekenterprise":
            return "widgetType.nextweekenterprise";
        case "enterpriseoutstandingactions":
            return "widgetType.enterpriseoutstandingactions";
        case "enterpriseactionsduethisweek":
            return "widgetType.enterpriseactionsduethisweek";
        case "enterpriseallopenactions":
            return "widgetType.enterpriseallopenactions";
        case "enterpriseupcomingactions":
            return "widgetType.enterpriseupcomingactions";
        case "numberchart":
            return "widgetType.numberchart";
        case "teamperformance":
            return "widgetType.teamperformance";
        case "multinumberchart":
            return "widgetType.multinumberchart";
        case "goalstatistics":
            return "widgetType.goalstatistics";
        case "watchlist":
            return "widgetType.watchlist";
    }

    return "";
};

export const getRecurrenceTypeNameKey = (type: RecurrenceType): string => {
    switch (type) {
        case RecurrenceType.daily: return "recurrenceType.day";
        case RecurrenceType.weekly: return "recurrenceType.week";
        case RecurrenceType.monthlyWeekDay: return "recurrenceType.month";
    }
    return "";
};

export const getRecurrenceTypePluralNameKey = (type: RecurrenceType): string => {
    switch (type) {
        case RecurrenceType.daily: return "recurrenceType.days";
        case RecurrenceType.weekly: return "recurrenceType.weeks";
        case RecurrenceType.monthlyWeekDay: return "recurrenceType.months";
    }
    return "";
};

export const getDayOfWeekNameKey = (day: DayOfWeek): string => {
    switch (day) {
        case DayOfWeek.sunday: return "dayOfWeek.sunday";
        case DayOfWeek.monday: return "dayOfWeek.monday";
        case DayOfWeek.tuesday: return "dayOfWeek.tuesday";
        case DayOfWeek.wednesday: return "dayOfWeek.wednesday";
        case DayOfWeek.thursday: return "dayOfWeek.thursday";
        case DayOfWeek.friday: return "dayOfWeek.friday";
        case DayOfWeek.saturday: return "dayOfWeek.saturday";
    }
    return "";
};

export const getShortDayOfWeekNameKey = (day: DayOfWeek): string => {
    switch (day) {
        case DayOfWeek.sunday: return "dayOfWeekShort.sunday";
        case DayOfWeek.monday: return "dayOfWeekShort.monday";
        case DayOfWeek.tuesday: return "dayOfWeekShort.tuesday";
        case DayOfWeek.wednesday: return "dayOfWeekShort.wednesday";
        case DayOfWeek.thursday: return "dayOfWeekShort.thursday";
        case DayOfWeek.friday: return "dayOfWeekShort.friday";
        case DayOfWeek.saturday: return "dayOfWeekShort.saturday";
    }
    return "";
};

export const getIndicesNameKey = (index: number): string => {
    switch (index) {
        case 1: return "indices.first";
        case 2: return "indices.second";
        case 3: return "indices.third";
        case 4: return "indices.fourth";
        case 5: return "indices.last";
    }
    return "";
};

export const getUpdateScheduleTypeNameKey = (type: UpdateScheduleType): string => {
    switch (type) {
        case UpdateScheduleType.everyPeriod: return "updateScheduleType.everyWeek";
        case UpdateScheduleType.everyMeeting: return "updateScheduleType.everyMeeting";
        case UpdateScheduleType.custom: return "updateScheduleType.custom";
    }
    return "";
};

export const getRecurrenceDescription = (translate: TranslateService, recurrence: RecurrenceDto): string => {
    const interpolationParams = {
        interval: recurrence.interval,
        dayOfWeek: recurrence.dayOfWeek !== undefined && recurrence.dayOfWeek !== null
            ? translate.instant(getDayOfWeekNameKey(recurrence.dayOfWeek) ?? "")
            : undefined,
        index: recurrence.index !== undefined && recurrence.index !== null
            ? translate.instant(getIndicesNameKey(recurrence.index) ?? "")
            : undefined
    };

    if (recurrence.interval === 1) {
        switch (recurrence.type) {
            case RecurrenceType.daily: return translate.instant("recurrenceDescription.daily", interpolationParams);
            case RecurrenceType.weekly: return translate.instant("recurrenceDescription.weekly", interpolationParams);
            case RecurrenceType.monthlyWeekDay: return translate.instant("recurrenceDescription.monthly", interpolationParams);
        }
    } else {
        switch (recurrence.type) {
            case RecurrenceType.daily: return translate.instant("recurrenceDescription.multiDaily", interpolationParams);
            case RecurrenceType.weekly: return translate.instant("recurrenceDescription.multiWeek", interpolationParams);
            case RecurrenceType.monthlyWeekDay: return translate.instant("recurrenceDescription.multiMonth", interpolationParams);
        }
    }
    return "";
};

export const getUpdateScheduleDescription = (translate: TranslateService, schedule: UpdateScheduleDto): string => {
    if (schedule.type !== UpdateScheduleType.custom) {
        const key = getUpdateScheduleTypeNameKey(schedule.type ?? UpdateScheduleType.everyPeriod);
        return key && translate.instant(key);
    }
    if (!schedule.recurrence) return "";
    return getRecurrenceDescription(translate, schedule.recurrence);
};

export const getPartnerTypeNameKey = (type: PartnerType): string => {
    switch (type) {
        case PartnerType.individual: return "partnerType.individual";
        case PartnerType.firm: return "partnerType.firm";
        case PartnerType.education: return "partnerType.education";
        case PartnerType.lab: return "partnerType.lab";
    }
    return "";
};

export const getPartnerStatusNameKey = (status: PartnerStatus): string => {
    switch (status) {
        case PartnerStatus.silver: return "partnerStatus.silver";
        case PartnerStatus.gold: return "partnerStatus.gold";
        case PartnerStatus.platinum: return "partnerStatus.platinum";
    }
    return "";
};

export const getSubscriptionProductTypeNameKey = (type: SubscriptionProductType): string => {
    switch (type) {
        case SubscriptionProductType.team: return "subscriptionProductType.team";
        case SubscriptionProductType.user: return "subscriptionProductType.user";
        case SubscriptionProductType.partner: return "subscriptionProductType.partner";
    }
    return "";
};

export const getSubscriptionTypeNameKey = (type: SubscriptionType): string => {
    switch (type) {
        case SubscriptionType.perTeam: return "subscriptionType.perTeam";
        case SubscriptionType.perUser: return "subscriptionType.perUser";
    }
    return "";
};

export const getSolutionApprovalActionTypeNameKey = (history: SolutionApprovalActionType): string => {
    switch (history) {
        case SolutionApprovalActionType.referredTo: return "solutionApprovalActionType.referredTo";
        case SolutionApprovalActionType.approvedBy: return "solutionApprovalActionType.approvedBy";
        case SolutionApprovalActionType.rejectedBy: return "solutionApprovalActionType.rejectedBy";
        case SolutionApprovalActionType.notedBy: return "solutionApprovalActionType.notedBy";
    }
    return "";
};

export const getOriginNameKey = (type?: EntityType, isAucbg: boolean = false): string => {
    switch (type) {
        case EntityType.goal: return isAucbg ? "origin.target" : "origin.goal";
        case EntityType.action: return "origin.action";
        case EntityType.number: return "origin.number";
        case EntityType.solution: return "origin.solution";
        case EntityType.news: return "origin.news";
        case EntityType.weeklyReport: return "origin.weeklyReport";
        case EntityType.discussion: return "origin.discussion";
        case EntityType.report: return "origin.report";
    }
    return "";
};

export const getEntityTypeNameKey = (type?: EntityType, isAucbg: boolean = false): string => {
    switch (type) {
        case EntityType.goal: return isAucbg ? "entityType.target" : "entityType.goal";
        case EntityType.action: return "entityType.action";
        case EntityType.number: return "entityType.number";
        case EntityType.solution: return "entityType.solution";
        case EntityType.news: return "entityType.news";
        case EntityType.discussion: return "entityType.discussion";
        case EntityType.weeklyReport:
        case EntityType.report: return "entityType.report";
    }
    return "";
};

export const getGoalStatusNameKey = (status: GoalStatus) => {
    switch (status) {
        case GoalStatus.onTarget: return "goalStatus.onTarget";
        case GoalStatus.offTarget: return "goalStatus.offTarget";
        case GoalStatus.complete: return "goalStatus.complete";
        case GoalStatus.updateRequired: return "goalStatus.updateRequired";
        case GoalStatus.cancelled: return "goalStatus.cancelled";
    }
    return "";
};

export const getNumberTargetTypeNameKey = (type: NumberTargetType): string => {
    switch (type) {
        case NumberTargetType.aboveTarget: return "numberTargetType.aboveTarget";
        case NumberTargetType.belowTarget: return "numberTargetType.belowTarget";
        case NumberTargetType.withinRange: return "numberTargetType.withinRange";
    }
    return "";
};

export const getPlanningStatusNameKey = (status: PlanningStatus): string => {
    switch (status) {
        case PlanningStatus.draft: return "planningStatus.draft";
        case PlanningStatus.locked: return "planningStatus.locked";
    }
    return "";
};

export const getNumberTypeNameKey = (type: NumberType): string => {
    switch (type) {
        case NumberType.normal: return "numberType.normal";
        case NumberType.currency: return "numberType.currency";
        case NumberType.percentage: return "numberType.percentage";
    }
    return "";
};

export const getNumberEntryTypeNameKey = (type: NumberEntryType): string => {
    switch (type) {
        case NumberEntryType.deltas: return "numberEntryType.deltas";
        case NumberEntryType.totals: return "numberEntryType.totals";
    }
    return "";
};

export const getCaptureMethodNameKey = (method: CaptureMethod): string => {
    switch (method) {
        case CaptureMethod.manual: return "captureMethod.manual";
        case CaptureMethod.automatic: return "captureMethod.automatic";
        case CaptureMethod.calculated: return "captureMethod.calculated";
        case CaptureMethod.deployed: return "captureMethod.deployed";
    }
    return "";
};

export const getPaymentMethodNameKey = (paymentMethod: PaymentMethod): string => {
    switch (paymentMethod) {
        case PaymentMethod.free:
            return "paymentMethod.free";
        case PaymentMethod.invoiced:
            return "paymentMethod.paidInvoiced";
        case PaymentMethod.stripe:
            return "paymentMethod.paidStripe";
        case PaymentMethod.stripeCustom:
            return "paymentMethod.paidStripeCustom";
        case PaymentMethod.appSumo:
            return "paymentMethod.paidAppSumo";
        default:
            return "";
    }
};

export const getOnboardingStatusNameKey = (status: OnboardingStatus): string => {
    switch (status) {
        case OnboardingStatus.notStarted: return "onboardingStatus.notStarted";
        case OnboardingStatus.paymentRequired: return "onboardingStatus.paymentRequired";
        case OnboardingStatus.complete: return "onboardingStatus.complete";
        default: return "";
    }
};

export const getClientStatusNameKey = (status: ClientStatus): string => {
    switch (status) {
        case ClientStatus.active: return "clientStatus.active";
        case ClientStatus.unpaid: return "clientStatus.unpaid";
        case ClientStatus.cancelled: return "clientStatus.cancelled";
        case ClientStatus.suspended: return "clientStatus.suspended";
        default: return "";
    }
};

export const getSubscriptionStatusNameKey = (status: SubscriptionStatus | null | undefined): string => {
    switch (status) {
        case SubscriptionStatus.active:
        case SubscriptionStatus.activeTrial: return "subscriptionStatus.active";
        case SubscriptionStatus.incomplete:
        case SubscriptionStatus.pastDue: return "subscriptionStatus.pastDue";
        case SubscriptionStatus.unpaid: return "subscriptionStatus.unpaid";
        case SubscriptionStatus.incompleteExpired:
        case SubscriptionStatus.cancelled: return "subscriptionStatus.cancelled";
        case null:
        case undefined:
        default:
            return "subscriptionStatus.none";
    }
};

export const getClientInstanceSourceNameKey = (source: ClientInstanceSource): string => {
    switch (source) {
        case ClientInstanceSource.manual: return "clientInstanceSource.manual";
        case ClientInstanceSource.selfRegistration: return "clientInstanceSource.selfRegistration";
        default: return "";
    }
};

export const getRoleNameKey = (role: Role): string => {
    switch (role) {
        case Role.superAdmin: return "role.superAdmin";
        case Role.enterpriseAdmin: return "role.enterpriseAdmin";
        case Role.admin: return "role.admin";
        case Role.partner: return "role.partner";
        case Role.partnerAdmin: return "role.partnerAdmin";
        case Role.advisor: return "role.advisor";
        case Role.user: return "role.user";
        case Role.associate: return "role.associate";
    }
    return "";
};

export const getPriorityNameKey = (method?: Priority): string => {
    switch (method) {
        case Priority.low: return "priority.low";
        case Priority.medium: return "priority.medium";
        case Priority.high: return "priority.high";
        default: return "";
    }
};

export const getPlanName = (translate: TranslateService, plan: SimplePlanTierDto) =>
    plan.nameLocKey ? translate.instant(plan.nameLocKey) : plan.name;

export const actionProgressNameKey = (progress?: ActionProgress) => {
    switch (progress) {
        case ActionProgress.notStarted: return "actionProgress.notStarted";
        case ActionProgress.inProgress: return "actionProgress.inProgress";
        case ActionProgress.complete: return "actionProgress.complete";
        case ActionProgress.overdue: return "actionProgress.overdue";
        case ActionProgress.cancelled: return "actionProgress.cancelled";
        default: return "";
    }
};

export const approvalTypeNameKey = (approvalType?: ApprovalType) => {
    switch (approvalType) {
        case ApprovalType.referred: return "approval.referred";
        case ApprovalType.approved: return "approval.approved";
        case ApprovalType.rejected: return "approval.rejected";
        case ApprovalType.noted: return "approval.noted";
        default: return "";
    }
};

export const getDiscussionStatusNameKey = (status?: DiscussionStatus) => {
    switch (status) {
        case DiscussionStatus.new: return "discussionStatus.new";
        case DiscussionStatus.resolved: return "discussionStatus.resolved";
        case DiscussionStatus.referred: return "discussionStatus.referred";
        case DiscussionStatus.approved: return "discussionStatus.approved";
        case DiscussionStatus.rejected: return "discussionStatus.rejected";
        default: return "";
    }
};

export const getDiscussionTypeNameKey = (type?: DiscussionType) => {
    switch (type) {
        case DiscussionType.discussion: return "discussionType.discussion";
        case DiscussionType.challenge: return "discussionType.challenge";
        case DiscussionType.opportunity: return "discussionType.opportunity";
        default: return "";
    }
};

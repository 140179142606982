import { Component, EventEmitter, Input, Output } from "@angular/core";
import { SimpleCompanyDto, SimpleTeamDto } from "@api";
import { FeedPartitionDto } from "@api/model/feedPartitionDto";
import * as moment from "moment";
import { BehaviorSubject, map, Observable, of, switchMap } from "rxjs";

import { AccessService } from "~services/access.service";
import { FeedStateService } from "~services/feed-state.service";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";

export declare type MapToTeamCallback<TEntity> = (entity: TEntity) => { company: SimpleCompanyDto; team: SimpleTeamDto };

@Component({
    selector: "app-feed-button",
    templateUrl: "./feed-button.component.html",
    styleUrls: ["./feed-button.component.scss"],
})
export class FeedButtonComponent<TEntity> extends WithDestroy() {
    get reference(): FeedPartitionDto | undefined {
        return this.referenceSubject.value;
    }

    @Input() set reference(value: FeedPartitionDto | undefined) {
        this.referenceSubject.next(value);
    }

    get entity(): TEntity | undefined {
        return this.entitySubject.value;
    }

    @Input() set entity(value: TEntity | undefined) {
        this.entitySubject.next(value);
    }

    @Input() mapEntityToTeam: MapToTeamCallback<TEntity> | null = null;

    get hasItems(): boolean {
        return !!this.reference && !!this.reference.lastActivityDate;
    }

    @Output() openFeed = new EventEmitter();

    readonly hasAccess$: Observable<boolean>;
    readonly hasUnreadItems$: Observable<boolean>;

    private readonly entitySubject = new BehaviorSubject<TEntity | undefined>(undefined);
    private readonly referenceSubject = new BehaviorSubject<FeedPartitionDto | undefined>(undefined);

    constructor(
        private readonly feedStateService: FeedStateService,
        private readonly accessService: AccessService,
    ) {
        super();

        this.hasAccess$ = this.entitySubject.pipe(
            switchMap(entity => {
                // If we aren't supplied information to do an access check, skip the access check.
                if (!entity || !this.mapEntityToTeam) return of(true);
                const { company, team } = this.mapEntityToTeam(entity);
                return this.accessService.canAccessCompanyTeam(company.id, team.id);
            }),
            shareReplayUntil(this.destroyed$)
        );

        this.hasUnreadItems$ = this.hasAccess$.pipe(
            switchMap(hasAccess => {
                if (!hasAccess) return of(false);
                return this.referenceSubject.pipe(
                    switchMap(reference => {
                        if (!reference || !reference.feedId || !reference.clientId) return of(false);
                        if (!reference.lastActivityDate) return of(false);
                        return this.feedStateService.getUserState(reference).pipe(
                            map(state => {
                                if (!state.lastViewed) return true;
                                return moment(reference.lastActivityDate) > state.lastViewed;
                            })
                        );
                    }),
                );
            }),
            shareReplayUntil(this.destroyed$),
        );
    }
}

import { AbstractControl, FormControl, FormGroup, ValidatorFn } from "@angular/forms";
import { NumberTargetDto, NumberTargetType } from "@api";
import { merge, Subscription } from "rxjs";

import { decimalValidator } from "~shared/util/custom-validators";
import { setEnabledState } from "~shared/util/form-helper";

import { hasLowerTarget, hasUpperTarget, sanitiseTarget } from "./target-helper";

declare type TargetControl = FormControl<number | null>;

export declare type WeekTargetForm = FormGroup<{ upper: TargetControl; lower: TargetControl }>;

export declare type WeekTargetsForm = FormGroup<{ [week: string]: WeekTargetForm }>;

export declare interface SimpleTargetFormControls {
    targetLowerBound: TargetControl;
    targetUpperBound: TargetControl;
}

export declare interface TargetFormControls {
    targetLowerBound: TargetControl;
    targetUpperBound: TargetControl;
    weekTargets: WeekTargetsForm;
}

export const weekGreaterThan = (weekLowerTarget: TargetControl, upperTarget: TargetControl, lowerTarget: TargetControl):
    ValidatorFn =>
    (weekUpperTarget: AbstractControl) => {
        if (weekLowerTarget.disabled) return null;
        if ((weekUpperTarget.value === null || weekUpperTarget.value === undefined) &&
            (weekLowerTarget.value === null || weekLowerTarget.value === undefined)) {
            return null;
        }

        const upper = weekUpperTarget.value ?? upperTarget.value ?? null;
        const lower = weekLowerTarget.value ?? lowerTarget.value ?? null;

        if (upper === null || lower === null || upper >= lower) return null;
        return { greaterThan: true };
    };

export const forAllWeeks = (form: WeekTargetsForm, callback: (weekStr: string, child: WeekTargetForm) => void) => {
    for (const weekStr in form.controls) {
        if (!Object.prototype.hasOwnProperty.call(form.controls, weekStr)) continue;
        callback(weekStr, form.controls[weekStr]);
    }
};

const buildWeekForm = (
    targetForm: TargetFormControls,
    value?: NumberTargetDto,
): [WeekTargetForm, Subscription] => {
    const lowerControl = new FormControl({ value: value?.lowerBound ?? null, disabled: targetForm.targetLowerBound.disabled }, [
        decimalValidator,
    ]);
    const upperControl = new FormControl({ value: value?.upperBound ?? null, disabled: targetForm.targetUpperBound.disabled }, [
        decimalValidator,
        weekGreaterThan(lowerControl, targetForm.targetUpperBound, targetForm.targetLowerBound),
    ]);
    const group = new FormGroup({
        lower: lowerControl,
        upper: upperControl,
    });
    // When any of the target values change, we need to re-validate the upper bound
    const sub = merge(
        lowerControl.valueChanges,
        targetForm.targetLowerBound.valueChanges,
        targetForm.targetUpperBound.valueChanges,
    ).subscribe(() => upperControl.updateValueAndValidity());
    return [group, sub];
};

export const bindWeeklyTargets = (
    weeks: number[],
    targetControls: TargetFormControls,
    subscription: Subscription,
    targets?: { [week: string]: NumberTargetDto }) => {
    for (const week of weeks) {
        const weekStr = week.toString();
        if (weekStr in targetControls.weekTargets.controls) continue;
        const [weekForm, weekSub] = buildWeekForm(targetControls, targets?.[weekStr]);
        targetControls.weekTargets.addControl(weekStr, weekForm);
        subscription.add(weekSub);
    }
};

export const revalidateUpperTargets = (
    targetControls: TargetFormControls | SimpleTargetFormControls,
) => {
    targetControls.targetUpperBound.updateValueAndValidity();
    if (!("weekTargets" in targetControls)) return;
    forAllWeeks(targetControls.weekTargets, (_, weekForm) => {
        weekForm.controls.upper.updateValueAndValidity();
    });
};

export const updateTargetDisabledState = (
    targetType: NumberTargetType,
    targets: TargetFormControls | SimpleTargetFormControls,
) => {
    const hasLower = hasLowerTarget(targetType);
    const hasUpper = hasUpperTarget(targetType);
    setEnabledState(targets.targetUpperBound, hasUpper);
    setEnabledState(targets.targetLowerBound, hasLower);
    if (!("weekTargets" in targets)) return;
    forAllWeeks(targets.weekTargets, (_, weekForm) => {
        setEnabledState(weekForm.controls.upper, hasUpper);
        setEnabledState(weekForm.controls.lower, hasLower);
    });
};

export const getWeekTargetsValue = (
    weeks: number[],
    targetType: NumberTargetType,
    weekTargets: WeekTargetsForm,
): { [week: string]: NumberTargetDto } =>
    weeks.reduce((targets, week) => {
        const weekString = week.toString();
        const weekForm = weekTargets.controls[weekString];
        targets[weekString] = sanitiseTarget(targetType, {
            lowerBound: weekForm.controls["lower"].value ?? undefined,
            upperBound: weekForm.controls["upper"].value ?? undefined,
        });
        return targets;
    }, {} as { [week: string]: NumberTargetDto });

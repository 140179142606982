import {
    GetActionDto, GetGoalDto, GetNumberDto, GetReportDto, NumberRecordDetailDto
} from "@api";

import { IActionInputModel } from "~shared/dialogs/edit-action-dialog/edit-action-dialog.component";

import { GenericItem } from "./input-builder-core";

export const mapGenericActionInput = (item: GenericItem): IActionInputModel => ({
    departmentId: item.department?.id,
    categoryId: item.category?.id,
    subCategoryId: item.category?.subCategory?.id,
});

export const mapGoalActionInput = (goal: GetGoalDto): IActionInputModel => mapGenericActionInput(goal);

export const mapNumberActionInput = (number: GetNumberDto | NumberRecordDetailDto): IActionInputModel => mapGenericActionInput(number);

export const mapReportActionInput = (report: GetReportDto): IActionInputModel => mapGenericActionInput(report);

export const mapActionActionInput = (action: GetActionDto): IActionInputModel => mapGenericActionInput(action);

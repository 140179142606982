import { Directive, EventEmitter, Input, Output } from "@angular/core";
import { GetFeedItemDto } from "@api";
import { BehaviorSubject } from "rxjs";

import { FeedPartitionContext } from "~feed/services";

@Directive()
export abstract class BaseFeedListItemDirective<T extends GetFeedItemDto> {
    /**
     * The context that the user is currently viewing, used to generate a relative context (without superfluous information).
     */
    @Input() viewContext?: FeedPartitionContext;

    @Input() set feedItem(value: T) {
        this.feedItemInternal = value;
        this.feedItemSubject.next(value);
    }

    get feedItem(): T {
        return this.feedItemInternal;
    }

    @Output() readonly changeContext = new EventEmitter<FeedPartitionContext>();

    private feedItemInternal!: T;
    protected readonly feedItemSubject = new BehaviorSubject<T | null>(null);
}

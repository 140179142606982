<mat-chip-set>
    <mat-chip color="primary" highlighted [matMenuTriggerFor]="periodContexts"
        [matTooltip]="'FeedsList.tooltips.changeContext' | translate">
        {{ contextToDescription(selectedContext) }}
        <mat-icon>arrow_drop_down</mat-icon>
    </mat-chip>
</mat-chip-set>

<mat-menu #periodContexts="matMenu" xPosition="before">
    <button *ngFor="let selectableContext of selectableContexts" mat-menu-item type="button" (click)="setSelectedContext(selectableContext)">
        {{ contextToDescription(selectableContext) }}
    </button>
</mat-menu>
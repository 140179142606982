import { AbstractControl, FormArray, FormControl, FormGroup } from "@angular/forms";
import { CurrentCompanyDto } from "@api";
import * as moment from "moment";
import { defer, merge, of } from "rxjs";

import { DayOfWeek } from "~shared/enums";

import { isCompanyLogoTeased } from "./feature-helper";

export const getDefaultCompanyLogoUrl = (): string => "assets/images/companydefault.png";
export const getTeasedCompanyLogoUrl = (): string => "assets/images/companyteased.png";
export const getPromptCompanyLogoUrl = (): string => "assets/images/companyprompt.png";

export const getCompanyLogoUrl = (company: CurrentCompanyDto | null | undefined) => {
    if (isCompanyLogoTeased(company)) {
        return getTeasedCompanyLogoUrl();
    }
    return company?.logoUrl || getPromptCompanyLogoUrl();
};

export const getDarkModeCompanyLogoUrl = (company: CurrentCompanyDto | null | undefined) => {
    if (isCompanyLogoTeased(company)) {
        return getTeasedCompanyLogoUrl();
    }
    return company?.darkModeLogoUrl || company?.logoUrl || getPromptCompanyLogoUrl();
};

/**
 * Revalidates a form and all descendent controls.
 * Useful if there are validation rules dependent on multiple fields.
 *
 * @param control The root control
 */
export const recursivelyUpdateValueAndValidity = (control: AbstractControl) => {
    if (control instanceof FormGroup) {
        const group = control as FormGroup;
        for (const field in group.controls) {
            if (!Object.prototype.hasOwnProperty.call(group.controls, field)) continue;
            recursivelyUpdateValueAndValidity(group.controls[field]);
        }
    } else if (control instanceof FormArray) {
        const group = control as FormArray;
        for (const c of group.controls) {
            recursivelyUpdateValueAndValidity(c);
        }
    }

    control.updateValueAndValidity();
};

export const valueAndChanges = <TValue>(control: AbstractControl<TValue> | FormControl<TValue>) =>
    defer(() => merge(of(control.value), control.valueChanges));

export const statusAndChanges = (control: AbstractControl) => defer(() => merge(of(control.status), control.statusChanges));

/**
 * Calculates the current day of the week in the user's local time.
 *
 * @returns The current day of the week
 *
 * Note: The moment.isoWeekday function returns 1 = Monday and 7 = Sunday, so taking the modulo of 7
   gives the correct Sunday = 0, Saturday = 6.
   The moment.weekday function returns different values depending on user locale, so is unsuitable.
 */
export const getCurrentDayOfWeek = () => (moment().isoWeekday() % 7) as DayOfWeek;

import { DelegationResponsibility, GetActionDto, GetGoalDto, GetNumberDto, GetReportDto } from "@api";

export const defaultActionsFilterPredicate = (data: GetActionDto, ownerId: string) => !ownerId ||
    (!data.isDelegated && data.owner?.userId === ownerId) ||
    (data.isDelegated && data.delegation?.assignee?.userId === ownerId);

export const defaultGoalsFilterPredicate = (data: GetGoalDto, ownerId: string) => !ownerId ||
    (!data.isDelegated && data.owner?.userId === ownerId) ||
    (data.isDelegated && data.delegation?.assignee?.userId === ownerId);

export const defaultNumbersFilterPredicate = (data: GetNumberDto, ownerId: string) => {
    if (!ownerId) return true;
    const isOwnerDelegated = data.isDelegated && !!data.delegation &&
        data.delegation.responsibility === DelegationResponsibility.ownership;
    return (!isOwnerDelegated && data.owner?.userId === ownerId) ||
        (isOwnerDelegated && data.delegation?.assignee?.userId === ownerId);
};

export const defaultReportsFilterPredicate = (data: GetReportDto, ownerId: string) => {
    if (!ownerId) return true;
    const isOwnerDelegated = data.isDelegated && !!data.delegation &&
        data.delegation.responsibility === DelegationResponsibility.ownership;
    return (!isOwnerDelegated && data.owner?.userId === ownerId) ||
        (isOwnerDelegated && data.delegation?.assignee?.userId === ownerId);
};

/* eslint-disable max-classes-per-file */
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

import { AttachmentDto, ReportRecordDetailDto } from "~/api";
import { ReportCollectionType } from "~shared/enums";
import { getExtension } from "~shared/util/attachments";

const getIconName = (extension: string | undefined): string => {
    switch (extension) {
        case "pdf":
            return "fa-file-pdf";
        case "docx":
        case "docm":
        case "doc":
            return "fa-file-word";
        case "xlsx":
        case "xlsm":
        case "xls":
            return "fa-file-excel";
        case "pptx":
        case "pptm":
        case "ppt":
            return "fa-file-powerpoint";
        default:
            // Technically should not happen.
            return "fa-file";
    }
};

const getLinkName = (link: string): string => {
    try {
        const url = new URL(link);
        const filePart = url.pathname.split("/").filter(x => x).pop();
        if (filePart) return decodeURIComponent(filePart);
        return url.hostname;
    } catch (e) {
        // If we can't parse the URL, just return the entire link.
        return link;
    }
};

interface LinkDetails {
    link: string;
    name: string;
}

const toLinkDetails = (link: string): LinkDetails => ({
    link,
    name: getLinkName(link)
});

export abstract class BaseAttachmentLinksComponent {
    abstract readonly attachments: AttachmentDto[];

    get linkDetails(): LinkDetails[] {
        return this._linkDetails;
    }

    private _linkDetails: LinkDetails[] = [];

    getAttachmentIconName = (attachment: AttachmentDto): string => {
        const extension = getExtension(attachment.path);
        return getIconName(extension);
    };

    getLinkIconName = (link: LinkDetails): string => {
        const extension = getExtension(link.name);
        return getIconName(extension);
    };

    protected setLinkDetails = (links: string[]) =>
        this._linkDetails = links.map(toLinkDetails);
}

@Component({
    selector: "app-attachment-links",
    templateUrl: "./attachment-links.component.html",
    styleUrls: ["./attachment-links.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentLinksComponent extends BaseAttachmentLinksComponent {

    @Input() attachments: AttachmentDto[] = [];

    @Input() set links(value: string[]) {
        this._links = value;
        this.setLinkDetails(value);
    }

    get links(): string[] {
        return this._links;
    }

    private _links: string[] = [];
}

@Component({
    selector: "app-report-attachment-links",
    templateUrl: "./attachment-links.component.html",
    styleUrls: ["./attachment-links.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportAttachmentLinksComponent extends BaseAttachmentLinksComponent {

    @Input({ required: true }) set report(value: ReportRecordDetailDto | null) {
        this._report = value;
        this.attachments = value?.reports ?? [];
        if (!value) {
            this.setLinkDetails([]);
        } else {
            this.setLinkDetails(
                value.collectionType === ReportCollectionType.externallyUpdated
                    ? [...value.permanentLinks ?? [], ...value.links ?? []]
                    : value.links);
        }
    }

    get report(): ReportRecordDetailDto | null {
        return this._report;
    }

    attachments: AttachmentDto[] = [];

    private _report: ReportRecordDetailDto | null = null;
}

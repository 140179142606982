import { FormControl } from "@angular/forms";
import { ScopedTeamWatchlistDto, ScopedUserWatchlistDto } from "@api";
import { Observable, switchMap, tap } from "rxjs";

export declare type ScopedWatchlistDto = ScopedUserWatchlistDto | ScopedTeamWatchlistDto;

export const isScopedUserList = (list: ScopedWatchlistDto): list is ScopedUserWatchlistDto => "clientId" in list && "user" in list;
export const isScopedTeamList = (list: ScopedWatchlistDto): list is ScopedTeamWatchlistDto => "company" in list && "team" in list;

export interface WatchlistForm<TList extends ScopedWatchlistDto = ScopedWatchlistDto> {
    list: TList;
    watchedControl: FormControl<boolean>;
}

export const buildWatchlistForm = <TList extends ScopedWatchlistDto = ScopedWatchlistDto>(
    list: TList): WatchlistForm<TList> => ({
        list,
        watchedControl: new FormControl(list.watched, { nonNullable: true }),
    });

export const buildWatchlistForms = <TList extends ScopedWatchlistDto = ScopedWatchlistDto>(
    lists: TList[]): WatchlistForm<TList>[] => lists.map(buildWatchlistForm);

export const subscribeToWatchedState = <TList extends ScopedWatchlistDto = ScopedWatchlistDto>(
    lists: WatchlistForm<TList>[], setWatched: (list: TList, watched: boolean) => Observable<TList>):
    Observable<WatchlistForm<TList>[]> => new Observable(subscriber => {
        for (const form of lists) {
            subscriber.add(form.watchedControl.valueChanges.pipe(
                switchMap(watched => setWatched(form.list, watched).pipe(
                    tap(list => {
                        form.list = list;
                        form.watchedControl.reset(list.watched, { emitEvent: false });
                        subscriber.next(lists);
                    }),
                )),
            ).subscribe());
        }
        subscriber.next(lists);
    });

import { Profile, Role } from "../enums";

export const getProfileRoles = (profile: Profile): Role[] => {
    switch (profile) {
        case Profile.superAdmin:
            return [Role.superAdmin];
        case Profile.enterpriseAdmin:
            return [Role.superAdmin, Role.enterpriseAdmin];
        case Profile.billingAdmin:
            return [Role.superAdmin, Role.enterpriseAdmin];
        case Profile.humanResources:
            return [Role.superAdmin, Role.enterpriseAdmin];
        case Profile.partnerAdmin:
            return [Role.superAdmin, Role.partnerAdmin];
        case Profile.partnerManager:
            return [Role.superAdmin, Role.partnerAdmin, Role.enterpriseAdmin];
        case Profile.partner:
            return [Role.superAdmin, Role.partnerAdmin, Role.partner];
        case Profile.admin:
            return [Role.superAdmin, Role.enterpriseAdmin, Role.admin, Role.partner, Role.partnerAdmin];
        case Profile.fullUser:
            return [Role.superAdmin, Role.enterpriseAdmin, Role.partnerAdmin, Role.partner, Role.admin, Role.user, Role.advisor];
        default:
            return [];
    }
};

import { Injectable } from "@angular/core";
import { CompanyTeamsMenuDto, UserApi } from "@api";
import { BehaviorSubject, exhaustMap, Observable, share } from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { retryWithDelay } from "~shared/util/caching";
import { shareReplayUntil } from "~shared/util/rx-operators";

@Injectable({
    providedIn: "root"
})
export class CompanyMenuRepository extends WithDestroy() {

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);
    private readonly menuItems$: Observable<CompanyTeamsMenuDto[]>;
    private readonly menuItemsReplayed$: Observable<CompanyTeamsMenuDto[]>;

    constructor(
        private readonly userApi: UserApi,
    ) {
        super();
        this.menuItems$ = this.refreshSubject.pipe(
            exhaustMap(() => this.userApi.listAccessibleTeams()),
            retryWithDelay(),
            share(),
        );
        this.menuItemsReplayed$ = this.menuItems$.pipe(shareReplayUntil(this.destroyed$));
    }

    getMenuItems = (refresh = false) => {
        if (refresh) {
            this.refresh();
            return this.menuItems$;
        }
        return this.menuItemsReplayed$;
    };

    refresh = () => this.refreshSubject.next();
}

/* eslint-disable max-classes-per-file */
import { NewsItemFeedApi, NewsItemFeedContext } from "@api";

import { GenericFeedAdapter } from "./feed.adapter";
import { FeedReference } from "./feed.common";

type NewsItemScope = readonly [companyId: string, teamId: string, newsItemId: string];

export class NewsItemFeedAdapter extends GenericFeedAdapter<NewsItemScope> {

    protected get scope() {
        return [
            this.reference.context.companyId,
            this.reference.context.teamId,
            this.reference.context.newsItemId,
        ] as const;
    }

    constructor(
        readonly reference: Readonly<FeedReference<NewsItemFeedContext>>,
        api: NewsItemFeedApi,
    ) {
        super(api);
    }
}

@if (list(); as list) {
    @if (list.sharingContext) {
        <mat-icon class="sharing-indicator"
            [wfTemplateTooltip]="tooltip"
            matTooltipClass="light-tooltip" matTooltipPosition="after"
            [attr.aria-label]="'watchlists.shared' | translate">
            people
        </mat-icon>
        <ng-template #tooltip>
            <div class="tooltip-body">
                <div wf-translate-template="watchlists.sharedBy">
                    <span *wfTransTplContent="'owner'" class="owner-name">
                        @if (isUserList(list)) {
                            {{ getUserName(list.user) }}
                        } @else {
                            {{ list.team.name }}
                            <span class="company-name">({{ list.company.name }})</span>
                        }
                    </span>
                </div>
                @if (isTeamList(list) && (shouldShowLink$ | async)) {
                    <a [routerLink]="['/company', list.company.id, 'team', list.team.id, 'watchlists', 'team', list.id]"
                        translate="watchlists.viewOriginal"></a>
                }
            </div>
        </ng-template>
    }
}
<table mat-table class="wf-table" [dataSource]="dataSource" matSort [matSortActive]="defaultSort ?? ''" matSortDirection="asc"
    [matSortDisableClear]="!!defaultSort" [trackBy]="trackByIdAndWeek" multiTemplateDataRows wfTableFocus>
    <ng-container matColumnDef="expand">
        <th mat-header-cell *matHeaderCellDef>
            <wf-expand-button [expanded]="canExpandAll && areAllExpanded"
                (expand)="expandAll()" (collapse)="collapseAll()"
                [disabled]="!canExpandAll"
                [expandTooltip]="'expandAll' | translate"
                [collapseTooltip]="'collapseAll' | translate">
            </wf-expand-button>
        </th>
        <td mat-cell *matCellDef="let element">
            <wf-expand-button *ngIf="element.actionsCount" [expanded]="isExpanded(element)"
                (expand)="expand(element)" (collapse)="collapse(element)"
                [expandTooltip]="'expandItem' | translate: { item: element.heading }"
                [collapseTooltip]="'collapseItem' | translate: { item: element.heading }">
            </wf-expand-button>
        </td>
    </ng-container>
    <ng-container matColumnDef="actionsCount">
        <th mat-header-cell *matHeaderCellDef>
            {{'goals.actionsCount' | translate}}</th>
        <td mat-cell *matCellDef="let element">
            <wf-child-count [count]="element.actionsCount"></wf-child-count>
        </td>
    </ng-container>
    <ng-container matColumnDef="heading">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'goals.heading' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <a class="multiline-description" (click)="viewGoal(element)" wfEmptyLink>
                {{element.heading}}
            </a>
            <app-notes-callout *ngIf="element.notes" [notes]="element.notes"></app-notes-callout>
        </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'goals.description' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="multiline-description">
                {{element.description}}
            </div>
        </td>
    </ng-container>
    <ng-container matColumnDef="week">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'period.week' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ 'period.weekDisplayShort' | translate: { financialYear: element.financialYear % 100, quarter: element.planningPeriod, week: element.week } }}
        </td>
    </ng-container>
    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef>
            <app-table-user-filter (selected)="applyOwnerFilter($event)" withSortHeader>
                {{ 'goals.owner' | translate }}
            </app-table-user-filter>
        </th>
        <td mat-cell *matCellDef="let element">
            <app-delegated-user-display [entity]="element">
                {{ getUserName(element.owner) }}
                <wf-private-indicator *ngIf="element.isPrivate"></wf-private-indicator>
            </app-delegated-user-display>
        </td>
    </ng-container>
    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'goals.team' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-team-display [team]="element.team" [company]="element.company"></app-team-display>
        </td>
    </ng-container>
    <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'goals.dueDate' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.dueDateLocal | dateFormat}}
        </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header [class.mat-column-status-display]="readonly">
            {{'goals.status' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" [class.mat-column-status-display]="readonly">
            <app-goal-status-update *ngIf="!readonly" [goal]="element" (updated)="goalUpdated(element)" [disabled]="disabled">
            </app-goal-status-update>
            <app-goal-status *ngIf="readonly" [status]="element.customStatus ?? element.status"></app-goal-status>
        </td>
    </ng-container>
    <ng-container matColumnDef="statusSummary">
        <th mat-header-cell *matHeaderCellDef>
            {{'goals.statusSummary' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-goal-status-summary [goal]="element"></app-goal-status-summary>
        </td>
    </ng-container>

    <ng-container matColumnDef="department">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'goals.department' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.department?.name}}
        </td>
    </ng-container>

    <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'goals.category' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.category?.description}}
        </td>
    </ng-container>

    <ng-container matColumnDef="subCategory">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'goals.subCategory' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{element.category?.subCategory?.description}}
        </td>
    </ng-container>

    <ng-container matColumnDef="actionDiscuss">
        <th mat-header-cell *matHeaderCellDef>
            {{'actionDiscuss' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-add-goal-child-action-button [source]="element"
                [disabled]="disabled" (actionAdded)="actionUpdated(element)">
            </app-add-goal-child-action-button>
            <app-add-goal-child-discussion-button [source]="element"
                [disabled]="disabled" (discussionAdded)="discussionAdded(element)">
            </app-add-goal-child-discussion-button>
        </td>
    </ng-container>
    <ng-container matColumnDef="options">
        <th mat-header-cell *matHeaderCellDef>
            <ng-content select="app-table-settings-menu"></ng-content>
        </th>
        <td mat-cell *matCellDef="let element">
            <div class="button-holder">
                <app-feed-button (openFeed)="openFeed(element)" [reference]="element.feedPartition">
                </app-feed-button>

                <ng-container *ngTemplateOutlet="extraOptionsTemplate; context: { $implicit: element }"></ng-container>
            </div>
        </td>
    </ng-container>

    <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let goalItem" [attr.colspan]="(displayedColumns$ | async)?.length">
            <app-child-actions-table [actions]="getChildActions(goalItem) | async" 
                [disabled]="disabled" [menuType]="fromMeeting ? 'edit' : 'feedOnly'"
                (reloadParentItem)="actionUpdated(goalItem)"
                *ngIf="isExpanded(goalItem) && (getChildActions(goalItem) | async)?.length" @detailExpand>
            </app-child-actions-table>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns$ | async"></tr>
    <tr mat-row *matRowDef="let element; let i = index; columns: displayedColumns$ | async;" [wfRowFocus]="element"
        [wfRowFocusIndex]="i" [class.wf-goal-update-required]="highlightUpdateRequired && !isUpdated(element)"></tr>
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expandable-row"></tr>
    <tr class="mat-mdc-row" *matNoDataRow>
        <td [attr.colspan]="(displayedColumns$ | async)?.length">
            <span class="no-records">
                {{'NoRecordsFound.noRecordsFound!' | translate}}
            </span>
        </td>
    </tr>
</table>
// eslint-disable-next-line max-classes-per-file
import { FocusMonitor, FocusTrapFactory, InteractivityChecker } from "@angular/cdk/a11y";
import { OverlayRef } from "@angular/cdk/overlay";
import { CdkPortalOutlet } from "@angular/cdk/portal";
import { DOCUMENT } from "@angular/common";
import { ANIMATION_MODULE_TYPE, Component, ElementRef, forwardRef, Inject, NgZone, Optional, ViewEncapsulation } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogContainer } from "@angular/material/dialog";

import { WfDialog } from "../dialog";
import { WfDialogCloseButtonComponent } from "../dialog-close-button/dialog-close-button.component";
import { WfDialogConfig } from "../dialog-config";

/**
 * A custom dialog container that includes a close button.
 */
@Component({
    selector: "wf-dialog-container",
    standalone: true,
    imports: [
        WfDialogCloseButtonComponent,
        CdkPortalOutlet,
    ],
    templateUrl: "./dialog-container.component.html",
    styleUrl: "./dialog-container.component.scss",
    encapsulation: ViewEncapsulation.None,
    host: {
        class: "wf-dialog-container"
    },
    providers: [
        {
            provide: MatDialog,
            useExisting: forwardRef(() => WfDialog),
        },
        {
            provide: WfDialogConfig,
            useExisting: MatDialogConfig,
        }
    ],
})
export class WfDialogContainerComponent extends MatDialogContainer {

    readonly _config: WfDialogConfig;

    constructor(
        elementRef: ElementRef,
        focusTrapFactory: FocusTrapFactory,
        @Optional() @Inject(DOCUMENT) _document: any,
        dialogConfig: WfDialogConfig,
        interactivityChecker: InteractivityChecker,
        ngZone: NgZone,
        overlayRef: OverlayRef,
        @Optional() @Inject(ANIMATION_MODULE_TYPE) _animationMode?: string,
        focusMonitor?: FocusMonitor,
    ) {
        super(
            elementRef,
            focusTrapFactory,
            _document,
            dialogConfig,
            interactivityChecker,
            ngZone,
            overlayRef,
            _animationMode,
            focusMonitor,
        );

        this._config = dialogConfig;
    }
}

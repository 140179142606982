import { trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActionsV2Api, DueDateChangeDto, GetActionDetailsDto, GetActionDto } from "@api";
import { BehaviorSubject, catchError, EMPTY, map, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { FeedAdapterBuilder, FeedScope, SimpleFeedScope } from "~feed";
import { ActionStateEvent, ActionStateService } from "~services/state";
import { WithDestroy } from "~shared/mixins";
import { fadeInAnimationBuilder } from "~shared/util/animations";
import { getDelegatedItemCompanyTeam } from "~shared/util/delegation-helper";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { sortString } from "~shared/util/sorters";
import { getUserName } from "~shared/util/user-helper";

import { HomepageScaffoldComponent } from "../homepage-scaffold/homepage-scaffold.component";

declare type ActionDto = GetActionDto | GetActionDetailsDto;

@Component({
    selector: "app-action-homepage",
    templateUrl: "./action-homepage.component.html",
    styleUrls: ["./action-homepage.component.scss"],
    providers: [
        SimpleFeedScope,
        {
            provide: FeedScope,
            useExisting: SimpleFeedScope,
        },
    ],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder()),
    ],
})
export class ActionHomepageComponent extends WithDestroy() implements OnInit, OnDestroy {

    @Input() set action(value: ActionDto | null) {
        this.actionSubject.next(value);
        this.simpleFeedScope.adapter = value ? this.feedAdapterBuilder.buildForAction(value) : null;
    }

    get action(): ActionDto | null {
        return this.actionSubject.value;
    }

    @Output() actionChange = new EventEmitter<ActionDto>();
    @Output() actionDeleted = new EventEmitter<void>();

    @ViewChild(HomepageScaffoldComponent) scaffold?: HomepageScaffoldComponent;

    readonly getUserName = getUserName;

    readonly dueDateHistory$: Observable<DueDateChangeDto[]>;
    isLoadingHistory = true;
    historyHasError = false;
    readonly historyColumns = ["updated", "dueDate"];

    private readonly actionSubject = new BehaviorSubject<ActionDto | null>(null);
    private readonly refreshHistorySubject = new BehaviorSubject<void>(undefined);

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly actionsApi: ActionsV2Api,
        private readonly actionStateService: ActionStateService,
        private readonly simpleFeedScope: SimpleFeedScope,
        private readonly feedAdapterBuilder: FeedAdapterBuilder,
    ) {
        super();

        this.dueDateHistory$ = this.actionSubject.pipe(
            withRefresh(this.refreshHistorySubject),
            tap(() => {
                this.isLoadingHistory = true;
                this.historyHasError = false;
            }),
            switchMap(action => {
                if (!action) return of([]);
                if ("dueDateHistory" in action && action.dueDateHistory) {
                    return of(action.dueDateHistory);
                }
                const { company, team } = getDelegatedItemCompanyTeam(action);
                return this.actionsApi.getActionDueDateHistory(
                    company.id,
                    team.id,
                    action.id,
                ).pipe(
                    catchError(() => {
                        this.historyHasError = true;
                        return of([]);
                    }),
                );
            }),
            map(history => [...history].sort(sortString.descending(h => h.updatedDate))),
            tap(() => this.isLoadingHistory = false),
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnInit(): void {
        this.subscriptions.add(this.actionSubject.pipe(
            switchMap(action => !action ? EMPTY : this.actionStateService.eventsForActions(action)),
        ).subscribe(this.handleStateEvent));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    afterUpdated = (action: ActionDto) => {
        if (this.action === action) {
            // The reference is literally equal.
            // This is likely because the change has already been applied to the action.
            return;
        }
        this.action = action;
        this.actionChange.emit(action);
        this.scaffold?.refreshFeed();
    };

    afterDeleted = () => this.actionDeleted.emit();

    private handleStateEvent = (event: ActionStateEvent) => {
        switch (event.type) {
            case "added": // We should never get an added event, but treat it as if updated for simplicity
            case "updated":
                this.afterUpdated(event.item);
                break;
            case "deleted":
                this.afterDeleted();
                break;
        }
    };
}


import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ActiveToast, ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";

export declare type NotificationType = "success" | "info" | "infowithhtml" | "error" | "warning" |
    "Success" | "Error" | "Warning";

export interface INotification {
    /** Closes the notification */
    close(): void;
    /** Triggered when the notification closes */
    onClose(): Observable<void>;
    /** Triggered when the notification is clicked */
    onClick(): Observable<void>;
}

@Injectable()
export class NotificationService {
    constructor(public toastr: ToastrService, private translate: TranslateService) {
    }

    /**
     * @deprecated Use either success, info, error or warning options
     */
    showStaticNotification(message: string, title: string, type: NotificationType) {
        switch (type.toLowerCase()) {
            case "success":
                this.success(message, title, undefined, true);
                break;
            case "info":
                this.info(message, title, undefined, true);
                break;
            case "infowithhtml":
                this.info(message, title, true, true);
                break;
            case "error":
                this.error(message, title, undefined, true);
                break;
            case "warning":
                this.warning(message, title, undefined, true);
                break;
            default:
        }
    }

    success(message: string, title: string = "Success", enableHtml = false, translate = false): INotification {
        if (translate) {
            message = this.translate.instant(message);
            title = title && this.translate.instant(title);
        } else if (title === "Success") {
            title = this.translate.instant(title);
        }

        const toast = this.toastr.success(message, title, {
            enableHtml: enableHtml
        });
        return this.mapToNotification(toast);
    }

    info(message: string, title: string, enableHtml = false, translate = false): INotification {
        if (translate) {
            message = this.translate.instant(message);
            title = title && this.translate.instant(title);
        }

        const toast = this.toastr.info(message, title, {
            enableHtml: enableHtml
        });
        return this.mapToNotification(toast);
    }

    error(message: string, title: string = "Error", enableHtml = false, translate = false, keepOpen = false): INotification {
        if (translate) {
            message = this.translate.instant(message);
            title = title && this.translate.instant(title);
        } else if (title === "Error") {
            title = this.translate.instant(title);
        }

        const toast = this.toastr.error(message, title, {
            enableHtml: enableHtml,
            disableTimeOut: keepOpen
        });
        return this.mapToNotification(toast);
    }

    errorUnexpected(): INotification {
        return this.error("errors.unexpected", undefined, undefined, true);
    }

    warning(message: string, title: string = "Warning", enableHtml = false, translate = false, keepOpen = false): INotification {
        if (translate) {
            message = this.translate.instant(message);
            title = title && this.translate.instant(title);
        } else if (title === "Warning") {
            title = this.translate.instant(title);
        }

        const toast = this.toastr.warning(message, title, {
            enableHtml: enableHtml,
            disableTimeOut: keepOpen,
            closeButton: !!keepOpen
        });
        return this.mapToNotification(toast);
    }

    private mapToNotification = (toast: ActiveToast<void>): INotification => ({
        close: () => {
            this.toastr.remove(toast.toastId);
        },
        onClose: () => toast.onHidden,
        onClick: () => toast.onTap
    });
}

import { SimpleCompanyDto, SimpleTeamDto } from "@api";

import { sortCompanyTeam } from "./sorters";

export interface TeamGroup<TItem> {
    readonly company: SimpleCompanyDto;
    readonly team: SimpleTeamDto;
    readonly items: TItem[];
}

export interface GroupableItem {
    readonly company: SimpleCompanyDto;
    readonly team: SimpleTeamDto;
}

export const groupItemsByTeam = <TItem extends GroupableItem>(items: TItem[]): TeamGroup<TItem>[] => {
    const teamsGrouped = items.groupBy(x => x.team.id);

    return Array.from(teamsGrouped).map(([_, teamItems]) => ({
        company: teamItems[0].company,
        team: teamItems[0].team,
        items: teamItems,
    })).sort(sortCompanyTeam.ascending());
};

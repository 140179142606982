import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { EntityReferenceDetailsDto } from "@api";

import { TeamContext } from "~services/contexts";
import { EntityType } from "~shared/enums";
import { getOriginNameKey } from "~shared/util/translation-helper";

@Component({
    selector: "app-origin-details",
    templateUrl: "./origin-details.component.html",
    styleUrls: ["./origin-details.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OriginDetailsComponent {

    @Input() origin?: EntityReferenceDetailsDto;
    @Input() defaultOrigin?: EntityType;

    constructor(
        private readonly teamContext: TeamContext,
    ) { }

    getOriginNameKey = () => getOriginNameKey(this.origin?.type ?? this.defaultOrigin, this.teamContext.settings.useAucbgMenus());
}

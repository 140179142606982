import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";

import { buildDefaultTableSettings, ColumnDefinition, ColumnSetting, TableSettings } from "~services/table-settings.service";

import { TableSettingsExtraContext, TableSettingsHeaderDirective } from "./table-settings-header.directive";

export interface ColumnData<TKey extends string = string> {
    key: TKey;
    setting: ColumnSetting;
    definition: Readonly<ColumnDefinition>;
}

const cloneTableSettings =
    <TSettings extends TableSettings<any> = TableSettings<any>>(settings: TSettings): TSettings => ({
        ...settings,
        columns: [...settings.columns],
    });

@Component({
    selector: "app-table-settings-menu",
    templateUrl: "./table-settings-menu.component.html",
    styleUrls: ["./table-settings-menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSettingsMenuComponent<TKey extends string = string, TSettings extends TableSettings<TKey> = TableSettings<TKey>> {

    @Input() get columnDefinitions(): Readonly<ColumnDefinition<TKey>>[] {
        return this.columnDefinitionsInternal;
    }

    set columnDefinitions(value: Readonly<ColumnDefinition<TKey>>[]) {
        this.columnDefinitionsInternal = value;
        this.recalculateColumnData();
    }

    @Input() get tableSettings(): TSettings {
        return this.tableSettingsInternal;
    }

    set tableSettings(value: TSettings) {
        this.tableSettingsInternal = value ? cloneTableSettings(value) : this.tableSettingsInternal;
        this.recalculateColumnData();
    }

    @Output() tableSettingsChange = new EventEmitter<TSettings>();

    @ViewChild(MatMenuTrigger) menu?: MatMenuTrigger;

    @ContentChild(TableSettingsHeaderDirective, { read: TemplateRef, static: false })
    headerTemplate?: TemplateRef<TableSettingsExtraContext>;

    filteredColumns$: Observable<ColumnData<TKey>[]>;

    private columnDataSubject = new BehaviorSubject<ColumnData<TKey>[]>([]);

    private columnDefinitionsInternal: Readonly<ColumnDefinition<TKey>>[] = [];
    private tableSettingsInternal: TSettings = { columns: [] } as unknown as TSettings;

    constructor() {
        this.filteredColumns$ = this.columnDataSubject.asObservable().pipe(
            map(this.filterVisible)
        );
    }

    isColumnVisible = (column: ColumnData<TKey>): boolean =>
        !!column.definition.lockType || column.setting.visible;

    drop = (event: CdkDragDrop<ColumnData<TKey>[]>, filteredColumns: ColumnData<TKey>[]) => {
        // The drop event is based on the index in the visible columns. We need to translate this to the index in the full list.
        const previousItem = filteredColumns[event.previousIndex];
        const currentItem = filteredColumns[event.currentIndex];

        const columnDataClone = [...this.columnDataSubject.value];
        const fixedPreviousIndex = columnDataClone.indexOf(previousItem);
        const fixedCurrentIndex = columnDataClone.indexOf(currentItem);

        moveItemInArray(columnDataClone, fixedPreviousIndex, fixedCurrentIndex);

        this.columnDataSubject.next(columnDataClone);
    };

    toggle = (column: ColumnData<TKey>, event: MatSlideToggleChange) => {
        if (column.definition.lockType) return;
        column.setting.visible = event.checked;
    };

    reset = () => {
        this.tableSettingsInternal = buildDefaultTableSettings<TKey, TSettings>(this.columnDefinitions);
        this.recalculateColumnData();
        this.tableSettingsChange.emit(this.tableSettingsInternal);
        this.menu?.closeMenu();
    };

    saveData = () => {
        this.tableSettingsInternal = {
            ...this.tableSettingsInternal,
            columns: this.columnDataSubject.value.map(d => d.setting)
        };
        this.tableSettingsChange.emit(this.tableSettingsInternal);
    };

    trackByKey = (_: number, column: ColumnData<TKey>) => column.key;

    getColumnNameKey = (column: ColumnData<TKey>) =>
        typeof column.definition.nameKey === "function" ? column.definition.nameKey() : column.definition.nameKey;

    private recalculateColumnData = () => {
        this.columnDataSubject.next(this.tableSettingsInternal.columns.map(c => {
            const def = this.columnDefinitionsInternal.find(d => d.key === c.key);
            if (!def) return null;
            return {
                key: c.key,
                setting: { ...c },
                definition: def
            } as ColumnData<TKey>;
        }).filter((c): c is ColumnData<TKey> => !!c));
    };

    private filterVisible = (columns: ColumnData<TKey>[]): ColumnData<TKey>[] =>
        columns.filter(column => {
            const def = column.definition;
            return def.lockType !== "position" && (def.isEnabled === undefined || def.isEnabled === true || def.isEnabled());
        });


    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_tableSettings: TableSettings | null | undefined;
}

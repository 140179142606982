import { CustomRoutes } from "~shared/custom-route";
import { Features, PageName, Profile, Role } from "~shared/enums";
import { featureFlagGuard } from "~shared/feature-flag.guard";
import { legacyAuthGuard } from "~shared/legacy-auth.guard";
import { planFeatureGuard } from "~shared/plan-feature.guard";
import { roleGuard } from "~shared/role.guard";

import { authGuard } from "./auth/auth.guard";
import { FullComponent, RootRedirectComponent } from "./components";

const userRoutes: CustomRoutes = [
    {
        path: "pages",
        loadChildren: () => import("./pages/pages.module").then(m => m.PagesComponentsModule),
        canActivate: [roleGuard],
        data: {
            profile: Profile.fullUser,
        },
    },
    {
        path: "dashboard",
        loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule)
    },
    {
        path: "update",
        loadChildren: () => import("./update/update.module").then(m => m.UpdateModule),
        canActivate: [roleGuard],
        data: {
            profile: Profile.fullUser,
        },
    },
    {
        path: "quarterly-planning",
        loadChildren: () => import("./quarterly-planning/quarterly-planning.module").then(m => m.QuarterlyPlanningModule),
        canActivate: [roleGuard],
        data: {
            pageName: PageName.quarterlyPlanning,
            profile: Profile.fullUser,
        },
    },
    {
        path: "annual-planning",
        loadChildren: () => import("./annual-planning/annual-planning.module").then(m => m.AnnualPlanningModule),
        canActivate: [planFeatureGuard, roleGuard],
        data: {
            planFeatures: ["annualPlanning"],
            pageName: PageName.annualPlanning,
            profile: Profile.fullUser,
        },
    },
    {
        path: "actions",
        loadChildren: () => import("./actions/actions.module").then(m => m.ActionsModule),
        canActivate: [roleGuard],
        data: {
            pageName: PageName.actions,
            profile: Profile.fullUser,
        },
    },
    {
        path: "discussions",
        loadChildren: () => import("./discussions/discussions.module").then(m => m.DiscussionsModule),
        canActivate: [roleGuard],
        data: {
            pageName: PageName.discussions,
            profile: Profile.fullUser,
        },
    },
    {
        path: "meeting",
        loadChildren: () => import("./meeting/meeting.module").then(m => m.MeetingModule),
        canActivate: [roleGuard],
        data: {
            profile: Profile.fullUser,
        },
    },
    {
        path: "news",
        loadChildren: () => import("./news/news.module").then(m => m.NewsModule),
        canActivate: [roleGuard],
        data: {
            pageName: PageName.news,
            profile: Profile.fullUser,
        },
    },
    {
        path: "performance",
        loadChildren: () => import("./performance/performance.module").then(m => m.PerformanceModule),
        canActivate: [roleGuard],
        data: {
            profile: Profile.fullUser,
        },
    },
    {
        path: "league",
        loadChildren: () => import("./league/league.module").then(m => m.LeagueModule),
        canActivate: [featureFlagGuard, planFeatureGuard, roleGuard],
        data: {
            featureFlags: [Features.league],
            planFeatures: ["league"],
            profile: Profile.fullUser,
        },
    },
    {
        path: "help",
        loadChildren: () => import("./help/help.module").then(m => m.HelpModule),
    },
    {
        path: "watchlists",
        loadChildren: () => import("./watchlists/watchlists.module").then(m => m.WatchlistsModule),
        canActivate: [roleGuard],
        data: {
            profile: Profile.fullUser,
        },
    },
    {
        path: "settings",
        loadChildren: () => import("./settings/settings.module").then(m => m.SettingsModule),
    },
    {
        path: "setup",
        loadChildren: () => import("./setup/setup.module").then(m => m.SetupModule),
    },
];

const legacyRedirects = [
    {
        path: "pages/welcome",
        redirectTo: "dashboard",
    },
    {
        path: "pages/update",
        redirectTo: "update",
    },
    {
        path: "pages/issues",
        redirectTo: "discussions"
    },
    {
        path: "pages/discussions",
        redirectTo: "discussions",
    },
    {
        path: "pages/challenge",
        redirectTo: "discussions",
    },
    {
        path: "pages/opportunity",
        redirectTo: "discussions",
    },
    {
        path: "pages/approvals",
        redirectTo: "discussions/approvals",
    },
    {
        path: "pages/news",
        redirectTo: "news",
    },
    {
        path: "pages/actions",
        redirectTo: "actions",
    },
    {
        path: "pages/newmeeting",
        redirectTo: "meeting/new",
    },
    {
        path: "pages/meetingarchive",
        redirectTo: "meeting/archive",
    },
    {
        path: "pages/dashboard",
        redirectTo: "performance/team",
    },
    {
        path: "pages/companydashboard",
        redirectTo: "performance/company",
    },
    {
        path: "pages/enterprisedashboard",
        redirectTo: "performance/enterprise",
    },
    {
        path: "pages/goals",
        redirectTo: "quarterly-planning/goals"
    },
    {
        path: "pages/numbers",
        redirectTo: "quarterly-planning/numbers"
    },
    {
        path: "pages/reporttypes",
        redirectTo: "quarterly-planning/reports"
    },
    {
        path: "pages/annualgoals",
        redirectTo: "annual-planning/goals",
    },
    {
        path: "pages/setup",
        redirectTo: "setup",
    },
];

const adminRoutes = [
    {
        path: "client-admin",
        loadChildren: () => import("./client-admin/client-admin.module").then(m => m.ClientAdminModule),
        canActivate: [roleGuard],
        data: {
            profile: Profile.partner,
        }
    },
    {
        path: "platform-admin",
        loadChildren: () => import("./platform-admin/platform-admin.module").then(m => m.PlatformAdminModule),
        canActivate: [roleGuard],
        data: {
            roles: [Role.superAdmin]
        }
    }
];

export const appRoutes: CustomRoutes = [
    {
        path: "",
        component: RootRedirectComponent,
        pathMatch: "full"
    },
    {
        path: "auth",
        loadChildren: () => import("./auth/auth.module").then(m => m.AuthComponentsModule)
    },
    {
        path: "signup",
        // Note that this component is never actually loaded, as the guard always rejects and redirects.
        component: RootRedirectComponent,
        children: [
            {
                path: "**",
                component: RootRedirectComponent,
            }
        ],
        canActivate: [legacyAuthGuard],
        data: {
            redirectToSignup: true
        }
    },
    {
        path: "payment",
        loadChildren: () => import("./payment/payment.module").then(m => m.PaymentModule),
        data: {
            titleKey: "paymentManagementScreen.title"
        }
    },
    {
        path: "",
        component: FullComponent,
        canActivate: [authGuard],
        children: [
            ...userRoutes,
            ...adminRoutes,
            ...legacyRedirects,
        ],
    },
    {
        path: "company/:companyId/team/:teamId",
        component: FullComponent,
        canActivate: [authGuard],
        children: [
            ...userRoutes,
            ...legacyRedirects,
            {
                path: "",
                pathMatch: "full",
                redirectTo: "dashboard"
            },
        ],
    },
    {
        path: "**",
        redirectTo: "/",
        pathMatch: "full"
    }
];


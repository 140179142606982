<ng-container *ngIf="item">
    <button mat-button color="primary" class="round-button" *ngIf="schedule.length > 1; else fakeSchedule" (click)="select.open()">
        <mat-select [value]="item" (valueChange)="selectSchedule($event)" [compareWith]="scheduleEquals" required #select panelWidth="">
            <mat-select-trigger>
                {{ 'period.quarterDisplay' | translate: { financialYear: item.financialYear, quarter: item.planningPeriod } }}
                <mat-icon>date_range</mat-icon>
            </mat-select-trigger>
            <mat-option *ngFor="let s of schedule" [value]="s">
                {{ 'period.quarterDisplay' | translate: { financialYear: s.financialYear, quarter: s.planningPeriod } }}
            </mat-option>
        </mat-select>
    </button>

    <ng-template #fakeSchedule>
        <span class="fake-schedule-container">
            <span class="fake-schedule">
                {{ 'period.quarterDisplay' | translate: { financialYear: item.financialYear, quarter: item.planningPeriod } }}
                <mat-icon>date_range</mat-icon>
            </span>
        </span>
    </ng-template>
</ng-container>
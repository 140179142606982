// eslint-disable-next-line max-classes-per-file
import { Component, ContentChild, Directive, EventEmitter, Input, Output, TemplateRef } from "@angular/core";
import { ThemePalette } from "@angular/material/core";
import { GetFeedItemDto } from "@api";

import { FeedPartitionContext } from "~feed/services";

export declare type FeedItemBorderType = undefined | "highlight" | "negative" | "positive";

@Directive({
    selector: "[appFeedListItemActions]"
})
export class FeedListItemActionsDirective { }

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "li[app-feed-list-item-content]",
    templateUrl: "./feed-list-item-content.component.html",
    styleUrls: ["./feed-list-item-content.component.scss"]
})
export class FeedListItemContentComponent {

    @Input() feedItem!: GetFeedItemDto;
    /**
     * The context that the user is currently viewing, used to generate a relative context (without superfluous information).
     */
    @Input() viewContext?: FeedPartitionContext;

    @Input() icon?: string;
    @Input() iconColor: ThemePalette;

    @Input() showCreationInfo = true;

    @Output() readonly changeContext = new EventEmitter<FeedPartitionContext>();

    @ContentChild(FeedListItemActionsDirective, { read: TemplateRef, static: false }) actions?: TemplateRef<unknown>;

    get feedItemPartitionContext(): FeedPartitionContext | undefined {
        const partitionKey = this.feedItem?.partitionKey;
        if (!partitionKey || !this.viewContext) return undefined;
        return {
            key: partitionKey,
            type: this.viewContext.type,
        };
    }

    get isCommentEdited(): boolean {
        return this.feedItem.type === "getCommentFeedItem" && this.feedItem.createdDate !== this.feedItem.lastUpdated;
    }
}

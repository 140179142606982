import { Component, Inject, OnDestroy } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { DiscussionAndSolutionDto, ReferredSolutionDto, SolvedDiscussionDto } from "@api";
import { Observable, Subject } from "rxjs";

import { HomepageDialog, HomepageEvent } from "../homepage-dialog.interface";
import { buildHomepageDialogConfig, HomepageDialogOptions } from "../homepage-dialog-shared";

export type DiscussionHomepageEvent = HomepageEvent<DiscussionAndSolutionDto> | {
    type: "solutionUpdated";
    item: ReferredSolutionDto;
};

type DiscussionHomepageDialogData = {
    discussion: DiscussionAndSolutionDto;
} | {
    referredSolution: ReferredSolutionDto;
};

@Component({
    selector: "app-discussion-homepage-dialog",
    templateUrl: "./discussion-homepage-dialog.component.html",
    styleUrls: ["./discussion-homepage-dialog.component.scss"],
})
export class DiscussionHomepageDialogComponent implements HomepageDialog<DiscussionAndSolutionDto, DiscussionHomepageEvent>, OnDestroy {

    readonly events$: Observable<DiscussionHomepageEvent>;

    discussion: DiscussionAndSolutionDto;
    referredSolution?: ReferredSolutionDto;

    private readonly eventsInternal = new Subject<DiscussionHomepageEvent>();

    constructor(
        private readonly dialogRef: MatDialogRef<DiscussionHomepageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) data: DiscussionHomepageDialogData,
    ) {
        this.events$ = this.eventsInternal.asObservable();

        if ("referredSolution" in data) {
            this.referredSolution = data.referredSolution;
            this.discussion = this.referredSolution.discussion;
        } else {
            this.discussion = data.discussion;
        }
    }

    static openForDiscussion(dialog: MatDialog, discussion: DiscussionAndSolutionDto, options?: HomepageDialogOptions) {
        return dialog.open<DiscussionHomepageDialogComponent, DiscussionHomepageDialogData>(
            DiscussionHomepageDialogComponent,
            {
                data: { discussion },
                ...buildHomepageDialogConfig(options),
            });
    }

    static openForReferredSolution(dialog: MatDialog, referredSolution: ReferredSolutionDto, options?: HomepageDialogOptions) {
        return dialog.open<DiscussionHomepageDialogComponent, DiscussionHomepageDialogData>(
            DiscussionHomepageDialogComponent,
            {
                data: { referredSolution },
                ...buildHomepageDialogConfig(options),
            });
    }

    ngOnDestroy(): void {
        this.eventsInternal.complete();
    }

    discussionUpdated = (discussion: DiscussionAndSolutionDto) => {
        // Note: we normally could not edit a discussion if coming from a referred solution
        if (this.referredSolution && discussion.solution) {
            this.referredSolution.discussion = discussion as SolvedDiscussionDto;
        }
        this.discussion = discussion;
        this.eventsInternal.next({ type: "updated", item: discussion });
    };

    discussionDeleted = () => {
        this.eventsInternal.next({ type: "deleted", item: this.discussion });
        this.dialogRef.close();
    };

    solutionUpdated = (solution: ReferredSolutionDto) => {
        this.referredSolution = solution;
        this.discussion = solution.discussion;
        this.eventsInternal.next({ type: "solutionUpdated", item: solution });
    };
}

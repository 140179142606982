import { trigger } from "@angular/animations";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from "@angular/core";
import { Subscription } from "rxjs";
import { filter, map, tap } from "rxjs/operators";

import { IWeekModel, WeekRepository } from "~repositories";
import { YearContextRepository } from "~repositories";
import { defaultAnimationTiming, fadeInAnimationBuilder } from "~shared/util/animations";

@Component({
    selector: "app-year-selector",
    templateUrl: "./year-selector.component.html",
    styleUrls: ["./year-selector.component.scss"],
    animations: [
        trigger("fadeIn", fadeInAnimationBuilder(defaultAnimationTiming)),
    ],
})
export class YearSelectorComponent implements OnInit, OnDestroy {

    private static lastElementId = 0;

    @Output() selectedYearChange = new EventEmitter<number>();

    get companyId(): string {
        return this._companyId;
    }

    @Input() set companyId(value: string) {
        this._companyId = value;
        if (this._companyId) this.ensureYearSet();
    }

    get selectedYear() {
        if (this.yearContext) return this.yearContext.getSelectedYear(this.companyId);
        return this._selectedYear;
    }

    @Input() set selectedYear(value: number | null) {
        if (value === null) return;

        if (this.yearContext) this.yearContext.setSelectedYear(this.companyId, value);
        this._selectedYear = value;
        this.selectedYearChange.emit(value);
    }

    get canReset(): boolean {
        return this.currentYear != null && this.selectedYear != null &&
            this.currentYear !== this.selectedYear;
    }

    readonly elementId: string;

    private currentYearSub?: Subscription;
    private _companyId!: string;

    private currentYear: number | null = null;

    private _selectedYear: number | null = null;

    constructor(
        private readonly weekRepository: WeekRepository,
        @Optional() private readonly yearContext: YearContextRepository | null,
    ) {
        this.elementId = `year-selector-${(YearSelectorComponent.lastElementId++)}`;
    }

    ngOnInit(): void {
        this.ensureYearSet();
    }

    ngOnDestroy(): void {
        this.currentYearSub?.unsubscribe();
    }

    incrementYear = (): void => {
        if (this.selectedYear === null) return;
        this.selectedYear++;
    };

    decrementYear = (): void => {
        if (this.selectedYear === null) return;
        this.selectedYear--;
    };

    reset = (): void => {
        if (!this.canReset) return;
        this.selectedYear = this.currentYear;
    };

    private ensureYearSet = () => {
        if (this.selectedYear === null) {
            this.setCurrentYear(/* updateSelected: */ true);
        } else {
            if (!this.currentYear) {
                this.setCurrentYear(/* updateSelected: */ false);
            }
            this.selectedYearChange.emit(this.selectedYear);
        }
    };

    private setCurrentYear = (updateSelected: boolean) => {
        if (this.currentYearSub) this.currentYearSub.unsubscribe();
        this.currentYearSub = this.weekRepository.getCurrentWeekData(this.companyId).pipe(
            filter((week): week is IWeekModel => !!week),
            map(week => week.financialYear),
            tap(year => this.currentYear = year),
        ).subscribe(year => {
            if (updateSelected) this.selectedYear = year;
        });
    };

    // eslint-disable-next-line @typescript-eslint/member-ordering, @typescript-eslint/naming-convention
    static ngAcceptInputType_companyId: string | null;
}

import { CaptureMethod, GetDailyChildNumberDto, GetDeployedChildNumberDto, GetNumberCalculationDefinitionDto, GetNumberDeploymentDefinitionDto, GetNumberDto, GetTeamUserResponsibilityDelegationDto, UpdateDailyChildNumberDto, UpdateDeployedChildNumberDto, UpdateNumberCalculationDefinitionDto, UpdateNumberDeploymentDefinitionDto, UpdateNumberDto, UpdateTeamUserResponsibilityDelegationDto } from "@api";

export type NumberSettingsDto = Omit<GetNumberDto,
    "collectionPeriodCount" | "records" | "latestWeek" |
    "resultToDate" | "targetToDate" | "latestResult" | "latestTarget" |
    "actionsCount" | "openActionsCount" | "discussionsCount" | "unsolvedDiscussionsCount" |
    "feedPartition">;

export const convertDelegationForUpdate = (delegation: GetTeamUserResponsibilityDelegationDto | undefined):
    UpdateTeamUserResponsibilityDelegationDto | undefined => {
    if (!delegation || !delegation.assignee) return undefined;
    return {
        companyId: delegation.company.id,
        teamId: delegation.team.id,
        assigneeUserId: delegation.assignee.userId,
        responsibility: delegation.responsibility,
    };
};

export const convertCalculationDefinitionForUpdate = (definition: GetNumberCalculationDefinitionDto | undefined):
    UpdateNumberCalculationDefinitionDto | undefined => {
    if (!definition) return undefined;

    return {
        type: definition.type,
        multiplier: definition.multiplier,
        divisor: definition.divisor,
        numbers: definition.numbers.map(n => ({
            companyId: n.number.company.id,
            teamId: n.number.team.id,
            globalId: n.number.globalId,
            inverted: n.inverted,
            weekOffset: n.weekOffset,
            rollingDefinition: n.rollingDefinition,
        })),
    };
};

export const convertDailyChildrenValueForUpdate =
    (children: GetDailyChildNumberDto[] | undefined): UpdateDailyChildNumberDto[] | undefined =>
        children?.map(c => ({
            updateDay: c.updateDay,
            updaterUserId: c.updater?.userId,
            target: c.target,
            weekTargets: c.weekTargets,
        }));

export const convertDeploymentDefinitionValueForUpdate = (definition: GetNumberDeploymentDefinitionDto | undefined):
    UpdateNumberDeploymentDefinitionDto | undefined => {
    if (!definition) return undefined;

    return {
        calculationType: definition.calculationType,
        teams: definition.teams.map(t => ({
            companyId: t.company.id,
            teamId: t.id,
        })),
    };
};

export const convertDeployedChildrenValueForUpdate =
    (children: GetDeployedChildNumberDto[] | undefined): UpdateDeployedChildNumberDto[] | undefined =>
        children?.filter(c =>
            // Check that the child is broadly valid, to prevent unchanged invalid children from being updated.
            // Note: if the child was changed, it would be made valid by the edit number dialog.
            // Children must have an owner, and if they are not manual, they must either have an updater or a delegation.
            !!c.owner && (!!c.updater || c.captureMethod !== CaptureMethod.manual || c.delegation && c.delegation.assignee)
        ).map(c => ({
            companyId: c.company.id,
            teamId: c.team.id,
            ownerUserId: c.owner?.userId ?? "",
            updaterUserId: c.updater?.userId,
            departmentId: c.department?.id,
            categoryId: c.category?.id,
            calculationDefinition: convertCalculationDefinitionForUpdate(c.calculationDefinition),
            deploymentDefinition: convertDeploymentDefinitionValueForUpdate(c.deploymentDefinition),
            delegation: convertDelegationForUpdate(c.delegation),
            dailyChildren: convertDailyChildrenValueForUpdate(c.dailyChildren),
            deployedChildren: convertDeployedChildrenValueForUpdate(c.deployedChildren),
            captureMethod: c.captureMethod,
            externalData: c.externalData,
            target: c.target,
            weekTargets: c.weekTargets,
        }));

export const convertNumberForUpdate = (number: NumberSettingsDto): UpdateNumberDto => ({
    ownerUserId: number.owner?.userId ?? "",
    updaterUserId: number.updater?.userId,
    departmentId: number.department?.id,
    categoryId: number.category?.id,
    subCategoryId: number.category?.subCategory?.id,
    calculationDefinition: convertCalculationDefinitionForUpdate(number.calculationDefinition),
    deploymentDefinition: convertDeploymentDefinitionValueForUpdate(number.deploymentDefinition),
    delegation: convertDelegationForUpdate(number.delegation),
    dailyChildren: convertDailyChildrenValueForUpdate(number.dailyChildren),
    deployedChildren: convertDeployedChildrenValueForUpdate(number.deployedChildren),
    description: number.description,
    type: number.type,
    entryType: number.entryType,
    captureMethod: number.captureMethod,
    numberStatus: number.numberStatus,
    targetType: number.targetType,
    isPrivate: number.isPrivate,
    isRecurring: number.isRecurring,
    requireNote: number.requireNote,
    workInstruction: number.workInstruction,
    workInstructionLink: number.workInstructionLink,
    detectProblemAfterUpdates: number.detectProblemAfterUpdates,
    allowWeeklyTargetOverrides: number.allowWeeklyTargetOverrides,
    target: number.target,
    weekTargets: number.weekTargets,
    scheduleDefinition: number.scheduleDefinition,
    dailyUpdateDefinition: number.dailyUpdateDefinition,
    externalData: number.externalData,
});

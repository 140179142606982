import { SimpleCompanyTeamDto, TeamModel, TeamReferenceDto } from "@api";

export const mapToCompanyTeam = (team: TeamModel): SimpleCompanyTeamDto => ({
    id: team.Id ?? "",
    name: team.TeamName ?? "",
    company: {
        id: team.companyId ?? "",
        clientId: team.clientId ?? "",
        name: team.companyName ?? "",
    },
});

export const mapToTeamReference = (team: SimpleCompanyTeamDto): TeamReferenceDto => ({
    companyId: team.company.id,
    teamId: team.id,
});

export const compareTeams = (o1: SimpleCompanyTeamDto, o2: SimpleCompanyTeamDto) =>
    o1.id === o2.id && o1.company.id === o2.company.id;

export const getTeamSearchData = (team: SimpleCompanyTeamDto) => `${team.name} ${team.company.name}`;

import { Injectable } from "@angular/core";
import { DiscussionAndSolutionDto, DiscussionsApi } from "@api";
import { Observable } from "rxjs";

import { BaseStateServiceWithRefresh } from "./base-state.service";
import { ItemReference } from "./comparators";
import { StateEvent } from "./state-shared";

export type DiscussionReference = ItemReference;

const isDiscussionForTeam = (discussion: DiscussionAndSolutionDto, companyId: string, teamId: string): boolean =>
    discussion.company.id === companyId && discussion.team.id === teamId;

const isDiscussionReferenceForTeam = (discussion: DiscussionReference, companyId: string, teamId: string): boolean =>
    discussion.companyId === companyId && discussion.teamId === teamId;

export type DiscussionStateEvent = StateEvent<DiscussionAndSolutionDto, DiscussionReference>;

@Injectable({
    providedIn: "root",
})
export class DiscussionStateService extends BaseStateServiceWithRefresh<DiscussionAndSolutionDto, DiscussionReference> {

    constructor(private readonly discussionsApi: DiscussionsApi) {
        super();
    }

    eventsForTeam = (companyId: string, teamId: string) => this.eventsForFilter(
        item => isDiscussionForTeam(item, companyId, teamId),
        item => isDiscussionReferenceForTeam(item, companyId, teamId),
    );

    eventsForDiscussions = (...discussions: DiscussionAndSolutionDto[]) => this.eventsForItems(discussions);

    protected refreshItem = (item: DiscussionReference): Observable<DiscussionAndSolutionDto> =>
        this.discussionsApi.getDiscussion(item.companyId, item.teamId, item.id);

    protected toReference = (item: DiscussionAndSolutionDto | DiscussionReference): DiscussionReference =>
        "companyId" in item ? item : ({ companyId: item.company.id, teamId: item.team.id, id: item.id });

    protected compareReferences = (ref1: DiscussionReference, ref2: DiscussionReference): boolean =>
        ref1.companyId === ref2.companyId &&
        ref1.teamId === ref2.teamId &&
        ref1.id === ref2.id;
}

import { PlanningPeriodDetailsDto } from "@api";
import { map, Observable, of, switchMap } from "rxjs";

import { IQuarter, IWeek, PeriodRepository } from "~repositories";

export const getPreviousPeriod =
    (periodRepository: PeriodRepository, companyId: string, currentQuarter: IQuarter): Observable<PlanningPeriodDetailsDto> =>
        currentQuarter.quarter > 1
            ? periodRepository.getPeriods(companyId, currentQuarter.financialYear)
                .pipe(map(periodsThisYear =>
                    periodsThisYear.find(p => p.index === currentQuarter.quarter - 1) as PlanningPeriodDetailsDto))
            : periodRepository.getPeriods(companyId, currentQuarter.financialYear - 1)
                .pipe(map(periodsLastYear =>
                    periodsLastYear.find(p => p.index === periodsLastYear.length) as PlanningPeriodDetailsDto));

export const getNextPeriod =
    (periodRepository: PeriodRepository, companyId: string, currentQuarter: IQuarter): Observable<PlanningPeriodDetailsDto> =>
        periodRepository.getPeriods(companyId, currentQuarter.financialYear).pipe(
            switchMap(periodsThisYear => {
                const matchingPeriod = periodsThisYear.find(p => p.index === currentQuarter.quarter + 1);
                if (matchingPeriod) return of(matchingPeriod);
                return periodRepository.getPeriods(companyId, currentQuarter.financialYear + 1)
                    .pipe(map(periodsNextYear => periodsNextYear.find(p => p.index === 1) as PlanningPeriodDetailsDto));
            })
        );

export const getPreviousWeek = (periodRepository: PeriodRepository, companyId: string, currentWeek: IWeek): Observable<IWeek> =>
    currentWeek.week > 1
        // If we're not the first week in the quarter, we can trivially move to the previous week
        ? of({
            financialYear: currentWeek.financialYear,
            quarter: currentWeek.quarter,
            week: currentWeek.week - 1,
        })
        // Otherwise, we should get the previous period, and use the last week in that period
        : getPreviousPeriod(periodRepository, companyId, currentWeek).pipe(
            map(period => ({
                financialYear: period.financialYear,
                quarter: period.index,
                week: period.collectionPeriods.length,
            })),
        );

export const getNextWeek = (periodRepository: PeriodRepository, companyId: string, currentWeek: IWeek): Observable<IWeek> =>
    periodRepository.getPeriods(companyId, currentWeek.financialYear).pipe(
        map(periodsThisYear => {
            const currentPeriod = periodsThisYear.find(p => p.index === currentWeek.quarter);
            if (currentPeriod) {
                const matchingWeek = currentPeriod.collectionPeriods.find(p => p.index === currentWeek.week + 1);
                if (matchingWeek) {
                    return {
                        financialYear: currentPeriod.financialYear,
                        quarter: currentPeriod.index,
                        week: matchingWeek.index,
                    };
                }
                // If the next period exists, we can just use the first week of that period
                const nextPeriod = periodsThisYear.find(p => p.index === currentWeek.quarter + 1);
                if (nextPeriod) {
                    return {
                        financialYear: nextPeriod.financialYear,
                        quarter: nextPeriod.index,
                        week: 1,
                    };
                }
            }
            // If we can't find the next period in this year, simply return Q1 W1 of next year
            return {
                financialYear: currentWeek.financialYear + 1,
                quarter: 1,
                week: 1,
            };
        })
    );

export const getSortablePeriodString = (item: { financialYear: number; planningPeriod: number; week: number }): string =>
    [
        item.financialYear.toString().padStart(4, "0"),
        item.planningPeriod.toString().padStart(2, "0"),
        item.week.toString().padStart(2, "0")
    ].join("_");

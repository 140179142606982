import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Chart, ChartDataset, ChartOptions, ChartTypeRegistry, registerables, ScatterDataPoint } from "chart.js";
import * as color from "color";
import {
    IWeekInfo
} from "src/app/shared/dialogs/number-chart-dialog/number-chart-calculation";

import { resultColor, resultLineStyleCore } from "~shared/chart-defaults";
import { NumberType } from "~shared/enums";
import { getNumberPrefix, getNumberSuffix } from "~shared/util/number-helper";

export type NumberWeekValue = IWeekInfo & {
    readonly result?: number;
};

export interface NumberWeeks {
    readonly globalId: string;
    readonly description: string;
    readonly type: NumberType;
    readonly weeks: NumberWeekValue[];
}

type NumberWeekDataPoint = ScatterDataPoint & NumberWeekValue;
type NumberWeeksDataSet = ChartDataset<"line", NumberWeekDataPoint[]>;

export interface MultiNumberChartData {
    readonly displayWeeks: IWeekInfo[];
    readonly numbers: NumberWeeks[];
}

@Component({
    selector: "app-multi-number-chart",
    templateUrl: "./multi-number-chart.component.html",
    styleUrls: ["./multi-number-chart.component.scss"]
})
export class MultiNumberChartComponent implements AfterViewInit, OnDestroy {
    @ViewChild("chart") chartEl?: ElementRef<HTMLCanvasElement>;

    @Input() set numbers(data: MultiNumberChartData | null | undefined) {
        if (!data) return;
        this.data = data;
        this.updateChart();
    }

    @Input() set maintainAspectRatio(value: boolean) {
        this.chartOptions.maintainAspectRatio = value;
        this.chart?.update();
    }

    @Input() set displaySubtitle(value: boolean) {
        if (this.chartOptions?.plugins?.title) {
            this.chartOptions.plugins.title.display = value;
            this.chart?.update();
        }
    }

    @Input() set displayLegend(value: boolean) {
        if (this.chartOptions?.plugins?.legend) {
            this.chartOptions.plugins.legend.display = value;
            this.chart?.update();
        }
    }

    /** Set by the parent container when data is being loaded */
    @Input() isLoading = false;

    @Output("previousClicked") previousClicked = new EventEmitter<void>();
    @Output("nextClicked") nextClicked = new EventEmitter<void>();

    private readonly chartOptions: ChartOptions = {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: this.translate.instant("numbers.chart.subtitle"),
                position: "bottom",
                align: "center"
            },
            legend: {
                labels: {
                    color: "black",
                    font: {
                        size: 15
                    },
                    filter: (item, data) => !!data.datasets[item.datasetIndex].label // hide datasets without a label
                },
            },
            tooltip: {
                mode: "x", // Only values that intersect with the same X value (week date) are show in the tooltip.
                callbacks: {
                    title: (xAxisItems) => {
                        const weekDataPoint = xAxisItems[0].raw as NumberWeekDataPoint;
                        const displayWeekInfo = this.data.displayWeeks.find(x => x.startDate.isSame(weekDataPoint?.startDate));
                        if (!displayWeekInfo) throw new Error("Week not found");

                        return this.translate.instant("numbers.chart.weekLabelWithDate", {
                            year: displayWeekInfo.financialYear,
                            quarter: displayWeekInfo.planningPeriod,
                            week: displayWeekInfo.collectionPeriod,
                            date: displayWeekInfo.startDate.clone().add(-1, "days").format("DD/MM")
                        });

                    },
                    label: (xTick) => {
                        const weekDataPoint = xTick.raw as NumberWeekDataPoint;
                        const displayWeekInfo = this.data.displayWeeks.find(x => x.startDate.isSame(weekDataPoint?.startDate));
                        if (!displayWeekInfo) throw new Error("Week not found");

                        const number = this.data.numbers[xTick.datasetIndex];
                        const value = number.weeks.find(x => x.startDate.isSame(weekDataPoint?.startDate))?.result;
                        if (!value) return "";

                        // If week data point was sourced from a different (only applies with enterprise version) quarter
                        // to the current quarter that is being viewed then display the quarter and week of the number.
                        // This only happens when viewing a number from a different company with a different financial year start date.
                        const differentQuarterInfo =
                            displayWeekInfo.financialYear !== weekDataPoint?.financialYear ||
                                displayWeekInfo.planningPeriod !== weekDataPoint?.planningPeriod ||
                                displayWeekInfo.collectionPeriod !== weekDataPoint?.collectionPeriod
                                ? ` (${this.translateWeek(weekDataPoint)})`
                                : "";

                        return `${xTick.dataset.label}: ${this.formatNumber(value, number.type)}` + differentQuarterInfo;
                    }
                },
                caretSize: 0,
                // Space where the caret would normally reside (essentially horizontal space between the Y axis and the tooltip).
                caretPadding: 14,
            },
        },
        datasets: {
            line: {
                tension: 0.4,
            }
        },
        scales: {
            //Number
            yAxis: {
                beginAtZero: true,
                grid: {
                    color: (context, options) =>
                        // If we are drawing a 0-line and the chart extends above and below, we should make this twice as dark
                        // Note: we check the chart bounds to make sure the line does not coincide with the border.
                        // If it does coincide with the border the border and we draw anyway, the border will appear darker.
                        context.tick.value === 0 && context.scale.ticks.some(t => t.value < 0) && context.scale.ticks.some(t => t.value > 0)
                            ? "rgba(0, 0, 0, 0.2)" // zero should be twice as dark
                            : options.borderColor as string, // by default, rgba(0, 0, 0, 0.1)
                },
                ticks: {
                    callback: value => this.formatNumber(value as number)
                }
            },
            //Weeks
            xAxis: {
                type: "time",
                time: {
                    unit: "week",
                    isoWeekday: true
                },
                bounds: "data",
                ticks: {
                    source: "data",
                    callback: (_, i) => {
                        const weekInfo = this.data.displayWeeks[i];
                        const labelTokens = [
                            `W ${weekInfo.collectionPeriod}`,
                            weekInfo.startDate.clone().add(-1, "days").format("DD/MM")
                        ];
                        return labelTokens as never;
                    }
                }
            }
        }
    };

    private data!: MultiNumberChartData;
    private chart?: Chart<keyof ChartTypeRegistry, NumberWeekDataPoint[], string>;

    constructor(
        private readonly translate: TranslateService,
    ) {
        Chart.register(...registerables);
    }

    ngAfterViewInit() {
        this.updateChart();
    }

    ngOnDestroy() {
        this.chart?.destroy();
    }

    previous = () => {
        this.previousClicked.emit();
    };

    next = () => {
        this.nextClicked.emit();
    };

    private formatNumber = (value: number, numberType?: NumberType): string =>
        getNumberPrefix(numberType ?? NumberType.normal) +
        new Intl.NumberFormat().format(value) +
        getNumberSuffix(numberType ?? NumberType.normal);

    private buildChart = () => {
        if (!this.chartEl) return;
        if (!this.data) return;

        this.chart = new Chart(this.chartEl.nativeElement, {
            type: "line",
            options: {
                ...this.chartOptions,
                interaction: {
                    mode: "index"
                }
            },
            data: {
                datasets: this.buildChartDatasets(this.data),
            }
        });
    };

    private updateChart = () => {
        if (!this.data) return;
        if (!this.chart) {
            this.buildChart();
            return;
        }

        this.chart.data.datasets = this.buildChartDatasets(this.data);
        this.chart.update("none"); // don't animate the change
    };

    private buildChartDatasets = (data: MultiNumberChartData): NumberWeeksDataSet[] => {
        const startResultColor = color(resultColor);
        const coloursHueRotate = 360 / data.numbers.length;

        return data.numbers.map<NumberWeeksDataSet>((number, i) => {
            const numberWeekDataPoints = data.displayWeeks.map<NumberWeekDataPoint | undefined>(displayWeek => {
                const weekData = number.weeks.find(numberWeek => numberWeek.startDate.isSame(displayWeek.startDate));
                // If there is no prior data then return a undefined so that first data point will be offset correctly
                // because there are other numbers that are probably being displayed.
                if (!weekData && number.weeks.find(numberWeek => numberWeek.startDate.isBefore(displayWeek.startDate))) {
                    return undefined;
                }

                return {
                    // Data is used when generating the tooltip.
                    ...(weekData || displayWeek),
                    x: displayWeek.startDate.clone().add(-1, "day").valueOf(),
                    y: (weekData?.result ?? null) as number
                };
            }).filter(Boolean);

            const colour = startResultColor.rotate(i * coloursHueRotate);

            return {
                ...resultLineStyleCore,
                borderColor: colour.string(),
                backgroundColor: colour.alpha(0.2).string(),
                label: number.description,
                data: numberWeekDataPoints,
                normalized: true
            };
        });
    };

    private translateWeek(weekInfo: IWeekInfo) {
        return this.translate.instant("numbers.chart.weekLabel", {
            year: weekInfo.financialYear,
            quarter: weekInfo.planningPeriod,
            week: weekInfo.collectionPeriod
        });
    }
}

<span class="year-container period-separator" [formGroup]="form">
    <label class="year-label" [for]="elementId + '-selected-year'">{{ 'periodSelector.year' | translate }} </label>

    <span *ngIf="!allowEdit" class="year" [id]="elementId + '-selected-year'">
        {{ selectedWeek?.financialYear }}
    </span>
    <input *ngIf="allowEdit" [id]="elementId + '-selected-year'" formControlName="financialYear" pattern="^\d*$"
        minlength="4" maxlength="4" required />
</span>

&#8203;
<span class="quarter-container period-separator" [formGroup]="form">
    <label class="quarter-label" [for]="elementId + '-selected-quarter'">{{ 'periodSelector.quarter' | translate }} </label>

    <span *ngIf="!allowEdit" class="quarter" [id]="elementId + '-selected-quarter'">
        {{ selectedWeek?.quarter }}
    </span>
    <input *ngIf="allowEdit" [id]="elementId + '-selected-quarter'" formControlName="quarter" pattern="^\d*$"
        maxlength="1" required />
</span>

&#8203;
<span class="week-container" [formGroup]="form">
    <label class="week-label" [for]="elementId + '-selected-week'">{{ 'periodSelector.week' | translate }}</label>

    <button *ngIf="!readonly" type="button" mat-icon-button color="primary"
        (click)="decrementWeek()"
        [title]="'periodSelector.prev' | translate">
        <mat-icon>arrow_back</mat-icon>
    </button>

    <span *ngIf="!allowEdit" class="week" [id]="elementId + '-selected-week'">
        {{ selectedWeek?.week }}
    </span>
    <input *ngIf="allowEdit" [id]="elementId + '-selected-week'" formControlName="week" pattern="^\d*$" maxlength="2"
        required />

    <button *ngIf="!readonly" type="button" mat-icon-button color="primary"
        (click)="incrementWeek()"
        [title]="'periodSelector.next' | translate" [disabled]="disableNext">
        <mat-icon>arrow_forward</mat-icon>
    </button>

    <button *ngIf="canReset" type="button" mat-icon-button (click)="reset()" class="reset-button" @fadeIn
        [matTooltip]="'periodSelector.resetToThisWeek' | translate">
        <mat-icon>undo</mat-icon>
    </button>
</span>

&#8203;
<span class="week-beginning-display" *ngIf="showWeekBeginning() && weekStartDate">
    ({{'periodSelector.weekBeginning' | translate: { date: (weekStartDate | dateFormat) } }})
</span>
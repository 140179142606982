import { HttpErrorResponse } from "@angular/common/http";
import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EntityType, GetTeamWatchlistDto, ScopedTeamWatchlistDto, SimpleCompanyTeamDto, TeamWatchlistsApi, WatchlistItemReferenceDto } from "@api";
import { BehaviorSubject, catchError, EMPTY, filter, map, Observable, of, Subscription, switchMap, tap } from "rxjs";

import { CompanyMenuRepository } from "~repositories";
import { TeamContext } from "~services/contexts";
import { NotificationService } from "~services/notification.service";
import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil, withRefresh } from "~shared/util/rx-operators";
import { sortTeam } from "~shared/util/sorters";
import { compareTeams, getTeamSearchData } from "~shared/util/team-helper";
import { valueAndChanges } from "~shared/util/util";
import { buildWatchlistForms, subscribeToWatchedState, WatchlistForm } from "~shared/util/watch-form-shared";
import { buildItemReference, ReferenceSource } from "~shared/util/watchlist-helper";

import { EditTeamWatchlistDialogComponent } from "../edit-team-watchlist-dialog/edit-team-watchlist-dialog.component";

interface TeamWatchlistPickerDialogData {
    source: ReferenceSource;
    type: EntityType;
}

@Component({
    selector: "app-team-watchlist-picker-dialog",
    templateUrl: "./team-watchlist-picker-dialog.component.html",
    styleUrls: ["./team-watchlist-picker-dialog.component.scss"]
})
export class TeamWatchlistPickerDialogComponent extends WithDestroy() implements OnInit, OnDestroy {

    readonly teamControl = new FormControl<SimpleCompanyTeamDto | null>(null);

    readonly teams$: Observable<SimpleCompanyTeamDto[]>;
    readonly lists$: Observable<WatchlistForm<ScopedTeamWatchlistDto>[]>;

    hasFailed = false;

    readonly compareTeams = compareTeams;
    readonly getTeamSearchData = getTeamSearchData;

    get itemHeading(): string {
        if ("heading" in this.source) {
            return this.source.heading as string;
        }
        if ("description" in this.source) {
            return this.source.description as string;
        }
        return "";
    }

    private readonly source: ReferenceSource;
    private readonly type: EntityType;
    private readonly reference: WatchlistItemReferenceDto;

    private readonly refreshSubject = new BehaviorSubject<void>(undefined);
    private readonly subscriptions = new Subscription();

    constructor(
        private readonly watchlistsApi: TeamWatchlistsApi,
        private readonly teamContext: TeamContext,
        private readonly companyMenuRepository: CompanyMenuRepository,
        private readonly notificationService: NotificationService,
        private readonly dialog: MatDialog,
        private readonly dialogRef: MatDialogRef<TeamWatchlistPickerDialogComponent, void>,
        @Inject(MAT_DIALOG_DATA) data: TeamWatchlistPickerDialogData,
    ) {
        super();

        this.source = data.source;
        this.type = data.type;
        this.reference = buildItemReference(data.source, data.type);

        this.teams$ = this.companyMenuRepository.getMenuItems().pipe(
            map(companies => companies.filter(c => c.company.clientId === data.source.company.clientId)),
            map(companies => companies.map(({ company, teams }) =>
                teams.map(team => ({ company, ...team }))
            ).flat().sort(sortTeam.ascending())),
            shareReplayUntil(this.destroyed$),
        );

        this.lists$ = valueAndChanges(this.teamControl).pipe(
            withRefresh(this.refreshSubject),
            tap(() => this.hasFailed = false),
            switchMap(team => !team ? of([]) : this.watchlistsApi.getListsForItem(
                team.company.id,
                team.id,
                this.reference,
            ).pipe(
                catchError(error => {
                    if (error instanceof HttpErrorResponse && (error.status >= 400 || error.status < 500)) {
                        // This is a permanent error. We should not retry.
                        this.notificationService.errorUnexpected();
                        this.dialogRef.close();
                        return EMPTY;
                    }
                    this.hasFailed = true;
                    return of([]);
                }),
            )),
            map(buildWatchlistForms),
            switchMap(this.subscribeToWatchedState),
            shareReplayUntil(this.destroyed$),
        );
    }

    static open(dialog: MatDialog, source: ReferenceSource, type: EntityType) {
        return dialog.open<TeamWatchlistPickerDialogComponent, TeamWatchlistPickerDialogData, void>(TeamWatchlistPickerDialogComponent, {
            width: "500px",
            data: { source, type },
        });
    }

    ngOnInit(): void {
        this.subscriptions.add(this.teamContext.companyTeam$.subscribe(ct => {
            if (!ct?.team || this.teamControl.value) return;
            this.teamControl.setValue({
                id: ct.team.id,
                name: ct.team.name,
                company: ct.company,
            });
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    refresh = () => this.refreshSubject.next();

    add = () => {
        const team = this.teamControl.value;
        if (!team) return;
        EditTeamWatchlistDialogComponent.openForAdd(this.dialog, team.company.id, team.id).afterClosed().pipe(
            filter(Boolean),
            switchMap(list => this.setWatchedInternal(list, /* watched: */ true)),
        ).subscribe({
            next: () => this.refreshSubject.next(),
            error: () => {
                this.notificationService.errorUnexpected();
                this.refreshSubject.next();
            },
        });
    };

    getTeamDisplay = (team: SimpleCompanyTeamDto | null | undefined) => team?.name ?? "";

    private subscribeToWatchedState = (lists: WatchlistForm<ScopedTeamWatchlistDto>[]):
        Observable<WatchlistForm<ScopedTeamWatchlistDto>[]> =>
        subscribeToWatchedState(lists, (list, watched) =>
            this.setWatchedInternal(list, watched).pipe(
                catchError(() => {
                    this.notificationService.errorUnexpected();
                    return of(list);
                }),
            ));

    private setWatchedInternal = (list: GetTeamWatchlistDto | ScopedTeamWatchlistDto, watched: boolean):
        Observable<ScopedTeamWatchlistDto> => {
        if (watched) {
            return this.watchlistsApi.watchItem(list.company.id, list.team.id, list.id, this.reference);
        } else {
            return this.watchlistsApi.unwatchItem(list.company.id, list.team.id, list.id, this.reference);
        }
    };
}

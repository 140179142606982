
import { _isNumberValue } from "@angular/cdk/coercion";
import { MatSort } from "@angular/material/sort";

/*
 * The following utility functions are extracted from the Angular Material table source code,
 * and are used to sort and filter data in the same manner.
 */

const MAX_SAFE_INTEGER = 9007199254740991;

export interface IHasSortingDataAccessor<TData> {
    sortingDataAccessor: (item: TData, sortHeaderId: string) => string | number;
}

/**
 * Data accessor function that is used for accessing data properties for sorting through
 * the default sortData function.
 * This default function assumes that the sort header IDs (which defaults to the column name)
 * matches the data's properties (e.g. column Xyz represents data['Xyz']).
 * May be set to a custom function for different behavior.
 *
 * @param data Data object that is being accessed.
 * @param sortHeaderId The name of the column that represents the data.
 */
export const defaultSortingDataAccessor = <TData>(
    data: TData,
    sortHeaderId: string,
): string | number => {
    const value = (data as { [key: string]: any })[sortHeaderId];

    if (_isNumberValue(value)) {
        const numberValue = Number(value);

        // Numbers beyond `MAX_SAFE_INTEGER` can't be compared reliably so we
        // leave them as strings. For more info: https://goo.gl/y5vbSg
        return numberValue < MAX_SAFE_INTEGER ? numberValue : value;
    }

    return value;
};

/**
 * Gets a sorted copy of the data array based on the state of the MatSort. Called
 * after changes are made to the filtered data or when sort changes are emitted from MatSort.
 * By default, the function retrieves the active sort and its direction and compares data
 * by retrieving data using the sortingDataAccessor. May be overridden for a custom implementation
 * of data ordering.
 *
 * @param data The array of data that should be sorted.
 * @param sort The connected MatSort that holds the current sort state.
 */
export const defaultSortData = <TData>(data: TData[], sort: MatSort, dataSource: IHasSortingDataAccessor<TData>): TData[] => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction === "") {
        return data;
    }

    return data.sort((a, b) => {
        let valueA = dataSource.sortingDataAccessor(a, active);
        let valueB = dataSource.sortingDataAccessor(b, active);

        // If there are data in the column that can be converted to a number,
        // it must be ensured that the rest of the data
        // is of the same type so as not to order incorrectly.
        const valueAType = typeof valueA;
        const valueBType = typeof valueB;

        if (valueAType !== valueBType) {
            if (valueAType === "number") {
                valueA += "";
            }
            if (valueBType === "number") {
                valueB += "";
            }
        }

        // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
        // one value exists while the other doesn't. In this case, existing value should come last.
        // This avoids inconsistent results when comparing values to undefined/null.
        // If neither value exists, return 0 (equal).
        let comparatorResult = 0;
        if (valueA != null && valueB != null) {
            // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
            if (valueA > valueB) {
                comparatorResult = 1;
            } else if (valueA < valueB) {
                comparatorResult = -1;
            }
        } else if (valueA != null) {
            comparatorResult = 1;
        } else if (valueB != null) {
            comparatorResult = -1;
        }

        return comparatorResult * (direction === "asc" ? 1 : -1);
    });
};

/**
 * Checks if a data object matches the data source's filter string. By default, each data object
 * is converted to a string of its properties and returns true if the filter has
 * at least one occurrence in that string. By default, the filter string has its whitespace
 * trimmed and the match is case-insensitive. May be overridden for a custom implementation of
 * filter matching.
 *
 * @param data Data object used to check against the filter.
 * @param filter Filter string that has been set on the data source.
 * @returns Whether the filter matches against the data
 */
export const defaultFilterPredicate = <TData extends object>(data: TData, filter: string): boolean => {
    // Transform the data into a lowercase string of all property values.
    const dataStr = Object.keys(data)
        .reduce((currentTerm: string, key: string) =>
        // Use an obscure Unicode character to delimit the words in the concatenated string.
        // This avoids matches where the values of two columns combined will match the user's query
        // (e.g. `Flute` and `Stop` will match `Test`). The character is intended to be something
        // that has a very low chance of being typed in by somebody in a text field. This one in
        // particular is "White up-pointing triangle with dot" from
        // https://en.wikipedia.org/wiki/List_of_Unicode_characters
            currentTerm + (data as {[key: string]: any})[key] + "◬"
        , "")
        .toLowerCase();

    // Transform the filter by converting it to lowercase and removing whitespace.
    const transformedFilter = filter.trim().toLowerCase();

    return dataStr.indexOf(transformedFilter) !== -1;
};

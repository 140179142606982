import { map, MonoTypeOperatorFunction, Observable, ObservableInput, pipe, shareReplay, switchMap, takeUntil } from "rxjs";

export const withRefresh = <T>(trigger: Observable<unknown>): MonoTypeOperatorFunction<T> =>
    switchMap<T, Observable<T>>(value => trigger.pipe(map(() => value)));

export const shareReplayUntil = <T>(trigger: ObservableInput<unknown>, bufferSize = 1): MonoTypeOperatorFunction<T> =>
    pipe(
        takeUntil(trigger),
        shareReplay({ bufferSize: bufferSize, refCount: false }),
    );

export const tapIndex = <T>(next: (value: T, index: number) => void): MonoTypeOperatorFunction<T> =>
    map((v, i) => {
        next(v, i);
        return v;
    });

export const tapFirst = <T>(next: (value: T) => void): MonoTypeOperatorFunction<T> =>
    tapIndex((v, i) => {
        if (i === 0) next(v);
    });

import { ReminderNames } from "~shared/enums";

export const getReminderNamesNameKey = (type: ReminderNames): string => {
    switch (type) {
        case ReminderNames.actionCreated: return "reminderNames.actionCreated";
        case ReminderNames.actionUpdated: return "reminderNames.actionUpdated";
        case ReminderNames.issueCreated: return "reminderNames.issueCreated";
        case ReminderNames.issueUpdated: return "reminderNames.issueUpdated";
        case ReminderNames.addNewPartner: return "reminderNames.addNewPartner";
        case ReminderNames.userInvited: return "reminderNames.userInvited";
        case ReminderNames.updateAppNotification: return "reminderNames.updateAppNotification";
        case ReminderNames.addSuggestion: return "reminderNames.addSuggestion";
        case ReminderNames.minutesOfMeetings: return "reminderNames.minutesOfMeetings";
        case ReminderNames.issueSolved: return "reminderNames.issueSolved";
        case ReminderNames.newClientAdded: return "reminderNames.newClientAdded";
        case ReminderNames.newsItemCreated: return "reminderNames.newsItemCreated";
        case ReminderNames.newsItemEdited: return "reminderNames.newsItemEdited";
        case ReminderNames.reportUpdated: return "reminderNames.reportUpdated";
        case ReminderNames.meetingRescheduled: return "reminderNames.meetingRescheduled";
        case ReminderNames.meetingCancelled: return "reminderNames.meetingCancelled";
        case ReminderNames.meetingReinstated: return "reminderNames.meetingReinstated";
        case ReminderNames.enterpriseSummaryReport: return "reminderNames.enterpriseSummaryReport";
        case ReminderNames.teamWatchlistNotification: return "reminderNames.teamWatchlistNotification";
        case ReminderNames.personalWatchlistNotification: return "reminderNames.personalWatchlistNotification";
        default: return "";
    }
};

import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { OidcSecurityService } from "angular-auth-oidc-client";
import { catchError, EMPTY, first, map, Observable, shareReplay, switchMap, tap, throwError } from "rxjs";

import { UserService } from "~services/user.service";

import { apiBaseURL } from "../api-config/app-api.config";

const cloneWithAccessToken = (request: HttpRequest<unknown>, accessToken: string | undefined): HttpRequest<unknown> => {
    if (!accessToken) return request;
    return request.clone({
        setHeaders: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${accessToken}`
        }
    });
};

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    private newAccessToken$: Observable<string | undefined> | undefined;

    constructor(
        private readonly router: Router,
        private readonly dialogRef: MatDialog,
        private readonly userService: UserService,
        private readonly oidcSecurityService: OidcSecurityService,
    ) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError(err => {
                // If the error is not a handlable unauthorized error, rethrow it
                if (!this.isUnauthorizedIpiError(err)) {
                    return throwError(() => err);
                }

                // Refresh the token (or wait for the token to be refreshed if a request is in-flight)
                return this.getNewAccessToken().pipe(
                    catchError(() => {
                        // If we can't refresh, log the user out.
                        this.handleUnauthorized();
                        return EMPTY;
                    }),
                    // Retry the request
                    // Note: we need to set the access token here, as the Interceptor that normally sets it may have already run.
                    switchMap((accessToken) => next.handle(cloneWithAccessToken(request, accessToken))),
                    catchError((err2) => {
                        // If the retried request fails but is not an unauthorized error, rethrow it
                        if (!this.isUnauthorizedIpiError(err2)) {
                            return throwError(() => err2);
                        }
                        // The retried request has failed with a 401. Log the user out.
                        this.handleUnauthorized();
                        return EMPTY;
                    })
                );
            })
        );
    }

    /**
     * Determines if the error is both unauthorized and the result of a request made to the IPI.
     *
     * @param err The thrown error returned from the HttpClient.
     * @returns True if the error is both unauthorized and from the IPI, false otherwise.
     */
    private isUnauthorizedIpiError = (err: unknown): err is HttpErrorResponse =>
        err instanceof HttpErrorResponse && err.status === 401 &&
        !!err.url && err.url.startsWith(apiBaseURL.baseURL);

    private handleUnauthorized = () => {
        const returnUrl = this.getReturnUrl();
        // This will attempt to get a new access token if the user is still logged in.
        this.userService.redirectToLogin(returnUrl);
        this.dialogRef.closeAll();
    };

    private getReturnUrl = (): string | undefined =>
        // If we're in the process of navigating or even resolving a route,
        // we need to check either the finalUrl or extractedUrl.
        this.router.getCurrentNavigation()?.finalUrl?.toString() ??
        this.router.getCurrentNavigation()?.extractedUrl?.toString() ??
        this.router.url;

    private getNewAccessToken = (): Observable<string | undefined> => {
        if (!this.newAccessToken$) {
            this.newAccessToken$ = this.oidcSecurityService.forceRefreshSession().pipe(
                first(),
                tap({
                    next: () => this.newAccessToken$ = undefined,
                    error: () => this.newAccessToken$ = undefined,
                }),
                map(result => result?.accessToken),
                shareReplay({ bufferSize: 1, refCount: true }),
            );
        }
        return this.newAccessToken$;
    };

}


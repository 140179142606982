import { Injectable, OnDestroy } from "@angular/core";
import { SwUpdate, VersionEvent } from "@angular/service-worker";
import {
    catchError, Connectable, connectable, distinctUntilChanged, EMPTY, expand, filter, from, map, merge, Observable, of, ReplaySubject,
    scan, Subscription, switchMap, takeUntil, tap, timer
} from "rxjs";

import { WithDestroy } from "~shared/mixins";
import { shareReplayUntil } from "~shared/util/rx-operators";

/**
 * How frequently we check for an update to the application.
 */
const UPDATE_CHECK_INTERVAL = 15 * 60 * 1000; // 15 minutes

@Injectable({
    providedIn: "root",
})
export class AppUpdateService extends WithDestroy() implements OnDestroy {

    readonly updateAvailable$: Observable<boolean>;

    private readonly versionUpdates$: Connectable<VersionEvent>;

    private readonly subscriptions = new Subscription();

    constructor(
        private readonly updates: SwUpdate,
    ) {
        super();

        this.versionUpdates$ = connectable(this.updates.versionUpdates, {
            connector: () => new ReplaySubject(1),
        });

        this.updateAvailable$ = this.buildUpdateAvailable().pipe(
            shareReplayUntil(this.destroyed$),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    initialise = () => {
        this.subscriptions.add(this.versionUpdates$.connect());
        this.subscriptions.add(this.updates.unrecoverable.subscribe(() => {
            // This can occur if we have an old cached app version but are trying to load a chunk that no longer exists.
            // We can only recover by refreshing the page.
            window.location.reload();
        }));
    };

    activateUpdate = () => window.location.reload();

    private buildUpdateAvailable = (): Observable<boolean> => {
        // If updates are not enabled, don't ever emit any events.
        if (!this.updates.isEnabled) return EMPTY;

        let hasChecked = false;

        // We start checking for updates after the service worker has emitted its first status value.
        // For a first load, this will likely be after the application stable, but for subsequent
        // loads it may be much earlier.
        const updateAvailable$ = this.versionUpdates$.pipe(
            filter(event =>
                event.type === "NO_NEW_VERSION_DETECTED" ||
                event.type === "VERSION_READY" ||
                event.type === "VERSION_INSTALLATION_FAILED"),
            map((event, index) => {
                // If the app says it has has a new version immediately, as we are using the "freshness" navigation strategy,
                // that version would have been loaded on page load and be currently running. As such, we can treat whatever
                // result is emitted initially as there being no update available.
                // Note: we skip this if we have manually checked for an update - perhaps the actual first check failed, so the first event
                // says we have an old version.
                if (index === 0 && !hasChecked) return false;
                // For subsequent events, we treat an update being available when the version is ready, or installation failed.
                // In either case, the page should be refreshed.
                return event.type === "VERSION_READY" || event.type === "VERSION_INSTALLATION_FAILED";
            }),
        );

        // If the initial update check fails, we may not get an initial version event.
        // In this case, wait for the check interval before checking for an update anyway.
        const versionCheckFallback$ = timer(UPDATE_CHECK_INTERVAL).pipe(map(() => false), takeUntil(updateAvailable$));

        return merge(updateAvailable$, versionCheckFallback$).pipe(
            switchMap(updateAvailable => of(updateAvailable).pipe(
                expand(() =>
                    // Even if know we have an update, we want to keep checking for an update, as this
                    // will force the ServiceWorker to update to an even newer version.
                    timer(UPDATE_CHECK_INTERVAL).pipe(
                        tap(() => hasChecked = true),
                        switchMap(() => from(this.updates.checkForUpdate()).pipe(
                            catchError(() => of(false)),
                        )),
                    ),
                ),
            )),
            // Once we know we need an update, any future checks should return true
            scan((prev, curr) => prev || curr, false),
            distinctUntilChanged(),
        );
    };
}

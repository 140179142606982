<table mat-table class="wf-table" [dataSource]="dataSource" matSort wfTableFocus
    matSortActive="team" matSortDirection="asc" matSortDisableClear>
    <ng-container matColumnDef="team">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="wf-column-heading">
            {{'numbers.team' | translate}}
        </th>
        <td mat-cell *matCellDef="let element" class="wf-column-heading">
            <a (click)="view(element)" wfEmptyLink>
                <app-team-display [team]="element.team" [company]="element.company"></app-team-display>
            </a>
        </td>
    </ng-container>

    <ng-container matColumnDef="owner">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'numbers.owner' | translate}}
        </th>
        <td mat-cell *matCellDef="let element">
            {{ getUserName(element.owner) }}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="resultSummary">
        <th mat-header-cell *matHeaderCellDef>
            {{ 'numbers.resultSummary' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
            <app-number-result-summary [number]="element"></app-number-result-summary>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; let i = index; columns: displayedColumns" [wfRowFocus]="row" [wfRowFocusIndex]="i">
    </tr>
</table>
<h4 mat-dialog-title>{{ 'numbers.deployment.migration' | translate }} - <span class="number-description">{{ description }}</span></h4>
@if ({ eligible: eligible$ | async }; as state) {
    @if (state.eligible == null) {
        <mat-dialog-content>
            <div class="loading-container">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </mat-dialog-content>
    } @else {
        <mat-dialog-content class="table-content">
            <section class="wf-section">
                <h4 class="wf-section-heading">{{ 'numbers.calculation.calculationBreakdown' | translate }}</h4>
                <app-generic-numbers-table [items]="sources$ | async" [columns]="sourceColumns"
                    showChildActions="false" showOptions="false"></app-generic-numbers-table>
            </section>
        </mat-dialog-content>
        @if (state.eligible) {
            <p mat-dialog-content class="fixed-content eligible-message" translate="numbers.deployment.migrationEligible"></p>
        } @else {
            <p mat-dialog-content class="fixed-content ineligible-message" translate="numbers.deployment.migrationNotEligible"></p>
        }
    }
}
<mat-dialog-actions align="end">
    <app-status-button type="button" [state]="buttonState" [disabled]="!canMigrate" (click)="migrate()">
        {{ "numbers.deployment.migrate" | translate }}
    </app-status-button>
</mat-dialog-actions>
import { Inject, Injectable, Optional } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateAdapterOptions, MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment from "moment";
import { Moment } from "moment";

@Injectable()
export class CustomDateAdapter extends MomentDateAdapter {
    constructor(
        @Optional() @Inject(MAT_DATE_LOCALE) dateLocale: string,
        @Optional() @Inject(MAT_MOMENT_DATE_ADAPTER_OPTIONS) _options?: MatMomentDateAdapterOptions | undefined) {
        super(dateLocale, _options);
    }

    today(): Moment {
        return moment().locale(this.locale);
    }

    getFirstDayOfWeek(): number {
        return 1; // Calendars should always start on Monday.
    }
}

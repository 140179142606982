import { Injectable } from "@angular/core";
import { SimpleCompanyTeamDto, TeamApi } from "@api";
import { map, Observable, shareReplay } from "rxjs";

import { getCompanyCacheItem, ICompanyCache, retryWithDelay, setCompanyCacheItem } from "~shared/util/caching";
import { sortTeam } from "~shared/util/sorters";
import { mapToCompanyTeam } from "~shared/util/team-helper";

@Injectable({
    providedIn: "root"
})
export class TeamRepository {
    private clientInstanceTeamCache: ICompanyCache<SimpleCompanyTeamDto[]> = {};
    private allCompanyTeamCache: ICompanyCache<SimpleCompanyTeamDto[]> = {};
    private allClientTeamCache: ICompanyCache<SimpleCompanyTeamDto[]> = {};

    constructor(
        private readonly teamApi: TeamApi) { }

    getClientInstanceTeams = (companyId: string): Observable<SimpleCompanyTeamDto[]> => {
        let cacheItem = getCompanyCacheItem(this.clientInstanceTeamCache, companyId);
        if (!cacheItem) {
            cacheItem = this.getTeamsObservableForCompany(companyId, true);
            setCompanyCacheItem(this.clientInstanceTeamCache, companyId, cacheItem);
        }
        return cacheItem;
    };

    getAllCompanyTeams = (companyId: string): Observable<SimpleCompanyTeamDto[]> => {
        let cacheItem = getCompanyCacheItem(this.allCompanyTeamCache, companyId);
        if (!cacheItem) {
            cacheItem = this.getAllTeamsObservableForCompany(companyId);
            setCompanyCacheItem(this.allCompanyTeamCache, companyId, cacheItem);
        }
        return cacheItem;
    };

    getAllClientTeams = (clientId: string): Observable<SimpleCompanyTeamDto[]> => {
        let cacheItem = getCompanyCacheItem(this.allClientTeamCache, clientId);
        if (!cacheItem) {
            cacheItem = this.getAllTeamsObservableForClient(clientId);
            setCompanyCacheItem(this.allClientTeamCache, clientId, cacheItem);
        }
        return cacheItem;
    };

    private getTeamsObservableForCompany = (companyId: string, forClientInstance: boolean) =>
        this.teamApi.getAllTeam(companyId, forClientInstance).pipe(
            retryWithDelay(),
            map(teams => teams.map(mapToCompanyTeam).sort(sortTeam.ascending())),
            shareReplay({ bufferSize: 1, refCount: false }),
        );

    private getAllTeamsObservableForCompany = (companyId: string) =>
        this.teamApi.listAllCompanyTeams(companyId).pipe(
            retryWithDelay(),
            map(teams => teams.sort(sortTeam.ascending())),
            shareReplay({ bufferSize: 1, refCount: false }),
        );

    private getAllTeamsObservableForClient = (clientId: string) =>
        this.teamApi.listAllClientTeams(clientId).pipe(
            retryWithDelay(),
            map(teams => teams.sort(sortTeam.ascending())),
            shareReplay({ bufferSize: 1, refCount: false }),
        );

}

import { ListRange } from "@angular/cdk/collections";
import { Directive, ElementRef, EventEmitter, HostListener, Output } from "@angular/core";

@Directive()
export abstract class BaseScrollListenerDirective {

    @Output() viewChange = new EventEmitter<ListRange>();

    constructor(
        protected readonly element: ElementRef<HTMLElement>,
    ) { }

    @HostListener("scroll", ["$event"]) onScroll(event: Event) {
        this.onViewChange(this.calculateVisibleRange(event.target as HTMLElement));
    }

    protected onViewChange(range: ListRange) {
        this.viewChange.next(range);
    };

    protected calculateVisibleRange = (element?: HTMLElement): ListRange => {
        element = element ?? this.element.nativeElement;
        const rowCount = this.getItemCount();
        if (rowCount === undefined || rowCount <= 0) return { start: 0, end: 0 };

        const containerHeight = element.offsetHeight; // viewport: ~500px
        const containerScrollHeight = element.scrollHeight; // length of all table
        const itemHeight = containerScrollHeight / rowCount;
        const scrollLocation = element.scrollTop;

        const start = Math.floor(scrollLocation / itemHeight);
        const end = Math.floor((scrollLocation + containerHeight) / itemHeight);
        return { start, end };
    };

    protected abstract getItemCount(): number | undefined;
}

import { UpdateStatisticDisplayMode, UpdateStatisticType } from "~shared/enums";

export const getUpdateStatisticTypeNameKey = (type: UpdateStatisticType): string => {
    switch (type) {
        case UpdateStatisticType.onTarget: return "updateStatisticType.onTarget";
        case UpdateStatisticType.offTarget: return "updateStatisticType.offTarget";
        default: return "";
    }
};

export const getUpdateStatisticDisplayModeNameKey = (mode: UpdateStatisticDisplayMode): string => {
    switch (mode) {
        case UpdateStatisticDisplayMode.count: return "updateStatisticDisplayMode.count";
        case UpdateStatisticDisplayMode.percentage: return "updateStatisticDisplayMode.percentage";
        default: return "";
    }
};

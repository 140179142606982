<form [formGroup]="form" (submit)="adopt()">
    <h4 mat-dialog-title>{{ 'numbers.deployment.adoption' | translate }} - <span class="number-description">{{ description }}</span></h4>

    <mat-dialog-content class="fixed-content">
        <mat-form-field subscriptSizing="dynamic">
            <mat-label translate="numbers.deployment.adoptNumber"></mat-label>
            <app-auto-select formControlName="child" [options]="orphans$ | async"
                [optionDisplayFunc]="getNumberDisplayFunc" [searchFunc]="getNumberSearchData" [groupWith]="groupNumbers"
                [compareWith]="compareNumbers">
                <ng-container *appAutoOption="let number">
                    <span class="multiline-description">{{number.description}}</span>
                </ng-container>
            </app-auto-select>
        </mat-form-field>
    </mat-dialog-content>
    @if (childControl.pending) {
        <mat-dialog-content class="fixed-content">
            <div class="loading-container">
                <mat-spinner [diameter]="40"></mat-spinner>
            </div>
        </mat-dialog-content>
    } @else if (childControl.hasError('ineligible')) {
        <p mat-dialog-content class="fixed-content ineligible-message" translate="numbers.deployment.adoptionNotEligible"></p>
    } @else if (childControl.valid) {
        <p mat-dialog-content class="fixed-content eligible-message" translate="numbers.deployment.adoptionEligible"></p>
    }
    <mat-dialog-actions align="end">
        <app-status-button type="submit" [state]="buttonState" [disabled]="!form.valid">
            {{ "numbers.deployment.adopt" | translate }}
        </app-status-button>
    </mat-dialog-actions>
</form>